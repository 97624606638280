import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { checkPermissionForSubject } from 'services/repeated-functions';

const nextButtonProps = {
  children: 'Siguiente',
};
const prevButtonProps = {
  children: 'Anterior',
};
const endButtonProps = {
  children: 'Finalizar',
};
const planningReferences = [
  'planningRef',
  'buttonDemandsRef',
  'subPlanningRef',
  'subRoutesRef',
  'subJobsRef',
];
const routesReferences = [
  'headerProcessRouteRef',
  'buttonShowRouteRef',
  'buttonFollowingRouteRef',
  'routeEditHeaderRef',
  'itemRemoveRef',
  'itemEditPositionRef',
  'routeResumeRef',
  'routeEditSynopticRef',
];
const routeJobsReferences = [
  'buttonShowRouteJobsRef',
  'buttonCallbackRouteJobsRef',
  'buttonCallback2RouteJobsRef',
];
const configurationFleetsReferences = ['configurationsRef', 'buttonCreateVehicleRef'];
const configurationUsersReferences = ['buttonCreateUserRef', 'rolesUserRef', 'rolesUserShowRef'];
const configurationRolesReferences = [
  'buttonCreateRoleRef',
  'columnRoleNameRoleRef',
  'columnPermissionNameRoleRef',
];
const configurationOrganizationReferences = ['organizationSubmenu', 'buttonSaveOrganization'];
const configurationSystemReferences = ['systemSubmenu', 'buttonSaveSystem'];
const reportsReferences = [
  'reportsRef',
  'buttonCreatePanelRef',
  'headerCategoryPanelRef',
  'totalViewPanelRef',
];
const reportsDownloadReferences = ['selectTypeReportRef', 'switchCustomReportRef'];
const followingReferences = [
  'followingRef',
  'leftCompFollowingRef',
  'synopticFollowingRef',
  'showTableFollowingRef',
  'showFilterFollowingRef',
];
const followingRouteDetailReferences = [
  'detailRouteFollowingRef',
  'detailRouteSearchFollowingRef',
  'detailRouteMapResumeFollowingRef',
  'detailRouteButtonEventsFollowingRef',
];
const locationsReferences = ['locationsRef', 'buttonCreateLocationRef', 'buttonMapLocationRef'];
const Positions = {
  leftTop: 'leftTop',
  leftBottom: 'leftBottom',
  rightTop: 'rightTop',
  topLeft: 'topLeft',
  topRight: 'topRight',
  top: 'top',
  bottom: 'bottom',
  right: 'right',
};
const startStep = {
  title: 'Hola 👋',
  description: (
    <div>
      {`Bienvenido/a al tour virtual de Planners. Si decides omitirlo, siempre puedes volver a
        activarlo con este botón. `}
      <Button icon={<FontAwesomeIcon icon="binoculars" color="#c1c1c1" />} size="small" disabled />
    </div>
  ),
  nextButtonProps,
  prevButtonProps,
};
const startAsLast = { ...startStep, nextButtonProps: endButtonProps };

function getTextByReference(reference) {
  let title;
  let description;
  let placement = 'bottom';
  switch (reference) {
    case 'planningRef':
      title = 'Rutas';
      description =
        'Módulo que permite cargar demandas, editar las rutas y asignar a los conductores.';
      placement = Positions.leftTop;
      break;
    case 'buttonDemandsRef':
      title = 'Cargar demandas';
      description =
        'Comienza una nueva planificación cargando demandas. Puedes optar por una carga avanzada con la optimización de ruteo de The Optimal o una carga simple que considerará el orden que determines en la planilla.';
      placement = Positions.topLeft;
      break;
    case 'subPlanningRef':
      title = 'Mi planificación';
      description =
        'Explora el Listado de Demandas Avanzadas en esta sección, donde puedes aprovechar la optimización de ruteo The Optimal con funciones de edición y parámetros.';
      placement = Positions.leftTop;
      break;
    case 'subRoutesRef':
      title = 'Lista de rutas';
      description =
        'Descubre aquí todas las rutas listas para asignar, editar y realizar seguimiento en este conjunto.';
      placement = Positions.leftTop;
      break;
    case 'subJobsRef':
      title = 'Lista de Trabajos de ruteo';
      description =
        'Descubre aquí todas las respuestas de los trabajos de ruteo, generados con la optimización de ruteo de The Optimal';
      placement = Positions.leftTop;
      break;
    case 'headerProcessRouteRef':
      title = 'Proceso';
      description =
        'En esta columna, conocerás el estado del conjunto de rutas. Se te informará si está listo para consulta o si ha surgido algún problema. Todo dependerá del tipo de proceso: activo, creado, pendiente o fallido.';
      placement = Positions.rightTop;
      break;
    case 'buttonShowRouteRef':
      title = 'Ver';
      description =
        'Utiliza esta opción para acceder al detalle del conjunto de rutas. Aquí podrás asignar y editar según tus preferencias.';
      placement = Positions.bottom;
      break;
    case 'buttonFollowingRouteRef':
      title = 'Seguimiento';
      description =
        'Cuando tus rutas estén listas, haz un seguimiento específico seleccionando solo el conjunto de rutas deseado.';
      placement = Positions.bottom;
      break;
    case 'buttonShowRouteJobsRef':
      title = 'Ver';
      description =
        'Puedes acceder para ver en detalle el trabajo realizado para generar el conjunto de rutas. Haz clic en el icono de ojo.';
      placement = Positions.bottom;
      break;
    case 'buttonCallbackRouteJobsRef':
      title = 'Callback';
      description =
        'Puedes intentar reenviar el trabajo de ruteo utilizando la opción de teléfono.';
      placement = Positions.bottom;
      break;
    case 'buttonCallback2RouteJobsRef':
      title = 'Callback';
      description =
        'Puedes intentar reenviar el trabajo de ruteo utilizando “Ejecutar callback” en caso de problemas de conexión con el ruteador.';
      placement = Positions.bottom;
      break;
    case 'reportsRef':
      title = 'Reportes';
      description =
        'Módulo que te permite consultar registros históricos mediante gráficos (paneles) o tablas (informes).';
      placement = Positions.rightTop;
      break;
    case 'buttonCreatePanelRef':
      title = 'Crear';
      description =
        'Si cuentas con los permisos necesarios, puedes crear nuevos paneles personalizados para la organización.';
      placement = Positions.bottom;
      break;
    case 'headerCategoryPanelRef':
      title = 'Categoría';
      description =
        'Al crear paneles, se agrupan automáticamente según la categoría que hayas definido.';
      placement = Positions.rightTop;
      break;
    case 'totalViewPanelRef':
      title = 'Cantidad de visitas';
      description =
        'Puedes verificar la cantidad de visitas y la última fecha de consulta del panel.';
      placement = Positions.rightTop;
      break;
    case 'selectTypeReportRef':
      title = 'Tipo de reporte';
      description =
        'Para comenzar a utilizar la sección de informes, es necesario seleccionar un tipo de reporte que deseas generar.';
      placement = Positions.rightTop;
      break;
    case 'switchCustomReportRef':
      title = 'Personzalizar informe';
      description =
        'Activando “Personalizar informe”, tendrás una vista previa con los primeros 10 registros. Puedes quitar campos moviéndolos a la izquierda y reincorporarlos llevándolos a la derecha. La vista previa reflejará cómo se descargará.';
      placement = Positions.rightTop;
      break;
    case 'followingRef':
      title = 'Seguimiento';
      description =
        'Módulo que te permite monitorear los eventos reportados, teniendo en cuenta los cumplimientos de las rutas asignadas.';
      placement = Positions.rightTop;
      break;
    case 'leftCompFollowingRef':
      title = 'Indicadores';
      description =
        'Estado de Rutas: Indicadores que reflejan la cantidad y estado actual de rutas en el rango definido. Entregas Planificadas: Total de entregas asociadas a las rutas con la cantidad de eventos reportados hasta el momento.';
      placement = Positions.rightTop;
      break;
    case 'synopticFollowingRef':
      title = 'Línea de tiempo /Sinóptico';
      description =
        'Visualiza las rutas en una línea de tiempo con colores representando el estado de entrega. Además, conoce los conductores y números de patente asignados a una ruta específica. Accede a los detalles de una ruta seleccionando el botón “Ver”.';
      placement = Positions.top;
      break;
    case 'showTableFollowingRef':
      title = 'Modo Tabla';
      description =
        'Activa el modo tabla en lugar del mapa para visualizar en detalle cada una de las rutas.';
      placement = Positions.topRight;
      break;
    case 'showFilterFollowingRef':
      title = 'Filtrar';
      description =
        'Descubre cómo filtrar y ordenar rutas para hacer un seguimiento más efectivo de las más relevantes.';
      placement = Positions.topRight;
      break;
    case 'detailRouteFollowingRef':
      title = 'Detalle';
      description =
        'Aquí es donde encontrarás el detalle de la ruta, así como los datos del conductor y del vehículo asignado.';
      placement = Positions.rightTop;
      break;
    case 'detailRouteSearchFollowingRef':
      title = 'Buscador';
      description =
        'Este buscador te permite ubicar la entrega deseada en la lista y el mapa al ingresar el número de documento o cliente.';
      placement = Positions.rightTop;
      break;
    case 'detailRouteMapResumeFollowingRef':
      title = 'Indicadores';
      description =
        'Los indicadores reflejan el resumen de eventos reportados para todas las entregas de la ruta.';
      placement = Positions.bottom;
      break;
    case 'detailRouteButtonEventsFollowingRef':
      title = 'Evento reportado';
      description =
        'Puedes revisar el detalle de los eventos reportados para una entrega desde aquí. Si la entrega cuenta con fotos asociadas, verás el ícono de una cámara.';
      placement = Positions.rightTop;
      break;
    case 'configurationsRef':
      title = 'Configuración';
      description =
        'Módulo que te permite configurar la flota, usuarios, restricciones y parámetros de la organización.';
      placement = Positions.rightTop;
      break;
    case 'buttonCreateVehicleRef':
      title = 'Crear';
      description =
        'Permite la creación de vehículos, ya sea manualmente o de manera masiva mediante archivos CSV. Encuentra validaciones de archivos para cargas masivas.';
      placement = Positions.leftTop;
      break;
    case 'buttonCreateUserRef':
      title = 'Crear';
      description =
        'Puedes crear usuarios por medio de esta acción, donde podrás definir el rol de la nueva persona y su primera clave de acceso.';
      placement = Positions.leftTop;
      break;
    case 'rolesUserRef':
      title = 'Roles';
      description =
        'Los roles definen las acciones que el usuario puede realizar en el sistema. Aquí puedes modificar estos roles según sea necesario.';
      placement = Positions.leftTop;
      break;
    case 'rolesUserShowRef':
      title = 'Roles';
      description = 'Los roles definen las acciones que el usuario puede realizar en el sistema.';
      placement = Positions.leftTop;
      break;
    case 'buttonCreateRoleRef':
      title = 'Crear';
      description =
        'Si necesitas un rol con acciones personalizadas, puedes crear uno nuevo específicamente para la organización.';
      placement = Positions.leftTop;
      break;
    case 'columnRoleNameRoleRef':
      title = 'Roles';
      description =
        'Esto es un rol, pero hay 6 roles base transversales que no se pueden modificar: Administrador, Planificador, Conductor, Monitor, Optimal y Optimal IT.';
      placement = Positions.bottom;
      break;
    case 'columnPermissionNameRoleRef':
      title = 'Permisos';
      description =
        'Los permisos corresponden a los menús disponibles en el sistema. Estos se ocultan para los usuarios que no tengan el permiso respectivo.';
      placement = Positions.rightTop;
      break;
    case 'organizationSubmenu':
      title = 'Subsecciones';
      description =
        'Explora las 8 subsecciones en esta área, aquí podrás configurar todo relacionado con la organización. Ahora nos encontramos en la sección General.';
      placement = Positions.rightTop;
      break;
    case 'buttonSaveOrganization':
    case 'buttonSaveSystem':
      title = 'Guardar';
      description = 'Guarda siempre los cambios que realices por cada sección.';
      placement = Positions.leftBottom;
      break;
    case 'systemSubmenu':
      title = 'Subsecciones';
      description =
        'Explora las 2 subsecciones en esta área, aquí podrás configurar todo relacionado con el sistema. Ahora nos encontramos en la sección General.';
      placement = Positions.rightTop;
      break;
    case 'routeEditHeaderRef':
      title = 'Encabezado de ruta';
      description =
        'Visualiza el número de ruta, asigna o reasigna conductores y vehículos, y observa la capacidad de vehículos de la ruta en barras de porcentaje para ver si la ruta está completa.';
      placement = Positions.bottom;
      break;
    case 'itemRemoveRef':
      title = 'Eliminar paradas';
      description =
        'Tienes la opción de marcar casillas donde se activará el botón para eliminar paradas. Es importante destacar que un conjunto de rutas no puede quedar sin paradas.';
      placement = Positions.right;
      break;
    case 'itemEditPositionRef':
      title = 'Orden de paradas';
      description =
        'Modifica el orden de tus paradas dentro de la ruta. Los tiempos de llegada y salida se recalcularán automáticamente con cada ajuste que realices en el orden de las paradas.';
      placement = Positions.right;
      break;
    case 'routeResumeRef':
      title = 'Estadísticas y comparaciones';
      description =
        'Los indicadores se actualizan con cada movimiento. Si deseas comparar con un movimiento anterior, haz clic en cualquier espacio y elige desde su última edición.';
      placement = Positions.bottom;
      break;
    case 'routeEditSynopticRef':
      title = 'Sinóptico o línea de tiempo';
      description =
        'Consulta rutas con el mismo conductor o vehículo en el rango de fecha del sinóptico. Estas rutas se mostrarán en gris para validar cruces o topes. Además, tienes la opción de expandir y verlas en una vista completa.';
      placement = Positions.top;
      break;
    case 'locationsRef':
      title = 'Ubicaciones';
      description =
        'Explora este módulo para gestionar bases y visitas que se usarán en los ruteos de la organización';
      placement = Positions.rightTop;
      break;
    case 'buttonCreateLocationRef':
      title = 'Crear';
      description =
        'Explora esta sección para crear ubicaciones manualmente o de manera masiva mediante archivos CSV. Encuentra validaciones de archivos para cargas masivas.';
      placement = Positions.bottom;
      break;
    case 'buttonMapLocationRef':
      title = 'Mapa';
      description =
        'Visualiza en el mapa todas las ubicaciones creadas para la organización en una vista completa';
      placement = Positions.bottom;
      break;
    default:
      break;
  }
  return {
    title,
    description,
    placement,
  };
}

function isLastStep(references, list, stepRef) {
  let lastElement;
  list.forEach((element) => {
    if (references[element]?.current) {
      lastElement = element;
    }
  });
  return lastElement === stepRef;
}

export default function defineStepsForTour(module, submodule, references, ability) {
  let steps = [startStep];
  let listRefToUse = [];
  // para los submodulos indefinidos, revisar permisos para ver cuál cargar como principal
  switch (module) {
    // ToDo: maybe this will be the same with other modules
    case 'planning':
      if (submodule === undefined) {
        const canReadDemandSet = checkPermissionForSubject(ability, 'read', 'planning');
        const canReadRoutes = checkPermissionForSubject(ability, 'read', 'routes');
        const canReadJobs = checkPermissionForSubject(ability, 'read', 'jobs');
        if (canReadDemandSet) {
          listRefToUse = planningReferences;
        } else if (canReadRoutes) {
          listRefToUse = routesReferences;
        } else if (canReadJobs) {
          listRefToUse = routeJobsReferences;
        }
      } else if (submodule === 'routes') {
        listRefToUse = routesReferences;
      } else if (submodule === 'jobs') {
        listRefToUse = routeJobsReferences;
      }
      break;
    case 'reports':
      if (submodule === undefined) {
        const canReadPanels = checkPermissionForSubject(ability, 'read', 'panels');
        const canReadReports = checkPermissionForSubject(ability, 'read', 'reports');
        if (canReadPanels) {
          listRefToUse = reportsReferences;
        } else if (canReadReports) {
          listRefToUse = reportsDownloadReferences;
        }
      } else if (submodule === 'reports') {
        listRefToUse = reportsDownloadReferences;
      }
      break;
    case 'following':
      if (submodule === undefined || submodule === 'routing-set') {
        listRefToUse = followingReferences;
      } else if (submodule === 'route-detail') {
        listRefToUse = followingRouteDetailReferences;
      }
      break;
    case 'configurations':
      if (submodule === undefined) {
        const canReadFleets = checkPermissionForSubject(ability, 'read', 'fleets');
        const canReadUsers = checkPermissionForSubject(ability, 'read', 'users');
        const canReadRoles = checkPermissionForSubject(ability, 'read', 'roles');
        const canReadOrg = checkPermissionForSubject(ability, 'read', 'organizations');
        const canReadSystem = checkPermissionForSubject(ability, 'read', 'system');
        if (canReadFleets) {
          listRefToUse = configurationFleetsReferences;
        } else if (canReadUsers) {
          listRefToUse = configurationUsersReferences;
        } else if (canReadRoles) {
          listRefToUse = configurationRolesReferences;
        } else if (canReadOrg) {
          listRefToUse = configurationOrganizationReferences;
        } else if (canReadSystem) {
          listRefToUse = configurationSystemReferences;
        }
      } else if (submodule === 'users') {
        listRefToUse = configurationUsersReferences;
      } else if (submodule === 'roles') {
        listRefToUse = configurationRolesReferences;
      } else if (submodule === 'organizations') {
        listRefToUse = configurationOrganizationReferences;
      } else if (submodule === 'system') {
        listRefToUse = configurationSystemReferences;
      }
      break;
    case 'locations':
      listRefToUse = locationsReferences;
      break;
    default:
      steps = [startAsLast];
      break;
  }
  listRefToUse.forEach((reference) => {
    if (references[reference]?.current) {
      const { title, description, placement } = getTextByReference(reference);
      steps.push({
        title,
        description,
        placement,
        target: () => references[reference]?.current,
        prevButtonProps,
        nextButtonProps: isLastStep(references, listRefToUse, reference)
          ? endButtonProps
          : nextButtonProps,
      });
    }
  });
  return steps;
}

export { defineStepsForTour };
