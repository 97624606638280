import apiSlice from 'app/api/apiSlice';

/**
 * fileProcessingApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const filesProcessingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFilesProcessing: builder.query({
      query: () => '/file-processing',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'FileProcessing', id })),
              { type: 'FileProcessing', id: 'PARTIAL-LIST' },
            ]
          : ['FileProcessing'],
    }),
    getFilesProcessingByFilters: builder.mutation({
      query: (query) => `/file-processing${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'FileProcessing', id })),
              { type: 'FileProcessing', id: 'PARTIAL-LIST' },
            ]
          : ['FileProcessing'],
    }),
    getFilesProcessingLastFive: builder.query({
      query: () => `/file-processing?order=DESC`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'FileProcessing', id })),
              { type: 'FileProcessing', id: 'PARTIAL-LIST' },
            ]
          : ['FileProcessing'],
    }),
    processFileImporter: builder.mutation({
      query: ({ file, body }) => {
        const fmData = new FormData();
        fmData.append('file', file);
        fmData.append('body', JSON.stringify(body));
        return {
          url: '/file-processing/process-file-importer',
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: ['FileProcessing'],
    }),
  }),
});

export const {
  useGetFilesProcessingQuery,
  useGetFilesProcessingByFiltersMutation,
  useGetFilesProcessingLastFiveQuery,
  useProcessFileImporterMutation,
} = filesProcessingApiSlice;
