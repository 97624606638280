import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Space, Table, Tag, Typography } from 'antd';
import { FollowingRoutesProps } from 'components/Following/following.propTypes';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CalculateDiffTimes,
  DataByStatusEvent,
  FormatToTime,
  convertDateToDayjs,
} from 'services/repeated-functions';
import '../../style.css';

const { Text } = Typography;

function FollowingTableRoutes(props) {
  const { allRoutes, isLoading } = props;
  const navigate = useNavigate();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const minutesAsActiveGps = 5;
  const todayAsDayjs = convertDateToDayjs(new Date(), tzOrganization);
  const limitGpsTime = todayAsDayjs.clone().subtract(minutesAsActiveGps, 'minutes');
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const showRoute = (id) => {
    navigate(`/following/route-detail/${id}`);
  };

  const goToRoutingSet = (id) => {
    navigate(`/planning/routes/${id}`);
  };

  const getStatus = (status) => {
    let label;
    let color;
    let border;
    switch (status) {
      case 'ASSIGNED':
        label = i18n.t('status.ASSIGNED', scopeI18n);
        color = 'default';
        break;
      case 'STARTED':
        label = i18n.t('status.STARTED', scopeI18n);
        color = 'processing';
        break;
      case 'FINISHED':
        label = i18n.t('status.FINISHED', scopeI18n);
        color = 'processing';
        border = '#0D63CF';
        break;
      default:
        label = i18n.t('status.default', scopeI18n);
        color = 'default';
        break;
    }
    return {
      label,
      color,
      border,
    };
  };

  const totalsByItems = (items) => {
    const totales = {
      SUCCESS: 0,
      FAILURE: 0,
      PARTIAL: 0,
      PENDING: 0,
    };
    // count only stops to report
    items
      .filter((item) => item.type === 'STOP')
      .forEach((item) => {
        const { events = [] } = item;
        if (events.length > 0) {
          ['SUCCESS', 'FAILURE', 'PARTIAL'].forEach((status) => {
            if (events.some((event) => event.status === status)) {
              totales[status] += 1;
            }
          });
          // first report
          if (events.length === 1 && events.every((event) => event.status === 'ARRIVAL')) {
            totales.PENDING += 1;
          }
          // postponed flow arrival -> postponed -> arrival -> postponed
          if (
            events.length > 1 &&
            events.every((event) => ['ARRIVAL', 'POSTPONED'].includes(event.status))
          ) {
            totales.PENDING += 1;
          }
        } else {
          // case not reported add as pending
          totales.PENDING += 1;
        }
      });
    return totales;
  };

  const renderHoursAndDifference = (value, real, planned) => {
    const { newTime, seconds } = CalculateDiffTimes(real, planned);
    const isPositive = seconds < 0;
    const classColor = isPositive ? 'positive' : 'negative';
    return (
      <Space style={{ width: '120px' }} direction="vertical">
        <Text>{FormatToTime(value, tzOrganization)}</Text>
        {real && (
          <Space>
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {FormatToTime(real, tzOrganization)}
            </Text>
            <Text className={`color-${classColor}-text`} style={{ fontSize: '12px' }}>
              <FontAwesomeIcon
                icon={`caret-${isPositive ? 'down' : 'up'}`}
                style={{ marginRight: '0.3rem' }}
              />
              {FormatToTime(newTime, tzOrganization)}
            </Text>
          </Space>
        )}
      </Space>
    );
  };

  const renderStepsEvents = (items) => {
    const { SUCCESS, FAILURE, PARTIAL, PENDING } = totalsByItems(items);
    const total = SUCCESS + FAILURE + PENDING + PARTIAL;
    const percentTotal = (percent) => (percent * 100) / total;
    const allStatusSteps = [
      { percent: percentTotal(SUCCESS), ...DataByStatusEvent('SUCCESS', i18n) },
      { percent: percentTotal(PARTIAL), ...DataByStatusEvent('PARTIAL', i18n) },
      { percent: percentTotal(FAILURE), ...DataByStatusEvent('FAILURE', i18n) },
      { percent: percentTotal(PENDING), ...DataByStatusEvent('PENDING', i18n) },
    ];
    return (
      <Row className="event-percentage-row">
        {allStatusSteps.map(({ text: label, percent, color }) => {
          return (
            percent > 0 && (
              <Col
                key={`col-${label}`}
                title={`${label}: ${Math.round(percent)} %`}
                flex={`${percent}%`}
                style={{
                  backgroundColor: color,
                }}
              />
            )
          );
        })}
      </Row>
    );
  };

  const columns = [
    {
      key: 'routingSetId',
      title: (
        <TitleHelp
          title={i18n.t('routingSetInit', scopeI18n)}
          helpText={i18n.t('routingSet', scopeI18n)}
        />
      ),
      dataIndex: 'routingSetId',
      fixed: 'left',
    },
    {
      key: 'routeOrder',
      title: i18n.t('routeOrder', scopeI18n),
      dataIndex: 'routeOrder',
      fixed: 'left',
      render: (value) => {
        return <Space style={{ width: '80px' }}>{value}</Space>;
      },
    },
    {
      key: 'depot',
      title: i18n.t('depot', scopeI18n),
      dataIndex: 'depot',
      render: (depot) => {
        if (depot) {
          const { name } = depot;
          return (
            <Text ellipsis className="depot-text-table" title={name}>
              {name}
            </Text>
          );
        }
        return '--';
      },
    },
    {
      key: 'driver',
      title: i18n.t('driver', scopeI18n),
      dataIndex: 'driver',
      render: (driver) => {
        if (driver) {
          const { fullName } = driver;
          return (
            <Text ellipsis className="name-text-driver-table" title={fullName}>
              {fullName}
            </Text>
          );
        }
        return i18n.t('notAssigned', scopeI18n);
      },
    },
    {
      key: 'vehicle',
      title: i18n.t('vehicle', scopeI18n),
      dataIndex: 'vehicle',
      render: (vehicle) => {
        if (vehicle) {
          const { plate } = vehicle;
          return plate;
        }
        return i18n.t('notAssigned', scopeI18n);
      },
    },
    {
      key: 'stopsSize',
      title: i18n.t('stopsSize', scopeI18n),
      dataIndex: 'stopsSize',
      render: (_, record) => {
        const { items } = record;
        const totalStops = items.filter((item) => item.type === 'STOP').length;
        const { SUCCESS, FAILURE, PARTIAL } = totalsByItems(items);
        const itemsReported = SUCCESS + PARTIAL + FAILURE;
        return `${itemsReported}/${totalStops}`;
      },
    },
    {
      key: 'startsAt',
      title: (
        <TitleHelp
          title={i18n.t('startsAt', scopeI18n)}
          helpText={i18n.t('helps.startsAt', scopeI18n)}
        />
      ),
      dataIndex: 'startsAt',
      width: 120,
      render: (value, record) => {
        const { startedAt, startsAt } = record;
        return renderHoursAndDifference(value, startedAt, startsAt);
      },
    },
    {
      key: 'endsAt',
      title: (
        <TitleHelp
          title={i18n.t('endsAt', scopeI18n)}
          helpText={i18n.t('helps.endsAt', scopeI18n)}
        />
      ),
      dataIndex: 'endsAt',
      render: (value, record) => {
        const { finishedAt, endsAt } = record;
        return renderHoursAndDifference(value, finishedAt, endsAt);
      },
    },
    {
      key: 'lastPosition',
      title: (
        <TitleHelp
          title={i18n.t('lastPosition', scopeI18n)}
          helpText={i18n.t('helps.lastPosition', scopeI18n)}
        />
      ),
      dataIndex: 'lastPosition',
      render: (_, record) => {
        let lastConnection;
        if (['STARTED', 'FINISHED'].includes(record.status)) {
          const { vehicle } = record;
          if (vehicle) {
            const { locationTimestamp } = vehicle;
            if (locationTimestamp) {
              lastConnection = locationTimestamp
                ? convertDateToDayjs(locationTimestamp, tzOrganization, 'DD-MM-YYYY HH:mm:ss')
                : undefined;
            }
          }
        } else {
          lastConnection = '--';
        }
        return lastConnection;
      },
    },
    {
      key: 'gpsActive',
      title: (
        <TitleHelp
          title={i18n.t('gpsActive', scopeI18n)}
          helpText={i18n.t('helps.gpsActive', { ...scopeI18n, minutes: minutesAsActiveGps })}
        />
      ),
      dataIndex: 'gpsActive',
      render: (_, record) => {
        const { vehicle } = record;
        let isActiveGps = false;
        let lastConnection;
        if (vehicle) {
          const { locationTimestamp } = vehicle;

          if (locationTimestamp) {
            lastConnection = locationTimestamp
              ? convertDateToDayjs(locationTimestamp, tzOrganization)
              : undefined;
            if (lastConnection) {
              isActiveGps = lastConnection.isAfter(limitGpsTime);
            }
          }
        }
        // ToDo: use correct value
        const classColor = isActiveGps ? 'positive' : 'negative';
        const text = isActiveGps ? i18n.t('active', scopeI18n) : i18n.t('inactive', scopeI18n);
        return (
          <Space
            title={
              !isActiveGps
                ? i18n.t('lastConnection', {
                    ...scopeI18n,
                    date: lastConnection?.format('DD-MM-YYYY HH:mm:ss'),
                  })
                : ''
            }
          >
            <FontAwesomeIcon
              icon="circle"
              beatFade={isActiveGps}
              className={`color-${classColor}-gps`}
            />
            {text}
          </Space>
        );
      },
    },
    {
      key: 'status',
      title: i18n.t('statusLabel', scopeI18n),
      dataIndex: 'status',
      render: (value) => {
        const { color, label, border } = getStatus(value);
        return (
          <Tag
            color={color}
            style={{ borderColor: border, color: border, width: '80px', textAlign: 'center' }}
          >
            {label}
          </Tag>
        );
      },
    },
    {
      key: 'steps',
      title: i18n.t('progress', scopeI18n),
      dataIndex: 'steps',
      render: (_, record) => {
        const { items } = record;
        return renderStepsEvents(items);
      },
    },
    {
      key: 'buttonRoutingSet',
      dataIndex: 'buttonRoutingSet',
      fixed: 'right',
      render: (_, record) => {
        return (
          <Button
            key="btn-show"
            type="default"
            onClick={() => {
              goToRoutingSet(record.routingSetId);
            }}
          >
            {i18n.t('goToRoutingSet', scopeI18n)}
          </Button>
        );
      },
    },
    {
      key: 'button',
      dataIndex: 'button',
      fixed: 'right',
      render: (_, record) => {
        return (
          <Button
            key="btn-show"
            type="primary"
            onClick={() => {
              showRoute(record.id);
            }}
          >
            {i18n.t('buttons.show')}
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      key="table-routes-following"
      columns={columns}
      dataSource={allRoutes}
      loading={isLoading}
      style={{ width: '100%' }}
      scroll={{
        x: true,
      }}
    />
  );
}

FollowingTableRoutes.defaultProps = {
  allRoutes: [],
  isLoading: false,
};

FollowingTableRoutes.propTypes = {
  allRoutes: FollowingRoutesProps,
  isLoading: PropTypes.bool,
};
export default FollowingTableRoutes;
