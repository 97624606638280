import { Skeleton } from 'antd';
import MobileConfiguration from 'components/Organizations/Configurations/Mobile';
import PropTypes from 'prop-types';

function OrganizationsCreationStepsMobile(props) {
  const { organizationId, isLoading, current } = props;
  return isLoading ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <MobileConfiguration
      organizationId={organizationId}
      submitButton
      goToNextStep
      current={current}
    />
  );
}

OrganizationsCreationStepsMobile.defaultProps = {
  organizationId: null,
  isLoading: false,
  current: null,
};
OrganizationsCreationStepsMobile.propTypes = {
  organizationId: PropTypes.number,
  isLoading: PropTypes.bool,
  current: PropTypes.number,
};

export default OrganizationsCreationStepsMobile;
