import { Drawer, Form, Typography } from 'antd';
import FooterDrawer from 'components/common/FooterDrawer';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { defineLabelByStatus } from 'services/repeated-functions';
import { formType } from 'types';
import MobileFormReason from '../FormReason';
import AllReasonsPropTypes from '../OrganizationMobile.propTypes';

const { Text } = Typography;

function MobileConfigurationDrawer(props) {
  const { drawerVisible, setDrawerVisible, statusReport, baseForm, setAllReasons, allReasons } =
    props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  const [form] = Form.useForm();

  const onClose = (event) => {
    event?.stopPropagation();
    setDrawerVisible(false, statusReport);
  };

  const title = <Text strong>{`Crear motivo ${defineLabelByStatus(statusReport, i18n)}`}</Text>;
  const footer = (
    <FooterDrawer
      onClose={onClose}
      typeDrawer="edit"
      form={form}
      buttonLoading={buttonLoading}
      entity={{ id: 1 }}
    />
  );

  return (
    <Drawer
      title={title}
      width="30%"
      onClose={(event) => onClose(event)}
      open={drawerVisible}
      destroyOnClose
      style={{ body: { paddingBottom: 80 } }}
      maskClosable={false}
      footer={footer}
      closable={false}
    >
      <MobileFormReason
        form={form}
        baseForm={baseForm}
        setButtonLoading={setButtonLoading}
        drawerVisible={drawerVisible}
        statusSelected={statusReport}
        setDrawerVisible={setDrawerVisible}
        setAllReasons={setAllReasons}
        allReasons={allReasons}
      />
    </Drawer>
  );
}

MobileConfigurationDrawer.propTypes = {
  allReasons: AllReasonsPropTypes,
  baseForm: formType,
  drawerVisible: PropTypes.bool,
  setAllReasons: PropTypes.func,
  setDrawerVisible: PropTypes.func,
  statusReport: PropTypes.string,
};

MobileConfigurationDrawer.defaultProps = {
  allReasons: {},
  baseForm: {},
  drawerVisible: false,
  setAllReasons: () => {},
  setDrawerVisible: () => {},
  statusReport: undefined,
};

export default MobileConfigurationDrawer;
