import IntroComponent from 'components/Help/Detail/common/introComponent';
import PropTypes from 'prop-types';

import GeoreferenceNote from '../common/georeferenceNote';
import RequirementsTable from '../common/requirementsTable';
import '../../style.css';

function StructureExistingVisits(props) {
  const { downloadExampleFile, helpBreadcrumbs } = props;
  const title = '¿Cómo debe ser la estructura del archivo para visitas que ya existen?';
  const mainText =
    'Si decides subir una demanda de tipo avanzada para visitas que ya existen en el sistema';

  const leftColRequired = ['Código', 'Cargas'];
  const rightColRequired = ['Código', 'SKU', 'Descripción', 'Cantidad', 'Cargas de producto'];

  return (
    <>
      {helpBreadcrumbs()}
      <IntroComponent
        title={title}
        type="advanced"
        mainText={mainText}
        downloadExampleFile={downloadExampleFile}
      />
      <GeoreferenceNote existVisit />
      <RequirementsTable
        leftColRequired={leftColRequired}
        leftImage
        rightColRequired={rightColRequired}
        rightImage
      />
    </>
  );
}

StructureExistingVisits.defaultProps = {
  downloadExampleFile: () => {},
  helpBreadcrumbs: () => {},
};

StructureExistingVisits.propTypes = {
  downloadExampleFile: PropTypes.func,
  helpBreadcrumbs: PropTypes.func,
};

export default StructureExistingVisits;
