import { Col, Row, Space, Table, Tag, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import {
  ColorByStatusEvent,
  convertDateToDayjs,
  defineLabelByStatus,
} from 'services/repeated-functions';
import { FollowingItemsProps } from '../../following.propTypes';

const { Text, Title } = Typography;
function PlannedStops(props) {
  const { allItems } = props;
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const allStops = allItems.filter((element) => element.type === 'STOP');
  const totalStops = allStops.length;
  const allStatus = ['SUCCESS', 'FAILURE', 'PARTIAL', 'PENDING'];
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const calculatePercentPerTotalStops = (numberStops) => {
    if (numberStops === 0) {
      return 0;
    }
    return Math.round((numberStops * 100) / totalStops);
  };
  const totalsByEvents = (items) => {
    const totales = {
      pa: {
        SUCCESS: 0,
        FAILURE: 0,
        PARTIAL: 0,
        PENDING: 0,
      },
      pe: {
        SUCCESS: 0,
        FAILURE: 0,
        PARTIAL: 0,
        PENDING: 0,
      },
    };
    const now = convertDateToDayjs(new Date(), tzOrganization);
    items.forEach((item) => {
      const isPe = convertDateToDayjs(item.departsAt, tzOrganization) > now;
      const { events = [] } = item;
      if (events.length > 0) {
        ['SUCCESS', 'FAILURE', 'PARTIAL'].forEach((status) => {
          if (events.some((event) => event.status === status)) {
            totales.pa[status] += 1;
            if (isPe) {
              totales.pe[status] += 1;
            }
          }
        });
        // first report
        if (events.length === 1 && events.every((event) => event.status === 'ARRIVAL')) {
          totales.pa.PENDING += 1;
          if (isPe) {
            totales.pe.PENDING += 1;
          }
        }
        // postponed flow arrival -> postponed -> arrival -> postponed
        if (
          events.length > 1 &&
          events.every((event) => ['ARRIVAL', 'POSTPONED'].includes(event.status))
        ) {
          totales.pa.PENDING += 1;
          if (isPe) {
            totales.pe.PENDING += 1;
          }
        }
      } else {
        // case not reported add as pending
        totales.pa.PENDING += 1;
        if (isPe) {
          totales.pe.PENDING += 1;
        }
      }
    });
    return totales;
  };

  const onCell = () => ({
    style: {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
  });

  const columns = [
    {
      dataIndex: 'status',
      key: 'status',
      onCell,
      render: (value) => {
        const color = ColorByStatusEvent(value);
        return (
          <Space>
            <div
              style={{ width: '20px', height: '20px', borderRadius: '4px', background: color }}
            />
            {value}
          </Space>
        );
      },
    },
    {
      title: <Text title={i18n.t('helps.na', scopeI18n)}>{i18n.t('na', scopeI18n)}</Text>,
      dataIndex: 'na',
      key: 'na',
      onCell,
    },
    {
      title: <Text title={i18n.t('helps.pa', scopeI18n)}>{i18n.t('pa', scopeI18n)}</Text>,
      dataIndex: 'pa',
      key: 'pa',
      onCell,
      render: (value) => <Text type="secondary">{`${value}%`}</Text>,
    },
    {
      title: <Text title={i18n.t('helps.pe', scopeI18n)}>{i18n.t('pe', scopeI18n)}</Text>,
      dataIndex: 'pe',
      key: 'pe',
      onCell,
      render: (value) => <Text type="secondary">{`${value}%`}</Text>,
    },
  ];
  const { pa, pe } = totalsByEvents(allStops);
  const dataChart = [];
  const data = allStatus.map((status, index) => {
    const paStatus = pa[status];
    const peStatus = pe[status];
    dataChart.push({
      id: `cha-${index}`,
      status,
      name: defineLabelByStatus(status, i18n),
      value: paStatus,
    });
    return {
      key: index,
      status: defineLabelByStatus(status, i18n),
      na: paStatus,
      pa: calculatePercentPerTotalStops(paStatus),
      pe: calculatePercentPerTotalStops(peStatus),
    };
  });

  return (
    <Row gutter={[8, 24]}>
      <Col span={24}>
        <Title level={4} className="assign-titles">
          {i18n.t('plannedDelivery', scopeI18n)}
          <Tag style={{ marginLeft: '0.5rem', fontSize: 15 }} color="processing">
            {totalStops}
          </Tag>
        </Title>
      </Col>
      <Col span={18} offset={4}>
        <PieChart width={150} height={150}>
          <Pie data={dataChart.filter(({ value }) => value > 0)} dataKey="value">
            {dataChart
              .filter(({ value }) => value > 0)
              .map((entry) => (
                <Cell key={`cell-${entry.id}`} fill={ColorByStatusEvent(entry.status)} />
              ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </Col>
      <Col span={24} className="container-planned-stops">
        <Table dataSource={data} columns={columns} pagination={false} size="small" />
      </Col>
    </Row>
  );
}

PlannedStops.defaultProps = {
  allItems: [],
};

PlannedStops.propTypes = {
  allItems: FollowingItemsProps,
};

export default PlannedStops;
