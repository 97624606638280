import { I18n } from 'i18n-js';
import { createContext } from 'react';

const i18n = new I18n();

// puede que este no se use :/
const setLanguage = (newLanguage) => {
  i18n.locale = newLanguage;
};
const getI18n = () => {
  return i18n;
};

const LocaleContext = createContext({
  setLanguage,
  getI18n,
  localeSelected: 'es',
  setLocaleSelected: () => {},
});

export default LocaleContext;
