import { Button, Dropdown, Popover, Progress } from 'antd';
import RouteStatus from 'components/common/RouteStatus';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorByOrder, HslaColorByStatusEvent, totalsByEvents } from 'services/repeated-functions';
import { SynopticData } from '../SynopticSettings.propTypes';

// Returns the FULL svg to draw the data area of the synoptic, contains a summary with the route name/number and info about how is assigned.
function RouteData(props) {
  const {
    routeNameTextSize,
    routeAsignationTextSize,
    colorLineWidth,
    vSpaceRoute,
    svgHeight,
    vTextSeparation,
    hTextSeparation,
    maxNameChar,
    showRouteNumber,
    data,
    hideColorLine,
    showAvatar,
    avatarImageSize,
    showRouteDetails,
  } = props;
  const navigate = useNavigate();
  const showRoute = (id) => {
    navigate(`/following/route-detail/${id}`);
  };
  const goToRoutingSet = (id) => {
    navigate(`/planning/routes/${id}`);
  };
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };
  const stopTextByStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return 'Entregas Pendientes';
      case 'SUCCESS':
        return 'Entregas Exitosas';
      case 'FAILURE':
        return 'Entregas Fallidas';
      case 'PARTIAL':
        return 'Entregas Parciales';
      default:
        return '';
    }
  };

  const handleDropdown = (key, id) => {
    if (key === 'goToRoutingSet') {
      goToRoutingSet(id);
    }
  };

  const routeTitle = (route) => {
    let driverVehicle = '';
    if (route.driver) {
      const plateLen = route.vehicle ? route.vehicle.plate.length : 10;
      const vehicle = route.vehicle ? route.vehicle.plate : 'Sin asignar';
      const nameToBeShown =
        route.driver.fullName.length <= maxNameChar - plateLen - 3
          ? route.driver.fullName
          : `${route.driver.fullName.slice(0, maxNameChar - plateLen - 3)}...`;

      driverVehicle = `${nameToBeShown} | ${vehicle}`;
    } else {
      driverVehicle = 'Sin asignar';
    }
    return driverVehicle;
  };

  const dataDisplay = [];
  if (data.length > 0) {
    dataDisplay.push(
      <rect
        key="dataFiller"
        x={0}
        y={0}
        width="100%"
        height={vSpaceRoute * (data.length + 1)}
        fill="white"
      />
    );
    data.forEach((route, index) => {
      // Adds the top line, color left line.
      dataDisplay.push(
        <g key={`${index + 1}-lines`}>
          {!hideColorLine && (
            <line
              x1={colorLineWidth / 2}
              y1={vSpaceRoute * index}
              x2={colorLineWidth / 2}
              y2={index * vSpaceRoute + vSpaceRoute}
              stroke={ColorByOrder(route.routeOrder)}
              strokeWidth={colorLineWidth}
            />
          )}
          <line
            x1="0"
            y1={vSpaceRoute * index}
            x2="100%"
            y2={vSpaceRoute * index}
            stroke="grey"
            strokeWidth={0.5}
          />
        </g>
      );
      // Adds the respective text if the option of showRouteNumber is enabled.
      if (showRouteNumber) {
        if (showAvatar) {
          dataDisplay.push(
            <g key={`${index + 1}-avatar`}>
              <circle
                cx="24"
                cy={vSpaceRoute / 2 + index * vSpaceRoute}
                r={(2 * avatarImageSize ** 2) ** (1 / 2) / 2}
                stroke="#999999"
                fill="none"
              />
              <image
                href={route.driver?.avatarUrl}
                x="8"
                y={vSpaceRoute / 2 - avatarImageSize / 2 + index * vSpaceRoute}
                width={avatarImageSize}
                height={avatarImageSize}
              />
            </g>
          );
        }
        dataDisplay.push(
          <g key={`${index + 1}-data`}>
            <text
              x={
                (hideColorLine ? 0 : colorLineWidth) +
                (showAvatar ? avatarImageSize + 8 : 0) +
                hTextSeparation
              }
              y={vSpaceRoute / 2 - vTextSeparation + index * vSpaceRoute}
              dominantBaseline="middle"
              fontWeight="medium"
              fontSize={routeNameTextSize}
            >
              {`Ruta ${route.routeOrder}`}
            </text>
            <foreignObject
              x={
                ((hideColorLine ? 0 : colorLineWidth) +
                  (showAvatar ? avatarImageSize + 8 : 0) +
                  hTextSeparation) *
                4
              }
              y={vTextSeparation + index * vSpaceRoute + 3}
              textAnchor="end"
              width="100%"
              height={50}
            >
              <RouteStatus status={route?.status} />
            </foreignObject>
            <text
              x={(hideColorLine ? 0 : colorLineWidth) + (showAvatar ? 36 : 0) + hTextSeparation}
              y={vSpaceRoute / 2 + vTextSeparation + index * vSpaceRoute}
              dominantBaseline="middle"
              fontSize={routeAsignationTextSize}
              fill="#999999"
            >
              {routeTitle(route)}
            </text>
          </g>
        );
        if (showRouteDetails) {
          const stopItems = route.items.filter((element) => element.type === 'STOP');
          const { pa } = totalsByEvents(stopItems);
          const content = Object.entries(pa).map(([key, value]) => (
            <div className="status-data" key={`content-showRouteDetails-${key}-${index + 1}`}>
              <Progress
                type="circle"
                percent={Math.round((value * 100) / stopItems.length)}
                size={80}
                status="normal"
                strokeColor={HslaColorByStatusEvent(key)}
              />
              <span>{stopTextByStatus(key)}</span>
            </div>
          ));
          const items = [
            {
              key: 'showStats',
              label: (
                <Popover
                  title={i18n.t('showStatsTitle', { ...scopeI18n, routeOrder: route.routeOrder })}
                  content={<div className="popover-content">{content}</div>}
                  trigger="click"
                  placement="right"
                >
                  {i18n.t('showStats', scopeI18n)}
                </Popover>
              ),
            },
            {
              key: 'goToRoutingSet',
              label: i18n.t('goToRoutingSet', scopeI18n),
            },
          ];
          dataDisplay.push(
            <g key={`${index + 1}-details`}>
              <foreignObject
                y={vSpaceRoute / 2 - 37 + index * vSpaceRoute}
                textAnchor="end"
                width="100%"
                height={50}
              >
                <div className="actions">
                  <Button
                    key="btn-show"
                    type="link"
                    onClick={() => {
                      showRoute(route.id);
                    }}
                    style={{ padding: 0 }}
                  >
                    Ver
                  </Button>
                  <Dropdown
                    menu={{
                      items,
                      onClick: (event) => {
                        const { key } = event;
                        handleDropdown(key, route.routingSetId);
                      },
                    }}
                    trigger="click"
                  >
                    <Button type="link" style={{ padding: 0 }}>
                      {i18n.t('buttons.more')}
                    </Button>
                  </Dropdown>
                </div>
              </foreignObject>
            </g>
          );
        }
      }
    });

    // Adds the final horizontal line and the vertical right line through all routes.
    dataDisplay.push(
      <g key={`${dataDisplay.length + 1}-data`}>
        <line x1="0" y1={svgHeight} x2="100%" y2={svgHeight} stroke="grey" strokeWidth={0.5} />
      </g>
    );
  }

  // This line represent the last line or the first, depending of the value of data.length
  // if = 0 the line wil be drawn at the start, if > 0 it will be drawn at the end.
  dataDisplay.push(
    <line
      key={`finalLine-${data.length + 1}`}
      x1="0"
      y1={vSpaceRoute * data.length}
      x2="100%"
      y2={vSpaceRoute * data.length}
      stroke="grey"
      strokeWidth={0.5}
    />
  );

  return (
    <svg width="100%" height={svgHeight} style={{ borderRight: '1px solid grey' }}>
      {dataDisplay}
    </svg>
  );
}
RouteData.defaultProps = {
  // *** Synoptic space definitions
  // consultar si el numero de ruta afecta esta seccion.
  colorLineWidth: 6, // pixels. Used to define the width of the color line located at the left side of the box.
  routeNameTextSize: 18, // Text size for the route name in the data area.
  routeAsignationTextSize: 15, // Text size for the name & plate of the asigned one.
  vSpaceRoute: 70, // Height of every rout to be shown.
  svgHeight: 0, // Height of the svg draw area.
  vTextSeparation: 10, // The height between the route name/number & assignment, consider this value to be double due to the point of reference is the middle of the vSpaceRoute.
  hTextSeparation: 15, // The width between the route text and the left color line.
  maxNameChar: 12, // Max. number of char (name length) to be shown in the assignment area.
  showRouteNumber: false, // boolean. sets the visibility of the route data text of this area.
  data: [], // Data to be shown and draw in the synoptic.
  hideColorLine: false, // boolean, sets the visibility of the route line colore on the left.
  showAvatar: false, // boolean, set the visibility of the driver's avatar
  avatarImageSize: 32, // number, size of each side of avatar image
  showRouteDetails: false, // bool, se the visibility of the show button for details of the route
};
RouteData.propTypes = {
  colorLineWidth: PropTypes.number,
  routeNameTextSize: PropTypes.number,
  routeAsignationTextSize: PropTypes.number,
  vSpaceRoute: PropTypes.number,
  svgHeight: PropTypes.number,
  vTextSeparation: PropTypes.number,
  hTextSeparation: PropTypes.number,
  maxNameChar: PropTypes.number,
  showRouteNumber: PropTypes.bool,
  data: SynopticData,
  hideColorLine: PropTypes.bool,
  showAvatar: PropTypes.bool,
  avatarImageSize: PropTypes.number,
  showRouteDetails: PropTypes.bool,
};

export default RouteData;
