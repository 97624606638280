import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Col, Divider, Form, Input, Row, Switch } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { formType } from 'types';
import AllReasonsPropTypes from '../OrganizationMobile.propTypes';

function MobileFormReason(props) {
  const {
    form,
    setButtonLoading,
    drawerVisible,
    statusSelected,
    baseForm,
    setDrawerVisible,
    setAllReasons,
    allReasons,
  } = props;
  const [useEnableComment, setUseEnableComment] = useState(true);
  const [fieldValues, setLoadedValues] = useState({});
  const { message } = App.useApp();

  const onChangeEnableComment = (newStatus) => {
    // clean related fields
    if (newStatus === false) {
      form.setFieldsValue({ commentRequired: newStatus });
    }
    setUseEnableComment(!newStatus);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    // assign data to parent form
    Object.keys(values).forEach((field) => {
      baseForm.setFieldsValue({ [`${field}On${statusSelected}`]: values[field] });
      if (field === 'reasons') {
        setAllReasons({ ...allReasons, [`${field}On${statusSelected}`]: values[field] });
      }
    });
    setDrawerVisible(false, statusSelected);
    message.success('Motivos agregados correctamente');
    setButtonLoading(false);
  };
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  // reset form only if drawer is displayed
  useEffect(() => {
    if (drawerVisible) {
      form.resetFields();
    }
  }, [form, drawerVisible]);

  const baseValues = {
    reasonRequired: false,
    enableComment: false,
    reasons: [],
  };

  if (Object.keys(fieldValues).length === 0) {
    const localFieldValues = {};
    const listFields = ['reasonRequired', 'enableComment', 'reasons', 'commentRequired'];
    listFields.forEach((_field) => {
      const valueLocal = baseForm.getFieldValue(`${_field}On${statusSelected}`);
      localFieldValues[`${_field}`] = valueLocal;
    });
    setUseEnableComment(!baseForm.getFieldValue(`enableCommentOn${statusSelected}`));
    setLoadedValues(localFieldValues);
  }

  const initialValues = { ...baseValues, ...fieldValues };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      initialValues={initialValues}
      validateMessages={{ required: 'Este campo es obligatorio' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.List
        name="reasons"
        rules={[
          () => ({
            validator(_, reasons) {
              if (reasons && reasons.length === 0) {
                return Promise.reject(new Error('Agregue al menos un motivo!'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <Row>
            <Col span={20} offset={2}>
              Motivos:
            </Col>
            <Col span={20} offset={2} style={{ color: 'red' }}>
              <Form.ErrorList errors={errors} />
            </Col>
            {fields.map(({ key, name, ...restField }, reasonIndex) => {
              const { isListField } = restField;
              return (
                <Col span={20} offset={2} key={key}>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        isListField={isListField}
                        name={name}
                        required={key === 0}
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder={`Opción ${reasonIndex + 1}`}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    {fields.length > 1 && (
                      <Col span={3}>
                        <FontAwesomeIcon
                          className="dynamic-delete-button"
                          icon={['fas', 'circle-minus']}
                          title="Quitar motivo"
                          onClick={() => remove(name)}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              );
            })}
            <Col span={18} offset={2}>
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  + Añadir opciones
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.List>
      <Row>
        <Col span={20} offset={2}>
          <Form.Item name="reasonRequired" label="Motivo obligatorio" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={20} offset={2}>
          <Divider />
        </Col>

        <Col span={10} offset={2}>
          <Form.Item name="enableComment" label="Activar comentario" valuePropName="checked">
            <Switch onChange={onChangeEnableComment} />
          </Form.Item>
        </Col>

        <Col span={10} offset={2}>
          <Form.Item name="commentRequired" label="Comentario obligatorio" valuePropName="checked">
            <Switch disabled={useEnableComment} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

MobileFormReason.propTypes = {
  allReasons: AllReasonsPropTypes,
  baseForm: formType,
  drawerVisible: PropTypes.bool,
  form: formType,
  setAllReasons: PropTypes.func,
  setButtonLoading: PropTypes.func,
  setDrawerVisible: PropTypes.func,
  statusSelected: PropTypes.string,
};

MobileFormReason.defaultProps = {
  allReasons: {},
  baseForm: {},
  drawerVisible: false,
  form: {},
  setAllReasons: () => {},
  setButtonLoading: () => {},
  setDrawerVisible: () => {},
  statusSelected: 'success',
};
export default MobileFormReason;
