import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Col, Dropdown, Row, Space } from 'antd';
import { useGetProfilesQuery } from 'features/routingEngine/routingEngineApiSlice';
import {
  useGetLabelsFromParametersQuery,
  useGetRoutingParametersQuery,
} from 'features/routingParameters/routingParametersApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  checkPermissionForSubject,
  displayUnauthorizedNotification,
} from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import RoutingParametersDrawer from './RoutingParametersDrawer';
import RoutingParametersTable from './Table';

function RoutingParameters(props) {
  const { organizationId, submitButton, current } = props;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState('create');
  const [parameter, setParameter] = useState();
  const [dataFormated, setDataFormated] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerClean, setTriggerClean] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [labels, setLabels] = useState([]);
  const { notification } = App.useApp();
  const [drawerEntity, setDrawerEntity] = useState('routingParameter');
  // permissions
  const ability = useContext(CaslContext);
  const canCreate = checkPermissionForSubject(ability, 'create', 'routingParameters');
  const canUpdateOrg = checkPermissionForSubject(ability, 'update', 'organizations');

  // custom hooks
  const organizationIdParam = organizationId ? `?organizationId=${organizationId}` : '';
  const {
    data: parametersData,
    isLoading,
    refetch,
    error: fetchError,
  } = useGetRoutingParametersQuery(organizationIdParam);

  const {
    data: labelsData,
    isLoading: labelsFetching,
    refetch: refetchLabels,
  } = useGetLabelsFromParametersQuery(organizationIdParam);
  const error403 = fetchError?.status === 403;
  const navigate = useNavigate();

  const { data: profilesData, isLoading: profilesFetching } = useGetProfilesQuery();

  useEffect(() => {
    if (!profilesFetching) {
      setProfiles(profilesData?.krakenprofiles || []);
    }
  }, [profilesData, profilesFetching]);

  useEffect(() => {
    if (!labelsFetching) {
      setLabels(labelsData);
    }
    refetchLabels();
  }, [labelsData, labelsFetching, refetchLabels]);

  useEffect(() => {
    if (error403) {
      const urlRedirect = submitButton ? '/unauthorized' : '/configurations/unauthorized';
      navigate(urlRedirect);
      displayUnauthorizedNotification(notification);
    }
  }, [error403, navigate, notification, submitButton]);

  const setDrawerParameter = (boolValue) => {
    setDrawerVisible(boolValue);
    if (!boolValue) {
      setParameter();
    }
  };

  const setDrawerData = (objectDrawer) => {
    const { drawerType, drawerVisibility, drawerParameter, drawerEntityType } = objectDrawer;
    setTypeDrawer(drawerType);
    setDrawerVisible(drawerVisibility);
    setParameter(drawerParameter);
    setDrawerEntity(drawerEntityType);
  };

  const showDrawer = (event) => {
    setDrawerVisible(true);
    setTypeDrawer('create');
    setDrawerEntity(event?.key);
  };

  useEffect(() => {
    setLoading(isLoading);
    setDataFormated(parametersData);
    refetch();
  }, [parametersData, isLoading, refetch]);
  const shouldDisplayButton = (canUpdateOrg && submitButton) || canCreate;

  const items = [
    {
      label: 'Parámetro de ruteo',
      key: 'routingParameter',
    },
    {
      label: 'Parámetro de servicio',
      key: 'serviceParameter',
    },
  ];

  return (
    <>
      {shouldDisplayButton && (
        <Row gutter={24} style={{ marginBottom: '1rem' }}>
          <Col flex="auto">
            <Dropdown menu={{ items, onClick: showDrawer }}>
              <Button type="primary" className="float-right">
                <Space>
                  Crear
                  <FontAwesomeIcon icon={['fas', 'chevron-down']} fontSize={14} />
                </Space>
              </Button>
            </Dropdown>
          </Col>
        </Row>
      )}
      <RoutingParametersTable
        setDrawerData={setDrawerData}
        loading={loading}
        data={dataFormated}
        setDataCallback={setDataFormated}
        profiles={profiles}
        organizationId={organizationId}
        submitButton={submitButton}
        current={current}
        labels={labels}
        triggerClean={triggerClean}
        setTriggerClean={setTriggerClean}
      />
      <RoutingParametersDrawer
        drawerVisible={drawerVisible}
        setDrawerParameter={setDrawerParameter}
        typeDrawer={typeDrawer}
        parameter={parameter}
        profiles={profiles}
        organizationId={organizationId}
        labels={labels}
        setTriggerClean={setTriggerClean}
        drawerEntity={drawerEntity}
      />
    </>
  );
}

RoutingParameters.defaultProps = {
  organizationId: null,
  submitButton: false,
  current: null,
};
RoutingParameters.propTypes = {
  organizationId: PropTypes.number,
  submitButton: PropTypes.bool,
  current: PropTypes.number,
};

export default RoutingParameters;
