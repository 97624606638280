import apiSlice from 'app/api/apiSlice';

/**
 * routingEngineApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const routingEngineApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    manualCallback: builder.mutation({
      query: (jobID) => ({
        url: `routing-engine/manual-callback/${jobID}`,
        method: 'POST',
      }),
      invalidatesTags: ['RoutingEngine'],
    }),
    callDataFromRe: builder.query({
      query: (params) => ({
        url: `routing-engine/routeJob/${params.jobId}${params.methodReal}`,
        providesTags: (_result, _error, id) => [{ type: 'RoutingEngine', id }],
      }),
    }),
    getProfiles: builder.query({
      query: () => ({
        url: 'routing-engine/get-profiles',
        providesTags: (_result, _error, id) => [{ type: 'RoutingEngineProfiles', id }],
      }),
    }),
    getParametersByProfile: builder.query({
      query: (profile) => ({
        url: `routing-engine/get-parameters-by-profile/${profile}`,
        providesTags: (_result, _error, id) => [{ type: 'RoutingEngineParameters', id }],
      }),
    }),
  }),
});

export const {
  useManualCallbackMutation,
  useCallDataFromReQuery,
  useGetProfilesQuery,
  useGetParametersByProfileQuery,
} = routingEngineApiSlice;
