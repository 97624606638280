import { Col } from 'antd';
import NoResponsiveComponent from '../layout/NoResponsiveContent';
import VisitLocationList from './List';

function VisitLocations() {
  return (
    <>
      <Col md={24} xs={0}>
        <VisitLocationList />
      </Col>
      <Col md={0} xs={24}>
        <NoResponsiveComponent />
      </Col>
    </>
  );
}

export default VisitLocations;
