import { Row } from 'antd';
import SynopticBase from 'components/common/Synoptic';
import SynopticControlBar from 'components/common/Synoptic/SynopticControlBar';
import dayjs from 'dayjs';
import { useGetRelativeRoutesMutation } from 'features/routes/routesApiSlice';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { RoutePropTypes } from 'types';

const SELECT_ALL = -1;

function RoutesDemandSynoptic(props) {
  const {
    currentLowerValue,
    setCurrentLowerValue,
    setCurrentUpperValue,
    routes,
    setRoutes,
    selectedRoute,
    allRoutes,
    setRestrictionsDrawerOpen,
  } = props;
  const [defaultSettings, setDefaultSettings] = useState(true);
  const [selectedState, setSelectedState] = useState('all');
  const [selectedType, setSelectedType] = useState('all');
  const [relativeRoutes, setRelativeRoutes] = useState([]);
  const [relativeRoutesKinds, setRelativeRoutesKinds] = useState([]);

  const totalRestrictionsVirtual = allRoutes.reduce(
    (summ, { totalRestrictions = 0 }) => summ + totalRestrictions,
    0
  );

  // Synoptic settings
  const [userSettings, setUserSettings] = useState({
    referenceDate: dayjs(),
    shownTimeForward: 12,
    shownTimeBackward: 1,
    shownTimeReference: 'hours',
    showRouteNumber: true,
    minimalTick: 15,
    minimalTickReference: 'minutes',
  });

  const [getRelativeRoutes] = useGetRelativeRoutesMutation();

  const lowerValues = {
    expand: 86,
    normal: 23,
    hide: 0,
  };

  const toggleSynoptic = () => {
    if (currentLowerValue === 'normal') {
      setCurrentUpperValue('expand');
      setCurrentLowerValue('hide');
    } else {
      setCurrentUpperValue('normal');
      setCurrentLowerValue('normal');
    }
  };

  const toggleExpandSynoptic = () => {
    if (currentLowerValue === 'normal') {
      setCurrentUpperValue('hide');
      setCurrentLowerValue('expand');
    } else {
      setCurrentUpperValue('normal');
      setCurrentLowerValue('normal');
    }
  };

  // Synoptic default settings based on the routes on display.
  useEffect(() => {
    const updateSettings = (routesFetched) => {
      const initDates = [];
      const endDates = [];
      routesFetched.forEach((route) => {
        initDates.push(dayjs(route.startsAt));
        endDates.push(dayjs(route.endsAt));
      });
      const minDate = dayjs(Math.min(...initDates));
      const maxDate = dayjs(Math.max(...endDates));
      const diffHours = Math.ceil((maxDate - minDate) / 3600000);

      return {
        referenceDate: minDate,
        shownTimeForward: diffHours + 0.5,
        shownTimeBackward: 0.5,
        showRouteNumber: true,
        shownTimeReference: 'hours',
        minimalTick: 15,
        minimalTickReference: 'minutes',
      };
    };

    if (defaultSettings && routes.length > 0) {
      setUserSettings(updateSettings(routes));
    }
  }, [routes, defaultSettings]);

  // Sets the values for the related routes on show option update
  useEffect(() => {
    if (relativeRoutesKinds && relativeRoutesKinds.length === 0) return;

    (async () => {
      let foundRoutes = [];
      if (relativeRoutesKinds.length > 0 && routes.length > 0) {
        const showOptions = relativeRoutesKinds.toString();
        const { referenceDate, shownTimeBackward, shownTimeForward, shownTimeReference } =
          userSettings;
        const startTime = dayjs(referenceDate).subtract(shownTimeBackward, shownTimeReference);
        const endTime = dayjs(referenceDate).add(shownTimeForward, shownTimeReference);
        // get leftLimit & rightLimit dates from userSettings
        const params = {
          routingSetId: routes[0]?.routingSetId,
          body: {
            leftLimit: startTime,
            rightLimit: endTime,
            show: showOptions,
          },
        };
        const response = await getRelativeRoutes(params);
        foundRoutes = response.data;
      }
      setRelativeRoutes(foundRoutes);
    })();
  }, [userSettings, routes, relativeRoutesKinds, getRelativeRoutes]);

  // Sets filters for selected Route, State & Type, in that order.
  useEffect(() => {
    // if try select a route, and all routes dont have, make react error
    const filterRoute = selectedRoute !== SELECT_ALL ? [allRoutes[selectedRoute]] : allRoutes;
    const filterState =
      selectedState === 'all'
        ? filterRoute
        : filterRoute.filter((route) => {
            if (selectedState === 'assigned') {
              return route.driver !== null;
            }
            return route.driver === null;
          });
    const filterType =
      selectedType === 'all'
        ? filterState
        : filterState.filter((route) => {
            return route.action === selectedType;
          });
    setRoutes(filterType);
  }, [allRoutes, selectedRoute, selectedState, selectedType, setRoutes]);

  return (
    <>
      <Row
        key="synoptic-demand-container"
        className="lower-container"
        style={{
          height: `${lowerValues[currentLowerValue]}%`,
          opacity: currentLowerValue === 'hide' ? 0 : 1,
        }}
      >
        <SynopticBase
          data={routes}
          userSettings={userSettings}
          setUserSettings={setUserSettings}
          relativeRoutes={relativeRoutes}
          routesColorBy="route-number"
        />
      </Row>
      <Row className="lower-control-bar">
        <SynopticControlBar
          defaultSettings={defaultSettings}
          setDefaultSettings={setDefaultSettings}
          userSettings={userSettings}
          setUserSettings={setUserSettings}
          currentState={currentLowerValue}
          toggleHide={toggleSynoptic}
          toggleFullScreen={toggleExpandSynoptic}
          filterState={setSelectedState}
          filterType={setSelectedType}
          showControl={setRelativeRoutesKinds}
          setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
          totalRestrictions={totalRestrictionsVirtual}
        />
      </Row>
    </>
  );
}

RoutesDemandSynoptic.defaultProps = {
  currentLowerValue: '',
  setCurrentLowerValue: () => {},
  setCurrentUpperValue: () => {},
  routes: [],
  setRoutes: () => {},
  selectedRoute: undefined,
  allRoutes: [],
  setRestrictionsDrawerOpen: () => {},
};

RoutesDemandSynoptic.propTypes = {
  currentLowerValue: PropTypes.string,
  setCurrentLowerValue: PropTypes.func,
  setCurrentUpperValue: PropTypes.func,
  setRoutes: PropTypes.func,
  routes: PropTypes.arrayOf(RoutePropTypes),
  selectedRoute: PropTypes.number,
  allRoutes: PropTypes.arrayOf(RoutePropTypes),
  setRestrictionsDrawerOpen: PropTypes.func,
};

export default RoutesDemandSynoptic;
