import PropTypes from 'prop-types';

export const VehiclePropTypes = PropTypes.shape({
  id: PropTypes.number,
  nid: PropTypes.string,
  plate: PropTypes.string,
  loads: PropTypes.arrayOf(
    PropTypes.shape({
      load: PropTypes.number,
    })
  ),
  deletedAt: PropTypes.string,
  customAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      dataType: PropTypes.string,
      defaultValue: PropTypes.string,
      customValue: PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
      }),
    })
  ),
});

export const VehiclePositionPropTypes = PropTypes.shape({
  vehicleId: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  timestamp: PropTypes.string,
  organizationId: PropTypes.number,
});
