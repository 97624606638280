import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Col, Divider, Flex, Row, Tag, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { convertDateToDayjs } from 'services/repeated-functions';
import { UserPropTypes } from '../userRoles.propTypes';

const { Text } = Typography;
function UsersShow(props) {
  const { user } = props;
  const {
    nationalNid,
    name,
    lastName,
    roles,
    phone,
    email,
    deletedAt: isInactive,
    photo,
    organization,
    lastLogin,
  } = user;
  const { timezone: tzOrganization, id: currentOrganizationId } =
    useSelector(selectCurrentOrganization);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'users' };
  const orgRoles = roles?.filter((uRole) => uRole.organizationId === currentOrganizationId) || [];
  return (
    <>
      <Flex align="center" justify="center">
        <Avatar
          src={photo}
          size={100}
          style={{ marginLeft: '1rem' }}
          icon={<FontAwesomeIcon icon={['fa', 'user']} />}
        />
      </Flex>

      <Divider orientation="left" orientationMargin="0">
        <Text strong>{i18n.t('personalInfo', scopeI18n)}:</Text>
      </Divider>

      <Row>
        <Col span={8} md={7} offset={1}>
          <Text strong>{i18n.t('nationalNid', scopeI18n)}:</Text>
        </Col>
        <Col span={15} md={15}>
          {nationalNid}
        </Col>
      </Row>
      <Row>
        <Col span={8} md={7} offset={1}>
          <Text strong>{i18n.t('name', scopeI18n)}:</Text>
        </Col>
        <Col span={15} md={15}>{`${name} ${lastName || ''}`}</Col>
      </Row>
      <Row>
        <Col span={7} md={7} offset={1}>
          <Text strong>{i18n.t('role', scopeI18n)}:</Text>
        </Col>
        <Col span={16} md={16}>
          {orgRoles?.map((role) => (
            <Tag key={role.id}>{role.name}</Tag>
          ))}
        </Col>
      </Row>

      <Divider orientation="left" orientationMargin="0">
        <Text strong>{i18n.t('additionalInformation', scopeI18n)}</Text>
      </Divider>

      <Row>
        <Col span={7} md={7} offset={1}>
          <Text strong>{i18n.t('phoneNumber', scopeI18n)}:</Text>
        </Col>
        <Col span={16} md={16}>
          {phone}
        </Col>
      </Row>
      <Row>
        <Col span={7} md={7} offset={1}>
          <Text strong>{i18n.t('email', scopeI18n)}:</Text>
        </Col>
        <Col span={16} md={16}>
          {email}
        </Col>
      </Row>
      <Row>
        <Col span={7} md={7} offset={1}>
          <Text strong>{i18n.t('selectedOrganization', scopeI18n)}:</Text>
        </Col>
        <Col span={16} md={16}>
          {organization?.name}
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={7} md={7} offset={1}>
          <Text strong>{i18n.t('status', scopeI18n)}:</Text>
        </Col>
        <Col span={16} md={16}>
          {i18n.t(isInactive ? 'inactive' : 'active', scopeI18n)}
        </Col>
      </Row>
      <Row>
        <Col span={7} md={7} offset={1}>
          <Text strong>{i18n.t('lastLogin', scopeI18n)}:</Text>
        </Col>
        <Col span={16} md={16}>
          {lastLogin ? convertDateToDayjs(lastLogin, tzOrganization, 'DD-MM-YYYY HH:mm') : ''}
        </Col>
      </Row>
    </>
  );
}

UsersShow.propTypes = {
  user: UserPropTypes,
};

UsersShow.defaultProps = {
  user: {},
};
export default UsersShow;
