import apiSlice from 'app/api/apiSlice';

/**
 * routingSetsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const routingSetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoutingSets: builder.query({
      query: () => '/routing-sets',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'RoutingSets', id })),
              { type: 'RoutingSets', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'RoutingSets', id: 'PARTIAL-LIST' }],
    }),
    getFullRoutingSets: builder.query({
      query: () => '/routing-sets?paginated=false',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'RoutingSets', id })),
              { type: 'RoutingSets', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'RoutingSets', id: 'PARTIAL-LIST' }],
    }),
    getRoutingSetsByFilters: builder.mutation({
      query: (query) => `/routing-sets${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'RoutingSets', id })),
              { type: 'RoutingSets', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'RoutingSets', id: 'PARTIAL-LIST' }],
    }),
    getRoutingSet: builder.query({
      query: (id) => `/routing-sets/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'RoutingSets', id }],
    }),
    getRoutingSetsJobs: builder.query({
      query: () => '/routing-sets/jobs',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'RoutingSets', id })),
              { type: 'RoutingSets', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'RoutingSets', id: 'PARTIAL-LIST' }],
    }),
    getRoutingSetsJobsPage: builder.mutation({
      query: (query) => `/routing-sets/jobs${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'RoutingSets', id })),
              { type: 'RoutingSets', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'RoutingSets', id: 'PARTIAL-LIST' }],
    }),
    deleteRoutingSet: builder.mutation({
      query: (routingSetId) => ({
        url: `/routing-sets/change-status/${routingSetId}`,
        method: 'POST',
      }),
      invalidatesTags: ['RoutingSets'],
    }),
    route: builder.mutation({
      query: (body) => ({
        url: `/routing-sets`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['RoutingSets'],
    }),
    getRoutingSetsJob: builder.query({
      query: (jobId) => `/routing-sets/jobs/${jobId}`,
      providesTags: (_result, _error, id) => [{ type: 'RoutingSets', id }],
    }),
  }),
});

export const {
  useGetRoutingSetsQuery,
  useGetFullRoutingSetsQuery,
  useGetRoutingSetQuery,
  useGetRoutingSetsJobsQuery,
  useGetRoutingSetsJobsPageMutation,
  useGetRoutingSetsJobQuery,
  useDeleteRoutingSetMutation,
  useRouteMutation,
  useGetRoutingSetsByFiltersMutation,
} = routingSetsApiSlice;
