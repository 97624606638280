import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Divider, Form, Input, Select, Space } from 'antd';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import TitleHelp from '../TitleHelp';
/**
 * Function to get a form item with a selector can add elements to select
 * @param { baseItems, label, required } props baseItems is an list of string
 * @returns form item with select
 */
function SelectPlusForm(props) {
  const {
    baseItems,
    label,
    required,
    spanLabel,
    spanWrapper,
    onChange,
    nameField,
    disabled,
    helpText,
  } = props;
  const [items, setItems] = useState([...baseItems]);
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const { message } = App.useApp();
  const form = Form.useFormInstance();

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    if (name) {
      // add item only if not exist
      if (!items.includes(name)) {
        setItems([...items, name]);
      }
      form?.setFieldValue(nameField, name);
      onChange(name);
      setOpen(false);
      setName('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      message.error(`Ingrese un texto para una nueva ${label.toLowerCase()}`);
    }
  };

  const ddRender = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: '8px 0',
        }}
      />
      <Space
        style={{
          padding: '0 8px 4px',
        }}
      >
        <Input
          placeholder={`Agregar ${label}`}
          ref={inputRef}
          value={name}
          onChange={onNameChange}
          maxLength={50}
          showCount
        />
        <Button type="primary" onClick={addItem}>
          <FontAwesomeIcon icon="plus" style={{ marginRight: '.5rem' }} />
          Agregar
        </Button>
      </Space>
    </>
  );

  return (
    <Form.Item
      labelCol={{
        span: spanLabel,
      }}
      wrapperCol={{
        span: spanWrapper,
      }}
      label={helpText ? <TitleHelp title={label} helpText={helpText} /> : label}
      name={nameField}
      required
      rules={[{ required: true }]}
    >
      <Select
        placeholder="Seleccione"
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        open={open}
        dropdownRender={(menu) => ddRender(menu)}
        options={items.map((item, index) => {
          const keyOption = `opt-${index}`;
          return {
            key: keyOption,
            label: item,
            value: item,
          };
        })}
        allowClear
        showSearch
        required={required}
        rules={[{ required }]}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
}
SelectPlusForm.defaultProps = {
  baseItems: [],
  label: 'Categoría',
  nameField: 'category',
  required: false,
  spanLabel: 24,
  spanWrapper: 24,
  onChange: () => {},
  disabled: false,
  helpText: undefined,
};
SelectPlusForm.propTypes = {
  baseItems: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  nameField: PropTypes.string,
  required: PropTypes.bool,
  spanLabel: PropTypes.number,
  spanWrapper: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
};
export default SelectPlusForm;
