import apiSlice from 'app/api/apiSlice';

/**
 * panelsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const panelsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategoriesPanels: builder.query({
      query: () => `/panels/get-categories`,
      providesTags: (_result, _error, id) => [{ type: 'CategoriesPanel', id }],
    }),
    getPanels: builder.query({
      query: () => '/panels',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Panels', id })) : ['Panels'],
    }),
    getPanel: builder.query({
      query: (id) => `/panels/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Panels', id }],
    }),
    getPanelsByFilters: builder.mutation({
      query: (query) => `/panels${query}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Panels', id })) : ['Panels'],
    }),
    deleteOrRestorePanel: builder.mutation({
      query: (panelId) => ({
        url: `panels/change-status/${panelId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Panels'],
    }),
    updatePanel: builder.mutation({
      query: ({ panelId, image, body }) => {
        const fmData = new FormData();
        fmData.append('image', image);
        fmData.append('body', JSON.stringify(body));
        return {
          url: `/panels/${panelId}`,
          method: 'PATCH',
          body: fmData,
        };
      },
      invalidatesTags: ['Panels'],
    }),
    createPanel: builder.mutation({
      query: ({ image, body }) => {
        const fmData = new FormData();
        fmData.append('image', image);
        fmData.append('body', JSON.stringify(body));
        return {
          url: '/panels',
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: ['Panels'],
    }),
  }),
});

export const {
  useGetPanelsQuery,
  useGetPanelQuery,
  useGetPanelsByFiltersMutation,
  useGetCategoriesPanelsQuery,
  useDeleteOrRestorePanelMutation,
  useCreatePanelMutation,
  useUpdatePanelMutation,
} = panelsApiSlice;
