import PropTypes from 'prop-types';

const CustomAttributesPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  dataType: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  customValue: PropTypes.shape({
    value: PropTypes.string,
  }),
});

export default CustomAttributesPropTypes;
