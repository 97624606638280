import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Col, Row, Tooltip } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import ButtonMenu from 'components/common/ButtonMenu';
import DrawerImporter from 'components/common/DrawerImporter';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { useGetSettingsQuery } from 'features/settings/settingsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import {
  useGetVehiclesQuery,
  useUploadEditVehiclesMutation,
  useUploadVehiclesMutation,
} from 'features/vehicles/vehiclesApiSlice';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { displayUnauthorizedNotification } from 'services/repeated-functions';
import Can from 'utils/Can';
import VehicleDrawer from '../Drawer';
import TableVehicles from './TableVehicles';

function VehiclesList() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { id: currOrgId, loadsUnit } = useSelector(selectCurrentOrganization);
  const { data: organization, isLoading: isLoadingOrgData } = useGetOrganizationQuery(currOrgId);
  const loadsFromOrg = organization?.loadsUnit || [];
  const [typeDrawer, setTypeDrawer] = useState('create');
  const [vehicle, setVehicle] = useState();
  const [dataFormated, setDataFormated] = useState([]);
  const [loading, setLoading] = useState(true);
  const [massiveUploadVisible, setMassiveUploadVisible] = useState(false);
  const [triggerClean, setTriggerClean] = useState(false);
  const [downloadQuery, setDownloadQuery] = useState('');
  const [actualPage, setActualPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [drawerUpdateVisible, setDrawerUpdateVisible] = useState(false);
  const { notification } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const ref = useRef(null);
  const { addReference } = useContext(TourContext);
  addReference('buttonCreateVehicleRef', ref);

  // custom hooks
  const {
    data: vehiclesData,
    isLoading,
    refetch,
    error: fetchError,
  } = useGetVehiclesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const error403 = fetchError?.status === 403;
  const navigate = useNavigate();

  useEffect(() => {
    if (error403) {
      navigate('/configurations/unauthorized');
      displayUnauthorizedNotification(notification);
    }
  }, [error403, navigate, notification]);

  const breadcrumbItems = [
    {
      key: 'configurations',
      url: '/configurations',
      label: i18n.t('sidebar.settings'),
    },
    {
      key: 'fleets',
      url: '/configurations',
      label: i18n.t('modules.fleet'),
    },
  ];

  // load settings for default action
  const { data: settingsFields, isLoading: isLoadingSetting } = useGetSettingsQuery(
    'GENERALROUTING?format=false'
  );
  const [uploadVehicles] = useUploadVehiclesMutation();
  const [uploadEditVehicles] = useUploadEditVehiclesMutation();

  const setDrawerVehicle = (boolValue) => {
    setDrawerVisible(boolValue);
    if (!boolValue) {
      setVehicle();
    }
  };

  const setDrawerData = (objectDrawer) => {
    const { drawerType, drawerVisibility, drawerVehicle } = objectDrawer;
    setTypeDrawer(drawerType);
    setDrawerVisible(drawerVisibility);
    setVehicle(drawerVehicle);
  };

  const showDrawer = (event) => {
    if (event.key === 'manual') {
      setDrawerVisible(true);
      setTypeDrawer('create');
    } else {
      setMassiveUploadVisible(true);
    }
  };

  useEffect(() => {
    if (vehiclesData) {
      const { data: dataVehicles, total } = vehiclesData;
      setTotalData(total);
      setDataFormated(dataVehicles);
    }

    setLoading(isLoading);
    refetch();
  }, [vehiclesData, isLoading, refetch]);

  return (
    !isLoadingOrgData && (
      <>
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        <Row gutter={24} style={{ height: '100%', marginBottom: '1rem' }}>
          <Col flex="auto" style={{ textAlign: 'right' }}>
            <Can I="update" a="fleets">
              <Tooltip placement="left" title={i18n.t('download.updateMassive')}>
                <Button
                  style={{ marginRight: '.5rem' }}
                  onClick={() => setDrawerUpdateVisible(!drawerUpdateVisible)}
                >
                  <FontAwesomeIcon icon={['fas', 'file-import']} />
                </Button>
              </Tooltip>
            </Can>
            <Can I="create" a="fleets">
              <ButtonMenu onClickEvent={showDrawer} refElement={ref} />
            </Can>
          </Col>
        </Row>
        <TableVehicles
          setDrawerData={setDrawerData}
          loading={loading}
          data={dataFormated}
          setDataCallback={setDataFormated}
          loadsFromOrg={loadsFromOrg}
          triggerClean={triggerClean}
          setTriggerClean={setTriggerClean}
          paginationData={{
            setTotalData,
            totalData,
            setActualPage,
            actualPage,
            downloadQuery,
            setDownloadQuery,
          }}
        />
        <VehicleDrawer
          drawerVisible={drawerVisible}
          setDrawerVehicle={setDrawerVehicle}
          typeDrawer={typeDrawer}
          vehicle={vehicle}
          loadsFromOrg={loadsFromOrg}
          vehiclesData={vehiclesData?.data}
          setTriggerClean={setTriggerClean}
        />
        {!isLoadingSetting && (
          <DrawerImporter
            key="importer-massive-vehicles"
            open={massiveUploadVisible}
            setVisible={setMassiveUploadVisible}
            reduxFunc={uploadVehicles}
            typeUpload="vehicles"
            settingsFields={settingsFields}
            loadsUnit={loadsUnit}
          />
        )}
        {!isLoadingSetting && (
          <DrawerImporter
            key="importer-massive-vehicles-edit"
            open={drawerUpdateVisible}
            setVisible={setDrawerUpdateVisible}
            reduxFunc={uploadEditVehicles}
            typeUpload="vehicles-edit"
            settingsFields={settingsFields}
            loadsUnit={loadsUnit}
          />
        )}
      </>
    )
  );
}
export default VehiclesList;
