import { App, Button, Col, Divider, Result, Row } from 'antd';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import { getFileForImporter } from 'components/common/DrawerImporter/drawer-functions';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import data from '../data.json';
import RequirementsAdvanced from './advanced/requirements';
import HowToImport from './simple/howToImport';
import RequirementsSimple from './simple/requirements';

import StructureExistingVisits from './advanced/structureExistingVisits';
import StructureNewVisits from './advanced/structureNewVisits';
import '../style.css';

function HelpDetail() {
  const { id } = useParams();
  const { message } = App.useApp();
  const { loadsUnit } = useSelector(selectCurrentOrganization);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'help' };
  const fileExportScopeI18n = { scope: 'form.demandsImport' };
  const origin = searchParams.get('origin');
  const detail = data.find(({ key }) => key === id);

  const downloadExampleFile = (type) => {
    // To export file we need File Format, org Loads and file type (simple or advanced)
    const fileFormat = { key: 'csv' };
    const link = document.createElement('a');
    const { file, download } = getFileForImporter(fileFormat, loadsUnit, type);
    if (file && download) {
      link.href = file;
      link.download = download;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log(
        `No file found for fileType: ${fileFormat.key} load: ${loadsUnit.length} typeLoad: ${typeUpload}`
      );
      message.error(i18n.t('errors.notFound', fileExportScopeI18n));
    }
  };

  const goToHelp = () => {
    navigate('/help');
  };

  // back to importer
  const goToModule = () => {
    let originModule = '/planning';
    switch (origin) {
      case 'advanced':
      case 'simple':
      case 'super-advanced':
        originModule = '/planning';
        break;
      case 'STOP':
      case 'STOP-edit':
        originModule = '/locations';
        break;
      case 'DEPOT':
      case 'DEPOT-edit':
        originModule = '/locations/base'; // si solo tiene base, debería ser /locations
        break;
      case 'vehicles':
      case 'vehicles-edit':
        originModule = '/configurations';
        break;
      default:
        originModule = '/planning';
        break;
    }
    navigate(originModule);
  };

  const buttonBack = (size = 'large') => (
    <Row>
      <Col md={{ span: 16, offset: 4 }} xs={{ span: 24 }}>
        <Divider />
        <Button
          size={size}
          type="primary"
          onClick={origin ? goToModule : goToHelp}
          title={i18n.t('back', scopeI18n)}
          className="help-back-button"
        >
          {i18n.t('back', scopeI18n)}
        </Button>
      </Col>
    </Row>
  );

  const breadcrumbItems = [
    {
      key: 'help',
      url: '/help',
      label: i18n.t('help.all'),
    },
    {
      key: 'index',
      url: '/help',
      label: detail?.title,
    },
  ];

  const helpBreadcrumbs = () => {
    return (
      <Row>
        <Col md={{ span: 16, offset: 4 }} xs={{ span: 24 }}>
          <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
        </Col>
      </Row>
    );
  };

  /**
   * Same componente for simple and advanced import
   * @returns visual component for the section
   */
  const howToImportComponent = () => {
    return (
      <>
        <HowToImport downloadExampleFile={downloadExampleFile} helpBreadcrumbs={helpBreadcrumbs} />
        {buttonBack('normal')}
      </>
    );
  };

  /**
   * Component for simple demands import requirements
   * @returns visual component for the section
   */
  const simpleRequirementsComponent = () => {
    return (
      <>
        <RequirementsSimple
          downloadExampleFile={downloadExampleFile}
          helpBreadcrumbs={helpBreadcrumbs}
        />
        {buttonBack('normal')}
      </>
    );
  };

  /**
   * Component for advaced demands import requirements
   * @returns visual component for the section
   */
  const advancedRequirementsComponent = () => {
    return (
      <>
        <RequirementsAdvanced
          downloadExampleFile={downloadExampleFile}
          helpBreadcrumbs={helpBreadcrumbs}
        />
        {buttonBack('normal')}
      </>
    );
  };

  /**
   * Component for structure for advanced imports with existing visits
   * @returns visual component for the section
   */
  const advancedSEVComponent = () => {
    return (
      <>
        <StructureExistingVisits
          downloadExampleFile={downloadExampleFile}
          helpBreadcrumbs={helpBreadcrumbs}
        />
        {buttonBack('normal')}
      </>
    );
  };

  /**
   * Component for structure for advanced imports with new visits
   * @returns visual component for the section
   */
  const advancedSNVComponent = () => {
    return (
      <>
        <StructureNewVisits
          downloadExampleFile={downloadExampleFile}
          helpBreadcrumbs={helpBreadcrumbs}
        />
        {buttonBack('normal')}
      </>
    );
  };

  /**
   * This component is temporal. When advanded import section is implemented this component probably will be removed
   * Or probably replaced with the component to show 404 error
   */
  const defaultComponent = () => {
    const title = i18n.t('title.error404', { scope: 'errorBoundary' });
    const subTitle = i18n.t('cannotFoundDocument', scopeI18n);
    return (
      <Row gutter={[12, 12]}>
        <Col xs={{ span: 24 }} md={{ span: 16, offset: 4 }}>
          <Result
            status={404}
            title={title}
            subTitle={subTitle}
            className="result-help"
            extra={buttonBack()}
          />
        </Col>
      </Row>
    );
  };

  switch (id) {
    case 'how-import-simple':
    case 'how-import-demands':
      return howToImportComponent();
    case 'format-requirements-simple':
      return simpleRequirementsComponent();
    case 'format-requirements-demands':
      return advancedRequirementsComponent();
    case 'how-structure-data-exisisting-visits':
      return advancedSEVComponent();
    case 'what-structure-data-new-visits':
      return advancedSNVComponent();
    default:
      return defaultComponent();
  }
}

export default HelpDetail;
