import { Row } from 'antd';
import { useGetRoutingSetQuery } from 'features/routingSets/routingSetsApiSlice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import FollowingLeftComponent from '../LeftComponent';
import FollowingRightComponent from '../RightComponent';
import { VehiclePositionPropTypes } from '../../Vehicles/vehicles.propTypes';

function FollowingRoutingSet(props) {
  const { isConnected, vehiclesPositionEvents } = props;
  const { id } = useParams();
  const [initialRoutes, setInitialRoutes] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [offlineMode, setOfflineMode] = useState(false);
  // custom hooks
  const { data: routingSetData, isLoading } = useGetRoutingSetQuery(id, {
    skip: id === undefined,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (routingSetData) {
      setAllRoutes(routingSetData.routes);
      setInitialRoutes(routingSetData.routes);
      let allItemsLoaded = [];
      routingSetData.routes.forEach((route) => {
        allItemsLoaded = [...allItemsLoaded, ...route.items];
      });
      setAllItems(allItemsLoaded);
    }
  }, [routingSetData, isLoading]);

  return (
    <Row gutter={24}>
      <FollowingLeftComponent allRoutes={allRoutes} allItems={allItems} offlineMode={offlineMode} />
      <FollowingRightComponent
        allRoutes={allRoutes}
        routingSet={routingSetData}
        setAllRoutes={setAllRoutes}
        setAllItems={setAllItems}
        initialRoutes={initialRoutes}
        setOfflineMode={setOfflineMode}
        offlineMode={offlineMode}
        isConnected={isConnected}
        vehiclesPositionEvents={vehiclesPositionEvents}
      />
    </Row>
  );
}

FollowingRoutingSet.defaultProps = {
  isConnected: false,
  vehiclesPositionEvents: [],
};

FollowingRoutingSet.propTypes = {
  isConnected: PropTypes.bool,
  vehiclesPositionEvents: PropTypes.arrayOf(VehiclePositionPropTypes),
};

export default FollowingRoutingSet;
