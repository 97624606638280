import {
  App,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  Typography,
} from 'antd';
import SubmitButton from 'components/common/SubmitButton';
import TitleHelp from 'components/common/TitleHelp';
import { useUpdateOrganizationMutation } from 'features/organizations/organizationsApiSlice';
import {
  useCreateSettingMutation,
  useGetSettingsQuery,
  useUpdateSettingMutation,
} from 'features/settings/settingsApiSlice';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';

const { Text, Title } = Typography;

function IntegrationsSms(props) {
  const { organizationId, goToNextStep, submitButton, current } = props;
  const type = 'SMS';
  const url = `${type}?format=false${organizationId ? `&organizationId=${organizationId}` : ''}`;
  const { data: settingsFields, isLoading, isFetching, refetch } = useGetSettingsQuery(url);
  const [updateSettingSms, { isLoading: updatingData }] = useUpdateSettingMutation();
  const [createSettingSms, { isLoading: savingData }] = useCreateSettingMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const ability = useContext(CaslContext);
  const canSave = checkPermissionForSubject(ability, 'update', 'communication');

  const valuesFromDb = {};
  if (!isFetching && settingsFields.length > 0) {
    const fields = settingsFields[0].keyValue;
    fields.forEach((field) => {
      Object.keys(field).forEach((nameField) => {
        const valueField = field[nameField];
        valuesFromDb[nameField] = valueField;
      });
    });
    // assign id
    valuesFromDb.id = settingsFields[0]?.id;
  }
  const defaultValues = {
    enableSms: false,
    enableFollowingLink: false,
    enableRatingLink: false,
  };
  const initialValues = { ...defaultValues, ...valuesFromDb };

  const onFinish = async (values) => {
    try {
      // get fields only with data
      const fieldsWithValues = Object.keys(values).filter((key) => {
        return values[key] !== undefined;
      });
      // build format to save
      const allFields = fieldsWithValues.map((field) => {
        const value = values[field];
        return { [field]: value };
      });
      const endObject = {
        keyValue: allFields,
        ...(organizationId && { organizationId }),
      };
      if (initialValues.id) {
        await updateSettingSms({ ...endObject, id: initialValues.id }).unwrap();
        message.success('Configuración de SMS actualizada correctamente');
      } else {
        await createSettingSms({ ...endObject, type }).unwrap();
        message.success('Configuración de SMS creada correctamente');
      }
      if (goToNextStep) {
        await updateOrganization({
          organizationId,
          body: { creationStep: current, creationStatus: 'COMPLETED' },
        })
          .then(() => {
            const urlRedirect = '/organizations';
            navigate(urlRedirect);
          })
          .catch((error) => {
            console.error(error);
            message.error('Error al guardar el formulario');
          });
      }
      // get value reloaded
      refetch();
    } catch (error) {
      console.error(error);
      message.error('Configuración de SMS no pudo ser actualizada');
    }
  };

  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  if (isLoading) return <Skeleton paragraph={{ rows: 10 }} />;

  return (
    <>
      <Title level={3}>SMS</Title>
      <Form
        form={form}
        initialValues={initialValues}
        name="integrationsSms"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        disabled={!canSave}
      >
        <Row justify="start" gutter={16}>
          <Col span={12}>
            <Form.Item
              name="enableSms"
              label={
                <TitleHelp
                  title="Activar envío de SMS"
                  helpText="Permite habilitar el uso de la API para la organización"
                />
              }
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" orientationMargin="0">
          <Text strong>Credenciales</Text>
        </Divider>
        <Row justify="start" gutter={16}>
          <Col span={6}>
            <Form.Item
              label={
                <TitleHelp
                  title="URL con link"
                  helpText="Endpoint para el envío de SMS por servicio externo con links de seguimiento y evaluación"
                />
              }
              name="withUrl"
              rules={[
                {
                  type: 'url',
                },
                {
                  type: 'string',
                  min: 6,
                },
              ]}
            >
              <Input placeholder="https://sample-endpoint.com/with-url" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <TitleHelp
                  title="URL sin link"
                  helpText="Endpoint para el envío de SMS por servicio externo sin links de seguimiento y evaluación"
                />
              }
              name="withoutUrl"
              rules={[
                {
                  type: 'url',
                },
                {
                  type: 'string',
                  min: 6,
                },
              ]}
            >
              <Input placeholder="https://sample-endpoint.com/without-url" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={16}>
          <Col span={6}>
            <Form.Item
              label={<TitleHelp title="Usuario" helpText="Usuario de conexión" />}
              name="user"
            >
              <Input placeholder="Usuario" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={<TitleHelp title="Contraseña" helpText="Contraseña de conexión" />}
              name="password"
            >
              <Input.Password placeholder="Contraseña" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={16}>
          <Col span={12}>
            <Form.Item
              label={<TitleHelp title="Token" helpText="Validador de conexión" />}
              name="token"
            >
              <Input placeholder="Token" />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" orientationMargin="0">
          <Text strong>Followers</Text>
        </Divider>
        <Row justify="start" gutter={16}>
          <Col span={6}>
            <Form.Item
              name="enableFollowingLink"
              label={
                <TitleHelp
                  title="Habilitar link de seguimiento"
                  helpText="Permite habilitar link de seguimiento en SMS"
                />
              }
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="enableRatingLink"
              label={
                <TitleHelp
                  title="Habilitar link de Evaluación"
                  helpText="Permite habilitar link de evaluación en SMS"
                />
              }
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={16}>
          <Col span={6}>
            <Form.Item
              label={
                <TitleHelp
                  title="Tipo de remitente"
                  helpText="Permite definir el tipo de número que se utilizará como remitente del SMS"
                />
              }
              name="serviceType"
            >
              <Select
                options={[
                  { key: 'short', value: 'shortNumber', label: 'Número corto' },
                  { key: 'long', value: 'longNumber', label: 'Número largo' },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Space>
              {canSave && submitButton && (
                <SubmitButton isLoading={savingData} submitButtonText="Finalizar" />
              )}
              {canSave && !submitButton && (
                <Button type="primary" htmlType="submit" loading={savingData || updatingData}>
                  Guardar
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
}

IntegrationsSms.defaultProps = {
  organizationId: null,
  goToNextStep: false,
  submitButton: false,
  current: null,
};
IntegrationsSms.propTypes = {
  organizationId: PropTypes.number,
  goToNextStep: PropTypes.bool,
  submitButton: PropTypes.bool,
  current: PropTypes.number,
};

export default IntegrationsSms;
