import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import IntegrationsSms from '../../Configurations/Integrations/Sms';

function OrganizationsCreationStepsSms(props) {
  const { organizationId, isLoading, current } = props;
  return isLoading ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <IntegrationsSms organizationId={organizationId} goToNextStep submitButton current={current} />
  );
}

OrganizationsCreationStepsSms.defaultProps = {
  organizationId: null,
  isLoading: false,
  current: null,
};
OrganizationsCreationStepsSms.propTypes = {
  organizationId: PropTypes.number,
  isLoading: PropTypes.bool,
  current: PropTypes.number,
};

export default OrganizationsCreationStepsSms;
