import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function drawRouteBackground(startsAt, endsAt, index, color, startTime, spaceDefinitions) {
  const {
    pxRatio,
    hSpaceDrawBorder,
    vRouteCenter,
    vSpaceRoute,
    routeLineThickness,
    defaultStrokeWidth,
  } = spaceDefinitions;
  const startRoute = new Date(startsAt);
  const endRoute = new Date(endsAt);

  const initDiff = (startRoute - startTime) / 60000;
  const endDiff = (endRoute - startRoute) / 60000;

  return (
    <rect
      x={initDiff * pxRatio + hSpaceDrawBorder}
      y={vRouteCenter + index * vSpaceRoute - 1}
      rx={4}
      ry={4}
      width={endDiff * pxRatio}
      height={routeLineThickness}
      fill={color}
      stroke={color}
      strokeWidth={defaultStrokeWidth}
    />
  );
}

const depotIcon = (xValue, yValue, wValue, hValue, color) => {
  return (
    <foreignObject x={xValue} y={yValue} width={wValue} height={hValue}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <FontAwesomeIcon icon={['fas', 'house-chimney']} color={color} style={{ height: '65%' }} />
      </div>
    </foreignObject>
  );
};

const elementIcon = (xValue, yValue, element) => {
  return (
    <text
      x={xValue}
      y={yValue}
      dominantBaseline="middle"
      textAnchor="middle"
      fontWeight="bold"
      fill="white"
      fontSize={15}
    >
      {element}
    </text>
  );
};

const parkIcon = (xValue, yValue) => {
  return (
    <text
      x={xValue}
      y={yValue}
      dominantBaseline="middle"
      textAnchor="middle"
      fontWeight="bold"
      fill="white"
      fontSize={20}
    >
      P
    </text>
  );
};

const restIcon = (xValue, yValue, wValue, hValue, color) => {
  return (
    <foreignObject x={xValue} y={yValue} width={wValue} height={hValue}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <FontAwesomeIcon icon={['fas', 'burger']} color={color} style={{ height: '65%' }} />
      </div>
    </foreignObject>
  );
};

/**
 * Function that darken a hsla color by a percentage, 35 by default
 * @param {*} color
 * @param {*} percentage
 * @returns hsla definition
 */
const darkenColor = (color, percentage = 35) => {
  // Parse the HSL color
  const [hue, saturation, lightness, alpha] = color
    .replace(/[hsla()%]/g, '') // Remove unnecessary characters
    .split(',')
    .map(parseFloat);
  // Calculate the new lightness value based on the percentage of the current lightness
  const newLightness = Math.max(lightness - (lightness * percentage) / 100, 0);
  // Return the updated color in HSL format
  return `hsla(${hue}, ${saturation}%, ${newLightness}%, ${alpha})`;
};

export { drawRouteBackground, depotIcon, elementIcon, darkenColor, parkIcon, restIcon };
