import { Button, Col, Divider, Row, Space, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Popup } from 'react-leaflet';
import { renderAddress } from 'services/repeated-functions';
import { pointShapePropTypes } from './LeafletMap.propType';

const { Text } = Typography;

function PopOverComponent(props) {
  const { drawablesRows, setSelectedPoint } = props;
  const { key: locationId, Nombre, Cod, address } = drawablesRows;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const onDelete = (e) => {
    e.preventDefault();
    setSelectedPoint({ ...drawablesRows, action: 'delete' });
  };
  const onEdit = (e) => {
    e.preventDefault();
    setSelectedPoint({ ...drawablesRows, action: 'edit' });
  };
  const popupText = drawablesRows?.deletedAt
    ? i18n.t('commons.unarchive')
    : i18n.t('commons.archive');

  return (
    <Popup key={locationId}>
      <div className="popover-content-container">
        <Row>
          <Col span={24}>
            <Text strong>{Nombre}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text>{Cod}</Text>
          </Col>
        </Row>
        <Divider style={{ margin: '6px 0' }} />
        <Row>
          <Col span={24}>{renderAddress(address)}</Col>
        </Row>
        <Row justify="end" style={{ marginTop: '15px' }}>
          <Col>
            <Space>
              <Button
                onClick={onDelete}
                type="primary"
                size="small"
                danger={drawablesRows?.deletedAt === null}
              >
                {popupText}
              </Button>
              {drawablesRows?.deletedAt === null && (
                <Button id="saveButton" type="primary" size="small" onClick={onEdit}>
                  Editar
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    </Popup>
  );
}

PopOverComponent.defaultProps = {
  drawablesRows: {},
  setSelectedPoint: () => {},
};

PopOverComponent.propTypes = {
  drawablesRows: pointShapePropTypes,
  setSelectedPoint: PropTypes.func,
};

export default PopOverComponent;
