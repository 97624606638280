import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext } from 'react';

function EmailsForm() {
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.location' };
  return (
    <Form.List name="emails">
      {(fields, { add, remove }, { errors }) => (
        <Space
          direction="vertical"
          style={{
            display: 'flex',
          }}
        >
          <Row justify="end">
            <Col span={24}>
              <Form.ErrorList errors={errors} />
            </Col>
          </Row>
          {fields.map(({ key, name, ...restField }, loadIndex) => {
            const { isListField } = restField;
            return (
              <Row justify="space-between" align="middle" key={key}>
                <Col span={20}>
                  <Form.Item
                    isListField={isListField}
                    label={i18n.t('emailCount', {
                      ...scopeI18n,
                      count: loadIndex + 1,
                    })}
                    name={[name, 'email']}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(i18n.t('rules.email', scopeI18n)));
                        },
                      }),
                      {
                        type: 'email',
                        message: i18n.t('rules.emailInvalid', scopeI18n),
                      },
                    ]}
                  >
                    <Input placeholder={i18n.t('email', scopeI18n)} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <FontAwesomeIcon
                    className="dynamic-delete-button"
                    icon={['fas', 'circle-minus']}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
            );
          })}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block>
              {i18n.t('buttons.addEmail')}
            </Button>
          </Form.Item>
        </Space>
      )}
    </Form.List>
  );
}

export default EmailsForm;
