import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Divider, Row, Typography } from 'antd';
import LeafletMap from 'components/common/Map/LeafletMap';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';

const { Text } = Typography;

function EventMap(props) {
  const { itemCoords, eventCoords, eventId, changeVisible, distance } = props;
  const { latitude: itemLatitude, longitude: itemLongitude } = itemCoords;
  const { latitude: eventLatitude, longitude: eventLongitude } = eventCoords;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const coordsFixed = (coords) => {
    const { latitude, longitude } = coords;
    return `${parseFloat(`${latitude}`).toFixed(4)}, ${parseFloat(`${longitude}`).toFixed(4)}`;
  };
  const dataSource = [
    {
      key: 'item',
      lat: itemLatitude,
      lng: itemLongitude,
      type: 'item',
      title: i18n.t('coordsItem', scopeI18n),
    },
    {
      key: 'event',
      lat: parseFloat(eventLatitude),
      lng: parseFloat(eventLongitude),
      type: 'event',
      color: '#E30001',
      title: i18n.t('coordsEvent', scopeI18n),
    },
  ];
  const styleCard = { width: '100%', borderRadius: '0', height: 90 };
  return (
    <Row>
      <Col span={8}>
        <Card size="small" style={styleCard}>
          <span>
            <Text type="secondary">{i18n.t('itemCoord', scopeI18n)}</Text>
          </span>
          <br />
          <span>
            <FontAwesomeIcon icon="fa-regular fa-circle-dot" />
            {` ${coordsFixed(itemCoords)}`}
          </span>
        </Card>
      </Col>
      <Col span={8}>
        <Card size="small" style={styleCard}>
          <span>
            <Text type="secondary">{i18n.t('eventCoord', scopeI18n)}</Text>
          </span>
          <br />
          <span>
            <FontAwesomeIcon icon="fa-solid fa-location-dot" color="#E30001" />
            {` ${coordsFixed(eventCoords)}`}
          </span>
        </Card>
      </Col>
      <Col span={8}>
        <Card size="small" style={styleCard}>
          <span>
            <Text type="secondary">{i18n.t('difference', scopeI18n)}</Text>
          </span>
          <br />
          <span>{`${distance} mts`}</span>
        </Card>
      </Col>
      <Col span={24} style={{ height: 300, paddingTop: '1rem' }}>
        <LeafletMap
          key={`LeafletMap-${eventId}`}
          typeMarkers="eventReport"
          defaultCoords={[itemLatitude, itemLongitude]}
          zoom={16}
          dataSource={dataSource}
        />
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          size="small"
          onClick={() => changeVisible(false)}
          style={{ float: 'right' }}
        >
          {i18n.t('closeMap', scopeI18n)}
        </Button>
      </Col>
      <Divider style={{ margin: '.5rem 0' }} />
    </Row>
  );
}

EventMap.defaultProps = {
  itemCoords: {},
  eventCoords: {},
  eventId: undefined,
  changeVisible: () => {},
  distance: 0,
};

EventMap.propTypes = {
  itemCoords: PropTypes.shape({
    latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  eventCoords: PropTypes.shape({
    latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  eventId: PropTypes.number,
  changeVisible: PropTypes.func,
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EventMap;
