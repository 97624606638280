import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

function AddonAfterSelect(props) {
  const { nameField, isDisabled, options } = props;
  return (
    <Form.Item name={nameField} noStyle>
      <Select
        className="select-after"
        style={{ width: 100 }}
        disabled={isDisabled}
        options={options}
      />
    </Form.Item>
  );
}

AddonAfterSelect.defaultProps = {
  nameField: '',
  isDisabled: false,
  options: [
    { value: 'seconds', label: 'Segundos' },
    { value: 'minutes', label: 'Minutos' },
  ],
};

AddonAfterSelect.propTypes = {
  nameField: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ),
};

export default AddonAfterSelect;
