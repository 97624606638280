import { Button, Flex } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { ColorByOrder } from 'services/repeated-functions';

const SELECT_ALL = -1;

const NavRouteButtons = React.memo(function NavRouteButtons(props) {
  const { size, onClick, selectedRoute } = props;
  const handleOnClick = useCallback(
    (event, selected) => {
      event.preventDefault();
      onClick(selected);
    },
    [onClick]
  );
  return (
    <Flex
      gap="small"
      style={{
        marginBottom: '0.5rem',
        paddingBottom: '0.5rem',
        overflow: 'auto',
        whiteSpace: 'nowrap',
      }}
    >
      <Button
        key="Todo"
        type={selectedRoute === SELECT_ALL ? 'primary' : 'default'}
        onClick={(e) => handleOnClick(e, SELECT_ALL)}
      >
        Todo
      </Button>
      {[...Array(size)].map((_e, index) => {
        const childrenId = index + 1;
        return (
          <Button
            key={childrenId}
            type={selectedRoute === index ? 'primary' : 'default'}
            onClick={(event) => handleOnClick(event, index)}
            style={selectedRoute === index ? { backgroundColor: ColorByOrder(childrenId) } : {}}
          >
            {childrenId}
          </Button>
        );
      })}
    </Flex>
  );
});

NavRouteButtons.defaultProps = {
  onClick: () => {},
  size: 0,
  selectedRoute: undefined,
};

NavRouteButtons.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  selectedRoute: PropTypes.number,
};

export default NavRouteButtons;
