import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRoutingSetsJobQuery } from 'features/routingSets/routingSetsApiSlice';
import RouteJobContent from './RouteJobContent';

import './style.css';

function RouteJob() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [routeJob, setRouteJob] = useState();
  const [loading, setLoading] = useState(true);
  const {
    data: routingSetJobData,
    isLoading,
    refetch,
  } = useGetRoutingSetsJobQuery(id, {
    skip: id === undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setRouteJob(routingSetJobData);
        setLoading(isLoading);
      } catch (error) {
        console.error(error);
        navigate('/planning/jobs');
      }
    };

    fetchData();
    refetch();
  }, [navigate, id, routingSetJobData, isLoading, refetch]);

  return (
    <Skeleton active loading={loading}>
      <RouteJobContent routeJob={routeJob} />
    </Skeleton>
  );
}

export default RouteJob;
