import apiSlice from 'app/api/apiSlice';

/**
 * eventAttachmentsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const eventAttachmentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEventAttachmentByEvent: builder.query({
      query: (id) => `/event-attachments/by-event/${id}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'EventAttachments', id })) : ['EventAttachments'],
    }),
  }),
});

export const { useGetEventAttachmentByEventQuery } = eventAttachmentsApiSlice;
