import PropTypes from 'prop-types';

const AllReasonsPropTypes = PropTypes.shape({
  reasonsOnsuccess: PropTypes.arrayOf(PropTypes.string),
  reasonsOnpartial: PropTypes.arrayOf(PropTypes.string),
  reasonsOnfailed: PropTypes.arrayOf(PropTypes.string),
  reasonsOnpostponed: PropTypes.arrayOf(PropTypes.string),
});

const Options = PropTypes.shape({
  option: PropTypes.string,
});

const QuestionPropTypes = PropTypes.shape({
  nid: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  formOptions: PropTypes.arrayOf(Options),
  defaultOption: PropTypes.string,
  required: PropTypes.bool,
});

export const AllQuestionsPropTypes = PropTypes.shape({
  questionsOnsuccess: PropTypes.arrayOf(QuestionPropTypes),
  questionsOnpartial: PropTypes.arrayOf(QuestionPropTypes),
  questionsOnfailed: PropTypes.arrayOf(QuestionPropTypes),
});

export const OrgMobileFormInitialValues = PropTypes.shape({
  reportStates: PropTypes.arrayOf(PropTypes.string),
  typeFlow: PropTypes.string,
  typeReport: PropTypes.string,
  enableEditQuantityOnsuccess: PropTypes.string,
  enableEditQuantityOnpartial: PropTypes.string,
  enableEditQuantityOnfailed: PropTypes.string,
  enableEditQuantityOnpostponed: PropTypes.string,
  photoOnsuccess: PropTypes.bool,
  enableSignOnsuccess: PropTypes.bool,
  enableFormOnsuccess: PropTypes.bool,
  photoOnpartial: PropTypes.bool,
  enableSignOnpartial: PropTypes.bool,
  enableFormOnpartial: PropTypes.bool,
  photoOnfailed: PropTypes.bool,
  enableSignOnfailed: PropTypes.bool,
  enableFormOnfailed: PropTypes.bool,
  photoOnpostponed: PropTypes.bool,
  enableSignOnpostponed: PropTypes.bool,
  enableFormOnpostponed: PropTypes.bool,
  enableAlert: PropTypes.bool,
  forceReport: PropTypes.bool,
  radiousOutPosition: PropTypes.number,
  acceptableDelay: PropTypes.number,
  enableNotification: PropTypes.bool,
  enableOrder: PropTypes.bool,
  enableCancelOrder: PropTypes.bool,
  enableLimitCancelOrder: PropTypes.bool,
  reasonRequiredOnsuccess: PropTypes.bool,
  enableCommentOnsuccess: PropTypes.bool,
  reasonsOnsuccess: PropTypes.arrayOf(PropTypes.string),
  commentRequiredOnsuccess: PropTypes.bool,
  reasonRequiredOnpartial: PropTypes.bool,
  enableCommentOnpartial: PropTypes.bool,
  reasonsOnpartial: PropTypes.arrayOf(PropTypes.string),
  commentRequiredOnpartial: PropTypes.bool,
  reasonRequiredOnfailed: PropTypes.bool,
  enableCommentOnfailed: PropTypes.bool,
  reasonsOnfailed: PropTypes.arrayOf(PropTypes.string),
  commentRequiredOnfailed: PropTypes.bool,
  reasonRequiredOnpostponed: PropTypes.bool,
  enableCommentOnpostponed: PropTypes.bool,
  reasonsOnpostponed: PropTypes.arrayOf(PropTypes.string),
  commentRequiredOnpostponed: PropTypes.bool,
});

export default AllReasonsPropTypes;
