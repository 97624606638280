import PropTypes from 'prop-types';

const DocumentsPropTypes = PropTypes.shape({
  id: PropTypes.number,
  nid: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  module: PropTypes.string,
  status: PropTypes.string,
  fileUrl: PropTypes.string,
  created_at: PropTypes.string,
});

export default DocumentsPropTypes;
