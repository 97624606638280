import {
  App,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Radio,
  Row,
  Space,
  Switch,
  Tabs,
  Typography,
} from 'antd';
import AddonAfterSelect from 'components/common/AddonAfterSelect';
import SubmitButton from 'components/common/SubmitButton';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { checkPermissionForSubject, defineLabelByStatus } from 'services/repeated-functions';
import { formType } from 'types';
import CaslContext from 'utils/caslContext';
import MobileConfigurationDrawer from '../Drawer';
import MobileFormStatus from '../FormStatus';
import { OrgMobileFormInitialValues } from '../OrganizationMobile.propTypes';
import QuestionFormDrawer from '../QuestionFormDrawer';

const { Text } = Typography;

function FormBase(props) {
  const { form, initialValues, onFinish, savingData, submitButton } = props;
  const defaultStatus = 'success';
  const [useLimitCancelOrder, setUseLimitCancelOrder] = useState(false);
  const [activeKey, setActiveKey] = useState(defaultStatus);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [formDrawerVisible, setFormDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusReport, setStatusReport] = useState(defaultStatus);
  const [reportStatesEnabled, setReportStatesEnabled] = useState(initialValues.reportStates);
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);
  const allStatus = ['success', 'partial', 'failed', 'postponed'];
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.mobile' };
  // permissions
  const ability = useContext(CaslContext);
  const canUpdate = checkPermissionForSubject(ability, 'update', 'communication');
  const canUpdateOrg = checkPermissionForSubject(ability, 'update', 'organizations');
  const showExperimentalDevelop = process.env.REACT_APP_SHOW_EXPERIMENTAL_DEVELOP;

  const {
    reasonsOnsuccess,
    reasonsOnpartial,
    reasonsOnfailed,
    reasonsOnpostponed,
    enableLimitCancelOrder,
    questionsOnsuccess,
    questionsOnpartial,
    questionsOnfailed,
  } = initialValues;
  const [allReasons, setAllReasons] = useState({
    reasonsOnsuccess,
    reasonsOnpartial,
    reasonsOnfailed,
    reasonsOnpostponed,
  });
  const [allQuestions, setAllQuestions] = useState({
    questionsOnsuccess,
    questionsOnpartial,
    questionsOnfailed,
  });

  if (loading) {
    setUseLimitCancelOrder(enableLimitCancelOrder);
    setLoading(false);
  }

  const changeLimitCancelOrder = (newStatus) => {
    // clean related fields
    if (newStatus === false) {
      form.setFieldsValue({
        minutesLimitCancelOrder: undefined,
      });
    }
    setUseLimitCancelOrder(newStatus);
  };

  const onClickReportStates = (e) => {
    setReportStatesEnabled(e);
    setActiveKey(e[0]);
  };

  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  const setDrawerForReason = (statusVisible, statusReportClicked) => {
    setStatusReport(statusReportClicked);
    setDrawerVisible(statusVisible);
  };

  const setDrawerForQuestions = (statusVisible, statusReportClicked, questionIndex) => {
    setStatusReport(statusReportClicked);
    setFormDrawerVisible(statusVisible);
    setSelectedQuestion(questionIndex === undefined ? undefined : questionIndex);
  };

  const optionsTabs = allStatus.map((status) => {
    return {
      label: defineLabelByStatus(status, i18n),
      key: status,
      disabled: !reportStatesEnabled.includes(status),
      forceRender: true,
      children: (
        <MobileFormStatus
          status={status}
          quantityStates={reportStatesEnabled.length}
          setDrawerVisible={setDrawerForReason}
          setDrawerForFormVisible={setDrawerForQuestions}
          allReasons={allReasons}
          setAllReasons={setAllReasons}
          allQuestions={allQuestions}
          setAllQuestions={setAllQuestions}
          canUpdate={canUpdate}
        />
      ),
    };
  });

  const colOrder = (
    <Col span={24}>
      <Divider orientation="left" orientationMargin="0">
        <Text strong>Orden</Text>
      </Divider>
      <Row>
        <Col span={11}>
          <Form.Item
            name="enableCancelOrder"
            label={
              <TitleHelp
                title="Habilitar cancelación de orden"
                helpText="Permite al cliente cancelar su orden antes de la hora planificada de entrega"
              />
            }
            valuePropName="checked"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item
            name="enableLimitCancelOrder"
            label={
              <TitleHelp
                title="Umbrales de cancelación de orden"
                helpText="Tiempo máximo antes de la entrega que se permite al cliente cancelar su orden"
              />
            }
            valuePropName="checked"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Switch onChange={changeLimitCancelOrder} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item
            name="minutesLimitCancelOrder"
            rules={[
              {
                required: useLimitCancelOrder,
                message: 'Por favor ingrese los minutos',
              },
            ]}
            extra="Este campo tiene que contener número"
          >
            <InputNumber
              addonAfter="Minutos"
              min="0"
              max="60"
              disabled={!useLimitCancelOrder || !canUpdate}
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        name="organizationMobile"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        disabled={!canUpdate}
      >
        <Form.Item
          name="reportStates"
          label="Estados de reporte"
          rules={[
            {
              required: true,
              message: 'Por favor seleccione al menos un estado de reporte',
            },
          ]}
        >
          <Checkbox.Group onChange={onClickReportStates}>
            <Row>
              {allStatus.map((status) => {
                return (
                  <Col span={24} key={`col-${status}`}>
                    <Checkbox value={status}>{defineLabelByStatus(status, i18n)}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <div className="mobile-state-background">
          <Tabs
            className="state-container"
            activeKey={activeKey}
            tabBarGutter={5}
            onChange={(e) => {
              setActiveKey(e);
            }}
            type="card"
            items={optionsTabs}
          />
        </div>

        <Row>
          {/* Reportar */}
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0" className="divider-mobile">
              <Text strong>Reportar</Text>
            </Divider>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="typeReport"
                  label={
                    <TitleHelp
                      title="Reportar"
                      helpText="Múltiple agrupa por defecto paradas con misma ubicación"
                    />
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Row>
                      <Col span={24}>
                        <Radio value="multi">Multiple</Radio>
                      </Col>
                      <Col span={24}>
                        <Radio value="simple">Simple</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  name="recommendFinish"
                  label={
                    <TitleHelp
                      title={i18n.t('recommendFinish', scopeI18n)}
                      helpText={i18n.t('helps.recommendFinish', scopeI18n)}
                    />
                  }
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="forceReport"
                  label={
                    <TitleHelp
                      title="Forzar reporte"
                      helpText="Obliga al conductor a reportar todas las paradas"
                    />
                  }
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  name="enableOrder"
                  label={
                    <TitleHelp
                      title="Forzar orden"
                      helpText="Obliga al conductor a seguir el orden de la ruta"
                    />
                  }
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          {/* Comunicación */}
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text strong>Comunicación</Text>
            </Divider>

            <Row>
              <Col>
                <Form.Item
                  name="enableAlert"
                  label={
                    <TitleHelp
                      title="Alerta"
                      helpText="Habilita las alertas de ubicación y tiempo"
                    />
                  }
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="radiousOutPosition"
                  label={
                    <TitleHelp
                      title="Radio de reporte fuera de lugar"
                      helpText="Alerta al conductor cuando reporte llegada está lejos del punto"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message:
                        'Por favor ingresar cantidad de metros para el radio de reporte fuera de lugar',
                    },
                  ]}
                >
                  <InputNumber addonAfter="Metros" min="0" max="1000" />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  name="acceptableDelay"
                  label={
                    <TitleHelp
                      title="Tiempo aceptable de retraso"
                      helpText="Alerta al conductor cuando no ha iniciado ruta"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresar segundos aceptables de retraso',
                    },
                  ]}
                >
                  <InputNumber
                    addonAfter={
                      <AddonAfterSelect nameField="acceptableDelayUnit" isDisabled={false} />
                    }
                    min="0"
                    max="600"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  name="enableNotification"
                  label={
                    <TitleHelp
                      title="Notificaciones"
                      helpText="Habilita el envío de notificaciones al dispositivo"
                    />
                  }
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>{/* Orden */}</Row>
        {`${showExperimentalDevelop}` === 'true' ? colOrder : ''}
        <Row>
          {/* Flujo */}
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text strong>Flujo</Text>
            </Divider>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="minRouteStartDelayHours"
                  label={
                    <TitleHelp
                      title="Horas mínimo de visualización de rutas"
                      helpText="Horas mínimo de consulta de rutas hacia atrás"
                    />
                  }
                >
                  <InputNumber addonAfter="Horas" min="1" />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  name="maxRouteStartDelayHours"
                  label={
                    <TitleHelp
                      title="Horas máximo de visualización de rutas"
                      helpText="Horas máximo de consulta de rutas hacia adelante. Este parámetro afecta tanto como a rutas activas como a rutas futuras"
                    />
                  }
                >
                  <InputNumber addonAfter="Horas" min="1" max="170" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <Space>
              {/* is flow of creation */}
              {canUpdateOrg && submitButton && <SubmitButton isLoading={savingData} />}
              {/* is normal flow of edition */}
              {canUpdate && !submitButton && (
                <Button type="primary" htmlType="submit" loading={savingData}>
                  Guardar
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
      <MobileConfigurationDrawer
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerForReason}
        statusReport={statusReport}
        baseForm={form}
        setAllReasons={setAllReasons}
        allReasons={allReasons}
      />
      <QuestionFormDrawer
        drawerVisible={formDrawerVisible}
        setDrawerVisible={setDrawerForQuestions}
        statusReport={statusReport}
        baseForm={form}
        selectedQuestion={selectedQuestion}
        setAllQuestions={setAllQuestions}
        allQuestions={allQuestions}
      />
    </>
  );
}

FormBase.defaultProps = {
  form: {},
  initialValues: {},
  onFinish: () => {},
  savingData: false,
  submitButton: false,
};

FormBase.propTypes = {
  form: formType,
  initialValues: OrgMobileFormInitialValues,
  onFinish: PropTypes.func,
  savingData: PropTypes.bool,
  submitButton: PropTypes.bool,
};

export default FormBase;
