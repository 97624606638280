import { Button, Col, Dropdown, Radio, Row, Skeleton } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import EmptyContent from 'components/layout/EmptyContent';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import { Suspense, lazy, useContext, useRef, useState } from 'react';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import NoResponsiveComponent from '../layout/NoResponsiveContent';

function System() {
  const [defaultValue, setDefaultValue] = useState('general');
  const [content, setContent] = useState('general');
  const ability = useContext(CaslContext);
  const canReadDefinitions = checkPermissionForSubject(ability, 'read', 'definitions');
  const canReadSystem = checkPermissionForSubject(ability, 'create', 'system');
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'system' };

  const SystemFormComponent = lazy(() => import('./Form'));
  const DefinitionComponent = lazy(() => import('./Definitions'));
  const CustomAttributesComponent = lazy(() => import('./CustomAttributes'));

  const onChange = (obj) => {
    const selectedContent = obj?.target ? obj?.target?.value : obj.key;
    const selectedMenu = obj?.target ? obj?.target?.value : 'definitions';
    setContent(selectedContent);
    setDefaultValue(selectedMenu);
  };
  const breadcrumbItems = [
    {
      key: 'configurations',
      url: '/configurations',
      label: i18n.t('sidebar.settings'),
    },
    {
      key: 'system',
      url: '/configurations/system',
      label: i18n.t('modules.system'),
    },
  ];
  const radioOptions = [];

  if (canReadSystem) {
    radioOptions.push({
      label: i18n.t('general', scopeI18n),
      value: 'general',
    });
  }

  if (canReadDefinitions) {
    radioOptions.push({
      label: (
        <Dropdown
          menu={{
            items: [
              {
                key: 'restrictions',
                label: i18n.t('restrictions', scopeI18n),
              },
              {
                key: 'loads',
                label: i18n.t('loads', scopeI18n),
              },
              {
                key: 'trackpoints',
                label: i18n.t('trackpoints', scopeI18n),
              },
              {
                key: 'customAttributes',
                label: i18n.t('customAttributes', scopeI18n),
              },
              {
                key: 'routingServices',
                label: i18n.t('routingServices', scopeI18n),
              },
            ],
            onClick: onChange,
          }}
          placement="bottom"
        >
          <Button
            type="text"
            size="small"
            onClick={(e) => e.preventDefault()}
            className="dropdown-routing"
          >
            {i18n.t('definitions', scopeI18n)}
          </Button>
        </Dropdown>
      ),
      value: 'definitions',
    });
  }

  const ref = useRef(null);
  const refButtonSave = useRef(null);
  const { addReference } = useContext(TourContext);
  addReference('systemSubmenu', ref);
  addReference('buttonSaveSystem', refButtonSave);
  return (
    <>
      <Col xs={0} md={24}>
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        <Row gutter={[8, 32]}>
          <Col span={16}>
            <Radio.Group
              options={radioOptions}
              onChange={onChange}
              optionType="button"
              buttonStyle="solid"
              defaultValue={defaultValue}
              value={defaultValue}
              ref={ref}
            />
          </Col>
          <Col span={24}>
            <Suspense fallback={<Skeleton active paragraph={{ rows: 15 }} />}>
              {{
                general: <SystemFormComponent />,
                definitions: <DefinitionComponent type="RESTRICTION" />,
                restrictions: <DefinitionComponent type="RESTRICTION" />,
                loads: <DefinitionComponent type="LOAD" />,
                trackpoints: <DefinitionComponent type="TRACKPOINT" />,
                customAttributes: <CustomAttributesComponent />,
                routingServices: <DefinitionComponent type="ROUTING_SERVICE" />,
              }[content] || <EmptyContent />}
            </Suspense>
          </Col>
          {/* used to be override in system general form */}
          <Col span={24} ref={refButtonSave} />
        </Row>
      </Col>
      <Col xs={24} md={0}>
        <NoResponsiveComponent />
      </Col>
    </>
  );
}

export default System;
