import { App, Col, Divider, Form, Input, InputNumber, Row, Typography } from 'antd';
import CaFormField from 'components/common/CustomAttributes/FormField';
import CustomAttributesPropTypes from 'components/common/CustomAttributes/customAttributes.propTypes';
import DynamicFields from 'components/common/Form/DynamicFields';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentOrganization } from 'features/users/userSlice';
import {
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
} from 'features/vehicles/vehiclesApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  BuildCustomAttributes,
  BuildLoadInForm,
  convertDateToDayjs,
  getRestrictionsForInitialValues,
} from 'services/repeated-functions';
import { formType, loadsFromOrgType } from 'types';
import { VehiclePropTypes } from '../vehicles.propTypes';

const { Text } = Typography;

function VehicleForm(props) {
  const {
    onCloseDrawer,
    vehicle,
    loadsFromOrg,
    caeFields,
    form,
    setButtonLoading,
    drawerVisible,
    vehiclesData,
    setTriggerClean,
  } = props;
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.vehicles' };
  // custom hooks
  const [createVehicle] = useCreateVehicleMutation();
  const [updateVehicle] = useUpdateVehicleMutation();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);

  let initialValues = {};

  const { dfFormatted, namesAndTypesFormatted } = getRestrictionsForInitialValues(vehicle);

  const isFieldDuplicated = (value, type) => {
    const siblings = vehiclesData.filter((res) => res[type] !== vehicle?.[type]);
    return siblings.some((item) => item?.[type]?.toLowerCase() === value?.toLowerCase());
  };

  if (vehicle.id) {
    const { nid: nidForm, plate: plateForm, loads, customAttributes } = vehicle;
    const loadsFormatted = {};
    loadsFromOrg.forEach((loadOrg, index) => {
      const { key } = loadOrg;
      loadsFormatted[`loads[${key}]`] = loads[index]?.load;
    });

    const caFormatted = {};
    customAttributes.forEach((ca) => {
      const { customValue } = ca;
      if (ca.dataType === 'date') {
        caFormatted[`ca[${ca.id}]`] = customValue?.value
          ? convertDateToDayjs(customValue?.value, tzOrganization)
          : undefined;
      } else if (ca.dataType === 'boolean') {
        caFormatted[`ca[${ca.id}]`] = customValue?.value === 'true';
      } else {
        caFormatted[`ca[${ca.id}]`] = customValue?.value;
      }
    });

    initialValues = {
      nidForm,
      plateForm,
      ...loadsFormatted,
      ...caFormatted,
      ...dfFormatted,
      ...namesAndTypesFormatted,
    };
  } else {
    // load initial values by default in create
    caeFields.forEach((ca) => {
      const { defaultValue } = ca;
      if (ca.dataType === 'date') {
        initialValues[`ca[${ca.id}]`] = defaultValue
          ? convertDateToDayjs(defaultValue, tzOrganization)
          : undefined;
      } else if (ca.dataType === 'boolean') {
        initialValues[`ca[${ca.id}]`] = defaultValue === 'true';
      } else {
        initialValues[`ca[${ca.id}]`] = defaultValue || undefined;
      }
    });
  }

  // reset form only if drawer is displayed
  useEffect(() => {
    if (drawerVisible) {
      form.resetFields();
    }
  }, [form, drawerVisible]);

  const onFinish = async (values) => {
    setButtonLoading(true);
    form.validateFields();
    try {
      const loads = BuildLoadInForm(values);
      const caes = BuildCustomAttributes(values);
      const { nidForm: nid, plateForm: plate } = values;
      const body = {
        ...values,
        nid,
        plate,
        loads,
        customAttributeEntities: caes,
      };
      setTriggerClean(true);
      if (vehicle.id) {
        const { id: vehicleId } = vehicle;
        await updateVehicle({ vehicleId, body }).unwrap();
      } else {
        await createVehicle(body).unwrap();
      }
      message.success(i18n.t('form.success'));
      onCloseDrawer();
    } catch (error) {
      message.error(i18n.t('form.error'));
    }
    setTriggerClean(false);
    setButtonLoading(false);
  };
  const onFinishFailed = () => {
    message.error(i18n.t('form.checkForm'));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={{ required: i18n.t('fieldRequired', scopeI18n) }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={initialValues}
    >
      <Row gutter={24}>
        <Col span={12} md={{ span: 9, offset: 2 }}>
          <Form.Item name="restrictions" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label={i18n.t('nid', scopeI18n)}
            name="nidForm"
            rules={[
              { required: true, min: 2 },
              () => ({
                validator(_, value) {
                  if (isFieldDuplicated(value, 'nid')) {
                    return Promise.reject(
                      new Error(i18n.t('rules.nid', { ...scopeI18n, nid: value }))
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder={i18n.t('nid', scopeI18n)} />
          </Form.Item>
        </Col>
        <Col span={12} md={{ span: 9, offset: 2 }}>
          <Form.Item
            label={i18n.t('plate', scopeI18n)}
            name="plateForm"
            rules={[
              () => ({
                validator(_, value) {
                  if (isFieldDuplicated(value, 'plate')) {
                    return Promise.reject(
                      new Error(i18n.t('rules.plate', { ...scopeI18n, plate: value }))
                    );
                  }
                  return Promise.resolve();
                },
              }),
              { min: 4, required: true },
            ]}
          >
            <Input placeholder={i18n.t('plate', scopeI18n)} />
          </Form.Item>
        </Col>
        {loadsFromOrg.map((loadOrg, index) => {
          const { key, name } = loadOrg;
          return (
            <Col key={`ColLoad${key}`} span={12} md={{ span: 9, offset: 2 }}>
              <Form.Item
                name={`loads[${key}]`}
                label={name}
                required={index === 0}
                rules={[{ required: index === 0 }]}
              >
                <InputNumber className="load-input" min={0} placeholder={name} />
              </Form.Item>
            </Col>
          );
        })}
        <Col span={24} md={{ span: 20, offset: 2 }}>
          <DynamicFields type="VEHICLE" />
        </Col>
        {caeFields.length > 0 && (
          <Col span={24} md={{ span: 20, offset: 2 }}>
            <Divider orientation="left" orientationMargin="0">
              <Text strong>{i18n.t('additionalInformation', scopeI18n)}</Text>
            </Divider>
          </Col>
        )}
        {caeFields.map((caeField) => {
          const { id } = caeField;
          return (
            <Col key={`ColCa${id}`} span={12} md={{ span: 9, offset: 2 }}>
              <CaFormField customAttribute={caeField} form={form} />
            </Col>
          );
        })}
      </Row>
    </Form>
  );
}

VehicleForm.propTypes = {
  onCloseDrawer: PropTypes.func,
  vehicle: VehiclePropTypes,
  loadsFromOrg: loadsFromOrgType,
  caeFields: PropTypes.arrayOf(CustomAttributesPropTypes),
  form: formType,
  setButtonLoading: PropTypes.func,
  drawerVisible: PropTypes.bool,
  vehiclesData: PropTypes.arrayOf(VehiclePropTypes),
  setTriggerClean: PropTypes.func,
};

VehicleForm.defaultProps = {
  onCloseDrawer: () => {},
  vehicle: {},
  loadsFromOrg: [],
  caeFields: [],
  form: {},
  setButtonLoading: () => {},
  drawerVisible: false,
  vehiclesData: [],
  setTriggerClean: () => {},
};

export default VehicleForm;
