import { createContext } from 'react';

const PointsContext = createContext({
  mapPoints: [],
  setMapPoints: () => {},
  collapseDemandId: null,
  addrFounded: {},
  onDrag: () => {},
  setMoveToPosition: () => {},
});

export default PointsContext;
