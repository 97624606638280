import { Menu } from 'antd';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import './style.css';

// TODO: find a better way, search subpath to skip
const blacklistedSubmdules = ['demand-sets', 'panels', 'panel', 'route-detail'];

function SubNavBar(props) {
  const { menuItems } = props;
  const { pathname } = useLocation();
  const [module, submodule] = pathname.substring(1).split('/');
  const selectedKey =
    submodule && !blacklistedSubmdules.includes(submodule) ? `${module}/${submodule}` : module;

  //* you must add initial items as selected
  const initialKeysForModule = ['planning', 'following', 'configurations', 'locations', 'reports'];
  return (
    <Menu
      selectedKeys={selectedKey}
      mode="horizontal"
      className="subnavbar"
      items={menuItems}
      defaultSelectedKeys={initialKeysForModule}
    />
  );
}

SubNavBar.defaultProps = {
  menuItems: [],
};

SubNavBar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({ key: PropTypes.string }),
      key: PropTypes.string,
      label: PropTypes.shape({ key: PropTypes.string }),
    })
  ),
};

export default SubNavBar;
