import { Drawer, Form, Skeleton, Typography } from 'antd';
import DemandParametrizationForm from 'components/DemandParametrizationForm';
import FooterDrawer from 'components/common/FooterDrawer';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { useGetLabelsFromParametersQuery } from 'features/routingParameters/routingParametersApiSlice';
import { useGetSettingsQuery } from 'features/settings/settingsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const { Title } = Typography;

function DrawerRow(props) {
  const { demandId, demandName, visible, setVisible, organizationId } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [generalRoutingSetting, setGeneralRoutingSetting] = useState({});
  const [labels, setLabels] = useState([]);
  const [defaultAction, setDefaultAction] = useState();
  const [form] = Form.useForm();
  const organizationIdParam = organizationId ? `?organizationId=${organizationId}` : '';
  const { id: currOrgId } = useSelector(selectCurrentOrganization);
  const { data: organization, isLoading: isLoadingOrgData } = useGetOrganizationQuery(
    organizationId || currOrgId
  );
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  // load settings for general routing
  const { data: settingsFields, refetch } = useGetSettingsQuery('GENERALROUTING?format=false');
  const {
    data: labelsData,
    isLoading: labelsFetching,
    refetch: refetchLabels,
  } = useGetLabelsFromParametersQuery(organizationIdParam);

  useEffect(() => {
    if (!isLoadingOrgData) {
      setDefaultAction(organization?.defaultAction);
    }
  }, [isLoadingOrgData, organization]);

  useEffect(() => {
    if (!labelsFetching) {
      setLabels(labelsData);
    }
    refetchLabels();
  }, [labelsData, labelsFetching, refetchLabels]);

  useEffect(() => {
    const fetchData = async () => {
      if (settingsFields && settingsFields.length > 0) {
        const generalSettings = settingsFields
          .find(({ type }) => type === 'GENERALROUTING')
          ?.keyValue?.reduce((summ, setting) => {
            const [name, value] = Object.entries(setting)[0];
            return {
              ...summ,
              [name]: value,
            };
          }, {});
        setGeneralRoutingSetting(generalSettings);
      }
      setLoading(settingsFields === undefined);
    };
    fetchData();
    refetch();
  }, [settingsFields, refetch]);

  const onClose = (event) => {
    event.stopPropagation();
    setVisible(false);
  };
  const drawerTitle = (title, subtitle) => {
    return (
      <>
        <Title level={4}>{title}</Title>
        {subtitle}
      </>
    );
  };

  const footer = (
    <FooterDrawer
      onClose={onClose}
      typeDrawer="create"
      form={form}
      buttonLoading={buttonLoading}
      labelPrimaryButton={i18n.t('form.parameterize.buttonPrimary')}
    />
  );
  return (
    <Drawer
      title={drawerTitle(demandName, i18n.t('form.parameterize.title'))}
      footer={footer}
      width={720}
      onClose={(event) => onClose(event)}
      open={visible}
      style={{ body: { paddingBottom: 80 } }}
      destroyOnClose
      maskClosable={false}
      closable={false}
    >
      {loading && labelsFetching && <Skeleton paragraph={{ rows: 15 }} />}
      {!loading && !labelsFetching && (
        <DemandParametrizationForm
          id={demandId}
          form={form}
          setButtonLoading={setButtonLoading}
          defaultAction={defaultAction}
          generalRoutingSetting={generalRoutingSetting}
          targetsRoute={labels}
          setVisible={setVisible}
        />
      )}
    </Drawer>
  );
}

DrawerRow.defaultProps = {
  demandId: 0,
  demandName: '',
  visible: false,
  setVisible: () => {
    return false;
  },
  organizationId: undefined,
};

DrawerRow.propTypes = {
  demandId: PropTypes.number,
  demandName: PropTypes.string,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  organizationId: PropTypes.number,
};

export default DrawerRow;
