import apiSlice from 'app/api/apiSlice';

/**
 * locationsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const locationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: (query) => `/locations${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Locations', id })),
              { type: 'Locations', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Locations', id: 'PARTIAL-LIST' }],
    }),
    getLocationsByFiltersStop: builder.mutation({
      query: (query) =>
        query.length > 0 ? `/locations${query}&type=STOP` : '/locations?type=STOP',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Locations', id })),
              { type: 'Locations', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Locations', id: 'PARTIAL-LIST' }],
    }),
    getLocationsByFiltersDepot: builder.mutation({
      query: (query) =>
        query.length > 0 ? `/locations${query}&type=DEPOT` : '/locations?type=DEPOT',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Locations', id })),
              { type: 'Locations', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Locations', id: 'PARTIAL-LIST' }],
    }),
    createLocation: builder.mutation({
      query: (params) => ({
        url: '/locations',
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['Locations', 'CategoriesDepot', 'CategoriesVisit', 'GroupsForLocation'],
    }),
    updateLocation: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locationId}`,
        method: 'PATCH',
        body: { ...params.body },
      }),
      invalidatesTags: ['Locations', 'CategoriesDepot', 'CategoriesVisit', 'GroupsForLocation'],
    }),
    getCustomAttributeLocationsStop: builder.query({
      query: () => '/locations/custom-attributes/definition?type=STOP',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'CaLocationsStop', id })) : ['CaLocationsStop'],
    }),
    getCustomAttributeLocationsDepot: builder.query({
      query: () => '/locations/custom-attributes/definition?type=DEPOT',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'CaLocationsDepot', id })) : ['CaLocationsDepot'],
    }),
    deleteOrRestoreLocation: builder.mutation({
      query: (locationId) => ({
        url: `/locations/change-status/${locationId}`,
        method: 'POST',
      }),
      invalidatesTags: [
        'Locations',
        { type: 'Locations', id: 'LIST' },
        'DemandsSets',
        'CategoriesDepot',
        'CategoriesVisit',
        'GroupsForLocation',
      ],
    }),
    massiveDeleteOrRestoreLocation: builder.mutation({
      query: (body) => ({
        url: `/locations/massive-change-status`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Locations', 'CategoriesDepot', 'CategoriesVisit', 'GroupsForLocation'],
    }),
    getDepotLocations: builder.query({
      query: () => '/locations/all-depots',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Locations', id })),
              { type: 'Locations', id: 'LIST' },
            ]
          : [{ type: 'Locations', id: 'LIST' }],
    }),
    getFullDepotLocations: builder.query({
      query: () => '/locations?type=DEPOT&paginated=false',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Locations', id })),
              { type: 'Locations', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Locations', id: 'PARTIAL-LIST' }],
    }),
    getCategoriesVisit: builder.query({
      query: () => `/locations/get-categories?type=STOP`,
      providesTags: (_result, _error, id) => [{ type: 'CategoriesVisit', id }],
    }),
    getCategoriesDepot: builder.query({
      query: () => `/locations/get-categories?type=DEPOT`,
      providesTags: (_result, _error, id) => [{ type: 'CategoriesDepot', id }],
    }),
    getGroupsVisit: builder.query({
      query: () => `/locations/get-groups?type=STOP`,
      providesTags: (_result, _error, id) => [{ type: 'GroupsForLocation', id }],
    }),
    getGroupsDepot: builder.query({
      query: () => `/locations/get-groups?type=DEPOT`,
      providesTags: (_result, _error, id) => [{ type: 'GroupsForLocation', id }],
    }),
    getStopsSuggested: builder.mutation({
      query: (query) => `locations/stops-suggest${query}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Locations', id })) : ['Locations'],
    }),
    getStopsSuggestedAlt: builder.query({
      query: (query) => `locations/stops-suggest${query}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Locations', id })) : ['Locations'],
    }),
    getLocation: builder.query({
      query: (id) => `/locations/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Locations', id }],
    }),
    uploadLocations: builder.mutation({
      query: ({ file, type }) => {
        const fmData = new FormData();
        fmData.append('file', file);
        fmData.append('type', type);
        return {
          url: '/locations/upload',
          prepareHeaders: (headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: [
        'FileProcessing',
        // 'Locations', // is proccesed by lamda, so delay to end of process
      ],
    }),
    uploadEditLocations: builder.mutation({
      query: ({ file, type }) => {
        const fmData = new FormData();
        fmData.append('file', file);
        fmData.append('type', type);
        return {
          url: '/locations/upload-edit',
          prepareHeaders: (headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: [
        'FileProcessing',
        // 'Locations', // is proccesed by lamda, so delay to end of process
      ],
    }),
    downloadLocations: builder.mutation({
      query: ({ params, format, type }) => {
        return {
          url: `/locations/download/${type}/${format}`,
          method: 'POST',
          body: { ...params },
        };
      },
      invalidatesTags: ['FileProcessing'],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useGetLocationsByFiltersStopMutation,
  useGetLocationsByFiltersDepotMutation,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useGetCustomAttributeLocationsStopQuery,
  useGetCustomAttributeLocationsDepotQuery,
  useDeleteOrRestoreLocationMutation,
  useGetDepotLocationsQuery,
  useGetFullDepotLocationsQuery,
  useGetCategoriesVisitQuery,
  useGetStopsSuggestedMutation,
  useGetLocationQuery,
  useUploadLocationsMutation,
  useUploadEditLocationsMutation,
  useDownloadLocationsMutation,
  useMassiveDeleteOrRestoreLocationMutation,
  useGetCategoriesDepotQuery,
  useGetStopsSuggestedAltQuery,
  useGetGroupsVisitQuery,
  useGetGroupsDepotQuery,
} = locationsApiSlice;
