import { Form, Upload } from 'antd';
import logoTOPlanners from 'assets/img/logo-TO-planners.svg';
import PropTypes from 'prop-types';
import { useState } from 'react';

function LogoForm(props) {
  const { url } = props;
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'logo.png',
      status: 'done',
      url: url || logoTOPlanners,
    },
  ]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    return imgWindow?.document.write(image.outerHTML);
  };
  const dummyRequest = async (options) => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  return (
    <Form.Item name="logo" getValueFromEvent={({ file }) => file.originFileObj}>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        maxCount={1}
        accept=".png,.jpeg,.jpg"
        customRequest={dummyRequest}
      >
        + Upload
      </Upload>
    </Form.Item>
  );
}

LogoForm.defaultProps = {
  url: null,
};

LogoForm.propTypes = {
  url: PropTypes.string,
};

export default LogoForm;
