import { Skeleton } from 'antd';
import LocationForm from 'components/Locations/Form';
import {
  useGetCategoriesVisitQuery,
  useGetCustomAttributeLocationsStopQuery,
  useGetGroupsVisitQuery,
  useGetLocationQuery,
  useGetLocationsQuery,
} from 'features/locations/locationsApiSlice';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { formType } from 'types';

function LocationEditForm(props) {
  const { locationId, form, onClose, setButtonLoading, showEditForm, setTriggerClean } = props;
  const locationType = 'LOCATION';
  const typeDrawer = 'edit';
  const createdFrom = 'VisitList';
  const [categories, setCategories] = useState([]);
  const { data: location, isLoading: isLoadingLocationData } = useGetLocationQuery(locationId);
  const { data: caeLocationsData, isLoading: isLoadingCaeData } =
    useGetCustomAttributeLocationsStopQuery();
  const { data: locations, isLoading: isLoadingLocations } = useGetLocationsQuery('?type=STOP', {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    refetch: refetchCategories,
  } = useGetCategoriesVisitQuery();
  const { data: listGroups, isLoading: isLoadingGroups } = useGetGroupsVisitQuery();
  const isLoading =
    isLoadingLocationData ||
    isLoadingCaeData ||
    isLoadingLocations ||
    isLoadingCategories ||
    isLoadingGroups;

  useEffect(() => {
    if (!isLoadingCategories) {
      const uniqCategories = [...new Set(categoriesData)];
      const allCategories = uniqCategories.map((category) => ({
        label: category,
        value: category,
      }));
      setCategories(allCategories);
    }
    refetchCategories();
  }, [categoriesData, isLoadingCategories, refetchCategories]);

  return (
    <Skeleton active loading={isLoading || !location} paragraph={{ rows: 15 }}>
      <LocationForm
        onCloseDrawer={onClose}
        location={location}
        caeFields={isLoading ? [] : caeLocationsData}
        locations={locations?.data || []}
        type={locationType}
        form={form}
        setButtonLoading={setButtonLoading}
        drawerVisible={showEditForm}
        refetchCategories={refetchCategories}
        categories={categories}
        setTriggerClean={setTriggerClean}
        typeDrawer={typeDrawer}
        createdFrom={createdFrom}
        enableMoveToPosition={false}
        listGroups={listGroups}
      />
    </Skeleton>
  );
}

LocationEditForm.defaultProps = {
  locationId: null,
  form: {},
  onClose: () => {},
  setButtonLoading: () => {},
  showEditForm: false,
  setTriggerClean: () => {},
};

LocationEditForm.propTypes = {
  locationId: PropTypes.number,
  form: formType,
  onClose: PropTypes.func,
  setButtonLoading: PropTypes.func,
  showEditForm: PropTypes.bool,
  setTriggerClean: PropTypes.func,
};

export default LocationEditForm;
