import dayjs from 'dayjs';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ColorByOrder, ConvertToMinutes } from 'services/repeated-functions';
import { SynopticData, SynopticRelativeRoutes } from '../SynopticSettings.propTypes';
import DrawRoute from './DrawRoute';

// All temporal events & ticks will be calculated in minutes.
// Returns the svg components to draw each route representation in the canvas. With it's starts, stops & ends.
function RouteDraw(props) {
  const {
    startTime,
    svgWidth,
    vSpaceRoute,
    hSpaceDrawBorder,
    displayHeight,
    minStopPxWidth,
    pxOnTick,
    minimalTick,
    minimalTickReference,
    routeLineThickness,
    defaultStrokeWidth,
    data,
    relativeRoutes,
    routesColorBy,
    eta,
    currentTime,
  } = props;

  const pxRatio = pxOnTick / ConvertToMinutes(minimalTick, minimalTickReference);
  const vRouteCenter = (vSpaceRoute - routeLineThickness) / 2;
  const { timezone } = useSelector(selectCurrentOrganization);

  // Function takes a hsla color as a string an let change it's saturation &/or lightness, leaving its hue & alpha intact.
  const adjustColor = (color, newLightness = -1, newSaturation = -1) => {
    const hsla = color.split(',');
    const saturation = newSaturation <= 100 && newSaturation > -1 ? `${newSaturation}%` : hsla[1];
    const lightness = newLightness <= 100 && newLightness > -1 ? `${newLightness}%` : hsla[2];

    return `${hsla[0]},${saturation},${lightness},${hsla[3]}`;
  };

  const drawDisplay = [];
  if (data.length > 0 && svgWidth) {
    drawDisplay.push(
      <rect
        key="drawFiller"
        x="0"
        y="0"
        width="100%"
        height={vSpaceRoute * (data.length + 1)}
        fill="white"
      />
    );

    data.forEach((route, dataIndex) => {
      const routeColor = ColorByOrder(route.routeOrder);
      const routeLightColor =
        routesColorBy === 'route-number' ? adjustColor(routeColor, 85) : 'lightgrey';
      const routeDarkColor = adjustColor(routeColor, 35);
      const depotColor = '#0D63CF';
      const parkingColor = '#09497F';
      const keyDrawDisplay = `dr-${dataIndex}`;
      drawDisplay.push(
        <DrawRoute
          key={keyDrawDisplay}
          route={route}
          routesData={data}
          dataIndex={dataIndex}
          routeColor={routeColor}
          routeLightColor={routeLightColor}
          routeDarkColor={routeDarkColor}
          depotColor={depotColor}
          parkingColor={parkingColor}
          kind="route"
          vSpaceRoute={vSpaceRoute}
          vRouteCenter={vRouteCenter}
          defaultStrokeWidth={defaultStrokeWidth}
          startTime={startTime}
          hSpaceDrawBorder={hSpaceDrawBorder}
          pxRatio={pxRatio}
          pxOnTick={pxOnTick}
          routesColorBy={routesColorBy}
          routeLineThickness={routeLineThickness}
          minStopPxWidth={minStopPxWidth}
          eta={eta}
          timezone={timezone}
          currentTime={currentTime}
        />
      );

      const relativeRoute = relativeRoutes.find(({ routeId }) => routeId === route.id);
      if (!relativeRoute) return;
      const { relatedRoutes } = relativeRoute;
      const relatedRouteColor = 'hsla(0, 0%, 60%, 1)';
      const relatedRouteLightColor = adjustColor(relatedRouteColor, 85);
      const relatedRouteDarkColor = adjustColor(relatedRouteColor, 15);
      const relatedDepotColor = '#999999';
      const relatedRarkingColor = '#09497F';
      relatedRoutes.forEach((relatedRoute) => {
        const keyDrawDisplayRelated = `drr-${dataIndex}`;
        drawDisplay.push(
          <DrawRoute
            key={keyDrawDisplayRelated}
            route={relatedRoute}
            routesData={relatedRoutes}
            dataIndex={dataIndex}
            routeColor={relatedRouteColor}
            routeLightColor={relatedRouteLightColor}
            routeDarkColor={relatedRouteDarkColor}
            depotColor={relatedDepotColor}
            parkingColor={relatedRarkingColor}
            kind="relatedRoute"
            vSpaceRoute={vSpaceRoute}
            vRouteCenter={vRouteCenter}
            defaultStrokeWidth={defaultStrokeWidth}
            startTime={startTime}
            hSpaceDrawBorder={hSpaceDrawBorder}
            pxRatio={pxRatio}
            pxOnTick={pxOnTick}
            routesColorBy={routesColorBy}
            routeLineThickness={routeLineThickness}
            minStopPxWidth={minStopPxWidth}
            eta={eta}
            timezone={timezone}
            currentTime={currentTime}
          />
        );
      });
    });
  } else {
    // If no data is shown a text should be displayed.
    drawDisplay.push(
      <text
        key="emptyDraw-0"
        x="33%"
        y={displayHeight / 2}
        dominantBaseline="middle"
        textAnchor="middle"
        fontWeight="bold"
        fill="grey"
        fontSize={20}
      >
        No hay rutas disponibles para mostrar
      </text>
    );
  }

  // This line represent the last line or the first, depending of the value of data.length
  drawDisplay.push(
    <line
      key={`finalLine-${data.length + 1}`}
      x1="0"
      y1={vSpaceRoute * data.length}
      x2="100%"
      y2={vSpaceRoute * data.length}
      stroke="grey"
      strokeWidth={0.5}
    />
  );

  return drawDisplay;
}
RouteDraw.defaultProps = {
  startTime: dayjs(), // Start Time, in date format.
  minimalTick: 15, // Amount. Time reference for each time tick.
  minimalTickReference: 'minutes', // String. Set for time convertion.
  pxOnTick: 40, // Pixels. 40px is used as minimum distance between time timeTicks.
  svgWidth: 244, // Pixels. Width of the draw column on px.
  vSpaceRoute: 70, // Pixels. Height of every route to be shown.
  hSpaceDrawBorder: 40, // Pixels. 40px as minimum space between the start and end of the svg tick markers space.
  displayHeight: 400, // Pixels. Minimal amount of routes to be shown, used for the default height for when theres no route to be shown.
  minStopPxWidth: 18, // Pixels. Sets the minimun with of the element so the stop number or ? char apears on the draw.
  routeLineThickness: 30, // Pixels. Sets the heigth of each route representation in the space given in the vSpaceRoute var.
  defaultStrokeWidth: 2, // Pixels. Sets the outline width of each route element representation, if 0 no outline will be draw.
  data: [], // Object Array. Data to be shown and draw in the synoptic.
  relativeRoutes: [], // Object Array, Contains routes from driver or vehicle.
  routesColorBy: '', // string, set colors of routes (valid options: status, route-number)
  eta: false,
  currentTime: new Date(), // Date, actual time and date
};
RouteDraw.propTypes = {
  startTime: PropTypes.instanceOf(dayjs),
  pxOnTick: PropTypes.number,
  svgWidth: PropTypes.number,
  vSpaceRoute: PropTypes.number,
  hSpaceDrawBorder: PropTypes.number,
  displayHeight: PropTypes.number,
  minStopPxWidth: PropTypes.number,
  depotDefinition: PropTypes.string,
  routeLineThickness: PropTypes.number,
  defaultStrokeWidth: PropTypes.number,
  data: SynopticData,
  relativeRoutes: SynopticRelativeRoutes,
  routesColorBy: PropTypes.string,
  eta: PropTypes.bool,
  currentTime: PropTypes.instanceOf(Date),
};

export default RouteDraw;
