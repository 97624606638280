import PropTypes from 'prop-types';

// Returns the FULL svg component to draw the fixed header of the data area.
function HeaderData(props) {
  const { vSpaceHeader, fullHeaderSpace } = props;

  return (
    <svg width="100%" height={fullHeaderSpace}>
      <rect x="0" y="0" width="100%" height={vSpaceHeader} fill="#f5f5f5" />
      <rect x="0" y={vSpaceHeader} width="100%" height={vSpaceHeader} fill="white" />
      <line x1="0" y1={1} x2="100%" y2={1} stroke="grey" strokeWidth={0.5} />
      <line x1="0" y1={vSpaceHeader} x2="100%" y2={vSpaceHeader} stroke="grey" strokeWidth={0.5} />
      <line
        x1="0"
        y1={fullHeaderSpace}
        x2="100%"
        y2={fullHeaderSpace}
        stroke="grey"
        strokeWidth={0.5}
      />
    </svg>
  );
}
HeaderData.defaultProps = {
  // *** Synoptic space definitions
  vSpaceHeader: 30, // pixels. 30px as minimum distance between upper horizontal lines.
  fullHeaderSpace: 60, // pixels. usually is the vSpaceHeader * 2, but is defined if an uneven definition is needed.
};
HeaderData.propTypes = {
  vSpaceHeader: PropTypes.number,
  fullHeaderSpace: PropTypes.number,
};

export default HeaderData;
