import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Card, Col, Form, Progress, Row, Upload } from 'antd';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { formatErrorsForImporter } from 'services/repeated-functions';
import LocaleContext from 'components/locale/LocaleContext';
import ImporterDrawerError from '../DrawerError';
import {
  ErrorResponsePropTypes,
  FileListPropTypes,
  OtherPayloadPropTypes,
} from '../drawerImporter.propTypes';

function ImporterUpload(props) {
  const { reduxFunc, otherPayload, errorResponse, setErrorResponse, setFileList, fileList } = props;
  const [errofileList, setErrorFileList] = useState([]);
  const [errorToDisplay, setErrorToDisplay] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.demandsImport.upload' };
  const ACCEPTED_FORMATS = ['csv']; // use with accept attribute on dragger
  const MAX_FILE_SIZE = 5; // MB

  // include all validation (data type, size)
  const beforeUpload = (file) => {
    const isAccepted = ACCEPTED_FORMATS.includes(file.name.split('.').pop());
    const isLt5M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    let sizeFileMessage;
    let acceptedFormatMessage;
    const scopeI18nErrors = { scope: 'form.demandsImport' };
    const messages = [];
    if (!isLt5M) {
      sizeFileMessage = i18n.t('errors.sizeFile', {
        ...scopeI18nErrors,
        fileName: file.name,
        maxFileSize: MAX_FILE_SIZE,
      });
      messages.push(sizeFileMessage);
      message.error(sizeFileMessage);
    }
    if (!isAccepted) {
      acceptedFormatMessage = i18n.t('errors.fileFormat', {
        ...scopeI18nErrors,
        fileName: file.name,
        formats: ACCEPTED_FORMATS.join(', '),
      });
      messages.push(acceptedFormatMessage);
      message.error(acceptedFormatMessage);
    }
    if (!isLt5M || !isAccepted) {
      const fileWithError = { ...file, status: 'error' };
      const newErrorObject = {
        uid: file.uid,
        name: file.name,
        errors: {
          0: [
            {
              name: file.name,
              details: [],
              messages,
            },
          ],
        },
      };
      setErrorResponse({ ...errorResponse, [file.uid]: newErrorObject });
      setErrorFileList([...errofileList, fileWithError]);
      setErrorToDisplay(newErrorObject);
    }
    return isAccepted && isLt5M;
  };
  const handleOnChange = (info) => {
    const { status, name } = info.file;
    const fileToUse = { ...info };
    const uidsError = errofileList.map(({ uid }) => uid);
    message.open({
      key: 'loading-msg',
      type: 'loading',
      content: i18n.t('alerts.validatingFile', { scope: 'form.demandsImport' }),
      duration: 2,
    });
    setButtonLoading(true);
    if (uidsError.includes(info.file.uid)) {
      const fileList1 = fileToUse.fileList.map((file) => {
        return {
          ...file,
          ...(uidsError.includes(file.uid) && { status: 'error' }),
        };
      });
      fileToUse.fileList = fileList1;
    }
    if (status === 'done') {
      message.destroy('loading-msg');
      message.success(
        i18n.t('alerts.validationSuccess', { scope: 'form.demandsImport', fileName: name })
      );
      setButtonLoading(false);
    } else if (status === 'error') {
      fileToUse.fileList = [{ ...info.file, status: 'error' }];
      message.destroy('loading-msg');
      message.error(
        i18n.t('alerts.validationFailed', { scope: 'form.demandsImport', fileName: name })
      );
      setButtonLoading(false);
    } else if (status === undefined) {
      message.destroy('loading-msg');
      setButtonLoading(false);
    }

    setFileList(fileToUse.fileList);
  };
  const uploadFunction = async (options) => {
    const { file, onSuccess, onProgress, onError } = options;
    onProgress({ percent: 30 });
    try {
      const res = await reduxFunc({ file, ...otherPayload }).unwrap();
      onProgress({ percent: 65 });
      setTimeout(() => {
        setErrorResponse({});
        onSuccess(res.message);
      }, 800);
    } catch (error) {
      onProgress({ percent: 85, status: 'exception' });
      const errorsByRow = formatErrorsForImporter(error.data, i18n);
      const newErrorObject = {
        uid: file.uid,
        name: file.name,
        errors: errorsByRow,
      };
      setErrorResponse({ ...errorResponse, [file.uid]: newErrorObject });
      setErrorToDisplay(newErrorObject);
      onError(error.data.message);
    }
  };
  const onRemove = (file) => {
    if (fileList.some((item) => item.uid === file.uid)) {
      // remove from list
      setFileList((fileList_) => fileList_.filter((item) => item.uid !== file.uid));
      // update response object, remove
      const otherUids = Object.keys(errorResponse).filter((uid) => uid !== file.uid);
      const otherFileErrors = otherUids.reduce((summ, uid) => {
        return {
          ...summ,
          [uid]: errorResponse[uid],
        };
      }, {});
      setErrorResponse(otherFileErrors);
      return true;
    }
    return false;
  };
  const closeDrawerError = (file) => {
    setErrorToDisplay();
    onRemove(file);
    setButtonLoading(false);
  };

  const uploadItem = (itemFile, actions) => {
    const { uid, name, status, percent } = itemFile;
    const onClickShowDetails = () => {
      const errorFounded = errorResponse[uid];
      setErrorToDisplay(errorFounded);
    };
    const loadingItem = (
      <Card className="card-uploading" size="small">
        <Row>
          <Col xl={1} md={2} className="col-spinner">
            <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
          </Col>
          <Col xl={23} md={22}>
            {name} -
            <Progress percent={percent} status={status === 'error' ? 'exception' : ''} />
          </Col>
        </Row>
      </Card>
    );

    if (status !== 'error' && percent !== 100) {
      return loadingItem;
    }
    return (
      <Card className={`card-uploaded-${status === 'error' ? 'error' : 'success'}`} size="small">
        <FontAwesomeIcon className="icon-file " icon={['fas', 'file-lines']} />
        {name}
        <div className="float-right">
          {status === 'error' && (
            <Button
              size="small"
              type="link"
              title={i18n.t('showErrorDetails', scopeI18n)}
              onClick={onClickShowDetails}
            >
              {i18n.t('showDetails', scopeI18n)}
            </Button>
          )}
          <Button
            size="small"
            type="link"
            onClick={() => actions.remove()}
            title={i18n.t('delete', scopeI18n)}
          >
            <FontAwesomeIcon icon="fa-regular fa-trash-can" className="icon-trash" />
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Form.Item name="file" getValueFromEvent={({ file }) => file.originFileObj}>
        <Upload
          beforeUpload={beforeUpload}
          listType="picture"
          onChange={handleOnChange}
          fileList={fileList}
          maxCount={1}
          onRemove={onRemove}
          customRequest={uploadFunction}
          itemRender={(originNode, file, fileList1, actions) => uploadItem(file, actions)}
        >
          <Button
            type="primary"
            className="btn-upload-importer"
            loading={buttonLoading}
            disabled={buttonLoading}
          >
            {i18n.t('uploadFile', scopeI18n)}
          </Button>
        </Upload>
      </Form.Item>
      <ImporterDrawerError
        open={errorToDisplay !== undefined}
        closeDrawerError={closeDrawerError}
        errorToDisplay={errorToDisplay}
      />
    </>
  );
}

ImporterUpload.defaultProps = {
  reduxFunc: () => {},
  otherPayload: {
    type: undefined,
  },
  setErrorResponse: () => {},
  errorResponse: {},
  setFileList: () => {},
  fileList: [],
};

ImporterUpload.propTypes = {
  reduxFunc: PropTypes.func,
  otherPayload: OtherPayloadPropTypes, // enabled only for locations importer, to use type
  setErrorResponse: PropTypes.func,
  errorResponse: ErrorResponsePropTypes,
  setFileList: PropTypes.func,
  fileList: FileListPropTypes,
};

export default ImporterUpload;
