import PropTypes from 'prop-types';

export const FollowingEventProp = PropTypes.shape({
  key: PropTypes.string,
  status: PropTypes.string,
  arrivedAt: PropTypes.string,
  departedAt: PropTypes.string,
  timeCaptured: PropTypes.string,
  comment: PropTypes.string,
  reason: PropTypes.string,
  form: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      response: PropTypes.string,
    })
  ),
  id: PropTypes.number,
});

export const FollowingItemProp = PropTypes.shape({
  id: PropTypes.number,
  key: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  lastEvent: PropTypes.shape({ id: PropTypes.number, status: PropTypes.string }),
  events: PropTypes.arrayOf(FollowingEventProp),
  nid: PropTypes.string,
  contactName: PropTypes.string,
  contactPhones: PropTypes.arrayOf(
    PropTypes.shape({
      phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  contactEmails: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
    })
  ),
  departedAt: PropTypes.string,
  arrivedAt: PropTypes.string,
  arrivesAt: PropTypes.string,
  distance: PropTypes.number,
});

export const FollowingItemsProps = PropTypes.arrayOf(FollowingItemProp);

export const FollowingRoutingSet = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  depot: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
});

export const FollowingRouteProps = PropTypes.shape({
  key: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.number,
  routeOrder: PropTypes.number,
  routingSetId: PropTypes.number,
  totalStops: PropTypes.number,
  totalDistance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startsAt: PropTypes.string,
  endsAt: PropTypes.string,
  driver: PropTypes.shape({
    fullName: PropTypes.string,
    photo: PropTypes.string,
    phone: PropTypes.string,
  }),
  vehicle: PropTypes.shape({
    plate: PropTypes.string,
  }),
  depot: PropTypes.shape({
    name: PropTypes.string,
  }),
  items: FollowingItemsProps,
});

export const FollowingRoutesProps = PropTypes.arrayOf(FollowingRouteProps);
