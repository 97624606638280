import loadingGif from 'assets/img/loading.gif';

function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#320182',
        height: '100vh',
      }}
    >
      <span
        style={{
          color: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#320182',
        }}
      >
        <img src={loadingGif} alt="loading.gif" style={{ width: 130 }} />
      </span>
    </div>
  );
}

export default Loading;
