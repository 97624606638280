import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import ErrorBoundary from './layout/ErrorBoundary';

const { Content } = Layout;

function DriverLayout() {
  return (
    <Content>
      <ErrorBoundary>
        <div className="site-layout-content-secondary">
          <Outlet />
        </div>
      </ErrorBoundary>
    </Content>
  );
}

export default DriverLayout;
