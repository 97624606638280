import { Button, Col, Drawer, Form, Row, Space } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { defineComponent } from 'services/repeated-functions';
import { FieldsFilterPropType } from '../tableFilter.propTypes';

function TableFilterAdvanced(props) {
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'commons' };
  const {
    isVisible,
    setVisible,
    advancedFieldsFilter,
    paginationData,
    extraParams,
    setDataCallback,
    setDownloadQuery,
    reduxFunc,
    filterIsLoading,
  } = props;
  const { setTotalData, setActualPage } = paginationData;
  const usePagination = setTotalData !== undefined;
  const [form] = Form.useForm();
  const onClose = (event) => {
    event?.stopPropagation();
    form.resetFields();
    setVisible(false);
  };

  const isDate = (fieldName) => {
    // add others types
    const typesDate = ['range'];
    const fieldsDate = advancedFieldsFilter.map((field) => {
      return typesDate.includes(field.type) ? field.name : null;
    });
    return fieldsDate.includes(fieldName);
  };

  const buildQuery = (values) => {
    let query = '';
    const fieldsForm = Object.keys(values);
    const fieldsAsQuery = fieldsForm.map((field) => {
      let valueField = values[field];
      if (valueField) {
        if (isDate(field)) {
          valueField = valueField?.map((date) => {
            // format: '2023-02-03T16:00:00-03:00'
            return date.format();
          });
        }
        return `${field}=${valueField}`;
      }
      return undefined;
    });
    query = fieldsAsQuery.filter((element) => element !== undefined).join('&');
    return query.length > 0 ? `?${query}` : '';
  };

  const fetchDataFiltered = async (params = '') => {
    let query = params;
    if (extraParams) {
      query += params.length > 0 ? `&${extraParams}` : `?${extraParams}`;
    }
    setDownloadQuery(query);
    try {
      const result = await reduxFunc(query).unwrap();
      if (usePagination) {
        const { data, total } = result;
        setDataCallback(data);
        setTotalData(total);
      } else {
        setDataCallback(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = (values) => {
    const query = buildQuery(values);
    if (usePagination) {
      setActualPage(1);
    }
    if (query !== '') {
      fetchDataFiltered(query);
    }
  };
  const onSaveFilter = () => {
    form.submit();
  };
  return (
    <Drawer
      title={i18n.t('advancedFilter', scopeI18n)}
      onClose={(event) => onClose(event)}
      open={isVisible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={onClose} disabled={filterIsLoading}>
                {i18n.t('buttons.cancel')}
              </Button>
              <Button
                id="saveButton"
                loading={filterIsLoading}
                type="primary"
                htmlType="submit"
                disabled={filterIsLoading}
                onClick={onSaveFilter}
              >
                {i18n.t('buttons.filter')}
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{
          flex: '100px',
        }}
      >
        {advancedFieldsFilter.map((field) => {
          return defineComponent(field, form, () => {});
        })}
      </Form>
    </Drawer>
  );
}

TableFilterAdvanced.defaultProps = {
  isVisible: false,
  setVisible: () => {},
  advancedFieldsFilter: [],
  setDataCallback: () => {
    console.warn('undefined setDatacallback');
  },
  extraParams: null,
  setDownloadQuery: () => {},
  paginationData: {
    setTotalData: undefined,
    setActualPage: undefined,
  },
  filterIsLoading: false,
};
TableFilterAdvanced.propTypes = {
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
  advancedFieldsFilter: FieldsFilterPropType,
  setDataCallback: PropTypes.func,
  extraParams: PropTypes.string,
  setDownloadQuery: PropTypes.func,
  paginationData: PropTypes.shape({
    setTotalData: PropTypes.func,
    setActualPage: PropTypes.func,
  }),
  reduxFunc: PropTypes.func.isRequired,
  filterIsLoading: PropTypes.bool,
};

export default TableFilterAdvanced;
