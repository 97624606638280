import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { SynopticData } from '../SynopticSettings.propTypes';
import itemTypes from '../itemTypes.json';
import DepotParkingItem from './DepotParkingItem';
import RestItem from './RestItem';
import StopItem from './StopItem';
import UnknownItem from './UnknownItem';

// Returns the popover content to be shown based on its type.
function ItemDetails(props) {
  const { routeNumber, itemNumber, data } = props;
  const { timezone } = useSelector(selectCurrentOrganization);
  const currentItemTypes = Object.keys(itemTypes);
  let drawPopover = null;

  const routeData = data.find((route) => {
    return route.routeOrder === routeNumber;
  });
  const itemData = routeData.items.find((item) => {
    return item.itemOrder === itemNumber;
  });

  // differentiation of content for item type.
  if (itemData.type === currentItemTypes[2]) {
    // STOP
    drawPopover = <StopItem routeData={routeData} itemData={itemData} timezone={timezone} />;
  } else if (itemData.type.includes('DEPOT') || itemData.type.includes('PARKING')) {
    // DEPOT, PARKING & their's RETURN_TO
    drawPopover = <DepotParkingItem itemData={itemData} timezone={timezone} />;
  } else if (itemData.type.includes('REST')) {
    drawPopover = <RestItem itemData={itemData} timezone={timezone} />;
  } else {
    // UNKNOWN
    drawPopover = <UnknownItem itemData={itemData} timezone={timezone} />;
  }

  return drawPopover;
}
ItemDetails.defaultProps = {
  routeNumber: 0, // number. Defines the route number of the data to be searched.
  itemNumber: 0, // number. Defines the item number of the route to be searched in the route subset of the data.
  data: [], // json. Current routes data of the synoptic.
};
ItemDetails.propTypes = {
  routeNumber: PropTypes.number,
  itemNumber: PropTypes.number,
  data: SynopticData,
};

export default ItemDetails;
