import { App, Button, Col, Divider, Row, Skeleton, Typography } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import { useCallDataFromReQuery } from 'features/routingEngine/routingEngineApiSlice';
import { useContext, useEffect, useState } from 'react';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllIndexFromArray } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';

const { Title } = Typography;

function RouteJobData() {
  const { jobId, method: methodName, jobDbId } = useParams();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(true);
  const methodReal = methodName === 'view' ? '' : `/${methodName}`;
  const navigate = useNavigate();
  const { notification } = App.useApp();
  // custom hooks
  const {
    data: reCallData,
    refetch,
    isLoading,
    error,
  } = useCallDataFromReQuery(
    { jobId, methodReal },
    {
      skip: jobId === undefined || methodReal === undefined,
    }
  );

  useEffect(() => {
    const callBackendData = async () => {
      try {
        setResult(reCallData);
        setLoading(isLoading);
      } catch (errorC) {
        notification.error({
          message: `Error ${errorC.status}`,
          description: errorC.statusText,
        });
      }
      if (error) {
        notification.error({
          message: `Error ${error.status}`,
          description: 'Problemas ejecutando la llamada al backend',
        });
        navigate(-1);
      }
    };
    callBackendData();
    refetch();
  }, [error, isLoading, navigate, notification, reCallData, refetch]);

  const humanName = (method) => {
    const linksRe = {
      view: 'Ver',
      status: 'Estado',
      result: 'Resultado',
      abort: 'Abortar',
    };
    return linksRe[method];
  };
  const ability = useContext(CaslContext);
  const allRoutesFromArray = getAllIndexFromArray(['planning', 'routes', 'jobs'], ability);
  const { jobs: jobIndexPath } = allRoutesFromArray;
  const { index: jobsIndex, path: jobsPath } = jobIndexPath;
  const jobsKey = `planning${jobsIndex ? '' : `/${jobsPath}`}`;
  const breadcrumbItems = [
    {
      key: 'home',
      url: '/planning',
      label: 'Rutas',
    },
    {
      key: 'routeJobList',
      url: `/${jobsKey}`,
      label: 'Lista de Trabajos de ruteo',
    },
    {
      key: 'routeJobDetail',
      url: `/planning/jobs/${jobDbId}`,
      label: 'Trabajo de ruteo',
    },
    {
      key: 'routeJobData',
      url: `/planning/jobs/${jobId}/${methodName}/${jobDbId}`,
      label: `${humanName(methodName)} trabajo`,
    },
  ];

  const json = result;
  return (
    <Row gutter={24} style={{ height: '100%' }}>
      <Col flex="auto">
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        <Typography>
          <Title>
            {humanName(methodName)} trabajo: {jobId}
            <Button
              className="btn-back-jobID"
              title="Volver"
              size="large"
              onClick={() => {
                navigate(-1);
              }}
            >
              Volver
            </Button>
          </Title>
          <Divider />
          <Skeleton active loading={loading}>
            <JsonView data={json} shouldInitiallyExpand={allExpanded} style={defaultStyles} />
          </Skeleton>
        </Typography>
      </Col>
    </Row>
  );
}

export default RouteJobData;
