import { Skeleton } from 'antd';
import ModuleConfiguration from 'components/Organizations/Configurations/Module';
import PropTypes from 'prop-types';

function OrganizationsCreationStepsModule(props) {
  const { organizationId, isLoading, current } = props;
  return isLoading ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <ModuleConfiguration
      organizationId={organizationId}
      goToNextStep
      submitButton
      current={current}
    />
  );
}

OrganizationsCreationStepsModule.defaultProps = {
  organizationId: null,
  isLoading: false,
  current: null,
};
OrganizationsCreationStepsModule.propTypes = {
  organizationId: PropTypes.number,
  isLoading: PropTypes.bool,
  current: PropTypes.number,
};

export default OrganizationsCreationStepsModule;
