import { Col, Row } from 'antd';
import loginPic from 'assets/img/login-image.png';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import './style.css';

function Account() {
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  const theOptimalFooter = (
    <span>
      © {new Date().getFullYear()},{' '}
      <a
        href="https://theoptimalpartner.com"
        target="_blank"
        rel="noreferrer"
        title={i18n.t('goToOptimalWeb')}
      >
        {i18n.t('theOptimal')}
      </a>
    </span>
  );
  return (
    <Row>
      <Col
        span={13}
        md={13}
        xs={0}
        style={{
          backgroundImage: `url(${loginPic})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
        }}
        className="login-left-content"
      >
        {theOptimalFooter}
      </Col>
      <Col span={11} xs={24} md={{ span: 9, offset: 1 }}>
        <div className="form-login-container">
          <Outlet />
        </div>
      </Col>
      <Col md={0} xs={24} style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
        {theOptimalFooter}
      </Col>
    </Row>
  );
}

export default Account;
