import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Space, Tooltip, Typography, theme } from 'antd';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { find, isArray } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { convertDateToDayjs } from 'services/repeated-functions';
import { itemType } from 'types';

const { Text } = Typography;

const currentRestrictions = [
  { name: 'demand_delivery_priorities', icon: 'p' },
  { name: 'demand_groups', icon: 'g' },
];

function ItemEditIndicators(props) {
  const {
    item: { departsAt, arrivesAt, vip: isVip, restrictions },
    isDepot,
  } = props;
  const { token } = theme.useToken();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);

  const getTrucksRestrictions = () => {
    const minMaxtrucksRestrictions = restrictions.filter(
      (tR) =>
        [
          'minimum_permissible_truck',
          'maximum_permissible_truck',
          'demand_vehicle_min_capacity',
          'demand_vehicle_max_capacity',
        ].includes(tR?.name) && tR?.breached
    );
    if (minMaxtrucksRestrictions.length > 0) {
      const title = (
        <Space direction="vertical">
          {minMaxtrucksRestrictions.map((mTruck) => `${mTruck?.label || ''}: ${mTruck?.value}`)}
        </Space>
      );
      const breachedRestriction = minMaxtrucksRestrictions.some(({ breached }) => breached);
      const color = breachedRestriction ? token.colorError : '#999999';
      const colorBorder = breachedRestriction ? token.colorError : token.colorBorder;

      return (
        <Tooltip title={title} color={color}>
          <div
            style={{
              border: `1px solid ${colorBorder}`,
              padding: '0 5px',
              color: `${color}`,
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <FontAwesomeIcon icon={['fas', 'truck-front']} color={color} />
          </div>
        </Tooltip>
      );
    }
    return '';
  };

  const getTimeWindowRestrictions = () => {
    const minMaxTwRestrictions = restrictions.filter(
      (tR) =>
        [
          'demand_delivery_min_route_cost',
          'demand_delivery_max_route_cost',
          'lunch_min_route_costs',
          'lunch_max_route_costs',
        ].includes(tR?.name) && tR?.breached
    );
    if (minMaxTwRestrictions.length > 0) {
      const title = (
        <Space direction="vertical">
          {minMaxTwRestrictions.map((mTw) => `${mTw?.label || ''}: ${mTw?.value}`)}
        </Space>
      );
      const breachedRestriction = minMaxTwRestrictions.some(({ breached }) => breached);
      const color = breachedRestriction ? token.colorError : '#999999';
      const colorBorder = breachedRestriction ? token.colorError : token.colorBorder;

      return (
        <Tooltip title={title} color={color}>
          <div
            style={{
              border: `1px solid ${colorBorder}`,
              padding: '0 5px',
              color: `${color}`,
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <FontAwesomeIcon icon={['fas', 'clock']} color={color} />
          </div>
        </Tooltip>
      );
    }
    return '';
  };

  const getRestriction = ({ name: restrictionName, icon: restrictionIcon }) => {
    const restriction = find(restrictions, { name: restrictionName, breached: true }) || {};
    const { id: key, label, value, breached } = restriction;
    const title = `${label || ''}: ${isArray(value) ? value.join(' - ') : value}`;
    const color = breached ? token.colorError : '#999999';
    const colorBorder = breached ? token.colorError : token.colorBorder;
    return (
      Object.keys(restriction).length > 0 && (
        <Tooltip title={title} color={color} key={key}>
          <div
            style={{
              border: `1px solid ${colorBorder}`,
              padding: '0 5px',
              color: `${color}`,
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            {['priority', 'group'].includes(restrictionName) ? (
              <>
                <FontAwesomeIcon icon={['fas', restrictionIcon]} color={color} />
                {value}
              </>
            ) : (
              <FontAwesomeIcon icon={['fas', restrictionIcon]} color={color} />
            )}
          </div>
        </Tooltip>
      )
    );
  };

  return (
    <Row align="middle" justify="end" gutter={16}>
      <Col>
        {!isDepot && (
          <Space>
            {getTrucksRestrictions()}
            {getTimeWindowRestrictions()}
            {currentRestrictions?.map((currentRestriction) =>
              getRestriction({ ...currentRestriction })
            )}
            {isVip ? (
              <Tooltip title="Vip">
                <FontAwesomeIcon icon={['fas', 'crown']} color="#F9BE27" fontSize={20} />
              </Tooltip>
            ) : (
              <div style={{ width: '23px' }} />
            )}
          </Space>
        )}
      </Col>
      <Col>
        <Text type={isDepot ? 'secondary' : ''}>
          <div style={{ width: '100px' }}>
            |{' '}
            {`${convertDateToDayjs(arrivesAt, tzOrganization, 'HH:mm')} - ${convertDateToDayjs(
              departsAt,
              tzOrganization,
              'HH:mm'
            )}`}
          </div>
        </Text>
      </Col>
    </Row>
  );
}

ItemEditIndicators.propTypes = {
  item: itemType.isRequired,
  isDepot: PropTypes.bool.isRequired,
};

export default ItemEditIndicators;
