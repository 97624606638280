import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Card, Drawer } from 'antd';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import LocaleContext from 'components/locale/LocaleContext';
import { ErrorToDisplayPropTypes } from '../drawerImporter.propTypes';

import '../style.css';

function ImporterDrawerError(props) {
  const { open, closeDrawerError, errorToDisplay } = props;
  const { name, errors } = errorToDisplay;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.demandsImport.drawerErrors' };

  const footerErrors = (
    <div className="footer-drawer-buttons">
      <Button id="exitButton" type="primary" onClick={() => closeDrawerError(errorToDisplay)}>
        {i18n.t('retryUpload', scopeI18n)}
      </Button>
    </div>
  );
  const countErrors = () => {
    const total = Object.values(errors).reduce((partialSum, val) => partialSum + val.length, 0);
    return total;
  };

  const errorFileAlert = () => {
    const { 0: fileError } = errors;
    if (!fileError) {
      return '';
    }
    return (
      <Alert
        banner
        type="error"
        message={i18n.t('errorsOnFile', scopeI18n)}
        className="alert-importer-error"
        description={
          <ul className="fa-ul">
            {fileError.map((err) =>
              err.messages.map((mess, idx) => {
                const index = `li-idx-${idx}`;
                return (
                  <li key={index}>
                    <span className="fa-li">
                      <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                    </span>
                    {mess}
                  </li>
                );
              })
            )}
          </ul>
        }
      />
    );
  };
  const errorsFileContentAlert = () => {
    const { 0: fileError } = errors;
    if (fileError) {
      return '';
    }
    return (
      <Alert
        banner
        type="error"
        message={i18n.t('haveErrorsOnImporter', { ...scopeI18n, totalErrors: countErrors() })}
        description={i18n.t('pleaseCheckDetails', scopeI18n)}
        className="alert-importer-error"
      />
    );
  };
  const errorsByRowCards = () => {
    return Object.entries(errors).map(([row, values], idxCard) => {
      if (row === '0') {
        return undefined;
      }
      const indexCard = `li1-idx-${idxCard}`;
      return (
        <Card
          key={indexCard}
          title={i18n.t('rowNumber', { ...scopeI18n, number: row })}
          size="small"
          className="card-detail-errors"
        >
          <ul className="fa-ul">
            {values.map(({ messages: error }, idx) => {
              const index = `li1-idx-${idx}`;
              return (
                <li key={index}>
                  <span className="fa-li">
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                  </span>
                  {error}
                </li>
              );
            })}
          </ul>
        </Card>
      );
    });
  };

  return (
    <Drawer
      title={name}
      open={open}
      footer={footerErrors}
      maskClosable={false}
      closable={false}
      width="439px"
      styles={{
        body: { padding: 0 },
      }}
    >
      {errorFileAlert()}
      {errorsFileContentAlert()}
      {errorsByRowCards()}
    </Drawer>
  );
}

ImporterDrawerError.defaultProps = {
  open: false,
  closeDrawerError: () => {},
  errorToDisplay: {
    name: '',
    errors: {},
  },
};

ImporterDrawerError.propTypes = {
  open: PropTypes.bool,
  closeDrawerError: PropTypes.func,
  errorToDisplay: ErrorToDisplayPropTypes,
};

export default ImporterDrawerError;
