import { Form, Input, Select, Space } from 'antd';
import chileFlag from 'assets/img/chile-flag.svg';
import colombiaFlag from 'assets/img/colombia-flag.svg';
import peruFlag from 'assets/img/peru-flag.svg';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import TitleHelp from '../TitleHelp';

const { Option } = Select;
const prefixPhones = [
  {
    value: '+56',
    title: 'Bandera de Chile',
    image: chileFlag,
  },
  {
    value: '+57',
    title: 'Bandera de Colombia',
    image: colombiaFlag,
  },
  {
    value: '+51',
    title: 'Bandera de Perú',
    image: peruFlag,
  },
];

function PhoneInput(props) {
  const { setSaveButtonDisabled, phoneIsRequired, onChangeRequiredFields } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  return (
    <Form.Item
      label={
        <TitleHelp
          title={i18n.t('commons.phoneNumber')}
          helpText={i18n.t('form.profile.helps.phoneNumber')}
        />
      }
      required={phoneIsRequired}
    >
      <Space.Compact>
        <Form.Item name="phonePrefix">
          <Select
            allowClear
            placeholder={i18n.t('commons.phonePrefix')}
            style={{
              width: 110,
            }}
          >
            {prefixPhones.map((prePhone) => {
              return (
                <Option key={prePhone.value} value={prePhone.value}>
                  <Space>
                    <img
                      src={prePhone.image}
                      alt={prePhone.title}
                      title={prePhone.title}
                      width="20px"
                    />
                    {prePhone.value}
                  </Space>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          rules={[
            () => ({
              validator(_, value) {
                if (phoneIsRequired) {
                  if (/^[0-9]{0,10}$/.test(value)) {
                    setSaveButtonDisabled(false);
                    return Promise.resolve();
                  }
                  setSaveButtonDisabled(true);
                  return Promise.reject(new Error(i18n.t('form.profile.errors.phoneNumber')));
                }
                setSaveButtonDisabled(false);
                return Promise.resolve();
              },
            }),
            { required: phoneIsRequired },
          ]}
          onChange={() => onChangeRequiredFields('phone')}
        >
          <Input placeholder={i18n.t('commons.phoneNumber')} />
        </Form.Item>
      </Space.Compact>
    </Form.Item>
  );
}

PhoneInput.defaultProps = {
  setSaveButtonDisabled: () => {},
  phoneIsRequired: false,
  onChangeRequiredFields: () => {},
};
PhoneInput.propTypes = {
  setSaveButtonDisabled: PropTypes.func,
  phoneIsRequired: PropTypes.bool,
  onChangeRequiredFields: PropTypes.func,
};

export default PhoneInput;
