import apiSlice from 'app/api/apiSlice';

/**
 * itemsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const itemsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getItemByTrackingId: builder.query({
      query: (trackingId) => `/items/get-by-tracking-id/${trackingId}`,
      providesTags: (_result, _error, id) => [{ type: 'Items', id }],
    }),
  }),
});

export const { useGetItemByTrackingIdQuery } = itemsApiSlice;
