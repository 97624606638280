import PropTypes from 'prop-types';

export const UserPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  photo: PropTypes.string,
  allocated: PropTypes.bool,
});

export const VehiclePropTypes = PropTypes.shape({
  id: PropTypes.number,
  nid: PropTypes.string,
  plate: PropTypes.string,
  loads: PropTypes.arrayOf(
    PropTypes.shape({
      load: PropTypes.number,
    })
  ),
});

const entityError = PropTypes.shape({
  label: PropTypes.string,
  glosa: PropTypes.string,
  routingSetId: PropTypes.number,
  routeOrder: PropTypes.number,
});

export const ErrorsPropTypes = PropTypes.shape({
  vehicle: entityError,
  driver: entityError,
  assistant: entityError,
});
