import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

function SearchContainer({ isVisible }) {
  return (
    isVisible && (
      <div className="search-container">
        <form className="search">
          <Input.Search
            allowClear
            size="large"
            placeholder="Buscar"
            onSearch={() => {
              console.log('search');
            }}
          />
        </form>
      </div>
    )
  );
}

SearchContainer.defaultProps = {
  isVisible: false,
};

SearchContainer.propTypes = {
  isVisible: PropTypes.bool,
};

export default SearchContainer;
