import exampleDepot from 'assets/documents/plantilla_bases.csv';
import exampleExcelDepot from 'assets/documents/plantilla_bases_excel.csv';
import exampleAdvanced from 'assets/documents/plantilla_demandas_avanzada.csv';
import exampleAdvanced2 from 'assets/documents/plantilla_demandas_avanzada_2.csv';
import exampleAdvanced3 from 'assets/documents/plantilla_demandas_avanzada_3.csv';
import exampleExcelAdvanced from 'assets/documents/plantilla_demandas_avanzada_excel.csv';
import exampleExcelAdvanced2 from 'assets/documents/plantilla_demandas_avanzada_excel_2.csv';
import exampleExcelAdvanced3 from 'assets/documents/plantilla_demandas_avanzada_excel_3.csv';
import exampleSimple from 'assets/documents/plantilla_demandas_simple.csv';
import exampleSimple2 from 'assets/documents/plantilla_demandas_simple_2.csv';
import exampleSimple3 from 'assets/documents/plantilla_demandas_simple_3.csv';
import exampleExcelSimple from 'assets/documents/plantilla_demandas_simple_excel.csv';
import exampleExcelSimple2 from 'assets/documents/plantilla_demandas_simple_excel_2.csv';
import exampleExcelSimple3 from 'assets/documents/plantilla_demandas_simple_excel_3.csv';
import exampleSuperAdvanced from 'assets/documents/plantilla_demandas_super_avanzada.csv';
import exampleSuperAdvanced2 from 'assets/documents/plantilla_demandas_super_avanzada_2.csv';
import exampleSuperAdvanced3 from 'assets/documents/plantilla_demandas_super_avanzada_3.csv';
import exampleExcelSuperAdvanced from 'assets/documents/plantilla_demandas_super_avanzada_excel.csv';
import exampleExcelSuperAdvanced2 from 'assets/documents/plantilla_demandas_super_avanzada_excel_2.csv';
import exampleExcelSuperAdvanced3 from 'assets/documents/plantilla_demandas_super_avanzada_excel_3.csv';
import exampleVehicle from 'assets/documents/plantilla_vehiculos.csv';
import exampleVehicle2 from 'assets/documents/plantilla_vehiculos_2.csv';
import exampleVehicle3 from 'assets/documents/plantilla_vehiculos_3.csv';
import exampleVehicleExcel from 'assets/documents/plantilla_vehiculos_excel.csv';
import exampleVehicleExcel2 from 'assets/documents/plantilla_vehiculos_excel_2.csv';
import exampleVehicleExcel3 from 'assets/documents/plantilla_vehiculos_excel_3.csv';
import exampleStop from 'assets/documents/plantilla_visitas.csv';
import exampleExcelStop from 'assets/documents/plantilla_visitas_excel.csv';
import dataQuestion from 'components/Help/data.json';
import { find } from 'lodash';

// list of all files download for importer
const downloadFiles = [
  {
    load: 1,
    file: exampleSimple,
    fileType: 'csv',
    typeUpload: 'simple',
    download: 'plantilla_demandas_simple.csv',
  },
  {
    load: 2,
    file: exampleSimple2,
    fileType: 'csv',
    typeUpload: 'simple',
    download: 'plantilla_demandas_simple.csv',
  },
  {
    load: 3,
    file: exampleSimple3,
    fileType: 'csv',
    typeUpload: 'simple',
    download: 'plantilla_demandas_simple.csv',
  },
  {
    load: 1,
    file: exampleExcelSimple,
    fileType: 'xlsx',
    typeUpload: 'simple',
    download: 'plantilla_demandas_simple_excel.csv',
  },
  {
    load: 2,
    file: exampleExcelSimple2,
    fileType: 'xlsx',
    typeUpload: 'simple',
    download: 'plantilla_demandas_simple_excel.csv',
  },
  {
    load: 3,
    file: exampleExcelSimple3,
    fileType: 'xlsx',
    typeUpload: 'simple',
    download: 'plantilla_demandas_simple_excel.csv',
  },
  {
    load: 1,
    file: exampleAdvanced,
    fileType: 'csv',
    typeUpload: 'advanced',
    download: 'plantilla_demandas_avanzada.csv',
  },
  {
    load: 2,
    file: exampleAdvanced2,
    fileType: 'csv',
    typeUpload: 'advanced',
    download: 'plantilla_demandas_avanzada.csv',
  },
  {
    load: 3,
    file: exampleAdvanced3,
    fileType: 'csv',
    typeUpload: 'advanced',
    download: 'plantilla_demandas_avanzada.csv',
  },
  {
    load: 1,
    file: exampleExcelAdvanced,
    fileType: 'xlsx',
    typeUpload: 'advanced',
    download: 'plantilla_demandas_avanzada_excel.csv',
  },
  {
    load: 2,
    file: exampleExcelAdvanced2,
    fileType: 'xlsx',
    typeUpload: 'advanced',
    download: 'plantilla_demandas_avanzada_excel.csv',
  },
  {
    load: 3,
    file: exampleExcelAdvanced3,
    fileType: 'xlsx',
    typeUpload: 'advanced',
    download: 'plantilla_demandas_avanzada_excel.csv',
  },
  {
    load: 1,
    file: exampleSuperAdvanced,
    fileType: 'csv',
    typeUpload: 'super-advanced',
    download: 'plantilla_demandas_super_avanzada.csv',
  },
  {
    load: 2,
    file: exampleSuperAdvanced2,
    fileType: 'csv',
    typeUpload: 'super-advanced',
    download: 'plantilla_demandas_super_avanzada.csv',
  },
  {
    load: 3,
    file: exampleSuperAdvanced3,
    fileType: 'csv',
    typeUpload: 'super-advanced',
    download: 'plantilla_demandas_super_avanzada.csv',
  },
  {
    load: 1,
    file: exampleExcelSuperAdvanced,
    fileType: 'xlsx',
    typeUpload: 'super-advanced',
    download: 'plantilla_demandas_super_avanzada_excel.csv',
  },
  {
    load: 2,
    file: exampleExcelSuperAdvanced2,
    fileType: 'xlsx',
    typeUpload: 'super-advanced',
    download: 'plantilla_demandas_super_avanzada_excel.csv',
  },
  {
    load: 3,
    file: exampleExcelSuperAdvanced3,
    fileType: 'xlsx',
    typeUpload: 'super-advanced',
    download: 'plantilla_demandas_super_avanzada_excel.csv',
  },
  {
    load: 0,
    file: exampleStop,
    fileType: 'csv',
    typeUpload: 'STOP',
    download: 'plantilla_visitas.csv',
  },
  {
    load: 0,
    file: exampleExcelStop,
    fileType: 'xlsx',
    typeUpload: 'STOP',
    download: 'plantilla_visitas_excel.csv',
  },
  {
    load: 0,
    file: exampleStop,
    fileType: 'csv',
    typeUpload: 'STOP-edit',
    download: 'plantilla_visitas.csv',
  },
  {
    load: 0,
    file: exampleExcelStop,
    fileType: 'xlsx',
    typeUpload: 'STOP-edit',
    download: 'plantilla_visitas_excel.csv',
  },
  {
    load: 0,
    file: exampleDepot,
    fileType: 'csv',
    typeUpload: 'DEPOT',
    download: 'plantilla_bases.csv',
  },
  {
    load: 0,
    file: exampleExcelDepot,
    fileType: 'xlsx',
    typeUpload: 'DEPOT',
    download: 'plantilla_bases_excel.csv',
  },
  {
    load: 0,
    file: exampleDepot,
    fileType: 'csv',
    typeUpload: 'DEPOT-edit',
    download: 'plantilla_bases.csv',
  },
  {
    load: 0,
    file: exampleExcelDepot,
    fileType: 'xlsx',
    typeUpload: 'DEPOT-edit',
    download: 'plantilla_bases_excel.csv',
  },
  {
    load: 1,
    file: exampleVehicle,
    fileType: 'csv',
    typeUpload: 'vehicles',
    download: 'plantilla_vehiculos.csv',
  },
  {
    load: 2,
    file: exampleVehicle2,
    fileType: 'csv',
    typeUpload: 'vehicles',
    download: 'plantilla_vehiculos.csv',
  },
  {
    load: 3,
    file: exampleVehicle3,
    fileType: 'csv',
    typeUpload: 'vehicles',
    download: 'plantilla_vehiculos.csv',
  },
  {
    load: 1,
    file: exampleVehicleExcel,
    fileType: 'xlsx',
    typeUpload: 'vehicles',
    download: 'plantilla_vehiculos_excel.csv',
  },
  {
    load: 2,
    file: exampleVehicleExcel2,
    fileType: 'xlsx',
    typeUpload: 'vehicles',
    download: 'plantilla_vehiculos_excel.csv',
  },
  {
    load: 3,
    file: exampleVehicleExcel3,
    fileType: 'xlsx',
    typeUpload: 'vehicles',
    download: 'plantilla_vehiculos_excel.csv',
  },
  {
    load: 1,
    file: exampleVehicle,
    fileType: 'csv',
    typeUpload: 'vehicles-edit',
    download: 'plantilla_vehiculos.csv',
  },
  {
    load: 2,
    file: exampleVehicle2,
    fileType: 'csv',
    typeUpload: 'vehicles-edit',
    download: 'plantilla_vehiculos.csv',
  },
  {
    load: 3,
    file: exampleVehicle3,
    fileType: 'csv',
    typeUpload: 'vehicles-edit',
    download: 'plantilla_vehiculos.csv',
  },
  {
    load: 1,
    file: exampleVehicleExcel,
    fileType: 'xlsx',
    typeUpload: 'vehicles-edit',
    download: 'plantilla_vehiculos_excel.csv',
  },
  {
    load: 2,
    file: exampleVehicleExcel2,
    fileType: 'xlsx',
    typeUpload: 'vehicles-edit',
    download: 'plantilla_vehiculos_excel.csv',
  },
  {
    load: 3,
    file: exampleVehicleExcel3,
    fileType: 'xlsx',
    typeUpload: 'vehicles-edit',
    download: 'plantilla_vehiculos_excel.csv',
  },
];

/**
 * Method to get file to download, name template
 * @param {*} event
 * @param {*} loadsUnit
 * @param {*} typeUpload
 * @returns object with file, name template, type
 */
const getFileForImporter = (event, loadsUnit, typeUpload) => {
  const fileFounded =
    find(downloadFiles, {
      fileType: event.key,
      load: loadsUnit.length,
      typeUpload,
    }) ?? {};
  return fileFounded;
};

/**
 * Method to get title for importer drawer
 * @param {*} typeUploadForTitle
 * @param {*} i18n
 * @returns title translated
 */
const getTitleForImporter = (typeUploadForTitle, i18n) => {
  const scopeI18n = { scope: 'form.demandsImport' };
  let typeTitle = 'simple';
  let title = '';
  switch (typeUploadForTitle) {
    case 'simple':
      typeTitle = 'simple';
      break;
    case 'super-advanced':
      typeTitle = 'superAdvanced';
      break;
    case 'advanced':
      typeTitle = 'advanced';
      break;
    case 'STOP':
    case 'STOP-edit':
      typeTitle = 'forStops';
      break;
    case 'DEPOT':
    case 'DEPOT-edit':
      typeTitle = 'forDepots';
      break;
    case 'vehicles':
    case 'vehicles-edit':
      typeTitle = 'forVehicles';
      break;
    default:
      typeTitle = 'simple';
      break;
  }
  if (['STOP-edit', 'DEPOT-edit', 'vehicles-edit'].includes(typeUploadForTitle)) {
    title = i18n.t('updateMassiveType', {
      ...scopeI18n,
      type: i18n.t(typeTitle, scopeI18n),
    });
  } else {
    title = i18n.t('typeImport', {
      ...scopeI18n,
      type: i18n.t(typeTitle, scopeI18n),
    });
  }
  return title;
};

const getQuestionHelpForImporter = (typeUploadFromImporter) => {
  const byType = dataQuestion
    .filter(({ typeUpload }) => typeUpload === typeUploadFromImporter)
    .map(({ title: question, key }) => ({
      question,
      key: `${key}?origin=${typeUploadFromImporter}`,
    }));

  return byType;
};

export { getFileForImporter, getTitleForImporter, getQuestionHelpForImporter };
