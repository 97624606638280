import apiSlice from 'app/api/apiSlice';

/**
 * demandSetsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const demandSetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDemandsSets: builder.query({
      query: () => '/demand-sets',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'DemandsSets', id })),
              { type: 'DemandsSets', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'DemandsSets', id: 'PARTIAL-LIST' }],
    }),
    getDemandsSet: builder.query({
      query: (id) => `/demand-sets/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'DemandsSets', id }],
    }),
    getDemandsByDemandSet: builder.query({
      query: (demandSetId) => ({
        url: `demand-sets/${demandSetId}`,
      }),
      providesTags: (_result, _error, id) => [{ type: 'DemandsSets', id }],
    }),
    updateDemandSet: builder.mutation({
      query: (params) => ({
        url: `demand-sets/${params.demandSetIdEdited}`,
        method: 'PATCH',
        body: { ...params.fields },
      }),
      invalidatesTags: ['DemandsSets'],
    }),
    deleteOrRestoreDemandSet: builder.mutation({
      query: (demandSetId) => ({
        url: `demand-sets/change-status/${demandSetId}`,
        method: 'POST',
      }),
      invalidatesTags: ['DemandsSets'],
    }),
    getDemandsSetsByFilters: builder.mutation({
      query: (query) => ({
        url: `demand-sets${query}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'DemandsSets', id })),
              { type: 'DemandsSets', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'DemandsSets', id: 'PARTIAL-LIST' }],
    }),
    uploadDemandSets: builder.mutation({
      query: ({ file }) => {
        const fmData = new FormData();
        fmData.append('file', file);
        return {
          url: '/demand-sets/upload',
          prepareHeaders: (headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: [
        // 'DemandSets', // is proccesed by lamda, so delay to end of process
        'FileProcessing',
      ],
    }),
    downloadDemandSet: builder.mutation({
      query: ({ id, format }) => {
        return {
          url: `/demand-sets/download/${id}/${format}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['FileProcessing'],
    }),
    uploadSimpleDemandSets: builder.mutation({
      query: ({ file }) => {
        const fmData = new FormData();
        fmData.append('file', file);
        return {
          url: '/demand-sets/upload-simple',
          prepareHeaders: (headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: [
        // 'DemandSets', // is proccesed by lamda, so delay to end of process
        'FileProcessing',
      ],
    }),
    uploadSuperAdvancedDemandSets: builder.mutation({
      query: ({ file }) => {
        const fmData = new FormData();
        fmData.append('file', file);
        return {
          url: '/demand-sets/upload-super-advanced',
          prepareHeaders: (headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: [
        // 'DemandSets', // is proccesed by lamda, so delay to end of process
        'FileProcessing',
      ],
    }),
  }),
});

export const {
  useGetDemandsSetsQuery,
  useGetDemandsSetQuery,
  useGetDemandsByDemandSetQuery,
  useUpdateDemandSetMutation,
  useDeleteOrRestoreDemandSetMutation,
  useGetDemandsSetsByFiltersMutation,
  useUploadDemandSetsMutation,
  useDownloadDemandSetMutation,
  useUploadSimpleDemandSetsMutation,
  useUploadSuperAdvancedDemandSetsMutation,
} = demandSetsApiSlice;
