import { Col, Progress, Row, Table, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext } from 'react';
import { FollowingRoutesProps } from '../../following.propTypes';

const { Text } = Typography;

function ProgressAndTable(props) {
  const { allRoutes } = props;
  const totalRoutes = allRoutes.length;
  const routesByStates = {
    FINISHED: 0,
    CREATED: 0,
    ASSIGNED: 0,
    STARTED: 0,
  };
  allRoutes.forEach((route) => {
    const { status } = route;
    routesByStates[status] += 1;
  });
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const calculatePercentPerTotal = (numberRoutes) => {
    if (numberRoutes === 0) {
      return 0;
    }
    return Math.round((numberRoutes * 100) / totalRoutes);
  };
  const finishedRoutes = routesByStates.FINISHED;
  const percentFinished = calculatePercentPerTotal(finishedRoutes);

  const defaultPadding = {
    paddingTop: '6px',
    paddingBottom: '6px',
  };
  const columns = [
    {
      dataIndex: 'status',
      key: 'status',
      onCell: () => {
        return {
          style: {
            background: '#f9f9f9',
            width: '140px',
            ...defaultPadding,
          },
        };
      },
    },
    {
      dataIndex: 'total',
      key: 'total',
      onCell: () => {
        return {
          style: {
            width: '40px',
            ...defaultPadding,
          },
        };
      },
    },
    {
      dataIndex: 'percentage',
      key: 'percentage',
      onCell: () => {
        return {
          style: defaultPadding,
        };
      },
      render: (value) => <Text type="secondary">{value} %</Text>,
    },
  ];

  const states = ['FINISHED', 'CREATED', 'ASSIGNED', 'STARTED'];
  const getTitle = (status) => {
    let title;
    switch (status) {
      case 'CREATED':
        title = i18n.t('routeStatus.notAssigned', scopeI18n);
        break;
      case 'ASSIGNED':
        title = i18n.t('routeStatus.notStarted', scopeI18n);
        break;
      case 'STARTED':
        title = i18n.t('routeStatus.inExecution', scopeI18n);
        break;
      case 'FINISHED':
        title = i18n.t('routeStatus.finished', scopeI18n);
        break;
      default:
        title = i18n.t('routeStatus.notAssigned', scopeI18n);
        break;
    }
    return title;
  };
  const data = states.map((statusUp) => {
    const total = routesByStates[statusUp];
    return {
      key: statusUp,
      status: getTitle(statusUp),
      total,
      percentage: calculatePercentPerTotal(total),
    };
  });

  return (
    <Row gutter={[8, 24]}>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Progress
          type="circle"
          percent={percentFinished}
          size={97}
          format={() => `${finishedRoutes}/${totalRoutes}`}
        />
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          showHeader={false}
        />
      </Col>
    </Row>
  );
}

ProgressAndTable.defaultProps = {
  allRoutes: [],
};

ProgressAndTable.propTypes = {
  allRoutes: FollowingRoutesProps,
};

export default ProgressAndTable;
