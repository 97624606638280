import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Typography } from 'antd';
import CascadeFilter from 'components/common/TableFilter/CascadeFilter';
import GrouppedButtonFilter from 'components/common/TableFilter/GrouppedButtonsFilter';
import InputFilter from 'components/common/TableFilter/InputFilter';
import RangePickerFilter from 'components/common/TableFilter/RangePickerFilter';
import SelectFilter from 'components/common/TableFilter/SelectFilter';
import dayjs from 'dayjs';
import durationDayjs from 'dayjs/plugin/duration';
import timezoneDayjs from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import 'moment/locale/es';
// add timezone for all dayjs instances
dayjs.extend(utc);
dayjs.extend(durationDayjs);
dayjs.extend(timezoneDayjs);

const { Text } = Typography;

const urlBase = process.env.REACT_APP_CORE_BACKEND;

// ADD: functions repeated as helper or others

/**
 * Return color using order for calc
 * @param {*} order order in route
 * @returns hsla color
 */
function ColorByOrder(order) {
  return `hsla(${order * 55 + 100},50%,50%,1)`;
}
/**
 * Method to convert date to timezone and format if this is passed
 * @param {*} date string with date format
 * @param {*} timezone string with name of timezone
 * @param {*} format string with format to parse
 * @returns date converted to dayjs or string formated date
 */
const convertDateToDayjs = (date, timezone, format = undefined) => {
  const dateOnTimezone = dayjs(date).tz(timezone);
  if (!dateOnTimezone.isValid()) {
    return undefined;
  }
  return format ? dateOnTimezone.format(format) : dateOnTimezone;
};

/**
 * Format value parsed to dayjs, with timezone to format HH:mm
 * @param {*} value date time value to be parsed
 * @param {*} timezone timezone to be parsed
 * @returns date time as string formatted
 */
function FormatToTime(value, timezone) {
  if (timezone) {
    return convertDateToDayjs(value, timezone, 'HH:mm');
  }
  return dayjs(value).format('HH:mm');
}

/**
 * Calculate diff between 2 dates, as object with hour:minutes and seconds to be used
 * @param {*} real real time to be compared
 * @param {*} planned planned time to be compared
 * @returns object dayjs with hh:mm difference between real and planned times; and seconds of diff
 */
function CalculateDiffTimes(real, planned) {
  const duration = dayjs.duration(dayjs(real).diff(dayjs(planned)));
  const seconds = duration.asSeconds();
  const initialTime = dayjs().startOf('day');
  const newTime = initialTime.add(Math.abs(seconds), 'seconds');
  return { newTime, seconds };
}

/**
 * Compare if type is depot or rtd
 * @param {*} type type of item
 * @returns boolean true if is depot
 */
function IsDepotOrRtd(type) {
  return ['DEPOT', 'RETURN_TO_DEPOT'].includes(type);
}

/**
 * Gives the duration in words for the seconds given
 * @param {*} time number of seconds to convert to words
 * @returns time in words of the duration given
 */
function secondsToWords(time) {
  if (time === 0) {
    return '';
  }
  const duration = dayjs.duration(Math.abs(time), 'seconds');

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return [
    days > 0 && `${days} días`,
    hours > 0 && `${hours} h`,
    minutes > 0 && `${minutes} min`,
    days + hours + minutes === 0 && seconds > 0 && `${seconds}s`,
  ]
    .filter(Boolean)
    .join(' ');
}

/**
 * Gives the status of the route in words
 * @param {*} time number of seconds to convert to words
 * @returns String with the time status
 */
function timeStatus(time) {
  if (time === 0) {
    return 'A tiempo';
  }
  if (time > 0) {
    return `Adelanto: +${secondsToWords(time)}`;
  }
  return `Atraso: -${secondsToWords(time)}`;
}

/**
 * Calculate ETA bewtween item and previous ite
 * @param {*} item actual item to calculate eta
 * @param {*} items all items to find previous
 * @param {*} startedAt route startedAt, used when previous item is depot
 * @param {*} timezone timezone from org
 * @param {*} otherTimes list of other times in seconds to be added. As load time, or others
 * @param {*} baseDate date from calculate the eta from
 * @returns Eta date and time diference in seconds
 */
function GetEtaFromDate(
  item,
  items,
  startedAt,
  timezone,
  otherTimes,
  isAddition,
  baseDate = new Date()
) {
  const { arrivesAt } = item;
  const myIndex = items.indexOf(item);
  if (myIndex > 0) {
    // always find previous item
    const prevItem = items[myIndex - 1];
    const { arrivedAt: real, arrivesAt: planned, type } = prevItem;
    const dateToUse = type === 'DEPOT' && startedAt ? startedAt : real;
    if (dateToUse) {
      const secondsFromPrevItem =
        (isAddition ? 1 : -1) * CalculateDiffTimes(dateToUse, planned).seconds;
      let eta = convertDateToDayjs(baseDate, timezone).add(secondsFromPrevItem, 'seconds');
      // ToDo: add other times, times for download/load etc
      otherTimes.forEach((timeToAdd) => {
        eta = eta.add(timeToAdd, 'seconds');
      });
      return { eta: new Date(eta), timeDifference: secondsFromPrevItem };
    }
    return { eta: undefined, timeDifference: undefined };
  }
  // for first item eta is planned time
  return {
    eta: new Date(arrivesAt),
    timeDifference: (isAddition ? 1 : -1) * CalculateDiffTimes(baseDate, arrivesAt).seconds,
  };
}

/**
 * Calculate ETA bewtween item and previous item
 * @param {*} item actual item to calculate eta
 * @param {*} items all items to find previous
 * @param {*} startedAt route startedAt, used when previous item is depot
 * @param {*} timezone timezone from org
 * @param {*} otherTimes list of other times in seconds to be added. As load time, or others
 * @returns Formatted ETA as HH:mm, or arrivesAt if is first item, return undefined if dont have arrivedAt prevItem
 */
function CalculateEta(item, items, startedAt, timezone, otherTimes) {
  const { arrivesAt } = item;
  const { eta } = GetEtaFromDate(item, items, startedAt, timezone, otherTimes, true, arrivesAt);
  return eta ? FormatToTime(eta, timezone) : undefined;
}

/**
 * Function to get color of tag
 * @param status
 * @returns Color for tag component by status
 */
function ColorByStatus(status) {
  let color = 'cyan';
  switch (status) {
    case 'PENDING':
      color = 'default';
      break;
    case 'CREATED':
      color = 'geekblue';
      break;
    case 'SUCCESS':
      color = 'green';
      break;
    case 'FAILURE':
      color = 'error';
      break;
    default:
      color = 'cyan';
  }
  return color;
}

/**
 * Function to get color of tag
 * @param status
 * @returns Color for tag component by route status
 */
function ColorByRouteStatus(status) {
  let color = 'gray';
  switch (status) {
    case 'CREATED':
      color = 'purple';
      break;
    case 'ASSIGNED':
      color = 'cyan';
      break;
    case 'STARTED':
      color = 'yellow';
      break;
    case 'FINISHED':
      color = 'gray';
      break;
    default:
      color = 'cyan';
  }
  return color;
}

/**
 * Function to get text translated of status
 * @param status
 * @returns Text translated by status
 */
function TextByStatus(status) {
  let text = 'Pendiente';
  switch (status) {
    case 'PENDING':
      text = 'Pendiente';
      break;
    case 'STARTED':
      text = 'Iniciada';
      break;
    case 'ARRIVAL':
      text = 'Llegué';
      break;
    case 'POSTPONED':
      text = 'Vuelvo más tarde';
      break;
    case 'PARTIAL':
      text = 'Parcial';
      break;
    case 'CREATED':
      text = 'Creado';
      break;
    case 'SUCCESS':
      text = 'Activo';
      break;
    case 'FAILURE':
      text = 'Fallido';
      break;
    default:
      text = 'Pendiente';
  }
  return text;
}

/**
 * Function to get text translated of status
 * @param status
 * @returns Text translated by route status
 */
function TextByRouteStatus(status) {
  let text = 'Creada';
  switch (status) {
    case 'CREATED':
      text = 'Creada';
      break;
    case 'STARTED':
      text = 'Iniciada';
      break;
    case 'ASSIGNED':
      text = 'Asignada';
      break;
    case 'FINISHED':
      text = 'Finalizada';
      break;
    default:
      text = '--';
  }
  return text;
}

/**
 * Function to get color of status event
 * @param status
 * @returns Color for status
 */
function ColorByStatusEvent(status) {
  let color = '#999999';
  switch (status) {
    case 'PENDING':
    case 'Pendiente':
    case 'Pending':
      color = '#F5F5F5';
      break;
    case 'PARTIAL':
    case 'Parcial':
    case 'Partial':
      color = '#D39E00';
      break;
    case 'SUCCESS':
    case 'Exitoso':
    case 'Successful':
      color = '#128403';
      break;
    case 'FAILURE':
    case 'ALERT':
    case 'Fallido':
    case 'Failed':
      color = '#BB0204';
      break;
    default:
      color = '#999999';
  }
  return color;
}

/**
 * Function to get color of status event
 * @param status
 * @returns Color for status
 */
function ColorByCognitoUserStatus(status) {
  let color = '#999999';
  switch (status) {
    case 'FORCE_CHANGE_PASSWORD':
      color = '#D39E00';
      break;
    case 'CONFIRMED':
      color = '#128403';
      break;
    case 'DELETED':
      color = '#BB0204';
      break;
    default:
      color = '#999999';
  }
  return color;
}

/**
 * Function to get color of status event on hsla format
 * @param status
 * @returns Color for status
 */
function HslaColorByStatusEvent(status) {
  let hslaColor = 'hsla(0, 0%, 95%, 1)'; // Default color: light gray
  switch (status) {
    case 'PENDING':
    case 'Pendiente':
      hslaColor = 'hsla(0, 0%, 60%, 1)'; // Gray
      break;
    case 'PARTIAL':
    case 'Parcial':
      hslaColor = 'hsla(42, 100%, 40%, 1)'; // Orange
      break;
    case 'SUCCESS':
    case 'Exitoso':
      hslaColor = 'hsla(120, 100%, 25%, 1)'; // Green
      break;
    case 'FAILURE':
    case 'ALERT':
    case 'Fallido':
      hslaColor = 'hsla(0, 100%, 40%, 1)'; // Red
      break;
    default:
      hslaColor = 'hsla(0, 0%, 95%, 1)'; // Default color: light gray
  }
  return hslaColor;
}

/**
 * Function to get text translated of status of events
 * @param status
 * @returns Text translated by status
 */
function TextByStatusEvent(status, i18n) {
  const scopeI18n = { scope: 'commons.eventStatus' };
  let text = 'Pendiente';
  switch (status) {
    case 'ARRIVAL':
      text = i18n.t('arrival', scopeI18n);
      break;
    case 'POSTPONED':
      text = i18n.t('postponed', scopeI18n);
      break;
    case 'PARTIAL':
      text = i18n.t('partial', scopeI18n);
      break;
    case 'SUCCESS':
      text = i18n.t('success', scopeI18n);
      break;
    case 'FAILURE':
      text = i18n.t('failure', scopeI18n);
      break;
    case 'ALERT':
      text = i18n.t('alert', scopeI18n);
      break;
    default:
      text = i18n.t('default', scopeI18n);
  }
  return text;
}

/**
 * Function to get text translated of cognito user status
 * @param status
 * @returns Text translated by status
 */
function TextByCognitoUserStatus(status, i18n) {
  const scopeI18n = { scope: 'commons.cognitoUserStatus' };
  let response = {};
  switch (status) {
    case 'UNCONFIRMED':
      response = {
        text: i18n.t('unconfirmed', scopeI18n),
        description: i18n.t('unconfirmedDescription', scopeI18n),
      };
      break;
    case 'CONFIRMED':
      response = {
        text: i18n.t('confirmed', scopeI18n),
        description: i18n.t('confirmedDescription', scopeI18n),
      };
      break;
    case 'ARCHIVED':
      response = {
        text: i18n.t('archived', scopeI18n),
        description: i18n.t('archivedDescription', scopeI18n),
      };
      break;
    case 'COMPROMISED':
      response = {
        text: i18n.t('compromised', scopeI18n),
        description: i18n.t('compromisedDescription', scopeI18n),
      };
      break;
    case 'UNKNOWN':
      response = {
        text: i18n.t('unknown', scopeI18n),
        description: i18n.t('unknownDescription', scopeI18n),
      };
      break;
    case 'RESET_REQUIRED':
      response = {
        text: i18n.t('resetRequired', scopeI18n),
        description: i18n.t('resetRequiredDescription', scopeI18n),
      };
      break;
    case 'FORCE_CHANGE_PASSWORD':
      response = {
        text: i18n.t('forceChangePassword', scopeI18n),
        description: i18n.t('forceChangePasswordDescription', scopeI18n),
      };
      break;
    case 'DELETED':
      response = {
        text: i18n.t('deleted', scopeI18n),
        description: i18n.t('deletedDescription', scopeI18n),
      };
      break;
    default:
      response = {
        text: i18n.t('default', scopeI18n),
        description: i18n.t('defaultDescription', scopeI18n),
      };
  }
  return response;
}

/**
 * Function to get color y text from status events
 * @param status
 * @returns object with color y text translated
 */
function DataByStatusEvent(status, i18n) {
  const color = ColorByStatusEvent(status);
  const text = TextByStatusEvent(status, i18n);
  return { color, text };
}

/**
 * Function to get color y text from status
 * @param status
 * @returns object with color y text translated
 */
function DataByStatus(status) {
  const color = ColorByStatus(status);
  const text = TextByStatus(status);
  return { color, text };
}

/**
 * Function to get color y text from route status
 * @param status
 * @returns object with color y text translated
 */
function DataByRouteStatus(status) {
  const color = ColorByRouteStatus(status);
  const text = TextByRouteStatus(status);
  return { color, text };
}

/**
 * Function to get color y text from cognito user status
 * @param status
 * @returns object with color y text translated
 */
function DataByCognitoUserStatus(status, i18n) {
  const color = ColorByCognitoUserStatus(status);
  const { text, description } = TextByCognitoUserStatus(status, i18n);
  return { color, text, description };
}

/**
 * Function to get the color based on the status received
 * Color will be displayed in the route detail and edit view
 * @param status
 * @returns Color of status
 */
function ColorByRouteStatusTag(status) {
  let borderColor;
  let backgroundColor;
  let color;
  switch (status) {
    case 'CREATED':
      borderColor = '#D3ADF7';
      backgroundColor = '#F9F0FF';
      color = '#722ED1';
      break;
    case 'ASSIGNED':
      borderColor = '#87E8DE';
      backgroundColor = '#E6FFFB';
      color = '#13C2C2';
      break;
    case 'STARTED':
      borderColor = '#D4B106';
      backgroundColor = '#FEFFE6';
      color = '#D4B106';
      break;
    case 'FINISHED':
      borderColor = '#808080';
      backgroundColor = '#808080';
      color = '#FFFFFF';
      break;
    default:
      break;
  }
  return { borderColor, backgroundColor, color };
}

/**
 * Function to get the text translated by status.
 * This text will be displayed in the route detail and edit view
 * @param status
 * @returns Text translated by status
 */
function TextByRouteStatusTag(status) {
  let text = 'Creada';
  switch (status) {
    case 'CREATED':
      text = 'Creada';
      break;
    case 'ASSIGNED':
      text = 'Asignada';
      break;
    case 'STARTED':
      text = 'En curso';
      break;
    case 'FINISHED':
      text = 'Finalizada';
      break;
    default:
      text = '--';
  }
  return text;
}

/**
 * Function to get text and color based on the status received
 * The text and color will be displayed in the route detail and edit view
 * @param {*} status
 * @returns an object with the colors and the text to be displayed
 */
function DataByRouteStatusTag(status) {
  const { borderColor, backgroundColor, color } = ColorByRouteStatusTag(status);
  const text = TextByRouteStatusTag(status);
  return { borderColor, backgroundColor, color, text };
}

/**
 * Function to get definition of columns for roles view
 * @returns array with initial, glosa and width
 */
function DefRoleColumns() {
  return [
    {
      initial: 'R',
      glosa: 'Ver',
      width: 70,
    },
    {
      initial: 'C',
      glosa: 'Crear',
      width: 85,
    },
    {
      initial: 'U',
      glosa: 'Editar',
      width: 85,
    },
    {
      initial: 'D',
      glosa: 'Archivar',
      width: 105,
    },
  ];
}

/**
 * Function to get urlBase + url in params
 * @param {*} url to be added to base
 * @returns new url
 */
function GetUrl(url = '') {
  const lastString = urlBase.slice(-1);
  const subPath = lastString === '/' ? `${urlBase}${url}` : `${urlBase}/${url}`;
  return url.startsWith('http://') || url.startsWith('https://') ? url : subPath;
}

/**
 * Function to get loads from form, join all in an array to be sended to backend
 * @param {*} valuesForm
 * @returns array with object load
 */
function BuildLoadInForm(valuesForm) {
  const regex = /loads\[\d+\]/;
  const keysLoads = Object.keys(valuesForm).filter((obKey) => obKey.match(regex));
  const loads = keysLoads.map((keyLoad) => {
    return {
      load: parseInt(valuesForm[keyLoad], 10),
    };
  });
  return loads;
}

/**
 * Function to build Custom Attributes to be sended to backend
 * @param {*} valuesForm
 * @returns array with customAttributeId and value
 */
function BuildCustomAttributes(valuesForm) {
  const regex = /ca\[\d+\]/;
  const keysCa = Object.keys(valuesForm).filter((obKey) => obKey.match(regex));
  const caes = [];
  keysCa.forEach((value) => {
    const id = value.match(/\d+/);
    const valueForm = valuesForm[value];
    const isDate = valueForm instanceof dayjs;
    const parsedValue = isDate ? dayjs(valueForm).format('YYYY-MM-DD') : valueForm;
    if (parsedValue !== undefined) {
      const finalValue = parsedValue === null ? '' : parsedValue;
      caes.push({ customAttributeId: parseInt(id[0], 10), value: `${finalValue}` });
    }
  });
  return caes;
}

function displayUnauthorizedNotification(notification, module = 'página') {
  notification.error({
    message: 'Error 403',
    description: `Sin permisos para esta ${module}.`,
    icon: <FontAwesomeIcon icon={['fas', 'lock']} style={{ color: 'rgb(227, 0, 1)' }} />,
  });
}

/**
 * Function to display module on sidebar if has at least on permission on this module, and can read this
 * @param {*} actions list of actions for current user
 * @param {*} module module to be checked
 * @returns boolean if has match
 */
// ToDo: this hide all sub-modules, add other permissions to role or access for url until all system use permission
function haveAtLeastOneSubModule(actions, module) {
  return actions.some((action) => {
    return action.permission.module === module && action.initial === 'R';
  });
}

/**
 * Function to check if a submenu is index, and which is him path to build route
 * @param {*} subMenus List of submenus/permissions
 * @param {*} indexToCheck position in submenus array to check if is index
 * @param {*} ability Ability with all permission for check
 * @returns object with index and path { index: true, path: '' }
 */
function defineIndexRoute(subMenus, indexToCheck, ability) {
  let isIndex = false;
  let path = '';
  // check if position 0 is the pointer
  if (indexToCheck === 0) {
    isIndex = ability.can('read', subMenus[indexToCheck]);
  } else {
    // check left elemetns if someone is index
    const leftItems = subMenus.slice(0, indexToCheck);
    const someLeftIsIndex = leftItems.some((subM) => ability.can('read', subM));
    // if someLeftIsIndex assign path
    if (someLeftIsIndex) {
      path = subMenus[indexToCheck];
    } else {
      // if none in left is index check if I am
      isIndex = ability.can('read', subMenus[indexToCheck]);
    }
  }
  // if not is index assign path
  if (!isIndex) {
    path = subMenus[indexToCheck];
  }
  return {
    index: isIndex,
    path,
    key: subMenus[indexToCheck],
  };
}

/**
 * Function to check if ability can do something
 * in ability I can <action> this <subject>
 * @param {*} ability instance of ability
 * @param {*} action action to be checked
 * @param {*} subject entity name
 * @returns boolean
 */
function checkPermissionForSubject(ability, action, subject) {
  return ability.can(action, subject);
}

/**
 * Function to get all check from an array of submenus
 * @param {*} subMenus List of submenus/permissions
 * @param {*} ability Ability with all permission for check
 * @returns array with objects with index and path [{ index: true, path: '' }]
 */
function getAllIndexFromArray(subMenus, ability) {
  const indexPermissions = {};
  subMenus.forEach((element, index) => {
    indexPermissions[element] = defineIndexRoute(subMenus, index, ability);
  });
  return indexPermissions;
}

/**
 * Function to get title for drawer, with form and show
 * @param {*} type type of view, create | edit | show
 * @param {*} entityName name of entity to display @example vehículo
 * @param {*} entityId id to be displayed @example nid
 * @returns full title
 */
function getTitleToDrawer(type, entityName, entityId) {
  let title;
  switch (type) {
    case 'create':
      title = `Crear ${entityName}`;
      break;
    case 'edit':
      title = `Editar ${entityName} [${entityId}]`;
      break;
    default:
      title = `Detalle de ${entityName} [${entityId}]`;
      break;
  }
  return title;
}

/**
 * Function to get label by status
 * @param {*} status event status
 * @returns event status translated
 */
function defineLabelByStatus(status, i18n) {
  const scopeI18n = { scope: 'commons.statuses' };
  let label;
  switch (status) {
    case 'success':
    case 'SUCCESS':
      label = i18n.t('success', scopeI18n);
      break;
    case 'partial':
    case 'PARTIAL':
      label = i18n.t('partial', scopeI18n);
      break;
    case 'failed':
    case 'FAILURE':
      label = i18n.t('failed', scopeI18n);
      break;
    case 'postponed':
    case 'POSTPONED':
      label = i18n.t('postponed', scopeI18n);
      break;
    case 'pending':
    case 'ARRIVAL':
    case 'PENDING':
      label = i18n.t('pending', scopeI18n);
      break;
    default:
      label = i18n.t('default', scopeI18n);
  }
  return label;
}

/**
 * Function to convert a time value to minutes based on it's time measure
 * @param {*} value Number. Time value to be converted
 * @param {*} timeMeasure String. Specifies kind of time measure of the value.
 * @returns the converted value in minutes, default option it's minutes.
 */
function ConvertToMinutes(value, timeMeasure) {
  switch (timeMeasure) {
    case 'days':
      return value * 1440;
    case 'hours':
      return value * 60;
    default:
      return value;
  }
}

/**
 * Function to change item position in array
 * @param {number} from Original index position
 * @param {number} to Destination index position
 * @param {array} arr Array of item values
 * @return {array} newArr Array with items reorordered
 */
function moveItem(from, to, arr) {
  const newArr = [...arr];
  const itemValues = newArr.splice(from, 1)[0];
  newArr.splice(to, 0, itemValues);
  return newArr;
}

/**
 * Function to insert item in specific index in array
 * @param {array} arr Array of item values
 * @param {number} index New position
 * @param {object} newItem Item object to insert
 * @return {array} arr Items with new item reorordered
 */
function insertItem(arr, index, newItem) {
  const itemIds = arr.filter((i) => i.id < 0).map((iId) => iId.id);
  let calcItemId = -1;
  if (itemIds.length > 0) {
    calcItemId = Math.min(...itemIds) - 1;
  }
  return [...arr.slice(0, index), { id: calcItemId, ...newItem }, ...arr.slice(index)];
}

/**
 * Function to calculate the item id based on all the items in the routes
 * @param {array} routes Array of routes with items
 * @return {number} calculated item id
 */
function calculateItemId(routes) {
  // get all item ids to get min, for not repeated ids
  const allItemIds = routes.reduce(
    (accumulator, { items: itemsFromRoute }) =>
      itemsFromRoute
        .filter(({ type, id }) => ['STOP', 'REST'].includes(type) && id < 0)
        .map(({ id }) => id)
        .concat(accumulator),
    []
  );
  let calcItemId = -1;
  if (allItemIds.length > 0) {
    calcItemId = Math.min(...allItemIds) - 1;
  }
  return calcItemId;
}

/**
 * Function to insert a new item in specific index in array
 * @param {array} items Array of item values
 * @param {number} index New position
 * @param {object} newItem Item object to insert
 * @param {array} routes Array of routes with items
 * @return {array} arr Items with new item reorordered
 */
function insertNewItem(items, index, newItem, routes) {
  const calcItemId = calculateItemId(routes);
  return [...items.slice(0, index), { id: calcItemId, ...newItem }, ...items.slice(index)];
}

/**
 * Function to get string between two substrings
 * @param {string} str Original string
 * @param {string} char1 Left string
 * @param {string} char2 Right string
 * @return {string} Founded string
 */
function getSubstring(str, char1, char2) {
  return str.substring(str.indexOf(char1) + 1, str.lastIndexOf(char2));
}

function cloneRouteObject(routes, newRouteOrder) {
  const routeIds = routes.filter((r) => r.id < 0).map((rId) => rId.id);
  let calcRouteId = -1;
  if (routeIds.length > 0) {
    calcRouteId = Math.min(...routeIds) + -1;
  }
  const firstRoute = routes[0];
  const depot = firstRoute.items.find((arrItems) => arrItems.type === 'DEPOT');
  const rtd = firstRoute.items.find((arrItems) => arrItems.type === 'RETURN_TO_DEPOT');
  return {
    ...firstRoute,
    id: calcRouteId,
    routeOrder: newRouteOrder,
    vehicle: null,
    driver: null,
    editable: true,
    items: [
      { ...depot, id: `d-${newRouteOrder}`, itemOrder: 0 },
      { ...rtd, id: `rtd-${newRouteOrder}`, itemOrder: 1 },
    ],
  };
}

function statusByEvents(events) {
  if (events.length > 0) {
    const foundStatus = ['SUCCESS', 'FAILURE', 'PARTIAL'].find((status) =>
      events.some((event) => event.status === status)
    );
    if (foundStatus) {
      return foundStatus;
    }
    // first report
    if (events.length === 1 && events.every((event) => event.status === 'ARRIVAL')) {
      return 'PENDING';
    }
    // postponed flow arrival -> postponed -> arrival -> postponed
    if (
      events.length > 1 &&
      events.every((event) => ['ARRIVAL', 'POSTPONED'].includes(event.status))
    ) {
      return 'PENDING';
    }
  } // case not reported add as pending
  return 'PENDING';
}

function totalsByEvents(items) {
  const totales = {
    pa: {
      SUCCESS: 0,
      FAILURE: 0,
      PARTIAL: 0,
      PENDING: 0,
    },
    pe: {
      SUCCESS: 0,
      FAILURE: 0,
      PARTIAL: 0,
      PENDING: 0,
    },
  };
  const now = dayjs();
  items.forEach((item) => {
    const isPe = dayjs(item.departsAt) > now;
    const { events = [] } = item;
    const status = statusByEvents(events);
    totales.pa[status] += 1;
    if (isPe) {
      totales.pe[status] += 1;
    }
  });
  return totales;
}
const sortSelect = (optionA, optionB) =>
  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

function groupByEntity(objectArray, property) {
  return objectArray
    .filter(({ entities }) => entities.includes(property))
    .reduce((acc, obj) => {
      const key = property;
      const curGroup = acc[key] ?? [];
      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
}

function getSettingsValues(settingsFields) {
  const valuesFromDb = {};
  if (settingsFields && settingsFields?.length > 0) {
    const fields = settingsFields[0].keyValue;
    fields.forEach((field) => {
      Object.keys(field).forEach((nameField) => {
        const valueField = field[nameField];
        valuesFromDb[nameField] = valueField;
      });
    });
    // assign id
    valuesFromDb.id = settingsFields[0]?.id;
  }
  return valuesFromDb;
}

const entities = [
  { label: 'Demandas', value: 'DEMAND' },
  { label: 'Visitas', value: 'LOCATION' },
  { label: 'Base', value: 'DEPOT' },
  { label: 'Vehículos', value: 'VEHICLE' },
];
const inputTypes = [
  { label: 'Número', value: 'number' },
  { label: 'Texto (string)', value: 'string' },
  { label: 'Texto (str)', value: 'str' },
  { label: 'Intervalo de fechas', value: 'timerange' },
  { label: 'Booleano (boolean)', value: 'boolean' },
  { label: 'Booleano (bool)', value: 'bool' },
  { label: 'Fecha', value: 'date' },
  { label: 'Hora [hh:mm]', value: 'time' },
  { label: 'Selector', value: 'select' },
  { label: 'Selector dinámico', value: 'selectPlus' },
  { label: 'Entero (integer)', value: 'integer' },
  { label: 'Entero (int)', value: 'int' },
  { label: 'Flotante', value: 'float' },
];

function getRestrictionsForInitialValues(entity) {
  const dfFormatted = {};
  const namesAndTypesFormatted = {};
  if (entity) {
    entity?.restrictions?.forEach((ca) => {
      const { id, value: customValue, type: dataType, name: dataName, label, description } = ca;
      namesAndTypesFormatted[`dynamicFieldsNames[${id}]`] = dataName;
      namesAndTypesFormatted[`dynamicFieldsTypes[${id}]`] = dataType;
      namesAndTypesFormatted[`dynamicFieldsLabels[${id}]`] = label;
      namesAndTypesFormatted[`dynamicFieldsDescriptions[${id}]`] = description;
      if (dataType === 'timerange') {
        const formattedValues = customValue.map((val) => {
          return dayjs(val, 'HH:mm:ss');
        });
        dfFormatted[`dynamicFields[${id}]`] = formattedValues;
      } else if (dataType === 'number') {
        dfFormatted[`dynamicFields[${id}]`] = parseInt(customValue, 10);
      } else if (dataType === 'date') {
        dfFormatted[`dynamicFields[${id}]`] = dayjs(customValue, 'DD-MM-YYYY');
      } else if (dataType === 'time') {
        dfFormatted[`dynamicFields[${id}]`] = customValue ? dayjs(customValue, 'HH:mm') : undefined;
      } else {
        dfFormatted[`dynamicFields[${id}]`] = customValue;
      }
    });
  }
  return {
    dfFormatted,
    namesAndTypesFormatted,
  };
}
/**
 * Generate text component with ellipsis and tooltip with full text
 * @param {*} text text to display
 * @returns Text component with ellipsis and tooltip
 */
const textWithEllipsis = (text) => {
  return (
    <Text
      ellipsis={{
        tooltip: text,
      }}
    >
      {text}
    </Text>
  );
};

/**
 * Method to get name of groups on items in an array of routes
 * @param {*} routes array of routes with items
 * @returns object with name of group and list of routes
 */
const nameGroupByRoutes = (routes) => {
  const demandGroupRoutes = {};
  routes.forEach(({ items, id: routeId }) => {
    items.forEach(({ restrictions }) => {
      const groupRestriction = restrictions.find(({ name }) => name === 'demand_groups');
      if (groupRestriction) {
        const valueGroup = groupRestriction.value;
        if (demandGroupRoutes[valueGroup]) {
          demandGroupRoutes[valueGroup].push(routeId);
          const partialArray = demandGroupRoutes[valueGroup];
          demandGroupRoutes[valueGroup] = [...new Set(partialArray)];
        } else {
          demandGroupRoutes[valueGroup] = [routeId];
        }
      }
    });
  });
  return demandGroupRoutes;
};

// ToDo: add other text and descriptions
/**
 * Method to get part to be used in notification
 * @param {*} item notification object
 * @returns icon, color, title & description to generate notifications
 */
const defineContentNotificationFirebase = (item) => {
  const { title, body, type, message, total } = item;
  const { statusProcess } = message;
  const isSuccess = statusProcess === 'SUCCESS';
  const failureColor = '#FF005C';
  let icon;
  let color = '#0D63CF';
  let titleNot = title;
  let descriptionNot = body;
  switch (type) {
    case 'superAdvancedDemands':
      icon = 'road';
      color = isSuccess ? '#4E75FF' : failureColor;
      titleNot = isSuccess ? 'Nuevas demandas super avanzada' : 'Error al cargar demandas';
      descriptionNot = isSuccess
        ? `Tienes ${total} conjuntos de demandas disponibles en mi planificación.`
        : `No se pudieron cargar ${total} conjuntos de demandas en mi planificación.`;
      break;
    case 'demands':
      icon = 'road';
      color = isSuccess ? '#4E75FF' : failureColor;
      titleNot = isSuccess ? 'Nuevas demandas' : 'Error al cargar demandas';
      descriptionNot = isSuccess
        ? `Tienes ${total} conjuntos de demandas disponibles en mi planificación.`
        : `No se pudieron cargar ${total} conjuntos de demandas en mi planificación.`;
      break;
    case 'routes':
      icon = 'road';
      color = isSuccess ? '#4E75FF' : failureColor;

      titleNot = isSuccess ? 'Nuevas rutas' : 'Error al cargar rutas';
      descriptionNot = isSuccess
        ? `Tienes ${total} conjunto de rutas disponibles en la lista de rutas.`
        : `No se pudieron generar los ${total} conjuntos de rutas.`;
      break;
    case 'routing_job':
      icon = 'road';
      color = isSuccess ? '#4E75FF' : failureColor;
      titleNot = isSuccess ? 'Nuevo trabajo de ruteo' : 'Problema de trabajo de ruteo';
      descriptionNot = isSuccess
        ? `Tienes ${total} trabajos de ruteo finalizado.`
        : `Hubo problemas con ${total} trabajo de ruteo.`;
      break;
    case 'reports':
      icon = 'file-lines';
      color = isSuccess ? '#4E75FF' : failureColor;
      titleNot = isSuccess
        ? 'Nuevo informe listo para ser descargado'
        : 'No se pudo generar el informe';
      descriptionNot = isSuccess
        ? `Tienes ${total} informes listos para ser descargados.`
        : `Hubo problemas con ${total} informes generados.`;
      break;
    case 'vehicles':
      icon = 'car';
      color = isSuccess ? '#4E75FF' : failureColor;
      titleNot = isSuccess ? 'Nuevos vehículos cargados' : 'No se pudieron cargar los vehículos';
      descriptionNot = isSuccess
        ? `Tienes ${total} sets de vehículos nuevos.`
        : `Hubo problemas con ${total} set de vehículos.`;
      break;
    case 'visits':
      icon = 'location-pin';
      color = isSuccess ? '#4E75FF' : failureColor;
      titleNot = isSuccess ? 'Nuevas visitas disponibles' : 'No se pudieron cargar las visitas';
      descriptionNot = isSuccess
        ? `Tienes ${total} sets de visitas nuevos.`
        : `Hubo problemas con ${total} set de visitas.`;
      break;
    case 'bases':
      icon = 'house-chimney';
      color = isSuccess ? '#4E75FF' : failureColor;
      titleNot = isSuccess ? 'Nuevas bases disponibles' : 'No se pudieron cargar las bases';
      descriptionNot = isSuccess
        ? `Tienes ${total} sets de bases nuevos.`
        : `Hubo problemas con ${total} set de bases.`;
      break;
    case 'download':
      icon = 'file-lines';
      color = isSuccess ? '#4E75FF' : failureColor;
      titleNot = isSuccess ? 'Nuevo documento procesado' : 'Problema con documento';
      descriptionNot = isSuccess
        ? `Tienes ${total} documentos listos para ser descargados.`
        : `Hubo problemas con ${total} documentos generados.`;
      break;
    case 'edit-visits':
      icon = 'map-marked';
      color = isSuccess ? '#599C70' : failureColor;
      titleNot = isSuccess ? 'Edición de visitas exitosa' : 'Error en la edición de visitas';
      descriptionNot = isSuccess
        ? `Modificaciones realizadas con éxito. ¡Tu información está actualizada y lista!`
        : `No fue posible procesar la edición de visitas. Por favor, verifica los datos e intenta nuevamente.`;
      break;
    case 'edit-bases':
      icon = 'map-marked';
      color = isSuccess ? '#599C70' : failureColor;
      titleNot = isSuccess ? 'Edición de bases exitosa' : 'Error en la edición de bases';
      descriptionNot = isSuccess
        ? `Modificaciones realizadas con éxito. ¡Tu información está actualizada y lista!`
        : `No fue posible procesar la edición de bases. Por favor, verifica los datos e intenta nuevamente.`;
      break;
    case 'edit-vehicles':
      icon = 'car';
      color = isSuccess ? '#599C70' : failureColor;
      titleNot = isSuccess ? 'Edición de vehículos exitosa' : 'Error en la edición de vehículos';
      descriptionNot = isSuccess
        ? `Modificaciones realizadas con éxito. ¡Tu información está actualizada y lista!`
        : `No fue posible procesar la edición de vehículos. Por favor, verifica los datos e intenta nuevamente.`;
      break;
    default:
      icon = 'info';
      color = '#0D63CF';
      break;
  }
  return { icon, color, title: titleNot, description: descriptionNot };
};

const cleanMapPoints = (points) => {
  return points.filter((p) => p?.lat && p?.lng);
};

/**
 * Method to get all timezone with her offset as label, value
 * @returns array with timezone [{ label: '(GMT-03:00) America/Santiago', value: 'America/Santiago' }]
 */
const getTimezonesWithOffset = () => {
  return moment.tz
    .names()
    .map((tZ) => ({ label: `(GMT${dayjs().tz(tZ).format('Z')}) ${tZ}`, value: tZ }));
};

// validate role optimal is for current organization
const userIsOptimal = (user) => {
  return (
    user?.roles?.filter(
      (role) =>
        role.organizationId === user?.organization.id && ['optimal', 'optimalIT'].includes(role.nid)
    ).length > 0
  );
};

// use organization not current org, case when is selecting org in dashboard
const userIsOptimalForOrg = (user, organization) => {
  return (
    user?.roles?.filter(
      (role) =>
        role.organizationId === organization?.id && ['optimal', 'optimalIT'].includes(role.nid)
    ).length > 0
  );
};

const userIsOnlyDriver = (user) => {
  const rolesInOrg =
    user?.roles
      ?.filter(({ organizationId }) => organizationId === user?.organization.id)
      .map(({ nid }) => nid) || [];
  return rolesInOrg.length > 0 && rolesInOrg.every((roleName) => roleName === 'driver');
};

/**
 * Used to update position of vehicle each x time
 */
const DEFAULT_TIMER = 5000;

/**
 * Method to get module name in spanish
 * ToDo: use i18n
 */
const moduleNameTranslation = (moduleName) => {
  let name = '';
  switch (moduleName) {
    case 'configurations':
      name = 'Configuración';
      break;
    case 'following':
      name = 'Seguimiento';
      break;
    case 'locations':
      name = 'Ubicaciones';
      break;
    case 'planning':
      name = 'Rutas';
      break;
    case 'reports':
      name = 'Reportes';
      break;
    case 'support':
      name = 'Ayuda';
      break;
    default:
      name = '';
  }
  return name;
};

/**
 * Method to add days to date
 * @param {string} date string date
 * @param {number} days number of days to add
 * @returns date with days added
 */
const addDaysToDate = (date, days) => {
  const parsedCreatedAt = dayjs(date);
  return parsedCreatedAt.add(dayjs.duration({ days }));
};

/**
 * Method to draw form input
 * @param {object} field input params
 * @param {object} form form instance
 * @param {function} handleChanges onChange function
 * @returns form input
 */
const defineComponent = (field, form, handleChanges) => {
  const { name: nameField, label: labelField, type: typeField, minCharsToSearch, span = 6 } = field;
  let fieldComponent;
  switch (typeField) {
    case 'text':
      fieldComponent = (
        <InputFilter
          fieldDefinition={field}
          handleChanges={handleChanges}
          form={form}
          minStringChars={minCharsToSearch}
        />
      );
      break;
    case 'range':
      fieldComponent = (
        <RangePickerFilter nameField={nameField} handleChanges={handleChanges} form={form} />
      );
      break;
    case 'select':
      fieldComponent = (
        <SelectFilter fieldDefinition={field} handleChanges={handleChanges} form={form} />
      );
      break;
    case 'location_cascade':
      fieldComponent = (
        <CascadeFilter fieldDefinition={field} handleChanges={handleChanges} form={form} />
      );
      break;
    case 'groupedButton':
      fieldComponent = (
        <GrouppedButtonFilter fieldDefinition={field} handleChanges={handleChanges} form={form} />
      );
      break;
    default:
      fieldComponent = (
        <InputFilter
          fieldDefinition={field}
          handleChanges={handleChanges}
          allowClear={false}
          type={typeField}
          form={form}
        />
      );
      break;
  }

  return (
    <Col md={span} xs={24} key={`col-${nameField}`}>
      <Form.Item label={labelField} name={nameField}>
        {fieldComponent}
      </Form.Item>
    </Col>
  );
};

/**
 * Method to remove duplicate object from array
 * @param {array} arrayOfObjects array of duplicate objects
 * @returns array of uniq objects
 */
const removeDuplicateKeysFromObject = (arrayOfObjects) => {
  return Array.from(new Set(arrayOfObjects.map((obj) => obj.id))).map((id) => {
    return arrayOfObjects.find((obj) => obj.id === id);
  });
};

/**
 * Method to format locations address
 * @param {object} address object of address
 * @returns React object
 */
const renderAddress = (address) => {
  const town = address?.town && address?.town !== '' ? `${address?.town},` : '';
  return (
    <Text>
      {address.street && address.number
        ? `${address.street} ${address.number}, ${address.city}, ${address.province}, ${town}${address.country}`
        : '-'}
    </Text>
  );
};

/**
 * Method to check if variable is an object
 * @param {object} value to check
 * @returns boolean
 */
const isObject = (value) => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};

/**
 * Method to get number transformed to format
 * @param {*} value
 * @param {*} format
 * @returns value formatted to format passed, 2 digit
 */
const transformValueToNumberWithSeparator = (value, format = 'es-CL') => {
  let valueToParse = value;
  if (typeof value !== 'number') {
    try {
      valueToParse = parseFloat(value);
      if (Number.isNaN(valueToParse)) {
        // return original value
        return value;
      }
    } catch (error) {
      return value;
    }
  }
  const formatNumber = new Intl.NumberFormat(format, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatNumber.format(valueToParse);
};

/**
 * Method to build format errors, and translate
 * @param {*} errorsResponse object with errors of importers
 * @param {*} i18n instance of i18n
 * @returns object with row as key, and list of error for each row
 */
const formatErrorsForImporter = (errorsResponse, i18n) => {
  const errorsByRow = {};
  const scopeI18nMessages = { scope: 'form.demandsImport.messages' };
  // process only if have rowsWithError
  if (errorsResponse?.rowsWithError?.length > 0) {
    const validationsDetailSpecials = [
      'requiredCols', // this use details as a list of string
      'conditionalRows', // this use details as a list of string
      'unknownError', // this use details as a list of string
      'validateMaxLoads', // this use details as a list of string
    ];
    const validationsWithDetails = [
      ...validationsDetailSpecials,
      'validateCoords',
      'validateDemandDetails',
      'validateFormatRestrictions',
    ];
    errorsResponse.rowsWithError.forEach((rowNumber) => {
      const errorsGrouped = [];
      Object.keys(errorsResponse.formattedErrors).forEach((errorName) => {
        const { rows, ...rest } = errorsResponse.formattedErrors[errorName];
        // check if error has row
        if (rows.includes(rowNumber)) {
          let detailForThisRow = [];
          if (validationsDetailSpecials.includes(errorName)) {
            // for conditionalRows join all details as an object
            if (errorName === 'conditionalRows') {
              const detailForThisRowObj = {};
              rest.details.forEach((det) => {
                Object.entries(det).forEach(([name, value]) => {
                  detailForThisRowObj[name] = value;
                });
              });
              detailForThisRow = [detailForThisRowObj];
            } else {
              // for other specials, dont convert
              detailForThisRow = rest.details;
            }
          } else {
            // for others errors join others attributes, to be searched
            Object.entries(rest).forEach(([detailName, rowsWithDetail]) => {
              if (rowsWithDetail.includes(rowNumber)) {
                detailForThisRow = detailForThisRow.concat(detailName.split('-'));
              }
            });
          }
          // build object base with details, and message to be builded
          const errorObject = {
            name: errorName,
            details: validationsWithDetails.includes(errorName) ? detailForThisRow : [],
            messages: [],
          };
          errorsGrouped.push(errorObject);
        }
      });
      errorsByRow[rowNumber] = errorsGrouped;
    });
    // Build message translated
    const scopeMessages = {
      scope: 'form.demandsImport.messages.conditionalRowsMessages',
    };
    // for each rowNumber check error name, and translate
    Object.entries(errorsByRow).forEach(([rowNumber, listErrors]) => {
      listErrors.forEach(({ name, details, messages }) => {
        const defaultOptionsTranslate = {
          ...scopeI18nMessages,
          details: details.join(', '),
          ...details[0],
          count: details.length,
        };
        if (name === 'conditionalRows') {
          // build keys to translate
          const { leftCondition, rightCondition, specialCondition = [] } = details[0];
          const keyLeft = leftCondition.join('-');
          const keyRight = rightCondition.join('-');
          const keySpecial = specialCondition.join('-');
          const leftTranslated = i18n.t(keyLeft, scopeMessages);
          const rightTranslated = i18n.t(keyRight, scopeMessages);
          const specialTranslated = i18n.t(keySpecial, scopeMessages);
          const hasSpecial = keySpecial.length > 0;
          const messageTranslate = i18n.t(
            hasSpecial ? 'conditionalRowsLeftRightSpecial' : 'conditionalRowsLeftRight',
            {
              ...scopeI18nMessages,
              leftCondition: leftTranslated,
              rightCondition: rightTranslated,
              specialCondition: specialTranslated,
            }
          );
          messages.push(messageTranslate);
        } else if (
          [
            'validateVisitCodeUniqueness',
            'validateBaseCodeUniqueness',
            'validateVehicleCodeUniqueness',
          ].includes(name)
        ) {
          // This have double validation, for row and code repeated
          const titleIsNotNumber = Number.isNaN(parseInt(rowNumber, 10));
          const messageTranslate = i18n.t(
            titleIsNotNumber ? `${name}NotNumber` : name,
            defaultOptionsTranslate
          );
          messages.push(messageTranslate);
        } else {
          const messageTranslate = i18n.t(name, defaultOptionsTranslate);
          messages.push(messageTranslate);
        }
      });
    });
  }
  return errorsByRow;
};

/**
 * Method to get all availables routes, with modified, and remove empty routes
 * @param {*} allRoutes in routing set
 * @param {*} recalculatedRoutes routes modified, recalculated
 * @param {*} deletedRoutesIds ids of route to delete
 * @returns
 */
const getOnlyAvailableRoutes = (allRoutes, recalculatedRoutes, deletedRoutesIds) => {
  let onlyAvailableRoutes = allRoutes;
  const hasNewRoutes = recalculatedRoutes.filter(({ id }) => id < 0);
  if (hasNewRoutes.length > 0) {
    // add only new routes not included in all routes
    const newRoutesInAllRoutes = allRoutes.filter(({ id }) => id < 0).map(({ id }) => id);
    const newRoutesNotIncluded = hasNewRoutes.filter(
      ({ id }) => !newRoutesInAllRoutes.includes(id)
    );
    onlyAvailableRoutes = onlyAvailableRoutes.concat(newRoutesNotIncluded);
  }
  if (deletedRoutesIds) {
    onlyAvailableRoutes = onlyAvailableRoutes.filter((nR) => nR.routeOrder !== deletedRoutesIds);
  }
  // also need update all routes with changes in routes
  onlyAvailableRoutes = onlyAvailableRoutes
    .map((thisRoute) => {
      // search route to get new changes on items
      const foundedRoute = recalculatedRoutes.find(({ id }) => thisRoute.id === id);
      if (foundedRoute) {
        return foundedRoute;
      }
      return thisRoute;
    })
    .map((nR2, idx) => {
      // re order, for last route added
      const routeOrder = idx + 1;
      return { ...nR2, routeOrder };
    });
  return onlyAvailableRoutes;
};

/**
 * Method to get other field value in form, usin nameField to compare
 * @param {*} nameField to be ommited to search
 * @param {*} form form instance
 * @param {*} fields array of 2 fields to search
 * @returns value in form for other field
 */
const getOtherValueInForm = (nameField, form, fields) => {
  const [firstField, secondField] = fields;
  return form.getFieldValue(nameField === firstField ? secondField : firstField);
};
/**
 * Method to check if latitude is invalid
 * @param {*} latitude
 * @returns bolean if is invalid
 */
const latitudeInvalid = (latitude) => {
  if (`${latitude}`.includes('e')) {
    return true;
  }
  const validLat = latitude >= -90 && latitude <= 90;
  return !validLat;
};
/**
 * Method to check if longitude is invalid
 * @param {*} longitude
 * @returns bolean if is invalid
 */
const longitudeInvalid = (longitude) => {
  if (`${longitude}`.includes('e')) {
    return true;
  }
  const validLon = longitude >= -180 && longitude <= 180;
  return !validLon;
};

const BASE_URL_RE = process.env.REACT_APP_BASE_URL_RE;
const VERSION_URL_RE = process.env.REACT_APP_VERSION_URL_RE;

export {
  addDaysToDate,
  BuildCustomAttributes,
  BuildLoadInForm,
  CalculateDiffTimes,
  CalculateEta,
  calculateItemId,
  checkPermissionForSubject,
  cleanMapPoints,
  cloneRouteObject,
  ColorByOrder,
  ColorByRouteStatus,
  ColorByStatus,
  ColorByStatusEvent,
  ColorByRouteStatusTag,
  convertDateToDayjs,
  ConvertToMinutes,
  DataByCognitoUserStatus,
  DataByRouteStatus,
  DataByStatus,
  DataByStatusEvent,
  DataByRouteStatusTag,
  DEFAULT_TIMER,
  defineComponent,
  defineContentNotificationFirebase,
  defineIndexRoute,
  defineLabelByStatus,
  DefRoleColumns,
  displayUnauthorizedNotification,
  entities,
  filterOption,
  formatErrorsForImporter,
  FormatToTime,
  getAllIndexFromArray,
  GetEtaFromDate,
  getOnlyAvailableRoutes,
  getOtherValueInForm,
  getRestrictionsForInitialValues,
  getSettingsValues,
  getSubstring,
  getTimezonesWithOffset,
  getTitleToDrawer,
  GetUrl,
  groupByEntity,
  haveAtLeastOneSubModule,
  HslaColorByStatusEvent,
  inputTypes,
  insertItem,
  insertNewItem,
  IsDepotOrRtd,
  isObject,
  latitudeInvalid,
  longitudeInvalid,
  moduleNameTranslation,
  moveItem,
  nameGroupByRoutes,
  removeDuplicateKeysFromObject,
  renderAddress,
  secondsToWords,
  sortSelect,
  statusByEvents,
  TextByRouteStatus,
  TextByStatus,
  TextByStatusEvent,
  TextByRouteStatusTag,
  textWithEllipsis,
  timeStatus,
  totalsByEvents,
  transformValueToNumberWithSeparator,
  userIsOnlyDriver,
  userIsOptimal,
  userIsOptimalForOrg,
  BASE_URL_RE,
  VERSION_URL_RE,
};
