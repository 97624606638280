import apiSlice, { ApiSliceTags } from 'app/api/apiSlice';

/**
 * usersApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => '/users',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users', id })),
              { type: 'Users', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Users', id: 'PARTIAL-LIST' }],
    }),
    getUsersByFilters: builder.mutation({
      query: (query) => `/users${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users', id })),
              { type: 'Users', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Users', id: 'PARTIAL-LIST' }],
    }),
    getAllUsers: builder.query({
      query: () => '/users?paginated=false',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users', id })),
              { type: 'Users', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Users', id: 'PARTIAL-LIST' }],
    }),
    getRoles: builder.query({
      query: (query) => {
        const url = query ? `/roles${query}` : '/roles';
        return url;
      },
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Roles', id })) : ['Roles'],
    }),
    createUser: builder.mutation({
      query: (params) => {
        const fmData = new FormData();
        fmData.append('avatar', params.avatar);
        fmData.append('body', JSON.stringify(params.body));
        return {
          url: '/users',
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation({
      query: (params) => {
        const fmData = new FormData();
        fmData.append('avatar', params.avatar);
        fmData.append('body', JSON.stringify(params.body));
        return {
          url: `/users/${params.userId}`,
          method: 'PATCH',
          body: fmData,
        };
      },
      invalidatesTags: ['Users'],
    }),
    assignRolesToUser: builder.mutation({
      query: (params) => ({
        url: `users/assign-roles/${params.userId}`,
        method: 'PATCH',
        body: { ...params.body },
      }),
      invalidatesTags: ['Users'],
    }),
    deleteOrRestoreUser: builder.mutation({
      query: (userId) => ({
        url: `users/change-status/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    uploadUsers: builder.mutation({
      query: (params) => {
        const fmData = new FormData();
        fmData.append('file', params);
        return {
          url: '/users/upload',
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: ['Users', 'FileProcessing'],
    }),
    changeCurrentOrganization: builder.mutation({
      query: (orgId) => ({
        url: `users/change-current-organization/${orgId}`,
        method: 'POST',
      }),
      invalidatesTags: ApiSliceTags.filter((elem) => elem !== 'Organizations'),
    }),
    getDriversAssitantsAvailablesByFilter: builder.mutation({
      query: (params) => {
        const { routeId, query } = params;
        const queryParams = query || '';
        return `/users/get-drivers-assistants/${routeId}${queryParams}`;
      },
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Users', id })) : ['Users'],
    }),
    changeStatusTour: builder.mutation({
      query: (orgId) => ({
        url: `users/change-status-tour/${orgId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetRolesQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useAssignRolesToUserMutation,
  useDeleteOrRestoreUserMutation,
  useGetUsersByFiltersMutation,
  useUploadUsersMutation,
  useChangeCurrentOrganizationMutation,
  useGetDriversAssitantsAvailablesByFilterMutation,
  useChangeStatusTourMutation,
  useGetAllUsersQuery,
} = usersApiSlice;
