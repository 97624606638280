import { Button, Col, Drawer, Row, Space, Tag } from 'antd';
import TableComponent from 'components/common/Table';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DataByRouteStatus, DataByStatus, convertDateToDayjs } from 'services/repeated-functions';

function DrawerRoutes(props) {
  const { routingSet, drawerVisible, setDrawerVisible } = props;
  const { id, name, routes } = routingSet;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'routes' };
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);

  const onClose = () => {
    setDrawerVisible(false);
  };

  const renderFooter = () => (
    <div style={{ flexDirection: 'row', textAlign: 'end' }}>
      <Button style={{ width: 100 }} onClick={onClose}>
        {i18n.t('buttons.close')}
      </Button>
    </div>
  );

  const renderStatus = () => {
    const { color, text } = DataByStatus(routingSet?.status);
    return (
      <Tag color={color} style={{ minWidth: '75px', textAlign: 'center' }}>
        {text}
      </Tag>
    );
  };

  const columns = [
    {
      title: i18n.t('routeOrder', scopeI18n),
      dataIndex: 'routeOrder',
      key: 'routeOrder',
      render: (text) => `Ruta ${text}`,
    },
    {
      title: i18n.t('driver', scopeI18n),
      dataIndex: 'driver',
      key: 'driver',
      render: (_, record) => {
        const { driver } = record;
        return driver?.fullName || 'Sin asignar';
      },
    },
    {
      title: i18n.t('plate', scopeI18n),
      dataIndex: 'plate',
      key: 'plate',
      render: (_, record) => {
        const { vehicle } = record;
        return vehicle?.plate || 'Sin asignar';
      },
    },
    {
      title: i18n.t('assistant', scopeI18n),
      dataIndex: 'assistant',
      key: 'assistant',
      render: (_, record) => {
        const { assistant } = record;
        return assistant?.fullName || 'Sin asignar';
      },
    },
    {
      title: i18n.t('status', scopeI18n),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const { color, text } = DataByRouteStatus(status);
        return (
          <Tag color={color} style={{ minWidth: '75px', textAlign: 'center' }}>
            {text}
          </Tag>
        );
      },
    },
  ];
  return (
    <Drawer
      title={`${id}: ${name}`}
      width="50%"
      onClose={(event) => onClose(event)}
      open={drawerVisible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={renderFooter()}
    >
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row>
          <Col
            span={12}
          >{`Tipo de accion: ${routingSet?.action === 'delivery' ? 'Llevada' : 'Recogida'}`}</Col>
          <Col span={12}>Proceso: {renderStatus()}</Col>
        </Row>
        <Row>
          <Col span={12}>
            Fecha Planificación:{' '}
            {convertDateToDayjs(routingSet.planificationAt, tzOrganization, 'DD-MM-YYYY HH:mm')}
          </Col>
          <Col span={12}>
            Fecha Creación:{' '}
            {convertDateToDayjs(routingSet.createdAt, tzOrganization, 'DD-MM-YYYY HH:mm')}
          </Col>
        </Row>
        <Row>
          <Col span={12}>Nº Rutas: {routes?.length}</Col>
        </Row>
      </Space>
      <Row gutter={24} style={{ height: '100%', marginTop: '30px' }} className="vehicles-container">
        <Col flex="auto">
          <TableComponent dataSource={sortBy(routes, ['routeOrder'])} columns={columns} />
        </Col>
      </Row>
    </Drawer>
  );
}

DrawerRoutes.propTypes = {
  drawerVisible: PropTypes.bool,
  setDrawerVisible: PropTypes.func,
  routingSet: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    action: PropTypes.string,
    status: PropTypes.string,
    planificationAt: PropTypes.string,
    createdAt: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  }),
};

DrawerRoutes.defaultProps = {
  drawerVisible: false,
  setDrawerVisible: () => {},
  routingSet: {},
};

export default DrawerRoutes;
