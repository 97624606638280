import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import { Link } from 'react-router-dom';

function SidebarMobileButton(props) {
  const { key, icon, linkTo, text, isSelected } = props;
  return {
    key,
    label: (
      <Space
        direction="vertical"
        align="center"
        size={4}
        className={`${isSelected ? 'hover' : ''}`}
      >
        <Link to={linkTo}>
          <FontAwesomeIcon
            icon={['fas', icon]}
            style={{ marginTop: '10px', marginRight: '.5rem' }}
          />
          {text}
        </Link>
      </Space>
    ),
  };
}

export default SidebarMobileButton;
