import { Skeleton } from 'antd';
import RoutingGeneral from 'components/Organizations/Configurations/Routing/General';
import PropTypes from 'prop-types';

function OrganizationsCreationStepsRoutingGeneral(props) {
  const { organizationId, isLoading, current } = props;
  return isLoading ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <RoutingGeneral organizationId={organizationId} goToNextStep submitButton current={current} />
  );
}

OrganizationsCreationStepsRoutingGeneral.defaultProps = {
  organizationId: null,
  isLoading: false,
  current: null,
};
OrganizationsCreationStepsRoutingGeneral.propTypes = {
  organizationId: PropTypes.number,
  isLoading: PropTypes.bool,
  current: PropTypes.number,
};

export default OrganizationsCreationStepsRoutingGeneral;
