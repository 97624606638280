import PropTypes from 'prop-types';

export const PanelPropTypes = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  totalViews: PropTypes.number,
  lastView: PropTypes.string,
  signedUrl: PropTypes.string,
  deletedAt: PropTypes.string,
  widgetCode: PropTypes.string,
});

export const PanelsPropTypes = PropTypes.arrayOf(PanelPropTypes);
