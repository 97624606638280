import { Avatar, Col, Input, List, Row, Typography } from 'antd';
import logoTOCircle from 'assets/img/logo_TO_circle.svg';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import data from '../data.json';

import '../style.css';

const { Text, Title } = Typography;

function HelpDashboard() {
  const [textToSearch, setTextToSearch] = useState();
  const navigate = useNavigate();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'help' };

  const groupedList = (list) => {
    const groupped = list.reduce((accumulator, obj) => {
      const { groupName } = obj;
      if (!accumulator[groupName]) {
        accumulator[groupName] = [];
      }
      accumulator[groupName].push(obj);
      return accumulator;
    }, {});
    const newList = Object.entries(groupped).map(([groupName, listForGroup]) => ({
      groupName,
      list: listForGroup,
    }));
    return newList;
  };

  const filterList = (list, textToFilter) => {
    const re = new RegExp(textToFilter, 'i');
    const dataFiltered = list.filter(
      ({ title, groupName, keyWords }) =>
        title.match(re) || groupName.match(re) || keyWords.some((keyWord) => keyWord.match(re))
    );
    return dataFiltered;
  };

  const visibleList = useMemo(() => {
    return filterList(data, textToSearch);
  }, [textToSearch]);

  const onSearch = (value) => {
    setTextToSearch(value);
  };
  const onChangeInput = (event) => {
    const { value } = event.target;
    if (value.length >= 4 || value.length === 0) {
      setTextToSearch(value);
    }
  };
  const goToDetail = (id) => {
    navigate(`/help/detail/${id}`);
  };

  return (
    <Col md={24}>
      <Row gutter={[12, 12]} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col md={{ span: 6, offset: 17 }} xs={{ span: 24 }}>
          <Input.Search
            allowClear
            placeholder={i18n.t('search', scopeI18n)}
            onSearch={onSearch}
            onChange={onChangeInput}
          />
          <Text type="secondary">
            {visibleList.length !== data.length
              ? i18n.t('displayedTotalOfFounded', {
                  ...scopeI18n,
                  total: data.length,
                  founded: visibleList.length,
                })
              : ''}
          </Text>
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="help-list">
        <Col md={{ span: 13, offset: 4 }} xs={{ span: 24 }}>
          <div>
            {groupedList(visibleList).map(({ groupName, list }, index) => {
              const keyDiv = `group-${index}`;
              return (
                <div key={keyDiv} className="help-group">
                  <Title level={5}>{groupName}</Title>
                  <List
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => (
                      <List.Item
                        onClick={(event) => {
                          event.preventDefault();
                          goToDetail(item.key);
                        }}
                        className="item-help"
                        title={i18n.t('showHelp', scopeI18n)}
                      >
                        <List.Item.Meta
                          avatar={<Avatar src={logoTOCircle} style={{ marginTop: '.5rem' }} />}
                          title={item.title}
                          description={
                            <Text type="secondary" ellipsis>
                              {item.description}
                            </Text>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

export default HelpDashboard;
