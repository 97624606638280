import {
  Alert,
  App,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd';
import AutocompleteAddress from 'components/common/AutocompleteAddress';
import Locations from 'components/common/Cascader/Locations';
import ContactForm from 'components/common/ContactForm';
import DynamicFields from 'components/common/Form/DynamicFields';
import ModalConfirm from 'components/common/ModalConfirm';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import {
  useAddDemandMutation,
  useDeleteDemandMutation,
  useUpdateDemandMutation,
} from 'features/demands/demandsApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import {
  getOtherValueInForm,
  getRestrictionsForInitialValues,
  latitudeInvalid,
  longitudeInvalid,
} from 'services/repeated-functions';
import { loadsFromOrgType } from 'types';
import AutocompleteLocation from './AutocompleteLocation';
import PointsContext from './context/PointsContext';
import { AddrFoundedPropType, DemandPropType } from './Demand.propTypes';
import DemandDetailForm from './DemandDetailForm';
import Georreference from './Georreference';

import './style.css';

const { Text } = Typography;

function DemandForm(props) {
  const {
    demandSetId,
    demand,
    isNewDemand,
    onCancel,
    isLastDemand,
    loadsFromOrg,
    addrFounded,
    afterSave,
    listGroups,
  } = props;
  const [form] = Form.useForm();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const [address, setAddress] = useState({
    ...demand.addr,
    country: demand.addr.country || 'Chile',
    province: demand.addr.province || 'Región Metropolitana de Santiago',
    city: demand.addr.city || 'Santiago',
    ...(demand.addr?.town && { town: demand.addr?.town }),
  });
  const [demandLoadsDisabled, setDemandLoadsDisabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { contactPhones: phonesFromDb, contactEmails: emailsFromDb } = demand;
  const phones = phonesFromDb
    ?.filter((item) => Object.keys(item).length)
    .map((ph) => ({
      prefix: ph.phone?.substring(0, 3) || '+56',
      phone: ph.phone?.substring(3),
    }));
  const emails = emailsFromDb?.filter((item) => Object.keys(item).length);
  const { message } = App.useApp();

  // ToDO: move to an separated function
  const { dfFormatted, namesAndTypesFormatted } = getRestrictionsForInitialValues(demand);

  const initialValues = {
    ...demand,
    ...dfFormatted,
    ...namesAndTypesFormatted,
    phones,
    emails,
  };

  // custom hooks
  const [addDemand] = useAddDemandMutation();
  const [updateDemand] = useUpdateDemandMutation();
  const [deleteDemand] = useDeleteDemandMutation();

  useEffect(() => {
    if (demand?.demandDetails?.length > 0) {
      setDemandLoadsDisabled(true);
    }
    // set demand source
    form.setFieldsValue({ source: demand?.source || 'frontend' });
  }, [demand, form]);

  // Set address from cascader
  const handleAddressValues = (values) => {
    const [country, province, city, town] = values;
    setAddress((prevAddress) => ({ ...prevAddress, country, province, city, town }));
  };

  useEffect(() => {
    if (address) {
      form.setFieldsValue({ addr: address });
    }
  }, [address, form]);

  useEffect(() => {
    const nidFromForm = form.getFieldValue('nid');
    if (addrFounded.key === demand.id || addrFounded.key === nidFromForm) {
      setAddress(addrFounded);
      form.setFieldsValue({
        destination_latitude: addrFounded.lat,
        destination_longitude: addrFounded.lng,
      });
    }
  }, [addrFounded, demand.id, form]);

  const onFinish = async (values) => {
    const { phones: phonesFromForm, emails: emailsFromForm, loads } = values;
    const phonesWithPrefix = phonesFromForm?.map((ph) => ({ phone: `${ph.prefix}${ph.phone}` }));
    const loadsWithValues = loads.map(({ load }) => {
      return { load: load || 0 };
    });
    const formValues = {
      ...values,
      loads: loadsWithValues,
      contactPhones: phonesWithPrefix,
      contactEmails: emailsFromForm,
    };
    if (!isNewDemand) {
      updateDemand({ demandId: demand.id, body: formValues })
        .unwrap()
        .then(() => message.success('Demanda Actualizada'))
        .catch(() => message.error('Demanda no pudo ser actualizada'));
    } else {
      await addDemand({ demandSetId, body: formValues })
        .unwrap()
        .then(() => message.success('Demanda Agregada'))
        .catch(() => message.error('Demanda no pudo ser agregada'));
    }
    // restart panel
    afterSave([]);
  };

  const handleOnValuesChange = (changedValues) => {
    if ('demandDetails' in changedValues) {
      const defaultProductLoads = loadsFromOrg.map(() => {
        return { load: 0 };
      });
      const { demandDetails } = form.getFieldsValue();
      demandDetails?.forEach((details) => {
        details?.loads?.forEach((detail, index) => {
          if (defaultProductLoads[index]) {
            const itemValue = !detail?.load ? 0 : detail?.load;
            defaultProductLoads[index].load += itemValue;
          }
        });
      });
      form.setFieldsValue({ loads: defaultProductLoads });

      // enable demand load fields
      setDemandLoadsDisabled(changedValues.demandDetails.length > 0);
    }
  };

  const handleOnCancel = () => {
    onCancel(undefined);
  };

  // archive modal
  const archiveDemand = async () => {
    try {
      const demandId = demand.id;
      await deleteDemand(demandId);
      message.success('Demanda Archivada');
    } catch (error) {
      message.error('No se pudo archivar la Demanda');
    }
  };
  const showArchiveModal = (event) => {
    event.stopPropagation();
    setIsModalVisible(true);
  };
  const onConfirmArchive = (event) => {
    event.stopPropagation();
    archiveDemand();
    setIsModalVisible(false);
  };
  const onCancelArchive = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
  };
  const { setMapPoints, setMoveToPosition, onDrag } = useContext(PointsContext);
  const manualChangeCoords = (e) => {
    const { name, value: newValue } = e.target;
    const otherCordsValue = getOtherValueInForm(name, form, [
      'destination_latitude',
      'destination_longitude',
    ]);
    if (!newValue || !otherCordsValue) {
      return;
    }
    let latitude;
    let longitude;
    if (name === 'destination_latitude') {
      if (latitudeInvalid(newValue)) {
        return;
      }
      latitude = parseFloat(newValue);
      longitude = parseFloat(otherCordsValue);
    } else {
      if (longitudeInvalid(newValue)) {
        return;
      }
      latitude = parseFloat(otherCordsValue);
      longitude = parseFloat(newValue);
    }

    // si es una demanda nueva agrego el objeto
    if (!demand.id) {
      const objectId = demand.pointId;
      const obj = {
        key: objectId,
        lat: `${latitude}`,
        lng: `${longitude}`,
        Cod: '',
        Nombre: '',
        usedRows: ['Cod', 'Nombre'],
        draggable: true,
        active: true,
        onDrag: (latLong) => {
          onDrag(latLong, objectId);
        },
      };
      setMapPoints((oldMapPoints) => [
        ...oldMapPoints.map((oldPoint) => ({
          ...oldPoint,
          active: false,
          draggable: false,
          onDrag: (latLong) => {
            onDrag(latLong, oldPoint.key);
          },
        })),
        obj,
      ]);
    } else {
      // cambiamos los puntos
      setMapPoints((oldMapPoints) => [
        ...oldMapPoints.map((object) => {
          if (object.key === demand.id) {
            return {
              ...object,
              lat: `${latitude}`,
              lng: `${longitude}`,
              active: true,
              draggable: true,
              onDrag: (latLong) => {
                onDrag(latLong, object.key);
              },
            };
          }
          return {
            ...object,
            active: false,
            draggable: false,
            onDrag: (latLong) => {
              onDrag(latLong, object.key);
            },
          };
        }),
      ]);
    }
    setMoveToPosition([latitude, longitude]);
  };
  const onFinishFailed = () => {
    message.error(i18n.t('form.checkForm'));
  };

  return (
    <>
      <ModalConfirm
        onOk={onConfirmArchive}
        onCancel={onCancelArchive}
        title={
          isLastDemand
            ? 'Si elimina esta demanda todo el conjunto será archivado y no podrá seguir editando. ¿Desea continuar?'
            : '¿ Estás seguro que desea archivar la demanda ?'
        }
        isModalVisible={isModalVisible}
      />
      {loadsFromOrg.length === 0 && (
        <Alert
          message="Los elementos relacionados con las Cargas están deshabilitados ya que debe configurar las unidades de medida en Configuración → Organización → Medidas"
          type="warning"
        />
      )}
      <Form
        form={form}
        initialValues={initialValues}
        name={demand.id}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        onValuesChange={handleOnValuesChange}
        scrollToFirstError
      >
        <Divider orientation="left" orientationMargin="0">
          <Text strong>Identificación</Text>
        </Divider>
        <Alert
          showIcon
          message="Modificar datos de la demanda no afectará a la visita asociada en la lista general de visitas (módulo ubicaciones)."
          type="warning"
          style={{ marginBottom: 10 }}
          key={demand.id}
          closable
        />
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="source" hidden>
              <Input />
            </Form.Item>
            <Form.Item name={['addr', 'country']} hidden>
              <Input />
            </Form.Item>
            <Form.Item name={['addr', 'city']} hidden>
              <Input />
            </Form.Item>
            <Form.Item name={['addr', 'province']} hidden>
              <Input />
            </Form.Item>
            <Form.Item name={['addr', 'town']} hidden>
              <Input />
            </Form.Item>
            <Form.Item name="restrictions" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="locationId" hidden>
              <Input />
            </Form.Item>
            <AutocompleteLocation />
          </Col>
          <Col span={12}>
            <Form.Item label="Nombre cliente" name="name">
              <Input placeholder="Nombre cliente" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="ID documento" name="document">
              <Input placeholder="ID documento" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="vip" valuePropName="checked">
              <Checkbox>
                <TitleHelp
                  title="Vip"
                  helpText="Prioriza la ubicación geográfica al momento de rutear"
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <ContactForm />
        <Divider orientation="left" orientationMargin="0">
          <Text strong>Ubicación</Text>
        </Divider>
        <Row>
          <Col span={24}>
            <AutocompleteAddress
              setAddress={setAddress}
              demandId={demand.id}
              client="demands-editor"
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Localización">
              <Locations
                country={address.country}
                province={address.province}
                city={address.city}
                town={address.town}
                customOnChange={(selected) => handleAddressValues(selected)}
              />
            </Form.Item>
            <Form.Item label="Número" name={['addr', 'number']}>
              <Input placeholder="Número" />
            </Form.Item>
            <Form.Item label="Información adicional" name={['addr', 'line2']}>
              <Input placeholder="Información adicional" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Calle" name={['addr', 'street']}>
              <Input placeholder="Calle" />
            </Form.Item>
            <Row className="search-button-row">
              <Col span={12}>
                <Form.Item label="Código postal" name="zip_code">
                  <Input placeholder="Código postal" />
                </Form.Item>
              </Col>
              <Col span={6} push={6}>
                <Georreference demandId={demand.id} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider orientation="left" orientationMargin="0">
          <Text strong>Geolocalización</Text>
        </Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Latitud"
              name="destination_latitude"
              required
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (latitudeInvalid(value)) {
                      return Promise.reject(new Error('Latitud fuera de rango'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                name="destination_latitude"
                placeholder="Latitud"
                onBlur={manualChangeCoords}
                min={-90}
                max={90}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Longitud"
              name="destination_longitude"
              required
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (longitudeInvalid(value)) {
                      return Promise.reject(new Error('Longitud fuera de rango'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                name="destination_longitude"
                placeholder="Longitud"
                onBlur={manualChangeCoords}
                min={-180}
                max={180}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Form.Item name="origin_latitude" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="origin_longitude" hidden>
            <Input />
          </Form.Item>
        </Row>
        {/* restrictions */}
        <DynamicFields type="DEMAND" listGroups={listGroups} />
        <Divider orientation="left" orientationMargin="0">
          <Text strong>Cargas</Text>
        </Divider>
        <Form.List name="loads">
          {(fields, { errors }) => (
            <>
              <Row justify="end">
                <Col span={8}>
                  <Form.ErrorList errors={errors} />
                </Col>
              </Row>
              <Row justify="space-evenly">
                {fields.map(({ key, name, ...restField }, loadIndex) => {
                  const { isListField } = restField;
                  const loadsFromOrgData = loadsFromOrg.find((load) => load.key === loadIndex + 1);
                  return (
                    loadsFromOrgData && (
                      <Col span={7} key={key}>
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                          }}
                        >
                          <Form.Item
                            isListField={isListField}
                            label={loadsFromOrgData?.name}
                            name={[name, 'load']}
                            required={key === 0}
                            rules={[{ required: key === 0 }]}
                          >
                            <InputNumber
                              placeholder={loadsFromOrgData?.name}
                              addonAfter={loadsFromOrgData?.unit}
                              disabled={demandLoadsDisabled}
                            />
                          </Form.Item>
                        </Space>
                      </Col>
                    )
                  );
                })}
              </Row>
            </>
          )}
        </Form.List>
        <Divider orientation="left" orientationMargin="0">
          <Text strong>Detalle de entrega</Text>
        </Divider>
        <DemandDetailForm loadsFromOrg={loadsFromOrg} />
        <br />
        <Row justify="end">
          <Col>
            <Space>
              {isNewDemand ? (
                <Button type="primary" onClick={handleOnCancel}>
                  Cancelar
                </Button>
              ) : (
                <Button type="primary" danger onClick={(event) => showArchiveModal(event)}>
                  Archivar
                </Button>
              )}
              <Button type="primary" htmlType="submit" disabled={loadsFromOrg.length === 0}>
                Guardar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
}

DemandForm.defaultProps = {
  demandSetId: 0,
  demand: {},
  loadsFromOrg: [],
  isNewDemand: false,
  isLastDemand: false,
  onCancel: () => {},
  addrFounded: {},
  afterSave: () => {},
  listGroups: [],
};

DemandForm.propTypes = {
  demandSetId: PropTypes.number,
  demand: DemandPropType,
  loadsFromOrg: loadsFromOrgType,
  isNewDemand: PropTypes.bool,
  isLastDemand: PropTypes.bool,
  onCancel: PropTypes.func,
  addrFounded: AddrFoundedPropType,
  afterSave: PropTypes.func,
  listGroups: PropTypes.arrayOf(PropTypes.string),
};

export default DemandForm;
