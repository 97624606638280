import { Select } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { FieldDefinitionPropType, FormPropType } from '../tableFilter.propTypes';

const { Option } = Select;

function SelectFilter(props) {
  const { fieldDefinition, mode, allowClear, placeholder, style, handleChanges, form } = props;
  const { name: nameField, options = [] } = fieldDefinition;

  const customChange = (e) => {
    form.setFieldsValue({ [nameField]: e });
    handleChanges(e);
  };

  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  return (
    <Select
      name={nameField}
      mode={mode}
      allowClear={allowClear}
      style={style}
      placeholder={placeholder || i18n.t('commons.select')}
      onChange={customChange}
    >
      {options.map((option) => {
        return (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        );
      })}
    </Select>
  );
}

SelectFilter.defaultProps = {
  fieldDefinition: {},
  handleChanges: () => {
    console.warn('undefined handleChanges');
  },
  mode: 'multiple',
  allowClear: true,
  style: { width: '100%' },
  placeholder: undefined,
  form: {},
};

SelectFilter.propTypes = {
  fieldDefinition: FieldDefinitionPropType,
  handleChanges: PropTypes.func,
  mode: PropTypes.string,
  allowClear: PropTypes.bool,
  style: PropTypes.shape({ width: PropTypes.string }),
  placeholder: PropTypes.string,
  form: FormPropType,
};

export default SelectFilter;
