import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { convertDateToDayjs } from 'services/repeated-functions';
import { SynopticItem } from '../../SynopticSettings.propTypes';
import itemTypes from '../../itemTypes.json';

const { Text, Title } = Typography;

// Returns the DEPOT, PARKING or their RETURN_TO type component to be rendered on the popover.
function DepotParkingItem(props) {
  const { itemData, timezone } = props;

  const spanTitle = 8;
  const spanInfo = 24 - spanTitle;

  const textType = itemTypes[itemData.type];
  const startsAt = dayjs(itemData.arrivesAt);

  const dayText = convertDateToDayjs(startsAt, timezone, 'DD-MM-YYYY');
  const initTimeText = convertDateToDayjs(startsAt, timezone, 'HH:mm');

  return (
    <>
      <hr style={{ margin: '0 10px 15px 0' }} />
      <div style={{ margin: '0 10px 15px 10px' }}>
        <Row>
          <Col>
            <Title level={5}>{textType}</Title>
          </Col>
        </Row>
        <Row>
          <Col span={spanTitle}>
            <Text strong>Fecha:</Text>
          </Col>
          <Col span={spanInfo}>
            <Text>{dayText}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={spanTitle}>
            <Text strong>Hora:</Text>
          </Col>
          <Col span={spanInfo}>
            <Text>{initTimeText}</Text>
          </Col>
        </Row>
      </div>
    </>
  );
}
DepotParkingItem.defaultProps = {
  itemData: {}, // object. Selected item data to be shown.
  timezone: 'America/Santiago',
};
DepotParkingItem.propTypes = {
  itemData: SynopticItem,
  timezone: PropTypes.string,
};

export default DepotParkingItem;
