import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  App,
  Avatar,
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Tag,
  Timeline,
  Typography,
  theme,
} from 'antd';
import { VehiclePositionPropTypes } from 'components/Vehicles/vehicles.propTypes';
import LeafletMap from 'components/common/Map/LeafletMap';
import PageHeader from 'components/common/PageHeader';
import TitleHelp from 'components/common/TitleHelp';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ColorByStatusEvent, DataByStatusEvent, FormatToTime } from 'services/repeated-functions';
import useIdleTimeout from 'services/useIdleTimeout';
import socket from '../../../../socket';
import { FollowingRouteProps } from '../../following.propTypes';
import DrawerEvents from '../DrawerEvents';
import TableDetailRoute from '../TableDetail';
import TimeLineRoute from '../TimeLine';

const { Text } = Typography;

function RouteDetailContent(props) {
  const { route, isConnected, vehiclesPositionEvents } = props;
  const {
    driver,
    vehicle = {},
    depot,
    items: itemsFromRoute,
    routingSetId,
    routeOrder,
    totalDistance,
    startsAt,
    endsAt,
    startedAt,
    finishedAt,
  } = route;
  const totalStops = itemsFromRoute.filter((item) => item.type === 'STOP').length;
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [items, setItems] = useState([]);
  const [mapPoints, setMapPoints] = useState([]);
  const [moveToPosition, setMoveToPosition] = useState(undefined);
  const [stringSearch, setStringSearch] = useState(undefined);
  const { id: currOrgId, timezone } = useSelector(selectCurrentOrganization);
  const { data: organization } = useGetOrganizationQuery(currOrgId);
  const loadsFromOrg = organization?.loadsUnit || [];
  const itemsRef = useRef();
  const { message } = App.useApp();
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);
  const [offlineMode, setOfflineMode] = useState(false);
  const { addReference } = useContext(TourContext);
  const ref = useRef(null);
  const refSearch = useRef(null);
  const refResume = useRef(null);
  const refButtonEvents = useRef(null);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };
  addReference('detailRouteFollowingRef', ref);
  addReference('detailRouteSearchFollowingRef', refSearch);
  addReference('detailRouteMapResumeFollowingRef', refResume);
  addReference('detailRouteButtonEventsFollowingRef', refButtonEvents);

  // for reuse move up
  const onSearch = (value) => {
    // search in timeline for name or document
    if (value) {
      const re = new RegExp(value, 'i');
      const foundedItem = items.find(({ name, document }) => name?.match(re) || document.match(re));
      if (foundedItem) {
        const newItems = items.map((item) => {
          const isFocused = foundedItem.id === item.id;
          return { ...item, isFocused, ref: isFocused ? itemsRef : null };
        });
        setItems(newItems);
        setMoveToPosition([foundedItem.latitude, foundedItem.longitude]);
        setStringSearch(value);
        // do scroll move
        setTimeout(() => {
          itemsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 500);
      } else {
        message.warning(i18n.t('noMatch', scopeI18n));
        setItems(itemsFromRoute);
      }
    } else {
      // on clear filter reset
      setMoveToPosition(undefined);
      setItems(itemsFromRoute);
      setStringSearch(undefined);
    }
  };

  const callbackResearch = () => {
    if (stringSearch) {
      onSearch(stringSearch);
    }
  };

  // handle idle
  const handleIdle = () => {
    setIsIdleModalOpen(true);
    setMoveToPosition(undefined);
  };
  const { isIdle, activate, setDisableTimer, remaining } = useIdleTimeout({
    onIdle: handleIdle,
    idleTime: 3600,
    callback: callbackResearch,
  });

  useEffect(() => {
    // no-op if the socket is already connected
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isIdle) {
      console.log('now im idle');
      setIsIdleModalOpen(false);
      setDisableTimer(true);
      setOfflineMode(true);
      socket.disconnect();
    }
    console.log('RouteDetailContent isConnected:', isConnected);
  }, [isIdle, setDisableTimer, setOfflineMode, isConnected]);

  const handleIdleOk = () => {
    setIsIdleModalOpen(false);
    activate();
    callbackResearch();
  };

  const handleIdleCancel = () => {
    setIsIdleModalOpen(false);
    setDisableTimer(true);
    setOfflineMode(true);
    callbackResearch();
  };

  const modalIdleTitle = () => {
    return (
      <Space
        direction="vertical"
        size="middle"
        align="center"
        style={{ width: '100%', justifyContent: 'center', marginBottom: '15px' }}
      >
        <FontAwesomeIcon
          icon={['fas', 'fa-circle-exclamation']}
          color={token.colorWarning}
          style={{ marginLeft: '1rem', marginRight: '1rem', fontSize: '40px' }}
        />
        <strong>{i18n.t('inactivityNotice', scopeI18n)}</strong>
      </Space>
    );
  };

  useEffect(() => {
    const asPoints = [];
    let vehicleAdded = false;
    // case route finished don't display vehicle
    if (vehicle === null || finishedAt) {
      vehicleAdded = true;
    }
    items.forEach((item) => {
      const { id: key, latitude: lat, longitude: lng, type, itemOrder, events } = item;
      const lastEvent = events.length > 0 ? events[events.length - 1] : undefined;
      const color = ColorByStatusEvent(lastEvent?.status);
      if (['ARRIVAL', undefined].includes(lastEvent?.status) && type !== 'DEPOT' && !vehicleAdded) {
        // try to add vehicle before pending point
        if (vehicle != null) {
          const { latitude, longitude, id: vehicleId } = vehicle;
          if (latitude && longitude) {
            const vehicleData = {
              key: `veh-${vehicleId}`,
              lat: latitude,
              lng: longitude,
              type: 'vehicle',
            };
            asPoints.push(vehicleData);
            vehicleAdded = true;
          }
        }
      }
      asPoints.push({
        key,
        lat,
        lng,
        type,
        order: itemOrder,
        color,
      });
    });

    setMapPoints(asPoints);
  }, [finishedAt, items, vehicle]);

  useEffect(() => {
    if (!offlineMode) {
      if (vehiclesPositionEvents.length === 0) {
        return;
      }
      const newMapPoints = [...mapPoints].map((object) => {
        const foundedVehicle = vehiclesPositionEvents
          .sort((a, b) => Number(new Date(a.timestamp)) - Number(new Date(b.timestamp)))
          .findLast((vD) => `veh-${vD.vehicleId.toString()}` === object.key.toString());
        if (object.key.toString() === `veh-${foundedVehicle?.vehicleId?.toString()}`) {
          return {
            ...object,
            lat: `${foundedVehicle.latitude}`,
            lng: `${foundedVehicle.longitude}`,
          };
        }
        return object;
      });
      if (JSON.stringify(mapPoints) !== JSON.stringify(newMapPoints)) {
        setMapPoints(newMapPoints);
      }
    }
  }, [offlineMode, mapPoints, vehiclesPositionEvents]);

  const switchChange = (e) => {
    setShowTable(e);
  };

  const onClickEvents = (id) => {
    if (id) {
      const foundedItem = items.find((item) => item.id === id);
      setSelectedItem(foundedItem);
    }
  };

  useEffect(() => {
    // ToDo: check how mark an item with alert (hasAlert)
    setItems(itemsFromRoute);
  }, [itemsFromRoute, showTable]);

  const itemsTimeline = items.map((item) => {
    return TimeLineRoute(
      item,
      onClickEvents,
      loadsFromOrg,
      startedAt,
      finishedAt,
      timezone,
      items,
      refButtonEvents
    );
  });

  const onSearchTable = (value) => {
    // search in timeline for name or document
    if (value) {
      const re = new RegExp(value, 'i');
      const foundedItems = itemsFromRoute.filter(
        ({ name, document }) => name?.match(re) || document.match(re)
      );
      if (foundedItems.length > 0) {
        setItems(foundedItems);
      } else {
        message.warning(i18n.t('noMatch', scopeI18n));
        setItems(itemsFromRoute);
      }
    } else {
      // on clear filter reset
      setMoveToPosition(undefined);
      setItems(itemsFromRoute);
    }
  };

  const getResumeForRoute = () => {
    let totalSuccess = 0;
    let totalPartial = 0;
    let totalFailure = 0;
    // use itemsFromRoute, because dont change with filters
    itemsFromRoute.forEach((item) => {
      if (item.events) {
        if (item.events.some((event) => event.status === 'SUCCESS')) {
          totalSuccess += 1;
        }
        if (item.events.some((event) => event.status === 'PARTIAL')) {
          totalPartial += 1;
        }
        if (item.events.some((event) => event.status === 'FAILURE')) {
          totalFailure += 1;
        }
      }
    });
    const allTotal = totalSuccess + totalPartial + totalFailure;
    // ToDo: replace with some math calc
    const totalAlert = 0;
    const totalCompliance = (
      <Text type="secondary">
        <TitleHelp
          title={i18n.t('totalCompliance', scopeI18n)}
          helpText={i18n.t('helps.totalCompliance', scopeI18n)}
        />
      </Text>
    );
    const styleText = { fontSize: 24, fontWeight: 400 };
    const totalAsText = (total) => {
      return (
        <Space style={styleText}>
          <Text type="secondary" style={styleText}>
            {total}
          </Text>
          /<Text style={styleText}>{totalStops}</Text>
        </Space>
      );
    };
    const titleStatus = (status) => {
      const { color, text } = DataByStatusEvent(status, i18n);
      const icon = status === 'ALERT' ? 'exclamation-triangle' : 'circle';
      return (
        <Space>
          <FontAwesomeIcon icon={icon} color={color} />
          <Text>{text}</Text>
        </Space>
      );
    };
    return [
      {
        colSpan: { xl: 5, lg: 12, md: 12, sm: 12 },
        title: totalCompliance,
        value: totalAsText(allTotal),
      },
      {
        colSpan: { xl: 5, lg: 9, md: 8, sm: 12 },
        title: titleStatus('SUCCESS'),
        value: totalAsText(totalSuccess),
      },
      {
        colSpan: { xl: 4, lg: 6, md: 8, sm: 12 },
        title: titleStatus('PARTIAL'),
        value: totalAsText(totalPartial),
      },
      {
        colSpan: { xl: 5, lg: 9, md: 8, sm: 12 },
        title: titleStatus('FAILURE'),
        value: totalAsText(totalFailure),
      },
      {
        colSpan: { xl: 5, lg: 12, md: 12, sm: 12 },
        title: titleStatus('ALERT'),
        value: <Text style={styleText}>{totalAlert}</Text>,
      },
    ];
  };
  const onReloadPage = () => {
    navigate(0);
  };
  const mapStatusIcon = (
    <Tag>
      <FontAwesomeIcon
        key="status-icon"
        title={offlineMode ? i18n.t('inactive', scopeI18n) : i18n.t('active', scopeI18n)}
        icon="circle"
        beatFade={!offlineMode}
        className={`color-${offlineMode ? 'negative' : 'positive'}-gps`}
      />
    </Tag>
  );
  const reloadMapButton = (
    <Button
      style={{ fontSize: '10px', height: '22px', paddingTop: '2px' }}
      key="refresh-button"
      icon={<FontAwesomeIcon icon={['fas', 'clock-rotate-left']} />}
      onClick={() => onReloadPage()}
      title={i18n.t('reload', scopeI18n)}
    />
  );
  return (
    <>
      <Modal
        open={isIdleModalOpen}
        onOk={handleIdleOk}
        onCancel={handleIdleCancel}
        okText={i18n.t('keepActive', scopeI18n)}
        cancelText={i18n.t('close', scopeI18n)}
        okButtonProps={{ style: { backgroundColor: token.colorWarning } }}
        closable={false}
        centered
      >
        {modalIdleTitle()}
        <p style={{ textAlign: 'center' }}>
          {i18n.t('mapUnusedNotice', { ...scopeI18n, number: remaining })}
        </p>
      </Modal>
      <Row gutter={[12, 12]}>
        <Col style={{ minHeight: showTable ? 'unset' : '80vh' }} span={9}>
          <Row gutter={[12, 12]}>
            <Col span={20}>
              <Space size="small" align="baseline">
                <PageHeader
                  title={i18n.t('routingSetRoute', { ...scopeI18n, routingSetId, routeOrder })}
                  onBack={() => navigate(-1)}
                />
                <Row justify="end">
                  <Col>
                    {mapStatusIcon}
                    {reloadMapButton}
                  </Col>
                </Row>
              </Space>
            </Col>
            <Col span={4} style={{ paddingTop: '1rem' }}>
              <Switch defaultChecked={showTable} onChange={switchChange} />{' '}
              {i18n.t('table', scopeI18n)}
            </Col>
          </Row>
          <Row gutter={[12, 12]} ref={ref}>
            <Col span={3} offset={1}>
              <Avatar
                size={48}
                icon={<FontAwesomeIcon icon={['fa', 'user']} />}
                alt={i18n.t('profile.altAvatar')}
                src={driver?.photo}
                style={{ marginTop: '1.5rem' }}
              />
            </Col>
            <Col span={20}>
              <Row>
                <Col span={24}>
                  <Text strong title={driver?.fullName}>
                    {driver ? driver.fullName : i18n.t('notAssigned', scopeI18n)}
                  </Text>
                </Col>
                <Col span={24}>{vehicle ? vehicle.plate : i18n.t('notAssigned', scopeI18n)}</Col>
                <Col span={24}>
                  <Text type="secondary">
                    {driver ? driver.phone : i18n.t('noPhoneNumber', scopeI18n)}
                  </Text>
                </Col>
                <Col span={24}>
                  <Text type="secondary">
                    {i18n.t('routeResume', {
                      ...scopeI18n,
                      depot: depot?.name,
                      totalStops,
                      distance: (parseFloat(totalDistance) / 1000).toFixed(2),
                    })}
                  </Text>
                </Col>
                <Col span={24}>
                  <Text type="secondary">
                    {FormatToTime(startsAt, timezone)} - {FormatToTime(endsAt, timezone)}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          {!showTable && (
            <>
              <Row gutter={[12, 12]} style={{ marginTop: '1rem' }} ref={refSearch}>
                <Col span={24}>
                  <Input.Search
                    allowClear
                    placeholder={i18n.t('searchDocumentClient', scopeI18n)}
                    onSearch={onSearch}
                  />
                </Col>
              </Row>
              <Row gutter={[12, 12]} className="row-timeline-container" style={{}}>
                <Col span={24} className="timeline-container">
                  <Timeline items={itemsTimeline} />
                </Col>
              </Row>
            </>
          )}
        </Col>
        {showTable && (
          <Col span={15}>
            <Row>
              {getResumeForRoute().map((resume, index) => {
                const { colSpan, title, value } = resume;
                const { lg, md, sm, xl } = colSpan;
                const key = typeof title === 'string' ? title : `cg-title${index}`;
                return (
                  <Col
                    key={`col-${key}`}
                    lg={lg}
                    md={md}
                    sm={sm}
                    xl={xl}
                    style={{ display: 'flex' }}
                  >
                    <Card
                      size="small"
                      style={{ width: '100%', borderRadius: '0' }}
                      bordered={false}
                    >
                      <span className="title-route-resume-card">{title}</span>
                      <br />
                      <span className="value-route-resume-card">{value}</span>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <Row gutter={[12, 12]} style={{ marginTop: '1rem' }}>
              <Col span={12} offset={12}>
                <Input.Search
                  allowClear
                  placeholder={i18n.t('searchDocumentClient', scopeI18n)}
                  onSearch={onSearchTable}
                />
              </Col>
            </Row>
          </Col>
        )}
        {showTable && (
          <Col span={24} style={{ minHeight: '80vh' }}>
            <TableDetailRoute
              allItems={itemsFromRoute}
              items={items}
              routeStartedAt={startedAt}
              routeFinishedAt={finishedAt}
              timezone={timezone}
              showDrawerEvents={onClickEvents}
            />
          </Col>
        )}
        {!showTable && (
          <Col style={{ minHeight: '80vh' }} span={15}>
            <LeafletMap
              useContainerSize
              showAlertRoutes={false}
              resumeCardContent={getResumeForRoute()}
              refResumeCard={refResume}
              baseSpanCardContent={{ span: 22, offset: 1 }}
              dataSource={mapPoints}
              typeMarkers="routeDetail"
              moveToPosition={moveToPosition}
              markerTriggerCallback={onClickEvents}
            />
          </Col>
        )}
      </Row>
      <DrawerEvents
        item={selectedItem}
        setSelectedItem={setSelectedItem}
        loadsFromOrg={loadsFromOrg}
      />
    </>
  );
}

RouteDetailContent.defaultProps = {
  route: {},
  isConnected: false,
  vehiclesPositionEvents: [],
};

RouteDetailContent.propTypes = {
  route: FollowingRouteProps,
  isConnected: PropTypes.bool,
  vehiclesPositionEvents: PropTypes.arrayOf(VehiclePositionPropTypes),
};

export default RouteDetailContent;
