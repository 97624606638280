import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';
import Loading from '.';

const LoadingContext = createContext({
  loading: false,
  setLoading: null,
});

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const value = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);
  return (
    <LoadingContext.Provider value={value}>
      {loading ? <Loading /> : children}
    </LoadingContext.Provider>
  );
}
LoadingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
