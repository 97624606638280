import { Col, Row, Typography } from 'antd';
import { find } from 'lodash';
import { inputTypes } from 'services/repeated-functions';
import RoutingParametersPropTypes from './routingParameters.propTypes';

const { Text } = Typography;

function RoutingParametersShow(props) {
  const {
    parameter: { name, value, profile, type, label },
  } = props;
  const typeLabel = find(inputTypes, { value: type })?.label || type;
  return (
    <Row gutter={24}>
      <Col span={20} offset={2}>
        <Row gutter={24}>
          <Col span={8} md={7} offset={2}>
            <Text strong>Perfil:</Text>
          </Col>
          <Col span={16} md={15}>
            {profile || '-'}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8} md={7} offset={2}>
            <Text strong>Etiqueta:</Text>
          </Col>
          <Col span={16} md={15}>
            {label}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8} md={7} offset={2}>
            <Text strong>Nombre de parámetro:</Text>
          </Col>
          <Col span={16} md={15}>
            {name}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8} md={7} offset={2}>
            <Text strong>Valor:</Text>
          </Col>
          <Col span={16} md={15}>
            {value}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8} md={7} offset={2}>
            <Text strong>Tipo de dato:</Text>
          </Col>
          <Col span={16} md={15}>
            {typeLabel}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

RoutingParametersShow.propTypes = {
  parameter: RoutingParametersPropTypes,
};

RoutingParametersShow.defaultProps = {
  parameter: {},
};

export default RoutingParametersShow;
