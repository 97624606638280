import { textWithEllipsis } from 'services/repeated-functions';

const executionReportColumns = [
  {
    width: 100,
    title: 'ID de Ruta',
    dataIndex: 'routingSetId',
    key: 'routingSetId',
    transferKey: 'rs.id',
  },
  {
    width: 80,
    title: 'Orden rutas',
    dataIndex: 'routeOrder',
    key: 'routeOrder',
    transferKey: 'ro.routeOrder',
  },
  {
    width: 120,
    title: 'Organización',
    dataIndex: 'organizationName',
    key: 'organizationName',
    transferKey: 'or.name',
    render: textWithEllipsis,
  },
  {
    width: 80,
    title: 'Zona horaria',
    dataIndex: 'organizationTimezone',
    key: 'organizationTimezone',
    transferKey: 'or.timezone',
  },
  {
    width: 120,
    title: 'Base',
    dataIndex: 'depotName',
    key: 'depotName',
    transferKey: 'de.name',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Conjunto de rutas',
    dataIndex: 'routingSetName',
    key: 'routingSetName',
    transferKey: 'rs.name',
    render: textWithEllipsis,
  },
  {
    width: 100,
    title: 'Inicio de ruta',
    dataIndex: 'routeStartsAt',
    key: 'routeStartsAt',
    transferKey: 'ro.startsAt',
  },
  {
    width: 100,
    title: 'Fin de ruta',
    dataIndex: 'routeEndsAt',
    key: 'routeEndsAt',
    transferKey: 'ro.endsAt',
  },
  {
    width: 100,
    title: 'Estado de ruta',
    dataIndex: 'routeStatus',
    key: 'routeStatus',
    transferKey: 'ro.status',
  },
  {
    width: 100,
    title: 'Tiempo total',
    dataIndex: 'routeTravelTime',
    key: 'routeTravelTime',
    transferKey: 'ro.travelTime',
  },
  {
    width: 100,
    title: 'Distancia total',
    dataIndex: 'routeTotalDistance',
    key: 'routeTotalDistance',
    transferKey: 'ro.totalDistance',
  },
  // item
  {
    width: 80,
    title: 'Orden paradas',
    dataIndex: 'itemOrder',
    key: 'itemOrder',
    transferKey: 'it.itemOrder',
  },
  {
    width: 100,
    title: 'Parada',
    dataIndex: 'itemName',
    key: 'itemName',
    transferKey: 'it.name',
    render: textWithEllipsis,
  },
  {
    width: 100,
    title: 'Llegada planificada',
    dataIndex: 'itemArrivesAt',
    key: 'itemArrivesAt',
    transferKey: 'it.arrivesAt',
  },
  {
    width: 100,
    title: 'Salida planificada',
    dataIndex: 'itemDepartsAt',
    key: 'itemDepartsAt',
    transferKey: 'it.departsAt',
  },
  {
    width: 100,
    title: 'Documento',
    dataIndex: 'itemDocument',
    key: 'itemDocument',
    transferKey: 'it.document',
    render: textWithEllipsis,
  },
  {
    width: 50,
    title: 'Vip',
    dataIndex: 'itemVip',
    key: 'itemVip',
    transferKey: 'it.vip',
  },
  {
    width: 100,
    title: 'Contacto',
    dataIndex: 'itemContactName',
    key: 'itemContactName',
    transferKey: 'it.contactName',
    render: textWithEllipsis,
  },
  {
    width: 100,
    title: 'Teléfonos',
    dataIndex: 'itemPhones',
    key: 'itemPhones',
    transferKey: 'it.contactPhones',
    render: textWithEllipsis,
  },
  {
    width: 100,
    title: 'Emails',
    dataIndex: 'itemEmails',
    key: 'itemEmails',
    transferKey: 'it.contactEmails',
    render: textWithEllipsis,
  },
  {
    width: 110,
    title: 'Coordenadas',
    dataIndex: 'itemCoords',
    key: 'itemCoords',
    transferKey: 'it.latitude',
  },
  {
    width: 100,
    title: 'País',
    dataIndex: 'itemCountry',
    key: 'itemCountry',
    transferKey: 'it.addr.country',
  },
  {
    width: 100,
    title: 'Región o Provincia',
    dataIndex: 'itemProvince',
    key: 'itemProvince',
    transferKey: 'it.addr.province',
  },
  {
    width: 100,
    title: 'Ciudad',
    dataIndex: 'itemCity',
    key: 'itemCity',
    transferKey: 'it.addr.city',
  },
  {
    width: 100,
    title: 'Calle',
    dataIndex: 'itemStreet',
    key: 'itemStreet',
    transferKey: 'it.addr.street',
    render: textWithEllipsis,
  },
  {
    width: 80,
    title: 'Número',
    dataIndex: 'itemStreetNumber',
    key: 'itemStreetNumber',
    transferKey: 'it.addr.number',
  },
  {
    width: 90,
    title: 'Referencia',
    dataIndex: 'itemStreetLine2',
    key: 'itemStreetLine2',
    transferKey: 'it.addr.line2',
    render: textWithEllipsis,
  },
  // driver-assistant-vehicle
  {
    width: 120,
    title: 'Conductor',
    dataIndex: 'driverName',
    key: 'driverName',
    transferKey: 'dr.name',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Asistente',
    dataIndex: 'assistantName',
    key: 'assistantName',
    transferKey: 'as.name',
    render: textWithEllipsis,
  },
  {
    width: 100,
    title: 'Vehículo',
    dataIndex: 'vehiclePlate',
    key: 'vehiclePlate',
    transferKey: 've.plate',
  },
  {
    width: 100,
    title: 'Capacidad',
    dataIndex: 'vehicleLoad',
    key: 'vehicleLoad',
    transferKey: 've.loads',
  },
  {
    width: 100,
    title: 'Unidad de capacidad',
    dataIndex: 'vehicleLoadUnit',
    key: 'vehicleLoadUnit',
    transferKey: 'or.type', // use uniq value, for can use transfer
  },
  // event
  {
    width: 100,
    title: 'Último evento',
    dataIndex: 'eventTimeCaptured',
    key: 'eventTimeCaptured',
    transferKey: 'ev.timeCaptured',
  },
  {
    width: 120,
    title: 'Reportado por',
    dataIndex: 'eventUpdatedBy',
    key: 'eventUpdatedBy',
    transferKey: 'up.name',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Email',
    dataIndex: 'eventUpdaterEmail',
    key: 'eventUpdaterEmail',
    transferKey: 'up.email',
  },
  {
    width: 100,
    title: 'Hora de llegada',
    dataIndex: 'itemArrivedAt',
    key: 'itemArrivedAt',
    transferKey: 'it.arrivedAt',
  },
  {
    width: 100,
    title: 'Estado parada',
    dataIndex: 'eventStatus',
    key: 'eventStatus',
    transferKey: 'ev.status',
  },
  {
    width: 100,
    title: 'Ubicación',
    dataIndex: 'eventCoords',
    key: 'eventCoords',
    transferKey: 'ev.latitude',
  },
  {
    width: 120,
    title: 'Motivo',
    dataIndex: 'eventReason',
    key: 'eventReason',
    transferKey: 'ev.reason',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Comentario',
    dataIndex: 'eventComment',
    key: 'eventComment',
    transferKey: 'ev.comment',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Formulario',
    dataIndex: 'eventForm',
    key: 'eventForm',
    transferKey: 'ev.form',
    render: textWithEllipsis,
  },
];

export default executionReportColumns;
