import { Col, Divider, Row, Typography } from 'antd';
import CaShowField from 'components/common/CustomAttributes/ShowField';
import ShowDynamicFields from 'components/common/Form/ShowDynamicFields';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext } from 'react';
import { loadsFromOrgType } from 'types';
import { VehiclePropTypes } from '../vehicles.propTypes';

const { Text } = Typography;

function VehicleShow(props) {
  const { vehicle, loadsFromOrg } = props;
  const { plate, loads = [], deletedAt, customAttributes, restrictions } = vehicle;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'vehicles' };

  return (
    <Row gutter={24}>
      <Col span={20} offset={2}>
        <Row gutter={24}>
          <Col span={8} md={7} offset={2}>
            <Text strong>{i18n.t('plate', scopeI18n)}:</Text>
          </Col>
          <Col span={16} md={15}>
            {plate}
          </Col>
        </Row>
        {loadsFromOrg.map((loadForm, index) => {
          const { key, name } = loadForm;
          return (
            <Row key={`rowLf${key}`} gutter={24} style={{ marginTop: '.5rem' }}>
              <Col span={8} md={7} offset={2}>
                <Text strong>{name}:</Text>
              </Col>
              <Col span={14} md={14}>
                {loads[index]?.load}
              </Col>
            </Row>
          );
        })}
        <Row gutter={24}>
          <Divider orientation="left" orientationMargin="0">
            <Text strong>{i18n.t('additionalInformation', scopeI18n)}</Text>
          </Divider>
        </Row>
        {customAttributes?.map((ca) => (
          <CaShowField key={`casf${ca.id}`} customAttribute={ca} offset={2} />
        ))}
        <Row gutter={24} style={{ marginTop: '.5rem' }}>
          <Col span={8} md={7} offset={2}>
            <Text strong>{i18n.t('status', scopeI18n)}:</Text>
          </Col>
          <Col span={16} md={15}>
            {i18n.t(deletedAt ? 'inactive' : 'active', scopeI18n)}
          </Col>
        </Row>
        {restrictions && (
          <ShowDynamicFields
            type="VEHICLE"
            assignedValues={restrictions}
            offset={2}
            span={8}
            md={7}
          />
        )}
      </Col>
    </Row>
  );
}

VehicleShow.propTypes = {
  vehicle: VehiclePropTypes,
  loadsFromOrg: loadsFromOrgType,
};

VehicleShow.defaultProps = {
  vehicle: {},
  loadsFromOrg: [],
};
export default VehicleShow;
