import apiSlice from 'app/api/apiSlice';

/**
 * reportsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generatePreview: builder.mutation({
      query: (body) => ({
        url: `reports/preview`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Reports'],
    }),
    download: builder.mutation({
      query: (body) => ({
        url: `reports/download`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FileProcessing'],
    }),
  }),
});

export const { useGeneratePreviewMutation, useDownloadMutation } = reportsApiSlice;
