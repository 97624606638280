import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// Returns the svg component to draw the current time line in the "draw area".
function CurrentTime(props) {
  const {
    startTime,
    endTime,
    hSpaceDrawBorder,
    vSpaceHeader,
    vSpaceRoute,
    ticksShown,
    pxOnTick,
    routesShown,
    currentTime,
  } = props;
  const [x1, setX1] = useState(0);
  const totalDuration = endTime - startTime;

  // Maximum column width (in px, number) that the current time line will have to move through.,
  const columnDrawWidthMax = ticksShown * pxOnTick;
  // Max height for the svg canvas.
  const svgHeight = vSpaceHeader * 2 + vSpaceRoute * routesShown;

  useEffect(() => {
    const currentDuration = currentTime - startTime;
    setX1((currentDuration / totalDuration) * columnDrawWidthMax);
  }, [currentTime, startTime, totalDuration, columnDrawWidthMax]);

  return (
    // Usage is based on the settings for range on visualization
    startTime <= currentTime &&
    currentTime <= endTime && (
      <line
        strokeDasharray="5, 5"
        x1={hSpaceDrawBorder + x1}
        y1={vSpaceHeader}
        x2={hSpaceDrawBorder + x1}
        y2={svgHeight}
        stroke="black"
        strokeWidth="2"
      />
    )
  );
}
CurrentTime.defaultProps = {
  // *** User defined setings
  startTime: dayjs(), // Start Time, in date format.
  endTime: dayjs(), // End Time, in date format.
  // *** Synoptic space definitions
  ticksShown: 30, // Number of ticks to be traveled. In this module affects the horizontal length the current line must be through
  pxOnTick: 40, // Width of the tick in px. In this module affects the horizontal length the current line must be through.
  routesShown: 1, // Number of routes to be shown, if needed on header only define a 0. In this module affects the vertical length of the current time line.
  vSpaceRoute: 70, // Height of every rout to be shown. In this module affects the vertical length of the current time line.
  hSpaceDrawBorder: 40, // Horizontal space (in px, number) between the time rule, consider that the value must be added at the start and end.
  vSpaceHeader: 0, // Vertical space (in px, number) between main top lines, if included.
  currentTime: new Date(), // Date, actual time and date
};
CurrentTime.propTypes = {
  startTime: PropTypes.instanceOf(dayjs),
  endTime: PropTypes.instanceOf(dayjs),
  ticksShown: PropTypes.number,
  pxOnTick: PropTypes.number,
  routesShown: PropTypes.number,
  vSpaceRoute: PropTypes.number,
  hSpaceDrawBorder: PropTypes.number,
  vSpaceHeader: PropTypes.number,
  currentTime: PropTypes.instanceOf(Date),
};

export default CurrentTime;
