import IntroComponent from 'components/Help/Detail/common/introComponent';
import PropTypes from 'prop-types';
import GeoreferenceNote from '../common/georeferenceNote';
import RequirementsTable from '../common/requirementsTable';

function StructureNewVisits(props) {
  const { downloadExampleFile, helpBreadcrumbs } = props;
  const title = '¿Cuál es la estructura del archivo para visitas que no existen?';
  const mainText =
    'Si decides subir una demanda de tipo avanzada para visitas que no existen en el sistema';
  const commonRequired = [
    'Latitud y longitud',
    'País',
    'Región/Comuna',
    'Comuna',
    'Dirección',
    'Calle',
    'Número',
  ];
  const leftColRequired = ['Cargas'];
  const leftNote =
    'Si ingresaste latitud y longitud, no necesitas llenar los campos de país, región/provincia, comuna, dirección, calle y número. De igual manera, si completaste estos campos, no es necesario ingresar la latitud y longitud';
  const rightColRequired = ['SKU', 'Descripción', 'Cantidad', 'Cargas de productos'];

  return (
    <>
      {helpBreadcrumbs()}
      <IntroComponent
        title={title}
        type="advanced"
        mainText={mainText}
        downloadExampleFile={downloadExampleFile}
      />
      <GeoreferenceNote />
      <RequirementsTable
        leftColRequired={[...commonRequired, ...leftColRequired]}
        leftNote={leftNote}
        rightColRequired={[...commonRequired, ...rightColRequired]}
      />
    </>
  );
}

StructureNewVisits.defaultProps = {
  downloadExampleFile: () => {},
  helpBreadcrumbs: () => {},
};

StructureNewVisits.propTypes = {
  downloadExampleFile: PropTypes.func,
  helpBreadcrumbs: PropTypes.func,
};

export default StructureNewVisits;
