import apiSlice from 'app/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ user: name, pwd: password, notificationToken }) => ({
        url: '/auth/sign-in',
        method: 'POST',
        body: {
          name,
          password,
          notificationToken,
        },
      }),
    }),
    confirmAccount: builder.mutation({
      query: (credentials) => ({
        url: '/auth/confirm-account',
        method: 'POST',
        body: {
          username: credentials.user,
          password: credentials.pwd,
          new_password: credentials.newPwd,
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: {
          username: credentials.user,
        },
      }),
    }),
    recoverPassword: builder.mutation({
      query: (credentials) => ({
        url: '/auth/recover-password',
        method: 'POST',
        body: {
          username: credentials.user,
          password: credentials.pwd,
          code: credentials.code,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: (credentials) => ({
        url: '/auth/change-password',
        method: 'POST',
        body: {
          access_token: credentials.token,
          current_password: credentials.pwd,
          new_password: credentials.newPwd,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useConfirmAccountMutation,
  useResetPasswordMutation,
  useRecoverPasswordMutation,
  useChangePasswordMutation,
} = authApiSlice;
