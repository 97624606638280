import PropTypes from 'prop-types';

const FileListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    lastModified: PropTypes.number,
    name: PropTypes.string,
    lastModifiedDate: PropTypes.instanceOf(Date),
    originFileObj: PropTypes.instanceOf(File),
    percent: PropTypes.number,
    response: PropTypes.string,
    size: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string,
    uid: PropTypes.string,
  })
);

const ErrorToDisplayPropTypes = PropTypes.shape({
  uid: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        details: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.arrayOf(
            PropTypes.shape({
              leftCondition: PropTypes.arrayOf(PropTypes.string),
              rightCondition: PropTypes.arrayOf(PropTypes.string),
              specialCondition: PropTypes.arrayOf(PropTypes.string),
            })
          ),
        ]),
        messages: PropTypes.arrayOf(PropTypes.string),
      })
    )
  ),
});

const ErrorResponsePropTypes = PropTypes.objectOf(ErrorToDisplayPropTypes);

const OtherPayloadPropTypes = PropTypes.shape({
  georeference: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const LoadUnitsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.number,
    name: PropTypes.string,
    unit: PropTypes.string,
  })
);

const SettingsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    keyValue: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
          LoadUnitsPropTypes,
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.arrayOf(PropTypes.number),
        ])
      )
    ),
  })
);

export {
  ErrorResponsePropTypes,
  ErrorToDisplayPropTypes,
  FileListPropTypes,
  LoadUnitsPropTypes,
  OtherPayloadPropTypes,
  SettingsPropTypes,
};
