import { Alert, Drawer, Typography } from 'antd';
import FooterDrawer from 'components/common/FooterDrawer';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { convertDateToDayjs } from 'services/repeated-functions';
import { FollowingItemProp } from '../../following.propTypes';
import EventComponent from './EventComponent';

const { Title, Text } = Typography;

function DrawerEvents(props) {
  const { item, setSelectedItem, loadsFromOrg } = props;
  const { name, nid, events, arrivesAt, departsAt, loads, latitude, longitude } = item;
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };
  const eventsOrdered =
    events?.length === 0
      ? []
      : orderBy(
          events,
          [(object) => convertDateToDayjs(object.timeCaptured, tzOrganization)],
          ['desc']
        );

  const coordsItem = {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
  };
  const onClose = () => {
    setSelectedItem(undefined);
  };

  const getLoadsWithUnit = () => {
    return loads
      .map(({ load }, index) => {
        return `${load} ${loadsFromOrg[index]?.unit || ''}`;
      })
      .join(' - ');
  };

  const drawerTitle = () => {
    return (
      <>
        <Title level={5} style={{ fontSize: 18, marginBottom: 'unset' }}>
          {name}
        </Title>
        <Text type="secondary">{nid}</Text>
      </>
    );
  };

  const footer = <FooterDrawer onClose={onClose} />;

  return (
    <Drawer
      title={drawerTitle()}
      footer={footer}
      open={item.id}
      width="429px"
      onClose={(event) => onClose(event)}
      maskClosable={false}
      closable={false}
    >
      {events?.length === 0 && (
        <Alert
          message={i18n.t('noEventsReported', scopeI18n)}
          showIcon
          style={{ textAlign: 'center' }}
        />
      )}
      {eventsOrdered.map((event) => (
        <EventComponent
          key={event.id}
          event={event}
          arrivesAt={arrivesAt}
          departsAt={departsAt}
          loads={getLoadsWithUnit()}
          coordsItem={coordsItem}
        />
      ))}
    </Drawer>
  );
}

DrawerEvents.defaultProps = {
  item: { events: [] },
  setSelectedItem: () => {},
  loadsFromOrg: [],
};

DrawerEvents.propTypes = {
  item: FollowingItemProp,
  setSelectedItem: PropTypes.func,
  loadsFromOrg: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      name: PropTypes.string,
      unit: PropTypes.string,
    })
  ),
};

export default DrawerEvents;
