import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Col, List, Progress, Row, Space, Tooltip, Typography } from 'antd';
import RouteStatus from 'components/common/RouteStatus';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';
import { ColorByOrder } from 'services/repeated-functions';
import { loadsFromOrgType, RoutePropTypes } from 'types';
import Assigment from '../Assigment';
import ItemEdit from './ItemEdit';

const { Text } = Typography;

function RouteEdit(props) {
  const {
    route,
    checkedItems,
    setCheckedItems,
    loadsFromOrg,
    setRoutes,
    setAllRoutes,
    allRoutes,
    removeItemsIsLoading,
    setIsRoutingSetUnchanged,
    isRoutingSetUnchanged,
    canEdit,
    routeEditHeaderRef,
    setSelectedRoute,
    setItemSelected,
  } = props;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [routeReloading, setRouteReloading] = useState(false);
  const routeColor = ColorByOrder(route?.routeOrder);
  const driver = route?.driver;
  const vehicle = route?.vehicle;
  const setAssigmentDrawer = (boolValue) => {
    setDrawerVisible(boolValue);
  };
  const openAssigment = () => {
    setDrawerVisible(true);
  };

  const over80k = vehicle?.loads.some(({ load }) => load > 79999);
  const over1M = vehicle?.loads.some(({ load }) => load > 999999);
  let routeLoadsWidth = over80k ? 270 : 210;
  routeLoadsWidth = over1M ? 300 : routeLoadsWidth;

  const { addReference } = useContext(TourContext);
  addReference('routeEditHeaderRef', routeEditHeaderRef);
  const itemRemoveRef = useRef(null);
  addReference('itemRemoveRef', itemRemoveRef);
  const itemEditPositionRef = useRef(null);
  addReference('itemEditPositionRef', itemEditPositionRef);

  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'routes.editor.list' };

  return (
    <>
      {route?.id > 0 && canEdit && (
        <Assigment
          drawerVisible={drawerVisible}
          setDrawerAssigment={setAssigmentDrawer}
          routeOrder={route?.routeOrder}
          route={route}
        />
      )}
      <List
        style={{
          borderRight: `8px solid ${routeColor}`,
          borderRadius: '5px',
          marginBottom: '15px',
        }}
        bordered
        loading={routeReloading || removeItemsIsLoading}
        header={
          <div ref={route?.routeOrder === 1 ? routeEditHeaderRef : null}>
            <List.Item key={route?.id} style={{ padding: 0 }}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    size="large"
                    src={driver?.avatarUrl}
                    icon={<FontAwesomeIcon icon={['fa', 'user']} />}
                    style={{
                      border: `2px solid ${routeColor}`,
                    }}
                  />
                }
                title={
                  <Space>
                    <Text style={{ color: routeColor }} strong>
                      {i18n.t('routeName', {
                        ...scopeI18n,
                        nid: `${route?.routingSetId}-${route?.routeOrder}`,
                      })}
                    </Text>
                    {route?.relaxedConstraints && (
                      <Tooltip title={i18n.t('alerts.relaxedConstraints', scopeI18n)}>
                        <FontAwesomeIcon icon={['fa', 'triangle-exclamation']} color="#faad14" />
                      </Tooltip>
                    )}
                    <RouteStatus status={route?.status} />
                  </Space>
                }
                description={
                  <>
                    {driver ? (
                      <Text strong>
                        {`${driver?.name || ''} ${driver?.lastName || ''} / ${
                          vehicle?.plate || ''
                        }`}
                      </Text>
                    ) : (
                      i18n.t('unassigned', scopeI18n)
                    )}
                    {canEdit && (
                      <>
                        <br />
                        <Button
                          type="link"
                          size="small"
                          onClick={() => openAssigment()}
                          style={{ padding: 0 }}
                          disabled={!isRoutingSetUnchanged}
                          title={`${
                            !isRoutingSetUnchanged
                              ? i18n.t('alerts.saveBeforeAssign', scopeI18n)
                              : ''
                          }`}
                        >
                          {i18n.t(driver ? 'reassign' : 'assign', scopeI18n)}
                        </Button>
                      </>
                    )}
                  </>
                }
              />
              <Space
                direction="vertical"
                style={{
                  width: routeLoadsWidth,
                }}
              >
                {loadsFromOrg?.map(({ unit: unitLoad }, indexLoad) => {
                  const routeLoad = route?.loads[indexLoad]?.load;
                  const vehicleLoad = vehicle?.loads[indexLoad]?.load;
                  const percentLoad = vehicleLoad ? (routeLoad * 100) / vehicleLoad : 0;
                  const roundLoad = Math.round(percentLoad);
                  const keyLoad = `${route?.id}-loads-rw-${indexLoad}`;
                  const status = roundLoad > 100 ? 'exception' : 'normal';
                  const title =
                    roundLoad > 100
                      ? i18n.t('alert.vehicleCapacityExceeded', scopeI18n)
                      : i18n.t('vehicleCapacity', scopeI18n);
                  return (
                    <Row gutter={8} key={keyLoad}>
                      <Col span={8}>
                        <Progress percent={roundLoad} status={status} size="small" title={title} />
                      </Col>
                      <Col span={16}>
                        |{' '}
                        <Text type="secondary">
                          {routeLoad} {unitLoad}
                        </Text>{' '}
                        /{' '}
                        <Text
                          strong
                          title={vehicleLoad ? '' : i18n.t('unassignedVehicle', scopeI18n)}
                        >
                          {vehicleLoad || '-'} {unitLoad}
                        </Text>
                      </Col>
                    </Row>
                  );
                })}
              </Space>
            </List.Item>
          </div>
        }
        dataSource={route?.items}
        renderItem={(item, itemIndexPosition) => (
          <ItemEdit
            item={item}
            route={route}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            loadsFromOrg={loadsFromOrg}
            setRoutes={setRoutes}
            setAllRoutes={setAllRoutes}
            allRoutes={allRoutes}
            setRouteReloading={setRouteReloading}
            setIsRoutingSetUnchanged={setIsRoutingSetUnchanged}
            canEdit={canEdit}
            itemRemoveRef={itemRemoveRef}
            itemEditPositionRef={itemEditPositionRef}
            itemIndexPosition={itemIndexPosition}
            setSelectedRoute={setSelectedRoute}
            setItemSelected={setItemSelected}
          />
        )}
      />
    </>
  );
}

RouteEdit.defaultProps = {
  route: [],
  checkedItems: [],
  setCheckedItems: () => {},
  setRoutes: () => {},
  setAllRoutes: () => {},
  allRoutes: [],
  removeItemsIsLoading: false,
  setIsRoutingSetUnchanged: () => {},
  isRoutingSetUnchanged: true,
  canEdit: false,
  routeEditHeaderRef: null,
  setSelectedRoute: () => {},
  setItemSelected: () => {},
};

RouteEdit.propTypes = {
  route: RoutePropTypes,
  checkedItems: PropTypes.arrayOf(PropTypes.number),
  setCheckedItems: PropTypes.func,
  loadsFromOrg: loadsFromOrgType.isRequired,
  setRoutes: PropTypes.func,
  setAllRoutes: PropTypes.func,
  allRoutes: PropTypes.arrayOf(RoutePropTypes),
  removeItemsIsLoading: PropTypes.bool,
  setIsRoutingSetUnchanged: PropTypes.func,
  isRoutingSetUnchanged: PropTypes.bool,
  canEdit: PropTypes.bool,
  routeEditHeaderRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  setSelectedRoute: PropTypes.func,
  setItemSelected: PropTypes.func,
};

export default RouteEdit;
