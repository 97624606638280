import { App, Form, Skeleton } from 'antd';
import { useUpdateOrganizationMutation } from 'features/organizations/organizationsApiSlice';
import {
  useCreateSettingMutation,
  useGetSettingsQuery,
  useUpdateSettingMutation,
} from 'features/settings/settingsApiSlice';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { displayUnauthorizedNotification } from 'services/repeated-functions';
import FormBase from './FormBase';

import './style.css';

function MobileConfiguration(props) {
  const { organizationId, submitButton, goToNextStep, current } = props;
  const [form] = Form.useForm();
  const defaultStatus = ['success', 'partial', 'failed', 'postponed'];
  const navigate = useNavigate();
  const type = 'MOBILE';
  const url = `${type}?format=false${organizationId ? `&organizationId=${organizationId}` : ''}`;
  const {
    data: settingsFields,
    isLoading,
    isFetching,
    refetch,
    error: fetchError,
  } = useGetSettingsQuery(url);
  const [updateSettingMobile, { isLoading: updatingData }] = useUpdateSettingMutation();
  const [createSettingMobile, { isLoading: savingData }] = useCreateSettingMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { message, notification } = App.useApp();

  const defaultValues = {
    reportStates: defaultStatus,
    typeFlow: 'ez',
    typeReport: 'multi',
    enableEditQuantityOnsuccess: 'both',
    enableEditQuantityOnpartial: 'both',
    enableEditQuantityOnfailed: 'both',
    enableEditQuantityOnpostponed: 'both',
    photoOnsuccess: false,
    enableSignOnsuccess: false,
    enableFormOnsuccess: false,
    photoOnpartial: false,
    enableSignOnpartial: false,
    enableFormOnpartial: false,
    photoOnfailed: false,
    enableSignOnfailed: false,
    enableFormOnfailed: false,
    photoOnpostponed: false,
    enableSignOnpostponed: false,
    enableFormOnpostponed: false,
    enableAlert: false,
    forceReport: false,
    enableNotification: false,
    enableOrder: false,
    radiousOutPosition: 500,
    acceptableDelay: 90,
    acceptableDelayUnit: 'minutes',
    enableCancelOrder: false,
    enableLimitCancelOrder: false,
    reasonRequiredOnsuccess: false,
    enableCommentOnsuccess: false,
    reasonsOnsuccess: [],
    commentRequiredOnsuccess: false,
    questionsOnsuccess: [],
    reasonRequiredOnpartial: false,
    enableCommentOnpartial: false,
    reasonsOnpartial: [],
    commentRequiredOnpartial: false,
    questionsOnpartial: [],
    reasonRequiredOnfailed: false,
    enableCommentOnfailed: false,
    reasonsOnfailed: [],
    commentRequiredOnfailed: false,
    questionsOnfailed: [],
    reasonRequiredOnpostponed: false,
    enableCommentOnpostponed: false,
    reasonsOnpostponed: [],
    commentRequiredOnpostponed: false,
    minRouteStartDelayHours: 24,
    maxRouteStartDelayHours: 24,
  };
  const error403 = fetchError?.status === 403;

  useEffect(() => {
    if (error403) {
      const urlRedirect = submitButton ? '/unauthorized' : '/configurations/unauthorized';
      navigate(urlRedirect);
      displayUnauthorizedNotification(notification);
    }
  }, [error403, navigate, notification, submitButton]);

  const valuesFromDb = {};
  if (!isFetching && !fetchError && settingsFields.length > 0) {
    const fields = settingsFields[0].keyValue;
    fields.forEach((field) => {
      Object.keys(field).forEach((nameField) => {
        const valueField = field[nameField];
        valuesFromDb[nameField] = valueField;
      });
    });
    // assign id
    valuesFromDb.id = settingsFields[0]?.id;
  }
  const initialValues = { ...defaultValues, ...valuesFromDb };

  const onFinish = async (values) => {
    try {
      // get fields only with data
      const fieldsWithValues = Object.keys(values).filter((key) => {
        return values[key] !== undefined;
      });
      // build format to save
      const allFields = fieldsWithValues.map((field) => {
        const value = values[field];
        return { [field]: value };
      });
      const endObject = {
        id: initialValues.id,
        keyValue: allFields,
      };
      if (initialValues.id) {
        const body = {
          ...endObject,
          id: initialValues.id,
          ...(organizationId && { organizationId }),
        };
        await updateSettingMobile(body).unwrap();
        message.success('Configuración mobile actualizada correctamente');
      } else {
        await createSettingMobile({
          ...endObject,
          type: 'MOBILE',
          ...(organizationId && { organizationId }),
        }).unwrap();
        message.success('Configuración mobile creada correctamente');
      }
      if (goToNextStep) {
        await updateOrganization({
          organizationId,
          body: { creationStep: current + 1 },
        })
          .then(() => {
            const urlRedirect = `/organizations/creation-steps/${organizationId}/${current + 1}`;
            navigate(urlRedirect);
          })
          .catch((error) => {
            console.error(error);
            message.error('Error al guardar el formulario');
          });
      }
      // get value reloaded
      refetch();
    } catch (error) {
      console.error(error);
      message.error('Configuración mobile no pudo ser actualizada');
    }
  };

  if (isLoading) return <Skeleton paragraph={{ rows: 10 }} />;
  return (
    !isFetching && (
      <FormBase
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        savingData={savingData || updatingData}
        submitButton={submitButton}
      />
    )
  );
}

MobileConfiguration.defaultProps = {
  organizationId: null,
  submitButton: false,
  goToNextStep: false,
  current: null,
};
MobileConfiguration.propTypes = {
  organizationId: PropTypes.number,
  submitButton: PropTypes.bool,
  goToNextStep: PropTypes.bool,
  current: PropTypes.number,
};

export default MobileConfiguration;
