import apiSlice from 'app/api/apiSlice';

/**
 * routesApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const routesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoute: builder.query({
      query: (id) => `/routes/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Routes', id }],
    }),
    getRoutes: builder.query({
      query: () => '/routes',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Routes', id })) : ['Routes'],
    }),
    getRoutesByFilters: builder.mutation({
      query: (query) => `/routes${query}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Routes', id })) : ['Routes'],
    }),
    updateRoute: builder.mutation({
      query: ({ routeId, body }) => ({
        url: `/routes/${routeId}`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['RoutingSets'],
    }),
    getRelativeRoutes: builder.mutation({
      query: ({ routingSetId, body }) => ({
        url: `/routes/relative-routes/${routingSetId}`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['RoutingSets'],
    }),
    recalcutateTimes: builder.mutation({
      query: (params) => ({
        url: '/routes/recalculate-items-times',
        method: 'POST',
        body: { ...params },
      }),
    }),
    moveTimeRoute: builder.mutation({
      query: ({ id, body }) => ({
        url: `/routes/move-time/${id}`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['RoutingSets', 'Routes'],
    }),
    saveChangedRoutes: builder.mutation({
      query: ({ routingSetId, body }) => ({
        url: `/routes/save-changed-routes/${routingSetId}`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['RoutingSets', 'Routes'],
    }),
    validateRest: builder.mutation({
      query: (body) => ({
        url: `/routes/validate-rest`,
        method: 'POST',
        body: { ...body },
      }),
    }),
  }),
});

export const {
  useGetRouteQuery,
  useGetRoutesQuery,
  useUpdateRouteMutation,
  useGetRoutesByFiltersMutation,
  useGetRelativeRoutesMutation,
  useRecalcutateTimesMutation,
  useMoveTimeRouteMutation,
  useSaveChangedRoutesMutation,
  useValidateRestMutation,
  useAddRestMutation,
} = routesApiSlice;
