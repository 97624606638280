import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Result, Typography } from 'antd';
import { clearErrors, selectErrors } from 'features/errors/errorsSlice';
import { selectCurrentUser } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LocaleContext from '../../locale/LocaleContext';

function ErrorBoundary(props) {
  const { status, offline, children, allView } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);
  const [error, setError] = useState();
  const { Text } = Typography;
  const currentUser = useSelector(selectCurrentUser);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'errorBoundary' };

  useEffect(() => {
    const currentStatus = errors?.status || status;

    if (currentStatus) {
      setError(currentStatus);
    }
  }, [status, errors]);

  let title = i18n.t('title.default', scopeI18n);
  let subTitle = i18n.t('subtitle.default', scopeI18n);

  if (error === 403) {
    title = i18n.t('title.error403', scopeI18n);
    subTitle = i18n.t('subtitle.error403', scopeI18n);
  }

  if (error === 404) {
    title = i18n.t('title.error404', scopeI18n);
    subTitle = i18n.t('subtitle.error404', scopeI18n);
  }

  if (offline) {
    title = i18n.t('title.offline', scopeI18n);
    subTitle = i18n.t('subtitle.offline', scopeI18n);
  }

  const onPress = () => {
    dispatch(clearErrors());
    setError(null);
    // if is logged go to organization dashboard other way root
    navigate(currentUser ? '/organizations' : '/');
  };

  const renderButton = () => (
    <Button type="primary" onClick={onPress}>
      {i18n.t('button', scopeI18n)}
    </Button>
  );

  const renderNotification = () => (
    <Card className="notification">
      <div className="container-info">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ fontSize: 14, color: 'black' }}>
            <FontAwesomeIcon
              icon={['fa', 'circle-exclamation']}
              style={{ marginRight: 6, color: '#146ebe' }}
            />
            {i18n.t('warning', scopeI18n)}
          </Text>
          <Text style={{ fontSize: 11, marginLeft: 20 }}>{i18n.t('warning', scopeI18n)}</Text>
        </div>
        <FontAwesomeIcon
          icon={['fas', 'xmark']}
          style={{ fontSize: 11, cursor: 'pointer' }}
          onClick={() => setError(null)}
        />
      </div>
    </Card>
  );

  const styleOffline = { height: '95vh', paddingTop: '40vh' };

  const renderError = () => (
    <Result
      status={error}
      title={title}
      subTitle={subTitle}
      extra={offline ? undefined : renderButton()}
      style={{
        marginTop: allView ? 120 : 0,
        backgroundColor: 'white',
        ...(offline && styleOffline),
      }}
    />
  );

  return (
    <>
      {error === 400 && renderNotification()}
      {(error && error !== null && error !== 400) || offline ? renderError() : children}
    </>
  );
}

ErrorBoundary.propTypes = {
  status: PropTypes.number,
  offline: PropTypes.bool,
  children: PropTypes.node,
  allView: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  status: null,
  offline: false,
  children: null,
  allView: false,
};

export default ErrorBoundary;
