import { Checkbox, Table } from 'antd';
import TitleHelp from 'components/common/TitleHelp';
import TourContext from 'components/layout/TourComponent/TourContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { DefRoleColumns } from 'services/repeated-functions';
import CheckboxCol from './CheckboxCol';

function TableRoles(props) {
  const { roles, permissions, loading, data, callbackCol, callbackCheck, canUpdate } = props;
  const defCheckCols = DefRoleColumns();
  const refColRoleName = useRef(null);
  const refPermissionCol = useRef(null);
  const { addReference } = useContext(TourContext);
  addReference('columnRoleNameRoleRef', refColRoleName);
  addReference('columnPermissionNameRoleRef', refPermissionCol);
  const [restrictedActionIds, setRestrictedActionIds] = useState([]);

  useEffect(() => {
    const actionIds = [];
    permissions.forEach((permission) => {
      permission.actions.forEach((action) => {
        if (permission.restricted) {
          actionIds.push(action.id);
        }
      });
    });
    if (permissions.length > 0) {
      setRestrictedActionIds(actionIds);
    }
  }, [permissions, restrictedActionIds]);

  const sharedOnCell = (record) => {
    if (!record.isModule) {
      return {
        colSpan: 1,
      };
    }
    return {
      colSpan: 0,
    };
  };
  const moduleCell = (record) => {
    if (record.isModule) {
      return {
        colSpan: roles.length * defCheckCols.length + 1,
      };
    }
    return {};
  };

  const colsActions = () => {
    return roles.map((role, index) => {
      const { name: title, description } = role;
      return {
        title: (
          <div ref={index === 0 ? refColRoleName : null}>
            <TitleHelp title={title} helpText={description} />
          </div>
        ),
        children: defCheckCols.map((defCrud) => {
          const { initial, glosa, width } = defCrud;
          return {
            title: (
              <CheckboxCol
                text={glosa}
                roleId={role.id}
                action={initial}
                callback={callbackCol}
                canUpdate={canUpdate}
              />
            ),
            dataIndex: `a${initial}r${role.id}`,
            key: glosa,
            width,
            render: (_text, record) => {
              const recordActionIds = record.actions.map((action) => action.id);
              return (
                !record.isModule && (
                  <Checkbox
                    name={`a${initial}r${role.id}`}
                    checked={_text}
                    onChange={(event) => {
                      callbackCheck(event.target.checked, initial, role.id, record);
                    }}
                    disabled={
                      !canUpdate || restrictedActionIds.some((id) => recordActionIds.includes(id))
                    }
                  />
                )
              );
            },
            onCell: sharedOnCell,
          };
        }),
      };
    });
  };

  const columns = [
    {
      title: <div ref={refPermissionCol}>Permisos</div>,
      dataIndex: 'permissionName',
      key: 'permissionName',
      fixed: 'left',
      width: 130,
      onCell: moduleCell,
      className: 'roles-module-row',
    },
    ...colsActions(),
    {
      title: 'Permisos',
      dataIndex: 'permissionName',
      key: 'permissionName',
      fixed: 'right',
      width: 130,
      className: 'roles-module-row',
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      bordered
      dataSource={data}
      pagination={false}
      size="small"
      scroll={{
        x: 250,
      }}
    />
  );
}

TableRoles.defaultProps = {
  loading: true,
  data: [],
  roles: [],
  permissions: [],
  callbackCol: () => {},
  callbackCheck: () => {},
  canUpdate: false,
};

TableRoles.propTypes = {
  loading: PropTypes.bool,
  // cannot map data coz cols are dynamic
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          httpMethod: PropTypes.string,
          initial: PropTypes.string,
        })
      ),
      name: PropTypes.string,
    })
  ),
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      module: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          initial: PropTypes.string,
        })
      ),
    })
  ),
  callbackCol: PropTypes.func,
  callbackCheck: PropTypes.func,
  canUpdate: PropTypes.bool,
};

export default TableRoles;
