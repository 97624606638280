import { textWithEllipsis } from 'services/repeated-functions';

const reportRoutesColumns = [
  {
    width: 100,
    title: 'ID de Ruta',
    dataIndex: 'routingSetId',
    key: 'routingSetId',
    transferKey: 'rs.id',
  },
  {
    width: 80,
    title: 'Orden',
    dataIndex: 'routeOrder',
    key: 'routeRouteOrder',
    transferKey: 'ro.routeOrder',
  },
  {
    width: 120,
    title: 'Organización',
    dataIndex: 'organizationName',
    key: 'organizationName',
    transferKey: 'or.name',
    render: textWithEllipsis,
  },
  {
    width: 80,
    title: 'Zona horaria',
    dataIndex: 'organizationTimezone',
    key: 'organizationTimezone',
    transferKey: 'or.timezone',
  },
  {
    width: 100,
    title: 'Última edición',
    dataIndex: 'updatedAt',
    key: 'routeUpdatedAt',
    transferKey: 'ro.updatedAt',
  },
  {
    width: 120,
    title: 'Editado por',
    dataIndex: 'routeUpdatedBy',
    key: 'routeUpdatedBy',
    transferKey: 'up.name',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Email',
    dataIndex: 'updaterEmail',
    key: 'updaterEmail',
    transferKey: 'up.email',
  },
  {
    width: 120,
    title: 'Base',
    dataIndex: 'depotName',
    key: 'depotName',
    transferKey: 'de.name',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Set de rutas',
    dataIndex: 'routingSetName',
    key: 'routingSetName',
    transferKey: 'rs.name',
    render: textWithEllipsis,
  },
  {
    width: 80,
    title: 'Acción',
    dataIndex: 'action',
    key: 'routeAction',
    transferKey: 'ro.action',
  },
  {
    width: 100,
    title: 'Inicio de ruta',
    dataIndex: 'startsAt',
    key: 'routeStartsAt',
    transferKey: 'ro.startsAt',
  },
  {
    width: 100,
    title: 'Fin de ruta',
    dataIndex: 'endsAt',
    key: 'routeEndsAt',
    transferKey: 'ro.endsAt',
  },
  {
    width: 100,
    title: 'Estado',
    dataIndex: 'status',
    key: 'routeStatus',
    transferKey: 'ro.status',
  },
  {
    width: 100,
    title: 'Tiempo total',
    dataIndex: 'travelTime',
    key: 'routeTravelTime',
    transferKey: 'ro.travelTime',
  },
  {
    width: 100,
    title: 'Distancia total',
    dataIndex: 'totalDistance',
    key: 'routeTotalDistance',
    transferKey: 'ro.totalDistance',
  },
  {
    width: 100,
    title: 'Total de paradas',
    dataIndex: 'totalStops',
    key: 'routeTotalStops',
    transferKey: 'ro.totalStops',
  },
  {
    width: 100,
    title: 'Total de cargas',
    dataIndex: 'loads',
    key: 'routeLoads',
    transferKey: 'ro.loads',
  },
  {
    width: 100,
    title: 'Unidad de carga',
    dataIndex: 'loadsUnit',
    key: 'routeLoadsUnit',
    transferKey: 'or.loadsUnit', // use uniq value, for can use transfer
  },
  {
    width: 120,
    title: 'Conductor',
    dataIndex: 'driverName',
    key: 'driverName',
    transferKey: 'dr.name',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Asistente',
    dataIndex: 'assistantName',
    key: 'assistantName',
    transferKey: 'as.name',
    render: textWithEllipsis,
  },
  {
    width: 100,
    title: 'Vehículo',
    dataIndex: 'vehiclePlate',
    key: 'vehiclePlate',
    transferKey: 've.plate',
  },
  {
    width: 100,
    title: 'Capacidad',
    dataIndex: 'vehicleLoad',
    key: 'vehicleLoad',
    transferKey: 've.loads',
  },
  {
    width: 100,
    title: 'Unidad de capacidad',
    dataIndex: 'vehicleLoadUnit',
    key: 'vehicleLoadUnit',
    transferKey: 'or.type', // use uniq value, for can use transfer
  },
];

export default reportRoutesColumns;
