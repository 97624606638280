import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import { useGetSettingsQuery } from 'features/settings/settingsApiSlice';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import LocaleContext from '../../../locale/LocaleContext';
import DynamicFieldsInput from './Input';

const { Text } = Typography;

function DynamicFields(props) {
  const { type, listGroups } = props;
  const { data: dynamicFields, isLoading } = useGetSettingsQuery(type, {
    skip: type === undefined,
  });
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.location' };

  if (isLoading) return <Skeleton />;
  return (
    dynamicFields &&
    dynamicFields.length > 0 && (
      <>
        <Divider orientation="left" orientationMargin="0">
          <Text strong>{i18n.t('restrictions', scopeI18n)}</Text>
        </Divider>
        <Row gutter={16}>
          {dynamicFields[0]?.keyValue
            ?.filter(({ enabled }) => enabled)
            .map((field, idx) => {
              const key = idx;
              return (
                <Col key={key} span={12}>
                  <DynamicFieldsInput customAttribute={field} listGroups={listGroups} />
                </Col>
              );
            })}
        </Row>
      </>
    )
  );
}

DynamicFields.defaultProps = {
  type: null,
  listGroups: [],
};

DynamicFields.propTypes = {
  type: PropTypes.string,
  listGroups: PropTypes.arrayOf(PropTypes.string),
};

export default DynamicFields;
