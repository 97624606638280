import PropTypes from 'prop-types';
import { DemandPropType } from './Demand.propTypes';

export const DemandSetPropType = PropTypes.shape({
  id: PropTypes.number,
  demands: PropTypes.arrayOf(DemandPropType),
  depot: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
});

export default DemandSetPropType;
