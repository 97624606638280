import { Button, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';

function RoutingSubMenu(props) {
  const { setContent, setDefaultValue } = props;
  const ability = useContext(CaslContext);
  const canReadRoutingParameters = checkPermissionForSubject(ability, 'read', 'routingParameters');
  const showExperimentalDevelop = process.env.REACT_APP_SHOW_EXPERIMENTAL_DEVELOP;

  const handleOnClick = ({ key }) => {
    setContent(key);
    setDefaultValue('routes');
  };

  const items = [];
  if (canReadRoutingParameters) {
    items.push(
      {
        key: 'routingGeneral',
        label: 'Ruteo general',
      },
      {
        key: 'routingParameters',
        label: 'Parámetros de ruteo',
      },
      {
        key: 'routingSettings',
        label: 'Restricciones',
      }
    );
    // ToDo: remove condition when enable calibrator
    if (`${showExperimentalDevelop}` === 'true') {
      items.push({
        key: 'calibrator',
        label: 'Calibrador de coordenadas',
      });
    }
  }

  return (
    <Dropdown menu={{ items, onClick: handleOnClick }} placement="bottom">
      <Button
        type="text"
        size="small"
        onClick={(e) => e.preventDefault()}
        className="dropdown-routing"
      >
        Ruteo
      </Button>
    </Dropdown>
  );
}
RoutingSubMenu.defaultProps = {
  setContent: () => {},
  setDefaultValue: () => {},
};

RoutingSubMenu.propTypes = {
  setContent: PropTypes.func,
  setDefaultValue: PropTypes.func,
};

export default RoutingSubMenu;
