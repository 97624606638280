import { Button, Drawer, Form } from 'antd';
import { RolesPropTypes } from 'components/Users/userRoles.propTypes';
import FooterDrawer from 'components/common/FooterDrawer';
import TourContext from 'components/layout/TourComponent/TourContext';
import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';
import FormRole from './FormRole';

function DrawerRole(props) {
  const { permissions, afterSaveFunc, roles, baseRoles, modules } = props;
  const [visible, setVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();

  const showDrawer = (event) => {
    event.stopPropagation();
    setVisible(true);
  };
  const onClose = (event) => {
    event?.stopPropagation();
    form.resetFields();
    setVisible(false);
  };
  const ref = useRef(null);
  const { addReference } = useContext(TourContext);
  addReference('buttonCreateRoleRef', ref);

  const footer = (
    <FooterDrawer onClose={onClose} typeDrawer="create" form={form} buttonLoading={buttonLoading} />
  );
  return (
    <>
      <Button
        size="large"
        type="default"
        onClick={(event) => {
          showDrawer(event);
        }}
        ref={ref}
      >
        Crear rol
      </Button>
      <Drawer
        title="Crear Rol"
        footer={footer}
        width="55%"
        onClose={(event) => onClose(event)}
        open={visible}
        destroyOnClose
        maskClosable={false}
        closable={false}
      >
        <FormRole
          permissions={permissions}
          closeDrawer={onClose}
          afterSaveFunc={afterSaveFunc}
          form={form}
          setButtonLoading={setButtonLoading}
          roles={roles}
          baseRoles={baseRoles}
          modules={modules}
        />
      </Drawer>
    </>
  );
}

DrawerRole.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      module: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          httpMethod: PropTypes.string,
          initial: PropTypes.string,
        })
      ),
    })
  ),
  afterSaveFunc: () => {},
  roles: RolesPropTypes,
  baseRoles: RolesPropTypes,
  modules: PropTypes.arrayOf(PropTypes.string),
};
DrawerRole.defaultProps = {
  permissions: [],
  afterSaveFunc: PropTypes.func,
  roles: [],
  baseRoles: [],
  modules: [],
};

export default DrawerRole;
