import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/query';
import { App, Button, Col, Empty, Row, Space, theme } from 'antd';
import AlertMap from 'components/common/AlertMap';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import EditableTable from 'components/common/EditableTable';
import LeafletMap from 'components/common/Map/LeafletMap';
import ModalConfirm from 'components/common/ModalConfirm';
import TableFilters from 'components/common/TableFilter';
import LocaleContext from 'components/locale/LocaleContext';
import NewRouteModal from 'components/NewRoute';
import {
  useDeleteOrRestoreDemandSetMutation,
  useGetDemandsByDemandSetQuery,
  useGetDemandsSetsByFiltersMutation,
  useGetDemandsSetsQuery,
  useUpdateDemandSetMutation,
} from 'features/demandSets/demandSetsApiSlice';
import { useGetDepotLocationsQuery } from 'features/locations/locationsApiSlice';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPermissionForSubject } from 'services/repeated-functions';
import Can from 'utils/Can';
import CaslContext from 'utils/caslContext';
import NoResponsiveComponent from '../layout/NoResponsiveContent';
import DrawerRow from './DrawerRow';

function RouteContent() {
  const [demands, setDemands] = useState([]);
  const [demandSets, setDemandSets] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [mapPoints, setMapPoints] = useState([]);
  const [selectedDemandSet, setSelectedDemandSet] = useState(skipToken);
  const [loadingTable, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(true);
  const [visible, setVisible] = useState(false);
  const [depots, setDepots] = useState([]);
  const [downloadQuery, setDownloadQuery] = useState('');
  const [actualPage, setActualPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [triggerClean, setTriggerClean] = useState(false);
  const navigate = useNavigate();
  const { message } = App.useApp();

  // custom hooks
  const { data: demandSetsData, refetch, isLoading } = useGetDemandsSetsQuery();
  const { data: demandsData } = useGetDemandsByDemandSetQuery(selectedDemandSet.id, {
    skip: selectedDemandSet?.id === undefined || selectedDemandSet?.deletedAt,
  });
  const { data: locationsData, isLoading: loadingDepot } = useGetDepotLocationsQuery();
  const [updateDemandSet] = useUpdateDemandSetMutation();
  const [deleteOrRestoreDemandSet] = useDeleteOrRestoreDemandSetMutation();
  const [getDemandsSetsByFilters] = useGetDemandsSetsByFiltersMutation();

  const ability = useContext(CaslContext);
  const canUpdateDemandSet = checkPermissionForSubject(ability, 'update', 'planning');
  const canDeleteDemandSet = checkPermissionForSubject(ability, 'delete', 'planning');
  const canCreateRoutingSet = checkPermissionForSubject(ability, 'create', 'routes');

  useEffect(() => {
    const setDataForTable = () => {
      const { data, total } = demandSetsData;
      setDemandSets(data);
      setTotalData(total);
      refetch();
      setLoading(false);
    };
    if (!isLoading) {
      setDataForTable();
    }
  }, [demandSetsData, isLoading, refetch]);

  useEffect(() => {
    if (demandsData && selectedDemandSet.deletedAt === null) {
      setDemands(demandsData.demands);
    } else {
      setShowMap(false);
      setDemands([]);
    }
  }, [demandsData, selectedDemandSet]);

  useEffect(() => {
    const setMapPointsAsync = async () => {
      try {
        const points = demands.map((demandPoint) => {
          const lat = demandPoint.destination_latitude;
          const lng = demandPoint.destination_longitude;
          return { key: demandPoint.id, lat, lng };
        });
        // set depot location
        if (selectedDemandSet?.id !== undefined && selectedDemandSet?.depot) {
          const {
            depot: { id, latitude, longitude },
          } = selectedDemandSet;
          points.push({ key: `DEPOT${id}`, lat: latitude, lng: longitude, type: 'DEPOT' });
        }
        setMapPoints(points);
        setShowMap(true);
      } catch (error) {
        message.error(error);
      }
    };
    setMapPointsAsync();
  }, [demands, message, selectedDemandSet]);

  useEffect(() => {
    const toggleFirstRow = async () => {
      if (demandSets.length > 0) {
        setSelectedDemandSet(demandSets[0]);
      }
    };

    toggleFirstRow();
  }, [demandSets, setSelectedDemandSet]);

  useEffect(() => {
    if (locationsData) {
      const depotsOptions = locationsData.map((depot) => ({
        value: depot.id,
        label: depot.name,
        key: `d-${depot.id}`,
      }));
      setDepots(depotsOptions);
    }
  }, [locationsData, loadingDepot]);

  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  const archiveRestoreDemandSet = async () => {
    try {
      const { id: selectedDemandSetId } = selectedDemandSet;
      setSelectedDemandSet(skipToken);
      await deleteOrRestoreDemandSet(selectedDemandSetId).unwrap();
      message.success(
        selectedDemandSet.deletedAt
          ? i18n.t('commons.elementUnarchived', { element: i18n.t('commons.demandSets') })
          : i18n.t('commons.elementArchived', { element: i18n.t('commons.demandSets') })
      );
    } catch (error) {
      message.error(
        selectedDemandSet.deletedAt
          ? i18n.t('commons.cannotUnarchiveElement', { element: i18n.t('commons.demandSets') })
          : i18n.t('commons.cannotArchiveElement', { element: i18n.t('commons.demandSets') })
      );
    }
  };

  const toggleMap = (rowData) => {
    setShowMap(!showMap);
    setSelectedDemandSet(rowData);
  };
  // Popup Archive & Restore
  const onConfirmAction = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await archiveRestoreDemandSet();
    setTriggerClean(false);
    setIsModalVisible(false);
  };
  const onCancelAction = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
  };
  const showModal = (event, record) => {
    event.stopPropagation();
    setSelectedDemandSet(record);
    setIsModalVisible(true);
  };
  const showDemandsEdit = (DemandID) => {
    setLoading(true);
    navigate(`/planning/demand-sets/${DemandID}/edit`);
  };
  const showDrawer = (event, record) => {
    event.stopPropagation();
    setSelectedDemandSet(record);
    setVisible(true);
  };

  const sendDemandSetFields = async (demandSetIdEdited, fields) => {
    try {
      setLoading(true);
      setTriggerClean(true);
      const result = await updateDemandSet({ demandSetIdEdited, fields }).unwrap();
      setTriggerClean(false);
      setLoading(false);
      return result;
    } catch (error) {
      setLoading(false);
      message.error(i18n.t('form.error'));
      return {};
    }
  };
  const havePermissionForActions = canUpdateDemandSet || canDeleteDemandSet || canCreateRoutingSet;

  const actionsCol = {
    key: 'action',
    width: 300,
    render: (_text, record) => {
      const hasntDepotOrDeleted = record.deletedAt || !record.depot;
      const titleParameterize = hasntDepotOrDeleted ? i18n.t('buttons.parameterize.disabled') : '';
      return (
        record && (
          <Space size="middle">
            <Can I="create" a="routes">
              <Button
                type="primary"
                disabled={hasntDepotOrDeleted || record?.totalDemands === 0}
                title={titleParameterize}
                onClick={(event) => {
                  showDrawer(event, record);
                }}
              >
                {i18n.t('buttons.parameterize.label')}
              </Button>
            </Can>
            <Can I="update" a="planning">
              <Button
                type="default"
                disabled={record.deletedAt}
                onClick={(event) => {
                  showDemandsEdit(record.id);
                  event.stopPropagation();
                }}
              >
                {i18n.t('buttons.edit')}
              </Button>
            </Can>
            <Can I="delete" a="planning">
              <Button
                type="primary dangerous"
                onClick={(event) => showModal(event, record)}
                danger
                className={record.deletedAt ? 'inverse' : ''}
              >
                {record.deletedAt ? i18n.t('buttons.unarchive') : i18n.t('buttons.archive')}
              </Button>
            </Can>
          </Space>
        )
      );
    },
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 40,
    },
    {
      title: i18n.t('commons.depot'),
      dataIndex: 'depot',
      key: 'depot',
      editProps: {
        editable: canUpdateDemandSet,
        typeEdit: 'select',
        optionSelect: depots,
        fieldInObject: 'id',
      },
      width: 200,
      render: (_text, record) => (record.depot ? record.depot.name : null),
    },
    {
      title: i18n.t('commons.name'),
      dataIndex: 'name',
      key: 'name',
      editProps: {
        editable: canUpdateDemandSet,
        typeEdit: 'text',
      },
    },
    {
      title: i18n.t('commons.totalOf', { element: i18n.t('commons.demands') }),
      dataIndex: 'totalDemands',
      key: 'demands',
      width: 115,
    },
  ];
  if (havePermissionForActions) {
    columns.push(actionsCol);
  }

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/planning',
      label: i18n.t('commons.routes'),
    },
    {
      key: 'demands',
      url: '/planning',
      label: i18n.t('commons.demandSets'),
    },
  ];

  const fieldsFilter = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
      minCharsToSearch: 1,
      span: 4,
    },
    {
      name: 'name',
      label: i18n.t('commons.name'),
      minCharsToSearch: 3,
      span: 10,
    },
    {
      name: 'depotId',
      label: i18n.t('commons.depot'),
      type: 'select',
      options: depots,
      span: 10,
    },
    {
      name: 'totalDemand',
      label: i18n.t('commons.totalDemands'),
      type: 'number',
      minCharsToSearch: 1,
      span: 9,
    },
    {
      name: 'deletedAt',
      label: i18n.t('commons.status'),
      type: 'select',
      options: [
        { value: 'true', label: 'Archivado' },
        { value: 'false', label: 'Desarchivado' },
      ],
      span: 8,
    },
  ];

  const titleModal = selectedDemandSet?.deletedAt
    ? i18n.t('commons.sureUnarchive', { element: i18n.t('commons.demandSets') })
    : i18n.t('commons.sureArchive', { element: i18n.t('commons.demandSets') });

  const colorButtonProps = { danger: selectedDemandSet?.deletedAt === undefined };
  const okText = selectedDemandSet?.deletedAt
    ? i18n.t('commons.unarchive')
    : i18n.t('commons.archive');
  const modalStatus = selectedDemandSet?.deletedAt ? 'warning' : 'error';

  const hideButtonEvent = () => setAlertVisible(false);

  const onChangePage = async (page, pageSize) => {
    // include filter parameters
    const filterQuery = downloadQuery !== '' ? `&${downloadQuery.slice(1)}` : '';
    const queryString = `?page=${page}&take=${pageSize}${filterQuery}`;
    try {
      setLoading(true);
      setActualPage(page);
      const { data, total } = await getDemandsSetsByFilters(queryString).unwrap();
      setTotalData(total);
      setDemandSets(data);
      setLoading(false);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const renderTable = () => (
    <>
      <ModalConfirm
        onOk={onConfirmAction}
        onCancel={onCancelAction}
        title={titleModal}
        isModalVisible={isModalVisible}
        okButtonProps={colorButtonProps}
        okText={okText}
        status={modalStatus}
      />
      {visible && selectedDemandSet && (
        <DrawerRow
          demandId={selectedDemandSet?.id}
          demandName={selectedDemandSet?.name}
          organizationId={selectedDemandSet?.organizationId}
          visible={visible}
          setVisible={setVisible}
        />
      )}
      <div
        style={{
          height: '75vh', // ToDo: for mobile unset
        }}
      >
        <Row gutter={16}>
          <Col span={15}>
            <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
            <TableFilters
              setDataCallback={setDemandSets}
              fieldsFilter={fieldsFilter}
              showTitle={false}
              reduxFunc={getDemandsSetsByFilters}
              setDownloadQuery={setDownloadQuery}
              paginationData={{ setTotalData, setActualPage }}
              triggerClean={triggerClean}
            />
            <div
              style={{
                height: '50vh',
                overflow: 'auto',
              }}
              className="vehicles-container"
            >
              <EditableTable
                dataSource={demandSets}
                columns={columns}
                toggleMap={toggleMap}
                loading={loadingTable}
                handleSave={sendDemandSetFields}
                extraParams={selectedDemandSet?.id}
                paginationData={{ totalData, onChangePage, actualPage }}
              />
            </div>
          </Col>
          <Col span={9}>
            <AlertMap
              alertVisible={alertVisible}
              message={i18n.t('alert.pinNumberInMap')}
              hideButtonEvent={hideButtonEvent}
            />
            <div style={{ height: alertVisible ? '70vh' : '78vh' }}>
              <LeafletMap dataSource={[mapPoints]} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
  const { token } = theme.useToken();

  const renderMessage = () => (
    <Empty
      style={{ color: token.colorPrimary, marginTop: '30vh' }}
      image={<FontAwesomeIcon icon={['fas', 'location-dot']} />}
      imageStyle={{
        height: 60,
      }}
      description={
        <span style={{ color: token.colorPrimary }}>{i18n.t('commons.emptyRoutes')}</span>
      }
    >
      <NewRouteModal buttonType="emptyContent" />
    </Empty>
  );

  const renderContent = () => (
    <>
      <Col md={24} xs={0}>
        {demandSetsData?.total > 0 ? renderTable() : renderMessage()}
      </Col>
      <Col xs={24} md={0}>
        <NoResponsiveComponent />
      </Col>
    </>
  );

  return renderContent();
}

export default RouteContent;
