import { Col, Radio, Row, Skeleton } from 'antd';
import EmptyContent from 'components/layout/EmptyContent';
import TourContext from 'components/layout/TourComponent/TourContext';
import { lazy, Suspense, useContext, useRef, useState } from 'react';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import NoResponsiveComponent from '../layout/NoResponsiveContent';
import IntegrationsSubMenu from './Configurations/Integrations/SubMenu';
import RoutingSubMenu from './RoutingSubMenu';
import './style.css';

function Organizations() {
  const [defaultValue, setDefaultValue] = useState('general');
  const [content, setContent] = useState('general');
  const ability = useContext(CaslContext);
  const canReadRouting = checkPermissionForSubject(ability, 'read', 'routingParameters');
  const canReadOrganization = checkPermissionForSubject(ability, 'read', 'organizations');
  const canReadCommunication = checkPermissionForSubject(ability, 'read', 'communication');
  const canReadModule = checkPermissionForSubject(ability, 'read', 'module');

  const GeneralComponent = lazy(() => import('./Configurations/General'));
  const LoadsComponent = lazy(() => import('./Configurations/Loads'));
  const MobileComponent = lazy(() => import('./Configurations/Mobile'));
  const EmailsComponent = lazy(() => import('./Configurations/Emails'));
  const RoutingParametersComponent = lazy(() => import('./Configurations/Routing/Parameters'));
  const RoutingGeneralComponent = lazy(() => import('./Configurations/Routing/General'));
  const RoutingSettingsComponent = lazy(() => import('./Configurations/Routing/Settings'));
  const RoutingCalibratorComponent = lazy(() => import('./Configurations/Routing/Calibrator'));
  const IntegrationsComponent = lazy(() => import('./Configurations/Integrations'));
  const SmsComponent = lazy(() => import('./Configurations/Integrations/Sms'));
  const ModuleComponent = lazy(() => import('./Configurations/Module'));

  const onChange = ({ target: { value } }) => {
    setContent(value);
    setDefaultValue(value);
  };

  const radioOptions = [];

  if (canReadOrganization) {
    radioOptions.push(
      {
        label: 'General',
        value: 'general',
      },
      {
        label: 'Medidas',
        value: 'loads',
      },
      {
        label: 'Mobile',
        value: 'mobile',
      }
    );
  }

  if (canReadCommunication) {
    radioOptions.push({
      label: 'Correos',
      value: 'emails',
    });
  }

  if (canReadRouting) {
    radioOptions.push({
      label: <RoutingSubMenu setContent={setContent} setDefaultValue={setDefaultValue} />,
      value: 'routes',
    });
  }

  if (canReadCommunication) {
    radioOptions.push({
      label: <IntegrationsSubMenu setContent={setContent} setDefaultValue={setDefaultValue} />,
      value: 'integrations',
    });
  }

  if (canReadModule) {
    radioOptions.push({
      label: 'Módulo',
      value: 'module',
    });
  }

  const ref = useRef(null);
  const refButtonSave = useRef(null);
  const { addReference } = useContext(TourContext);
  addReference('organizationSubmenu', ref);
  addReference('buttonSaveOrganization', refButtonSave);

  return (
    <>
      <Col xs={0} md={24}>
        <Row style={{ marginBottom: '32px' }}>
          <Col span={24}>
            <Radio.Group
              options={radioOptions}
              onChange={onChange}
              optionType="button"
              buttonStyle="solid"
              defaultValue={defaultValue}
              value={defaultValue}
              ref={ref}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Suspense fallback={<Skeleton active paragraph={{ rows: 15 }} />}>
              {{
                general: <GeneralComponent />,
                loads: <LoadsComponent />,
                mobile: <MobileComponent />,
                emails: <EmailsComponent />,
                routingParameters: <RoutingParametersComponent />,
                routingGeneral: <RoutingGeneralComponent />,
                routingSettings: <RoutingSettingsComponent />,
                calibrator: <RoutingCalibratorComponent />,
                routes: <RoutingGeneralComponent />,
                integrations: <IntegrationsComponent />,
                sms: <SmsComponent />,
                module: <ModuleComponent />,
              }[content] || <EmptyContent />}
            </Suspense>
          </Col>
          {/* used to be override in general form */}
          <Col span={24} ref={refButtonSave} />
        </Row>
      </Col>
      <Col xs={24} md={0}>
        <NoResponsiveComponent />
      </Col>
    </>
  );
}

export default Organizations;
