import { App, Button, Col, Form, Row, Table } from 'antd';
import ModalConfirm from 'components/common/ModalConfirm';
import TableButtons from 'components/common/TableButtons';
import TableFilters from 'components/common/TableFilter';
import { useUpdateOrganizationMutation } from 'features/organizations/organizationsApiSlice';
import {
  useClearValuesMutation,
  useDeleteOrRestoreParameterMutation,
  useGetRoutingParametersByFiltersMutation,
} from 'features/routingParameters/routingParametersApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useGetAllUsersQuery } from 'features/users/usersApiSlice';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertDateToDayjs } from 'services/repeated-functions';
import RoutingParametersPropTypes from './routingParameters.propTypes';

function RoutingParametersTable(props) {
  const {
    setDrawerData,
    loading,
    data,
    setDataCallback,
    profiles,
    organizationId,
    submitButton,
    current,
    labels,
    triggerClean,
    setTriggerClean,
  } = props;
  const [selectedParameter, setSelectedParameter] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isClearValuesModalVisible, setIsClearValuesModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);

  // custom hooks
  const [deleteOrRestoreParameter] = useDeleteOrRestoreParameterMutation();
  const [getRoutingParametersByFilters] = useGetRoutingParametersByFiltersMutation();
  const [clearValues, { isLoading: isClearing }] = useClearValuesMutation();
  const { data: usersData, isLoading: usersFetching } = useGetAllUsersQuery();
  const [updateOrganization] = useUpdateOrganizationMutation();

  const clearRowValues = async () => {
    setIsClearValuesModalVisible(false);
    setTriggerClean(true);
    await clearValues({ parameterIds: selectedRowKeys })
      .unwrap()
      .then(() => {
        message.success('Valores limpiados correctamente');
        setSelectedRowKeys([]);
      })
      .catch((updateError) => {
        console.log(updateError);
        message.error('Hubo un error al limpiar los valores, intente nuevamente');
      });
    setTriggerClean(false);
  };

  const archiveRestoreParameter = async () => {
    await deleteOrRestoreParameter(selectedParameter.id)
      .unwrap()
      .then(() => {
        message.success(`Parámetro ${selectedParameter.deletedAt ? 'Desarchivado' : 'Archivado'}`);
      })
      .catch((updateError) => {
        console.log(updateError);
        message.error(
          `No se pudo ${selectedParameter.deletedAt ? 'desarchivar' : 'archivar'} el parámetro`
        );
      });
  };

  const onConfirmAction = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await archiveRestoreParameter();
    setTriggerClean(false);
    setIsModalVisible(false);
  };
  const onCancelAction = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
    setSelectedParameter();
  };
  const onClearValuesCancelAction = (event) => {
    event.stopPropagation();
    setIsClearValuesModalVisible(false);
  };
  const showModal = (event, record) => {
    event.stopPropagation();
    setSelectedParameter(record);
    setIsModalVisible(true);
  };
  const showClearValuesModal = (event) => {
    event.stopPropagation();
    setIsClearValuesModalVisible(true);
  };

  const onClickActionButton = (type, record) => {
    const drawerData = {
      drawerType: type,
      drawerVisibility: true,
      drawerParameter: record,
      drawerEntityType: record?.service === 'kraken' ? 'routingParameter' : 'routingService',
    };
    setDrawerData(drawerData);
  };

  const goToNextStep = async () => {
    await updateOrganization({
      organizationId,
      body: { creationStep: current + 1 },
    })
      .then(() => {
        const urlRedirect = `/organizations/creation-steps/${organizationId}/${current + 1}`;
        navigate(urlRedirect);
      })
      .catch((error) => {
        console.error(error);
        message.error('Error al guardar el formulario');
      });
  };

  const buttonsTable = (record) => (
    <TableButtons
      subject="routingParameters"
      record={record}
      readClickHandle={onClickActionButton}
      editClickHandle={onClickActionButton}
      deleteClickHandle={showModal}
    />
  );

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      width: 150,
    },
    {
      title: 'Perfil',
      dataIndex: 'profile',
      key: 'profile',
      width: 150,
      render: (text) => text || '-',
      onCell: () => {
        return {
          style: {
            width: '150px',
          },
        };
      },
    },
    {
      title: 'Etiqueta',
      dataIndex: 'label',
      key: 'label',
      width: 150,
      onCell: () => {
        return {
          style: {
            width: '150px',
          },
        };
      },
    },
    {
      title: 'Servicio',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: 'Modificado el',
      dataIndex: 'updatedAt',
      render: (_text, record) =>
        convertDateToDayjs(record?.updatedAt, tzOrganization, 'DD-MM-YYYY HH:mm'),
    },
    {
      title: 'Modificado por',
      dataIndex: 'updatedBy',
      render: (_text, record) => record?.updatedBy && record?.updatedBy.fullName,
    },
    {
      key: 'action',
      width: 320,
      render: (_text, record) => buttonsTable(record),
    },
  ];

  const fieldsFilter = [
    {
      name: 'name',
      label: 'Nombre',
      type: 'text',
      minCharsToSearch: 2,
    },
    {
      name: 'updatedAt',
      label: 'Modificado el',
      type: 'range',
    },
    {
      name: 'updatedBy',
      label: 'Modificado por',
      type: 'select',
      options: !usersFetching
        ? usersData?.data?.map((user) => ({ value: user?.id, label: user?.fullName }))
        : [],
    },
    {
      name: 'deletedAt',
      label: 'Estado',
      type: 'select',
      options: [
        { value: 'true', label: 'Archivado' },
        { value: 'false', label: 'Desarchivado' },
      ],
    },
    {
      name: 'profile',
      label: 'Perfil',
      type: 'select',
      options: profiles.map((profile) => ({ value: profile, label: profile })),
    },
    {
      name: 'label',
      label: 'Etiqueta',
      type: 'select',
      options: labels.map((label) => ({ value: label, label })),
    },
    {
      name: 'service',
      label: 'Servicio',
      type: 'select',
      options: ['kraken', 'geoservices'].map((service) => ({ value: service, label: service })),
    },
  ];

  const okText = selectedParameter?.deletedAt ? 'Desarchivar' : 'Archivar';
  const titleModal = `¿Estás seguro que desea ${okText} este parámetro?`;
  const titleClearValuesModal = `¿Estás seguro que desea limpiar estos ${selectedRowKeys?.length} valores? Estos valores se limpiarán permanentemente.`;
  const colorButtonProps = { danger: selectedParameter?.deletedAt === null };
  const modalStatus = selectedParameter?.deletedAt ? 'warning' : 'error';

  // rowSelection object indicates the need for row selection
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <TableFilters
        setDataCallback={setDataCallback}
        fieldsFilter={fieldsFilter}
        showTitle={false}
        reduxFunc={getRoutingParametersByFilters}
        extraParams={organizationId ? `organizationId=${organizationId}` : ''}
        triggerClean={triggerClean}
      />
      <Row gutter={24} style={{ height: '80%' }} className="vehicles-container">
        <Col flex="auto">
          <Form form={form}>
            <Button
              onClick={showClearValuesModal}
              disabled={!hasSelected}
              loading={isClearing}
              style={{ marginBottom: '1rem' }}
            >
              Limpiar valor(es)
            </Button>
            <Table
              rowSelection={rowSelection}
              rowKey="id"
              columns={columns}
              dataSource={data}
              loading={loading}
              pagination={{
                showTotal: (total) => `Total: ${total}`,
                total: data?.length,
                defaultPageSize: 6,
              }}
            />
            {submitButton && (
              <Row justify="end" style={{ marginTop: '30px' }}>
                <Col>
                  <Button
                    type="primary"
                    disabled={!data || data.length === 0}
                    loading={loading}
                    onClick={goToNextStep}
                  >
                    Siguiente
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
      <ModalConfirm
        onOk={onConfirmAction}
        onCancel={onCancelAction}
        title={titleModal}
        isModalVisible={isModalVisible}
        okButtonProps={colorButtonProps}
        okText={okText}
        status={modalStatus}
      />
      <ModalConfirm
        onOk={clearRowValues}
        onCancel={onClearValuesCancelAction}
        title={titleClearValuesModal}
        isModalVisible={isClearValuesModalVisible}
        okButtonProps={colorButtonProps}
        okText="Limpiar"
        status="error"
      />
    </>
  );
}

RoutingParametersTable.propTypes = {
  setDrawerData: PropTypes.func,
  setDataCallback: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(RoutingParametersPropTypes),
  profiles: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  organizationId: PropTypes.number,
  submitButton: PropTypes.bool,
  current: PropTypes.number,
  triggerClean: PropTypes.bool,
  setTriggerClean: PropTypes.func,
};

RoutingParametersTable.defaultProps = {
  setDrawerData: () => {},
  setDataCallback: () => {},
  loading: true,
  data: [],
  profiles: [],
  labels: [],
  organizationId: null,
  submitButton: false,
  current: null,
  triggerClean: false,
  setTriggerClean: () => {},
};

export default RoutingParametersTable;
