import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Divider, Modal, Row, Space, Timeline, theme } from 'antd';
import { VehiclePositionPropTypes } from 'components/Vehicles/vehicles.propTypes';
import LeafletMap from 'components/common/Map/LeafletMap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { convertDateToDayjs } from 'services/repeated-functions';
import useIdleTimeout from 'services/useIdleTimeout';
import socket from 'socket';
import { RoutePropTypes, eventType, itemType } from 'types';

const timeFormat = 'DD [de] MMMM [de] YYYY HH:mm';

function MapContent(props) {
  const { route, item, finishedEvent, isConnected, vehiclesPositionEvents } = props;
  const {
    organization: { id: organizationId, timezone: tzOrganization, contactEmail, contactPhone },
  } = item;
  const { token } = theme.useToken();
  const [mapFullSize, setMapFullSize] = useState(false);
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);

  const vehicle = route?.vehicle;
  const [dataSource, setDataSource] = useState([
    { key: item?.id, lat: item?.latitude, lng: item?.longitude, type: 'point' },
    {
      key: vehicle?.id,
      lat: vehicle?.latitude,
      lng: vehicle?.longitude,
      type: 'vehicle',
    },
  ]);

  // handle idle
  const handleIdle = () => {
    setIsIdleModalOpen(true);
  };
  const { isIdle, activate, setDisableTimer, remaining } = useIdleTimeout({
    onIdle: handleIdle,
    idleTime: 3600,
  });

  useEffect(() => {
    // no-op if the socket is already connected
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isIdle) {
      setIsIdleModalOpen(false);
      setDisableTimer(true);
      socket.disconnect();
    }
  }, [isIdle, setDisableTimer, isConnected]);

  const handleIdleOk = () => {
    setIsIdleModalOpen(false);
    activate();
  };
  const handleIdleCancel = () => {
    setIsIdleModalOpen(false);
    setDisableTimer(true);
  };

  useEffect(() => {
    if (isConnected) {
      const positionEventsWithOrg = vehiclesPositionEvents.filter(
        (event) => event.organizationId === organizationId
      );
      if (positionEventsWithOrg.length === 0) {
        return;
      }
      const lastTrackpoint = positionEventsWithOrg.sort(
        (a, b) => Number(new Date(a.timestamp)) - Number(new Date(b.timestamp))
      );
      const newMapPoints = [...dataSource].map((object) => {
        const foundedVehicle = lastTrackpoint.findLast(
          (vD) => vD.vehicleId.toString() === object.key.toString()
        );

        if (
          object.key.toString() === foundedVehicle?.vehicleId?.toString() &&
          object?.type === 'vehicle'
        ) {
          return {
            ...object,
            lat: `${foundedVehicle.latitude}`,
            lng: `${foundedVehicle.longitude}`,
          };
        }

        return object;
      });

      if (JSON.stringify(dataSource) !== JSON.stringify(newMapPoints)) {
        setDataSource(newMapPoints);
      }
    }
  }, [isConnected, dataSource, vehiclesPositionEvents, vehicle, organizationId]);

  const modalIdleTitle = () => {
    return (
      <Space
        direction="vertical"
        size="middle"
        align="center"
        style={{ width: '100%', justifyContent: 'center', marginBottom: '15px' }}
      >
        <FontAwesomeIcon
          icon={['fas', 'fa-circle-exclamation']}
          color={token.colorWarning}
          style={{ marginLeft: '1rem', marginRight: '1rem', fontSize: '40px' }}
        />
        <strong>Aviso de inactividad</strong>
      </Space>
    );
  };

  const timeLineItems = [
    {
      children: (
        <>
          <p>
            <strong>Planificado</strong>
          </p>
          <p>Pedido creado y programado</p>
          <p>{convertDateToDayjs(route?.startsAt, tzOrganization, timeFormat)}</p>
        </>
      ),
    },
  ];

  if (route?.startedAt) {
    timeLineItems.push({
      children: (
        <>
          <p>
            <strong>En camino</strong>
          </p>
          <p>Envío saliendo al lugar de destino</p>
          <p>{convertDateToDayjs(route?.startedAt, tzOrganization, timeFormat)}</p>
        </>
      ),
    });
  }

  if (!isEmpty(finishedEvent)) {
    const title = finishedEvent?.status === 'SUCCESS' ? 'Entregado' : 'Entrega fallida';
    const subTitle =
      finishedEvent?.status === 'SUCCESS'
        ? 'Tu envío fue entregado'
        : 'Tu envío no pudo ser entregado';
    timeLineItems.push({
      color: finishedEvent?.status === 'SUCCESS' ? token.colorSuccess : token.colorError,
      children: (
        <>
          <p>
            <strong>{title}</strong>
          </p>
          <p>{subTitle}</p>
          <p>{convertDateToDayjs(finishedEvent?.clientCreatedAt, tzOrganization, timeFormat)}</p>
        </>
      ),
    });
  }

  return (
    <>
      <Modal
        open={isIdleModalOpen}
        onOk={handleIdleOk}
        onCancel={handleIdleCancel}
        okText="Mantener activo"
        cancelText="Cerrar"
        okButtonProps={{ style: { backgroundColor: token.colorWarning } }}
        closable={false}
        centered
      >
        {modalIdleTitle()}
        <p style={{ textAlign: 'center' }}>
          {`No estás utilizando el mapa. El mapa se desactivará en ${remaining} minutos. ¿Necesitas más tiempo?`}
        </p>
      </Modal>
      {!isEmpty(finishedEvent) && (
        <Row gutter={16} style={{ marginBottom: '24px' }}>
          <Col span={24}>
            <Alert
              message={
                finishedEvent?.status === 'SUCCESS'
                  ? `Tu envío N° ${item?.document} ha sido entregado a ${
                      item?.contactName
                    },  a las ${convertDateToDayjs(
                      finishedEvent?.clientCreatedAt,
                      tzOrganization,
                      'HH:mm'
                    )} hrs`
                  : `Tu envío N° ${item?.document} no pudo ser entregado por la siguiente razón: ${finishedEvent?.reason}.
                  Para más información contactar al correo ${contactEmail} o número ${contactPhone}`
              }
              type={finishedEvent?.status === 'SUCCESS' ? 'info' : 'error'}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Timeline
            pending={isEmpty(finishedEvent) ? 'Cargando...' : ''}
            reverse
            items={timeLineItems}
          />
        </Col>
        <Col
          lg={{ span: 18 }}
          xs={{ span: 24 }}
          style={{ minHeight: 400, height: mapFullSize ? '70vh' : 400 }}
        >
          <LeafletMap
            useContainerSize
            useCustomFullscreen
            typeMarkers="following"
            showAlertRoutes={false}
            dataSource={dataSource}
            mapFullSize={mapFullSize}
            setMapFullSize={setMapFullSize}
          />
        </Col>
      </Row>
      <Divider plain />
    </>
  );
}

export default MapContent;

MapContent.defaultProps = {
  finishedEvent: {},
};

MapContent.propTypes = {
  route: RoutePropTypes.isRequired,
  item: itemType.isRequired,
  finishedEvent: eventType,
  isConnected: PropTypes.bool.isRequired,
  vehiclesPositionEvents: PropTypes.arrayOf(VehiclePositionPropTypes).isRequired,
};
