import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  Typography,
} from 'antd';
import imageBoth from 'assets/img/configurations-mobile/both.png';
import imageDecrease from 'assets/img/configurations-mobile/decrease.png';
import imageIncrease from 'assets/img/configurations-mobile/increase.png';
import imageNone from 'assets/img/configurations-mobile/none.png';
import imageReasons from 'assets/img/configurations-mobile/reasons.png';
import TitleHelp from 'components/common/TitleHelp';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Can from 'utils/Can';
import AllReasonsPropTypes, { AllQuestionsPropTypes } from '../OrganizationMobile.propTypes';
import questionTypes from '../questionTypeData.json';

const { Text } = Typography;
const { Option } = Select;

const labelEditQuantity = (
  <Popover
    placement="right"
    title="Tipos de Edición de cantidades:"
    content={
      <Row>
        <Col>
          <Text strong>Habilitar solo disminuir:</Text> En esta opción se habilitará solo el
          disminuir, asi como muestra el ejemplo:
          <Image src={imageDecrease} />
        </Col>
        <Col>
          <Text strong>Habilitar solo aumento:</Text> En esta opción se habilitará solo el aumento,
          asi como muestra el ejemplo:
          <Image src={imageIncrease} />
        </Col>
        <Col>
          <Text strong>Habilitar ambas:</Text> En esta opción se habilitará el aumento y el
          disminuir, asi como muestra el ejemplo:
          <Image src={imageBoth} />
        </Col>
        <Col>
          <Text strong> Desactivar:</Text> En esta opción se desactivará el aumento y el disminuir,
          asi como muestra el ejemplo:
          <Image src={imageNone} />
        </Col>
      </Row>
    }
  >
    <Text>
      Edición de cantidades
      <FontAwesomeIcon
        icon={['fas', 'question-circle']}
        color="#999999"
        style={{ marginLeft: '5px' }}
      />
    </Text>
  </Popover>
);

const labelReason = (
  <Popover
    placement="right"
    title="Motivo:"
    content={
      <Row>
        <Col>
          Si añadiste opciones y configuraste los motivos, la estructura se vería reflejada en la
          app como muestra la imagen:
        </Col>
        <Col>
          <Image src={imageReasons} />
        </Col>
      </Row>
    }
  >
    <Text>
      Estructura
      <FontAwesomeIcon
        icon={['fas', 'question-circle']}
        color="#999999"
        style={{ marginLeft: '5px' }}
      />
    </Text>
  </Popover>
);

function MobileFormStatus(props) {
  const {
    status,
    quantityStates,
    setDrawerVisible,
    allReasons,
    allQuestions,
    setDrawerForFormVisible,
    canUpdate,
    setAllQuestions,
  } = props;
  const [usePhoto, setUsePhoto] = useState(false);
  const [useSign, setUseSign] = useState(false);
  const [loadingReasons, setLoadingReasons] = useState(true);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [listReason, setListReason] = useState([]);
  const [listQuestion, setListQuestion] = useState([]);
  const allDisabled = quantityStates === 0;
  const form = Form.useFormInstance();
  const [useForm, setUseForm] = useState(form.getFieldValue(`enableFormOn${status}`));
  const onChangePhotoSwitch = (newStatus) => {
    // clean related fields
    if (newStatus === false) {
      form.setFieldsValue({
        [`minPhotoOn${status}`]: undefined,
        [`maxPhotoOn${status}`]: undefined,
      });
    }
    setUsePhoto(newStatus);
  };

  const onChangeSignSwitch = (newStatus) => {
    // clean related fields
    if (newStatus === false) {
      form.setFieldsValue({
        [`minSignOn${status}`]: undefined,
        [`maxSignOn${status}`]: undefined,
      });
    }
    setUseSign(newStatus);
  };

  const onChangeFormSwitch = (newStatus) => {
    setUseForm(newStatus);
  };
  const listFields = ['reasonRequired', 'enableComment', 'reasons', 'commentRequired'];
  const cleanFieldsReason = () => {
    listFields.forEach((field) => {
      form.setFieldsValue({ [`${field}On${status}`]: undefined });
    });
    setListReason([]);
  };

  // update list of reasons for status
  if (loadingReasons) {
    const newListReason = form.getFieldValue(`reasonsOn${status}`) || [];
    setListReason(newListReason);
    setLoadingReasons(false);
    setUsePhoto(form.getFieldValue(`photoOn${status}`));
    setUseSign(form.getFieldValue(`enableSignOn${status}`));
  }
  useEffect(() => {
    const newListReason = allReasons[`reasonsOn${status}`] || [];
    setListReason(newListReason);
    setLoadingReasons(false);
  }, [allReasons, status]);

  // update list of questions
  if (loadingQuestions) {
    const updatedListQuestion = form.getFieldValue([`questionsOn${status}`]) || [];
    setListQuestion(updatedListQuestion);
    setLoadingQuestions(false);
  }
  useEffect(() => {
    const updatedListQuestion = allQuestions[`questionsOn${status}`] || [];
    setListQuestion(updatedListQuestion);
    form.setFieldsValue({ [`questionsOn${status}`]: allQuestions[`questionsOn${status}`] });
    setLoadingQuestions(false);
  }, [allQuestions, status, form]);

  const removeQuestion = (questionIndex) => {
    const copy = [...allQuestions[`questionsOn${status}`]];
    copy.splice(questionIndex, 1);
    setAllQuestions({
      ...allQuestions,
      [`questionsOn${status}`]: copy,
    });
  };

  return (
    <>
      {allDisabled && <Skeleton active loading={allDisabled} paragraph={{ rows: 10 }} />}
      {!allDisabled && (
        <>
          <Row>
            <Col style={{ marginLeft: '1rem', marginRight: '1rem' }}>
              <Divider orientation="left" orientationMargin="0">
                <Text strong>Motivo</Text>
              </Divider>
              <Row>
                {listReason.length > 0 && (
                  <Col style={{ marginLeft: '1rem' }}>
                    <Card
                      size="small"
                      title={labelReason}
                      extra={
                        <Can I="update" a="organizations">
                          <Button
                            type="primary"
                            ghost
                            size="small"
                            disabled={allDisabled}
                            onClick={() => {
                              setDrawerVisible(true, status);
                            }}
                            title="Modificar motivos"
                          >
                            <FontAwesomeIcon icon={['fas', 'pen-to-square']} />
                          </Button>
                          <Button
                            type="primary"
                            danger
                            size="small"
                            disabled={allDisabled}
                            style={{ marginLeft: '.5rem' }}
                            onClick={cleanFieldsReason}
                            title="Eliminar motivos"
                          >
                            <FontAwesomeIcon icon={['fas', 'trash']} />
                          </Button>
                        </Can>
                      }
                      style={{
                        width: 400,
                      }}
                    >
                      Motivos:
                      <ul>
                        {listReason.map((element, index) => (
                          <li key={`li${index + 1}`}>{element}</li>
                        ))}
                      </ul>
                      <Form.Item name={`reasonsOn${status}`} hidden>
                        <Input name={`reasonsOn${status}`} />
                      </Form.Item>
                      <Divider />
                      <Form.Item
                        name={`reasonRequiredOn${status}`}
                        label="Motivo obligatorio"
                        valuePropName="checked"
                      >
                        <Switch disabled />
                      </Form.Item>
                      <Divider />
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name={`enableCommentOn${status}`}
                            label="Activar comentario"
                            valuePropName="checked"
                          >
                            <Switch disabled />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={`commentRequiredOn${status}`}
                            label="Comentario obligatorio"
                            valuePropName="checked"
                          >
                            <Switch disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )}

                <Col
                  style={{ textAlign: 'center', paddingLeft: listReason.length > 0 ? '2rem' : '0' }}
                >
                  <Can I="update" a="organizations">
                    <Button
                      type="primary"
                      disabled={allDisabled || listReason.length > 0}
                      onClick={() => {
                        setDrawerVisible(true, status);
                      }}
                    >
                      + Crear motivo
                    </Button>
                  </Can>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '1rem' }}>
              <Divider orientation="left" orientationMargin="0">
                <Text strong>Requisitos</Text>
              </Divider>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name={`photoOn${status}`}
                    label="Fotos"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Switch disabled={allDisabled || !canUpdate} onChange={onChangePhotoSwitch} />
                  </Form.Item>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        name={`minPhotoOn${status}`}
                        dependencies={[`maxPhotoOn${status}`]}
                        rules={[
                          { required: usePhoto, message: 'Por favor ingrese el mínimo' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                usePhoto === false ||
                                getFieldValue(`maxPhotoOn${status}`) >= value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Debe ser menor o igual al máximo'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          placeholder="Mínimo"
                          disabled={!usePhoto || !canUpdate}
                          min="0"
                          max="10"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ textAlign: 'center' }}>
                      -
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name={`maxPhotoOn${status}`}
                        dependencies={[`minPhotoOn${status}`]}
                        rules={[
                          { required: usePhoto, message: 'Por favor ingrese el máximo' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                usePhoto === false ||
                                getFieldValue(`minPhotoOn${status}`) <= value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Debe ser mayor o igual al mínimo'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          placeholder="Máximo"
                          disabled={!usePhoto || !canUpdate}
                          min="0"
                          max="10"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">Este campo tiene que contener número</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={`enableSignOn${status}`}
                    label="Firmas"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Switch disabled={allDisabled || !canUpdate} onChange={onChangeSignSwitch} />
                  </Form.Item>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        name={`minSignOn${status}`}
                        dependencies={[`maxSignOn${status}`]}
                        rules={[
                          { required: useSign, message: 'Por favor ingrese el mínimo' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                useSign === false ||
                                getFieldValue(`maxSignOn${status}`) >= value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Debe ser menor o igual al máximo'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          placeholder="Mínimo"
                          disabled={!useSign || !canUpdate}
                          min="0"
                          max="10"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ textAlign: 'center' }}>
                      -
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name={`maxSignOn${status}`}
                        dependencies={[`minSignOn${status}`]}
                        rules={[
                          { required: useSign, message: 'Por favor ingrese el máximo' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                useSign === false ||
                                getFieldValue(`minSignOn${status}`) <= value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Debe ser mayor o igual al mínimo'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          placeholder="Máximo"
                          disabled={!useSign || !canUpdate}
                          min="0"
                          max="10"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">Este campo tiene que contener número</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {status !== 'postponed' && (
            <>
              {/* Formulario */}
              <Row>
                <Col style={{ marginLeft: '1rem' }}>
                  <Divider orientation="left" orientationMargin="0">
                    <Text strong>Formulario</Text>
                  </Divider>
                  <Row>
                    <Col>
                      <Form.Item
                        name={`enableFormOn${status}`}
                        label={
                          <TitleHelp
                            title="Formulario"
                            helpText="Habilita un formulario que el conductor deberá completar tras realizar un reporte"
                          />
                        }
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Switch
                          disabled={allDisabled || !canUpdate}
                          onChange={onChangeFormSwitch}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.List name={`questionsOn${status}`}>
                        {(fields, { remove }, { errors }) => (
                          <Space
                            direction="vertical"
                            style={{
                              display: 'flex',
                            }}
                          >
                            <Row justify="end">
                              <Col span={24}>
                                <Form.ErrorList errors={errors} />
                              </Col>
                            </Row>
                            {fields.map(({ key, name, ...restField }, questionIndex) => {
                              const { isListField } = restField;
                              return (
                                <Row justify="space-between" align="top" key={`dynamic-${key}`}>
                                  <Col span={24} style={{ marginLeft: '1rem' }}>
                                    <Card
                                      size="small"
                                      title={`Pregunta ${questionIndex + 1}`}
                                      extra={
                                        <Can I="update" a="organizations">
                                          <Button
                                            type="primary"
                                            ghost
                                            size="small"
                                            disabled={!useForm}
                                            onClick={() => {
                                              setDrawerForFormVisible(true, status, questionIndex);
                                            }}
                                            title="Modificar pregunta"
                                          >
                                            <FontAwesomeIcon icon={['fas', 'pen-to-square']} />
                                          </Button>
                                          <Button
                                            type="primary"
                                            danger
                                            size="small"
                                            disabled={!useForm}
                                            style={{ marginLeft: '.5rem' }}
                                            onClick={() => {
                                              remove(name);
                                              removeQuestion(questionIndex);
                                            }}
                                            title="Eliminar pregunta"
                                          >
                                            <FontAwesomeIcon icon={['fas', 'trash']} />
                                          </Button>
                                        </Can>
                                      }
                                      style={{
                                        width: 400,
                                      }}
                                    >
                                      <Form.Item
                                        name={[name, 'nid']}
                                        isListField={isListField}
                                        hidden
                                      >
                                        <Input name={[name, 'nid']} disabled />
                                      </Form.Item>
                                      <Form.Item
                                        name={[name, 'label']}
                                        isListField={isListField}
                                        hidden
                                      >
                                        <Input name={[name, 'label']} disabled />
                                      </Form.Item>
                                      <Form.Item
                                        name={[name, 'type']}
                                        isListField={isListField}
                                        hidden
                                      >
                                        <Input name={[name, 'type']} disabled />
                                      </Form.Item>
                                      <Form.Item
                                        name={[name, 'required']}
                                        valuePropName="checked"
                                        hidden
                                      >
                                        <Checkbox disabled />
                                      </Form.Item>
                                      {listQuestion[questionIndex].required && (
                                        <Text strong type="danger">
                                          {'* '}
                                        </Text>
                                      )}
                                      <Text strong>{listQuestion[questionIndex].label}</Text>
                                      <br />
                                      <Text>{`Tipo de respuesta: ${
                                        questionTypes.find(
                                          (t) => t.value === listQuestion[questionIndex].type
                                        ).label
                                      }`}</Text>
                                      {questionTypes.find(
                                        (t) => t.value === listQuestion[questionIndex].type
                                      ).category === 'Select' && (
                                        <Form.Item
                                          name={[name, 'formOptions']}
                                          isListField={isListField}
                                          hidden
                                        >
                                          <Input name={[name, 'formOptions']} disabled />
                                        </Form.Item>
                                      )}
                                      {questionTypes.find(
                                        (t) => t.value === listQuestion[questionIndex].type
                                      ).category === 'Slider' && (
                                        <>
                                          <br />
                                          <Text>{`- Minimo: ${listQuestion[questionIndex].formOptions[0]?.option}`}</Text>
                                          <br />
                                          <Text>{`- Máximo: ${listQuestion[questionIndex].formOptions[1]?.option}`}</Text>
                                        </>
                                      )}
                                      {questionTypes.find(
                                        (t) => t.value === listQuestion[questionIndex].type
                                      ).category === 'Select' && (
                                        <Form.Item
                                          isListField={isListField}
                                          name={[name, 'defaultOption']}
                                          label={
                                            <TitleHelp
                                              title="Valor por defecto"
                                              helpText="Opción o valor que el conductor verá seleccionado o definido por defecto en la pregunta."
                                            />
                                          }
                                          style={{ margin: '15px 0 0 0' }}
                                        >
                                          <Select
                                            allowClear
                                            placeholder="Ninguno"
                                            disabled={!useForm || !canUpdate}
                                          >
                                            {listQuestion[questionIndex].formOptions?.map((o) => {
                                              return (
                                                <Option key={o.option} value={o.option}>
                                                  {o.option}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </Form.Item>
                                      )}
                                    </Card>
                                  </Col>
                                </Row>
                              );
                            })}
                          </Space>
                        )}
                      </Form.List>
                    </Col>
                    <Col style={{ textAlign: 'center', paddingLeft: '2rem' }}>
                      <Can I="update" a="organizations">
                        <Button
                          type="primary"
                          disabled={!useForm}
                          onClick={() => {
                            setDrawerForFormVisible(true, status);
                          }}
                        >
                          + Crear pregunta
                        </Button>
                      </Can>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Cantidades */}
              <Row>
                <Col style={{ marginLeft: '1rem' }}>
                  <Divider orientation="left" orientationMargin="0">
                    <Text strong>Cantidades</Text>
                  </Divider>
                  <Col>
                    <Form.Item
                      name={`enableEditQuantityOn${status}`}
                      label={labelEditQuantity}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Radio.Group disabled={allDisabled || !canUpdate}>
                        <Row>
                          <Col span={24}>
                            <Radio value="increase">Habilitar aumento</Radio>
                          </Col>
                          <Col span={24}>
                            <Radio value="decrease">Habilitar disminuir</Radio>
                          </Col>
                          <Col span={24}>
                            <Radio value="both">Habilitar ambas</Radio>
                          </Col>
                          <Col span={24}>
                            <Radio value="disabled">Desactivar</Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
}

MobileFormStatus.defaultProps = {
  allReasons: {},
  allQuestions: {},
  quantityStates: true,
  setDrawerVisible: () => {},
  status: 'success',
  setDrawerForFormVisible: () => {},
  canUpdate: false,
  setAllQuestions: () => {},
};

MobileFormStatus.propTypes = {
  allReasons: AllReasonsPropTypes,
  allQuestions: AllQuestionsPropTypes,
  quantityStates: PropTypes.number,
  setDrawerVisible: PropTypes.func,
  status: PropTypes.string,
  setDrawerForFormVisible: PropTypes.func,
  canUpdate: PropTypes.bool,
  setAllQuestions: PropTypes.func,
};

export default MobileFormStatus;
