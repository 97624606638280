import { App, Checkbox, Col, Divider, Form, Input, Row, Select, Table } from 'antd';
import { RolesPropTypes } from 'components/Users/userRoles.propTypes';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import { useCreateRoleMutation } from 'features/roles/rolesApiSlice';
import { selectCurrentUser } from 'features/users/userSlice';
import { filter, flatten } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DefRoleColumns } from 'services/repeated-functions';
import { formType } from 'types';
import CheckboxCol from '../CheckboxCol';

function FormRole(props) {
  const { permissions, closeDrawer, form, setButtonLoading, roles, baseRoles, modules } = props;
  const [actionIds, setActionIds] = useState([]);
  const [actionFromRoleIds, setActionFromRoleIds] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [actionByInitial, setActionByInitial] = useState({ C: [], R: [], U: [], D: [] });
  const [weight, setWeight] = useState(0);
  const [restrictedActionIds, setRestrictedActionIds] = useState([]);
  const { currentOrganizationId } = useSelector(selectCurrentUser);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  // custom hooks
  const [createRole] = useCreateRoleMutation();

  const isFieldDuplicated = (value, type) => {
    const siblings = roles.map((res) => res[type]);
    return siblings.some((item) => item?.toLowerCase() === value?.toLowerCase());
  };

  const sendRole = async (paramsRole) => {
    const body = paramsRole;
    try {
      await createRole(body);
      message.success('Rol creado correctamente');
      closeDrawer();
    } catch (error) {
      message.error('Formulario con errores, revisar');
      console.error(error);
    }
  };

  const onFinish = (values) => {
    setButtonLoading(true);
    // build data to send
    const paramsRole = {
      nid: values.nid,
      name: values.name,
      description: values.description,
      organizationId: currentOrganizationId,
      actionIds,
      weight,
    };
    sendRole(paramsRole);
    setButtonLoading(false);
    form.resetFields();
  };
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };
  const callbackCol = (isChecked, initialAction) => {
    const newIds = isChecked
      ? [...actionIds, ...actionByInitial[initialAction]]
      : actionIds.filter(
          (acId) =>
            !actionByInitial[initialAction].includes(acId) || actionFromRoleIds.includes(acId)
        );
    setActionIds(newIds);
  };
  const callbackCheck = (isChecked, actionId) => {
    const newIds = isChecked
      ? [...actionIds, actionId]
      : actionIds.filter((acId) => acId !== actionId);
    setActionIds(newIds);
  };

  useEffect(() => {
    const restrictedIds = [];
    const setActions = () => {
      const assigned = { C: [], R: [], U: [], D: [] };
      permissions.forEach((permission) => {
        permission.actions.forEach((action) => {
          assigned[action.initial].push(action.id);
          if (permission.restricted) {
            restrictedIds.push(action.id);
          }
        });
      });
      return assigned;
    };
    const fillDataSource = () => {
      const permissionsArray = flatten(
        modules.map((moduleName) => {
          return filter(permissions, { module: moduleName });
        })
      );
      return permissionsArray.map((permission) => {
        const returnObj = {};
        permission.actions.forEach((action) => {
          returnObj[`a${action.initial}`] = action.id;
        });
        const permissionName =
          // <TitleInfo title={permission.name} helpText={permission.description} justify />
          permission.name;
        return {
          key: permission.id,
          permissionName,
          ...returnObj,
        };
      });
    };

    if (permissions.length > 0) {
      const assignedActions = setActions();
      setActionByInitial(assignedActions);
      const data = fillDataSource();
      setDataSource(data);
      setRestrictedActionIds(restrictedIds);
    }
  }, [permissions, modules, actionIds]);

  const defCheckCols = DefRoleColumns();

  const colFromArray = defCheckCols.map((defCrud) => {
    const { initial, glosa } = defCrud;
    return {
      title: <CheckboxCol text={glosa} action={initial} callback={callbackCol} canUpdate />,
      dataIndex: `a${initial}`,
      key: glosa,
      render: (_text) => {
        return (
          <Checkbox
            name={`a${initial}`}
            checked={actionIds.includes(_text)}
            onChange={(event) => {
              callbackCheck(event.target.checked, _text);
            }}
            disabled={actionFromRoleIds.includes(_text) || restrictedActionIds.includes(_text)}
          />
        );
      },
    };
  });

  const columns = [
    {
      title: 'Permisos',
      dataIndex: 'permissionName',
      key: 'permissionName',
      width: 115,
    },
    ...colFromArray,
  ];

  const cleanCheckBoxes = () => {
    setActionIds([]);
    setActionFromRoleIds([]);
    setWeight(0);
  };

  const handleOnChange = (value) => {
    cleanCheckBoxes();
    if (value) {
      const role = baseRoles.find((rol) => rol.id === value);
      const actions = role.actions.map((action) => action.id);
      setActionIds(actions);
      setActionFromRoleIds(actions);
      setWeight(role.weight);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={{ required: 'Este campo es obligatorio' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row className="main-content">
        <Col offset={2} span={9}>
          <Form.Item
            label="Nid"
            name="nid"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (isFieldDuplicated(value, 'nid')) {
                    return Promise.reject(new Error(`Ya existe el nid ${value}`));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input name="nid" className="input-form-role" />
          </Form.Item>
        </Col>
        <Col offset={2} span={9}>
          <Form.Item
            label="Nombre"
            name="name"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (isFieldDuplicated(value, 'name')) {
                    return Promise.reject(new Error(`Ya existe el nombre ${value}`));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input name="name" className="input-form-role" />
          </Form.Item>
        </Col>
        <Col offset={2} span={9}>
          <Form.Item
            label={
              <TitleHelp
                title="Rol"
                helpText="Elige un rol base y personaliza los permisos del nuevo rol según necesites."
              />
            }
            name="role"
          >
            <Select
              onChange={handleOnChange}
              allowClear
              placeholder={i18n.t('commons.select')}
              options={baseRoles.map((role, index) => ({
                key: `role-${index}`,
                label: role.name,
                value: role.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col offset={2} span={9}>
          <Form.Item label="Descripcion" name="description">
            <Input name="description" className="input-form-role" />
          </Form.Item>
        </Col>
        <Col span={24} xl={{ span: 19, offset: 2 }}>
          <Divider orientation="left" orientationMargin="0">
            Permisos
          </Divider>
        </Col>
        <Col span={24} xl={{ span: 20, offset: 2 }}>
          <Table
            columns={columns}
            bordered
            dataSource={dataSource}
            pagination={false}
            tableLayout="fixed"
            size="small"
          />
        </Col>
      </Row>
    </Form>
  );
}

FormRole.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      module: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          initial: PropTypes.string,
        })
      ),
    })
  ),
  closeDrawer: PropTypes.func,
  form: formType,
  setButtonLoading: PropTypes.func,
  roles: RolesPropTypes,
  baseRoles: RolesPropTypes,
  modules: PropTypes.arrayOf(PropTypes.string),
};
FormRole.defaultProps = {
  permissions: [],
  closeDrawer: () => {},
  form: {},
  setButtonLoading: () => {},
  roles: [],
  baseRoles: [],
  modules: [],
};

export default FormRole;
