import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Col, Form, Row, Select, Skeleton, Space } from 'antd';
import SubmitButton from 'components/common/SubmitButton';
import { useGetDefinitionsQuery } from 'features/definitions/definitionsAPiSlice';
import { useUpdateOrganizationMutation } from 'features/organizations/organizationsApiSlice';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { organizationType } from 'types';

const maxLoads = 3;

function OrganizationsCreationStepsLoads(props) {
  const { organization, isLoading, current, setStepsStatus, setCurrent } = props;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateOrganization, { isLoading: isUpdating }] = useUpdateOrganizationMutation();
  const [addLoadDisabled, setAddLoadDisabled] = useState(
    organization?.loadsUnit?.length === maxLoads
  );
  const { message } = App.useApp();
  const [loadsFromOrg, setLoadsFromOrg] = useState([]);
  const {
    data: definitionsFromDb,
    isLoading: isLoadingLoad,
    refetch,
  } = useGetDefinitionsQuery('LOAD');

  useEffect(() => {
    if (definitionsFromDb?.length > 0) {
      const firstDefinition = definitionsFromDb.find(({ type }) => type === 'LOAD');
      const { data } = firstDefinition;
      setLoadsFromOrg(data);
    }
    refetch();
  }, [definitionsFromDb, refetch]);

  const initialValues = {
    loadsUnit: organization?.loadsUnit?.length === 0 ? [{ name: '' }] : organization?.loadsUnit,
  };
  const onFinish = async ({ loadsUnit }) => {
    const formValues = loadsUnit.map((value, index) => {
      const { unit } = loadsFromOrg.find(
        (load) => load.label.toLowerCase() === value.name.toLowerCase()
      );
      return { key: index + 1, name: value.name, unit };
    });
    await updateOrganization({
      organizationId: organization.id,
      body: { loadsUnit: formValues, creationStep: current + 1 },
    })
      .then(() => {
        message.success('Formulario enviado correctamente');
        setCurrent(current + 1);
        navigate(`/organizations/creation-steps/${organization?.id}/${current + 1}`);
      })
      .catch((error) => {
        console.error(error);
        setStepsStatus('error');
        message.error('Error al guardar el formulario');
      });
  };
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };
  const countFields = (_, values) => {
    const { loadsUnit } = values;
    setAddLoadDisabled(loadsUnit.length === maxLoads);
  };
  return isLoading || isLoadingLoad ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <Form
      form={form}
      initialValues={initialValues}
      name="organizationLoads"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={countFields}
      autoComplete="off"
    >
      <Form.List name="loadsUnit">
        {(fields, { add, remove }, { errors }) => (
          <>
            <Row justify="end">
              <Col span={8}>
                <Form.ErrorList errors={errors} />
              </Col>
            </Row>
            {fields.map(({ key, name, ...restField }, loadIndex) => {
              const { isListField } = restField;
              return (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                  }}
                >
                  <Form.Item
                    isListField={isListField}
                    label={`Carga ${loadIndex + 1}`}
                    name={[name, 'name']}
                    rules={[
                      { required: loadIndex === 0 },
                      () => ({
                        validator(_, value) {
                          if (value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Debe seleccionar una opción'));
                        },
                      }),
                    ]}
                  >
                    <Select
                      style={{
                        width: 230,
                      }}
                      allowClear
                      options={loadsFromOrg.map(({ label, unit }) => ({
                        value: label,
                        label: `${label} [${unit}]`,
                      }))}
                    />
                  </Form.Item>
                  {fields.length > 1 && (
                    <FontAwesomeIcon
                      className="dynamic-delete-button"
                      icon={['fas', 'circle-minus']}
                      onClick={() => remove(name)}
                    />
                  )}
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                style={{
                  width: 230,
                }}
                disabled={addLoadDisabled}
              >
                + Añadir carga
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Row justify="end">
        <Col>
          <Space>
            <SubmitButton isLoading={isLoading || isUpdating} />
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

OrganizationsCreationStepsLoads.defaultProps = {
  organization: {},
  isLoading: false,
};
OrganizationsCreationStepsLoads.propTypes = {
  setStepsStatus: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  organization: organizationType,
  isLoading: PropTypes.bool,
};

export default OrganizationsCreationStepsLoads;
