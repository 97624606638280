import { Skeleton } from 'antd';
import { useGetPanelQuery } from 'features/panels/panelsApiSlice';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PanelDetailContent from './DetailContent';

function PanelShow() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [panel, setPanel] = useState();
  const [loading, setLoading] = useState(true);

  const { data: panelData, isLoading } = useGetPanelQuery(id, {
    skip: id === undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPanel(panelData);
        setLoading(isLoading);
      } catch (error) {
        console.error(error);
        navigate('/reports');
      }
    };
    fetchData();
  }, [navigate, id, panelData, isLoading]);

  return (
    <Skeleton active loading={loading}>
      <PanelDetailContent panel={panel} />
    </Skeleton>
  );
}
export default PanelShow;
