import { Button, Col, Form, Input, Row, Typography } from 'antd';
import usePasswordStrengthChecker from 'components/common/Password/PasswordStrengthChecker';
import StrengthMeter from 'components/common/Password/StrengthMeter';
import LocaleContext from 'components/locale/LocaleContext';
import { useConfirmAccountMutation } from 'features/auth/authApiSlice';
import { setCredentials } from 'features/auth/authSlice';
import { setCurrentUser } from 'features/users/userSlice';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { userIsOnlyDriver } from 'services/repeated-functions';
import './style.css';

const { Title } = Typography;

function ConfirmAccount() {
  const { userName } = useParams();
  const [form] = Form.useForm();
  const [newPwd, setNewPwd] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.account.confirm' };

  // check password strength custom hook
  const [passwordErrors, checkPasswordStrength, hasPasswordErrors] = usePasswordStrengthChecker();
  const [confirmAccount, { isLoading }] = useConfirmAccountMutation();
  const handlePwdInput = (e) => setNewPwd(e.target.value);

  const onFinish = async (values) => {
    try {
      const userData = await confirmAccount(values).unwrap();
      dispatch(setCredentials({ ...userData, userName }));
      dispatch(setCurrentUser({ ...userData }));
      const userIsDriver = userIsOnlyDriver(userData.userProfile);
      navigate(userIsDriver ? '/' : '/organizations');
    } catch (err) {
      const errMessage = i18n.t('errors.userOrPassword', scopeI18n);
      form.setFields([
        {
          name: 'pwd',
          errors: [errMessage],
        },
        {
          name: 'newPwd',
          errors: [errMessage],
        },
      ]);
    }
  };

  return (
    <>
      <Title level={4} className="confirm-account-title">
        {i18n.t('confirmAccount', scopeI18n)}
      </Title>
      <Row>
        <Col span={24} xs={{ span: 22, offset: 1 }}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            name="login"
            initialValues={{
              remember: true,
              user: userName,
            }}
            autoComplete="off"
            className="confirm-account-form"
          >
            <Form.Item name="user" hidden>
              <Input name="user" />
            </Form.Item>

            <Form.Item
              className="login-item"
              label={i18n.t('temporalPassword', scopeI18n)}
              name="pwd"
              size="large"
              rules={[
                {
                  required: true,
                  message: i18n.t('rules.temporalPassword', scopeI18n),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              className="login-item"
              label={i18n.t('newPassword', scopeI18n)}
              name="newPwd"
              size="large"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: i18n.t('rules.newPassword', scopeI18n),
                },
                () => ({
                  validator() {
                    if (!hasPasswordErrors) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(i18n.t('errors.newPassword', scopeI18n)));
                  },
                }),
              ]}
            >
              <Input.Password onInput={checkPasswordStrength} onChange={handlePwdInput} />
            </Form.Item>

            <Form.Item
              className="login-item"
              label={i18n.t('confirmPassword', scopeI18n)}
              name="confirmNewPwd"
              size="large"
              dependencies={['newPwd']}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPwd') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(i18n.t('errors.confirmPassword', scopeI18n)));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="login-item"
                loading={isLoading}
              >
                {i18n.t('buttons.save')}
              </Button>
            </Form.Item>
          </Form>
          {newPwd && <StrengthMeter passwordErrors={passwordErrors} />}
        </Col>
      </Row>
    </>
  );
}

export default ConfirmAccount;
