import { Form, Transfer } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';

function TransferFields(props) {
  const { setTargetKeys, targetKeys, allcustomFields } = props;
  const [selectedKeys, setSelectedKeys] = useState([]);
  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const filterOption = (inputValue, option) =>
    option.title.toLowerCase().indexOf(inputValue) !== -1;
  return (
    <Form.Item
      name="customFields"
      wrapperCol={{
        span: 24,
      }}
    >
      <Transfer
        dataSource={allcustomFields}
        showSearch
        locale={{
          itemUnit: 'campo',
          itemsUnit: 'campos',
        }}
        filterOption={filterOption}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={(item) => item.label || item.title}
      />
    </Form.Item>
  );
}

TransferFields.defaultProps = {
  setTargetKeys: () => {},
  targetKeys: [],
  allcustomFields: [],
};

TransferFields.propTypes = {
  setTargetKeys: PropTypes.func,
  targetKeys: PropTypes.arrayOf(PropTypes.string),
  allcustomFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    })
  ),
};

export default TransferFields;
