import { Radio } from 'antd';
import PropTypes from 'prop-types';
import { FieldDefinitionPropType, FormPropType } from '../tableFilter.propTypes';

function GrouppedButtonFilter(props) {
  const { fieldDefinition, handleChanges, form } = props;
  const { name: nameField, options, defaultValue } = fieldDefinition;

  const customChange = (e) => {
    form.setFieldsValue({ [nameField]: e.target.value });
    handleChanges(e);
  };

  return (
    <Radio.Group onChange={customChange} defaultValue={defaultValue}>
      {options.map(({ label, value }) => (
        <Radio.Button key={`rb-${value}`} value={value}>
          {label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}

GrouppedButtonFilter.defaultProps = {
  fieldDefinition: {},
  handleChanges: () => {
    console.warn('undefined handleChanges');
  },
  form: {},
};

GrouppedButtonFilter.propTypes = {
  fieldDefinition: FieldDefinitionPropType,
  handleChanges: PropTypes.func,
  form: FormPropType,
};

export default GrouppedButtonFilter;
