import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  App,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Popover,
  Row,
  Space,
  Typography,
} from 'antd';
import mapDepotIcon from 'assets/img/map-depot.svg';
import LocaleContext from 'components/locale/LocaleContext';
import { useRecalcutateTimesMutation } from 'features/routes/routesApiSlice';
import { filter, find, isNaN, values } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import {
  cloneRouteObject,
  getOnlyAvailableRoutes,
  insertNewItem,
  nameGroupByRoutes,
} from 'services/repeated-functions';
import { itemType, RoutePropTypes } from 'types';
import ItemEditIndicators from './Indicators';

const { Text } = Typography;

function ItemEdit(props) {
  const {
    item,
    route,
    checkedItems,
    setCheckedItems,
    loadsFromOrg,
    setRoutes,
    setRouteReloading,
    setAllRoutes,
    allRoutes,
    setIsRoutingSetUnchanged,
    canEdit,
    itemRemoveRef,
    itemEditPositionRef,
    itemIndexPosition,
    setSelectedRoute,
    setItemSelected,
  } = props;

  const { items: routeItems } = route;
  const form = Form.useFormInstance();
  const isDepot = ['DEPOT', 'RETURN_TO_DEPOT'].includes(item?.type);
  const [touchedRoutes, setTouchedRoutes] = useState([]);
  const [deletedRoutesIds, setDeletedRoutesIds] = useState(undefined);
  const [recalculate, setRecalculate] = useState(false);
  const [recalcutateTimes] = useRecalcutateTimesMutation();
  const [isModiffing, setIsModiffing] = useState(false);
  const inputItemOrderRef = useRef(null);
  const { message } = App.useApp();
  const itemBlocked = !item?.editable || !canEdit;

  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'routes.editor.list.itemList' };

  useEffect(() => {
    // TODO: move to an reusable hook
    const getTimes = async () => {
      setIsModiffing(true);
      // get group name for every route to send
      const demandGroupRoutes = nameGroupByRoutes(touchedRoutes);
      setRouteReloading(true); // prevent make 2 reload of state newRoutesRecalc1 can be 2 routes
      const newRoutesRecalc1 = await Promise.all(
        touchedRoutes?.map(async (tRoute) => {
          const tRouteFill = { ...tRoute, demandGroupRoutes };
          const { data } = await recalcutateTimes(tRouteFill);
          return {
            ...tRoute,
            ...data,
          };
        })
      );
      // after recalculate, hide reloading
      setTimeout(() => {
        setRouteReloading(false);
      }, 800);

      if (touchedRoutes.length > 0) {
        setRoutes(newRoutesRecalc1);
        // modify all universe of routes to apply filters
        const onlyAvailableRoutes = getOnlyAvailableRoutes(
          allRoutes,
          newRoutesRecalc1,
          deletedRoutesIds
        );
        setAllRoutes(onlyAvailableRoutes);
        // reset all selected routes
        if (deletedRoutesIds) {
          const SELECT_ALL = -1;
          setSelectedRoute(SELECT_ALL);
        }
        setIsModiffing(false);
        setIsRoutingSetUnchanged(false);
      }
    };
    if (recalculate) {
      getTimes();
    }
    //! skip pass allRoutes to skip recall
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recalculate,
    recalcutateTimes,
    route.id,
    setRouteReloading,
    setRoutes,
    setAllRoutes,
    touchedRoutes,
    setIsRoutingSetUnchanged,
    deletedRoutesIds,
  ]);

  const onChange = (e) => {
    if (e.target.checked) {
      setCheckedItems((actualElements) => [...actualElements, e.target.name]);
    } else {
      setCheckedItems((current) => current.filter((currentItem) => currentItem !== e.target.name));
    }
  };

  useEffect(() => {
    if (form) {
      // TODO: review this variables
      form.setFieldValue([item?.id, 'routeId'], route?.id);
      form.setFieldValue([item?.id, 'routeOrder'], route?.routeOrder);
      form.setFieldValue([item?.id, 'itemId'], item?.id);
      form.setFieldValue([item?.id, 'itemType'], item?.type);
      form.setFieldValue([item?.id, 'itemOrder'], item?.itemOrder);
    }
  }, [form, item, route]);

  const searchItemInRoutes = (itemToSearch) => {
    const routesToUse = allRoutes.map((aR) => {
      const { items } = aR;
      const itemsRest = items.filter((i) => i.id !== itemToSearch.id);
      return { ...aR, items: itemsRest };
    });
    return routesToUse;
  };

  const onBlurRouteInput = (e) => {
    if (itemBlocked) {
      return;
    }
    const { value } = e.target;
    const routeOrder = parseInt(value, 10);
    if (isNaN(routeOrder)) {
      console.error('routeOrder número inválido');
      form.setFieldValue([item?.id, 'routeOrder'], route?.routeOrder);
      return;
    }
    form.setFieldValue([item?.id, 'itemOrder'], '');
    inputItemOrderRef.current.focus();
  };

  const onBlurItemInput = (e) => {
    if (itemBlocked) {
      return;
    }
    const { id, value } = e.target;
    const itemOrder = parseInt(value, 10);
    const itemId = parseInt(id, 10);
    const formValues = values(form.getFieldsValue());
    // Modified item
    const itemFormObject = find(formValues, ['itemId', itemId]);
    let { routeOrder } = itemFormObject;
    routeOrder = parseInt(routeOrder, 10);
    const originRouteOrder = route?.routeOrder;
    const originItemObject = find(routeItems, ['id', itemId]);
    const { itemOrder: originItemOrder } = originItemObject;
    if (isNaN(itemOrder)) {
      console.error('itemOrder: número inválido', itemOrder);
      inputItemOrderRef.current.focus();
      return;
    }
    if (itemOrder < 1) {
      console.error('itemOrder: orden invalido', itemOrder);
      form.setFieldValue([item?.id, 'itemOrder'], originItemOrder);
      return;
    }
    if (isNaN(routeOrder)) {
      console.error('routeOrder: número inválido', routeOrder);
      return;
    }
    if (routeOrder < 1) {
      console.error('routeOrder: número de ruta invalida', routeOrder);
      return;
    }
    if (originRouteOrder === routeOrder && originItemOrder === itemOrder) {
      return;
    }
    const itemObject = { ...originItemObject, itemOrder };
    const routeOrders = allRoutes.map((aR) => aR.routeOrder);
    const routesForUse = searchItemInRoutes(itemObject);
    let destinationRouteObject;
    if (routeOrders.includes(routeOrder)) {
      destinationRouteObject = find(routesForUse, ['routeOrder', routeOrder]);
    } else {
      let cloneRouteOrder = routeOrder;
      if (routeOrder > routesForUse.length) {
        cloneRouteOrder = routesForUse.length + 1;
      }
      destinationRouteObject = cloneRouteObject(routesForUse, cloneRouteOrder);
    }
    const destinationRouteItems = destinationRouteObject?.items;
    if (!destinationRouteObject.editable) {
      form.setFieldValue([item?.id, 'routeOrder'], originRouteOrder);
      form.setFieldValue([item?.id, 'itemOrder'], originItemOrder);
      message.error(i18n.t('errors.notEditable', { scope: 'routes.editor.drawer' }));
      return;
    }
    let lastReportedItemOrder;
    if (destinationRouteObject.status === 'STARTED') {
      const lastReportedItem = destinationRouteItems.findLast(
        (arrItem) => arrItem.editable === false
      );
      lastReportedItemOrder = lastReportedItem?.itemOrder;
    }
    // obtain the RTD route item
    const { itemOrder: rtdItemOrder } = destinationRouteItems.find(
      (arrItems) => arrItems.type === 'RETURN_TO_DEPOT'
    );
    let insertItemIndex = itemOrder;
    if (insertItemIndex >= rtdItemOrder) {
      // If it is an empty route I add it as the first item
      if (rtdItemOrder === 1) {
        insertItemIndex = 1;
      } else {
        // obtain the last route item
        const { itemOrder: lastItemOrder } = destinationRouteItems.findLast(
          (arrItems) => arrItems.type === 'STOP'
        );
        // add it to the end
        insertItemIndex = lastItemOrder + 1;
      }
    }
    if (insertItemIndex <= lastReportedItemOrder) {
      insertItemIndex = lastReportedItemOrder + 1;
    }
    // or move or replace
    const newDestinationItems = insertNewItem(
      destinationRouteItems,
      insertItemIndex,
      {
        ...itemObject,
        routeId: destinationRouteItems.id,
      },
      routesForUse
    );
    // ensure that RTD item is always in the last position
    const rtdItem = newDestinationItems.find(
      (newDestinationItem) => newDestinationItem.type === 'RETURN_TO_DEPOT'
    );
    const reorderedItems = newDestinationItems
      .filter(({ type }) => type !== 'RETURN_TO_DEPOT')
      .concat(rtdItem)
      .map((itemObj, idx) => {
        return { ...itemObj, itemOrder: idx };
      });
    const otherRoutesEdited = [];
    // check if the item's route is different from the destination's route
    if (originRouteOrder !== routeOrder) {
      const originRoute = routesForUse.find((r) => r.routeOrder === originRouteOrder);
      const reorderedOriginalItems = originRoute.items.map((itemObj, idx) => {
        return { ...itemObj, itemOrder: idx };
      });
      const routeChanged = {
        ...originRoute,
        items: reorderedOriginalItems,
      };
      const totalOriginalStops = filter(reorderedOriginalItems, ['type', 'STOP']).length;
      const originalRouteHasToRemoved = totalOriginalStops === 0;
      // The original route may be left without items, so it should be discarded.
      if (originalRouteHasToRemoved) {
        // If the route has no elements left, it should not be sent.
        setDeletedRoutesIds(originRouteOrder);
      } else {
        // send the route if it still has items
        otherRoutesEdited.push(routeChanged);
      }
    }
    const finalRoutes = [
      {
        ...destinationRouteObject,
        items: reorderedItems,
      },
      ...otherRoutesEdited,
    ];
    setTouchedRoutes(finalRoutes);
    setRecalculate(true);
  };

  const loadsFormatted = (loads) => {
    return (
      <Space>
        {Object.keys(loads).map((load, index) => {
          const loadData = loadsFromOrg[index];
          return loadData && `${loads[load]?.load} ${loadData?.unit} | `;
        })}
      </Space>
    );
  };

  const renderDetailItem = () => {
    const { contactName, contactPhones, document, restrictions } = item;
    return (
      <Row>
        <Col span={12}>{i18n.t('contactName', scopeI18n)}:</Col>
        <Col span={12}>{contactName || '-'}</Col>
        <Col span={12}>{i18n.t('contactPhone', scopeI18n)}:</Col>
        <Col span={12}>{contactPhones[0]?.phone ? item?.contactPhones[0].phone : '-'}</Col>
        <Col span={12}>{i18n.t('document', scopeI18n)}:</Col>
        <Col span={12}>{document || '-'}</Col>
        {restrictions.length > 0 ? (
          <Col span={24}>
            <b>{i18n.t('restrictions', scopeI18n)}</b>
          </Col>
        ) : (
          ''
        )}
        {restrictions.map((restriction) => {
          return (
            <Fragment key={restriction.id}>
              <Col span={12} key={restriction.id}>
                {restriction.label}:
              </Col>
              <Col span={12} key={restriction.name}>
                {restriction.value}
              </Col>
            </Fragment>
          );
        })}
      </Row>
    );
  };

  const showExperimentalDevelop = `${process.env.REACT_APP_SHOW_EXPERIMENTAL_DEVELOP}` === 'true';
  const nameToDisplay = `${showExperimentalDevelop ? `(${item?.id}) ` : ''}${item?.name}`;

  return (
    <List.Item
      key={item?.id}
      id={item?.id}
      style={
        isModiffing
          ? {
              backgroundColor: '#E6F4FF',
            }
          : undefined
      }
    >
      <Form.Item name={[item?.id, 'itemId']} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={[item?.id, 'itemType']} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={[item?.id, 'routeId']} hidden>
        <Input />
      </Form.Item>
      {isDepot && (
        <Form.Item name={[item?.id, 'itemOrder']} hidden>
          <Input />
        </Form.Item>
      )}
      <Row align="middle">
        <Col>
          <Space align="center" size={16}>
            <div ref={itemIndexPosition === 1 ? itemRemoveRef : null}>
              <Checkbox
                name={item?.id}
                onChange={onChange}
                style={{ width: '20px' }}
                disabled={isDepot || itemBlocked}
                checked={checkedItems.includes(item?.id)}
              />
            </div>
            <Form.Item noStyle name={[item?.id, 'routeOrder']}>
              <Input
                size="large"
                min={1}
                onBlur={onBlurRouteInput}
                style={{ width: '45px', textAlign: 'center', padding: '5px' }}
                disabled={isDepot || itemBlocked}
              />
            </Form.Item>
            {!isDepot ? (
              <div ref={itemIndexPosition === 1 ? itemEditPositionRef : null}>
                <Form.Item noStyle name={[item?.id, 'itemOrder']}>
                  <Input
                    ref={inputItemOrderRef}
                    id={item?.id}
                    size="large"
                    min={1}
                    onBlur={onBlurItemInput}
                    style={{ width: '45px', textAlign: 'center', padding: '5px' }}
                    disabled={itemBlocked}
                  />
                </Form.Item>
              </div>
            ) : (
              <div style={{ width: '45px', textAlign: 'center', padding: '5px' }}>
                <img src={mapDepotIcon} alt="depot" />
              </div>
            )}
            <Space direction="vertical" size={0}>
              {isDepot && (
                <Text type="secondary" strong>
                  {nameToDisplay}
                </Text>
              )}
              {!isDepot && (
                <Popover content={renderDetailItem} trigger="click">
                  <Button
                    title={i18n.t('showInfo', scopeI18n)}
                    className="button-no-hover"
                    type="text"
                    block
                    style={{ height: '100%', padding: 0, textAlign: 'left' }}
                  >
                    <Space direction="vertical" size={0}>
                      <Text strong type={itemBlocked ? 'secondary' : 'primary'}>
                        {nameToDisplay}
                        <FontAwesomeIcon
                          icon={['fas', 'fa-edit']}
                          onClick={() => {
                            if (!itemBlocked) {
                              setItemSelected({ ...item, routeNumber: route?.routeOrder });
                            }
                          }}
                          style={{ marginLeft: '.5rem' }}
                          color={itemBlocked ? '#999999' : '#9825F2'}
                          title={i18n.t('edit', scopeI18n)}
                          disabled={itemBlocked}
                        />
                      </Text>
                      <Text type="secondary">
                        {loadsFormatted(item?.loads)} {item?.group}
                      </Text>
                    </Space>
                  </Button>
                </Popover>
              )}
            </Space>
          </Space>
        </Col>
      </Row>
      <ItemEditIndicators item={item} isDepot={isDepot} />
    </List.Item>
  );
}

ItemEdit.propTypes = {
  route: RoutePropTypes.isRequired,
  item: itemType.isRequired,
  checkedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  setCheckedItems: PropTypes.func.isRequired,
  loadsFromOrg: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      name: PropTypes.string,
      unit: PropTypes.string,
    })
  ).isRequired,
  setRoutes: PropTypes.func.isRequired,
  setAllRoutes: PropTypes.func.isRequired,
  setSelectedRoute: PropTypes.func.isRequired,
  allRoutes: PropTypes.arrayOf(RoutePropTypes).isRequired,
  setRouteReloading: PropTypes.func.isRequired,
  setIsRoutingSetUnchanged: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  itemRemoveRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  itemEditPositionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  itemIndexPosition: PropTypes.number.isRequired,
  setItemSelected: PropTypes.func.isRequired,
};

export default ItemEdit;
