import { Button, Col, Divider, Row, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Popup } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { DataByStatusEvent } from 'services/repeated-functions';
import { pointShapePropTypes } from './LeafletMap.propType';

const { Text } = Typography;

// Returns a popup for the leaflet map where there are diferent custom sections, a 'common' one & a 'other' one
function PopupExtendedComponent(props) {
  const navigate = useNavigate();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  const { drawablesRows, mainAreaSpan } = props;
  const { usedRows = Object.keys(drawablesRows) } = drawablesRows;
  const sideAreaSpan = 24 - mainAreaSpan;
  let graphBarElement = null;

  const showRoute = (route) => {
    navigate(route);
  };

  if (drawablesRows.graphData) {
    const { TotalItems, SuccessItems, PartialItems, FailedItems } = drawablesRows.graphData;
    const percentTotal = (percent) => ((percent * 100) / TotalItems).toFixed(1);
    const pendingItems = TotalItems - SuccessItems - PartialItems - FailedItems;

    const allStatusSteps = [
      { percent: percentTotal(SuccessItems), ...DataByStatusEvent('SUCCESS', i18n) },
      { percent: percentTotal(PartialItems), ...DataByStatusEvent('PARTIAL', i18n) },
      { percent: percentTotal(FailedItems), ...DataByStatusEvent('FAILURE', i18n) },
      { percent: percentTotal(pendingItems), ...DataByStatusEvent('PENDING', i18n) },
    ];

    // Set section for bar graph & route delivery status information
    graphBarElement = (
      <div key={`bar-graph-${drawablesRows.key}`}>
        <Row>
          <Col span={24}>
            <Row className="event-percentage-row extended">
              {allStatusSteps.map(({ text: label, percent, color }) => {
                return (
                  percent > 0 && (
                    <Col
                      key={`col-${label}`}
                      title={`${label}: ${Math.round(percent)} %`}
                      flex={`${percent}%`}
                      style={{
                        backgroundColor: color,
                      }}
                    />
                  )
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Text strong>Exitoso: </Text>
          </Col>
          <Col span={14}>
            <Text>{`${SuccessItems} `}</Text>
            <Text type="secondary">{`(${allStatusSteps[0].percent}%)`}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Text strong>Parcial: </Text>
          </Col>
          <Col span={14}>
            <Text>{`${PartialItems} `}</Text>
            <Text type="secondary">{`(${allStatusSteps[1].percent}%)`}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Text strong>Fallido: </Text>
          </Col>
          <Col span={14}>
            <Text>{`${FailedItems} `}</Text>
            <Text type="secondary">{`(${allStatusSteps[2].percent}%)`}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Text strong>Pendiente: </Text>
          </Col>
          <Col span={14}>
            <Text>{`${pendingItems} `}</Text>
            <Text type="secondary">{`(${allStatusSteps[3].percent}%)`}</Text>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Popup key={`c-${drawablesRows.key}`} closeButton={false}>
      <div className="popup-extended-content-container">
        <div
          className="top-container"
          style={{ height: drawablesRows.navigateRoute ? '245px' : '290px' }}
        >
          <Row gutter={24}>
            <Col span={mainAreaSpan}>
              {/* Common area. Doesn't have a header. */}
              {Object.keys(drawablesRows.common).map((key) => (
                <Row key={`r${key}c-${drawablesRows.common.key}`}>
                  <Col span={8}>
                    <Text strong>{key}:</Text>
                  </Col>
                  <Col span={16}>
                    <Text>{drawablesRows.common[key]}</Text>
                  </Col>
                </Row>
              ))}
              {/* Custom sections. Has a header which name is extracted from the key */}
              {Object.keys(drawablesRows)
                .filter((key_) => usedRows.includes(key_))
                .map((key) => {
                  const customSections = Object.keys(drawablesRows[key]).map((customKey) => (
                    <Row key={`r${customKey}c-${drawablesRows.key}`}>
                      <Col span={8}>
                        <Text strong>{customKey}:</Text>
                      </Col>
                      <Col span={16}>
                        <Text>{drawablesRows[key][customKey]}</Text>
                      </Col>
                    </Row>
                  ));
                  return (
                    <div key={`r${key}c-${drawablesRows.key}`}>
                      <Divider orientation="left" style={{ margin: '12px 0 5px 0' }}>
                        <Text strong type="secondary">
                          {key}
                        </Text>
                      </Divider>
                      {customSections}
                    </div>
                  );
                })}
            </Col>
            <Col span={sideAreaSpan}>
              <Divider orientation="left">
                <Text strong type="secondary">
                  Otros
                </Text>
              </Divider>
              {/* Other area. Has a header with name 'Otros', a bar graph can be set if graphData is defined. */}
              {Object.keys(drawablesRows.other).map((key) => (
                <Row key={`r${key}c-${drawablesRows.other.key}`}>
                  <Col span={10}>
                    <Text strong>{key}:</Text>
                  </Col>
                  <Col span={14}>
                    <Text>{drawablesRows.other[key]}</Text>
                  </Col>
                </Row>
              ))}
              {graphBarElement}
            </Col>
          </Row>
        </div>
        {drawablesRows.navigateRoute && (
          <div className="end-container">
            <Row>
              <Col span={24}>
                <Divider style={{ margin: '6px 0' }} />
                <div className="popup-show-route-btn">
                  <Button
                    type="primary"
                    onClick={() => {
                      showRoute(drawablesRows.navigateRoute);
                    }}
                    disabled={!drawablesRows.Cod}
                  >
                    Ver
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Popup>
  );
}

PopupExtendedComponent.defaultProps = {
  drawablesRows: {}, // object. Data sent to the PopupExtendedComponent. The custom values are defined in full sections with name rather than single elements. An element named common & other IS EXPECTED.
  mainAreaSpan: 14, // amount. Span width definition for the left column. It's value also affects the span value for the right col (sideAreaSpan).
};
PopupExtendedComponent.propTypes = {
  drawablesRows: pointShapePropTypes,
  mainAreaSpan: PropTypes.number,
};

export default PopupExtendedComponent;
