import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import image1 from 'assets/img/help/advanced/structure-ev1.png';
import image3 from 'assets/img/help/advanced/structure-ev4.png';
import image4 from 'assets/img/help/advanced/structure-ev5.png';
import image2 from 'assets/img/help/simple/requirements-simple2.png';

import '../../style.css';

const { Text, Title } = Typography;

function GeoreferenceNote(props) {
  const { existVisit } = props;

  const checkExistVisit = () => {
    return existVisit ? (
      <>
        <Col md={{ span: 16 }} xs={{ span: 24 }}>
          <Title level={2}>En caso de ubicación</Title>
          <li>
            <Text>
              Si tienes ingresado el código de visita, la latitud y longitud, y la dirección
              completa, el sistema tomará como referencia el código de visita y usará la información
              almacenada en el sistema.
            </Text>
          </li>
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} align="middle">
          <img src={image1} alt={'img1'} className="help-howtoimport-image1" />
        </Col>
      </>
    ) : (
      <>
        <Col md={{ span: 16 }} xs={{ span: 24 }}>
          <Title level={2}>En caso de ubicación</Title>
          <li>
            <Text>
              Si no tienes el código de visita, pero has ingresado la latitud, longitud y la
              dirección completa, el sistema priorizará la latitud y longitud.
            </Text>
          </li>
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} align="middle">
          <img src={image3} alt={'image3'} className="help-howtoimport-image1" />
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }}>
          <li>
            <Text>
              Si no tienes el código de visita ni latitud y longitud, pero has ingresado la
              dirección completa, el sistema usará la dirección proporcionada.
            </Text>
          </li>
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} align="middle">
          <img src={image4} alt={'image4'} className="help-howtoimport-image1" />
        </Col>
      </>
    );
  };

  return (
    <Row gutter={[12, 12]} justify={'center'}>
      {checkExistVisit()}
      <Col md={{ span: 16 }} xs={{ span: 24 }}>
        <Col md={{ span: 24 }}>
          <Text strong>Nota: </Text>
          <Text>
            Si activas la opción “Georeferenciar todos los registros”, el sistema tomará en cuenta
            la dirección completa para todos los registros y georeferenciará.
          </Text>
        </Col>
        <br />
        <Col md={{ span: 24 }} align="middle">
          <img src={image2} alt={'img2'} className="help-howtoimport-image2" />
        </Col>
      </Col>
    </Row>
  );
}

GeoreferenceNote.defaultProps = {
  existVisit: false,
};

GeoreferenceNote.propTypes = {
  existVisit: PropTypes.bool,
};

export default GeoreferenceNote;
