import { Alert, Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { convertDateToDayjs } from 'services/repeated-functions';
import { SynopticItem } from '../../SynopticSettings.propTypes';

const { Text, Title } = Typography;

// Returns the content for an UNKNOWN type to be rendered on the popover.
function UnknownItem(props) {
  const { itemData, timezone } = props;

  const spanTitle = 8;
  const spanInfo = 24 - spanTitle;

  const startsAt = dayjs(itemData.arrivesAt);
  const endDate = dayjs(itemData.departsAt);

  const dayText = convertDateToDayjs(startsAt, timezone, 'DD-MM-YYYY');
  const initTimeText = convertDateToDayjs(startsAt, timezone, 'HH:mm');
  const endTimeText = convertDateToDayjs(endDate, timezone, 'HH:mm');

  return (
    <>
      <hr style={{ margin: '0 0 15px 0' }} />
      <div style={{ padding: '0 10px 15px 10px' }}>
        <Row>
          <Col>
            <Title level={5}>Desconocido</Title>
          </Col>
        </Row>
        <Row>
          <Col span={spanTitle}>
            <Text strong>Fecha:</Text>
          </Col>
          <Col span={spanInfo}>
            <Text>{dayText}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={spanTitle}>
            <Text strong>Hora:</Text>
          </Col>
          <Col span={spanInfo}>
            <Text>
              {startsAt.isSame(endDate) ? initTimeText : `${initTimeText} - ${endTimeText}`}
            </Text>
          </Col>
        </Row>
        <hr style={{ margin: '15px 0 15px 0' }} />
        <Row>
          <Col>
            <Alert
              description="Contacte a su administrador para resolver esta situación."
              type="warning"
              showIcon
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
UnknownItem.defaultProps = {
  itemData: {}, // object. Selected item data to be shown.
  timezone: 'America/Santiago',
};
UnknownItem.propTypes = {
  itemData: SynopticItem,
  timezone: PropTypes.string,
};

export default UnknownItem;
