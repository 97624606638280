import { useRef } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer } from 'react-leaflet';
import LeafletMarkers from './LeafletMarkers';
import { pointShapePropTypes } from './LeafletMap.propType';

function StaticMap(props) {
  const { dataSource } = props;
  const mapRef = useRef(null);
  const here = {
    apiKey: process.env.REACT_APP_HERE_API_KEY,
  };
  const style = 'normal.day';
  const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?apiKey=${here.apiKey}&ppi=320`;
  const styleMapContainer = { height: '100%', minHeight: '100%' };

  return (
    <MapContainer
      ref={mapRef}
      id="map-container"
      zoomControl={false}
      doubleClickZoom={false}
      closePopupOnClick={false}
      dragging={false}
      zoomSnap={false}
      zoomDelta={false}
      trackResize={false}
      touchZoom={false}
      scrollWheelZoom={false}
      style={styleMapContainer}
    >
      <TileLayer attribution="&copy; HERE" url={hereTileUrl} />
      <LeafletMarkers points={dataSource} showRoutes={false} typeMarkers="routeDetail" />
    </MapContainer>
  );
}

StaticMap.defaultProps = {
  dataSource: [],
};

StaticMap.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(pointShapePropTypes), pointShapePropTypes])
  ),
};

export default StaticMap;
