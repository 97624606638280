import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOut, setCredentials } from 'features/auth/authSlice';
import { clearErrors, setErrors } from 'features/errors/errorsSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CORE_BACKEND,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const handledErrors = [401, 400];

const baseQueryWithReauth = async (args, api, extraOptions) => {
  api.dispatch(clearErrors());
  // original request
  let result = await baseQuery(args, api, extraOptions);
  if (!handledErrors.includes(result?.error?.status)) {
    api.dispatch(setErrors(result?.error?.status));
  }

  if (result?.error?.status === 401) {
    const { user, userSub, refreshToken } = api.getState().auth;
    const refereshTokenArgs = {
      url: '/auth/refresh-token',
      method: 'POST',
      body: {
        user_sub: userSub,
        refresh_token: refreshToken,
      },
    };
    // send refresh token to get new access token
    const refreshResult = await baseQuery(refereshTokenArgs, api, extraOptions);
    if (refreshResult && refreshResult?.data?.access_token) {
      const refreshData = {
        user,
        user_sub: userSub,
        refresh_token: refreshToken,
        access_token: refreshResult?.data?.access_token,
      };
      // store the new token
      api.dispatch(setCredentials({ ...refreshData }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const ApiSliceTags = [
  'RoutingSets',
  'DemandsSets',
  'Definitions',
  'Roles',
  'Permissions',
  'Users',
  'RoutingEngine',
  'Demands',
  'DemandDetails',
  'Georreference',
  'Vehicles',
  'CaVehicles',
  'FileProcessing',
  'Locations',
  'Organizations',
  'ActionsUser',
  'CaLocationsStop',
  'CaLocationsDepot',
  'Settings',
  'RoutingParameters',
  'Panels',
  'RoutingEngineProfiles',
  'RoutingEngineParameters',
  'ProfilesRoutingParameters',
  'LabelsRoutingParameters',
  'Reports',
  'Items',
  'CustomAttributes',
  'CategoriesVisit',
  'CategoriesDepot',
  'GroupsForDemand',
  'GroupsForLocation',
  'GroupsForItem',
];

const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 5,
  tagTypes: ApiSliceTags,
  endpoints: () => ({}),
});

export default apiSlice;
