import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetSettingsQuery } from 'features/settings/settingsApiSlice';
import PropTypes from 'prop-types';
import { useContext } from 'react';

const { Text } = Typography;

function ShowDynamicFields(props) {
  const { type, assignedValues, offset, span, md } = props;
  const { data: dynamicFields, isLoading } = useGetSettingsQuery(type, {
    skip: type === undefined,
  });
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'locations' };

  if (isLoading) return <Skeleton />;
  return (
    dynamicFields &&
    dynamicFields.length > 0 && (
      <>
        <Divider orientation="left" orientationMargin="0">
          <Text strong>{i18n.t('restrictions', scopeI18n)}</Text>
        </Divider>
        {dynamicFields[0]?.keyValue
          ?.filter(({ enabled }) => enabled)
          .map((field, idx) => {
            const key = `row-${idx}`;
            const thisValue = assignedValues.find((assigned) => assigned.name === field.name);
            const { value, id, type: typeField } = thisValue || {};
            let printValue = value;
            if (typeField === 'boolean') {
              printValue = value === 'true' || value ? 'Sí' : 'No';
            }
            return (
              <Row gutter={16} key={key}>
                <Col span={span} md={md} offset={offset}>
                  <Text key={`Text${id}`} strong>
                    {field.label}:
                  </Text>
                </Col>
                <Col key={`Val${id}`} span={24 - span - offset} md={24 - md - offset}>
                  {printValue || '-'}
                </Col>
              </Row>
            );
          })}
      </>
    )
  );
}

ShowDynamicFields.defaultProps = {
  type: null,
  assignedValues: [],
  offset: 0,
  span: 8,
  md: 7,
};

ShowDynamicFields.propTypes = {
  type: PropTypes.string,
  assignedValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      id: PropTypes.number,
    })
  ),
  offset: PropTypes.number,
  span: PropTypes.number,
  md: PropTypes.number,
};

export default ShowDynamicFields;
