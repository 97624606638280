import { App, Button, Col, Form, Input, Row, Typography } from 'antd';
import usePasswordStrengthChecker from 'components/common/Password/PasswordStrengthChecker';
import StrengthMeter from 'components/common/Password/StrengthMeter';
import LocaleContext from 'components/locale/LocaleContext';
import { useRecoverPasswordMutation } from 'features/auth/authApiSlice';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const { Title } = Typography;

function RecoverPassword() {
  const { userName } = useParams();
  const [form] = Form.useForm();
  const [newPwd, setNewPwd] = useState('');
  const navigate = useNavigate();
  const [recoverPassword, { isLoading }] = useRecoverPasswordMutation();
  const [passwordErrors, checkPasswordStrength, hasPasswordErrors] = usePasswordStrengthChecker();
  const handlePwdInput = (e) => setNewPwd(e.target.value);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.account.recover' };

  const onFinish = async (values) => {
    try {
      await recoverPassword(values).unwrap();
      message.success(
        <>
          <div>{i18n.t('alerts.passwordChanged', scopeI18n)}</div>
          <div>{i18n.t('alerts.enabledToLogin', scopeI18n)}</div>
        </>,
        4
      );
      navigate('/');
    } catch (err) {
      const errMessage = i18n.t('errors.wrongData', scopeI18n);
      form.setFields([
        {
          name: 'code',
          errors: [errMessage],
        },
      ]);
    }
  };

  return (
    <>
      <Title level={4} className="recover-password-title">
        {i18n.t('recoverPassword', scopeI18n)}
      </Title>
      <Row>
        <Col span={24} xs={{ span: 22, offset: 1 }}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            name="login"
            initialValues={{
              user: userName,
            }}
            autoComplete="off"
            className="confirm-account-form"
          >
            <Form.Item name="user" hidden>
              <Input name="user" />
            </Form.Item>

            <Form.Item
              className="login-item"
              label={i18n.t('code', scopeI18n)}
              name="code"
              size="large"
              rules={[
                {
                  required: true,
                  message: i18n.t('rules.code', scopeI18n),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="login-item"
              label={i18n.t('password', scopeI18n)}
              name="pwd"
              size="large"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: i18n.t('rules.password', scopeI18n),
                },
                () => ({
                  validator() {
                    if (!hasPasswordErrors) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(i18n.t('errors.minStrength', scopeI18n)));
                  },
                }),
              ]}
            >
              <Input.Password onInput={checkPasswordStrength} onChange={handlePwdInput} />
            </Form.Item>

            <Form.Item
              className="login-item"
              label={i18n.t('confirmPassword', scopeI18n)}
              name="confirmPwd"
              size="large"
              dependencies={['pwd']}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('pwd') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(i18n.t('errors.notEquals', scopeI18n)));
                  },
                }),
              ]}
              style={{ marginBottom: '50px' }}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="login-item"
                loading={isLoading}
              >
                {i18n.t('buttons.send')}
              </Button>
            </Form.Item>
          </Form>
          {newPwd && <StrengthMeter passwordErrors={passwordErrors} />}
        </Col>
      </Row>
    </>
  );
}

export default RecoverPassword;
