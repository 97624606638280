import {
  UserPropTypes,
  VehiclePropTypes,
} from 'components/RoutesDemand/Assigment/assigment.propTypes';
import PropTypes from 'prop-types';

export const loadsFromOrgType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
  })
);

export const itemType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemOrder: PropTypes.number,
  type: PropTypes.string,
  loads: PropTypes.arrayOf(
    PropTypes.shape({
      load: PropTypes.number,
    })
  ),
  arrivesAt: PropTypes.string,
  departsAt: PropTypes.string,
  name: PropTypes.string,
  group: PropTypes.string,
  loading: PropTypes.bool,
  editable: PropTypes.bool,
  restrictions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      breached: PropTypes.bool,
      description: PropTypes.string,
    })
  ),
});

export const eventType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  reason: PropTypes.string,
  comment: PropTypes.string,
  clientCreatedAt: PropTypes.string,
  timeCaptured: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  eventAttachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      signedURl: PropTypes.string,
    })
  ),
});

export const formType = PropTypes.shape({
  validateFields: PropTypes.func,
  resetFields: PropTypes.func,
  setFieldsValue: PropTypes.func,
});

export const LocationsPropTypes = PropTypes.shape({
  id: PropTypes.number,
  nid: PropTypes.string,
  name: PropTypes.string,
  updated_at: PropTypes.string,
  contactId: PropTypes.string,
  contactName: PropTypes.string,
  contactPhones: PropTypes.arrayOf(PropTypes.shape({ phone: PropTypes.string })),
  contactEmails: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })),
});

export const RoutePropTypes = PropTypes.shape({
  id: PropTypes.number,
  status: PropTypes.string,
  action: PropTypes.string,
  assignment: PropTypes.string,
  endsAt: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      lat: PropTypes.string,
      lng: PropTypes.string,
      type: PropTypes.string,
      editable: PropTypes.bool,
    })
  ),
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loads: PropTypes.arrayOf(
    PropTypes.shape({
      load: PropTypes.number,
    })
  ),
  routeOrder: PropTypes.number,
  startsAt: PropTypes.string,
  totalDistance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalStops: PropTypes.number,
  travelTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  driver: UserPropTypes,
  assistant: UserPropTypes,
  vehicle: VehiclePropTypes,
  editable: PropTypes.bool,
});

export const organizationType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  fantasyName: PropTypes.string,
});

export const routingSetType = PropTypes.shape({
  id: PropTypes.number,
  totalRoutes: PropTypes.number,
  travelTime: PropTypes.number,
  totalDistance: PropTypes.number,
  totalOccupation: PropTypes.number,
  totalRestrictions: PropTypes.number,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      loads: PropTypes.arrayOf(
        PropTypes.shape({
          load: PropTypes.number,
        })
      ),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          loads: PropTypes.arrayOf(
            PropTypes.shape({
              load: PropTypes.number,
            })
          ),
        })
      ),
      vehicle: PropTypes.shape({
        id: PropTypes.number,
      }),
    })
  ),
});
