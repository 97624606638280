import { Layout } from 'antd';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorBoundary from './layout/ErrorBoundary';
import NavBar from './layout/NavBar';
import Sidebar from './layout/Sidebar';
import SidebarMobile from './layout/SidebarMobile';
import TourComponent from './layout/TourComponent';

const { Content } = Layout;

function MainLayout(props) {
  const { isOffline } = props;
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Layout>
      <div className="app">
        <Sidebar />
        <SidebarMobile collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className="right-panel">
          <ErrorBoundary offline={isOffline}>
            <NavBar setCollapsed={setCollapsed} collapsed={collapsed} />
            <Layout>
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <TourComponent />
                <div className="site-layout-content">
                  <Outlet />
                </div>
              </Content>
            </Layout>
          </ErrorBoundary>
        </div>
      </div>
    </Layout>
  );
}
MainLayout.propTypes = {
  isOffline: PropTypes.bool,
};

MainLayout.defaultProps = {
  isOffline: true,
};

export default MainLayout;
