import { App } from 'antd';
import ModalConfirm from 'components/common/ModalConfirm';
import { useDeleteOrRestorePanelMutation } from 'features/panels/panelsApiSlice';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function PanelDeleteComponent(props) {
  const { isModalVisible, setIsModalVisible, id, isDeleted, setTriggerClean } = props;
  const navigate = useNavigate();
  const [deleteOrRestorePanel] = useDeleteOrRestorePanelMutation();
  const { message } = App.useApp();

  // archive modal
  const archivePanel = async () => {
    try {
      await deleteOrRestorePanel(id).unwrap();
      message.success('Panel archivado');
      navigate('/reports');
    } catch (error) {
      message.error('No se pudo archivar el Panel');
    }
  };

  const onConfirmArchive = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await archivePanel();
    setTriggerClean(false);
    setIsModalVisible(false);
  };
  const onCancelArchive = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
  };

  const state = isDeleted ? 'Desarchivar' : 'Archivar';

  return (
    <ModalConfirm
      okText={state}
      onOk={onConfirmArchive}
      onCancel={onCancelArchive}
      title={`¿Estás seguro que desea ${state} este panel?`}
      isModalVisible={isModalVisible}
    />
  );
}

PanelDeleteComponent.defaultProps = {
  id: undefined,
  isModalVisible: false,
  isDeleted: false,
  setIsModalVisible: () => {},
  setTriggerClean: () => {},
};

PanelDeleteComponent.propTypes = {
  id: PropTypes.number,
  isModalVisible: PropTypes.bool,
  isDeleted: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  setTriggerClean: PropTypes.func,
};

export default PanelDeleteComponent;
