import { Button, Card, Progress, Space, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { selectCurrentUser } from 'features/users/userSlice';
import { useSelector } from 'react-redux';
import { userIsOptimalForOrg } from 'services/repeated-functions';
import './style.css';

const { Meta } = Card;
const { Text } = Typography;
const TOTAL_STEPS = 10;

function OrganizationsDashboardCard(props) {
  const {
    organization: { id, name, fantasyName, logoUrl, creationStep, creationStatus, deletedAt },
    canUpdate,
    canDelete,
    canRead,
    handleChangeCurrOrg,
    showModal,
    organization: selectedOrg,
  } = props;
  const isCompleted = creationStatus === 'COMPLETED';
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const userHasOptimalRolesCheck = userIsOptimalForOrg(currentUser, selectedOrg);
  const demoIsExpired = selectedOrg?.demoIsExpired && !userHasOptimalRolesCheck;

  const onDelete = () => {
    showModal(selectedOrg);
  };
  const onEditOrView = async () => {
    if (isCompleted) {
      await handleChangeCurrOrg(id);
      const navigateRoute = demoIsExpired ? '/plans' : '/planning';
      navigate(navigateRoute);
    } else {
      navigate(`/organizations/creation-steps/${id}/${creationStep}`);
    }
  };
  const progressStatus = () => (isCompleted ? 'success' : 'normal');
  const progressTooltip = () =>
    isCompleted
      ? 'Fases completas en la creación de la organización'
      : 'Fase incompleta en la creación de la organización';
  const progressFormat = () => (
    <Tooltip title={progressTooltip}>{`${creationStep}/${TOTAL_STEPS}`}</Tooltip>
  );
  const actionsbuttons = () => {
    const buttons = [];

    if (canDelete) {
      buttons.push(
        <Button type="primary" danger onClick={onDelete}>
          {deletedAt === null ? 'Archivar' : 'Desarchivar'}
        </Button>
      );
    }
    if (canUpdate && !isCompleted) {
      buttons.push(
        <Button type="primary" onClick={onEditOrView}>
          Editar
        </Button>
      );
    }
    if (canRead && isCompleted && deletedAt === null) {
      buttons.push(
        <Button type="primary" onClick={onEditOrView}>
          Ver
        </Button>
      );
    }
    if (buttons.length === 1 && (canRead || canUpdate) && !canDelete) {
      buttons.unshift('');
    }
    // for restore, show only button on wide
    if (buttons.length === 1 && canDelete && deletedAt === null) {
      buttons.push('');
    }
    return buttons;
  };

  return (
    <Card
      className="card-style"
      cover={<img alt={`${name} logo`} src={logoUrl} className="card-cover-style" />}
      actions={actionsbuttons()}
    >
      <Space>
        <Meta
          title={
            <Text style={{ width: 150 }} ellipsis title={name}>
              {name}
            </Text>
          }
          description={
            <Text className="description-card-style" ellipsis title={name}>
              {fantasyName}
            </Text>
          }
        />
        <Progress
          type="circle"
          status={progressStatus}
          percent={Math.round((creationStep * 100) / TOTAL_STEPS)}
          size={30}
          format={progressFormat}
        />
      </Space>
    </Card>
  );
}

OrganizationsDashboardCard.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    fantasyName: PropTypes.string,
    logoUrl: PropTypes.string,
    creationStep: PropTypes.number,
    creationStatus: PropTypes.string,
    deletedAt: PropTypes.string,
    demoIsExpired: PropTypes.bool,
  }).isRequired,
  canUpdate: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canRead: PropTypes.bool.isRequired,
  handleChangeCurrOrg: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default OrganizationsDashboardCard;
