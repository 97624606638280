import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Space, Tooltip } from 'antd';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';

function RouteButton(props) {
  const { buttonType, buttonText, onClick } = props;
  const ability = useContext(CaslContext);
  const canCreateDemandSet = checkPermissionForSubject(ability, 'create', 'planning');
  const canCreateRoute = checkPermissionForSubject(ability, 'create', 'routes');
  const isInNavBar = buttonType === 'navBar';
  const ref = useRef(null);
  const { addReference } = useContext(TourContext);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.demandsImport' };
  const showExperimentalDevelop = process.env.REACT_APP_SHOW_EXPERIMENTAL_DEVELOP;

  useEffect(() => {
    addReference('buttonDemandsRef', ref);
  }, [addReference]);

  const items = [];
  if (canCreateDemandSet) {
    items.push({
      label: (
        <Tooltip placement="right" title={i18n.t('help.advancedOption', scopeI18n)}>
          <Button type="text" block>
            {i18n.t('advancedOption', scopeI18n)}
          </Button>
        </Tooltip>
      ),
      key: 'advanced',
    });
  }
  if (canCreateRoute) {
    items.push({
      label: (
        <Tooltip placement="right" title={i18n.t('help.simpleOption', scopeI18n)}>
          <Button type="text" block>
            {i18n.t('simpleOption', scopeI18n)}
          </Button>
        </Tooltip>
      ),
      key: 'simple',
    });
  }
  if (canCreateRoute && `${showExperimentalDevelop}` === 'true') {
    items.push({
      label: (
        <Tooltip placement="right" title={i18n.t('help.superAdvancedOption', scopeI18n)}>
          <Button type="text" block>
            {i18n.t('superAdvancedOption', scopeI18n)}
          </Button>
        </Tooltip>
      ),
      key: 'super-advanced',
    });
  }

  const onClickEvent = (event) => {
    const { key } = event;
    onClick(key);
  };
  let typeButton = 'default';
  if (isInNavBar) {
    typeButton = 'primary';
  }
  const buttonElement = (
    <Dropdown menu={{ items, onClick: onClickEvent }} overlayClassName="new-routingset-button">
      <Button type={typeButton} className={isInNavBar ? 'new-route' : ''} size="large" ref={ref}>
        <Space>
          {isInNavBar && <FontAwesomeIcon icon={['fas', 'plus']} style={{ marginRight: '5px' }} />}
          {buttonText}
          <FontAwesomeIcon icon={['fas', 'chevron-down']} fontSize={14} />
        </Space>
      </Button>
    </Dropdown>
  );

  return (canCreateDemandSet || canCreateRoute) && buttonElement;
}

RouteButton.defaultProps = {
  buttonType: 'navBar',
  buttonText: '',
  onClick: () => {},
};

RouteButton.propTypes = {
  buttonType: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default RouteButton;
