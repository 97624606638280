import { Alert, App, Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import SubmitButton from 'components/common/SubmitButton';
import { useGetDefinitionsQuery } from 'features/definitions/definitionsAPiSlice';
import { useUpdateOrganizationMutation } from 'features/organizations/organizationsApiSlice';
import {
  useCreateSettingMutation,
  useGetSettingsQuery,
  useUpdateSettingMutation,
} from 'features/settings/settingsApiSlice';
import { find, findIndex } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPermissionForSubject, entities, groupByEntity } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import RoutingSettingsInputs from './Inputs';

const { Title } = Typography;
const enabledRestrictionsByDefault = [
  'demand_vehicle_max_capacity',
  'demand_vehicle_min_capacity',
  'demand_groups',
  'demand_delivery_priorities',
];

const restrictionsRelated = {
  lunch_min_route_costs: 'lunch_max_route_costs',
  lunch_max_route_costs: 'lunch_min_route_costs',
  demand_delivery_min_route_cost: 'demand_delivery_max_route_cost',
  demand_delivery_max_route_cost: 'demand_delivery_min_route_cost',
};
function RoutingSettings(props) {
  const { organizationId, goToNextStep, submitButton, current } = props;
  const navigate = useNavigate();
  const { data: definitionsFromDb } = useGetDefinitionsQuery('RESTRICTION');
  const entityTypes = entities.map((entity) => entity.value).join(',');
  const url = `${entityTypes}?format=false${
    organizationId ? `&organizationId=${organizationId}` : ''
  }`;
  const { data: settings, refetch: refetchSettings } = useGetSettingsQuery(url, {
    skip: entityTypes === undefined,
  });
  const [initialValues, setInitialValues] = useState({});
  const [form] = Form.useForm();
  const ability = useContext(CaslContext);
  const canCreate = checkPermissionForSubject(ability, 'create', 'routingParameters');
  const canUpdate = checkPermissionForSubject(ability, 'update', 'routingParameters');
  const canUpdateOrg = checkPermissionForSubject(ability, 'update', 'organizations');

  // need both, maybe only need one
  const canSave = (canCreate && canUpdate) || (canUpdateOrg && submitButton);

  const [updateSetting, { isLoading: updatingData }] = useUpdateSettingMutation();
  const [createSetting, { isLoading: creatingData }] = useCreateSettingMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { message } = App.useApp();

  useEffect(() => {
    if (definitionsFromDb && settings) {
      const restriction = find(definitionsFromDb || [], { type: 'RESTRICTION' });
      if (restriction) {
        const { data } = restriction;
        const groupedDefinitions = entities.map((entity) => {
          return { entity: entity.value, restrictions: groupByEntity(data, entity.value) };
        });

        const initialData = groupedDefinitions.map(({ entity, restrictions }) => {
          const settingsByEntity = find(settings, { type: entity });
          const enabledAndDisabledRestrictions =
            restrictions[entity]?.map((restrictionByEntity) => {
              const { name } = restrictionByEntity;
              const settingFounded = find(settingsByEntity?.keyValue, { name });
              if (submitButton) {
                return {
                  ...restrictionByEntity,
                  enabled: enabledRestrictionsByDefault.includes(name),
                };
              }
              return { ...restrictionByEntity, enabled: settingFounded?.enabled || false };
            }) || [];
          return {
            [entity]: enabledAndDisabledRestrictions,
            [`${entity}-settingId`]: settingsByEntity?.id,
          };
        });
        setInitialValues(Object.assign({}, ...initialData));
      }
    }
  }, [definitionsFromDb, settings, submitButton]);

  const onFinish = async (values) => {
    entities.forEach(async ({ value: entityName }) => {
      const formValues = values[entityName];
      const settingId = values[`${entityName}-settingId`];
      // only send if has restrictions defined
      if (formValues) {
        const endObject = {
          keyValue: formValues,
          type: entityName,
          ...(organizationId && { organizationId }),
        };
        if (settingId) {
          await updateSetting({ ...endObject, id: settingId }).unwrap();
        } else {
          await createSetting({ ...endObject }).unwrap();
        }
      }
    });
    message.success('Formulario enviado correctamente');
    refetchSettings();
    if (goToNextStep) {
      await updateOrganization({
        organizationId,
        body: { creationStep: current + 1 },
      })
        .then(() => {
          const urlRedirect = `/organizations/creation-steps/${organizationId}/${current + 1}`;
          navigate(urlRedirect);
        })
        .catch((error) => {
          console.error(error);
          message.error('Error al guardar el formulario');
        });
    }
  };

  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };
  const onChangeSwitch = (valueSwitch, fieldDefinition) => {
    const nameField = form.getFieldValue(fieldDefinition);
    if (Object.keys(restrictionsRelated).includes(nameField)) {
      const typeRestriction = fieldDefinition[0];
      const otherField = restrictionsRelated[nameField];
      const allFieldsByType = initialValues[typeRestriction];
      const indexOfOther = findIndex(allFieldsByType, ({ name }) => name === otherField);
      const fieldToChange = [typeRestriction, indexOfOther, 'enabled'];
      form.setFieldValue(fieldToChange, valueSwitch);
    }
  };

  return Object.keys(initialValues).length > 0 && settings ? (
    <>
      <Row gutter={24} style={{ marginBottom: '1rem' }}>
        <Col flex="auto">
          <Title level={4}>Restricciones</Title>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
        disabled={!canSave}
      >
        {entities.map(({ label, value }) => {
          return (
            <div key={`fragment-${value}`}>
              <Divider orientation="left" key={`divider-${value}`}>
                {label}
              </Divider>
              <Form.Item name={`${value}-settingId`} hidden noStyle>
                <Input />
              </Form.Item>
              {initialValues[value].length > 0 ? (
                <Row key={`row-${value}`}>
                  <RoutingSettingsInputs
                    entityType={value}
                    key={`inputs-${value}`}
                    onChange={onChangeSwitch}
                  />
                </Row>
              ) : (
                'Sin restricciones configuradas.'
              )}
            </div>
          );
        })}
        <Row justify="end">
          <Col>
            {/* is flow of creation */}
            {canUpdateOrg && submitButton && (
              <SubmitButton isLoading={updatingData || creatingData} />
            )}
            {/* is normal flow of edition */}
            {canCreate && canUpdate && !submitButton && (
              <Button type="primary" htmlType="submit" loading={updatingData || creatingData}>
                Guardar
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  ) : (
    <Alert description="No hay definiciones de tipo restricción creadas." type="warning" showIcon />
  );
}

RoutingSettings.defaultProps = {
  organizationId: null,
  goToNextStep: false,
  submitButton: false,
  current: null,
};
RoutingSettings.propTypes = {
  organizationId: PropTypes.number,
  goToNextStep: PropTypes.bool,
  submitButton: PropTypes.bool,
  current: PropTypes.number,
};

export default RoutingSettings;
