import { Col, Divider, Form, Input, Row, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext } from 'react';
import EmailsForm from '../Form/EmailsForm';
import PhonesForm from '../Form/PhonesForm';

const { Text } = Typography;

function ContactForm() {
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.location' };
  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Text strong>{i18n.t('contactInfo', scopeI18n)}</Text>
      </Divider>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={i18n.t('code', scopeI18n)} name="contactId">
            <Input placeholder={i18n.t('code', scopeI18n)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={i18n.t('name', scopeI18n)} name="contactName">
            <Input placeholder={i18n.t('name', scopeI18n)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <PhonesForm />
        </Col>
        <Col span={12}>
          <EmailsForm />
        </Col>
      </Row>
    </>
  );
}

export default ContactForm;
