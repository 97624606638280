import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Tooltip } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentUser, setStatusTour } from 'features/users/userSlice';
import { useChangeStatusTourMutation } from 'features/users/usersApiSlice';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function TourButton() {
  const { id: userId, enableTour: prevEnableTour } = useSelector(selectCurrentUser);
  const [changeStatusTour] = useChangeStatusTourMutation();
  const dispatch = useDispatch();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  const onClickButton = async () => {
    await changeStatusTour(userId);
    // change for new status, use effect on tour component detect change
    dispatch(setStatusTour({ enableTour: !prevEnableTour }));
  };
  return (
    <Col xs={0} md={24}>
      <Tooltip title={i18n.t('commons.showTour')} arrow={false}>
        <Button
          icon={<FontAwesomeIcon icon="binoculars" color="#c1c1c1" />}
          size="large"
          onClick={onClickButton}
        />
      </Tooltip>
    </Col>
  );
}

export default TourButton;
