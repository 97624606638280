import { Col, Divider, Row, Typography } from 'antd';
import StaticMap from 'components/common/Map/StaticMap';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { convertDateToDayjs } from 'services/repeated-functions';
import { SynopticItem, SynopticRoute } from '../../SynopticSettings.propTypes';

const { Text } = Typography;

// Returns the STOP component to be rendered on the popover.
function StopItem(props) {
  const { routeData, itemData, timezone } = props;
  const spanTitle = 8;
  const spanInfo = 24 - spanTitle;

  const startsAt = dayjs(itemData.arrivesAt);
  const endDate = dayjs(itemData.departsAt);

  const itemPoint = [
    {
      key: itemData.itemOrder,
      lat: itemData.latitude,
      lng: itemData.longitude,
      type: itemData.type,
      routeOrder: itemData.itemOrder,
      color: '#FF4085',
    },
  ];

  const dayText = convertDateToDayjs(startsAt, timezone, 'DD-MM-YYYY');
  const initTimeText = convertDateToDayjs(startsAt, timezone, 'HH:mm');
  const endTimeText = convertDateToDayjs(endDate, timezone, 'HH:mm');

  const driverText = routeData.driver
    ? `${routeData.driver.name} ${routeData.driver.lastName}`
    : 'Sin asignar';
  const vehicleText = routeData.vehicle ? routeData.vehicle.plate : 'Sin asignar';

  const regionText = itemData.addr.province ? itemData.addr.province : 'No definido';
  const comunneText = itemData.addr.city ? itemData.addr.city : 'No definido';
  const directionText = itemData.addr.street
    ? `${itemData.addr.street} ${itemData.addr.number || ''}`
    : 'No definido';

  return (
    <div>
      <Divider style={{ margin: '15px 0 15px 0' }} />
      <Row>
        <Col span={12} style={{ padding: '0 10px 25px 10px' }}>
          <Row>
            <Col span={spanTitle}>
              <Text strong>Fecha:</Text>
            </Col>
            <Col span={spanInfo}>
              <Text>{dayText}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={spanTitle}>
              <Text strong>Hora:</Text>
            </Col>
            <Col span={spanInfo}>
              <Text>{`${initTimeText} - ${endTimeText}`}</Text>
            </Col>
          </Row>
          <Divider style={{ margin: '15px 0 15px 0' }} />
          <Row>
            <Col span={spanTitle}>
              <Text strong>Conductor:</Text>
            </Col>
            <Col span={spanInfo}>
              <Text>{driverText}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={spanTitle}>
              <Text strong>Vehículo:</Text>
            </Col>
            <Col span={spanInfo}>
              <Text>{vehicleText}</Text>
            </Col>
          </Row>
          <Divider style={{ margin: '15px 0 15px 0' }} />
          <Row>
            <Col span={spanTitle}>
              <Text strong>Región:</Text>
            </Col>
            <Col span={spanInfo}>
              <Text>{regionText}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={spanTitle}>
              <Text strong>Comuna:</Text>
            </Col>
            <Col span={spanInfo}>
              <Text>{comunneText}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={spanTitle}>
              <Text strong>Dirección:</Text>
            </Col>
            <Col span={spanInfo}>
              <Text>{directionText}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={12} style={{ padding: '0 10px 0 10px' }}>
          <StaticMap dataSource={itemPoint} />
        </Col>
      </Row>
    </div>
  );
}
StopItem.defaultProps = {
  routeData: {}, // object. Current route data of the synoptic.
  itemData: {}, // object. Selected item data to be shown.
  timezone: 'America/Santiago',
};
StopItem.propTypes = {
  routeData: SynopticRoute,
  itemData: SynopticItem,
  timezone: PropTypes.string,
};

export default StopItem;
