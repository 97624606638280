import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import './style.css';

function TableComponent(props) {
  const { dataSource, columns, toggleMap, loading, paginationData } = props;
  const { totalData, onChangePage, actualPage } = paginationData;
  const pagination = totalData
    ? {
        showTotal: (total) => `Total: ${total}`,
        total: totalData,
        showSizeChanger: true,
        onChange: onChangePage,
        current: actualPage,
      }
    : undefined;
  return (
    <Table
      rowKey={(record) => record.id}
      onRow={(record) => {
        return {
          onClick: () => {
            toggleMap(record);
          },
        };
      }}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={pagination}
    />
  );
}

TableComponent.defaultProps = {
  dataSource: [],
  columns: [],
  toggleMap: () => {
    return false;
  },
  loading: false,
  paginationData: {
    totalData: undefined,
    onChangePage: undefined,
    actualPage: 1,
  },
};

TableComponent.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      createdAt: PropTypes.string,
      demandSetId: PropTypes.number,
      id: PropTypes.number,
      jobID: PropTypes.string,
      name: PropTypes.string,
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          action: PropTypes.string,
          endsAt: PropTypes.string,
          id: PropTypes.number,
          loads: PropTypes.arrayOf(
            PropTypes.shape({
              load: PropTypes.number,
            })
          ),
          startsAt: PropTypes.string,
          status: PropTypes.string,
          totalStops: PropTypes.number,
          travelTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
      status: PropTypes.string,
      totalRoutes: PropTypes.number,
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
      render: PropTypes.func,
    })
  ),
  toggleMap: PropTypes.func,
  loading: PropTypes.bool,
  paginationData: PropTypes.shape({
    totalData: PropTypes.number,
    onChangePage: PropTypes.func,
    actualPage: PropTypes.number,
  }),
};

export default TableComponent;
