import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel, Col, Image, List, Row, Tabs, theme } from 'antd';
import { eventType, itemType } from 'types';

function MapDetail(props) {
  const { item, finishedEvent } = props;
  const { addr } = item;
  const { eventAttachments } = finishedEvent;
  const { token } = theme.useToken();
  const contactEmail = item?.contactEmails[0]?.email;
  const contactPhone = item?.contactPhones[0]?.phone;
  const contactLocation = `${addr?.street} ${addr?.number}, ${addr?.city}`;

  const informationContent = () => {
    const data = [
      { title: contactEmail, icon: 'envelope' },
      { title: contactPhone, icon: 'phone' },
      { title: contactLocation, icon: 'location-dot' },
    ];
    return (
      <List
        size="small"
        dataSource={data}
        renderItem={(source) => (
          <List.Item>
            <FontAwesomeIcon icon={['fa', source.icon]} color={token.colorPrimary} /> {source.title}
          </List.Item>
        )}
      />
    );
  };
  const proofOfDelivery = () => {
    return (
      <Row>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Row>
            <Col style={{ width: '100px' }} lg={{ span: 4 }} xs={{ span: 8 }}>
              <strong>Motivo</strong>
            </Col>
            <Col lg={{ span: 20 }} xs={{ span: 16 }}>
              {finishedEvent?.reason || 'Sin información'}
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '100px' }} lg={{ span: 4 }} xs={{ span: 8 }}>
              <strong>Comentario</strong>
            </Col>
            <Col lg={{ span: 20 }} xs={{ span: 16 }}>
              {finishedEvent?.comment || 'Sin información'}
            </Col>
          </Row>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Carousel className="images-events" style={{ maxWidth: '400px' }}>
            {eventAttachments?.map((eventAttachment) => (
              <div key={`div-${eventAttachment.id}`}>
                <Image
                  key={eventAttachment.id}
                  height={160}
                  width={160}
                  style={{
                    textAlign: 'center',
                  }}
                  src={eventAttachment.signedUrl}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    );
  };
  const items = [
    {
      key: '1',
      label: 'Información',
      children: informationContent(),
    },
    {
      key: '2',
      label: 'Prueba de entrega',
      children: proofOfDelivery(),
    },
  ];
  return <Tabs defaultActiveKey="1" items={items} />;
}

export default MapDetail;

MapDetail.defaultProps = {
  finishedEvent: {},
};
MapDetail.propTypes = {
  item: itemType.isRequired,
  finishedEvent: eventType,
};
