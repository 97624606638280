import { Space } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import Can from 'utils/Can';
import ButtonTable from './ButtonTable';

function TableButtons(props) {
  const { record, readClickHandle, editClickHandle, deleteClickHandle, subject } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'buttons' };
  const isDeleted = () => {
    if (subject === 'users') {
      const orgIdsArchived = record.organizations.map(({ id }) => id);
      const relatedOrgIsArchived = record?.organizationUsers.filter(
        (orgUs) => orgIdsArchived.includes(orgUs.organizationId) && orgUs.deletedAt
      );
      return record.deletedAt || relatedOrgIsArchived.length > 0;
    }
    return record.deletedAt;
  };

  const viewButton = (
    <ButtonTable
      label={i18n.t('show', scopeI18n)}
      type="primary"
      onClickEvent={() => {
        readClickHandle('show', record);
      }}
    />
  );
  const editButton = (
    <ButtonTable
      label={i18n.t('edit', scopeI18n)}
      onClickEvent={() => {
        editClickHandle('edit', record);
      }}
    />
  );
  const deleteButton = (
    <ButtonTable
      type="primary"
      label={i18n.t(isDeleted() ? 'unarchive' : 'archive', scopeI18n)}
      onClickEvent={(event) => deleteClickHandle(event, record)}
      danger
      className={isDeleted() ? 'inverse' : ''}
    />
  );

  const listButtons = [
    <Can key={`read-${record.id}`} I="read" a={subject}>
      {viewButton}
    </Can>,
    <Can key={`update-${record.id}`} I="update" a={subject}>
      {editButton}
    </Can>,
    <Can key={`delete-${record.id}`} I="delete" a={subject}>
      {deleteButton}
    </Can>,
  ];

  return (
    <Space size="middle" className="float-right">
      {listButtons.map((element) => element)}
    </Space>
  );
}

TableButtons.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    deletedAt: PropTypes.string,
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    organizationUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        organizationId: PropTypes.number,
        deletedAt: PropTypes.string,
      })
    ),
  }),
  readClickHandle: PropTypes.func,
  editClickHandle: PropTypes.func,
  deleteClickHandle: PropTypes.func,
  subject: PropTypes.string,
};

TableButtons.defaultProps = {
  readClickHandle: () => {},
  editClickHandle: () => {},
  deleteClickHandle: () => {},
  record: {},
  subject: 'fleets',
};

export default TableButtons;
