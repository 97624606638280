import { Drawer, Form, Typography } from 'antd';
import FooterDrawer from 'components/common/FooterDrawer';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { getTitleToDrawer } from 'services/repeated-functions';
import UsersForm from '../Form';
import UsersShow from '../Show';
import { RolesPropTypes, UserPropTypes } from '../userRoles.propTypes';

const { Text } = Typography;

function UserDrawer(props) {
  const { drawerVisible, setDrawerUser, typeDrawer, user, roles, setTriggerClean } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'users' };

  const onClose = (event) => {
    event?.stopPropagation();
    setDrawerUser(false);
  };

  const title = (
    <Text strong>{getTitleToDrawer(typeDrawer, i18n.t('user', scopeI18n), user.username)}</Text>
  );

  const footer = (
    <FooterDrawer
      onClose={onClose}
      typeDrawer={typeDrawer}
      form={form}
      entity={user}
      buttonLoading={buttonLoading}
    />
  );

  return (
    <Drawer
      title={title}
      footer={footer}
      width="40%"
      onClose={(event) => onClose(event)}
      open={drawerVisible}
      destroyOnClose
      style={{ body: { paddingBottom: 80 } }}
      maskClosable={false}
      closable={false}
    >
      {['create', 'edit'].includes(typeDrawer) ? (
        <UsersForm
          onCloseDrawer={onClose}
          user={user}
          roles={roles}
          form={form}
          setButtonLoading={setButtonLoading}
          drawerVisible={drawerVisible}
          setTriggerClean={setTriggerClean}
          typeDrawer={typeDrawer}
        />
      ) : (
        <UsersShow onCloseDrawer={onClose} user={user} />
      )}
    </Drawer>
  );
}

UserDrawer.propTypes = {
  drawerVisible: PropTypes.bool,
  setDrawerUser: PropTypes.func,
  typeDrawer: PropTypes.string,
  user: UserPropTypes,
  roles: RolesPropTypes,
  setTriggerClean: PropTypes.func,
};

UserDrawer.defaultProps = {
  drawerVisible: false,
  typeDrawer: 'create',
  setDrawerUser: () => {},
  user: {},
  roles: [],
  setTriggerClean: () => {},
};

export default UserDrawer;
