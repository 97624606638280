import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Card, Col, Popover, Row, Space, Tag, Typography } from 'antd';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import ModalConfirm from 'components/common/ModalConfirm';
import TableComponent from 'components/common/Table';
import TableFilters from 'components/common/TableFilter';
import TitleHelp from 'components/common/TitleHelp';
import TourContext from 'components/layout/TourComponent/TourContext';
import { useGetFullDepotLocationsQuery } from 'features/locations/locationsApiSlice';
import {
  useDeleteRoutingSetMutation,
  useGetFullRoutingSetsQuery,
  useGetRoutingSetsByFiltersMutation,
  useGetRoutingSetsQuery,
} from 'features/routingSets/routingSetsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  convertDateToDayjs,
  DataByStatus,
  removeDuplicateKeysFromObject,
} from 'services/repeated-functions';
import Can from 'utils/Can';
import NoResponsiveComponent from '../../layout/NoResponsiveContent';
import DrawerRoutes from './DrawerRoutes';

const { Text } = Typography;

function ListContent() {
  const [routingSets, setRoutingSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [routingSetId, setRoutingSetId] = useState();
  const [selectedRoutingSet, setSelectedRoutingSet] = useState();
  const [downloadQuery, setDownloadQuery] = useState('');
  const [actualPage, setActualPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [triggerClean, setTriggerClean] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [routingSet, setRoutingSet] = useState({});
  const [routesDrawerIsVisible, setRoutesDrawerIsVisible] = useState(false);
  const navigate = useNavigate();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { message } = App.useApp();
  const { addReference } = useContext(TourContext);
  const ref = useRef(null);
  const refShow = useRef(null);
  const refFollowing = useRef(null);

  // custom hooks
  const { data: routingSetsData, isLoading, refetch } = useGetRoutingSetsQuery();
  const { data: fullRoutingSetsData, isLoading: fullDataIsLoading } = useGetFullRoutingSetsQuery();
  const [deleteRoutingSet] = useDeleteRoutingSetMutation();
  const [getRoutingSetsByFilters, { isLoading: filterIsLoading }] =
    useGetRoutingSetsByFiltersMutation();
  const { data: depots, isLoading: depotsLoading } = useGetFullDepotLocationsQuery();

  useEffect(() => {
    const setFullData = () => {
      const driversArray = [];
      const assistantsArray = [];
      const vehiclesArray = [];
      const routes = fullRoutingSetsData?.data
        .map((rData) => rData?.routes)
        .filter((innerArray) => innerArray.length > 0);
      routes?.forEach((value) => {
        value.forEach((valueData) => {
          if (valueData.driver) {
            driversArray.push(valueData.driver);
          }
          if (valueData.assistant) {
            assistantsArray.push(valueData.assistant);
          }
          if (valueData.vehicle) {
            vehiclesArray.push(valueData.vehicle);
          }
        });
      });
      setDrivers(removeDuplicateKeysFromObject(driversArray));
      setVehicles(removeDuplicateKeysFromObject(vehiclesArray));
      setAssistants(removeDuplicateKeysFromObject(assistantsArray));
    };
    if (!fullDataIsLoading) {
      setFullData();
    }
  }, [fullRoutingSetsData, fullDataIsLoading]);

  useEffect(() => {
    const setDataForTable = () => {
      const { data, total } = routingSetsData;
      setRoutingSets(data);
      setTotalData(total);
      refetch();
      setLoading(false);
    };
    if (!isLoading) {
      setDataForTable();
    }
  }, [routingSetsData, isLoading, refetch]);

  const showRoute = (id) => {
    setLoading(true);
    navigate(`/planning/routes/${id}`);
  };

  const followingRoute = (id) => {
    setLoading(true);
    navigate(`/following/routing-set/${id}`);
  };

  // archive
  const archiveRoutingSet = async () => {
    if (routingSetId) {
      try {
        const newStatus = selectedRoutingSet.deletedAt ? 'desarchivado' : 'archivado';
        await deleteRoutingSet(routingSetId);
        message.success(`Conjunto de rutas ${newStatus}`);
      } catch (error) {
        const newStatus = selectedRoutingSet.deletedAt ? 'desarchivar' : 'archivar';
        message.error(`No se pudo ${newStatus} el conjunto de rutas`);
      }
    }
  };
  const showModal = (event, id) => {
    event.stopPropagation();
    setIsModalVisible(true);
    setRoutingSetId(id);
    const selected = routingSets.find(({ id: rsId }) => id === rsId);
    setSelectedRoutingSet(selected);
  };
  const onConfirmArchive = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await archiveRoutingSet();
    setTriggerClean(false);
    setIsModalVisible(false);
    setRoutingSetId(undefined);
    setSelectedRoutingSet(undefined);
  };
  const onCancelArchive = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
    setRoutingSetId(undefined);
    setSelectedRoutingSet(undefined);
  };

  const openRoutesDrawer = (record) => {
    setRoutingSet(record);
    setRoutesDrawerIsVisible(true);
  };

  const helpStatus = [
    {
      title: 'Activo',
      color: 'green',
      description: 'Las rutas se generaron correctamente.',
    },
    {
      title: 'Creado',
      color: 'default',
      description: 'Las rutas pronto se generarán.',
    },
    {
      title: 'Pendiente',
      color: 'default',
      description: 'Las rutas se están generando.',
    },
    {
      title: 'Fallido',
      color: 'error',
      description: 'Hubo un problema en la generación de rutas.',
    },
  ];

  const renderInformation = (
    <Card
      style={{ padding: '.5rem', boxShadow: 'unset' }}
      bodyStyle={{ padding: '.5rem' }}
      bordered={false}
    >
      {helpStatus.map(({ title, description, color }) => {
        return (
          <Space key={title}>
            <Tag color={color} style={{ minWidth: '75px', textAlign: 'center' }}>
              {title}
            </Tag>
            {description}
          </Space>
        );
      })}
    </Card>
  );
  const titleStatus = (
    <Space ref={ref}>
      <Text>Proceso</Text>
      <Popover content={renderInformation}>
        <FontAwesomeIcon icon="fa-solid fa-circle-question" color="#999999" />
      </Popover>
    </Space>
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      render: (_, record) => (
        <Button
          type="link"
          className="btn-no-shadow"
          title="Ver rutas"
          onClick={(event) => {
            event.stopPropagation();
            openRoutesDrawer(record);
          }}
        >
          {record.id}
        </Button>
      ),
    },
    {
      title: (
        <TitleHelp
          title="Nombre"
          helpText="El nombre se hereda del conjunto de demandas, y el número a la derecha indica la cantidad de veces que se ha utilizado ese conjunto."
          placement="right"
        />
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <TitleHelp
          title="Base"
          helpText="Muestra la base asociada al conjunto de rutas al parametrizar"
          placement="right"
        />
      ),
      dataIndex: 'depot',
      key: 'depot',
      render: (_text, record) => {
        return record?.demandSet?.depot?.name;
      },
    },
    {
      title: (
        <TitleHelp
          title="Tipo de acción"
          helpText="Determina si se debe priorizar el horario de inicio o de término de la ruta"
        />
      ),
      dataIndex: 'action',
      key: 'action',
      render: (text) => {
        const textTranslated = text === 'delivery' ? 'Llevada' : 'Recogida';
        return textTranslated;
      },
    },
    {
      title: 'Fecha Planificación',
      dataIndex: 'planificationAt',
      key: 'planificationAt',
      render: (date) => {
        return convertDateToDayjs(date, tzOrganization, 'DD-MM-YYYY HH:mm');
      },
    },
    {
      title: 'Rutas',
      dataIndex: 'totalRoutes',
      key: 'totalRoutes',
    },
    {
      title: titleStatus,
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const { color, text } = DataByStatus(status);
        return (
          <Tag color={color} style={{ minWidth: '75px', textAlign: 'center' }}>
            {text}
          </Tag>
        );
      },
    },
    {
      key: 'buttons',
      render: (_text, record) => {
        const disabled = record.status !== 'SUCCESS';
        return (
          <Space size="middle">
            <Can key={`read-${record.id}`} I="read" a="routes">
              <Button
                disabled={disabled}
                type="primary"
                onClick={() => {
                  showRoute(record.id);
                }}
                style={{ minWidth: '54px' }}
                ref={refShow}
              >
                {disabled ? <FontAwesomeIcon icon={['fa', 'spinner']} spin /> : 'Ver'}
              </Button>
            </Can>
            <Can I="read" a="following">
              <Button
                disabled={disabled}
                onClick={(event) => {
                  event.preventDefault();
                  followingRoute(record.id);
                }}
                ref={refFollowing}
              >
                Seguimiento
              </Button>
            </Can>
            <Can key={`delete-${record.id}`} I="delete" a="routes">
              <Button
                type="primary"
                danger
                className={record.deletedAt ? 'inverse' : ''}
                onClick={(event) => {
                  showModal(event, record.id);
                }}
              >
                {record.deletedAt ? 'Desarchivar' : 'Archivar'}
              </Button>
            </Can>
          </Space>
        );
      },
    },
  ];

  const fieldsFilter = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'depot',
      label: 'Base',
      type: 'select',
      options: !depotsLoading
        ? depots?.data?.map((depot) => ({ value: depot?.id, label: depot?.name }))
        : [],
    },
    {
      name: 'action',
      label: 'Tipo de acción',
      type: 'select',
      options: [
        { value: 'delivery', label: 'Llevada' },
        { value: 'pickup', label: 'Recogida' },
      ],
    },
    {
      name: 'planificationAt',
      label: 'Planificación',
      type: 'range',
    },
  ];

  const advancedFieldsFilter = [
    {
      name: 'name',
      label: 'Nombre',
      type: 'text',
      span: 24,
    },
    {
      name: 'status',
      label: 'Proceso',
      type: 'select',
      options: [
        { value: 'SUCCESS', label: 'Activo' },
        { value: 'PENDING', label: 'Pendiente' },
      ],
      span: 24,
    },
    {
      name: 'createdAt',
      label: 'Creación',
      type: 'range',
      span: 24,
    },
    {
      name: 'routeStatus',
      label: 'Estado',
      type: 'select',
      options: [
        { value: 'CREATED', label: 'Creada' },
        { value: 'ASSIGNED', label: 'Asignada' },
        { value: 'STARTED', label: 'Iniciada' },
        { value: 'FINISHED', label: 'Finalizada' },
      ],
      span: 24,
    },
    {
      name: 'driver',
      label: 'Conductor',
      type: 'select',
      options:
        drivers.length > 0
          ? drivers?.map((driver) => ({ value: driver?.id, label: driver?.fullName }))
          : [],
      span: 24,
    },
    {
      name: 'vehicle',
      label: 'Vehículo',
      type: 'select',
      options:
        vehicles.length > 0
          ? vehicles?.map((vehicle) => ({ value: vehicle?.id, label: vehicle?.nid }))
          : [],
      span: 24,
    },
    {
      name: 'assistant',
      label: 'Acompañante',
      type: 'select',
      options:
        assistants.length > 0
          ? assistants?.map((assistant) => ({ value: assistant?.id, label: assistant?.fullName }))
          : [],
      span: 24,
    },
  ];

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/planning',
      label: 'Rutas',
    },
    {
      key: 'routes',
      url: '/planning/routes',
      label: 'Lista de rutas',
    },
  ];

  addReference('headerProcessRouteRef', ref);
  addReference('buttonShowRouteRef', refShow);
  addReference('buttonFollowingRouteRef', refFollowing);

  const onChangePage = async (page, pageSize) => {
    // include filter parameters
    const filterQuery = downloadQuery !== '' ? `&${downloadQuery.slice(1)}` : '';
    const queryString = `?page=${page}&take=${pageSize}${filterQuery}`;
    try {
      setLoading(true);
      setActualPage(page);
      const { data, total } = await getRoutingSetsByFilters(queryString).unwrap();
      setTotalData(total);
      setRoutingSets(data);
      setLoading(false);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  return (
    <>
      <Col md={24} xs={0}>
        <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
        <TableFilters
          setDataCallback={setRoutingSets}
          fieldsFilter={fieldsFilter}
          advancedFieldsFilter={advancedFieldsFilter}
          reduxFunc={getRoutingSetsByFilters}
          setDownloadQuery={setDownloadQuery}
          paginationData={{ setTotalData, setActualPage }}
          triggerClean={triggerClean}
          filterIsLoading={filterIsLoading}
        />
        <Row gutter={24} style={{ height: '100%' }} className="vehicles-container">
          <Col flex="auto">
            <TableComponent
              dataSource={routingSets}
              columns={columns}
              loading={loading}
              paginationData={{ totalData, onChangePage, actualPage }}
            />
          </Col>
        </Row>
        <ModalConfirm
          onOk={onConfirmArchive}
          onCancel={onCancelArchive}
          title={`¿Está seguro que quiere ${
            selectedRoutingSet?.deletedAt ? 'desarchivar' : 'archivar'
          } este conjunto de rutas y todas las rutas que lo contienen?`}
          isModalVisible={isModalVisible}
        />
        <DrawerRoutes
          routingSet={routingSet}
          drawerVisible={routesDrawerIsVisible}
          setDrawerVisible={setRoutesDrawerIsVisible}
        />
      </Col>
      <Col xs={24} md={0}>
        <NoResponsiveComponent />
      </Col>
    </>
  );
}

export default ListContent;
