import { Col, DatePicker, Row, Typography } from 'antd';
import { VehiclePositionPropTypes } from 'components/Vehicles/vehicles.propTypes';
import LocaleContext from 'components/locale/LocaleContext';
import dayjs from 'dayjs';
import { useGetRoutesByFiltersMutation } from 'features/routes/routesApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertDateToDayjs } from 'services/repeated-functions';
import FollowingLeftComponent from '../LeftComponent';
import FollowingRightComponent from '../RightComponent';
import '../style.css';

const { RangePicker } = DatePicker;
const { Title } = Typography;
function FollowingGeneral(props) {
  const { isConnected, vehiclesPositionEvents } = props;
  const [initialRoutes, setInitialRoutes] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const today = dayjs().format('YYYY-MM-DD');
  const [routeStartFilter, setRouteStartFilter] = useState([today, today]);
  const [offlineMode, setOfflineMode] = useState(false);

  const [getRoutesByFilters] = useGetRoutesByFiltersMutation();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);

  const formatDate = 'DD-MM-YYYY';
  const todayAsDayjs = convertDateToDayjs(new Date(), tzOrganization);
  const maxDayRange = 14;
  const disabledDate = (current) => {
    // maxDayRange or selected as initial + maxDayRange
    const tooLate =
      current > todayAsDayjs.clone().add(maxDayRange, 'days') ||
      current > convertDateToDayjs(routeStartFilter[0], tzOrganization).add(maxDayRange, 'days');
    const tooEarly = current < todayAsDayjs.clone().subtract(maxDayRange, 'days');
    return !!tooEarly || !!tooLate;
  };

  useEffect(() => {
    const fetchData = async () => {
      const queryRouteStartFilter = `?startsAt=${routeStartFilter}`;
      const result = await getRoutesByFilters(queryRouteStartFilter).unwrap();
      const routesData = result;
      setAllRoutes(routesData);
      setInitialRoutes(routesData);
      let allItemsLoaded = [];
      routesData.forEach((route) => {
        allItemsLoaded = [...allItemsLoaded, ...route.items];
      });
      setAllItems(allItemsLoaded);
    };
    fetchData();
  }, [getRoutesByFilters, routeStartFilter]);

  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  return (
    <Row gutter={24}>
      <Col xs={24} md={0}>
        <Title level={4} style={{ fontWeight: 500 }}>
          {i18n.t('planningFor', scopeI18n)}:
        </Title>
      </Col>
      <Col xs={24} md={0} style={{ paddingBottom: '1rem' }}>
        <RangePicker
          popupClassName="responsive-picker"
          format={formatDate}
          disabledDate={disabledDate}
          allowClear={false}
          onChange={(e) => {
            if (e) {
              const initial = e[0].format('YYYY-MM-DD');
              const final = e[1].format('YYYY-MM-DD');
              setRouteStartFilter([initial, final]);
            }
          }}
          defaultValue={[todayAsDayjs, todayAsDayjs]}
        />
      </Col>
      <FollowingLeftComponent allRoutes={allRoutes} allItems={allItems} offlineMode={offlineMode} />
      <FollowingRightComponent
        allRoutes={allRoutes}
        setAllRoutes={setAllRoutes}
        setAllItems={setAllItems}
        initialRoutes={initialRoutes}
        routeStartFilter={routeStartFilter}
        setRouteStartFilter={setRouteStartFilter}
        setOfflineMode={setOfflineMode}
        offlineMode={offlineMode}
        isConnected={isConnected}
        vehiclesPositionEvents={vehiclesPositionEvents}
      />
    </Row>
  );
}

FollowingGeneral.defaultProps = {
  isConnected: false,
  vehiclesPositionEvents: [],
};

FollowingGeneral.propTypes = {
  isConnected: PropTypes.bool,
  vehiclesPositionEvents: PropTypes.arrayOf(VehiclePositionPropTypes),
};

export default FollowingGeneral;
