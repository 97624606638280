import apiSlice from 'app/api/apiSlice';

/**
 * demandsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const demandsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDemands: builder.query({
      query: (demandSetId) => `/demands/?demand_set_id=${demandSetId}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Demands', id })) : ['Demands'],
    }),
    getDemandsFilters: builder.mutation({
      query: (query) => ({
        url: `demands${query}`,
      }),
      invalidatesTags: ['Demands'],
    }),
    addDemand: builder.mutation({
      query: (params) => ({
        url: `demands/${params.demandSetId}`,
        method: 'POST',
        body: { ...params.body },
      }),
      invalidatesTags: ['Demands', 'DemandsSets', 'GroupsForDemand'],
    }),
    updateDemand: builder.mutation({
      query: (params) => ({
        url: `demands/${params.demandId}`,
        method: 'PUT',
        body: { ...params.body },
      }),
      invalidatesTags: ['Demands', 'DemandsSets', 'GroupsForDemand'],
    }),
    deleteDemand: builder.mutation({
      query: (demandId) => ({
        url: `/demands/change-status/${demandId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Demands', 'DemandsSets', 'GroupsForDemand'],
    }),
    getGroups: builder.query({
      query: () => `/demands/get-groups`,
      providesTags: (_result, _error, id) => [{ type: 'GroupsForDemand', id }],
    }),
  }),
});

export const {
  useGetDemandsQuery,
  useGetDemandsFiltersMutation,
  useAddDemandMutation,
  useUpdateDemandMutation,
  useDeleteDemandMutation,
  useGetGroupsQuery,
} = demandsApiSlice;
