import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import './style.css';

function BreadcrumbComponent(props) {
  const { breadcrumbItems, marginBottom } = props;
  const items = breadcrumbItems.map((item, index) => {
    if (breadcrumbItems.length === index + 1) {
      return { title: item.label };
    }
    return {
      title: <Link to={item.url}>{item.label}</Link>,
    };
  });

  return <Breadcrumb separator=">" style={{ marginBottom }} items={items} />;
}

BreadcrumbComponent.defaultProps = {
  marginBottom: '20px',
};

BreadcrumbComponent.propTypes = {
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  marginBottom: PropTypes.string,
};

export default BreadcrumbComponent;
