import apiSlice from 'app/api/apiSlice';

/**
 * settingsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: (type) => `settings/by-type/${type}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Settings', id })) : ['Settings'],
    }),
    updateSetting: builder.mutation({
      query: (params) => ({
        url: `/settings/${params.id}`,
        method: 'PATCH',
        body: { ...params },
      }),
      invalidatesTags: ['Settings'],
    }),
    createSetting: builder.mutation({
      query: (params) => ({
        url: '/settings',
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
});

export const { useGetSettingsQuery, useUpdateSettingMutation, useCreateSettingMutation } =
  settingsApiSlice;
