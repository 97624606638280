import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import ConfigurationsEmails from '../../Configurations/Emails';

function OrganizationsCreationStepsEmail(props) {
  const { organizationId, isLoading, current } = props;
  return isLoading ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <ConfigurationsEmails
      organizationId={organizationId}
      goToNextStep
      submitButton
      current={current}
    />
  );
}

OrganizationsCreationStepsEmail.defaultProps = {
  organizationId: null,
  isLoading: false,
  current: null,
};
OrganizationsCreationStepsEmail.propTypes = {
  organizationId: PropTypes.number,
  isLoading: PropTypes.bool,
  current: PropTypes.number,
};

export default OrganizationsCreationStepsEmail;
