import { Button, Col, Image, Row, Tooltip, Typography } from 'antd';
import imageApp from 'assets/img/driver/app-mobile.png';
import qrAndroid from 'assets/img/driver/qr-android.svg';
import qrIos from 'assets/img/driver/qr-ios.svg';
import loginIcon from 'assets/img/login-icon.svg';
import LocaleContext from 'components/locale/LocaleContext';
import { logOut } from 'features/auth/authSlice';
import { logOutCurrentUser } from 'features/users/userSlice';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './style.css';

const { Text, Title } = Typography;

function Driver() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'driver' };

  let isIOS = false;
  const userAgent = window.navigator?.userAgent;
  const iosDevices = ['iphone', 'iPhone', 'ipad', 'iPad', 'Macintosh', 'Mac'];
  if (iosDevices.some((soDevice) => userAgent.includes(soDevice))) {
    isIOS = true;
  }
  const handleLogout = async () => {
    dispatch(logOut());
    dispatch(logOutCurrentUser());
    navigate('/');
  };

  const LINKS_APPSTORE = {
    android: {
      link: 'https://play.google.com/store/apps/details?id=cl.routinguc.routing.mobile.android',
      image: qrAndroid,
    },
    iphone: {
      link: 'https://apps.apple.com/cl/app/routing-mobile/id1670959114',
      image: qrIos,
    },
  };

  const orderedQrs = isIOS ? Object.keys(LINKS_APPSTORE).toReversed() : Object.keys(LINKS_APPSTORE);
  const qrCols = orderedQrs.map((os) => {
    const osName = i18n.t(`so.${os}`);
    const { link, image } = LINKS_APPSTORE[os];
    return (
      <Col span={12} xs={24} md={12} className="driver col-rqs" key={`qr-${os}`}>
        <Tooltip title={i18n.t('downloadForOs', { ...scopeI18n, os: osName })} placement="top">
          <a href={link} title={osName} rel="noreferrer" className="qr-image">
            <img src={image} alt={osName} />
          </a>
        </Tooltip>
      </Col>
    );
  });

  return (
    <Row>
      <Col span={18} offset={1}>
        <Image
          src={loginIcon}
          alt="logo"
          title="Planners"
          preview={false}
          className="form-login-logo"
        />
      </Col>
      <Col span={4} xs={0} md={4} className="driver col-logout">
        <Button type="default" onClick={handleLogout}>
          {i18n.t('profile.logOut')}
        </Button>
      </Col>
      <Col xs={24} md={0} className="driver col-logout">
        <Button type="default" size="large" onClick={handleLogout} className="mobile-btn">
          {i18n.t('profile.logOut')}
        </Button>
      </Col>
      <Col
        span={10}
        offset={1}
        md={{ span: 10, offset: 1 }}
        xs={{ span: 22, offset: 1 }}
        className="driver col-info"
      >
        <Title level={3}>{i18n.t('congratulations', scopeI18n)}</Title>
        <Text type="secondary">{i18n.t('welcomeMessage', scopeI18n)}</Text>
        <Row>
          <Col span={24} className="select-scan">
            <Text type="secondary">{i18n.t('selectOrScan', scopeI18n)}</Text>
          </Col>
          {qrCols}
        </Row>
      </Col>
      <Col
        span={8}
        offset={2}
        md={{ span: 8, offset: 3 }}
        xs={{ span: 24, offset: 0 }}
        className="driver app-preview"
      >
        <Image src={imageApp} preview={false} alt="preview-app" />
      </Col>
      <Col span={24} className="driver col-footer">
        <span>
          © {new Date().getFullYear()},{' '}
          <a
            href="https://theoptimalpartner.com"
            target="_blank"
            rel="noreferrer"
            title={i18n.t('goToOptimalWeb')}
          >
            {i18n.t('theOptimal')}
          </a>
        </span>
      </Col>
    </Row>
  );
}
export default Driver;
