import { App, Button, Col, Drawer, Form, Input, Row } from 'antd';
import usePasswordStrengthChecker from 'components/common/Password/PasswordStrengthChecker';
import StrengthMeter from 'components/common/Password/StrengthMeter';
import LocaleContext from 'components/locale/LocaleContext';
import { selectNotificationToken } from 'features/app/appSlice';
import { useChangePasswordMutation, useLoginMutation } from 'features/auth/authApiSlice';
import { selectCurrentUsername } from 'features/auth/authSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function ChangePasswordDrawer(props) {
  const { isVisible, setVisible } = props;
  const [form] = Form.useForm();
  const [newPwd, setNewPwd] = useState('');
  const username = useSelector(selectCurrentUsername);
  const notificationToken = useSelector(selectNotificationToken);
  const [login] = useLoginMutation();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.changePassword' };
  const initialValues = {
    token: '',
    pwd: '',
    newPwd: '',
    confirmNewPwd: '',
  };

  // check password strength custom hook
  const [passwordErrors, checkPasswordStrength, hasPasswordErrors] = usePasswordStrengthChecker();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const handlePwdInput = (e) => setNewPwd(e.target.value);
  const { message } = App.useApp();

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible, setVisible]);

  const resetForm = () => {
    setNewPwd('');
    form.setFieldsValue(initialValues);
  };

  const onClose = (event) => {
    event?.stopPropagation();
    setVisible(false);
    resetForm();
  };

  const onFinish = async (values) => {
    try {
      // always get new access_token
      const loginData = await login({ user: username, pwd: values.pwd, notificationToken });
      const formValues = { ...values, token: loginData.data.access_token };
      await changePassword(formValues).unwrap();
      message.success(i18n.t('alerts.changeSuccessfully', scopeI18n), 4);
      setVisible(false);
      resetForm();
    } catch (err) {
      const errMessage = i18n.t('errors.wrongPassword', scopeI18n);
      form.setFields([
        {
          name: 'pwd',
          errors: [errMessage],
        },
        {
          name: 'newPwd',
          errors: [errMessage],
        },
      ]);
    }
  };

  return (
    <Drawer
      title={i18n.t('changePassword', scopeI18n)}
      size="default"
      onClose={(event) => onClose(event)}
      open={isVisible}
      style={{ body: { paddingBottom: 80 } }}
      destroyOnClose
      closable={false}
      footer={
        <div className="footer-drawer-buttons">
          <Button onClick={onClose} disabled={isLoading}>
            {i18n.t('buttons.cancel')}
          </Button>
        </div>
      }
    >
      <div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          name="login"
          initialValues={initialValues}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col md={{ span: 18, offset: 4 }} xs={24}>
              <Form.Item name="token" hidden>
                <Input name="token" />
              </Form.Item>

              <Form.Item
                className="login-item"
                label={i18n.t('currentPassword', scopeI18n)}
                name="pwd"
                size="large"
                rules={[
                  {
                    required: true,
                    message: i18n.t('rules.currentPassword', scopeI18n),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>

            <Col md={{ span: 18, offset: 4 }} xs={24}>
              <Form.Item
                className="login-item"
                label={i18n.t('newPassword', scopeI18n)}
                name="newPwd"
                size="large"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: i18n.t('rules.newPassword', scopeI18n),
                  },
                  () => ({
                    validator() {
                      if (!hasPasswordErrors) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(i18n.t('errors.minStrength', scopeI18n)));
                    },
                  }),
                ]}
              >
                <Input.Password onInput={checkPasswordStrength} onChange={handlePwdInput} />
              </Form.Item>
            </Col>
            <Col md={{ span: 18, offset: 4 }} xs={24}>
              <Form.Item
                className="login-item"
                label={i18n.t('confirmNewPassword', scopeI18n)}
                name="confirmNewPwd"
                size="large"
                dependencies={['newPwd']}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPwd') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(i18n.t('errors.notEquals', scopeI18n)));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col md={{ span: 18, offset: 4 }} xs={24}>
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="login-item"
                  loading={isLoading}
                >
                  {i18n.t('buttons.save')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {newPwd && (
          <Row gutter={24}>
            <Col md={{ span: 20, offset: 4 }}>
              <StrengthMeter passwordErrors={passwordErrors} />
            </Col>
          </Row>
        )}
      </div>
    </Drawer>
  );
}
ChangePasswordDrawer.defaultProps = {
  isVisible: false,
  setVisible: () => {},
};
ChangePasswordDrawer.propTypes = {
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
};
export default ChangePasswordDrawer;
