import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import chileFlag from 'assets/img/chile-flag.svg';
import colombiaFlag from 'assets/img/colombia-flag.svg';
import peruFlag from 'assets/img/peru-flag.svg';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext } from 'react';

const { Option } = Select;

const prefixPhones = [
  {
    value: '+56',
    title: 'Bandera de Chile',
    image: chileFlag,
  },
  {
    value: '+57',
    title: 'Bandera de Colombia',
    image: colombiaFlag,
  },
  {
    value: '+51',
    title: 'Bandera de Perú',
    image: peruFlag,
  },
];

function PhonesForm() {
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.location' };
  return (
    <Form.List name="phones">
      {(fields, { add, remove }, { errors }) => (
        <Space
          direction="vertical"
          style={{
            display: 'flex',
          }}
        >
          <Row justify="end">
            <Col span={24}>
              <Form.ErrorList errors={errors} />
            </Col>
          </Row>
          {fields.map(({ key, name, ...restField }, loadIndex) => {
            const { isListField } = restField;
            return (
              <Row justify="space-between" align="middle" key={key}>
                <Col span={22}>
                  <Form.Item label={i18n.t('phoneCount', { ...scopeI18n, count: loadIndex + 1 })}>
                    <Space.Compact>
                      <Form.Item
                        name={[name, 'prefix']}
                        rules={[
                          { required: loadIndex === 0 },
                          () => ({
                            validator(_, value) {
                              if (value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(i18n.t('rules.phonePrefix', scopeI18n))
                              );
                            },
                          }),
                        ]}
                        noStyle
                      >
                        <Select
                          style={{
                            width: 110,
                          }}
                        >
                          {prefixPhones.map((prePhone) => {
                            return (
                              <Option key={prePhone.value} value={prePhone.value}>
                                <Space>
                                  <img
                                    src={prePhone.image}
                                    alt={prePhone.title}
                                    title={prePhone.title}
                                    width="20px"
                                  />
                                  {prePhone.value}
                                </Space>
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        isListField={isListField}
                        name={[name, 'phone']}
                        rules={[
                          {
                            pattern: /^[0-9]{1,10}$/,
                            message: i18n.t('rules.phoneJustNumber', scopeI18n),
                          },
                          () => ({
                            validator(_, value) {
                              if (value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(i18n.t('rules.phone', scopeI18n)));
                            },
                          }),
                        ]}
                        noStyle
                      >
                        <Input
                          style={{
                            width: '60%',
                          }}
                          placeholder={i18n.t('phone', scopeI18n)}
                        />
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <FontAwesomeIcon
                    className="dynamic-delete-button"
                    icon={['fas', 'circle-minus']}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
            );
          })}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block>
              {i18n.t('buttons.addPhone')}
            </Button>
          </Form.Item>
        </Space>
      )}
    </Form.List>
  );
}

export default PhonesForm;
