import PropTypes from 'prop-types';
import DynamicFieldsPropTypes from '../../common/Form/DynamicFields/dynamicFields.propTypes';

export const DemandPropType = PropTypes.shape({
  id: PropTypes.number,
  addr: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    line2: PropTypes.string,
    number: PropTypes.string,
    street: PropTypes.string,
    province: PropTypes.string,
  }),
  source: PropTypes.string,
  demandDetails: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  contactId: PropTypes.string,
  contactName: PropTypes.string,
  contactPhones: PropTypes.arrayOf(PropTypes.shape({ phone: PropTypes.string })),
  contactEmails: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })),
  restrictions: DynamicFieldsPropTypes,
});

export const AddrFoundedPropType = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  city: PropTypes.string,
  country: PropTypes.string,
  line2: PropTypes.string,
  number: PropTypes.string,
  street: PropTypes.string,
  province: PropTypes.string,
  lat: PropTypes.string,
  lng: PropTypes.string,
});
