import apiSlice from 'app/api/apiSlice';

/**
 * georreferenceApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */
export const georreferenceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    geocode: builder.mutation({
      query: (params) => ({
        url: `/geoservices/geocode/`,
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['Georreference'],
    }),
    autocomplete: builder.mutation({
      query: (params) => ({
        url: `/geoservices/autocomplete/`,
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['Georreference'],
    }),
    reverseGeocode: builder.mutation({
      query: (params) => ({
        url: `/geoservices/reverse-geocode/`,
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['Georreference'],
    }),
  }),
});

export const { useGeocodeMutation, useAutocompleteMutation, useReverseGeocodeMutation } =
  georreferenceApiSlice;
