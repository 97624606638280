import { Col, Row, Skeleton } from 'antd';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import PageHeader from 'components/common/PageHeader';
import { useGetCategoriesPanelsQuery, useGetPanelQuery } from 'features/panels/panelsApiSlice';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PanelForm from '../Form';

function PanelEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [panel, setPanel] = useState();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const { data: panelData, isLoading } = useGetPanelQuery(id, {
    skip: id === undefined,
  });

  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    refetch: refetchCategories,
  } = useGetCategoriesPanelsQuery();

  useEffect(() => {
    if (!isLoadingCategories) {
      const uniqCategories = [...new Set(categoriesData)];
      const allCategories = uniqCategories.map((category) => ({
        label: category,
        value: category,
      }));
      setCategories(allCategories);
    }
    refetchCategories();
  }, [categoriesData, isLoading, isLoadingCategories, refetchCategories]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPanel(panelData);
        setLoading(isLoading);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [panelData, isLoading]);

  const onBack = () => {
    navigate(`/reports/panels/${id}`);
  };

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/reports',
      label: 'Reportes',
    },
    {
      key: 'panels',
      url: `/reports`,
      label: 'Paneles',
    },
    {
      key: 'panel-show',
      url: `/reports/panels/${id}`,
      label: 'Detalle',
    },
    {
      key: 'panel-edit',
      url: `/reports/panels/${id}/edit`,
      label: 'Editar panel',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <BreadcrumbComponent breadcrumbItems={breadcrumbItems} marginBottom="0px" />
        <PageHeader className="site-page-header" onBack={onBack} title="Editar panel" />
      </Col>
      <Col span={12}>
        <Skeleton active loading={loading}>
          <PanelForm panel={panel} categories={categories} />
        </Skeleton>
      </Col>
    </Row>
  );
}

export default PanelEdit;
