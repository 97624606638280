import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Flex, Image, List, Space, Tag, Typography } from 'antd';
import loginIcon from 'assets/img/login-icon.svg';
import { logOut } from 'features/auth/authSlice';
import { logOutCurrentUser } from 'features/users/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './style.css';

const { Text } = Typography;

function Plans() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToHome = () => {
    navigate('/organizations');
  };
  const handleLogout = async () => {
    dispatch(logOut());
    dispatch(logOutCurrentUser());
    navigate('/');
  };
  const handleOnClick = (value) => {
    console.log(value);
  };
  const basePlanTitle = () => {
    return (
      <>
        <Text className="basic-text">Todas las funcionalidades del</Text>{' '}
        <Text className="base-plan-title">Plan Base</Text>
      </>
    );
  };
  const mediumPlanTitle = () => {
    return (
      <>
        <Text className="basic-text">Todas las funcionalidades del</Text>{' '}
        <Text className="medium-plan-title">Plan Medium</Text>
      </>
    );
  };

  return (
    <>
      <Flex justify="space-between" align="flex-start">
        <Image
          src={loginIcon}
          alt="logo"
          title="Planners"
          preview={false}
          className="optimal-logo"
        />
        <Space>
          <Button type="default" onClick={goToHome}>
            Volver al home
          </Button>
          <Button type="default" onClick={handleLogout}>
            Cerrar Sesión
          </Button>
        </Space>
      </Flex>

      <div className="pricing-table-container">
        <Flex justify="center" align="center">
          <Space direction="vertical">
            <div className="pricing-header">
              <h2>Desata tu potencial logístico con nuestros exclusivos planes</h2>
            </div>

            <div className="pricing-sub-header">
              Selecciona el plan que mejor se adapte a tus necesidades logísticas
            </div>
          </Space>
        </Flex>
        <Flex justify="center" align="flex-end">
          <div className="pricing-table">
            {/* BASE */}
            <div className="table table-base">
              <span className="value" />

              <div className="content">
                <h3>Base</h3>

                <div className="description">Descubre una nueva era en gestión logística</div>

                <Button className="btn" onClick={() => handleOnClick('base')}>
                  Lo quiero
                </Button>

                <List
                  dataSource={[
                    'Ruteo optimizado por algoritmo The Optimal',
                    'Ruteo manual, ¡tú decides el camino!',
                    'Edita y personaliza puntos de entrega fácilmente',
                    'Asigna rutas a conductores y sigue sus entregas en tiempo real',
                    'Configura tu flota y puntos de entrega con sencillez',
                    'Notificaciones instantáneas cuando tus rutas están listas',
                    'Comunicación efectiva con clientes vía correo y SMS',
                    'Informes detallados de entregas y ubicación de vehículos',
                  ]}
                  renderItem={(item) => (
                    <List.Item className="plans-features-list">
                      <span className="table-features">
                        <FontAwesomeIcon icon={['fas', 'check']} /> {item}
                      </span>
                    </List.Item>
                  )}
                />
              </div>
            </div>

            {/* MEDIUM */}
            <div className="table table-medium">
              <span className="value" />

              <Flex justify="flex-end" align="flex-start">
                <Tag color="purple" className="most-popular-tag">
                  Más popular
                </Tag>
              </Flex>

              <div className="content">
                <h3>Medium</h3>

                <div className="description">Impulsa tu logística al siguiente nivel</div>

                <Button type="primary" className="btn" onClick={() => handleOnClick('medium')}>
                  Lo quiero
                </Button>

                <List
                  dataSource={[
                    basePlanTitle(),
                    'Reportes estándar en Excel para análisis detallados',
                    'Obtén insights y métricas clave para tomar decisiones informadas',
                    'Analiza patrones de entrega y rendimiento de flota de manera eficiente',
                  ]}
                  renderItem={(item) => (
                    <List.Item className="plans-features-list">
                      <span className="table-features">
                        <FontAwesomeIcon
                          icon={['fas', 'check']}
                          className="table-features-medium-check"
                        />{' '}
                        {item}
                      </span>
                    </List.Item>
                  )}
                />
              </div>
            </div>

            {/* FULL */}
            <div className="table table-full">
              <span className="value" />

              <div className="content">
                <h3>Full</h3>

                <div className="description">Lidera el juego logístico</div>

                <Button className="btn" onClick={() => handleOnClick('full')}>
                  Lo quiero
                </Button>

                <List
                  dataSource={[
                    mediumPlanTitle(),
                    'Reportes personalizados con Tableau para análisis avanzado',
                    'Visualiza datos de manera intuitiva y toma decisiones estratégicas',
                    'Personaliza informes según tus necesidades específicas',
                    'Desbloquea el potencial total de tu logística con análisis profundos',
                  ]}
                  renderItem={(item) => (
                    <List.Item className="plans-features-list">
                      <span className="table-features">
                        <FontAwesomeIcon
                          icon={['fas', 'check']}
                          className="table-features-full-check"
                        />{' '}
                        {item}
                      </span>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
        </Flex>
      </div>
    </>
  );
}

export default Plans;
