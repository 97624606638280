import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space, Tooltip } from 'antd';
import PropTypes from 'prop-types';

/**
 * Function to create component title with question mark as help
 * @param { * } props include title, helpText
 * @returns Text on title + icon question mark with helpText
 */
function TitleHelp(props) {
  const { title, helpText, useSolid, placement } = props;
  const classSolid = useSolid ? 'fas' : 'far';
  return (
    <Space>
      {title}
      <Tooltip title={helpText} placement={placement}>
        <FontAwesomeIcon icon={[classSolid, 'question-circle']} color="#999999" />
      </Tooltip>
    </Space>
  );
}

TitleHelp.defaultProps = {
  title: '',
  helpText: 'ayuda de ejemplo - cambiar',
  useSolid: true,
  placement: 'top',
};

TitleHelp.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  helpText: PropTypes.string,
  useSolid: PropTypes.bool,
  placement: PropTypes.string,
};

export default TitleHelp;
