import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Card, Col, Row, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ColorByOrder } from 'services/repeated-functions';
import { UserPropTypes, VehiclePropTypes } from '../assigment.propTypes';
import './style.css';

const { Paragraph } = Typography;

function InfoCard(props) {
  const { routeOrder, driver, assistant, vehicle } = props;
  const colorRoute = ColorByOrder(routeOrder);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'assigment' };

  const renderAvatar = () => (
    <Col span={4}>
      <Avatar
        size={36}
        icon={<FontAwesomeIcon icon={['fa', 'user']} />}
        alt={i18n.t('profile.altAvatar')}
        src={driver?.photo}
        style={{
          borderColor: colorRoute,
        }}
        className="ant-avatat-assign"
      />
    </Col>
  );

  const renderRoute = () => (
    <Paragraph
      style={{
        color: colorRoute,
      }}
      className="ant-typography-assign"
      strong
    >
      {i18n.t('routeNumber', { ...scopeI18n, number: routeOrder })}
    </Paragraph>
  );

  const renderDriver = () => (
    <Row>
      <Paragraph className="ant-typography-assign" disabled={!driver?.lastName}>
        {driver ? `${driver?.name} ${driver?.lastName}` : i18n.t('driver', scopeI18n)} /
      </Paragraph>
      <Paragraph
        style={{ marginLeft: 2 }}
        className="ant-typography-assign"
        disabled={!assistant?.lastName}
      >
        {assistant ? `${assistant?.name} ${assistant?.lastName}` : i18n.t('assistant', scopeI18n)}
      </Paragraph>
    </Row>
  );

  const renderVehicle = () => (
    <Paragraph className="ant-typography-assign" disabled={!vehicle?.plate}>
      {vehicle?.plate || i18n.t('commons.vehicle')}
    </Paragraph>
  );

  const renderInfo = () => (
    <Row>
      {renderAvatar()}
      <Col span={20}>
        {renderRoute()}
        {renderDriver()}
        {renderVehicle()}
      </Col>
    </Row>
  );

  return (
    <Card
      style={{
        borderLeftColor: colorRoute,
        borderLeftWidth: 6,
      }}
      className="ant-card-assign"
    >
      {renderInfo()}
    </Card>
  );
}

InfoCard.propTypes = {
  routeOrder: PropTypes.number,
  driver: UserPropTypes,
  assistant: UserPropTypes,
  vehicle: VehiclePropTypes,
};

InfoCard.defaultProps = {
  routeOrder: null,
  driver: [],
  assistant: [],
  vehicle: [],
};

export default InfoCard;
