import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Form, App } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGeocodeMutation } from 'features/georreference/georreferenceApiSlice';
import PointsContext from './context/PointsContext';

import './style.css';

function Georreference(props) {
  const { demandId } = props;
  const form = Form.useFormInstance();
  const { mapPoints, setMapPoints, onDrag, setMoveToPosition } = useContext(PointsContext);
  const [loading, setLoading] = useState(false);
  const [geoSuccessfull, setGeoSuccessful] = useState(true);
  const { message } = App.useApp();

  // custom hook
  const [geocode] = useGeocodeMutation();

  const changeLatLng = (newCoords, params) => {
    const objectId = demandId || params?.nid;
    const notFounded = mapPoints.filter((item) => item.key === objectId).length === 0;
    if (notFounded) {
      const obj = {
        key: objectId,
        lat: `${newCoords.destination_latitude}`,
        lng: `${newCoords.destination_longitude}`,
        Cod: params?.nid,
        Nombre: params?.name,
        usedRows: ['Cod', 'Nombre'],
        draggable: true,
        onDrag: (latLon) => {
          onDrag(latLon, objectId);
        },
      };
      setMapPoints((oldMapPoints) => [...oldMapPoints, obj]);
    } else {
      setMapPoints(
        [...mapPoints].map((object) => {
          if (object.key === objectId) {
            return {
              ...object,
              lat: `${newCoords.destination_latitude}`,
              lng: `${newCoords.destination_longitude}`,
              draggable: true,
              onDrag: (latLon) => {
                onDrag(latLon, objectId);
              },
            };
          }
          return object;
        })
      );
    }
    setMoveToPosition([newCoords.destination_latitude, newCoords.destination_longitude]);
  };

  const fetchData = async (params) => {
    const { street, number, city, country, province } = params.addr;
    setLoading(true);
    if (!street || !number || !city || !province) {
      setLoading(false);
      setGeoSuccessful(false);
      return;
    }

    const address = `${street} ${number},${city},${province},${country}`;
    const client = 'demands-editor';
    try {
      const result = await geocode({ address, client }).unwrap();
      if (result) {
        const latLng = {
          destination_latitude: result.latitude,
          destination_longitude: result.longitude,
        };
        form.setFieldsValue(latLng);
        changeLatLng(latLng, params);
        setLoading(false);
        setGeoSuccessful(true);
      } else {
        setLoading(false);
        setGeoSuccessful(false);
        console.error('Cannot get data: ', result);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getAddressLatLng = async (event) => {
    message.open({
      key: 'loading-msg',
      type: 'loading',
      content: 'Actualizando ubicación...',
      duration: 2,
    });
    const formValues = form.getFieldsValue();
    await fetchData(formValues);
    event.stopPropagation();
    message.destroy('loading-msg');
    if (geoSuccessfull) {
      message.success('La ubicación ha sido actualizada, recuerda guardar los cambios');
    }
  };

  return (
    <Tooltip title={geoSuccessfull ? 'Georreferenciar dirección' : 'Error al georreferenciar'}>
      <Button
        type="default"
        className="search-button"
        size="middle"
        onClick={getAddressLatLng}
        loading={loading}
        danger={!geoSuccessfull}
      >
        <FontAwesomeIcon icon={['fas', 'magnifying-glass-location']} />
      </Button>
    </Tooltip>
  );
}

Georreference.defaultProps = {
  demandId: undefined,
};

Georreference.propTypes = {
  demandId: PropTypes.number,
};

export default Georreference;
