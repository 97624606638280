import { App, Button, Checkbox, Col, Divider, Form, Input, Row, Space, Typography } from 'antd';
import SubmitButton from 'components/common/SubmitButton';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';

const { Text } = Typography;

function FormIntegrations(props) {
  const { initialValues, onFinish, savingData, submitButton } = props;
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const ability = useContext(CaslContext);
  const canSave = checkPermissionForSubject(ability, 'update', 'communication');

  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  const eventsRoute = [
    {
      label: 'Inicio de ruta',
      value: 'STARTED',
    },
    {
      label: 'Término de ruta',
      value: 'FINISHED',
    },
  ];

  const eventsItems = [
    {
      label: 'Llegué',
      value: 'ARRIVAL',
    },
    {
      label: 'Vuelvo más tarde',
      value: 'POSTPONED',
    },
    {
      label: 'Exitoso',
      value: 'SUCCESS',
    },
    {
      label: 'Parcial',
      value: 'PARTIAL',
    },
    {
      label: 'Fallido',
      value: 'FAILURE',
    },
  ];

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name="integrationsSettings"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      disabled={!canSave}
    >
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <Form.Item
            label="URL (endpoint) de envío de eventos"
            name="sendEventUrl"
            rules={[
              {
                type: 'url',
              },
              {
                type: 'string',
                min: 6,
              },
            ]}
          >
            <Input placeholder="https://sample-endpoint.com/register-event" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="statusForEvents">
            <Checkbox.Group style={{ width: '100%' }}>
              <Divider orientation="left" orientationMargin="0">
                <Text strong>Eventos de rutas</Text>
              </Divider>
              {eventsRoute.map(({ label, value }) => (
                <Checkbox
                  key={`eve-${value}`}
                  value={value}
                  style={{
                    lineHeight: '32px',
                    width: '100%',
                  }}
                >
                  {label}
                </Checkbox>
              ))}
              <Divider orientation="left" orientationMargin="0">
                <Text strong>Eventos de reporte</Text>
              </Divider>
              {eventsItems.map(({ label, value }) => (
                <Checkbox
                  key={`eve-${value}`}
                  value={value}
                  style={{
                    lineHeight: '32px',
                    width: '100%',
                  }}
                >
                  {label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            {/* is flow of creation */}
            {canSave && submitButton && <SubmitButton isLoading={savingData} />}
            {/* is normal flow of edition */}
            {canSave && !submitButton && (
              <Button type="primary" htmlType="submit" loading={savingData}>
                Guardar
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

FormIntegrations.propTypes = {
  initialValues: PropTypes.shape({
    sendEventUrl: PropTypes.string,
  }),
  onFinish: PropTypes.func,
  savingData: PropTypes.bool,
  submitButton: PropTypes.bool,
};

FormIntegrations.defaultProps = {
  initialValues: {
    sendEventUrl: undefined,
  },
  onFinish: () => {},
  savingData: false,
  submitButton: false,
};

export default FormIntegrations;
