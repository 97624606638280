import { App, Button, Cascader, Drawer, Form, Input, Select, Skeleton } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetRoutesByFiltersMutation } from 'features/routes/routesApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { FollowingRoutesProps, FollowingRoutingSet } from '../../following.propTypes';

function FollowingDrawerFilter(props) {
  const {
    routes,
    setAllRoutes,
    setAllItems,
    filterVisible,
    allRoutes,
    routingSet,
    routeStartFilter,
    setFilterVisible,
  } = props;
  const [form] = Form.useForm();
  const [dataFetched, setDataFetched] = useState(false);
  const [optionsCascader, setOptionsCascader] = useState([]);
  const [optionsDocuments, setOptionsDocuments] = useState([]);
  const [optionsDepots, setOptionsDepots] = useState([]);
  const [optionsDrivers, setOptionsDrivers] = useState([]);
  const [getRoutesByFilters] = useGetRoutesByFiltersMutation();
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const generateOptionsForSelect = (arrayOptions) => {
    const newArray = [...new Set(arrayOptions)] // remove duplicated
      .filter((option) => option) // remove undefined null
      .sort() // order asc
      .map((option) => ({
        value: option,
        label: option,
      }));
    return newArray;
  };
  const generateOptionsFromObject = (object, field) => {
    return Object.keys(object).map((objectId) => ({
      value: objectId,
      label: object[objectId][field],
    }));
  };

  useEffect(() => {
    const generateOptionsForCascader = (cascader) => {
      return Object.keys(cascader).map((routingSetId) => ({
        value: routingSetId,
        label: `CR ${routingSetId}`,
        children: cascader[routingSetId].map((route) => {
          const { id, routeOrder } = route;
          return {
            value: id,
            label: `Ruta ${routingSetId}-${routeOrder}`,
          };
        }),
      }));
    };
    if (routes.length > 0) {
      // build data to selects
      const filterObje = { cascader: {}, documents: [], depots: {}, drivers: {} };
      // initial routes only load cascader
      routes.forEach((route) => {
        if (filterObje.cascader[route.routingSetId] === undefined) {
          filterObje.cascader[route.routingSetId] = [route];
        } else {
          filterObje.cascader[route.routingSetId].push(route);
        }
      });
      // other filter was loaded with current data
      allRoutes.forEach((route) => {
        if (route?.depot) {
          const { depot } = route;
          filterObje.depots[depot.id] = depot;
        }
        if (route?.driver) {
          const { driver } = route;
          filterObje.drivers[driver.id] = driver;
        }
        route.items
          .filter(({ type }) => type === 'STOP')
          .forEach((item) => {
            filterObje.documents.push(item.document);
          });
      });
      // fill options
      const routesCascade = generateOptionsForCascader(filterObje.cascader);
      setOptionsCascader(routesCascade);
      const allDocs = generateOptionsForSelect(filterObje.documents);
      setOptionsDocuments(allDocs);
      const allDepots = generateOptionsFromObject(filterObje.depots, 'name');
      setOptionsDepots(allDepots);
      const allDrivers = generateOptionsFromObject(filterObje.drivers, 'fullName');
      setOptionsDrivers(allDrivers);
      setDataFetched(true);
    }
  }, [allRoutes, routes]);

  const clearFilter = () => {
    form.resetFields();
    form.submit();
  };

  const footer = (
    <div className="footer-drawer-buttons">
      <Button type="default" onClick={clearFilter} disabled={!dataFetched}>
        {i18n.t('clear', scopeI18n)}
      </Button>
      <Button
        type="primary"
        onClick={() => form.submit()}
        style={{ marginLeft: '1rem' }}
        disabled={!dataFetched}
      >
        {i18n.t('filter', scopeI18n)}
      </Button>
    </div>
  );

  const buildQuery = (values) => {
    let query = '';
    const fieldsForm = Object.keys(values);
    const fieldsAsQuery = fieldsForm.map((field) => {
      const valueField = values[field];
      if (valueField) {
        if (field === 'routeIds') {
          const routeIds = valueField?.map((route) => {
            return route[1];
          });
          if (routeIds.length > 0) {
            return `${field}=${routeIds}`;
          }
          return null;
        }
        return `${field}=${valueField}`;
      }
      return undefined;
    });
    query = fieldsAsQuery.filter((element) => element !== undefined).join('&');
    return query.length > 0 ? `?${query}` : '';
  };

  const onFinish = async (values) => {
    const query = buildQuery(values);
    try {
      let result = [];
      const queryRouteStartFilter = `startsAt=${routeStartFilter}`;
      let finalQuery =
        query === '' ? `?${queryRouteStartFilter}` : `${query}&${queryRouteStartFilter}`;
      if (routingSet) {
        const rsQeueryString = `routingSetId=${routingSet.id}`;
        finalQuery = query.length > 0 ? `${query}&${rsQeueryString}` : `?${rsQeueryString}`;
      }
      result = await getRoutesByFilters(finalQuery).unwrap();
      setAllRoutes(result);
      let allItemsLoaded = [];
      result.forEach((route) => {
        allItemsLoaded = [...allItemsLoaded, ...route.items];
      });
      setAllItems(allItemsLoaded);
      setFilterVisible(!filterVisible);
    } catch (error) {
      message.error(error);
    }
  };
  const onFinishFailed = () => {};

  const initialValues = {};
  const optionsShow = [
    {
      label: i18n.t('executedInDisorder', scopeI18n),
      value: 'executedInDisorder',
    },
    {
      label: i18n.t('delayedRoutes', scopeI18n),
      value: 'delayedRoutes',
    },
    {
      label: i18n.t('FAILURE', scopeI18n),
      value: 'FAILURE',
    },
    {
      label: i18n.t('PARTIAL', scopeI18n),
      value: 'PARTIAL',
    },
    {
      label: i18n.t('SUCCESS', scopeI18n),
      value: 'SUCCESS',
    },
  ];
  const optionsOrder = [
    {
      label: i18n.t('delayedRoutes1', scopeI18n),
      value: 'delayedRoutes',
    },
    {
      label: i18n.t('routesWithFailure', scopeI18n),
      value: 'routesWithFailure',
    },
  ];

  return (
    <Drawer
      title={i18n.t('filter', scopeI18n)}
      footer={footer}
      width="300px"
      open={filterVisible}
      destroyOnClose={false}
      maskClosable={false}
      closable={false}
      getContainer={false}
      rootStyle={{
        zIndex: 2000,
      }}
      className="filter-drawer"
    >
      {!dataFetched && <Skeleton active paragraph={{ rows: 10 }} />}
      {dataFetched && (
        <Form
          form={form}
          layout="vertical"
          validateMessages={{ required: 'Este campo es obligatorio' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Form.Item name="routeIds" label={i18n.t('route', scopeI18n)}>
            <Cascader
              placeholder={i18n.t('commons.select')}
              options={optionsCascader}
              multiple
              showCheckedStrategy={Cascader.SHOW_CHILD}
              popupClassName="cascader-drawer-following"
            />
          </Form.Item>
          <Form.Item name="client" label={i18n.t('client', scopeI18n)}>
            <Input placeholder={i18n.t('commons.typeHere')} />
          </Form.Item>
          <Form.Item name="document" label={i18n.t('document', scopeI18n)}>
            <Select
              placeholder={i18n.t('commons.select')}
              options={optionsDocuments}
              popupClassName="cascader-drawer-following"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="depotId" label={i18n.t('depot', scopeI18n)}>
            <Select
              placeholder={i18n.t('commons.select')}
              options={optionsDepots}
              popupClassName="cascader-drawer-following"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="driverId" label={i18n.t('driver', scopeI18n)}>
            <Select
              placeholder={i18n.t('commons.select')}
              options={optionsDrivers}
              popupClassName="cascader-drawer-following"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="show" label={i18n.t('show', scopeI18n)}>
            <Select
              placeholder={i18n.t('commons.select')}
              options={optionsShow}
              popupClassName="cascader-drawer-following"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="orderBy" label={i18n.t('orderBy', scopeI18n)}>
            <Select
              placeholder={i18n.t('commons.select')}
              options={optionsOrder}
              popupClassName="cascader-drawer-following"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Form>
      )}
    </Drawer>
  );
}
FollowingDrawerFilter.defaultProps = {
  routes: [],
  setAllRoutes: () => {},
  setAllItems: () => {},
  filterVisible: false,
  allRoutes: [],
  routingSet: undefined,
  routeStartFilter: ['2023-05-22', '2023-05-22'],
  setFilterVisible: () => {},
};

FollowingDrawerFilter.propTypes = {
  routes: FollowingRoutesProps,
  allRoutes: FollowingRoutesProps,
  setAllRoutes: PropTypes.func,
  setAllItems: PropTypes.func,
  filterVisible: PropTypes.bool,
  routingSet: FollowingRoutingSet,
  routeStartFilter: PropTypes.arrayOf(PropTypes.string),
  setFilterVisible: PropTypes.func,
};
export default FollowingDrawerFilter;
