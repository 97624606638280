import { textWithEllipsis } from 'services/repeated-functions';

const editionReportColumns = [
  {
    width: 120,
    title: 'Organización',
    dataIndex: 'organizationName',
    key: 'organizationName',
    transferKey: 'or.name',
    render: textWithEllipsis,
  },
  {
    width: 80,
    title: 'Zona horaria',
    dataIndex: 'organizationTimezone',
    key: 'organizationTimezone',
    transferKey: 'or.timezone',
  },
  {
    width: 100,
    title: 'Última edición',
    dataIndex: 'routingSetUpdatedAt',
    key: 'routingSetUpdatedAt',
    transferKey: 'rs.updatedAt',
  },
  {
    width: 120,
    title: 'Editado por',
    dataIndex: 'routingSetUpdatedBy',
    key: 'routingSetUpdatedBy',
    transferKey: 'up.name',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Email',
    dataIndex: 'updaterEmail',
    key: 'updaterEmail',
    transferKey: 'up.email',
  },
  {
    width: 120,
    title: 'Base',
    dataIndex: 'depotName',
    key: 'depotName',
    transferKey: 'de.name',
    render: textWithEllipsis,
  },
  {
    width: 120,
    title: 'Conjunto de rutas',
    dataIndex: 'routingSetName',
    key: 'routingSetName',
    transferKey: 'rs.name',
    render: textWithEllipsis,
  },
  {
    width: 100,
    title: 'Distancia total original',
    dataIndex: 'routingEngineSetTotalDistance',
    key: 'routingEngineSetTotalDistance',
    transferKey: 'rer.routeCost1',
  },
  {
    width: 100,
    title: 'Distancia total modificada',
    dataIndex: 'routingSetTotalDistance',
    key: 'routingSetTotalDistance',
    transferKey: 'rs.totalDistance',
  },
  {
    width: 100,
    title: 'Tiempo total original',
    dataIndex: 'routingEngineSetTotalTime',
    key: 'routingEngineSetTotalTime',
    transferKey: 'rer.routeCost',
  },
  {
    width: 100,
    title: 'Tiempo total modificado',
    dataIndex: 'routingSetTotalTime',
    key: 'routingSetTotalTime',
    transferKey: 'rs.travelTime',
  },
  {
    width: 100,
    title: 'Número de rutas original',
    dataIndex: 'routingEngineSetTotalRoutes',
    key: 'routingEngineSetTotalRoutes',
    transferKey: 'rer.id',
  },
  {
    width: 100,
    title: 'Número de rutas modificado',
    dataIndex: 'routingSetTotalRoutes',
    key: 'routingSetTotalRoutes',
    transferKey: 'rs.totalRoutes',
  },
  {
    width: 100,
    title: 'Número de paradas original',
    dataIndex: 'routingEngineSetTotalStops',
    key: 'routingEngineSetTotalStops',
    transferKey: 'rs.parametersRouting',
  },
  {
    width: 100,
    title: 'Número de paradas modificado',
    dataIndex: 'routingSetTotalStops',
    key: 'routingSetTotalStops',
    transferKey: 'ro.totalStops',
  },
  {
    width: 100,
    title: 'Restricciones infringidas',
    dataIndex: 'routingSetTotalRestrictions',
    key: 'routingSetTotalRestrictions',
    transferKey: 'rs.totalRestrictions',
  },
];
export default editionReportColumns;
