import PropTypes from 'prop-types';

export const RolesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    nid: PropTypes.string,
    name: PropTypes.string,
  })
);

export const UserPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  nationalNid: PropTypes.string,
  photo: PropTypes.string,
  deletedAt: PropTypes.string,
  username: PropTypes.string,
  roles: RolesPropTypes,
  userCognitoStatus: PropTypes.string,
  userCognitoEnabled: PropTypes.bool,
});

export const OrganizationsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  })
);
