import carIcon from 'assets/img/map/carToRight.svg';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { LeafletTrackingMarker } from 'react-leaflet-tracking-marker';
import { DEFAULT_TIMER } from 'services/repeated-functions';
import { coordsArrayPropTypes, pointShapePropTypes } from './LeafletMap.propType';
import PopupExtendedComponent from './PopupExtendedComponent';

function TrackingMarker(props) {
  const { index, lat, lng, prevCoords, drawablesRows } = props;
  const [prevPos, setPrevPos] = useState(prevCoords);

  const icon = L.icon({
    iconSize: [32, 19],
    popupAnchor: [2, -20],
    iconUrl: carIcon,
  });

  useEffect(() => {
    if (prevPos[1] !== lng && prevPos[0] !== lat) setPrevPos([lat, lng]);
  }, [lat, lng, prevCoords, prevPos]);

  const showPopup = drawablesRows.usedRows !== undefined;

  return (
    <LeafletTrackingMarker
      icon={icon}
      position={[lat, lng]}
      previousPosition={prevPos}
      duration={DEFAULT_TIMER}
    >
      {showPopup ? (
        <PopupExtendedComponent key={`pc-${index}`} drawablesRows={drawablesRows} />
      ) : null}
    </LeafletTrackingMarker>
  );
}

TrackingMarker.defaultProps = {
  lat: -33.42219427854345,
  lng: -70.61473298777823,
  prevCoords: [-33.423895646748136, -70.61300564519561],
  drawablesRows: {},
  index: 0,
};

TrackingMarker.propTypes = {
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prevCoords: coordsArrayPropTypes,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  drawablesRows: pointShapePropTypes,
};

export default TrackingMarker;
