import { Input } from 'antd';
import PropTypes from 'prop-types';
import { FieldDefinitionPropType, FormPropType } from '../tableFilter.propTypes';

function InputFilter(props) {
  const { fieldDefinition, handleChanges, allowClear, type, form, minStringChars } = props;
  const { name: nameField, label: labelField } = fieldDefinition;

  const customChange = (e) => {
    form.setFieldsValue({ [nameField]: e.target.value });
    if (type === 'text') {
      const currLength = e.target.value.length;
      if (currLength >= minStringChars || currLength === 0) {
        handleChanges(e);
      }
    } else {
      handleChanges(e);
    }
  };

  return (
    <Input
      name={nameField}
      id={nameField}
      placeholder={labelField}
      onChange={customChange}
      allowClear={allowClear}
      type={type}
      form={form}
    />
  );
}

InputFilter.defaultProps = {
  fieldDefinition: {},
  handleChanges: () => {
    console.warn('undefined handleChanges');
  },
  allowClear: true,
  type: 'text',
  form: {},
  minStringChars: 4,
};

InputFilter.propTypes = {
  fieldDefinition: FieldDefinitionPropType,
  handleChanges: PropTypes.func,
  allowClear: PropTypes.bool,
  type: PropTypes.string,
  form: FormPropType,
  minStringChars: PropTypes.number,
};

export default InputFilter;
