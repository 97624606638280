import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorBoundary from './layout/ErrorBoundary';

const { Content } = Layout;

function DemoLayout(props) {
  const { isOffline } = props;
  return (
    <Content>
      <ErrorBoundary offline={isOffline}>
        <div className="site-layout-content-secondary">
          <Outlet />
        </div>
      </ErrorBoundary>
    </Content>
  );
}
DemoLayout.propTypes = {
  isOffline: PropTypes.bool,
};

DemoLayout.defaultProps = {
  isOffline: true,
};

export default DemoLayout;
