import { Drawer, Form, Skeleton, Typography } from 'antd';
import FooterDrawer from 'components/common/FooterDrawer';
import LocaleContext from 'components/locale/LocaleContext';
import {
  useGetCategoriesDepotQuery,
  useGetCategoriesVisitQuery,
  useGetCustomAttributeLocationsDepotQuery,
  useGetCustomAttributeLocationsStopQuery,
  useGetGroupsDepotQuery,
  useGetGroupsVisitQuery,
} from 'features/locations/locationsApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { getTitleToDrawer } from 'services/repeated-functions';
import { LocationsPropTypes } from 'types';
import LocationForm from '../Form';
import LocationShow from '../Show';

const { Text } = Typography;

function LocationDrawer(props) {
  const {
    drawerVisible,
    setDrawerLocation,
    typeDrawer,
    location,
    locations,
    locationType,
    setTriggerClean,
    createdFrom,
  } = props;
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'locations' };

  // custom hooks
  const methodCaeQuery =
    locationType === 'DEPOT'
      ? useGetCustomAttributeLocationsDepotQuery
      : useGetCustomAttributeLocationsStopQuery;
  const { data: caeLocationsData, isLoading } = methodCaeQuery();

  const onClose = (event) => {
    event?.stopPropagation();
    setDrawerLocation(false);
  };

  const methodCategoriesQuery =
    locationType === 'DEPOT' ? useGetCategoriesDepotQuery : useGetCategoriesVisitQuery;
  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    refetch: refetchCategories,
  } = methodCategoriesQuery();

  const methodGroupsQuery =
    locationType === 'DEPOT' ? useGetGroupsDepotQuery : useGetGroupsVisitQuery;
  const { data: listGroups, isLoading: isLoadingGroups } = methodGroupsQuery();

  useEffect(() => {
    if (!isLoadingCategories) {
      const uniqCategories = [...new Set(categoriesData)];
      const allCategories = uniqCategories.map((category) => ({
        label: category,
        value: category,
      }));
      setCategories(allCategories);
      setLoading(isLoadingCategories || isLoadingGroups);
    }
    refetchCategories();
  }, [categoriesData, isLoadingCategories, isLoadingGroups, refetchCategories]);

  const entityName = i18n.t(locationType === 'DEPOT' ? 'depot' : 'visit', scopeI18n);
  const title = <Text strong>{getTitleToDrawer(typeDrawer, entityName, location.name, i18n)}</Text>;

  const footer = (
    <FooterDrawer
      onClose={onClose}
      typeDrawer={typeDrawer}
      form={form}
      entity={location}
      buttonLoading={buttonLoading}
    />
  );

  return (
    <Drawer
      title={title}
      footer={footer}
      width="70%"
      onClose={(event) => onClose(event)}
      open={drawerVisible}
      destroyOnClose
      maskClosable={false}
      closable={false}
    >
      {['create', 'edit'].includes(typeDrawer) ? (
        <Skeleton active loading={loading} paragraph={{ rows: 15 }}>
          <LocationForm
            onCloseDrawer={onClose}
            location={location}
            caeFields={isLoading ? [] : caeLocationsData}
            locations={locations}
            type={locationType}
            form={form}
            setButtonLoading={setButtonLoading}
            drawerVisible={drawerVisible}
            refetchCategories={refetchCategories}
            categories={categories}
            setTriggerClean={setTriggerClean}
            typeDrawer={typeDrawer}
            createdFrom={createdFrom}
            listGroups={listGroups}
          />
        </Skeleton>
      ) : (
        <LocationShow location={location} locations={locations} locationType={locationType} />
      )}
    </Drawer>
  );
}

LocationDrawer.propTypes = {
  drawerVisible: PropTypes.bool,
  setDrawerLocation: PropTypes.func,
  typeDrawer: PropTypes.string,
  location: LocationsPropTypes,
  locations: PropTypes.arrayOf(LocationsPropTypes),
  locationType: PropTypes.string,
  setTriggerClean: PropTypes.func,
  createdFrom: PropTypes.string,
};

LocationDrawer.defaultProps = {
  drawerVisible: false,
  typeDrawer: 'create',
  setDrawerLocation: () => {},
  location: {},
  locations: [],
  locationType: 'DEPOT',
  setTriggerClean: () => {},
  createdFrom: '',
};

export default LocationDrawer;
