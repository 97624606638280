import React from 'react';
import PropTypes from 'prop-types';
import TableFilters from 'components/common/TableFilter';
import { useGetDemandsFiltersMutation } from 'features/demands/demandsApiSlice';

function DemandSetsFilters(props) {
  const { setDemands, demandSetId } = props;

  // custom hooks
  const [getDemandsFilters] = useGetDemandsFiltersMutation();

  const fieldsFilter = [
    {
      name: 'nid',
      label: 'Código',
      type: 'text',
      minCharsToSearch: 2,
    },
    {
      name: 'name',
      label: 'Nombre',
      type: 'text',
    },
    {
      name: 'document',
      label: 'Nº documento',
      type: 'text',
      minCharsToSearch: 3,
    },
    {
      name: 'street',
      label: 'Calle',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Comuna',
      type: 'location_cascade',
    },
  ];

  return (
    <TableFilters
      setDataCallback={setDemands}
      fieldsFilter={fieldsFilter}
      showTitle={false}
      reduxFunc={getDemandsFilters}
      extraParams={`demand_set_id=${demandSetId}`}
    />
  );
}

DemandSetsFilters.defaultProps = {
  setDemands: () => {},
  demandSetId: 0,
};

DemandSetsFilters.propTypes = {
  setDemands: PropTypes.func,
  demandSetId: PropTypes.number,
};

export default DemandSetsFilters;
