import { Col, Divider, Row, Tooltip, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { CalculateDiffTimes, CalculateEta, FormatToTime } from 'services/repeated-functions';
import { FollowingItemProp } from '../../following.propTypes';

const { Text } = Typography;

function DetailItem(props) {
  const { item, timezone, routeStartedAt, items } = props;
  const {
    nid,
    contactName,
    contactPhones,
    contactEmails,
    departedAt,
    arrivedAt,
    arrivesAt,
    distance,
  } = item;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const renderHoursAndDifference = (real, planned) => {
    const { newTime, seconds } = CalculateDiffTimes(real, planned);
    const isPositive = seconds < 0;
    const classColor = isPositive ? 'positive' : 'negative';
    return <Text className={`color-${classColor}-text`}>{FormatToTime(newTime, timezone)}</Text>;
  };

  const hasArrivedDeparted = arrivedAt && departedAt;
  const { newTime: stoppedTime } = CalculateDiffTimes(departedAt, arrivedAt);

  const limitDistance = 700;
  const calculateDistance = (distanceReported) => {
    if (distanceReported) {
      const passLimitDistance = distanceReported > limitDistance;
      const colorClass = passLimitDistance ? 'negative' : 'positive';
      const suffixTitle = i18n.t(passLimitDistance ? 'overcome' : 'within', scopeI18n);
      return (
        <Text
          title={`${suffixTitle} ${i18n.t('distanceRange', scopeI18n)}`}
          className={`color-${colorClass}-text`}
        >
          {distanceReported}
        </Text>
      );
    }
    return '-';
  };
  const eta = CalculateEta(item, items, routeStartedAt, timezone, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <Text strong type="secondary">
            {nid}
          </Text>
        </Col>
      </Row>
      <Divider plain orientation="left" style={{ margin: '5px 0' }}>
        <Text type="secondary">{i18n.t('contactData', scopeI18n)}</Text>
      </Divider>
      <Row>
        <Col span={12}>{i18n.t('name', scopeI18n)}:</Col>
        <Col span={12}>
          <Tooltip title={contactName || '-'}>
            <Text ellipsis>{contactName || '-'}</Text>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col span={12}>{i18n.t('contactPhone', scopeI18n)}:</Col>
        <Col span={12}>{contactPhones[0]?.phone ? contactPhones[0].phone : '-'}</Col>
      </Row>
      <Row>
        <Col span={12}>{i18n.t('email', scopeI18n)}:</Col>
        <Col span={12}>
          <Tooltip title={contactEmails[0]?.email ? contactEmails[0].email : '-'}>
            <Text ellipsis>{contactEmails[0]?.email ? contactEmails[0].email : '-'}</Text>
          </Tooltip>
        </Col>
      </Row>
      <Divider plain orientation="left" style={{ margin: '5px 0' }}>
        <Text type="secondary">{i18n.t('time', scopeI18n)}</Text>
      </Divider>
      <Row>
        <Col span={12}>{i18n.t('diffTime', scopeI18n)}:</Col>
        <Col span={12}>{arrivedAt ? renderHoursAndDifference(arrivedAt, arrivesAt) : '-'}</Col>
      </Row>
      <Row>
        <Col span={12}>{i18n.t('estimatedTime', scopeI18n)}:</Col>
        <Col span={12}>{eta || '-'}</Col>
      </Row>
      <Row>
        <Col span={12}>{i18n.t('stopTime', scopeI18n)}:</Col>
        <Col span={12}>{hasArrivedDeparted ? FormatToTime(stoppedTime, timezone) : '-'}</Col>
      </Row>
      <Row>
        <Col span={12}>{i18n.t('outTime', scopeI18n)}:</Col>
        <Col span={12}>{departedAt ? FormatToTime(departedAt, timezone) : '-'}</Col>
      </Row>
      <Divider plain orientation="left" style={{ margin: '5px 0' }}>
        <Text type="secondary">{i18n.t('distance', scopeI18n)}</Text>
      </Divider>
      <Row>
        <Col span={12}>{i18n.t('reportDistance', scopeI18n)}:</Col>
        <Col span={12}>{calculateDistance(distance)}</Col>
      </Row>
    </>
  );
}

DetailItem.defaultProps = {
  item: undefined, // could be a empty object
  timezone: 'America/Santiago',
  routeStartedAt: undefined,
  items: [],
};

DetailItem.propTypes = {
  item: FollowingItemProp,
  timezone: PropTypes.string,
  routeStartedAt: PropTypes.string,
  items: PropTypes.arrayOf(FollowingItemProp),
};

export default DetailItem;
