import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { DataByRouteStatusTag } from 'services/repeated-functions';

function RouteStatus(props) {
  const { status } = props;
  const { borderColor, backgroundColor, color, text } = DataByRouteStatusTag(status);

  return (
    <Tag
      color={backgroundColor}
      style={{
        borderColor: borderColor,
        borderRadius: 2,
        textAlign: 'center',
        fontWeight: 400,
        width: 71,
        color: color,
      }}
    >
      {text}
    </Tag>
  );
}

RouteStatus.propTypes = {
  status: PropTypes.string,
};

RouteStatus.defaultProps = {
  status: '',
};

export default RouteStatus;
