import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button } from 'antd';
import PropTypes from 'prop-types';

function AlertMap(props) {
  const { alertVisible, message, hideButtonEvent } = props;

  const buttonHide = (
    <Button title="Ocultar" type="text" size="small" onClick={hideButtonEvent}>
      <FontAwesomeIcon icon={['fas', 'xmark']} />
    </Button>
  );

  if (alertVisible) {
    return (
      <Alert showIcon message={message} action={buttonHide} style={{ marginBottom: '1rem' }} />
    );
  }
  return undefined;
}

AlertMap.propTypes = {
  alertVisible: PropTypes.bool,
  hideButtonEvent: PropTypes.func,
  message: PropTypes.string,
};

AlertMap.defaultProps = {
  hideButtonEvent: () => {},
  message: '',
  alertVisible: true,
};

export default AlertMap;
