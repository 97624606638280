import { useEffect, useState } from 'react';
import { DefaultPasswordErrors } from './password-errors';

export default function usePasswordStrengthChecker() {
  const [passwordErrors, setPasswordErrors] = useState(DefaultPasswordErrors);
  const [hasPasswordErrors, setHasPasswordErrors] = useState(false);

  function addRemoveErrors(error, add) {
    setPasswordErrors((prevState) => {
      return {
        ...prevState,
        [error]: add,
      };
    });
  }

  useEffect(() => {
    const anyErrors = Object.values(passwordErrors).includes(true);
    setHasPasswordErrors(anyErrors);
  }, [passwordErrors]);

  function checkPasswordStrength(e) {
    const passwordValue = e.target.value;
    const passwordLength = passwordValue.length;
    const capitalLetterRegExp = /(?=.*?[A-Z])/;
    const containsDigitsRegExp = /(?=.*?[0-9])/;
    const specialCharacterRegExp = /[^\w\s]/;
    const lowerCaseRegExp = /(?=.*?[a-z])/;

    const capitalLetterResult = capitalLetterRegExp.test(passwordValue);
    const containsDigitsResult = containsDigitsRegExp.test(passwordValue);
    const specialCharacterResult = specialCharacterRegExp.test(passwordValue);
    const lowerCaseResult = lowerCaseRegExp.test(passwordValue);

    if (passwordValue !== '') {
      // Password minimum length
      addRemoveErrors('length', passwordLength < 8);
      // Contains at least 1 uppercase letter
      addRemoveErrors('uppercaseLetter', !capitalLetterResult);
      // Contains at least 1 lowercase letter
      addRemoveErrors('lowercaseLetter', !lowerCaseResult);
      // Contains at least 1 number
      addRemoveErrors('digit', !containsDigitsResult);
      // Contains at least 1 special character
      addRemoveErrors('specialCharacter', !specialCharacterResult);
    }
  }
  return [passwordErrors, checkPasswordStrength, hasPasswordErrors];
}
