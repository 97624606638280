import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space } from 'antd';
import PropTypes from 'prop-types';

import './style.css';

function PageHeader(props) {
  const { title, subtitle, extra, onBack } = props;
  return (
    <div className="ant-page-header padding-page-header ant-page-header-ghost">
      <div className="ant-page-header-heading">
        <div className="ant-page-header-heading-left">
          <div className="ant-page-header-back">
            <Button
              className="ant-page-header-back-button"
              type="link"
              icon={<FontAwesomeIcon icon={['fas', 'arrow-left']} />}
              onClick={onBack}
            />
            <span className="ant-page-header-heading-title" title="Title">
              {title}
            </span>
            {subtitle && (
              <span className="ant-page-header-heading-title-secondary">{subtitle}</span>
            )}
          </div>
        </div>
        <div className="ant-page-header-heading-extra">
          <Space align="end">{extra && extra}</Space>
        </div>
      </div>
    </div>
  );
}

PageHeader.defaultProps = {
  title: '',
  subtitle: undefined,
  extra: null,
  onBack: () => {},
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  onBack: PropTypes.func,
};

export default PageHeader;
