import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Popover,
  Progress,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import TableFilters from 'components/common/TableFilter';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetFilesProcessingByFiltersMutation } from 'features/documents/fileProcessingApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertDateToDayjs, GetUrl } from 'services/repeated-functions';
import DocumentsPropTypes from '../documents.propTypes';

function TableDocuments(props) {
  const { Text } = Typography;
  const { data, loading, setDataCallback, setDocument, totalData, setTotalData } = props;
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const [getFilesProcessingByFilters] = useGetFilesProcessingByFiltersMutation();
  const [downloadQuery, setDownloadQuery] = useState('');
  const [actualPage, setActualPage] = useState(1);
  const [cleanFilter] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'documents' };
  const urlCsv = 'file-processing/download/id';

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 1000);
  };

  const renderTag = (color) => (
    <Tag color={color} style={{ width: 12, height: 6, borderRadius: 10 }} />
  );

  const renderInformation = () => (
    <Card style={{ padding: 2, boxShadow: 'unset' }} bordered={false}>
      <p>
        {renderTag('#EAECEE')}
        <b>{i18n.t('statuses.pending', scopeI18n)}</b>: {i18n.t('statuses.pendingDesc', scopeI18n)}
      </p>
      <p>
        {renderTag('#D39E00')}
        <b>{i18n.t('statuses.inProgress', scopeI18n)}</b>:{' '}
        {i18n.t('statuses.inProgressDesc', scopeI18n)}
      </p>
      <p>
        {renderTag('#30BA00')}
        <b>{i18n.t('statuses.ready', scopeI18n)}</b>: {i18n.t('statuses.readyDesc', scopeI18n)}
      </p>
      <p>
        {renderTag('#E30001')}
        <b>{i18n.t('statuses.error', scopeI18n)}</b>: {i18n.t('statuses.errorDesc', scopeI18n)}
      </p>
    </Card>
  );

  const renderHelp = (title) => (
    <Space>
      <Text>{title}</Text>
      <Popover content={() => renderInformation()}>
        <FontAwesomeIcon icon="fa-solid fa-circle-question" color="#999999" />
      </Popover>
    </Space>
  );

  const renderStatusBar = (status) => {
    let percent = 100;
    let finalStatus;

    switch (status) {
      case 'SUCCESS':
        finalStatus = 'success';
        break;
      case 'PROCESSING':
        percent = 70;
        finalStatus = 'active';
        break;
      case 'FAILED':
        finalStatus = 'exception';
        break;
      default:
        percent = 0;
        finalStatus = 'active';
        break;
    }

    return <Progress percent={percent} status={finalStatus} showInfo={false} />;
  };

  const isImport = (type) => type === 'IMPORT';

  const renderType = (type) => (
    <Tag color={isImport(type) ? 'default' : 'processing'}>
      {i18n.t(isImport(type) ? 'import' : 'export', scopeI18n)}
    </Tag>
  );

  const renderModuleName = (module) => {
    let textToShow = module;

    // ToDo: add others path to write redirect
    // switch true work as an if with many else if
    switch (true) {
      case module.includes('planning/demand-sets'):
      case module === 'Demands':
      case module === 'planning':
        textToShow = i18n.t('modules.demands', scopeI18n);
        break;
      case module === 'Users':
        textToShow = i18n.t('modules.users', scopeI18n);
        break;
      case module === 'locations':
        textToShow = i18n.t('modules.visits', scopeI18n);
        break;
      case module === 'locations/base':
        textToShow = i18n.t('modules.depots', scopeI18n);
        break;
      case module === 'configurations/fleets':
        textToShow = i18n.t('modules.vehicles', scopeI18n);
        break;
      case module === 'reports/reports':
        textToShow = i18n.t('modules.reports', scopeI18n);
        break;
      default:
        break;
    }
    return textToShow;
  };

  const renderMenuActions = (file) => {
    const { module, status } = file;
    const urlRedirect = `/${module}`;
    // urls are disabled because are filled wrong
    const disabledUrl = ['Demands', 'Users'].includes(module);

    const menuItems = [
      {
        key: '1',
        disabled: disabledUrl,
        title: disabledUrl ? i18n.t('errors.showOrigin', scopeI18n) : '',
        label: (
          <a href={urlRedirect} rel="noreferrer">
            {i18n.t('showOrigin', scopeI18n)}
          </a>
        ),
      },
    ];

    // ToDo: maybe show error only for some role or permission
    if (status === 'FAILED') {
      menuItems.push({
        key: '2',
        label: i18n.t('errors.showError', scopeI18n),
      });
    }

    return menuItems;
  };

  const isDisabled = (status) => status === 'SUCCESS' || status === 'FAILED';

  const renderActions = (file) => {
    const { createdAt, id, status, type, expired } = file;
    const tooOld =
      convertDateToDayjs(createdAt, tzOrganization) <
      convertDateToDayjs(new Date(), tzOrganization).subtract(1, 'day');
    const cannotDownload = expired || status !== 'SUCCESS' || (tooOld && !isImport(type));
    let titleButton = '';
    if (expired) {
      titleButton = i18n.t('status.expired', scopeI18n);
    }
    if (tooOld && status === 'SUCCESS') {
      titleButton = i18n.t('status.tooOld', scopeI18n);
    }
    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          onClick={() => enterLoading(id)}
          disabled={cannotDownload}
          href={cannotDownload ? '#' : GetUrl(urlCsv.replace('id', id))}
          loading={loadings[id]}
          title={titleButton}
        >
          {i18n.t('commons.download')}
        </Button>
        <Dropdown
          menu={{
            items: renderMenuActions(file),
            onClick: () => {
              if (status !== 'SUCCESS') {
                setDocument(file);
              }
            },
          }}
          placement="bottomRight"
          disabled={!isDisabled(status)}
        >
          <Button style={{ marginLeft: '.6rem' }}>
            <FontAwesomeIcon icon="fa-solid fa-ellipsis" color="#999999" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'nid',
      key: 'nid',
      width: 110,
      render: (record) => <Text title={record}>{record.split('-').shift()}...</Text>,
      responsive: ['md'],
    },
    {
      title: i18n.t('commons.name'),
      dataIndex: 'name',
      key: 'name',
      width: 272,
    },
    {
      title: (
        <TitleHelp title={i18n.t('commons.type')} helpText={i18n.t('helps.type', scopeI18n)} />
      ),
      dataIndex: 'type',
      key: 'type',
      width: 118,
      render: (record) => renderType(record),
      responsive: ['md'],
    },
    {
      title: i18n.t('display', scopeI18n),
      dataIndex: 'module',
      key: 'module',
      width: 150,
      render: (record) => renderModuleName(record),
      responsive: ['md'],
    },
    {
      title: () => renderHelp(i18n.t('commons.status')),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (record) => renderStatusBar(record),
    },
    {
      title: i18n.t('commons.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 188,
      render: (date) => {
        return convertDateToDayjs(date, tzOrganization, 'DD-MM-YYYY HH:mm');
      },
      responsive: ['md'],
    },
    {
      key: 'action',
      width: 220,
      render: (record) => renderActions(record),
    },
  ];

  const fieldsFilter = [
    {
      name: 'name',
      label: i18n.t('commons.name'),
      type: 'text',
    },
    {
      name: 'type',
      label: i18n.t('commons.type'),
      type: 'select',
      options: [
        { value: 'IMPORT', label: i18n.t('import', scopeI18n) },
        { value: 'EXPORT', label: i18n.t('export', scopeI18n) },
      ],
    },
    {
      name: 'module',
      label: i18n.t('display', scopeI18n),
      type: 'select',
      options: [
        {
          value: 'planning/demand-sets,demands,planning',
          label: i18n.t('modules.demands', scopeI18n),
        },
        { value: 'locations', label: i18n.t('modules.visits', scopeI18n) },
        { value: 'locations/base', label: i18n.t('modules.depots', scopeI18n) },
        { value: 'users', label: i18n.t('modules.users', scopeI18n) },
        { value: 'reports/reports', label: i18n.t('modules.reports', scopeI18n) },
        { value: 'configurations/fleets', label: i18n.t('modules.vehicles', scopeI18n) },
      ],
    },
    {
      name: 'status',
      label: i18n.t('commons.status'),
      type: 'select',
      options: [
        { value: 'PENDING', label: i18n.t('statuses.pending', scopeI18n) },
        { value: 'PROCESSING', label: i18n.t('statuses.inProgress', scopeI18n) },
        { value: 'SUCCESS', label: i18n.t('statuses.ready', scopeI18n) },
        { value: 'FAILED', label: i18n.t('statuses.failed', scopeI18n) },
      ],
    },
  ];

  const renderTableFilters = () => (
    <TableFilters
      setDataCallback={setDataCallback}
      fieldsFilter={fieldsFilter}
      showTitle={false}
      triggerClean={cleanFilter}
      reduxFunc={getFilesProcessingByFilters}
      setDownloadQuery={setDownloadQuery}
      paginationData={{ setTotalData, setActualPage }}
    />
  );

  const onChangePage = async (page, pageSize) => {
    // include filter parameters
    const filterQuery = downloadQuery !== '' ? `&${downloadQuery.slice(1)}` : '';
    const queryString = `?page=${page}&take=${pageSize}${filterQuery}`;
    try {
      // setLoading(true);
      setActualPage(page);
      const { data: dataResponse, total } = await getFilesProcessingByFilters(queryString).unwrap();
      setTotalData(total);
      setDataCallback(dataResponse);
      // setLoading(false);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  return (
    <>
      {renderTableFilters()}
      <Row gutter={24} style={{ height: '100%' }} className="table-container">
        <Col flex="auto">
          <Table
            rowKey="nid"
            columns={columns}
            dataSource={data}
            loading={loading}
            scroll={{
              y: 410,
            }}
            pagination={{
              showTotal: (total) => `Total: ${total}`,
              total: totalData,
              showSizeChanger: true,
              onChange: onChangePage,
              current: actualPage,
            }}
          />
        </Col>
      </Row>
    </>
  );
}

TableDocuments.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(DocumentsPropTypes),
  setDataCallback: PropTypes.func,
  setDocument: PropTypes.func,
  totalData: PropTypes.number,
  setTotalData: PropTypes.func,
};

TableDocuments.defaultProps = {
  loading: true,
  data: [],
  setDataCallback: () => {},
  setDocument: () => {},
  setTotalData: () => {},
  totalData: 0,
};

export default TableDocuments;
