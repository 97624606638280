import Locations from 'components/common/Cascader/Locations';
import PropTypes from 'prop-types';
import { FieldDefinitionPropType, FormPropType } from '../tableFilter.propTypes';

const elementTypesGroup = {
  0: 'countries',
  1: 'provinces',
  2: 'cities',
  3: 'towns',
};

function CascadeFilter(props) {
  const { fieldDefinition, handleChanges, allowClear, form } = props;
  const { name: nameField, label: labelField } = fieldDefinition;

  const groupSelectedValues = (values) => {
    const groupedValues = { countries: [], provinces: [], cities: [], towns: [] };
    values.forEach((subarray) => {
      const searchedElement = subarray[subarray.length - 1];
      const searchedindex = subarray.length - 1;
      const elementTypeGroup = elementTypesGroup[searchedindex];
      groupedValues[elementTypeGroup].push(searchedElement);
    });
    return groupedValues;
  };

  const selectedValues = (selected) => {
    const grouped = groupSelectedValues(selected);
    form.setFieldsValue({ [nameField]: grouped });
    handleChanges();
  };

  return (
    <Locations
      isMultiple
      placeholder={labelField}
      customOnChange={(selected) => selectedValues(selected)}
      allowClear={allowClear}
    />
  );
}

CascadeFilter.defaultProps = {
  fieldDefinition: {},
  handleChanges: () => {
    console.warn('undefined handleChanges');
  },
  allowClear: true,
  form: {},
};

CascadeFilter.propTypes = {
  fieldDefinition: FieldDefinitionPropType,
  handleChanges: PropTypes.func,
  allowClear: PropTypes.bool,
  form: FormPropType,
};

export default CascadeFilter;
