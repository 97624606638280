import { Col, Row, Typography } from 'antd';
import { Popup } from 'react-leaflet';
import { pointShapePropTypes } from './LeafletMap.propType';

const { Text } = Typography;

function PopupComponent(props) {
  const { drawablesRows } = props;
  const { usedRows = Object.keys(drawablesRows) } = drawablesRows;

  return (
    <Popup key={`c-${drawablesRows.key}`} closeButton={false}>
      <div className="popup-content-container">
        {Object.keys(drawablesRows)
          .filter((key_) => usedRows.includes(key_))
          .map((key) => (
            <Row key={`r${key}c-${drawablesRows.key}`}>
              <Col span={10}>
                <Text strong>{key}:</Text>
              </Col>
              <Col span={14}>
                <Text>{drawablesRows[key]}</Text>
              </Col>
            </Row>
          ))}
      </div>
    </Popup>
  );
}

PopupComponent.defaultProps = {
  drawablesRows: {},
};

PopupComponent.propTypes = {
  drawablesRows: pointShapePropTypes,
};

export default PopupComponent;
