import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is idle
 * @param callback - function to be executed after idle
 */
const useIdleTimeout = ({ onIdle, idleTime = 1, callback = () => {} }) => {
  const idleTimeout = 1000 * idleTime;
  const fiveMinutes = 1000 * 300;
  // in case idle is minor of 5 minutes sustr 1 milsecond
  const promptBeforeIdle = fiveMinutes > idleTimeout ? idleTimeout - 1 : fiveMinutes; // tiene que ser 5 minutos antes
  const [remaining, setRemaining] = useState(idleTimeout);
  const [isIdle, setIdle] = useState(false);
  const [timerIsDisabled, setDisableTimer] = useState(false);

  const millisToMinutesAndSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleIdle = () => {
    setIdle(true);
    setRemaining(0);
    callback();
  };

  const onPrompt = () => {
    onIdle();
    setRemaining(promptBeforeIdle);
  };

  const { getRemainingTime, activate, isPrompted } = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle,
    onPrompt,
    onIdle: handleIdle,
    debounce: 1000,
    disabled: timerIsDisabled,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      // only do if is promp drawed
      if (isPrompted()) {
        setRemaining(millisToMinutesAndSeconds(getRemainingTime()));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return {
    isIdle,
    setIdle,
    activate,
    setDisableTimer,
    remaining,
  };
};

export default useIdleTimeout;
