import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Col, Form, Input, InputNumber, Radio, Row, Space, Tooltip } from 'antd';
import SubmitButton from 'components/common/SubmitButton';
import TitleHelp from 'components/common/TitleHelp';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { addDaysToDate, convertDateToDayjs } from 'services/repeated-functions';

const MIN_DEMO_DAYS = 1;
const MAX_DEMO_DAYS = 30;
const DEFAULT_DEMO_DAYS = 15;
function FormModule(props) {
  const {
    initialValues,
    onFinish,
    savingData,
    submitButton,
    canSave,
    organizationCreatedAt,
    tzOrganization,
  } = props;
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [selectedPlan, setSelectedPlan] = useState(initialValues?.purchasedPlan);
  const demoFinishDateFormatted = convertDateToDayjs(
    initialValues?.demoFinishDate,
    tzOrganization,
    'DD-MM-YYYY HH:mm:ss'
  );

  useEffect(() => {
    if (organizationCreatedAt) {
      const days = initialValues?.demoDays ? initialValues?.demoDays : DEFAULT_DEMO_DAYS;
      const demoFinishDate = addDaysToDate(organizationCreatedAt, days);
      form.setFieldsValue({ demoFinishDate });
    }
  }, [form, initialValues?.demoDays, organizationCreatedAt]);

  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  const plans = [
    { label: 'Demo', value: 'demo' },
    { label: 'Base', value: 'base' },
    { label: 'Medium', value: 'medium' },
    { label: 'Full', value: 'full' },
  ];

  const onChangeDemoDays = ({ target }) => {
    const demoFinishDate = addDaysToDate(organizationCreatedAt, target?.value);
    form.setFieldsValue({ demoFinishDate });
  };

  const onChange = ({ target }) => {
    setSelectedPlan(target?.value);
  };

  return (
    <Form
      form={form}
      initialValues={{ ...initialValues, ...(!initialValues?.demoDays && { demoDays: 15 }) }}
      name="organizationModule"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      disabled={!canSave}
    >
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <TitleHelp
                title="Módulo"
                helpText="Paquete de servicios contratados, puede encontrar una especificación en: Nuestros planes"
              />
            }
            name="purchasedPlan"
            rules={[
              {
                required: true,
                message: 'Por favor ingresar módulo',
              },
            ]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {plans.map(({ label, value }) => {
                  return (
                    <span key={`input-container-${value}`}>
                      <Radio
                        key={value}
                        value={value}
                        onChange={onChange}
                        disabled={
                          (value === 'demo' &&
                            initialValues?.purchasedPlan &&
                            initialValues?.purchasedPlan !== 'demo') ||
                          !canSave
                        }
                      >
                        {label}
                        {value === 'demo' && initialValues?.demoFinishDate && (
                          <span style={{ marginLeft: '5px' }}>
                            <Tooltip
                              title={`Fecha fin de demo: ${demoFinishDateFormatted}`}
                              className="tooltip-information"
                            >
                              <FontAwesomeIcon icon={['fas', 'circle-info']} color="#999999" />
                            </Tooltip>
                          </span>
                        )}
                      </Radio>
                      {value === 'demo' && selectedPlan === 'demo' && (
                        <>
                          <Form.Item
                            key={`days-${value}`}
                            name="demoDays"
                            rules={[
                              {
                                required: true,
                                message: 'Por favor ingresar Día(s) gratuito(s)',
                              },
                              () => ({
                                validator(_, demoDaysValue) {
                                  if (demoDaysValue >= initialValues?.demoDays) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      'El número de días no puede ser menor al ya configurado'
                                    )
                                  );
                                },
                              }),
                            ]}
                            extra={`El rango permitido para el número de días va desde ${MIN_DEMO_DAYS} hasta ${MAX_DEMO_DAYS} días`}
                            style={{ marginBottom: '0px' }}
                            onChange={onChangeDemoDays}
                          >
                            <InputNumber
                              min={MIN_DEMO_DAYS}
                              max={MAX_DEMO_DAYS}
                              addonAfter="Día(s) gratuito(s)"
                              style={{ width: '200px' }}
                              controls={false}
                            />
                          </Form.Item>
                          <Form.Item name="demoFinishDate" hidden>
                            <Input name="demoFinishDate" />
                          </Form.Item>
                        </>
                      )}
                    </span>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            {/* is flow of creation */}
            {canSave && submitButton && <SubmitButton isLoading={savingData} />}
            {/* is normal flow of edition */}
            {canSave && !submitButton && (
              <Button type="primary" htmlType="submit" loading={savingData}>
                Guardar
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

FormModule.propTypes = {
  initialValues: PropTypes.shape({
    purchasedPlan: PropTypes.string,
    demoDays: PropTypes.number,
    demoFinishDate: PropTypes.string,
  }),
  onFinish: PropTypes.func,
  savingData: PropTypes.bool,
  submitButton: PropTypes.bool,
  canSave: PropTypes.bool,
  organizationCreatedAt: PropTypes.string,
  tzOrganization: PropTypes.string,
};

FormModule.defaultProps = {
  initialValues: {
    purchasedPlan: 'basic',
  },
  onFinish: () => {},
  savingData: false,
  submitButton: false,
  canSave: true,
  organizationCreatedAt: '',
  tzOrganization: '',
};

export default FormModule;
