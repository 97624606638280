import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { ConvertToMinutes } from 'services/repeated-functions';
import { useSelector } from 'react-redux';
import { selectCurrentOrganization } from 'features/users/userSlice';

// Returns the calculated elements containing all the time ticks & day text and limits to it's movement
// It is expected to send the result to HeaderDraw
function HeaderDrawDay(props) {
  const {
    startTime,
    endTime,
    ticksShown,
    pxOnTick,
    hSpaceDrawBorder,
    dayTextLeftBuffer,
    dayTextRightBuffer,
    minimalTick,
    minimalTickReference,
    hScrollDayText,
    colDataWidth,
    colDrawWidth,
    vSpaceHeader,
  } = props;

  const { timezone } = useSelector(selectCurrentOrganization);

  // Maximum column width (in px, number) that the current time line will have to move through.
  const svgWidth = ticksShown * pxOnTick + hSpaceDrawBorder * 2 || 0;

  const startsAt = dayjs(startTime).tz(timezone);
  const endsAt = dayjs(endTime).tz(timezone);

  // Vars for the day text in the time timeTicks
  const daysBtwnStartEnd = startsAt.isSame(endsAt, 'day') ? 1 : endsAt.diff(startsAt, 'days') + 1;

  const daysText = [];
  const options = { weekday: 'long', day: 'numeric', month: 'short' }; // add  year: 'numeric' to option var so the year can be visible
  for (let i = 0; i < daysBtwnStartEnd; i += 1) {
    const day = new Date(startTime);
    day.setDate(day.getDate() + i);
    daysText.push(day.toLocaleString('es-CL', options)); // en-US is default i guess
  }

  let lastHour = '';
  const daysTextInitial = [];
  for (let i = 0; i <= ticksShown; i += 1) {
    const tickTime = dayjs(startTime).add(
      ConvertToMinutes(minimalTick, minimalTickReference) * i,
      'minutes'
    );
    const hours = tickTime.format('HH');

    if (lastHour === '') {
      daysTextInitial.push([daysText[0], dayTextLeftBuffer]);
      daysText.shift();
    }
    if (daysBtwnStartEnd > 1 && hours === '00' && lastHour !== '00') {
      if (daysText.length >= 1) {
        daysTextInitial.push([daysText[0], hSpaceDrawBorder + pxOnTick * i + dayTextLeftBuffer]);
        daysText.shift();
      }
    }
    lastHour = hours;
  }

  const daysTextPosition = daysTextInitial.map((dayValues, index) => {
    const nextValue =
      (daysTextInitial[index + 1] ? daysTextInitial[index + 1][1] : svgWidth) - dayTextRightBuffer;
    return [dayValues[0], dayValues[1], nextValue];
  });

  const centerView = colDrawWidth / 3;
  const dayTextElements = [];

  daysTextPosition.forEach((dayData, index) => {
    let xValue = 0;
    if (centerView + hSpaceDrawBorder > dayData[2]) {
      // if is fully behind the scroll area
      xValue = -1 * (colDataWidth + colDrawWidth);
    }
    if (centerView + hSpaceDrawBorder < dayData[1]) {
      // if it is fully beyond the scroll area
      xValue = colDataWidth + colDrawWidth;
    }

    const textStyle = {
      position: 'absolute',
      top: vSpaceHeader / 4,
      zIndex: 3,
      width: colDataWidth + colDrawWidth,
      display: 'flex',
      margin: 'auto',
      textAlign: 'center',
      justifyContent: 'center',
      transform: `translateX(${xValue}px)`,
    };

    dayTextElements.push(
      <div key={`dayElement-${index + 1}`} id={`dayElement-${index + 1}`} style={textStyle}>
        <p style={{ color: '#808080' }}>{dayData[0]}</p>
      </div>
    );
  });

  useEffect(() => {
    const currentView = hScrollDayText + centerView + hSpaceDrawBorder;
    const fullSvgWidth = colDataWidth + colDrawWidth;
    const bufferWidth = fullSvgWidth / 4;

    daysTextPosition.forEach((dayData, index) => {
      const dayElement = document.getElementById(`dayElement-${index + 1}`);

      const bufferBefore = daysTextPosition[index - 1] ? daysTextPosition[index - 1][2] : 0;
      const bufferAfter = daysTextPosition[index + 1] ? daysTextPosition[index + 1][1] : svgWidth;

      if (dayData[2] < currentView && bufferAfter > currentView) {
        // currentView is between the right buffer and the end of the day space. It's sended to a left buffer
        dayElement.style.transition = 'transform 1s ease-in';
        dayElement.style.transform = `translateX(-${bufferWidth}px)`;
      }
      if (currentView < dayData[1] && currentView > bufferBefore) {
        // currentView is beteeen the left buffer and the init of the day space. It's sended to a right buffer
        dayElement.style.transition = 'transform 1s ease-in';
        dayElement.style.transform = `translateX(${bufferWidth}px)`;
      }
      if (bufferAfter < currentView && dayData[2] < currentView) {
        // currentView is fully outside the left buffer area & the end of the day space. It's sended out of bounds
        dayElement.style.transition = 'transform 1s ease-in';
        dayElement.style.transform = `translateX(-${fullSvgWidth}px)`;
      }
      if (currentView < bufferBefore && currentView < dayData[1]) {
        // currentView is fully outside the right buffer area & the init of the day space. It's sended out of bounds
        dayElement.style.transition = 'transform 1s ease-in';
        dayElement.style.transform = `translateX(${fullSvgWidth}px)`;
      }
      if (dayData[1] <= currentView && currentView <= dayData[2]) {
        // currentView is between the init of the day space & it's end. It's sended to the center screen.
        dayElement.style.transition = 'transform 0.5s linear';
        dayElement.style.transform = `translateX(0px)`;
      }
    });
  }, [
    hScrollDayText,
    colDataWidth,
    colDrawWidth,
    svgWidth,
    centerView,
    hSpaceDrawBorder,
    daysTextPosition,
  ]);

  return dayTextElements;
}
HeaderDrawDay.defaultProps = {
  startTime: dayjs(), // Start Time, in date format.
  endTime: dayjs(), // End Time, in date format.
  minimalTick: 15, // amount. Time reference for each time tick.
  minimalTickReference: 'minutes', // string. Set for time convertion.
  ticksShown: 30, // pixels. Number of ticks to be rendered.
  hSpaceDrawBorder: 40, // pixels. Horizontal space between the time rule, consider that the value must be added at the start and end.
  pxOnTick: 40, // pixels. Width of the space between two ticks.
  dayTextLeftBuffer: 50, // pixels. left px space for the day text
  dayTextRightBuffer: 200, // pixels. right px space for the day text
  hScrollDayText: 0, // pixels. horizontal scroll of the draw area.
  colDataWidth: 0, // pixels. Defines the width of the data column.
  colDrawWidth: 0, // pixels. Defines the width of the draw column.
  vSpaceHeader: 30, // pixels. Vertical space between main top lines.
};
HeaderDrawDay.propTypes = {
  startTime: PropTypes.instanceOf(dayjs),
  endTime: PropTypes.instanceOf(dayjs),
  minimalTick: PropTypes.number,
  minimalTickReference: PropTypes.string,
  ticksShown: PropTypes.number,
  pxOnTick: PropTypes.number,
  hSpaceDrawBorder: PropTypes.number,
  dayTextLeftBuffer: PropTypes.number,
  dayTextRightBuffer: PropTypes.number,
  hScrollDayText: PropTypes.number,
  colDataWidth: PropTypes.number,
  colDrawWidth: PropTypes.number,
  vSpaceHeader: PropTypes.number,
};

export default HeaderDrawDay;
