import { Button, Form } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function SubmitButton(props) {
  const { isLoading, submitButtonText } = props;
  const form = Form.useFormInstance();
  const [submittable, setSubmittable] = useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  useEffect(() => {
    const getFormValidations = async () => {
      await form
        .validateFields()
        .then(() => {
          setSubmittable(true);
        })
        .catch(({ errorFields }) => {
          if (!isEmpty(errorFields)) {
            setSubmittable(false);
          }
        });
    };
    getFormValidations();
  }, [form, values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>
      {submitButtonText}
    </Button>
  );
}

SubmitButton.defaultProps = {
  isLoading: false,
  submitButtonText: 'Siguiente',
};
SubmitButton.propTypes = {
  isLoading: PropTypes.bool,
  submitButtonText: PropTypes.string,
};

export default SubmitButton;
