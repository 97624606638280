import PropTypes from 'prop-types';
import { App, Button, Col, Form, Input, Row, Upload } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreatePanelMutation, useUpdatePanelMutation } from 'features/panels/panelsApiSlice';
import SelectPlusForm from 'components/common/SelectPlusForm';
import { PanelPropTypes } from '../panels.propTypes';

const acceptedFormats = ['png', 'jpeg', 'jpg'];

function PanelForm(props) {
  const { categories, panel } = props;
  const [imageUrl, setImageUrl] = useState(panel?.signedUrl);
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const fakeFile = {
    uid: '-1',
    name: 'panel.png',
    status: 'done',
    url: imageUrl,
  };
  const [fileList, setFileList] = useState(panel.id ? [fakeFile] : []);
  const navigate = useNavigate();
  const { message } = App.useApp();

  // custom hooks
  const [createPanel] = useCreatePanelMutation();
  const [updatePanel] = useUpdatePanelMutation();

  let initialValues = {};
  if (panel.id) {
    initialValues = { ...panel };
  }
  const onFinish = async (values) => {
    setButtonLoading(true);
    form.validateFields();
    try {
      const { image } = values;
      const body = {
        ...values,
      };
      if (panel.id) {
        await updatePanel({ panelId: panel.id, image, body }).unwrap();
        // redirect to list
      } else {
        await createPanel({ image, body }).unwrap();
      }
      message.success('Formulario enviado correctamente');
      navigate('/reports');
    } catch (error) {
      message.error('Formulario con errores, revisar');
      console.error(error);
    }
    setButtonLoading(false);
  };
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  // upload methods
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const onRemove = () => {
    setImageUrl();
  };
  const handleChange = (info) => {
    setFileList(info.fileList);
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
    });
  };
  const dummyRequest = async (options) => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const beforeUpload = (file) => {
    const isAccepted = acceptedFormats.includes(file.name.split('.').pop());
    if (!isAccepted) {
      message.error(`${file.name} no tiene formato válido (${acceptedFormats.join(', ')})`);
      onRemove();
    }
    return isAccepted;
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      validateMessages={{ required: 'Este campo es obligatorio' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={initialValues}
    >
      <Row gutter={24}>
        <Col span={24}>
          <SelectPlusForm
            spanLabel={4}
            spanWrapper={20}
            form={form}
            nameField="category"
            selected={panel.category}
            label="Categoría"
            baseItems={categories.map(({ label }) => label)}
          />
        </Col>
        <Col span={24}>
          <Form.Item
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 20,
            }}
            label="Titulo"
            name="title"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Titulo" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 20,
            }}
            label="Descripción"
            name="description"
            required
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder="Descripción" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 20,
            }}
            label="Widget code"
            name="widgetCode"
            required
            rules={[
              {
                required: true,
              },
              {
                type: 'url',
              },
              {
                type: 'string',
                min: 6,
              },
            ]}
          >
            <Input placeholder="Widget code" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 20,
            }}
            name="image"
            label="Imagen"
            required
            rules={[{ required: true }]}
            getValueFromEvent={({ file }) => file.originFileObj}
          >
            <Upload
              listType="picture-card"
              name="image"
              maxCount={1}
              accept=".png,.jpeg,.jpg"
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onRemove={onRemove}
              customRequest={dummyRequest}
            >
              + Subir
            </Upload>
          </Form.Item>
        </Col>
        <Col span={4} offset={20}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={buttonLoading}>
              {panel.id ? 'Guardar' : 'Crear'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

PanelForm.defaultProps = {
  categories: [],
  panel: {},
};

PanelForm.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  panel: PanelPropTypes,
};

export default PanelForm;
