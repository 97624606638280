import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Col, Tooltip } from 'antd';
import logoTOPlanners from 'assets/img/logo-TO-planners.svg';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentUser } from 'features/users/userSlice';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * Shows organization's logo
 * @returns logo
 */
function OrganizationLogo() {
  const { organizations, organization } = useSelector(selectCurrentUser);
  const defaultLogo = logoTOPlanners;
  const enableRibbon = organizations.length > 1;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  const textRibbon = <FontAwesomeIcon icon={['fa', 'rotate']} />;

  const imageLogo = (
    <img
      className="image-logo"
      src={organization?.logoUrl || defaultLogo}
      alt={i18n.t('commons.clientLogo', { name: organization?.name })}
    />
  );

  return (
    <Col xs={0} md={24}>
      <Tooltip title={i18n.t('commons.goToOrganizations')} arrow={false}>
        <div className="client-logo-container">
          <Link className="" to="/organizations">
            {enableRibbon ? (
              <Badge.Ribbon text={textRibbon} placement="start" color="magenta">
                {imageLogo}
              </Badge.Ribbon>
            ) : (
              imageLogo
            )}
          </Link>
        </div>
      </Tooltip>
    </Col>
  );
}

export default OrganizationLogo;
