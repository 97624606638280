import { Col, Form, Input, Switch } from 'antd';
import TitleHelp from 'components/common/TitleHelp';
import PropTypes from 'prop-types';

function RoutingSettingsInputs(props) {
  const { entityType, onChange } = props;
  const form = Form.useFormInstance();
  return (
    <Form.List name={entityType} key={entityType}>
      {(fields) => {
        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const { isListField } = restField;
              return (
                <Col span={8} key={key}>
                  <Form.Item name={[name, 'key']} hidden noStyle>
                    <Input />
                  </Form.Item>
                  <Form.Item name={[name, 'name']} hidden noStyle>
                    <Input />
                  </Form.Item>
                  <Form.Item name={[name, 'label']} hidden noStyle>
                    <Input />
                  </Form.Item>
                  <Form.Item name={[name, 'description']} hidden noStyle>
                    <Input />
                  </Form.Item>
                  <Form.Item name={[name, 'type']} hidden noStyle>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    isListField={isListField}
                    name={[name, 'enabled']}
                    label={
                      <TitleHelp
                        title={form.getFieldValue([entityType, name, 'label'])}
                        helpText={form.getFieldValue([entityType, name, 'description'])}
                      />
                    }
                    valuePropName="checked"
                  >
                    <Switch
                      onChange={(valueSwitch) => onChange(valueSwitch, [entityType, name, 'name'])}
                    />
                  </Form.Item>
                </Col>
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
}

RoutingSettingsInputs.propTypes = {
  entityType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RoutingSettingsInputs;
