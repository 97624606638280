import {
  App,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import AddonAfterSelect from 'components/common/AddonAfterSelect';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import { useRouteMutation } from 'features/routingSets/routingSetsApiSlice';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { formType } from 'types';
import './style.css';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

function DemandParametrizationForm(props) {
  const {
    id: demandSetId,
    form,
    defaultAction,
    generalRoutingSetting,
    targetsRoute,
    setButtonLoading,
    setVisible,
  } = props;
  const {
    maxWaitingCost: maxWaitingCostFromSetting,
    enableMaxWaitingCost,
    enableDeliveryDemandCostAndRates,
    enableFixedDeliveryStopCost,
    enableDeliveryCostPerLoadUnit,
    deliveryDemandCostAndRates,
    fixedDeliveryStopCost,
    deliveryCostPerLoadUnit,
    maxWaitingCostUnit,
    deliveryDemandCostAndRatesUnit,
    fixedDeliveryStopCostUnit,
    deliveryCostPerLoadUnitUnit,
    geoserviceSpeedWeight,
    profileLabel,
    defaultVehicleLoads,
    loadsUnits,
    showInParameterize,
  } = generalRoutingSetting;
  const [dates, setDates] = useState(null);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  const optionsTimes = [
    { value: 'seconds', label: i18n.t('commons.seconds') },
    { value: 'minutes', label: i18n.t('commons.minutes') },
  ];

  // custom hooks
  const [route, { isLoading }] = useRouteMutation();

  useEffect(() => {
    setButtonLoading(isLoading);
  }, [isLoading, setButtonLoading]);

  const onFinish = async (values) => {
    const {
      action,
      rangeDeliveryDate,
      maxWaitingCost,
      target,
      fixedDeliveryStopCost: fixedDeliveryStopCostSet,
      deliveryDemandCostAndRates: deliveryDemandCostAndRatesSet,
      deliveryCostPerLoadUnit: deliveryCostPerLoadUnitSet,
      geoserviceSpeedWeight: geoserviceSpeedWeightSet,
      fixedDeliveryStopCostUnit: fixedDeliveryStopCostUnitSet,
      deliveryDemandCostAndRatesUnit: deliveryDemandCostAndRatesUnitSet,
      deliveryCostPerLoadUnitUnit: deliveryCostPerLoadUnitUnitSet,
      maxWaitingCostUnit: maxWaitingCostUnitSet,
      defaultVehicleLoads: defaultVehicleLoadsSet,
      variableFleet: variableFleetSet,
    } = values;

    const generalRouting = {};
    if (enableMaxWaitingCost) {
      generalRouting.maxWaitingCost = maxWaitingCost;
      generalRouting.maxWaitingCostUnit = maxWaitingCostUnitSet;
    }
    if (enableDeliveryDemandCostAndRates) {
      generalRouting.deliveryDemandCostAndRates = deliveryDemandCostAndRatesSet;
      generalRouting.deliveryDemandCostAndRatesUnit = deliveryDemandCostAndRatesUnitSet;
    }
    if (enableDeliveryCostPerLoadUnit) {
      generalRouting.deliveryCostPerLoadUnit = deliveryCostPerLoadUnitSet;
      generalRouting.deliveryCostPerLoadUnitUnit = deliveryCostPerLoadUnitUnitSet;
    }
    generalRouting.geoserviceSpeedWeight = geoserviceSpeedWeightSet;
    try {
      const body = {
        demandSetId,
        action,
        extraParams: {
          action,
          rangeDeliveryDate: rangeDeliveryDate.map((date) => date.format('YYYY-MM-DD HH:mm')),
          target,
          fixedDeliveryStopCost: fixedDeliveryStopCostSet,
          fixedDeliveryStopCostUnit: fixedDeliveryStopCostUnitSet,
          generalRouting,
          fleetsParams: {
            defaultVehicleLoads: defaultVehicleLoadsSet,
            variableFleet: variableFleetSet,
          },
        },
      };
      await route(body).unwrap();
      message.success('Ruteo enviado correctamente');
      setVisible(false);
    } catch (error) {
      message.error('Formulario con errores, revisar');
      console.error(error);
    }
  };

  const onFinishFailed = () => {
    message.error(i18n.t('form.error'));
  };

  const initialValues = {
    action: defaultAction,
    maxWaitingCost: enableMaxWaitingCost ? maxWaitingCostFromSetting : 0,
    deliveryDemandCostAndRates: enableDeliveryDemandCostAndRates
      ? deliveryDemandCostAndRates
      : undefined,
    fixedDeliveryStopCost: enableFixedDeliveryStopCost ? fixedDeliveryStopCost : undefined,
    deliveryCostPerLoadUnit: enableDeliveryCostPerLoadUnit ? deliveryCostPerLoadUnit : undefined,
    geoserviceSpeedWeight: geoserviceSpeedWeight || undefined,
    maxWaitingCostUnit: maxWaitingCostUnit || 'minutes',
    fixedDeliveryStopCostUnit: fixedDeliveryStopCostUnit || 'minutes',
    deliveryCostPerLoadUnitUnit: deliveryCostPerLoadUnitUnit || 'minutes',
    deliveryDemandCostAndRatesUnit: deliveryDemandCostAndRatesUnit || 'minutes',
    target: profileLabel,
    defaultVehicleLoads,
    variableFleet: true,
    showInParameterize,
  };

  const disabledRangeTime = (current, type) => {
    let disabledHoursArray = [];
    let disabledMinutesArray = [];
    if (type === 'end') {
      if (dates && dates[0] && current) {
        // if is same day block previous hours and minutes
        const firstDate = dates[0].clone();
        if (current.isSame(firstDate, 'hour')) {
          const maxMinutes = parseInt(firstDate.add(1, 'minute').format('mm'), 10);
          const maxHours = parseInt(firstDate.add(1, 'hour').format('HH'), 10) - 1;
          disabledHoursArray = range(0, maxHours);
          disabledMinutesArray = range(0, maxMinutes);
        } else if (current.isSame(firstDate, 'day')) {
          // if same day, block hours before
          const maxHours = parseInt(firstDate.add(1, 'hour').format('HH'), 10) - 1;
          disabledHoursArray = range(0, maxHours);
          // la hora seleccionada es menor al date 0
          // time selected is before first date, block all hour
          if (current < firstDate) {
            disabledMinutesArray = range(0, 60);
          }
        } else {
          disabledHoursArray = [];
          disabledMinutesArray = [];
        }
      } else {
        disabledHoursArray = [];
        disabledMinutesArray = [];
      }
    } else {
      disabledHoursArray = [];
      disabledMinutesArray = [];
    }
    return {
      disabledMinutes: () => disabledMinutesArray,
      disabledHours: () => disabledHoursArray,
    };
  };

  const scopeI18n = { scope: 'form.parameterize' };
  const defaultTitleDisabled = i18n.t('fieldDisabled', scopeI18n);

  return (
    <div>
      <Row gutter={20}>
        <Col flex="auto">
          <div className="form-container">
            <Form
              form={form}
              layout="vertical"
              initialValues={initialValues}
              validateMessages={{ required: i18n.t('form.fieldRequired') }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row className="main-content">
                <Col span={20} offset={2}>
                  <Form.Item
                    name="rangeDeliveryDate"
                    label={i18n.t('rangeDeliveryDate', scopeI18n)}
                    rules={[{ required: true }]}
                  >
                    <RangePicker
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      disabledTime={disabledRangeTime}
                      onCalendarChange={(val) => setDates(val)}
                    />
                  </Form.Item>
                </Col>
                <Col span={9} offset={2}>
                  <Form.Item
                    label={
                      <TitleHelp
                        title={i18n.t('action', scopeI18n)}
                        helpText={i18n.t('help.action', scopeI18n)}
                      />
                    }
                    required
                    name="action"
                  >
                    {/* todo: pasar como parametro del select */}
                    <Select name="action">
                      <Option value="delivery">{i18n.t('commons.delivery')}</Option>
                      <Option value="pickup">{i18n.t('commons.pickup')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={9} offset={2}>
                  <Form.Item
                    label={
                      <TitleHelp
                        title={i18n.t('fixedDeliveryStopCost', scopeI18n)}
                        helpText={i18n.t('help.fixedDeliveryStopCost', scopeI18n)}
                      />
                    }
                    name="fixedDeliveryStopCost"
                    title={!enableFixedDeliveryStopCost ? defaultTitleDisabled : ''}
                  >
                    <InputNumber
                      min="0"
                      name="fixedDeliveryStopCost"
                      disabled={!enableFixedDeliveryStopCost}
                      addonAfter={
                        <AddonAfterSelect
                          nameField="fixedDeliveryStopCostUnit"
                          isDisabled={!enableFixedDeliveryStopCost}
                          options={optionsTimes}
                        />
                      }
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col offset={2} span={20}>
                  <Form.Item
                    label={
                      <TitleHelp
                        title={i18n.t('target', scopeI18n)}
                        helpText={i18n.t('help.target', scopeI18n)}
                      />
                    }
                    required
                    name="target"
                    style={{ marginBottom: '1rem' }}
                  >
                    <Select
                      name="target"
                      placeholder={i18n.t('commons.select')}
                      options={targetsRoute.map((label) => ({
                        value: label,
                        label,
                      }))}
                    />
                  </Form.Item>
                </Col>
                {!showInParameterize && (
                  <>
                    <Form.Item name="variableFleet">
                      <Input hidden />
                    </Form.Item>
                    {!loadsUnits && (
                      <Form.Item name={['defaultVehicleLoads', 0]}>
                        <Input hidden />
                      </Form.Item>
                    )}
                    {loadsUnits?.map((_, index) => {
                      const keyLoad = `luk-${index}`;
                      return (
                        <Form.Item name={['defaultVehicleLoads', index]} key={keyLoad}>
                          <Input hidden />
                        </Form.Item>
                      );
                    })}
                  </>
                )}
                {showInParameterize && (
                  <>
                    <Col span={20} offset={2}>
                      <Divider orientation="left" orientationMargin="0" style={{ margin: 0 }}>
                        <Text strong>{i18n.t('form.parameterize.defaultVehicle')}</Text>
                      </Divider>
                    </Col>
                    <Col span={9} offset={2}>
                      <Form.Item
                        name="variableFleet"
                        valuePropName="checked"
                        label={
                          <TitleHelp
                            title={i18n.t('variableFleet', scopeI18n)}
                            helpText={i18n.t('help.variableFleet', scopeI18n)}
                          />
                        }
                        required
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    {!loadsUnits && (
                      <Col span={9} offset={2}>
                        <Form.Item
                          label={
                            <TitleHelp
                              title={i18n.t('loadCardinale', { ...scopeI18n, number: 1 })}
                              helpText={i18n.t('help.defaultVehicleLoads', scopeI18n)}
                            />
                          }
                          name={['defaultVehicleLoads', 0]}
                          required
                          rules={[{ required: true }]}
                        >
                          <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                    )}
                    {loadsUnits?.map((loadsUnit, index) => {
                      const keyLoad = `luk-${index}`;
                      return (
                        <Col span={9} offset={2} key={keyLoad}>
                          <Form.Item
                            label={
                              <TitleHelp
                                title={
                                  loadsUnit
                                    ? loadsUnit?.name
                                    : i18n.t('loadCardinale', { ...scopeI18n, number: index + 1 })
                                }
                                helpText={i18n.t('help.defaultVehicleLoads', scopeI18n)}
                              />
                            }
                            name={['defaultVehicleLoads', index]}
                            required={index === 0}
                            rules={[{ required: index === 0 }]}
                          >
                            <InputNumber
                              placeholder={loadsUnit?.name || ''}
                              addonAfter={loadsUnit?.unit || ''}
                              min={0}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      );
                    })}
                  </>
                )}
                <Col span={20} offset={2}>
                  <Divider orientationMargin="0" style={{ margin: 0 }} orientation="left">
                    <Text strong>{i18n.t('form.parameterize.times')}</Text>
                  </Divider>
                </Col>
                <Col span={9} offset={2}>
                  <Form.Item
                    label={
                      <TitleHelp
                        title={i18n.t('geoserviceSpeedWeight', scopeI18n)}
                        helpText={i18n.t('help.geoserviceSpeedWeight', scopeI18n)}
                      />
                    }
                    name="geoserviceSpeedWeight"
                  >
                    <InputNumber min="0" name="geoserviceSpeedWeight" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    label={
                      <TitleHelp
                        title={i18n.t('deliveryDemandCostAndRates', scopeI18n)}
                        helpText={i18n.t('help.deliveryDemandCostAndRates', scopeI18n)}
                      />
                    }
                    name="deliveryDemandCostAndRates"
                    title={!enableDeliveryDemandCostAndRates ? defaultTitleDisabled : ''}
                  >
                    <InputNumber
                      min="0"
                      name="deliveryDemandCostAndRates"
                      disabled={!enableDeliveryDemandCostAndRates}
                      addonAfter={
                        <AddonAfterSelect
                          nameField="deliveryDemandCostAndRatesUnit"
                          isDisabled={!enableDeliveryDemandCostAndRates}
                          options={optionsTimes}
                        />
                      }
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={9} offset={2}>
                  <Form.Item
                    label={
                      <TitleHelp
                        title={i18n.t('maxWaitingCost', scopeI18n)}
                        helpText={i18n.t('help.maxWaitingCost', scopeI18n)}
                      />
                    }
                    name="maxWaitingCost"
                    required
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min="0"
                      addonAfter={
                        <AddonAfterSelect
                          nameField="maxWaitingCostUnit"
                          isDisabled={false}
                          options={optionsTimes}
                        />
                      }
                      name="maxWaitingCost"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <TitleHelp
                        title={i18n.t('deliveryCostPerLoadUnit', scopeI18n)}
                        helpText={i18n.t('help.deliveryCostPerLoadUnit', scopeI18n)}
                      />
                    }
                    name="deliveryCostPerLoadUnit"
                    title={!enableDeliveryCostPerLoadUnit ? defaultTitleDisabled : ''}
                  >
                    <InputNumber
                      min="0"
                      name="deliveryCostPerLoadUnit"
                      disabled={!enableDeliveryCostPerLoadUnit}
                      addonAfter={
                        <AddonAfterSelect
                          nameField="deliveryCostPerLoadUnitUnit"
                          isDisabled={!enableDeliveryCostPerLoadUnit}
                          options={optionsTimes}
                        />
                      }
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

DemandParametrizationForm.defaultProps = {
  id: 0,
  form: {},
  defaultAction: 'delivery',
  targetsRoute: [],
  generalRoutingSetting: {
    maxWaitingCost: 10,
    enableMaxWaitingCost: false,
    fixedDeliveryStopCost: 0,
    enableFixedDeliveryStopCost: false,
    deliveryDemandCostAndRates: 0,
    enableDeliveryDemandCostAndRates: false,
    deliveryCostPerLoadUnit: 0,
    enableDeliveryCostPerLoadUnit: false,
    geoserviceSpeedWeight: 0,
    deliveryDemandCostAndRatesUnit: 'seconds',
    fixedDeliveryStopCostUnit: 'seconds',
    deliveryCostPerLoadUnitUnit: 'seconds',
    maxWaitingCostUnit: 'minutes',
    profileLabel: undefined,
    defaultVehicleLoads: [0, 0, 0],
    loadsUnits: [],
    showInParameterize: false,
  },
  setButtonLoading: () => {},
  setVisible: () => {},
};

DemandParametrizationForm.propTypes = {
  id: PropTypes.number,
  form: formType,
  defaultAction: PropTypes.string,
  targetsRoute: PropTypes.arrayOf(PropTypes.string),
  generalRoutingSetting: PropTypes.shape({
    maxWaitingCost: PropTypes.number,
    enableMaxWaitingCost: PropTypes.bool,
    fixedDeliveryStopCost: PropTypes.number,
    enableFixedDeliveryStopCost: PropTypes.bool,
    deliveryDemandCostAndRates: PropTypes.number,
    enableDeliveryDemandCostAndRates: PropTypes.bool,
    deliveryCostPerLoadUnit: PropTypes.number,
    enableDeliveryCostPerLoadUnit: PropTypes.bool,
    geoserviceSpeedWeight: PropTypes.number,
    deliveryDemandCostAndRatesUnit: PropTypes.string,
    fixedDeliveryStopCostUnit: PropTypes.string,
    deliveryCostPerLoadUnitUnit: PropTypes.string,
    maxWaitingCostUnit: PropTypes.string,
    profileLabel: PropTypes.string,
    defaultVehicleLoads: PropTypes.arrayOf(PropTypes.number),
    loadsUnits: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.number,
        name: PropTypes.string,
        unit: PropTypes.string,
      })
    ),
    showInParameterize: PropTypes.bool,
  }),
  setButtonLoading: PropTypes.func,
  setVisible: PropTypes.func,
};

export default DemandParametrizationForm;
