import { useRecalcutateTimesMutation } from 'features/routes/routesApiSlice';
import { filter } from 'lodash';
import { useState } from 'react';
import { nameGroupByRoutes } from 'services/repeated-functions';

function useItems(itemsIds, routes) {
  const [recalcutateTimes] = useRecalcutateTimesMutation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const deleteRoutes = [];

  const getTimes = async (touchedRoutes) => {
    // get group name for every route to send
    const demandGroupRoutes = nameGroupByRoutes(touchedRoutes);
    const newRoutesRecalc1 = await Promise.all(
      touchedRoutes?.map(async (tRoute) => {
        const tRouteFill = { ...tRoute, demandGroupRoutes };
        const { data } = await recalcutateTimes(tRouteFill);
        return {
          ...tRoute,
          ...data,
        };
      })
    );
    return newRoutesRecalc1;
  };

  const removeItemsFromRoutes = async () => {
    setLoading(true);
    const routesWithItemsRemoved = routes.map((route) => {
      const { items } = route;
      // remove item from original route
      const newOriginalRouteItems = items.filter(
        ({ id: oRouteItemId }) => !itemsIds.includes(oRouteItemId)
      );
      // reorder items
      const reorderedOriginalItems = newOriginalRouteItems.map((itemObj, idx) => {
        return { ...itemObj, itemOrder: idx };
      });
      // check if route has no stop left
      const totalOriginalStops = filter(reorderedOriginalItems, ['type', 'STOP']).length;
      const originalRouteHasToRemoved = totalOriginalStops === 0;
      if (originalRouteHasToRemoved) {
        deleteRoutes.push(route.id);
      }
      return { ...route, items: reorderedOriginalItems };
    });

    // reorder routes
    const finalRoutes = routesWithItemsRemoved
      .filter(({ id: nRId }) => !deleteRoutes.includes(nRId))
      .map((nR2, idx) => {
        const routeOrder = idx + 1;
        return { ...nR2, routeOrder };
      });

    // recalculate times
    if (finalRoutes.length > 0 && errors.length === 0) {
      const newRoutes = await getTimes(finalRoutes);
      setLoading(false);
      return newRoutes;
    }

    setErrors([...errors, 'Debe mantener al menos una parada en este conjunto']);
    setLoading(false);
    return routes;
  };

  return [removeItemsFromRoutes, loading, errors, setErrors];
}

export default useItems;
