import PropTypes from 'prop-types';

const DynamicFieldsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.bool,
    ]),
    type: PropTypes.string,
  })
);

export default DynamicFieldsPropTypes;
