import { Radio, Skeleton, Space } from 'antd';
import { VehiclePositionPropTypes } from 'components/Vehicles/vehicles.propTypes';
import EmptyContent from 'components/layout/EmptyContent';
import { useGetItemByTrackingIdQuery } from 'features/items/itemsApiSlice';
import PropTypes from 'prop-types';
import { Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TrackingMap from './Map';
import Rating from './Rating';

function Tracking(props) {
  const { isConnected, vehiclesPositionEvents } = props;
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [defaultValue, setDefaultValue] = useState('map');
  const [content, setContent] = useState('map');

  useEffect(() => {
    if (searchParams.size > 0 && searchParams.get('view')) {
      setDefaultValue(searchParams.get('view'));
      setContent(searchParams.get('view'));
    }
  }, [searchParams]);

  const { data, isLoading, error } = useGetItemByTrackingIdQuery(token, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (error?.status === 404) {
      navigate('/unauthorized');
    }
  }, [error, navigate]);

  const radioOptions = [
    {
      label: 'Seguimiento',
      value: 'map',
    },
    {
      label: 'Evaluar',
      value: 'rating',
    },
  ];
  const onChange = ({ target: { value } }) => {
    setContent(value);
    setDefaultValue(value);
  };

  return (
    !isLoading && (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Radio.Group
          options={radioOptions}
          onChange={onChange}
          optionType="button"
          buttonStyle="solid"
          defaultValue={defaultValue}
          value={defaultValue}
        />
        <Suspense fallback={<Skeleton active paragraph={{ rows: 15 }} />}>
          {{
            map: (
              <TrackingMap
                item={data}
                route={data?.route}
                isConnected={isConnected}
                vehiclesPositionEvents={vehiclesPositionEvents}
              />
            ),
            rating: <Rating />,
          }[content] || <EmptyContent />}
        </Suspense>
      </Space>
    )
  );
}

Tracking.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  vehiclesPositionEvents: PropTypes.arrayOf(VehiclePositionPropTypes).isRequired,
};

export default Tracking;
