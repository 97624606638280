import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Dropdown, Row, Space } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import ButtonMenu from 'components/common/ButtonMenu';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetRolesQuery, useGetUsersQuery } from 'features/users/usersApiSlice';
import { useContext, useEffect, useRef, useState } from 'react';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import UserDrawer from '../Drawer';
import UserModalMassive from '../ModalMassive';
import TableUsers from './TableUsers';

function UsersList() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState('create');
  const [user, setUser] = useState();
  const [dataFormated, setDataFormated] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerClean, setTriggerClean] = useState(false);
  const [downloadQuery, setDownloadQuery] = useState('');
  const [actualPage, setActualPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  // permissions
  const ability = useContext(CaslContext);
  const canCreate = checkPermissionForSubject(ability, 'create', 'users');
  const canUpdate = checkPermissionForSubject(ability, 'update', 'users');

  const ref = useRef(null);
  const { addReference } = useContext(TourContext);
  addReference('buttonCreateUserRef', ref);

  // custom hooks
  const { data: usersData, isLoading: usersLoading } = useGetUsersQuery();
  const { data: rolesData, isLoading: rolesLoading } = useGetRolesQuery(
    '?allRolesFromMyOrganization=true'
  );
  // modal
  const [visibleModal, setVisibleModal] = useState(false);
  const showModal = () => {
    setVisibleModal(true);
  };

  const breadcrumbItems = [
    {
      key: 'configurations',
      url: '/configurations',
      label: i18n.t('sidebar.settings'),
    },
    {
      key: 'users',
      url: '/configurations/users',
      label: i18n.t('modules.users'),
    },
  ];

  const setDrawerUser = (boolValue) => {
    setDrawerVisible(boolValue);
    if (!boolValue) {
      setUser();
    }
  };

  const setDrawerData = (objectDrawer) => {
    const { drawerType, drawerVisibility, drawerUser } = objectDrawer;
    setTypeDrawer(drawerType);
    setDrawerVisible(drawerVisibility);
    setUser(drawerUser);
  };

  const showDrawer = (event) => {
    if (event.key === 'manual') {
      setDrawerVisible(true);
      setTypeDrawer('create');
    } else {
      showModal(true);
    }
  };

  useEffect(() => {
    if (!usersLoading && !rolesLoading) {
      const { data: dataUsers, total } = usersData;
      setTotalData(total);
      setDataFormated(dataUsers);
      setRoles(rolesData);
      setLoading(false);
    }
  }, [usersData, usersLoading, rolesLoading, rolesData]);
  const showExperimentalDevelop = process.env.REACT_APP_SHOW_EXPERIMENTAL_DEVELOP;
  const items = [
    {
      label: i18n.t('manual', { scope: 'buttons' }),
      key: 'manual',
    },
  ];
  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Row gutter={24} style={{ height: '100%', marginBottom: '1rem' }}>
        {canCreate && (
          <Col flex="auto">
            {`${showExperimentalDevelop}` === 'true' ? (
              <ButtonMenu onClickEvent={showDrawer} disableMassive refElement={ref} />
            ) : (
              <Dropdown menu={{ items, onClick: showDrawer }}>
                <Button type="primary" className="float-right" ref={ref}>
                  <Space>
                    {i18n.t('create', { scope: 'buttons' })}
                    <FontAwesomeIcon icon={['fas', 'chevron-down']} fontSize={14} />
                  </Space>
                </Button>
              </Dropdown>
            )}
          </Col>
        )}
      </Row>
      <TableUsers
        setDrawerData={setDrawerData}
        loading={loading}
        data={dataFormated}
        roles={roles}
        setDataCallback={setDataFormated}
        canUpdate={canUpdate}
        triggerClean={triggerClean}
        setTriggerClean={setTriggerClean}
        paginationData={{
          setTotalData,
          totalData,
          setActualPage,
          actualPage,
          downloadQuery,
          setDownloadQuery,
        }}
      />
      <UserDrawer
        drawerVisible={drawerVisible}
        setDrawerUser={setDrawerUser}
        typeDrawer={typeDrawer}
        user={user}
        roles={roles}
        setTriggerClean={setTriggerClean}
      />
      <UserModalMassive visibleModal={visibleModal} setVisibleModal={setVisibleModal} />
    </>
  );
}
export default UsersList;
