import { Button, Card, Col, Row } from 'antd';
import ModalConfirm from 'components/common/ModalConfirm';
import TitleHelp from 'components/common/TitleHelp';
import TourContext from 'components/layout/TourComponent/TourContext';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';
import { RoutePropTypes, routingSetType } from 'types';

function RouteResume(props) {
  const { allRoutes, routingSet, isEditable, setRestrictionsDrawerOpen } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const minutesSecondsFormat = 'm [min] s [seg]';
  const hourMinutesFormat = 'HH[h] mm[m]';
  const daysHourMinutesFormat = 'D[d] HH[h] mm[m]';
  const allRoutesWithVehicleRs = routingSet?.routes?.every(({ vehicle }) => vehicle);
  const allRoutesWithVehicle = allRoutes?.every(({ vehicle }) => vehicle);
  const { addReference } = useContext(TourContext);
  const routeResumeRef = useRef(null);
  addReference('routeResumeRef', routeResumeRef);

  const travelTimeFormatted = (travelTime) => {
    // start day and end of month to add days and starts with 1, add seconds to get in time format
    const initialTime = dayjs().endOf('month').startOf('day');
    const newTime = initialTime.add(Math.abs(travelTime), 'seconds');
    let stringFormat = hourMinutesFormat;
    if (travelTime < 3599) {
      stringFormat = minutesSecondsFormat;
    } else if (travelTime > 86400) {
      stringFormat = daysHourMinutesFormat;
    } else {
      stringFormat = hourMinutesFormat;
    }
    return newTime.format(stringFormat);
  };
  const totalDistanceFormatted = (totalDistance) => `${Math.round(totalDistance / 1000)} km`;
  const totalDistanceDecimalFormatted = (totalDistance) =>
    `${(totalDistance / 1000).toFixed(1)} km`;
  const foFormatted = (totalOccupation) => {
    const mathFo = totalOccupation > 0 ? totalOccupation * 100 : 0;
    return `${Math.round(mathFo)}%`;
  };

  // values for routes (initial state, or when is edit)
  // virtual sufix are editing routes or initial if not are changes
  const totalDistanceVirtualInMts = allRoutes.reduce(
    (partialSum, { totalDistance }) => partialSum + parseFloat(totalDistance),
    0.0
  );
  const travelTimeVirtual = allRoutes.reduce(
    (partialSum, { travelTime }) => partialSum + parseFloat(travelTime),
    0.0
  );
  const totalRoutesVirtual = allRoutes.length;
  const totalLoadRoute = allRoutes.reduce((summ, { loads }) => summ + loads[0].load, 0);
  const totalLoadVehicle = allRoutes.reduce(
    (summ, { vehicle }) => summ + (vehicle ? vehicle.loads[0].load : 0),
    0
  );
  let totalOccupationVirtual = 0;
  if (totalLoadRoute > 0 && totalLoadVehicle > 0) {
    totalOccupationVirtual = totalLoadRoute / totalLoadVehicle;
  }
  const foVirtual = foFormatted(totalOccupationVirtual);
  const totalTimeTravelVirtual = travelTimeFormatted(travelTimeVirtual);
  const totalDistanceVirtual = totalDistanceFormatted(totalDistanceVirtualInMts);
  const totalRestrictionsVirtual = allRoutes.reduce(
    (summ, { totalRestrictions = 0 }) => summ + totalRestrictions,
    0
  );
  const helpRestrictions =
    totalRestrictionsVirtual > 0
      ? `${totalRestrictionsVirtual} ${
          totalRestrictionsVirtual === 1 ? 'restricción infringida' : 'restricciones infringidas'
        }`
      : 'Sin restricciones en las rutas';
  const titleHelpRestriction = <TitleHelp title="" helpText={helpRestrictions} />;

  // values for original routes, using routing-set
  const {
    totalRoutes: totalRoutesRoutingSet,
    travelTime: travelTimeRoutingSet,
    totalDistance: totalDistanceRoutingSet,
    totalRestrictions: totalRestrictionsRoutingSet,
  } = routingSet;
  const totalTimeTravelRs = travelTimeFormatted(travelTimeRoutingSet);
  const totalDistanceRs = totalDistanceFormatted(totalDistanceRoutingSet);
  const foRs = foFormatted(routingSet?.totalOccupation || 0);

  const cardGridOriginal = [
    {
      colSpan: { xl: 5, lg: 9, md: 8, sm: 12 },
      title: 'Total de rutas',
      value: totalRoutesRoutingSet,
    },
    {
      colSpan: { xl: 4, lg: 6, md: 8, sm: 12 },
      title: 'F.O.',
      value: allRoutesWithVehicleRs ? foRs : '-',
    },
    { colSpan: { xl: 5, lg: 9, md: 8, sm: 12 }, title: 'Tiempo', value: totalTimeTravelRs },
    {
      colSpan: { xl: 5, lg: 12, md: 12, sm: 12 },
      title: 'Distancia',
      value: totalDistanceRs,
    },
    {
      colSpan: { xl: 5, lg: 12, md: 12, sm: 12 },
      title: 'Restricciones',
      value: totalRestrictionsRoutingSet,
    },
  ];

  // grid with all routes, editing routes
  const cardGrid = [
    {
      colSpan: { xl: 5, lg: 9, md: 8, sm: 12 },
      title: 'Total de rutas',
      value: totalRoutesVirtual,
    },
    {
      colSpan: { xl: 4, lg: 6, md: 8, sm: 12 },
      title: 'F.O.',
      value: allRoutesWithVehicle ? foVirtual : '-',
    },
    {
      colSpan: { xl: 5, lg: 9, md: 8, sm: 12 },
      title: 'Tiempo',
      value: totalTimeTravelVirtual,
      difference: travelTimeVirtual - travelTimeRoutingSet,
      differenceFormatted: travelTimeFormatted(Math.abs(travelTimeRoutingSet - travelTimeVirtual)),
    },
    {
      colSpan: { xl: 5, lg: 12, md: 12, sm: 12 },
      title: 'Distancia',
      value: totalDistanceVirtual,
      difference: totalDistanceVirtualInMts - totalDistanceRoutingSet,
      differenceFormatted: totalDistanceDecimalFormatted(
        Math.abs(totalDistanceRoutingSet - totalDistanceVirtualInMts)
      ),
    },
    {
      colSpan: { xl: 5, lg: 12, md: 12, sm: 12 },
      title: 'Restricciones',
      value: totalRestrictionsVirtual,
      difference: totalRestrictionsVirtual - totalRestrictionsRoutingSet,
      differenceFormatted: Math.abs(totalRestrictionsRoutingSet - totalRestrictionsVirtual),
    },
  ];

  const onUndoChanges = () => {
    setIsModalVisible(true);
  };

  const onCancelAction = () => {
    setIsModalVisible(false);
  };

  const onConfirmModal = () => {
    window.location.reload(false);
  };

  const renderButtonRestrictions = () => (
    <Button
      style={{ fontSize: 12, padding: 0, height: 'auto' }}
      type="link"
      onClick={() => setRestrictionsDrawerOpen(true)}
      title="Mostrar restricciones"
    >
      Restricciones
    </Button>
  );

  const renderButtonUseOriginal = () => (
    <Button
      style={{ float: 'right', fontSize: 12 }}
      type="link"
      onClick={onUndoChanges}
      title="Mantener versión original"
    >
      Elegir
    </Button>
  );

  return (
    <div ref={routeResumeRef}>
      <Row>
        <Col span={24} style={{ marginBottom: '.3rem' }}>
          <Row>
            {cardGrid.map(({ colSpan, title, value, difference, differenceFormatted }, index) => {
              const { lg, md, sm, xl } = colSpan;
              const key = typeof title === 'string' ? title : `cg-title${index}`;
              return (
                <Col key={`col-${key}`} lg={lg} md={md} sm={sm} xl={xl} style={{ display: 'flex' }}>
                  <Card size="small" style={{ width: '100%', borderRadius: '0' }}>
                    <span className="title-route-resume-card">
                      {title === 'Restricciones' ? renderButtonRestrictions() : title}
                    </span>
                    <br />
                    <span className="value-route-resume-card">
                      {`${value} `}
                      {title === 'Restricciones' ? titleHelpRestriction : ''}
                    </span>
                    {isEditable && difference !== undefined && (
                      <>
                        <br />
                        <span
                          className={`diff-${
                            difference > 0 ? 'positive' : 'negative'
                          }-value-route-resume-card`}
                        >
                          {difference > 0 ? '+ ' : '- '}
                          {differenceFormatted}
                        </span>
                      </>
                    )}
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
        {isEditable && (
          <Col
            span={24}
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)', marginBottom: '.3rem' }}
          >
            <Row>
              {cardGridOriginal.map(({ colSpan, title, value }, index) => {
                const { lg, md, sm, xl } = colSpan;
                const key = typeof title === 'string' ? title : `cg-title${index}`;
                return (
                  <Col
                    key={`col-${key}`}
                    lg={lg}
                    md={md}
                    sm={sm}
                    xl={xl}
                    style={{ display: 'flex' }}
                  >
                    <Card
                      size="small"
                      style={{ width: '100%', borderRadius: '0', body: { padding: '5px 12px' } }}
                    >
                      <span className="value-route-resume-card">{value}</span>
                      {title === 'Restricciones' && renderButtonUseOriginal()}
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
        <ModalConfirm
          onOk={onConfirmModal}
          onCancel={onCancelAction}
          title="¿Estás seguro que quieres deshacer los cambios?"
          isModalVisible={isModalVisible}
          okText="Deshacer"
          status="warning"
          okButtonProps={{ danger: false }}
        />
      </Row>
    </div>
  );
}

RouteResume.defaultProps = {
  allRoutes: [],
  routingSet: {},
  isEditable: false,
  setRestrictionsDrawerOpen: () => {},
};

RouteResume.propTypes = {
  allRoutes: PropTypes.arrayOf(RoutePropTypes),
  routingSet: routingSetType,
  isEditable: PropTypes.bool,
  setRestrictionsDrawerOpen: PropTypes.func,
};

export default RouteResume;
