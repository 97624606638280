import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Checkbox, Col, Layout, Row, Skeleton, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ModalConfirm from 'components/common/ModalConfirm';
import TableFilters from 'components/common/TableFilter';
import {
  useDeleteOrRestoreOrganizationMutation,
  useGetOrganizationsByFiltersMutation,
  useGetOrganizationsQuery,
} from 'features/organizations/organizationsApiSlice';
import { useChangeCurrentOrganizationMutation } from 'features/users/usersApiSlice';
import { setCurrentUser } from 'features/users/userSlice';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import OrganizationsDashboardCard from './Card';
import DashboardHeader from './Header';

const contentStyle = {
  minHeight: 120,
  backgroundColor: '#fff',
};

const fieldsFilter = [
  {
    name: 'name',
    label: 'Nombre',
    type: 'text',
    minCharsToSearch: 2,
  },
  {
    name: 'deletedAt',
    label: 'Estado',
    type: 'select',
    options: [
      { value: 'true', label: 'Archivado' },
      { value: 'false', label: 'Desarchivado' },
    ],
  },
];

function OrganizationsDashboardList() {
  const [organizations, setOrganizations] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [getOrganizationsByFilters] = useGetOrganizationsByFiltersMutation();
  const { data: organizationsData, isLoading, refetch } = useGetOrganizationsQuery();
  const [changeCurrOrg] = useChangeCurrentOrganizationMutation();
  const [deleteRestoreOrganization] = useDeleteOrRestoreOrganizationMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message } = App.useApp();
  // permissions
  const ability = useContext(CaslContext);
  const canCreate = checkPermissionForSubject(ability, 'create', 'organizations');
  const canUpdate = checkPermissionForSubject(ability, 'update', 'organizations');
  const canDelete = checkPermissionForSubject(ability, 'delete', 'organizations');
  const canRead = checkPermissionForSubject(ability, 'read', 'organizations');

  useEffect(() => {
    if (!isLoading) {
      setOrganizations(organizationsData);
    }
    refetch();
  }, [organizationsData, isLoading, refetch]);
  const onShowIncomplete = async (e) => {
    const showIncomplete = e.target.checked;
    try {
      const query = showIncomplete ? '?creationStatus=INCOMPLETE' : '';
      const result = await getOrganizationsByFilters(query).unwrap();
      setOrganizations(result);
    } catch (error) {
      message.error(error);
    }
  };
  const createOrganization = () => {
    navigate('/organizations/creation-steps');
  };
  const handleChangeCurrOrg = async (value) => {
    const result = await changeCurrOrg(value).unwrap();
    const userProfile = {
      id: result.id,
      nationalNid: result.nationalNid,
      givenName: result.name,
      lastName: result.lastName,
      fullName: `${result.name} ${result.lastName}`,
      photo: result.photo,
      phone: result.phone,
      email: result.email,
      subject: result.subject,
      organization: result.organization,
      organizations: result.organizations,
      roles: result.roles,
      actions: result.actions,
      enableTour: result.enableTour,
      notificationToken: result.notificationToken,
    };
    dispatch(setCurrentUser({ userProfile }));
  };

  const archiveRestoreUser = async () => {
    try {
      await deleteRestoreOrganization(selectedOrganization.id).unwrap();
      message.success(
        `Organización ${selectedOrganization.deletedAt !== null ? 'Desarchivada' : 'Archivada'}`
      );
    } catch (error) {
      message.error(
        `No se pudo ${
          selectedOrganization.deletedAt !== null ? 'desarchivar' : 'archivar'
        } la Organización`
      );
    }
  };

  const onConfirmAction = (event) => {
    event.stopPropagation();
    archiveRestoreUser();
    setIsModalVisible(false);
  };
  const onCancelAction = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
    setSelectedOrganization();
  };
  const showModal = (record) => {
    setSelectedOrganization(record);
    setIsModalVisible(true);
  };

  const okText = selectedOrganization?.deletedAt !== null ? 'Desarchivar' : 'Archivar';
  const titleModal = `¿Estás seguro que desea ${okText} esta organización?`;
  const colorButtonProps = { danger: selectedOrganization?.deletedAt === null };
  const modalStatus = selectedOrganization?.deletedAt !== null ? 'warning' : 'error';

  return (
    <Layout style={contentStyle}>
      <DashboardHeader />
      <Content style={contentStyle}>
        <TableFilters
          setDataCallback={setOrganizations}
          fieldsFilter={fieldsFilter}
          showTitle={false}
          reduxFunc={getOrganizationsByFilters}
        />
        <Row
          justify="end"
          style={{
            marginBottom: '15px',
          }}
        >
          <Col style={{ textAlign: 'right' }}>
            <Checkbox onChange={onShowIncomplete}>Mostrar organizaciones incompletas</Checkbox>
          </Col>
        </Row>
        {isLoading ? (
          <Skeleton paragraph={{ rows: 10 }} />
        ) : (
          <Space size={32} wrap style={{ justifyContent: 'center' }}>
            {canCreate && (
              <Button type="dashed" className="create-button-style" onClick={createOrganization}>
                <FontAwesomeIcon
                  icon={['fas', 'plus']}
                  className="font-awesome-icon-style"
                  size="5x"
                />
                <p>Crear organización</p>
              </Button>
            )}
            {organizations.map((organization) => (
              <OrganizationsDashboardCard
                key={organization.id}
                organization={organization}
                canUpdate={canUpdate}
                canDelete={canDelete}
                canRead={canRead}
                handleChangeCurrOrg={handleChangeCurrOrg}
                showModal={showModal}
              />
            ))}
            <ModalConfirm
              onOk={onConfirmAction}
              onCancel={onCancelAction}
              title={titleModal}
              isModalVisible={isModalVisible}
              okButtonProps={colorButtonProps}
              okText={okText}
              status={modalStatus}
            />
          </Space>
        )}
      </Content>
    </Layout>
  );
}

export default OrganizationsDashboardList;
