import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';

function CheckboxCol(props) {
  const { text, roleId, action, callback, canUpdate } = props;
  const [indeterminate, setIndeterminate] = useState(true);

  return (
    <Checkbox
      indeterminate={indeterminate}
      onChange={(event) => {
        callback(event.target.checked, action, roleId);
        setIndeterminate(false);
      }}
      disabled={!canUpdate}
    >
      {text}
    </Checkbox>
  );
}

CheckboxCol.defaultProps = {
  text: 'Ver',
  action: 'R',
  roleId: 1,
  callback: () => {},
  canUpdate: false,
};

CheckboxCol.propTypes = {
  text: PropTypes.string,
  action: PropTypes.string,
  roleId: PropTypes.number,
  callback: PropTypes.func,
  canUpdate: PropTypes.bool,
};

export default CheckboxCol;
