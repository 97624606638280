import { Modal, Result } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';

function ModalConfirm(props) {
  const {
    title,
    onOk,
    onCancel,
    isModalVisible,
    confirmLoading,
    okText,
    cancelText,
    okButtonProps,
    status,
  } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  return (
    <Modal
      closable={false}
      maskClosable={false}
      open={isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      cancelText={cancelText || i18n.t('buttons.cancel')}
      okText={okText || i18n.t('buttons.archive')}
      okType="primary"
      okButtonProps={okButtonProps}
      width={500}
      destroyOnClose
      centered
      confirmLoading={confirmLoading}
    >
      <Result status={status} title={title || i18n.t('footerDrawer.areYouSure')} />
    </Modal>
  );
}

ModalConfirm.defaultProps = {
  onOk: () => {},
  onCancel: () => {},
  title: undefined,
  isModalVisible: false,
  confirmLoading: false,
  okText: undefined,
  cancelText: undefined,
  okButtonProps: { danger: true },
  status: 'error',
};

ModalConfirm.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  isModalVisible: PropTypes.bool,
  confirmLoading: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  okButtonProps: PropTypes.shape({ danger: PropTypes.bool }),
  status: PropTypes.string,
};

export default ModalConfirm;
