import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Col, Divider, List, Row, Steps, theme } from 'antd';
import { isEmpty } from 'lodash';
import { convertDateToDayjs } from 'services/repeated-functions';
import { RoutePropTypes, eventType, itemType } from 'types';

function MapHeader(props) {
  const { route, item, finishedEvent } = props;
  const {
    organization: { timezone: tzOrganization },
  } = item;
  const { token } = theme.useToken();

  const title = () => {
    let titleText;
    if (!isEmpty(finishedEvent)) {
      titleText = finishedEvent?.status === 'FAILURE' ? 'Entrega fallida' : 'Estado de envío';
    } else if (!route?.startedAt) {
      titleText = 'Estado de envío';
    } else {
      titleText = 'En camino';
    }
    return titleText;
  };
  return (
    <>
      <Row>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <List
            itemLayout="horizontal"
            dataSource={[
              {
                title: title(),
                description: `Entrega estimada ${convertDateToDayjs(
                  item?.arrivesAt,
                  tzOrganization,
                  'DD [de] MMMM HH:mm'
                )} a ${convertDateToDayjs(item?.departsAt, tzOrganization, 'HH:mm')}`,
              },
            ]}
            renderItem={(source) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      shape="square"
                      size={42}
                      icon={<FontAwesomeIcon icon={['fa', 'rotate']} />}
                      style={{ backgroundColor: token.colorPrimary }}
                    />
                  }
                  title={source.title}
                  description={source.description}
                />
              </List.Item>
            )}
          />
        </Col>
        <Col lg={{ span: 6, offset: 6 }} xs={{ span: 24 }}>
          <Steps
            size="small"
            labelPlacement="vertical"
            items={[
              {
                title: 'Planificado',
                status: 'finish',
                icon: <FontAwesomeIcon icon={['fas', 'file-pen']} />,
              },
              {
                title: 'En camino',
                status: route?.startedAt ? 'finish' : 'wait',
                icon: <FontAwesomeIcon icon={['fas', 'truck']} />,
              },
              {
                title: 'Entregado',
                status: !isEmpty(finishedEvent) ? 'finish' : 'wait',
                icon: <FontAwesomeIcon icon={['fas', 'file-pen']} />,
              },
            ]}
          />
        </Col>
      </Row>
      <Divider plain />
    </>
  );
}

export default MapHeader;

MapHeader.defaultProps = {
  finishedEvent: {},
};

MapHeader.propTypes = {
  route: RoutePropTypes.isRequired,
  item: itemType.isRequired,
  finishedEvent: eventType,
};
