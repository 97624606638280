import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'antd';
import TableFilters from 'components/common/TableFilter';
import TourContext from 'components/layout/TourComponent/TourContext';
import {
  useGetCategoriesPanelsQuery,
  useGetPanelsByFiltersMutation,
} from 'features/panels/panelsApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPermissionForSubject } from 'services/repeated-functions';
import Can from 'utils/Can';
import CaslContext from 'utils/caslContext';

function PanelFilter(props) {
  const { setPanels, triggerClean } = props;
  const [categories, setCategories] = useState([]);
  const [getPanelsByFilters] = useGetPanelsByFiltersMutation();
  const { data: categoriesData, isLoading, refetch } = useGetCategoriesPanelsQuery();

  const ability = useContext(CaslContext);
  const canCreate = checkPermissionForSubject(ability, 'create', 'panels');
  const navigate = useNavigate();
  const { addReference } = useContext(TourContext);
  const refCreate = useRef(null);
  addReference('buttonCreatePanelRef', refCreate);

  useEffect(() => {
    if (!isLoading) {
      const uniqCategories = [...new Set(categoriesData)];
      const allCategories = uniqCategories.map((category) => ({
        label: category,
        value: category,
      }));
      setCategories(allCategories);
    }
    refetch();
  }, [categoriesData, isLoading, refetch]);

  const clickAdd = () => {
    navigate('/reports/panel/new');
  };

  const fieldsFilter = [
    {
      label: '',
      name: 'orderBy',
      span: 6,
      options: [
        {
          value: 'all',
          label: 'Todo',
        },
        {
          value: 'most_viewed',
          label: 'Más vistos',
        },
        {
          value: 'less_viewed',
          label: 'Menos vistos',
        },
      ],
      defaultValue: 'all',
      type: 'groupedButton',
    },
    {
      name: 'category',
      label: 'Categoría',
      type: 'select',
      span: 7,
      options: categories,
    },
    {
      name: 'showDeleted',
      label: 'Estado',
      type: 'select',
      span: 5,
      options: [
        { value: 'true', label: 'Archivado' },
        { value: 'false', label: 'Desarchivado' },
      ],
    },
    {
      name: 'search',
      label: 'Buscar',
      type: 'text',
      span: 6,
    },
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col span={canCreate ? 23 : 24}>
        <TableFilters
          setDataCallback={setPanels}
          fieldsFilter={fieldsFilter}
          showTitle={false}
          reduxFunc={getPanelsByFilters}
          triggerClean={triggerClean}
        />
      </Col>
      <Can key="create-panel" I="create" a="panels">
        <Col md={1} xs={0}>
          <Button type="primary" onClick={clickAdd} title="Añadir panel" ref={refCreate}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        </Col>
      </Can>
    </Row>
  );
}

PanelFilter.defaultProps = {
  setPanels: () => {},
  triggerClean: false,
};

PanelFilter.propTypes = {
  setPanels: PropTypes.func,
  triggerClean: PropTypes.bool,
};
export default PanelFilter;
