import { Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import './style.css';
import { resumeCardContentPropTypes } from '../common/Map/LeafletMap.propType';

function RouteResumeCard(props) {
  const { cardGrid, baseSpanCardContent } = props;
  const { span, offset } = baseSpanCardContent;
  return (
    <Row>
      <Col span={span} offset={offset}>
        <Row>
          {cardGrid.map((cg, index) => {
            const { lg, md, sm, xl } = cg.colSpan;
            const key = typeof cg.title === 'string' ? cg.title : `cg-title${index}`;
            return (
              <Col key={`col-${key}`} lg={lg} md={md} sm={sm} xl={xl} style={{ display: 'flex' }}>
                <Card size="small" style={{ width: '100%', borderRadius: '0' }}>
                  <span className="title-route-resume-card">{cg.title}</span>
                  <br />
                  <span className="value-route-resume-card">{cg.value}</span>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}

RouteResumeCard.defaultProps = {
  cardGrid: [],
  baseSpanCardContent: { span: 20, offset: 2 },
};

RouteResumeCard.propTypes = {
  cardGrid: PropTypes.arrayOf(resumeCardContentPropTypes),
  baseSpanCardContent: PropTypes.shape({
    span: PropTypes.number,
    offset: PropTypes.number,
  }),
};

export default RouteResumeCard;
