import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Drawer, Skeleton } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useUpdateRouteMutation } from 'features/routes/routesApiSlice';
import { useGetDriversAssitantsAvailablesByFilterMutation } from 'features/users/usersApiSlice';
import { useGetVehiclesAvailablesByFilterMutation } from 'features/vehicles/vehiclesApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import ButtonsTab from './ButtonsTab';
import DataList from './DataList';
import InfoCard from './InfoCard';
import InputSearch from './InputSearch';
import { UserPropTypes, VehiclePropTypes } from './assigment.propTypes';

function Assigment(props) {
  const { route, routeOrder, drawerVisible, setDrawerAssigment } = props;
  const { id } = route;
  const [type, setType] = useState('driver');
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [driver, setDriver] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [assistant, setAssistant] = useState(null);
  const [clearText, setClearText] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'assigment' };

  // custom hooks
  const [getVehiclesByFilters] = useGetVehiclesAvailablesByFilterMutation();
  const [getDriverAssitantsByFilters] = useGetDriversAssitantsAvailablesByFilterMutation();
  const [updateRoute] = useUpdateRouteMutation();

  useEffect(() => {
    if (route && drawerVisible) {
      setDriver(route.driver);
      setVehicle(route.vehicle);
      setAssistant(route.assistant);
    }
  }, [route, drawerVisible]);

  useEffect(() => {
    const fetchData = async () => {
      if (id && drawerVisible) {
        const dataDriverAssistants = await getDriverAssitantsByFilters({ routeId: id }).unwrap();
        const dataVehicles = await getVehiclesByFilters({ routeId: id }).unwrap();
        setAssistants(dataDriverAssistants);
        setDrivers(dataDriverAssistants);
        setVehicles(dataVehicles);
        setLoading(false);
      }
    };
    fetchData();
  }, [drawerVisible, getDriverAssitantsByFilters, getVehiclesByFilters, id]);

  const onClose = () => {
    setDrawerAssigment(false);
    setType('driver');
    setAssistant(null);
    setVehicle(null);
    setDriver(null);
    setVehicles([]);
    setDrivers([]);
    setAssistants([]);
  };

  const changeButtonSelected = (selected) => {
    setType(selected);
    setClearText(true);
  };

  const onSave = async () => {
    try {
      // if has vehicle selected can save
      if (vehicle.id) {
        const body = {
          driverId: driver?.id || null,
          assistantId: assistant?.id || null,
          vehicleId: vehicle.id,
          status: driver && vehicle ? 'ASSIGNED' : 'CREATED',
        };
        const { id: routeId } = route;
        const response = await updateRoute({ routeId, body }).unwrap();
        const {
          assistant: assistantResponse,
          driver: driverResponse,
          vehicle: vehicleResponse,
        } = response;
        const assignedResponse = [assistantResponse, driverResponse, vehicleResponse];
        message.success(i18n.t('form.sendSuccess'));
        if (assignedResponse.some((item) => item)) {
          assignedResponse
            .filter((item) => item)
            .forEach((element) => {
              message.info(
                i18n.t('entityAlreadyAssigned', {
                  ...scopeI18n,
                  entity: i18n.t(`commons.${element.glosa}`),
                  routes: `[${element.routesNid.join(', ')}]`,
                  count: element.routesNid.length,
                })
              );
            });
        }
        // when save without move select dont display :/
        setType('driver');
        setDrawerAssigment(false);
      }
    } catch (error) {
      message.error(i18n.t('form.error'));
      const { data } = error;
      // get key to select tab as active
      const selectedTab = Object.keys(data)[0];
      changeButtonSelected(selectedTab);
      setErrors(data);
    }
  };

  const isVehicle = type === 'vehicle';
  const isDriver = type === 'driver';
  const isAssistant = type === 'assistant';

  const renderButtonTabs = () => <ButtonsTab type={type} onChange={changeButtonSelected} />;

  const renderInputSearch = () => {
    let func = getDriverAssitantsByFilters;
    if (isVehicle) {
      func = getVehiclesByFilters;
    }
    const setData = (data) => {
      if (isVehicle) {
        setVehicles(data);
      }
      if (isDriver) {
        setDrivers(data);
      }
      if (isAssistant) {
        setAssistants(data);
      }
      setClearText(false);
    };
    return (
      <InputSearch
        func={func}
        routeId={id}
        setData={setData}
        dataType={type}
        clearText={clearText}
      />
    );
  };
  const renderClearSelected = () => {
    const doClickClearSelection = () => {
      if (isVehicle) {
        setVehicle(null);
      }
      if (isDriver) {
        setDriver(null);
      }
      if (isAssistant) {
        setAssistant(null);
      }
    };
    // Show only for driver and assistant
    return isDriver || isAssistant ? (
      <Button
        onClick={doClickClearSelection}
        block
        type="primary"
        ghost
        style={{ marginTop: '.5rem' }}
      >
        {i18n.t('clearSelection', scopeI18n)}
        <FontAwesomeIcon icon="fa-regular fa-circle-xmark" style={{ marginLeft: '.5rem' }} />
      </Button>
    ) : (
      ''
    );
  };

  const setDataType = (item) => {
    switch (type) {
      case 'driver':
        setDriver(item);
        break;
      case 'assistant':
        setAssistant(item);
        break;
      case 'vehicle':
        setVehicle(item);
        break;
      default:
        setDriver(item);
        break;
    }
  };

  const renderList = () => (
    <DataList
      dataType={type}
      vehicles={vehicles}
      onSelected={(item) => setDataType(item)}
      driver={driver}
      assistant={assistant}
      vehicle={vehicle}
      drivers={drivers}
      assistants={assistants}
      errors={errors}
    />
  );

  const renderFooter = () => (
    <div style={{ flexDirection: 'row', textAlign: 'end' }}>
      <Button style={{ width: 100 }} onClick={onClose}>
        {i18n.t('buttons.cancel')}
      </Button>
      <Button
        style={{ marginLeft: 16, width: 100 }}
        type="primary"
        onClick={onSave}
        disabled={!vehicle}
      >
        {i18n.t('buttons.save')}
      </Button>
    </div>
  );

  const renderInfoCard = () => (
    <InfoCard
      route={route}
      routeOrder={routeOrder}
      driver={driver}
      assistant={assistant}
      vehicle={vehicle}
    />
  );

  return (
    <Drawer
      title={i18n.t('assigment', scopeI18n)}
      width={388}
      onClose={(event) => onClose(event)}
      open={drawerVisible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={renderFooter()}
    >
      {renderInfoCard()}
      {renderButtonTabs()}
      {renderInputSearch()}
      {renderClearSelected()}
      <Skeleton loading={loading} active paragraph={{ rows: 4 }} style={{ marginTop: '1rem' }}>
        {renderList()}
      </Skeleton>
    </Drawer>
  );
}

Assigment.propTypes = {
  drawerVisible: PropTypes.bool,
  setDrawerAssigment: PropTypes.func,
  routeOrder: PropTypes.number,
  route: PropTypes.shape({
    action: PropTypes.string,
    assignment: PropTypes.string,
    endsAt: PropTypes.string,
    id: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.number,
        lat: PropTypes.string,
        lng: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    key: PropTypes.number,
    loads: PropTypes.arrayOf(
      PropTypes.shape({
        load: PropTypes.number,
      })
    ),
    route: PropTypes.number,
    startsAt: PropTypes.string,
    totalDistance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalStops: PropTypes.number,
    travelTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    driver: UserPropTypes,
    assistant: UserPropTypes,
    vehicle: VehiclePropTypes,
  }),
};

Assigment.defaultProps = {
  drawerVisible: false,
  setDrawerAssigment: () => {},
  routeOrder: null,
  route: null,
};

export default Assigment;
