import { App, Button, DatePicker, Form, Radio, Select, Switch } from 'antd';
import TitlePopover from 'components/common/TitlePopover';
import TourContext from 'components/layout/TourComponent/TourContext';
import dayjs from 'dayjs';
import { useGeneratePreviewMutation } from 'features/reports/reportsApiSlice';
import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';
import { LocationsPropTypes } from 'types';
import Can from 'utils/Can';
import editionReportColumns from '../ColumnsDefinition/editionReportColumns';
import executionReportColumns from '../ColumnsDefinition/executionReportColumns';
import reportRoutesColumns from '../ColumnsDefinition/reportRouteColumns';
import TransferFields from './TransferFields';

const { RangePicker } = DatePicker;

function DownloadFilter(props) {
  const {
    depots,
    setTypeReport,
    typeReport,
    targetKeys,
    setTargetKeys,
    setIsModalVisible,
    setDataForTable,
    setLoading,
    setValues,
  } = props;
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [allcustomFields, setAllcustomFields] = useState([]);
  const [formatSelected, setFormatSelected] = useState(false);
  const [dates, setDates] = useState(null);
  const { message } = App.useApp();
  const { addReference } = useContext(TourContext);
  const refTypeReport = useRef(null);
  const refCustomReport = useRef(null);
  addReference('selectTypeReportRef', refTypeReport);
  addReference('switchCustomReportRef', refCustomReport);

  const [generatePreview] = useGeneratePreviewMutation();

  const onFinish = async (values) => {
    setButtonLoading(true);
    setIsModalVisible(true);
    setValues(values);
    setTimeout(() => {
      setButtonLoading(false);
    }, 3000);
  };
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  const initialValues = {
    action: 'all',
    customReport: showCustomFields,
    customFields: showCustomFields ? allcustomFields : undefined,
  };

  const optionsAction = [
    { label: 'Todo', value: 'all' },
    { label: 'Recogida', value: 'pickup' },
    { label: 'Llevada', value: 'delivery' },
  ];

  const allDepots = depots.map((depot) => {
    return {
      label: depot.name,
      value: depot.id,
    };
  });

  const optionsStatus = [
    {
      label: 'Creada',
      value: 'CREATED',
    },
    {
      label: 'Asignada',
      value: 'ASSIGNED',
    },
    {
      label: 'Iniciada',
      value: 'STARTED',
    },
    {
      label: 'Finalizada',
      value: 'FINISHED',
    },
  ];
  // range date
  const maxDayRange = 90;
  // default limit
  const daysBefore = dayjs().subtract(maxDayRange, 'days');
  const daysAfter = dayjs().add(maxDayRange, 'days');
  const disabledDate = (current) => {
    if (!dates) {
      return (
        (current && current < daysBefore.endOf('day')) ||
        (current && current > daysAfter.endOf('day'))
      );
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > maxDayRange;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > maxDayRange;
    return !!tooEarly || !!tooLate;
  };

  // switch
  const onChangeSwitch = (value) => {
    setShowCustomFields(value);
    // if is off, load default columns and keys
    if (value === false) {
      let columnsToUse = reportRoutesColumns;
      if (typeReport === 'report_route') {
        columnsToUse = reportRoutesColumns;
      } else if (typeReport === 'execution_report') {
        columnsToUse = executionReportColumns;
      } else if (typeReport === 'edition_report') {
        columnsToUse = editionReportColumns;
      }
      setAllcustomFields(
        columnsToUse.map((column) => {
          return {
            title: column.title,
            dataIndex: column.dataIndex,
            key: column.transferKey,
          };
        })
      );
      setTargetKeys(columnsToUse.map(({ transferKey }) => transferKey));
    } else {
      form.setFields([
        {
          name: 'customFields',
          value: targetKeys,
        },
      ]);
    }
  };

  const optionsTypeReport = [
    { label: 'Reporte de rutas', value: 'report_route' },
    { label: 'Reporte de ejecución', value: 'execution_report' },
    { label: 'Reporte de ediciones', value: 'edition_report' },
  ];

  // format
  const onChangeFormat = (e) => {
    setFormatSelected(e !== undefined);
  };
  const optionsFormat = [
    {
      label: 'Microsoft Excel (.xlsx)',
      value: 'xlsx',
    },
    {
      label: 'CSV (.csv)',
      value: 'csv',
    },
  ];

  const handleOnValuesChange = async (changedValues, values) => {
    const { customReport, format, typeReport: typeReportIsChanging } = changedValues;
    if (!format) {
      setLoading(true);
    }
    const doRequest = async (isChangeReport = false, customFields = []) => {
      // send only action and customReport when change typeReport
      const valuesToSend = isChangeReport
        ? { typeReport: typeReportIsChanging, action: 'all', customReport: false }
        : values;
      const dataForTable = await generatePreview({
        ...valuesToSend,
        customFields: values.customReport ? customFields : undefined,
      }).unwrap();
      setDataForTable(dataForTable);
      setLoading(false);
    };

    // if change type report
    if (typeReportIsChanging) {
      setTypeReport(typeReportIsChanging);
      setFormatSelected(false);
      // restart all other fields in form
      form.setFieldsValue({
        action: 'all',
        range: undefined,
        depot: undefined,
        status: undefined,
        format: undefined,
        customReport: false,
      });
      setShowCustomFields(false);
      let columnsToUse = reportRoutesColumns;
      if (typeReportIsChanging === 'report_route') {
        columnsToUse = reportRoutesColumns;
      } else if (typeReportIsChanging === 'execution_report') {
        columnsToUse = executionReportColumns;
      } else if (typeReportIsChanging === 'edition_report') {
        columnsToUse = editionReportColumns;
      }
      setAllcustomFields(
        columnsToUse.map((column) => {
          return {
            title: column.title,
            dataIndex: column.dataIndex,
            key: column.transferKey,
          };
        })
      );
      setTargetKeys(columnsToUse.map(({ transferKey }) => transferKey));
      // do request for preview, with default fields
      await doRequest(true);
    } else if (!format) {
      // dont do request if change format
      const { typeReport: typeReportChanged } = values;
      // if has type report selected
      if (typeReportChanged) {
        // add customFields for first enable or load from values
        let customFields = values?.customFields;
        if (customReport) {
          customFields = targetKeys;
        }
        // do request with custom fields
        await doRequest(false, customFields);
      }
    }
  };

  // helps
  const typeReportHelps = [
    {
      strong: 'Reporte de rutas',
      plain:
        'Contiene la información necesaria para validar la planificación de las rutas que comiencen en el periodo seleccionado.',
    },
    {
      strong: 'Reporte de ejecución',
      plain:
        'Contiene la información necesaria para realizar seguimiento a la ejecución de las rutas que comenzaron en el periodo seleccionado.',
    },
    {
      strong: 'Reporte de ediciones',
      plain:
        'Contiene la información necesaria para validar los cambios realizados a las rutas posterior a su creación optimizada.',
    },
  ];
  const actionHelps = [
    {
      strong: 'Recogida',
      plain: 'Rutas en las que se fija un horario de llegada al último punto de trayecto.',
    },
    {
      strong: 'Llevada',
      plain: 'Rutas en las que se fija un horario de salida del primer punto de trayecto.',
    },
  ];
  const statusHelps = [
    {
      strong: 'Creada',
      plain: 'Ruta se encuentra pendiente de asignación de conductor o vehículo.',
    },
    {
      strong: 'Asignada',
      plain: 'Ruta se encuentra asignada a un conductor y vehículo.',
    },
    {
      strong: 'Iniciada',
      plain: 'Conductor ha notificado el inicio de la ruta.',
    },
    {
      strong: 'Finalizada',
      plain: 'Conductor ya ha notificado el término de la ruta.',
    },
  ];

  return (
    <Form
      form={form}
      layout="horizontal"
      validateMessages={{ required: 'Este campo es obligatorio' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={initialValues}
      onValuesChange={handleOnValuesChange}
    >
      <div key="select-type-report-key" className="select-type-report" ref={refTypeReport}>
        <Form.Item
          labelAlign="left"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          name="typeReport"
          label={<TitlePopover title="Tipo de reporte" content={typeReportHelps} />}
          rules={[{ required: true }]}
        >
          <Select options={optionsTypeReport} placeholder="Seleccione" />
        </Form.Item>
      </div>
      <Form.Item
        labelAlign="left"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        name="action"
        label={<TitlePopover title="Acción" content={actionHelps} />}
      >
        <Radio.Group>
          {optionsAction.map(({ label, value }) => (
            <Radio.Button key={`rb-${value}`} value={value}>
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        name="range"
        label="Fecha y hora"
      >
        <RangePicker
          showTime
          showToday
          disabledDate={disabledDate}
          format="DD-MM-YYYY HH:mm"
          onCalendarChange={(val) => setDates(val)}
          placement="topRight"
        />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        name="depot"
        label="Base"
      >
        <Select mode="multiple" allowClear options={allDepots} placeholder="Seleccione" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        name="status"
        label={<TitlePopover title="Estado" content={statusHelps} />}
      >
        <Select mode="multiple" allowClear options={optionsStatus} placeholder="Seleccione" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        name="format"
        label="Formato"
        rules={[{ required: true }]}
      >
        <Select
          allowClear
          options={optionsFormat}
          placeholder="Seleccione"
          onChange={onChangeFormat}
        />
      </Form.Item>
      <div key="switch-custom-report-key" className="switch-custom-report" ref={refCustomReport}>
        <Form.Item
          labelAlign="left"
          labelCol={{
            span: 8,
            md: 8,
            xs: 0,
          }}
          wrapperCol={{
            span: 16,
            md: 16,
            xs: 0,
          }}
          name="customReport"
          label="Personalizar informe"
          valuePropName="checked"
        >
          <Switch
            onChange={onChangeSwitch}
            disabled={typeReport === undefined}
            title={typeReport === undefined ? 'Seleccione un Tipo de reporte' : ''}
          />
        </Form.Item>
      </div>
      {showCustomFields && typeReport !== undefined && (
        <TransferFields
          targetKeys={targetKeys}
          setTargetKeys={setTargetKeys}
          allcustomFields={allcustomFields}
        />
      )}
      <Can I="read" a="reports">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={buttonLoading}
            disabled={!typeReport || !formatSelected || targetKeys.length === 0}
            title={!typeReport || !formatSelected ? 'Seleccione Tipo de reporte y formato' : ''}
          >
            Generar
          </Button>
        </Form.Item>
      </Can>
    </Form>
  );
}

DownloadFilter.propTypes = {
  depots: PropTypes.arrayOf(LocationsPropTypes),
  setTypeReport: PropTypes.func,
  typeReport: PropTypes.string,
  targetKeys: PropTypes.arrayOf(PropTypes.string),
  setTargetKeys: PropTypes.func,
  setIsModalVisible: PropTypes.func,
  setDataForTable: PropTypes.func,
  setLoading: PropTypes.func,
  setValues: PropTypes.func,
};

DownloadFilter.defaultProps = {
  depots: [],
  setTypeReport: () => {},
  typeReport: undefined,
  targetKeys: [],
  setTargetKeys: () => {},
  setIsModalVisible: () => {},
  setDataForTable: () => {},
  setLoading: () => {},
  setValues: () => {},
};

export default DownloadFilter;
