import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Divider, Radio, Row, Select, Space, Tooltip, theme } from 'antd';
import TitleHelp from 'components/common/TitleHelp';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { SynopticSettings, TootltipsEnabledSettings } from '../SynopticSettings.propTypes';
import SynopticSettingsDrawer from '../SynopticSettingsDrawer';

// Returns the html element with the lower bar of the synoptic where all the options are found.
function SynopticControlBar(props) {
  const {
    userSettings,
    setUserSettings,
    filtersEnabled,
    tootltipsEnabled,
    defaultSettings,
    setDefaultSettings,
    currentState,
    toggleHide,
    toggleFullScreen,
    filterState,
    filterType,
    showControl,
    setRestrictionsDrawerOpen,
    totalRestrictions,
  } = props;

  const { parameterEnabled, expandEnabled, hideEnabled } = tootltipsEnabled;
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [stateSelected, setStateSelected] = useState('all');
  const showSpan = 7;
  const typeSpan = 4;
  const statusSpan = 8;
  const buttonsOffset = filtersEnabled ? 0 : showSpan + typeSpan + statusSpan;
  const { token } = theme.useToken();

  return (
    <>
      <Divider style={{ margin: '10px 0 10px 0' }} />
      <Row style={{ width: '100%' }}>
        {filtersEnabled && (
          // Filter option input for later implementation.
          <>
            <Col span={showSpan}>
              <TitleHelp
                title="Mostrar"
                helpText="La opción permite visualizar en el sinóptico los horarios de los usuarios y/o vehiculos asignados a la ruta para revisar posibles conflictos de horario."
              />
              <Select
                mode="multiple"
                allowClear
                style={{ width: '70%', marginLeft: '5px' }}
                disabled={currentState === 'hide'}
                placeholder="Seleccione"
                onChange={(value) => {
                  showControl(value);
                }}
                options={[
                  { label: 'Conductor', value: 'driver' },
                  { label: 'Vehiculo', value: 'vehicle' },
                ]}
              />
            </Col>
            <Col span={typeSpan}>
              <TitleHelp
                title="Tipo"
                helpText="La opción permite filtrar el conjunto de rutas por el tipo de acción de ruta."
              />
              <Select
                allowClear
                style={{ width: '60%', marginLeft: '5px' }}
                placeholder="Todo"
                onChange={(value) => {
                  filterType(value || 'all');
                }}
                options={[
                  { label: 'Llevada', value: 'delivery' },
                  { label: 'Recogida', value: 'pickup' },
                ]}
              />
            </Col>
            <Col span={statusSpan}>
              <TitleHelp
                title="Estado"
                helpText="La opción permite filtrar el conjunto de rutas por el estado de asignación de ruta."
              />
              <Radio.Group
                style={{ marginLeft: '5px' }}
                buttonStyle="solid"
                value={stateSelected}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  setStateSelected(selectedValue);
                  filterState(selectedValue);
                }}
              >
                <Radio.Button value="all">Todo</Radio.Button>
                <Radio.Button value="assigned">Asignado</Radio.Button>
                <Radio.Button value="unassigned">No asignado</Radio.Button>
              </Radio.Group>
            </Col>
          </>
        )}
        <Col span={5} offset={buttonsOffset}>
          <Space style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
            {totalRestrictions > 0 && (
              <Tooltip
                title={`${totalRestrictions} ${
                  totalRestrictions === 1 ? 'restricción infringida' : 'restricciones infringidas'
                }`}
                color={token.colorError}
              >
                <Button onClick={() => setRestrictionsDrawerOpen(true)}>
                  {totalRestrictions}
                  <FontAwesomeIcon
                    icon={['fas', 'fa-circle-exclamation']}
                    color={token.colorError}
                    style={{ marginLeft: '1rem' }}
                  />
                </Button>
              </Tooltip>
            )}
            {parameterEnabled && (
              <Tooltip title="Configuración de parametros">
                <Button
                  onClick={() => {
                    setSettingsVisible(!settingsVisible);
                  }}
                  disabled={currentState === 'hide'}
                >
                  <FontAwesomeIcon icon={['fas', 'fa-cog']} />
                </Button>
              </Tooltip>
            )}
            {expandEnabled && (
              <Tooltip
                title={
                  currentState === 'normal'
                    ? 'Expandir a vista completa'
                    : 'Contraer a tamaño normal'
                }
              >
                <Button
                  onClick={() => {
                    toggleFullScreen();
                  }}
                  disabled={currentState === 'hide'}
                >
                  <FontAwesomeIcon
                    icon={['fas', currentState !== 'expand' ? 'fa-maximize' : 'fa-minimize']}
                  />
                </Button>
              </Tooltip>
            )}
            {hideEnabled && (
              <Tooltip title={`${currentState === 'normal' ? 'Ocultar' : 'Mostrar'} sinóptico`}>
                <Button
                  onClick={() => {
                    toggleHide();
                  }}
                  disabled={currentState === 'expand'}
                >
                  <FontAwesomeIcon
                    icon={['fas', currentState !== 'hide' ? 'angle-right' : 'angle-left']}
                    rotation={90}
                  />
                </Button>
              </Tooltip>
            )}
          </Space>
        </Col>
      </Row>
      <SynopticSettingsDrawer
        userSettings={userSettings}
        setUserSettings={setUserSettings}
        settingsVisible={settingsVisible}
        setSettingsVisible={setSettingsVisible}
        defaultSettings={defaultSettings}
        setDefaultSettings={setDefaultSettings}
      />
    </>
  );
}
SynopticControlBar.defaultProps = {
  // *** User defined setings
  userSettings: {
    referenceDate: dayjs(), // Base date to be shown in the draw.
    shownTimeForward: 36, // Amount of time to be shown forward of the reference date
    shownTimeBackward: 3, // Amount of time to be shown backward of the reference date
    shownTimeReference: 'hours', // Set for a time convertion.
    showRouteNumber: true, // Bool to show or not the route number.
    minimalTick: 15, // 1, 5, 10, 15, 20, 30, 60 is recomended. 240 doesnt work quite well.
    minimalTickReference: 'minutes', // Set for time convertion.
  },
  setUserSettings: () => {}, // function. The function that set the synoptic settings.
  defaultSettings: true, // boolean. Variable to check if the settings values are the default.
  setDefaultSettings: () => {}, // function. Updates the variable when the settings are changed.
  filtersEnabled: true, // boolean. TEMPORAL VALUE. Sets the visualization of the filter elements.
  // Show or hide tooltips from control bar
  tootltipsEnabled: {
    parameterEnabled: true, // Boolean, show/hide parameter config button fot synpotic
    expandEnabled: true, // Boolean, show/hide expand button for synoptic
    hideEnabled: true, // Boolean, show/hide hide button for synaptic
  },
  currentState: 'normal', // string. Set tje current state of the synoptic, possible values are: hide, normal or expand. Used to hide certain buttons.
  toggleHide: () => {}, // function. Sets the visibility of the synoptic between hidden and normal on toggle.
  toggleFullScreen: () => {}, // function. Sets the visibility of the synoptic between expanded and normal on toggle.
  filterState: () => {},
  filterType: () => {},
  showControl: () => {},
  setRestrictionsDrawerOpen: () => {},
  totalRestrictions: 0,
};
SynopticControlBar.propTypes = {
  userSettings: SynopticSettings,
  setUserSettings: PropTypes.func,
  defaultSettings: PropTypes.bool,
  setDefaultSettings: PropTypes.func,
  filtersEnabled: PropTypes.bool,
  tootltipsEnabled: TootltipsEnabledSettings,
  currentState: PropTypes.string,
  toggleHide: PropTypes.func,
  toggleFullScreen: PropTypes.func,
  filterState: PropTypes.func,
  filterType: PropTypes.func,
  showControl: PropTypes.func,
  setRestrictionsDrawerOpen: PropTypes.func,
  totalRestrictions: PropTypes.number,
};

export default SynopticControlBar;
