import { Col, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import LeafletMap from 'components/common/Map/LeafletMap';
import { RoutePropTypes, routingSetType } from 'types';
import RouteResume from '../RouteResume';

function RoutesDemandMap(props) {
  const { routeStops, allRoutes, routingSet, isEditable, showMap, setRestrictionsDrawerOpen } =
    props;

  return (
    <Col
      style={{
        width: showMap ? '40%' : '0',
        height: isEditable ? '70%' : '80%',
      }}
    >
      <Skeleton active paragraph={{ rows: 15 }} loading={routeStops.length === 0}>
        <RouteResume
          allRoutes={allRoutes}
          routingSet={routingSet}
          isEditable={isEditable}
          setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
        />
        <div className="upper-container map-element" style={{ width: '100%' }}>
          <LeafletMap dataSource={routeStops} showRoutes />
        </div>
      </Skeleton>
    </Col>
  );
}

RoutesDemandMap.defaultProps = {
  routeStops: [],
  allRoutes: [],
  routingSet: {},
  isEditable: false,
  showMap: true,
  setRestrictionsDrawerOpen: () => {},
};

RoutesDemandMap.propTypes = {
  allRoutes: PropTypes.arrayOf(RoutePropTypes),
  routeStops: PropTypes.arrayOf(PropTypes.arrayOf(RoutePropTypes)),
  routingSet: routingSetType,
  isEditable: PropTypes.bool,
  showMap: PropTypes.bool,
  setRestrictionsDrawerOpen: PropTypes.func,
};

export default RoutesDemandMap;
