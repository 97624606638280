import { App, Button, Col, Row } from 'antd';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import { useGetActionsFromUserMutation } from 'features/authorization/authorizationApiSlice';
import {
  useGetBaseRolesQuery,
  useGetPermissionsQuery,
  useGetRolesQuery,
  useMassiveAssignRoleMutation,
} from 'features/roles/rolesApiSlice';
import { setActionsCurrentUser } from 'features/users/userSlice';
import { filter, flatten } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkPermissionForSubject, moduleNameTranslation } from 'services/repeated-functions';
import Can from 'utils/Can';
import CaslContext from 'utils/caslContext';
import DrawerRole from './Create/DrawerRole';
import TableRoles from './TableRoles';

// ToDo: read this data from database
const modules = ['planning', 'following', 'locations', 'reports', 'configurations', 'support'];

function RolesContent() {
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [baseRoles, setBaseRoles] = useState([]);
  const [clickedCheckbox, setClickedCheckbox] = useState({});
  const [loadingTable, setLoadingTable] = useState(true);
  const [actionCrudIds, setActionCrudIds] = useState({});
  const dispatch = useDispatch();
  const { notification } = App.useApp();

  // custom hooks
  const {
    data: permissionsData,
    isLoading,
    refetch: refetchPermissions,
  } = useGetPermissionsQuery();
  const { data: rolesData, refetch: refetchRoles } = useGetRolesQuery();
  const { data: baseRolesData, refetch: refetchBaseRoles } = useGetBaseRolesQuery();
  const [massiveAssignRole] = useMassiveAssignRoleMutation();
  const [getActionsUser] = useGetActionsFromUserMutation();
  // permissions
  const ability = useContext(CaslContext);
  const canCreate = checkPermissionForSubject(ability, 'create', 'roles');
  const canUpdate = checkPermissionForSubject(ability, 'update', 'roles');

  const breadcrumbItems = [
    {
      key: 'configuration',
      url: '/configurations',
      label: 'Configuración',
    },
    {
      key: 'roles',
      url: '/configurations/roles',
      label: 'Roles y permisos',
    },
  ];

  useEffect(() => {
    if (permissionsData) {
      setPermissions(permissionsData);
      refetchPermissions();
    }
    if (rolesData) {
      setRoles(rolesData);
      refetchRoles();
    }
    if (baseRolesData) {
      setBaseRoles(baseRolesData);
      refetchBaseRoles();
    }
  }, [
    permissionsData,
    rolesData,
    isLoading,
    refetchPermissions,
    refetchRoles,
    baseRolesData,
    refetchBaseRoles,
  ]);

  const onCheckColumn = (isChecked, initialAction, roleId) => {
    const actionIds = actionCrudIds[initialAction];
    if (isChecked) {
      // debo agregar todas las acciones de tipo 'C-R-U-D' al rol seleccionado
      const ids = clickedCheckbox[roleId] ? [...clickedCheckbox[roleId], ...actionIds] : actionIds;
      setClickedCheckbox({
        ...clickedCheckbox,
        [roleId]: ids,
      });
    } else {
      // debo quitar todas las acciones de tipo 'C-R-U-D' al rol seleccionado
      const actionsInRole = clickedCheckbox[roleId] ? clickedCheckbox[roleId] : [];
      const actionsDiff = actionsInRole.filter((ac) => !actionIds.includes(ac));
      setClickedCheckbox({
        ...clickedCheckbox,
        [roleId]: actionsDiff,
      });
    }
    // add to data new values to display changes
    const newData = data.map((row) => {
      const newRow = { ...row };
      newRow[`a${initialAction}r${roleId}`] = isChecked;
      return newRow;
    });
    setData(newData);
  };

  const onCheck = (isChecked, initialAction, roleId, record) => {
    const idSelected = record.actions.find((action) => {
      return action.initial === initialAction;
    }).id;
    if (isChecked) {
      const ids = clickedCheckbox[roleId] ? [...clickedCheckbox[roleId], idSelected] : [idSelected];
      setClickedCheckbox({
        ...clickedCheckbox,
        [roleId]: ids,
      });
    } else {
      // remove from list
      const ids = clickedCheckbox[roleId]
        ? clickedCheckbox[roleId].filter((acId) => acId !== idSelected)
        : [];
      setClickedCheckbox({
        ...clickedCheckbox,
        [roleId]: ids,
      });
    }
    // add to data new values to display changes
    const newData = data.map((row) => {
      const newRow = { ...row };
      if (row.key === record.key) {
        newRow[`a${initialAction}r${roleId}`] = isChecked;
      }
      return newRow;
    });
    setData(newData);
  };

  const onSave = async () => {
    setLoadingTable(true);
    try {
      const body = Object.keys(clickedCheckbox).map((roleId) => {
        return {
          id: parseInt(roleId, 10),
          actionIds: clickedCheckbox[roleId],
        };
      });
      await massiveAssignRole(body);
      // reload actions for current user
      await getActionsUser()
        .unwrap()
        .then((actions) => {
          dispatch(setActionsCurrentUser({ actions }));
          notification.success({ message: 'Atención', description: 'Roles actualizados' });
        })
        .catch(() =>
          notification.error({
            message: 'Error',
            description: 'Roles no se pudieron actualizar',
          })
        );
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: 'Roles no se pudieron actualizar',
      });
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    // aqui se arma la data para filtrar en la tabla y marcar los checks
    const buildDataSet = () => {
      const selectedFromDb = {};
      const permissionsByModules = modules.map((moduleName) => {
        const arrayData = [];
        const permissionsArray = filter(permissions, { module: moduleName });
        arrayData.push({
          key: moduleName,
          permissionName: moduleNameTranslation(moduleName),
          actions: [],
          isModule: true,
        });
        const permissionsActions = permissionsArray.map((permission) => {
          const allActions = {};

          roles.forEach((role) => {
            const actionIds = role.actions.map((action) => action.id);
            permission.actions.forEach((action) => {
              allActions[`a${action.initial}r${role.id}`] = actionIds.includes(action.id);
            });
            selectedFromDb[role.id] = actionIds;
          });

          const dataF = {
            key: permission.id,
            permissionName: permission.name,
            module: permission.module,
            actions: permission.actions,
            isModule: false,
            ...allActions,
          };
          return dataF;
        });
        arrayData.push(...permissionsActions);
        return arrayData;
      });
      setClickedCheckbox(selectedFromDb);
      return flatten(permissionsByModules);
    };

    const buildActionsCrud = () => {
      const actionCrudObj = {
        C: [],
        R: [],
        U: [],
        D: [],
      };
      permissions.forEach((permission) => {
        permission.actions.forEach((act) => {
          actionCrudObj[act.initial].push(act.id);
        });
      });
      setActionCrudIds(actionCrudObj);
    };
    if (roles.length > 0 && permissions.length > 0) {
      const dataBuilded = buildDataSet();
      setData(dataBuilded);
      buildActionsCrud();
      setLoadingTable(false);
    }
  }, [roles, permissions]);

  return (
    <Row gutter={16} className="roles-container">
      <Col span={24}>
        <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
      </Col>
      <Col span={24}>
        <Col span={24} className="float-right" style={{ marginBottom: '1rem' }}>
          {permissions.length > 0 && canCreate && (
            <DrawerRole
              permissions={permissions}
              roles={roles}
              baseRoles={baseRoles}
              modules={modules}
            />
          )}
          <Can I="update" a="roles">
            <Button
              size="large"
              type="primary"
              onClick={onSave}
              disabled={loadingTable}
              style={{ marginLeft: '1rem' }}
            >
              Guardar
            </Button>
          </Can>
        </Col>
      </Col>
      <Col span={24}>
        <TableRoles
          roles={roles}
          permissions={permissions}
          loading={loadingTable}
          data={data}
          callbackCol={onCheckColumn}
          callbackCheck={onCheck}
          canUpdate={canUpdate}
        />
      </Col>
    </Row>
  );
}

export default RolesContent;
