import {
  Alert,
  App,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Switch,
  Typography,
} from 'antd';
import AddonAfterSelect from 'components/common/AddonAfterSelect';
import SubmitButton from 'components/common/SubmitButton';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { selectCurrentOrganization, selectCurrentUser } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BASE_URL_RE,
  checkPermissionForSubject,
  sortSelect,
  userIsOptimal,
  VERSION_URL_RE,
} from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import { GenerarRoutingSettingPropTypes, LabelsSelect } from '../generalRouting.propTypes';

const { Text } = Typography;

function RoutingGeneralForm(props) {
  const {
    settingsFields,
    onFinish,
    savingData,
    initialValues,
    submitButton,
    labels,
    organizationId,
  } = props;
  const [form] = Form.useForm();
  const [enableFixedDeliveryStopCost, setEnableFixedDeliveryStopCost] = useState(
    settingsFields.enableFixedDeliveryStopCost
  );
  const [enableMaxWaitingCost, setEnableMaxWaitingCost] = useState(
    settingsFields.enableMaxWaitingCost
  );
  const [enableDeliveryDemandCostAndRates, setEnableDeliveryDemandCostAndRates] = useState(
    settingsFields.enableDeliveryDemandCostAndRates
  );
  const [enableDeliveryCostPerLoadUnit, setEnableDeliveryCostPerLoadUnit] = useState(
    settingsFields.enableDeliveryCostPerLoadUnit
  );
  const { message } = App.useApp();
  let idOrgToUse = organizationId;
  const { id: currOrgId } = useSelector(selectCurrentOrganization);
  // if no is creation, use id from currentUser
  if (!idOrgToUse) {
    idOrgToUse = currOrgId;
  }
  const { data: organization, isLoading: isLoadingOrgData } = useGetOrganizationQuery(idOrgToUse);
  const loadsFromOrg = organization?.loadsUnit || [];
  const loadsFromOrgData = isLoadingOrgData ? [] : loadsFromOrg;
  const ability = useContext(CaslContext);
  const canCreate = checkPermissionForSubject(ability, 'create', 'routingParameters');
  const canUpdate = checkPermissionForSubject(ability, 'update', 'routingParameters');
  const canUpdateOrg = checkPermissionForSubject(ability, 'update', 'organizations');

  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.generalRouting' };

  // need both, maybe only need one
  const canSave = (canCreate && canUpdate) || (canUpdateOrg && submitButton);
  // load setters and values as attributes in an object, to be called dynamic
  const switchFuncs = {
    setEnableFixedDeliveryStopCost,
    setEnableMaxWaitingCost,
    setEnableDeliveryDemandCostAndRates,
    setEnableDeliveryCostPerLoadUnit,
    enableFixedDeliveryStopCost,
    enableMaxWaitingCost,
    enableDeliveryDemandCostAndRates,
    enableDeliveryCostPerLoadUnit,
  };

  const onFinishFailed = () => {
    message.error(i18n.t('form.checkForm'));
  };

  const changeSwitch = (value, event) => {
    const { target } = event;
    const currId = target.id || target.parentElement.id;
    const fieldName = currId.charAt(0).toUpperCase() + currId.slice(1);
    // set value
    switchFuncs[`set${fieldName}`](value);
    // clean value on input if disabled
    if (!value) {
      const fieldNameMayus = fieldName.split('Enable')[1];
      const inputFieldName = fieldNameMayus.charAt(0).toLowerCase() + fieldNameMayus.slice(1);
      form.setFieldValue(inputFieldName, undefined);
    }
  };
  const unitValues = {
    fixedDeliveryStopCostUnit: settingsFields.fixedDeliveryStopCostUnit || 'minutes',
    maxWaitingCostUnit: settingsFields.maxWaitingCostUnit || 'minutes',
    deliveryDemandCostAndRatesUnit: settingsFields.deliveryDemandCostAndRatesUnit || 'minutes',
    deliveryCostPerLoadUnitUnit: settingsFields.deliveryCostPerLoadUnitUnit || 'minutes',
  };

  const urlRoutingEngine = organization?.urlRoutingEngine ?? BASE_URL_RE;
  const versionRoutingEngine = organization?.versionRoutingEngine ?? VERSION_URL_RE;
  const currentUser = useSelector(selectCurrentUser);
  const userHasOptimalRolesCheck = userIsOptimal(currentUser);

  if (isLoadingOrgData) {
    return <Skeleton paragraph={{ rows: 15 }} />;
  }
  return (
    !isLoadingOrgData && (
      <Form
        form={form}
        layout="vertical"
        validateMessages={{ required: i18n.t('form.fieldRequired') }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          ...initialValues,
          ...unitValues,
          ...settingsFields,
          loadsUnits: loadsFromOrgData,
        }}
        disabled={!canSave}
      >
        <Row justify="start" gutter={[24, 12]}>
          {!canSave && (
            <Col span={24}>
              <Alert
                description={i18n.t('form.permissionRequiredForSetting')}
                type="warning"
                showIcon
                style={{ padding: 10, marginBottom: '1rem' }}
              />
            </Col>
          )}
          <Col span={24}>
            <Row gutter={[24, 12]}>
              <Col span={24}>
                <Divider orientation="left" orientationMargin="0" style={{ margin: 0 }}>
                  <Text strong>{i18n.t('routeServices', scopeI18n)}</Text>
                </Divider>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <TitleHelp
                      title={i18n.t('defaultTarget', scopeI18n)}
                      helpText={i18n.t('helps.defaultTarget', scopeI18n)}
                    />
                  }
                  name="profileLabel"
                  required
                  rules={[{ required: true }]}
                  style={{ marginBottom: '0.3rem' }}
                >
                  <Select
                    allowClear
                    filterSort={sortSelect}
                    placeholder={i18n.t('commons.select')}
                    options={labels}
                  />
                </Form.Item>
              </Col>
              <Col span={12} md={8}>
                <Form.Item
                  label={
                    <TitleHelp
                      title={i18n.t('maxSendForWork', scopeI18n)}
                      helpText={i18n.t('helps.maxSendForWork', scopeI18n)}
                    />
                  }
                  name="maxSendForWork"
                  required
                  rules={[{ required: true }]}
                  style={{ marginBottom: '0.3rem' }}
                >
                  <InputNumber style={{ width: '100%' }} min={0} max={500} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[24, 12]}>
              <Col span={24}>
                <Divider orientation="left" orientationMargin="0" style={{ margin: 0 }}>
                  <Text strong>{i18n.t('defaultVehicle', scopeI18n)}</Text>
                </Divider>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <TitleHelp
                      title={i18n.t('showInParameterize', scopeI18n)}
                      helpText={i18n.t('helps.showInParameterize', scopeI18n)}
                    />
                  }
                  name="showInParameterize"
                  required
                  rules={[{ required: true }]}
                  style={{ marginBottom: '0.3rem' }}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={
                    <TitleHelp
                      title={i18n.t('minVehicles', scopeI18n)}
                      helpText={i18n.t('helps.minVehicles', scopeI18n)}
                    />
                  }
                  name="minVehicles"
                  required
                  rules={[{ required: true }]}
                  style={{ marginBottom: '0.3rem' }}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={
                    <TitleHelp
                      title={i18n.t('geoserviceSpeedWeight', scopeI18n)}
                      helpText={i18n.t('helps.geoserviceSpeedWeight', scopeI18n)}
                    />
                  }
                  name="geoserviceSpeedWeight"
                  required
                  rules={[{ required: true }]}
                  style={{ marginBottom: '0.3rem' }}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
              <Form.Item name="loadsUnits">
                <Input hidden />
              </Form.Item>
              {loadsFromOrgData &&
                loadsFromOrgData.map((load, index) => {
                  const key = index;
                  return (
                    <Col span={6} key={key}>
                      <Form.Item
                        label={
                          <TitleHelp
                            title={load?.name}
                            helpText={i18n.t('helps.loadsUnits', scopeI18n)}
                          />
                        }
                        name={['defaultVehicleLoads', key]}
                        required={index === 0}
                        rules={[{ required: index === 0 }]}
                        style={{ marginBottom: '0.3rem' }}
                      >
                        <InputNumber
                          placeholder={load?.name}
                          addonAfter={load?.unit}
                          min={0}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  );
                })}
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[24, 12]}>
              <Col span={24}>
                <Divider orientation="left" orientationMargin="0" style={{ margin: 0 }}>
                  <Text strong>{i18n.t('times', scopeI18n)}</Text>
                </Divider>
              </Col>
              {[
                {
                  field: 'fixedDeliveryStopCost',
                  label: i18n.t('fixedDeliveryStopCost', scopeI18n),
                  helpText: i18n.t('helps.fixedDeliveryStopCost', scopeI18n),
                  addonAfter: (
                    <AddonAfterSelect
                      nameField="fixedDeliveryStopCostUnit"
                      isDisabled={!switchFuncs.enableFixedDeliveryStopCost}
                    />
                  ),
                },
                {
                  field: 'maxWaitingCost',
                  label: i18n.t('maxWaitingCost', scopeI18n),
                  helpText: i18n.t('helps.maxWaitingCost', scopeI18n),
                  addonAfter: (
                    <AddonAfterSelect
                      nameField="maxWaitingCostUnit"
                      isDisabled={!switchFuncs.enableMaxWaitingCost}
                    />
                  ),
                },
                {
                  field: 'deliveryDemandCostAndRates',
                  label: i18n.t('deliveryDemandCostAndRates', scopeI18n),
                  helpText: i18n.t('helps.deliveryDemandCostAndRates', scopeI18n),
                  addonAfter: (
                    <AddonAfterSelect
                      nameField="deliveryDemandCostAndRatesUnit"
                      isDisabled={!switchFuncs.enableDeliveryDemandCostAndRates}
                    />
                  ),
                },
                {
                  field: 'deliveryCostPerLoadUnit',
                  label: i18n.t('deliveryCostPerLoadUnit', scopeI18n),
                  helpText: i18n.t('helps.deliveryCostPerLoadUnit', scopeI18n),
                  addonAfter: (
                    <AddonAfterSelect
                      nameField="deliveryCostPerLoadUnitUnit"
                      isDisabled={!switchFuncs.enableDeliveryCostPerLoadUnit}
                    />
                  ),
                },
              ].map(({ field, label, helpText, addonAfter = 'seg' }) => {
                const fieldName = field.charAt(0).toUpperCase() + field.slice(1);
                const switchName = `enable${fieldName}`;
                const isRequired = switchFuncs[switchName];
                return (
                  <Col span={6} key={`col-${field}`}>
                    <Form.Item
                      label={<TitleHelp title={label} helpText={helpText} />}
                      name={switchName}
                      valuePropName="checked"
                      required={isRequired}
                      rules={[{ required: isRequired }]}
                      style={{ marginBottom: '0.3rem' }}
                    >
                      <Switch onChange={changeSwitch} />
                    </Form.Item>
                    <Form.Item
                      name={field}
                      required={isRequired}
                      rules={[{ required: isRequired }]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        addonAfter={addonAfter}
                        disabled={!isRequired || !canSave}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </Col>
          {userHasOptimalRolesCheck && (
            <Col span={24}>
              <Row gutter={[24, 12]}>
                <Col span={24}>
                  <Divider orientation="left" orientationMargin="0" style={{ margin: 0 }}>
                    <Text strong>{i18n.t('endpointRe', scopeI18n)}</Text>
                  </Divider>
                </Col>
                <Col span={24}>
                  <Typography.Text
                    code
                  >{`${urlRoutingEngine}/api/${versionRoutingEngine}`}</Typography.Text>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row justify="end">
          <Col>
            {/* is flow of creation */}
            {canUpdateOrg && submitButton && <SubmitButton isLoading={savingData} />}
            {/* is normal flow of edition */}
            {canCreate && canUpdate && !submitButton && (
              <Button type="primary" htmlType="submit" loading={savingData}>
                {i18n.t('buttons.save')}
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    )
  );
}

RoutingGeneralForm.defaultProps = {
  settingsFields: [],
  onFinish: () => {},
  savingData: false,
  initialValues: {},
  submitButton: false,
  labels: [],
  organizationId: null,
};

RoutingGeneralForm.propTypes = {
  settingsFields: GenerarRoutingSettingPropTypes,
  onFinish: PropTypes.func,
  savingData: PropTypes.bool,
  initialValues: GenerarRoutingSettingPropTypes,
  submitButton: PropTypes.bool,
  labels: LabelsSelect,
  organizationId: PropTypes.number,
};

export default RoutingGeneralForm;
