import apiSlice from 'app/api/apiSlice';

/**
 * definitionsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const definitionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDefinitions: builder.query({
      query: (type) => `/definitions/by-type/${type}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Definitions', id })) : ['Definitions'],
    }),
    getDefinitionsByFilters: builder.mutation({
      query: (query) => `/definitions${query}`,
      providesTags: (result) =>
        result
          ? [
              result.map(({ id }) => ({ type: 'Definitions', id })),
              { type: 'Definitions', id: 'LIST' },
            ]
          : [{ type: 'Definitions', id: 'LIST' }],
    }),
    updateDefinition: builder.mutation({
      query: ({ id, body }) => ({
        url: `/definitions/${id}`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Definitions'],
    }),
    createDefinition: builder.mutation({
      query: (params) => ({
        url: '/definitions',
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['Definitions'],
    }),
  }),
});

export const {
  useGetDefinitionsQuery,
  useGetDefinitionsByFiltersMutation,
  useUpdateDefinitionMutation,
  useCreateDefinitionMutation,
} = definitionsApiSlice;
