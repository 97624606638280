import PropTypes from 'prop-types';

const pointShapePropTypes = PropTypes.shape({
  Cod: PropTypes.string,
  Nombre: PropTypes.string,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  usedRows: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  prevCoords: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  routeOrder: PropTypes.number,
  title: PropTypes.string,
});

const resumeCardContentPropTypes = PropTypes.shape({
  colSpan: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xl: PropTypes.number,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
});

const coordsArrayPropTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

export { pointShapePropTypes, resumeCardContentPropTypes, coordsArrayPropTypes };
