import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import {
  GetEtaFromDate,
  HslaColorByStatusEvent,
  statusByEvents,
  timeStatus,
} from 'services/repeated-functions';
import ItemDetails from '../../ItemDetails';
import itemTypes from '../../itemTypes.json';
import {
  darkenColor,
  depotIcon,
  drawRouteBackground,
  elementIcon,
  parkIcon,
  restIcon,
} from '../../repeatedDraw';

function DrawRoute(props) {
  const {
    route,
    routesData,
    dataIndex,
    routeColor,
    routeLightColor,
    routeDarkColor,
    depotColor,
    parkingColor,
    kind,
    vSpaceRoute,
    vRouteCenter,
    defaultStrokeWidth,
    startTime,
    hSpaceDrawBorder,
    pxRatio,
    pxOnTick,
    routesColorBy,
    routeLineThickness,
    minStopPxWidth,
    eta,
    timezone,
    currentTime,
  } = props;
  const currentItemTypes = Object.keys(itemTypes);

  const drawDisplay = [];
  const items = [...route.items].sort((a, b) => a.itemOrder - b.itemOrder);
  const yRouteHeight = vRouteCenter + dataIndex * vSpaceRoute - defaultStrokeWidth - 1;
  const [xEtaInitDraw, setXEtaInitDraw] = useState(0);
  const [timeDiff, setTImeDiff] = useState(0);
  const [showEta, setShowEta] = useState(eta); // Value to show eta for a single route

  useEffect(() => {
    if (!eta) return;
    const firstPendingItem = route.startedAt
      ? items.find(
          (item) => item.type === currentItemTypes[2] && statusByEvents(item.events) === 'PENDING'
        )
      : items[0];
    if (!firstPendingItem) {
      setShowEta(false);
      return;
    }
    setShowEta(true);
    const { eta: calculatedEta, timeDifference } = GetEtaFromDate(
      firstPendingItem,
      items,
      route.startsAt,
      timezone,
      [],
      false,
      currentTime
    );
    const diffEta = calculatedEta === startTime ? 0 : (calculatedEta - startTime) / 60000;
    const xBaseEtaInitDraw = hSpaceDrawBorder + diffEta * pxRatio;
    setXEtaInitDraw(xBaseEtaInitDraw - defaultStrokeWidth);
    setTImeDiff(timeDifference);
  }, [
    currentItemTypes,
    currentTime,
    defaultStrokeWidth,
    eta,
    hSpaceDrawBorder,
    items,
    pxRatio,
    route.startedAt,
    route.startsAt,
    startTime,
    timezone,
  ]);

  if (showEta) {
    drawDisplay.push(
      <foreignObject
        x={xEtaInitDraw - 15 / 2}
        y={yRouteHeight - routeLineThickness / 2}
        width={15}
        height={15}
        key={`eta-fo-${route.id}`}
      >
        <Tooltip
          title={timeStatus(timeDiff)}
          trigger="click"
          className="pointer"
          key={`eta-tp-${route.id}`}
        >
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={faLocationPin} color="#999999" />
          </div>
        </Tooltip>
      </foreignObject>
    );
  }

  drawDisplay.push(
    <g key={`${kind}-${dataIndex + 1}`}>
      <line
        x1={0}
        y1={vSpaceRoute * dataIndex}
        x2="100%"
        y2={vSpaceRoute * dataIndex}
        stroke="grey"
        strokeWidth={0.5}
      />
      {drawRouteBackground(route.startsAt, route.endsAt, dataIndex, routeLightColor, startTime, {
        pxRatio,
        hSpaceDrawBorder,
        vRouteCenter,
        vSpaceRoute,
        routeLineThickness,
        defaultStrokeWidth,
      })}
    </g>
  );

  // TODO: for drag and drop, draw elements between items to be setted as droppeable zones
  // so you be able to calculate the position to be inserted the item on the route more easly
  items.forEach((item, itemIndex) => {
    const elementKey = `${kind}${dataIndex + 1}-item${itemIndex}`;
    const arrivesAt = new Date(item.arrivesAt);
    const departsAt = new Date(item.departsAt);
    const drawElement = [];

    const isLastReturnTo = item.type.includes('RETURN_TO') && itemIndex + 1 === route.items.length;

    const diffInit = arrivesAt === startTime ? 0 : (arrivesAt - startTime) / 60000;
    const diffEnd = arrivesAt === departsAt ? 0 : (departsAt - arrivesAt) / 60000;
    const xBaseItemInitDraw = hSpaceDrawBorder + diffInit * pxRatio;
    const xItemInitDraw = xBaseItemInitDraw - (isLastReturnTo ? pxOnTick : 0) - defaultStrokeWidth;

    let elementWidth = 0;
    if (currentItemTypes.includes(item.type)) {
      // PARKING & it's RETURN_TO are treated as a DEPOT, with a fixed width
      elementWidth =
        item.type.includes('DEPOT') || item.type.includes('PARKING') ? pxOnTick : diffEnd * pxRatio;
    } else {
      // Check to setup an unknown element width as a DEPOT or a STOP
      elementWidth = arrivesAt === departsAt ? pxOnTick : diffEnd * pxRatio;
    }

    const statusDeptoColor = (date) =>
      routesColorBy === 'route-number' || date ? depotColor : '#999999';

    // Define characteristics of the item to be drawed per kind
    switch (item.type) {
      case currentItemTypes[0]:
        // RETURN_TO_DEPOT
        drawElement.push(
          'white', // fill
          statusDeptoColor(route.finishedAt), // stroke
          depotIcon(
            defaultStrokeWidth,
            defaultStrokeWidth,
            elementWidth,
            routeLineThickness,
            statusDeptoColor(route.finishedAt)
          ) // icon
        );
        break;
      case currentItemTypes[2]:
        // STOP
        drawElement.push(
          routesColorBy === 'route-number'
            ? routeColor
            : HslaColorByStatusEvent(statusByEvents(item.events)), // fill
          routesColorBy === 'route-number'
            ? routeDarkColor
            : darkenColor(HslaColorByStatusEvent(statusByEvents(item.events))), // stroke
          elementIcon(
            defaultStrokeWidth + 10,
            2 + defaultStrokeWidth + routeLineThickness / 2,
            item.itemOrder
          ) // icon
        );
        break;
      case currentItemTypes[3]:
        // DEPOT
        drawElement.push(
          'white', // fill
          statusDeptoColor(route.startedAt), // stroke
          depotIcon(
            defaultStrokeWidth,
            defaultStrokeWidth,
            elementWidth,
            routeLineThickness,
            statusDeptoColor(route.startedAt)
          ) // icon
        );
        break;
      case currentItemTypes[1]:
      case currentItemTypes[4]:
        // PARKING & it's RETURN_TO_PARKING
        drawElement.push(
          parkingColor, // fill
          parkingColor, // stroke
          parkIcon(
            defaultStrokeWidth + elementWidth / 2,
            2 + defaultStrokeWidth + routeLineThickness / 2
          ) // icon
        );
        break;
      case currentItemTypes[5]:
        // REST
        drawElement.push(
          'white', // fill
          statusDeptoColor(route.startedAt), // stroke
          restIcon(
            defaultStrokeWidth,
            defaultStrokeWidth,
            elementWidth,
            routeLineThickness,
            statusDeptoColor(route.startedAt)
          ) // icon
        );
        break;
      default:
        // UNKNOWN
        drawElement.push(
          'black', // fill
          'black', // stroke
          elementIcon(defaultStrokeWidth + 10, 2 + defaultStrokeWidth + routeLineThickness / 2, '?') // icon
        );
        break;
    }

    // Adds the representation of the item element to be drawn.
    // TODO: set items with stop kind as drageable
    drawDisplay.push(
      <foreignObject
        key={elementKey}
        x={xItemInitDraw}
        y={yRouteHeight}
        width={elementWidth + defaultStrokeWidth * 2}
        height={routeLineThickness + defaultStrokeWidth * 2}
      >
        <Popover
          trigger={kind === 'route' ? 'click' : 'none'}
          title={`Ruta ${route.routeOrder}`}
          arrow={false}
          className="pointer"
          content={
            <ItemDetails
              routeNumber={route.routeOrder}
              itemNumber={item.itemOrder}
              data={routesData}
              currentItemTypes={currentItemTypes}
            />
          }
          overlayStyle={{ width: item.type === currentItemTypes[2] ? '700px' : 'auto' }}
        >
          <svg
            width={elementWidth + defaultStrokeWidth * 2}
            height={routeLineThickness + defaultStrokeWidth * 2}
          >
            <rect
              x={defaultStrokeWidth}
              y={defaultStrokeWidth}
              rx={4}
              ry={4}
              width={elementWidth}
              height={routeLineThickness}
              fill={drawElement[0]}
              stroke={drawElement[1]}
              strokeWidth={defaultStrokeWidth}
            />
            {elementWidth >= minStopPxWidth && drawElement[2]}
          </svg>
        </Popover>
      </foreignObject>
    );
  });

  return drawDisplay;
}

export default DrawRoute;
