import { Button } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';

function FooterDrawer(props) {
  const { onClose, typeDrawer, form, buttonLoading, entity, labelPrimaryButton } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  return (
    <div className="footer-drawer-buttons">
      <Button
        onClick={(event) => {
          onClose(event);
        }}
        type={typeDrawer === 'show' ? 'primary' : 'default'}
        disabled={buttonLoading}
      >
        {typeDrawer === 'show' ? i18n.t('footerDrawer.exit') : i18n.t('footerDrawer.cancel')}
      </Button>
      {['create', 'edit'].includes(typeDrawer) ? (
        <Button
          type="primary"
          style={{ marginLeft: '2rem' }}
          onClick={() => form.submit()}
          loading={buttonLoading}
        >
          {labelPrimaryButton ||
            (entity.id ? i18n.t('footerDrawer.save') : i18n.t('footerDrawer.confirm'))}
        </Button>
      ) : (
        ''
      )}
    </div>
  );
}

FooterDrawer.propTypes = {
  onClose: PropTypes.func,
  typeDrawer: PropTypes.string,
  form: PropTypes.shape({ submit: PropTypes.func }),
  buttonLoading: PropTypes.bool,
  entity: PropTypes.shape({ id: PropTypes.number }),
  labelPrimaryButton: PropTypes.string,
};

FooterDrawer.defaultProps = {
  onClose: () => {},
  typeDrawer: 'show',
  form: {},
  buttonLoading: false,
  entity: {},
  labelPrimaryButton: undefined,
};

export default FooterDrawer;
