import { Col, Row, Skeleton } from 'antd';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import PageHeader from 'components/common/PageHeader';
import { useGetCategoriesPanelsQuery } from 'features/panels/panelsApiSlice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PanelForm from '../Form';

function PanelNew() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    data: categoriesData,
    isLoading,
    refetch: refetchCategories,
  } = useGetCategoriesPanelsQuery();

  useEffect(() => {
    if (!isLoading) {
      const uniqCategories = [...new Set(categoriesData)];
      const allCategories = uniqCategories.map((category) => ({
        label: category,
        value: category,
      }));
      setCategories(allCategories);
      setLoading(isLoading);
    }
    refetchCategories();
  }, [categoriesData, isLoading, refetchCategories]);

  const onBack = () => {
    navigate(`/reports/panels`);
  };

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/reports',
      label: 'Reportes',
    },
    {
      key: 'panels',
      url: '/reports',
      label: 'Paneles',
    },
    {
      key: 'panel-edit',
      url: `/reports/panel/new`,
      label: 'Crear',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <BreadcrumbComponent breadcrumbItems={breadcrumbItems} marginBottom="0px" />
        <PageHeader className="site-page-header" onBack={onBack} title="Crear panel" />
      </Col>
      <Col span={12}>
        <Skeleton active loading={loading}>
          <PanelForm categories={categories} />
        </Skeleton>
      </Col>
    </Row>
  );
}

export default PanelNew;
