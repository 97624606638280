import { Col, Divider, Row, Space, Typography } from 'antd';
import AlertMap from 'components/common/AlertMap';
import CaShowField from 'components/common/CustomAttributes/ShowField';
import ShowDynamicFields from 'components/common/Form/ShowDynamicFields';
import LeafletMap from 'components/common/Map/LeafletMap';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertDateToDayjs } from 'services/repeated-functions';
import { LocationsPropTypes } from 'types';
import LocaleContext from '../../locale/LocaleContext';

function LocationShow(props) {
  const { location, locations, locationType } = props;
  const {
    addresses,
    customAttributes,
    latitude,
    longitude,
    name,
    nid,
    updatedAt,
    createdAt,
    id,
    createdBy,
    contactPhones: phones,
    contactEmails: emails,
    contactId,
    contactName,
    category,
    restrictions,
    vip,
  } = location;

  const [mapPoints, setMapPoints] = useState([]);
  const [alertVisible, setAlertVisible] = useState(true);
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'locations' };

  useEffect(() => {
    if (locations) {
      const basePoints = [];
      locations.map((loc) => {
        if (loc.id !== id) {
          basePoints.push({ key: loc.id, lat: loc.latitude, lng: loc.longitude, active: false });
        }
        return basePoints;
      });
      setMapPoints(basePoints);
    }
  }, [id, locations]);

  const { Text } = Typography;

  const separatorStyle = { marginBottom: 8 };

  const renderIdentification = () => (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Text strong>{i18n.t('identifier', scopeI18n)}</Text>
      </Divider>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('code', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {nid}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('name', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {name}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('category', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {category}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('vip', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {i18n.t(vip ? 'yes' : 'no', scopeI18n)}
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
        <Text strong>{i18n.t('contactData', scopeI18n)}</Text>
      </Divider>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('dni', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          <Space direction="vertical">{contactId}</Space>
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('name', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          <Space direction="vertical">{contactName}</Space>
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('phoneNumber', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          <Space direction="vertical">{phones?.map((ph) => ph.phone)}</Space>
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('email', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          <Space direction="vertical">{emails?.map((ph) => ph.email)}</Space>
        </Col>
      </Row>
    </>
  );

  const renderLocation = () => (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Text strong>{i18n.t('location', scopeI18n)}</Text>
      </Divider>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('country', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {addresses?.country || '-'}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('province', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {addresses?.province || '-'}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('city', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {addresses?.city || '-'}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('address', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {addresses?.street || '-'} {addresses?.number || '-'}
        </Col>
      </Row>
    </>
  );

  const renderGeolocation = () => (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Text strong>{i18n.t('geolocation', scopeI18n)}</Text>
      </Divider>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('latitude', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {latitude}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={8} md={7}>
          <Text strong>{i18n.t('longitude', scopeI18n)}</Text>
        </Col>
        <Col span={16} md={15}>
          {longitude}
        </Col>
      </Row>
    </>
  );

  const renderCreationData = () => (
    <div style={{ marginBottom: 32 }}>
      <Divider orientation="left" orientationMargin="0">
        <Text strong>{i18n.t('creation', scopeI18n)}</Text>
      </Divider>
      <Row gutter={24} style={separatorStyle}>
        <Col span={12} md={10}>
          <Text strong>{i18n.t('createdAt', scopeI18n)}</Text>
        </Col>
        <Col span={12} md={8}>
          {convertDateToDayjs(createdAt, tzOrganization, 'DD-MM-YYYY HH:mm')}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={12} md={10}>
          <Text strong>{i18n.t('createdBy', scopeI18n)}</Text>
        </Col>
        <Col span={12} md={8}>
          {createdBy?.name} {createdBy?.lastName}
        </Col>
      </Row>
      <Row gutter={24} style={separatorStyle}>
        <Col span={12} md={10}>
          <Text strong>{i18n.t('updatedAt', scopeI18n)}</Text>
        </Col>
        <Col span={12} md={8}>
          {convertDateToDayjs(updatedAt, tzOrganization, 'DD-MM-YYYY HH:mm')}
        </Col>
      </Row>
    </div>
  );
  const hideButtonEvent = () => setAlertVisible(false);

  const renderMap = () => {
    const fullDataSource = [];

    if (latitude && longitude) {
      const key = location?.id || mapPoints.length + 1;
      fullDataSource.push([{ key, lat: latitude, lng: longitude, active: true }]);
    }

    if (mapPoints.length > 0) {
      fullDataSource.push(mapPoints);
    }

    return mapPoints.length > 0 ? (
      <div
        style={{
          height: alertVisible ? '55vh' : '63vh',
        }}
      >
        <AlertMap
          alertVisible={alertVisible}
          message={i18n.t('alert.pinIsCurrentLocation')}
          hideButtonEvent={hideButtonEvent}
        />
        <LeafletMap dataSource={fullDataSource} />
      </div>
    ) : (
      ''
    );
  };

  return (
    <Row gutter={32}>
      <Col span={12}>
        {renderIdentification()}
        {customAttributes?.map((ca) => (
          <CaShowField key={`casf${ca.id}`} customAttribute={ca} />
        ))}
        {renderLocation()}
        {renderGeolocation()}
      </Col>
      <Col span={12}>
        {renderCreationData()}
        {restrictions && (
          <div style={{ marginBottom: '1rem' }}>
            <ShowDynamicFields
              type={locationType}
              assignedValues={restrictions}
              span={12}
              md={10}
            />
          </div>
        )}
        {renderMap()}
      </Col>
    </Row>
  );
}

LocationShow.propTypes = {
  location: LocationsPropTypes,
  locations: PropTypes.arrayOf(LocationsPropTypes),
  locationType: PropTypes.string,
};

LocationShow.defaultProps = {
  location: {},
  locations: [],
  locationType: 'LOCATION',
};

export default LocationShow;
