import { App, Upload } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUploadUsersMutation } from 'features/users/usersApiSlice';
import {
  useGetFilesProcessingLastFiveQuery,
  useGetFilesProcessingQuery,
} from 'features/documents/fileProcessingApiSlice';

const { Dragger } = Upload;

function UserDragger(props) {
  const { fileList, setFileList, setConfirmLoading } = props;
  const [uploadUsers] = useUploadUsersMutation();
  const { refetch } = useGetFilesProcessingQuery();
  const { refetch: refetchLast } = useGetFilesProcessingLastFiveQuery();
  const { message } = App.useApp();

  const acceptedFormats = ['csv']; // use with accept attribute on dragger
  const progress = {
    strokeColor: {
      '0%': '#1A63D0',
      '100%': '#1A63D0',
    },
    size: '10',
    status: 'active',
    format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
  };

  const handleOnChangeUploadFile = (info) => {
    setFileList(info.fileList);
  };

  const handleOnChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} archivo subido correctamente.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} subida de archivo fallida.`);
    }
    setConfirmLoading(status === 'uploading');
    handleOnChangeUploadFile(info);
  };

  const onRemove = (file) => {
    if (fileList.some((item) => item.uid === file.uid)) {
      setFileList((fileList_) => fileList_.filter((item) => item.uid !== file.uid));
      return true;
    }
    return false;
  };

  // include all validation (data type, size)
  const beforeUpload = (file) => {
    const isAccepted = acceptedFormats.includes(file.name.split('.').pop());
    if (!isAccepted) {
      message.error(`${file.name} no tiene formato válido (${acceptedFormats.join(', ')})`);
      onRemove(file);
      setConfirmLoading(isAccepted);
    }
    return isAccepted;
  };

  const uploadFunction = async (options) => {
    const { file, onSuccess, onProgress } = options;
    onProgress({ percent: 30 });
    const res = await uploadUsers(file).unwrap();
    onProgress({ percent: 65 });
    // reload fileProcessing
    refetch();
    refetchLast();
    setTimeout(() => {
      onSuccess(res.message);
    }, 800);
  };
  return (
    <Dragger
      preserve={false}
      name="file"
      onChange={handleOnChange}
      fileList={fileList}
      customRequest={uploadFunction}
      maxCount={1}
      progress={progress}
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      accept=".csv"
    >
      <p className="ant-upload-drag-icon">
        <FontAwesomeIcon icon={['fas', 'inbox']} size="3x" />
      </p>
      <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo</p>
      <p className="ant-upload-hint">
        *Archivos compatibles: CSV separados por punto y coma &ldquo;;&ldquo; (.csv)
      </p>
    </Dragger>
  );
}

UserDragger.propTypes = {
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      lastModified: PropTypes.number,
      name: PropTypes.string,
      percent: PropTypes.number,
      size: PropTypes.number,
      status: PropTypes.string,
      type: PropTypes.string,
      uid: PropTypes.string,
      crossOrigin: PropTypes.string,
      thumbUrl: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  setFileList: PropTypes.func,
  setConfirmLoading: PropTypes.func,
};

UserDragger.defaultProps = {
  fileList: [],
  setFileList: () => {},
  setConfirmLoading: () => {},
};

export default UserDragger;
