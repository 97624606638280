import { Col, Row } from 'antd';
import { useGetPanelsQuery } from 'features/panels/panelsApiSlice';
import { useEffect, useState } from 'react';
import '../style.css';
import PanelFilter from './Filter';
import PanelList from './List';

function Panels() {
  const [panels, setPanels] = useState([]);
  const [triggerClean, setTriggerClean] = useState(false);
  const { data: panelsData, isLoading, refetch } = useGetPanelsQuery();

  useEffect(() => {
    const fetchData = () => {
      if (panelsData) {
        setPanels(panelsData);
      }
    };
    refetch();
    fetchData();
  }, [setPanels, isLoading, panelsData, refetch]);

  return (
    <Row gutter={24}>
      <Col span={24}>
        <PanelFilter setPanels={setPanels} triggerClean={triggerClean} />
      </Col>
      <Col span={24} className="panel-list-container">
        <PanelList panels={panels} key="panel-list" setTriggerClean={setTriggerClean} />
      </Col>
    </Row>
  );
}

export default Panels;
