import { App, Button, Col, Form, Input, InputNumber, Row, Space, Switch } from 'antd';
import SubmitButton from 'components/common/SubmitButton';
import TitleHelp from 'components/common/TitleHelp';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';

function EmailsForm(props) {
  const { initialValues, onFinish, savingData, submitButton } = props;
  const [form] = Form.useForm();
  const { message } = App.useApp();
  // permissions
  const ability = useContext(CaslContext);
  const canUpdate = checkPermissionForSubject(ability, 'update', 'communication');
  const canCreate = checkPermissionForSubject(ability, 'create', 'communication');
  const canSave = canUpdate && canCreate;
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };
  return (
    <Form
      form={form}
      initialValues={initialValues}
      name="emailsForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      disabled={!canSave}
    >
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item
            label={
              <TitleHelp
                title="Correo de salida"
                helpText="Dirección que se utilizará para el envío de correos, toda casilla nueva debe ser habilitada por el equipo TI"
              />
            }
            rules={[
              {
                type: 'email',
                message: 'No es un Email válido',
              },
              { required: true, message: 'Por favor ingrese un correo de salida' },
            ]}
            name="fromEmail"
          >
            <Input placeholder="Correo" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item
            name="enableEmails"
            label={<TitleHelp title="Activar envío de correos" />}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item
            name="enableFollowingButton"
            label={
              <TitleHelp
                title="Activar botón de seguimiento"
                helpText="Mostrar o no el botón de seguimiento en el correo de planificación"
              />
            }
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item
            name="enableRatingButton"
            label={
              <TitleHelp
                title="Habilitar botón de evaluación"
                helpText="Permite mostrar el botón de Evaluación en correo de pedido finalizado"
              />
            }
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item
            name="followingViewLife"
            label={
              <TitleHelp
                title="Duración de vista de seguimiento"
                helpText="Tiempo que estará disponible para consulta la vista de seguimiento por correo"
              />
            }
            rules={[
              {
                type: 'number',
                message: 'No es un número válido',
              },
              { required: true },
            ]}
            extra="Este campo tiene que contener número"
          >
            <InputNumber addonAfter="Horas" min="1" max="48" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            {/* is flow of creation */}
            {canUpdate && submitButton && <SubmitButton isLoading={savingData} />}
            {/* is normal flow of edition */}
            {canUpdate && !submitButton && (
              <Button type="primary" htmlType="submit" loading={savingData} disabled={!canSave}>
                Guardar
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

EmailsForm.propTypes = {
  initialValues: PropTypes.shape({
    defaultAction: PropTypes.string,
  }),
  onFinish: PropTypes.func,
  savingData: PropTypes.bool,
  submitButton: PropTypes.bool,
};

EmailsForm.defaultProps = {
  initialValues: {
    defaultAction: 'delivery',
  },
  onFinish: () => {},
  savingData: false,
  submitButton: false,
};

export default EmailsForm;
