import {
  App,
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import TitleHelp from 'components/common/TitleHelp';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConvertToMinutes } from 'services/repeated-functions';
import { SynopticSettings } from '../SynopticSettings.propTypes';

const { Text } = Typography;

// Returns the drawer where the settings of the synoptic can be changed.
function SynopticSettingsDrawer(props) {
  const {
    userSettings,
    setUserSettings,
    settingsVisible,
    setSettingsVisible,
    drawerWidth,
    defaultSettings,
    setDefaultSettings,
  } = props;
  const { referenceDate, shownTimeBackward, shownTimeForward, minimalTick, minimalTickReference } =
    userSettings;

  const [form] = Form.useForm();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const { message } = App.useApp();

  // Predefined tick minutes representation values
  const timeTicksValues = [
    { label: '1 minuto', value: '1' },
    { label: '2 minutos', value: '2' },
    { label: '5 minutos', value: '5' },
    { label: '10 minutos', value: '10' },
    { label: '15 minutos', value: '15' },
    { label: '20 minutos', value: '20' },
    { label: '30 minutos', value: '30' },
    { label: '60 minutos', value: '60' },
    { label: '120 minutos', value: '120' },
  ];

  const formatedDate = dayjs(referenceDate, 'DD-MM-YYYY HH:mm');
  const initialValues = useMemo(() => {
    return {
      timeBackward: shownTimeBackward.toString(),
      timeForward: shownTimeForward.toString(),
      timeBtwnTicks: ConvertToMinutes(minimalTick, minimalTickReference).toString(),
    };
  }, [shownTimeBackward, shownTimeForward, minimalTick, minimalTickReference]);

  // Validation of changed values based on the modified values.
  const hasChangedValues = (values) => {
    return Object.keys(values).every((key) => values[key] === initialValues[key]);
  };

  const validateHoursFormat = (_, value, maxHours) => {
    if (value.match(/^\d*\.?\d$/)) {
      if (Number(value) > maxHours) {
        setSaveButtonDisabled(true);
        return Promise.reject(
          new Error(`El valor no puede ser mayor a ${maxHours} ${maxHours > 1 ? 'horas' : 'hora'}`)
        );
      }
      setSaveButtonDisabled(false);
      return Promise.resolve();
    }
    setSaveButtonDisabled(true);
    return Promise.reject(
      new Error('Debe ingresar un valor numérico positivo de hasta un decimal.')
    );
  };

  const resetForm = useCallback(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const restoreDefaultValues = () => {
    if (defaultSettings) {
      message.warning('Ya estan definidos los valores por defecto.', 4);
    } else {
      setDefaultSettings(true);
      message.success('Se ha restaurado la configuración por defecto.', 4);
      resetForm();
    }
    setSettingsVisible(false);
  };

  const onClose = (event) => {
    event?.stopPropagation();
    resetForm();
    setSettingsVisible(false);
  };

  const onFinish = async (values) => {
    if (hasChangedValues(values)) {
      message.warning('No se han realizado cambios.', 4);
    } else {
      const formatedValues = {
        referenceDate,
        shownTimeForward: Number(values.timeForward),
        shownTimeBackward: Number(values.timeBackward),
        shownTimeReference: 'hours',
        minimalTick: Number(values.timeBtwnTicks),
        minimalTickReference: 'minutes',
      };
      setUserSettings({ ...userSettings, ...formatedValues });
      setDefaultSettings(false);
      message.success('Se ha actualizado la configuración del Sinoptico.', 4);
      form.setFieldsValue(values);
    }
    setSettingsVisible(false);
  };

  useEffect(() => {
    setSettingsVisible(settingsVisible);
    if (settingsVisible) {
      resetForm();
    }
  }, [settingsVisible, setSettingsVisible, resetForm]);

  return (
    <Drawer
      title="Configuración"
      width={drawerWidth}
      onClose={(event) => onClose(event)}
      open={settingsVisible}
      style={{ body: { paddingBottom: 80 } }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={
        <Row>
          <Col span={14}>
            <Button
              type="link"
              size="large"
              onClick={restoreDefaultValues}
              disabled={defaultSettings}
            >
              Valores por defecto
            </Button>
          </Col>
          <Col span={10}>
            <div className="footer-drawer-buttons">
              <Button onClick={onClose}>Cancelar</Button>
              <Button
                id="saveButton"
                form="synoptic-settings"
                type="primary"
                htmlType="submit"
                style={{ marginLeft: '2rem' }}
                disabled={saveButtonDisabled}
              >
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <div className="drawer-container">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          name="synoptic-settings"
          initialValues={initialValues}
          autoComplete="off"
          requiredMark="required"
        >
          <Divider orientation="left">
            <Text strong>Rango de fecha y hora</Text>
          </Divider>
          <Row gutter={24}>
            <Col span={12}>
              <div style={{ margin: '0 0 8px' }}>
                <Text>Fecha y hora de referencia</Text>
              </div>
              <DatePicker
                disabled
                showTime={{ format: 'HH:mm' }}
                format="DD-MM-YYYY HH:mm"
                style={{ margin: '0 0 24px' }}
                defaultValue={formatedDate}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={
                  <TitleHelp
                    title="Hacia atrás"
                    helpText="Permite definir cuantas horas hacia ATRÁS se quiere visualizar en el sinóptico en base a la 'Fecha y hora de referencia'"
                  />
                }
                name="timeBackward"
                size="large"
                required
                rules={[{ validator: (_, value) => validateHoursFormat(_, value, 72) }]}
              >
                <Input addonAfter="horas" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <TitleHelp
                    title="Hacia adelante"
                    helpText="Permite definir cuantas horas hacia ADELANTE se quiere visualizar en el sinóptico en base a la 'Fecha y hora de referencia'"
                  />
                }
                name="timeForward"
                size="large"
                required
                rules={[{ validator: (_, value) => validateHoursFormat(_, value, 72) }]}
              >
                <Input addonAfter="horas" />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">
            <Text strong>Grilla</Text>
          </Divider>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={
                  <TitleHelp
                    title="Ancho mínimo de la columna"
                    helpText="Permite definir la representación de tiempo entre cada marca de tiempo en el encabezado del sinóptico"
                  />
                }
                name="timeBtwnTicks"
                required
                size="large"
              >
                <Select options={timeTicksValues} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Drawer>
  );
}
SynopticSettingsDrawer.defaultProps = {
  // *** User defined setings
  userSettings: {
    referenceDate: dayjs(), // Base date to be shown in the draw.
    shownTimeForward: 36, // Amount of time to be shown forward of the reference date
    shownTimeBackward: 3, // Amount of time to be shown backward of the reference date
    shownTimeReference: 'hours', // Set for a time convertion.
    minimalTick: 15, // 1, 5, 10, 15, 20, 30, 60 is recomended. 240 doesnt work quite well.
    minimalTickReference: 'minutes', // Set for time convertion.
  },
  setUserSettings: () => {}, // function. The function that set the synoptic settings.
  settingsVisible: false, // boolean. Variable that sets the visibility of the settings drawer.
  setSettingsVisible: () => {}, // function. The function that sets the value for the value of the var that sets the visibility of the drawer.
  defaultSettings: true, // boolean. Variable to check if the settings values are the default.
  setDefaultSettings: () => {}, // function. Updates the variable when the settings are changed.
  drawerWidth: '550px', // string. The width definition for the settings drawer.
};
SynopticSettingsDrawer.propTypes = {
  userSettings: SynopticSettings,
  setUserSettings: PropTypes.func,
  settingsVisible: PropTypes.bool,
  setSettingsVisible: PropTypes.func,
  defaultSettings: PropTypes.bool,
  setDefaultSettings: PropTypes.func,
  drawerWidth: PropTypes.string,
};

export default SynopticSettingsDrawer;
