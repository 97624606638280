import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from '../features/app/appSlice';
import authReducer from '../features/auth/authSlice';
import errorReducer from '../features/errors/errorsSlice';
import userReducer from '../features/users/userSlice';
import apiSlice from './api/apiSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [apiSlice.reducerPath],
};

const combinedReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  currentUser: userReducer,
  errors: errorReducer,
  appSlice: appReducer,
});

const rootReducer = (state, action) => {
  let dupState = state;
  // clear all state if logout
  if (action.type === 'auth/logOut') {
    dupState = undefined;
  }
  return combinedReducer(dupState, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
