import { App, Button, Col, Form, Row, Table, Typography } from 'antd';
import ModalConfirm from 'components/common/ModalConfirm';
import TableButtons from 'components/common/TableButtons';
import TableFilters from 'components/common/TableFilter';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import {
  useDeleteOrRestoreVehicleMutation,
  useGetVehiclesByFiltersMutation,
  useMassiveDeleteOrRestoreVehicleMutation,
} from 'features/vehicles/vehiclesApiSlice';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { loadsFromOrgType } from 'types';
import Can from 'utils/Can';
import { VehiclePropTypes } from '../vehicles.propTypes';

const { Text } = Typography;
function TableVehicles(props) {
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [massiveArchiveRestoreModalVisible, setMassiveArchiveRestoreModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'vehicles' };

  // custom hooks
  const [deleteRestoreVehicle] = useDeleteOrRestoreVehicleMutation();
  const [getVehiclesByFilters] = useGetVehiclesByFiltersMutation();
  const [massiveChanges, { isLoading: isMassive }] = useMassiveDeleteOrRestoreVehicleMutation();

  const {
    setDrawerData,
    loading,
    data,
    setDataCallback,
    loadsFromOrg,
    triggerClean,
    setTriggerClean,
    paginationData,
  } = props;
  const { setTotalData, totalData, setActualPage, actualPage, downloadQuery, setDownloadQuery } =
    paginationData;

  const archiveRestoreVehicle = async () => {
    try {
      await deleteRestoreVehicle(selectedVehicle.id).unwrap();
      const successMessage = i18n.t(
        selectedVehicle.deletedAt ? 'elementUnarchived' : 'elementArchived',
        {
          scope: 'commons',
          element: i18n.t('vehicle'),
        }
      );
      message.success(successMessage);
    } catch (error) {
      const errorMessage = i18n.t(
        selectedVehicle.deletedAt ? 'cannotUnarchiveElement' : 'cannotArchiveElement',
        { scope: 'commons', element: i18n.t('vehicle') }
      );
      message.error(errorMessage);
    }
  };

  const onConfirmAction = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await archiveRestoreVehicle();
    setTriggerClean(false);
    setIsModalVisible(false);
  };
  const onCancelAction = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
    setSelectedVehicle();
  };
  const showModal = (event, record) => {
    event.stopPropagation();
    setSelectedVehicle(record);
    setIsModalVisible(true);
  };

  const onClickActionButton = (type, record) => {
    const drawerData = {
      drawerType: type,
      drawerVisibility: true,
      drawerVehicle: record,
    };
    setDrawerData(drawerData);
  };

  // rowSelection object indicates the need for row selection
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const showMassiveChangeStatusModal = (event) => {
    event.stopPropagation();
    setMassiveArchiveRestoreModalVisible(true);
  };
  const onMassiveChangeStatusCancelAction = (event) => {
    event.stopPropagation();
    setMassiveArchiveRestoreModalVisible(false);
  };

  const buttonsTable = (record) => (
    <TableButtons
      subject="fleets"
      record={record}
      readClickHandle={onClickActionButton}
      editClickHandle={onClickActionButton}
      deleteClickHandle={showModal}
    />
  );
  const massiveArchiveRestore = async () => {
    try {
      const result = await massiveChanges({ ids: selectedRowKeys }).unwrap();
      const { data: dataLocation, total } = await getVehiclesByFilters('').unwrap();
      setTotalData(total);
      setDataCallback(dataLocation);
      const { totalArchived, totalRestored } = result;
      const textArchived =
        totalArchived > 0 ? i18n.t('totalArchived', { ...scopeI18n, count: totalArchived }) : '';
      const textRestored =
        totalRestored > 0 ? i18n.t('totalUnarchived', { ...scopeI18n, count: totalRestored }) : '';
      const successText = i18n.t('changeStatusMassiveDetail', {
        ...scopeI18n,
        archived: textArchived,
        restored: textRestored,
      });
      message.success(successText);
      setSelectedRowKeys([]);
    } catch (error) {
      console.log(error);
      message.error(i18n.t('errors.changeStatusMassive', scopeI18n));
    }
  };
  const onMassiveChangeStatusOkAction = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await massiveArchiveRestore();
    setTriggerClean(false);
    setMassiveArchiveRestoreModalVisible(false);
  };

  const titleMassiveChangeStatus = i18n.t('sureChangeMassiveVehicles', {
    ...scopeI18n,
    count: selectedRowKeys?.length,
  });
  const renderMassiveModalConfirm = () => (
    <ModalConfirm
      onOk={onMassiveChangeStatusOkAction}
      onCancel={onMassiveChangeStatusCancelAction}
      title={titleMassiveChangeStatus}
      isModalVisible={massiveArchiveRestoreModalVisible}
      okButtonProps={{ danger: false }}
      okText={i18n.t('changeStatus', scopeI18n)}
      status="info"
    />
  );

  const columns = [
    {
      title: (
        <TitleHelp title={i18n.t('nid', scopeI18n)} helpText={i18n.t('helps.nid', scopeI18n)} />
      ),
      dataIndex: 'nid',
      key: 'nid',
      width: 200,
    },
    {
      title: i18n.t('plate', scopeI18n),
      dataIndex: 'plate',
      key: 'plate',
      width: 150,
    },
    ...loadsFromOrg.map((load, index) => {
      return {
        title: load.name,
        dataIndex: `load${load.key}`,
        key: `load${load.key}`,
        width: 200,
        render: (_text, record) => {
          const loadFromRecord = record.loads[index];
          return loadFromRecord?.load;
        },
      };
    }),
    {
      key: 'action',
      width: 320,
      render: (_text, record) => buttonsTable(record),
    },
  ];

  const fieldsFilter = [
    {
      name: 'nid',
      label: i18n.t('nid', scopeI18n),
      type: 'text',
      minCharsToSearch: 2,
    },
    {
      name: 'plate',
      label: i18n.t('plate', scopeI18n),
      type: 'text',
    },
    {
      name: 'loads',
      label: i18n.t('loads', scopeI18n),
      type: 'number',
    },
    {
      name: 'deletedAt',
      label: i18n.t('status', scopeI18n),
      type: 'select',
      options: [
        { value: 'true', label: i18n.t('archived', { scope: 'commons' }) },
        { value: 'false', label: i18n.t('unarchived', { scope: 'commons' }) },
      ],
    },
  ];

  const okText = selectedVehicle?.deletedAt
    ? i18n.t('commons.unarchive')
    : i18n.t('commons.archive');
  const titleModal = i18n.t(selectedVehicle?.deletedAt ? 'sureUnarchive' : 'sureArchive', {
    scope: 'commons',
    element: i18n.t('vehicles.vehicle'),
  });
  const colorButtonProps = { danger: selectedVehicle?.deletedAt === null };
  const modalStatus = selectedVehicle?.deletedAt ? 'warning' : 'error';

  const onChangePage = async (page, pageSize) => {
    // include filter parameters
    const filterQuery = downloadQuery !== '' ? `&${downloadQuery.slice(1)}` : '';
    const queryString = `?page=${page}&take=${pageSize}${filterQuery}`;
    try {
      setActualPage(page);
      const { data: dataLocation, total } = await getVehiclesByFilters(queryString).unwrap();
      setTotalData(total);
      setDataCallback(dataLocation);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const pagination = {
    showTotal: (total) => `Total: ${total}`,
    total: totalData,
    showSizeChanger: true,
    onChange: onChangePage,
    current: actualPage,
  };

  return (
    <>
      <TableFilters
        setDataCallback={setDataCallback}
        fieldsFilter={fieldsFilter}
        showTitle={false}
        triggerClean={triggerClean}
        reduxFunc={getVehiclesByFilters}
        setDownloadQuery={setDownloadQuery}
        paginationData={{ setTotalData, setActualPage }}
      />
      <Row gutter={24} style={{ height: '100%' }} className="vehicles-container">
        <Col flex="auto">
          <Can key="delete-massive" I="delete" a="visits">
            <Button
              onClick={showMassiveChangeStatusModal}
              disabled={!hasSelected}
              loading={isMassive}
              style={{ marginBottom: '1rem' }}
            >
              {i18n.t('changeStatus', scopeI18n)}
            </Button>
          </Can>
          {selectedRowKeys.length > 0 ? (
            <Text style={{ paddingLeft: '.5rem' }}>
              {i18n.t('totalRowsSelected', { ...scopeI18n, total: selectedRowKeys.length })}
            </Text>
          ) : (
            ''
          )}
          <Form form={form}>
            <Table
              rowSelection={rowSelection}
              rowKey="id"
              columns={columns}
              dataSource={data}
              loading={loading}
              pagination={pagination}
            />
          </Form>
        </Col>
      </Row>
      <ModalConfirm
        onOk={onConfirmAction}
        onCancel={onCancelAction}
        title={titleModal}
        isModalVisible={isModalVisible}
        okButtonProps={colorButtonProps}
        okText={okText}
        status={modalStatus}
      />
      {renderMassiveModalConfirm()}
    </>
  );
}

TableVehicles.propTypes = {
  setDrawerData: PropTypes.func,
  setDataCallback: PropTypes.func,
  loading: PropTypes.bool,
  loadsFromOrg: loadsFromOrgType,
  data: PropTypes.arrayOf(VehiclePropTypes),
  triggerClean: PropTypes.bool,
  setTriggerClean: PropTypes.func,
  paginationData: PropTypes.shape({
    setTotalData: PropTypes.func,
    totalData: PropTypes.number,
    setActualPage: PropTypes.func,
    actualPage: PropTypes.number,
    downloadQuery: PropTypes.string,
    setDownloadQuery: PropTypes.func,
  }),
};

TableVehicles.defaultProps = {
  setDrawerData: () => {},
  setDataCallback: () => {},
  loading: true,
  data: [],
  loadsFromOrg: [],
  triggerClean: true,
  setTriggerClean: () => {},
  paginationData: {},
};

export default TableVehicles;
