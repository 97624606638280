import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import image1 from 'assets/img/help/simple/howtosimple1.png';
import image2 from 'assets/img/help/simple/howtosimple2.png';

const { Text, Title } = Typography;

function HowToImport(props) {
  const { downloadExampleFile, helpBreadcrumbs } = props;

  return (
    <>
      {helpBreadcrumbs()}
      <Row gutter={[12, 12]} justify={'center'}>
        <Col md={{ span: 16 }} xs={{ span: 24 }}>
          <Title className="help-title">¿Cómo importar mis demandas?</Title>
          <Text>Después de crear o importar tus </Text>
          <Link to={'/configurations'}>
            <u>vehículos</u>
          </Link>
          <Text> y </Text>
          <Link to={'/locations'}>
            <u>visitas</u>
          </Link>
          <Text> , puedes importar tus demandas de la siguiente manera:</Text>
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} className="help-list-item">
          <Text>1. Haz clic en el botón </Text>
          <Text strong>“Cargar demandas”</Text>
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} align="middle">
          <img src={image1} alt={'img1'} className="help-howtoimport-image1" />
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} className="help-list-item">
          <Text>2. Se desplegará un menú con dos opciones:</Text>
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} align="middle">
          <img src={image2} alt={'image2'} className="help-howtoimport-image2" />
        </Col>
        <br />
        <Col md={{ span: 16 }} xs={{ span: 24 }} className="help-list-item">
          <Text strong>Opción 1: Avanzado: </Text>
          <br />
          <ul>
            <li>
              <Text strong>Descripción: </Text>
              <Text>
                Crea y parametriza demandas para optimización con la lógica de The Optimal.
              </Text>
            </li>
            <li>
              <Text strong>Instrucciones: </Text>
              <Text> Llena nuestro formato de CSV y súbelo a la plataforma.</Text>
            </li>
            <li>
              <Text strong>Plantilla: </Text>
              <Link onClick={() => downloadExampleFile('advanced')} component={Typography.Link}>
                <u>Descargar la plantilla de ejemplo avanzado.</u>
              </Link>
            </li>
            <li>
              <Text strong>Requisitos: </Text>
              <Link to={'/help/detail/format-requirements-demands'}>
                <u>Ver requisitos del formato avanzado.</u>
              </Link>
            </li>
          </ul>
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} className="help-list-item">
          <Text strong>Opción 1: Simple: </Text>
          <br />
          <ul>
            <li>
              <Text strong>Descripción: </Text>
              <Text>
                Crea rutas directamente sin optimización donde estaría ubicadas directamente en la
                lista de rutas. Recuerda que en esta opción deberás especificar el número de rutas y
                orden de cada parada que tendrá tu planificación.
              </Text>
            </li>
            <li>
              <Text strong>Instrucciones: </Text>
              <Text>Llena nuestro formato CSV y súbelo a la plataforma.</Text>
            </li>
            <li>
              <Text strong>Plantilla: </Text>
              <Link onClick={() => downloadExampleFile('simple')} component={Typography.Link}>
                <u>Descargar la plantilla de ejemplo simple.</u>
              </Link>
            </li>
            <li>
              <Text strong> Requisitos: </Text>
              <Link to={'/help/detail/format-requirements-simple'}>
                <u>Ver requisitos del formato simple.</u>
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
}

HowToImport.defaultProps = {
  downloadExampleFile: () => {},
  helpBreadcrumbs: () => {},
};

HowToImport.propTypes = {
  downloadExampleFile: PropTypes.func,
  helpBreadcrumbs: PropTypes.func,
};

export default HowToImport;
