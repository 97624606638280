import { Result } from 'antd';
import noResponsive from 'assets/img/no-responsive.svg';

// ToDo: maybe return with col xs 24 md 0 to show
function NoResponsiveComponent() {
  return (
    <Result
      style={{ padding: '3rem 0px' }}
      icon={<img src={noResponsive} alt="Visisble sólo en ordenador" />}
      subTitle="Esta función no es compatible con dispositivos móviles. Por favor, utiliza un ordenador para acceder a ella."
    />
  );
}

export default NoResponsiveComponent;
