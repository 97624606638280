import { Col } from 'antd';
import NoResponsiveComponent from '../layout/NoResponsiveContent';
import UsersList from './List';
import './style.css';

function Users() {
  return (
    <>
      <Col md={24} xs={0}>
        <UsersList />
      </Col>
      <Col md={0} xs={24}>
        <NoResponsiveComponent />
      </Col>
    </>
  );
}

export default Users;
