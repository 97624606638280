import PropTypes from 'prop-types';

const oneOfAllTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.array,
  PropTypes.bool,
]);

export const FieldDefinitionPropType = PropTypes.shape({
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: oneOfAllTypes,
      label: PropTypes.string,
    })
  ),
  type: PropTypes.string,
  minCharsToSearch: PropTypes.number,
  defaultValue: oneOfAllTypes,
});

export const FieldsFilterPropType = PropTypes.arrayOf(FieldDefinitionPropType);

export const FormPropType = PropTypes.shape({
  getFieldError: PropTypes.func,
  getFieldInstance: PropTypes.func,
  getFieldValue: PropTypes.func,
  getFieldWarning: PropTypes.func,
  getFieldsError: PropTypes.func,
  getFieldsValue: PropTypes.func,
  getInternalHooks: PropTypes.func,
  isFieldTouched: PropTypes.func,
  isFieldValidating: PropTypes.func,
  isFieldsTouched: PropTypes.func,
  isFieldsValidating: PropTypes.func,
  resetFields: PropTypes.func,
  scrollToField: PropTypes.func,
  setFields: PropTypes.func,
  setFieldsValue: PropTypes.func,
  submit: PropTypes.func,
  validateFields: PropTypes.func,
});

export const TableFilterPropType = {
  setDataCallback: PropTypes.func,
  fieldsFilter: FieldsFilterPropType,
  advancedFieldsFilter: FieldsFilterPropType,
  showTitle: PropTypes.bool,
  triggerClean: PropTypes.bool,
  reduxFunc: PropTypes.func.isRequired,
  extraParams: PropTypes.string,
  setDownloadQuery: PropTypes.func,
  paginationData: PropTypes.shape({
    setTotalData: PropTypes.func,
    setActualPage: PropTypes.func,
  }),
};
