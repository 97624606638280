import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorBoundary from './layout/ErrorBoundary';

const { Content } = Layout;

function SecondaryLayout(props) {
  const { isOffline } = props;
  return (
    <div className="app">
      <div className="right-panel">
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <ErrorBoundary offline={isOffline}>
            <div className="site-layout-content-secondary">
              <Outlet />
            </div>
          </ErrorBoundary>
        </Content>
      </div>
    </div>
  );
}

SecondaryLayout.propTypes = {
  isOffline: PropTypes.bool,
};

SecondaryLayout.defaultProps = {
  isOffline: true,
};

export default SecondaryLayout;
