import PropTypes from 'prop-types';
import React from 'react';
import { Polygon, Polyline } from 'react-leaflet';

function LeafletPolyline(props) {
  const { routeId, coords, color, showPolygon } = props;
  const polyLineOptions = { color };
  const polygonOptions = { color, fillOpacity: 0.3 };
  return (
    <>
      <Polyline pathOptions={polyLineOptions} positions={coords} key={routeId} />
      {showPolygon && <Polygon pathOptions={polygonOptions} positions={coords} />}
    </>
  );
}

LeafletPolyline.defaultProps = {
  routeId: 0,
  coords: [],
  color: '',
  showPolygon: true,
};

LeafletPolyline.propTypes = {
  routeId: PropTypes.number,
  coords: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ),
  color: PropTypes.string,
  showPolygon: PropTypes.bool,
};

export default LeafletPolyline;
