import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const SynopticLoads = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      load: PropTypes.number,
    }),
    PropTypes.shape({
      load: PropTypes.shape({
        load: PropTypes.number,
      }),
    }),
  ])
);

const SynopticItem = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nid: PropTypes.string,
  action: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  type: PropTypes.string,
  arrivesAt: PropTypes.string,
  departsAt: PropTypes.string,
  addr: PropTypes.shape({
    country: PropTypes.string,
    province: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.string,
    line2: PropTypes.string,
  }),
});

const SynopticItems = PropTypes.arrayOf(SynopticItem);

const SynopticRoute = PropTypes.shape({
  action: PropTypes.string,
  assignment: PropTypes.string,
  endsAt: PropTypes.string,
  id: PropTypes.number.isRequired,
  items: SynopticItems,
  key: PropTypes.number,
  loads: SynopticLoads,
  route: PropTypes.number,
  startsAt: PropTypes.string,
  totalDistance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalStops: PropTypes.number,
  travelTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const SynopticRelativeRoute = PropTypes.shape({
  routeId: PropTypes.number,
  routeOrder: PropTypes.number,
  relatedRoutes: PropTypes.arrayOf(SynopticRoute),
});

const SynopticData = PropTypes.arrayOf(SynopticRoute);
const SynopticRelativeRoutes = PropTypes.arrayOf(SynopticRelativeRoute);

const SynopticSettings = PropTypes.shape({
  referenceDate: PropTypes.oneOfType([PropTypes.instanceOf(dayjs)]),
  shownTimeForward: PropTypes.number,
  shownTimeBackward: PropTypes.number,
  shownTimeReference: PropTypes.string,
  showRouteNumber: PropTypes.bool,
  minimalTick: PropTypes.number,
  minimalTickReference: PropTypes.string,
});

const TootltipsEnabledSettings = PropTypes.shape({
  parameterEnabled: PropTypes.bool,
  expandEnabled: PropTypes.bool,
  hideEnabled: PropTypes.bool,
});

export {
  SynopticData,
  SynopticItem,
  SynopticRelativeRoutes,
  SynopticRoute,
  SynopticSettings,
  TootltipsEnabledSettings,
};
