import PropTypes from 'prop-types';
import { Row, Select } from 'antd';
import TitleHelp from 'components/common/TitleHelp';

function StatusFilter(props) {
  const { routingSetData, setRoutes } = props;

  const onFilter = (status_array) => {
    const filteredRoutes =
      status_array.length === 0
        ? routingSetData?.routes
        : routingSetData?.routes.filter((route) => status_array.includes(route?.status));
    setRoutes(filteredRoutes);
  };

  return (
    <Row justify="end" style={{ marginBottom: '15px', marginRight: '5px' }}>
      <TitleHelp
        title="Estado"
        helpText="Permite filtrar el listado de rutas en base al estado actual de cada una."
      />
      <Select
        mode="multiple"
        allowClear
        style={{ width: '200px', marginLeft: '5px' }}
        placeholder="Seleccione"
        onChange={(value) => {
          onFilter(value);
        }}
        options={[
          { value: 'CREATED', label: 'Creada' },
          { value: 'ASSIGNED', label: 'Asignada' },
          { value: 'STARTED', label: 'En curso' },
          { value: 'FINISHED', label: 'Finalizada' },
        ]}
      />
    </Row>
  );
}

StatusFilter.defaultProps = {
  routingSetData: () => {},
  setRoutes: () => {},
};

StatusFilter.propTypes = {
  routingSetData: PropTypes.object,
  setRoutes: PropTypes.func,
};

export default StatusFilter;
