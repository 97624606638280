import { Alert, App, Col, Form, Input, InputNumber, Row, Select, Skeleton, Space, Tag } from 'antd';
import SelectPlusForm from 'components/common/SelectPlusForm';
import TitleHelp from 'components/common/TitleHelp';
import { useGetParametersByProfileQuery } from 'features/routingEngine/routingEngineApiSlice';
import {
  useCreateRoutingParameterMutation,
  useGetRoutingParametersQuery,
  useUpdateRoutingParameterMutation,
} from 'features/routingParameters/routingParametersApiSlice';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { sortSelect } from 'services/repeated-functions';
import { formType } from 'types';
import RoutingParametersPropTypes from './routingParameters.propTypes';

function RoutingParametersForm(props) {
  const {
    onCloseDrawer,
    parameter,
    form,
    setButtonLoading,
    drawerVisible,
    profiles,
    organizationId,
    labels,
    setTriggerClean,
  } = props;
  const isEdit = parameter?.id !== undefined;
  const [selectedProfile, setSelectedProfile] = useState(isEdit ? parameter.profile : undefined);
  const [selectedParameter, setSelectedParameter] = useState();
  const [parameters, setParameters] = useState([]);
  const [allParameters, setAllParameters] = useState([]);
  const [parametersWithOtherProfile, setParametersWithOtherProfile] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(isEdit ? parameter.label : undefined);
  const { message } = App.useApp();

  // custom hooks
  const { data: parametersData, isLoading: parametersFetching } = useGetParametersByProfileQuery(
    selectedProfile,
    {
      skip: selectedProfile === undefined,
    }
  );
  const [createParameter] = useCreateRoutingParameterMutation();
  const [updateParameter] = useUpdateRoutingParameterMutation();
  const organizationIdParam = organizationId ? `?organizationId=${organizationId}` : '';

  const {
    data: routingParametersData,
    isLoading,
    refetch,
  } = useGetRoutingParametersQuery(organizationIdParam);

  useEffect(() => {
    if (!isLoading) {
      setAllParameters(routingParametersData);
    }
    refetch();
  }, [isLoading, refetch, routingParametersData]);

  useEffect(() => {
    const checkDisabled = (currName) => {
      return allParameters
        .filter(({ profile, label }) => profile === selectedProfile && label === selectedLabel)
        .some(({ name: pname }) => pname === currName);
    };
    if (!parametersFetching && parametersData) {
      const { krakenconfigs } = parametersData;
      const parametersForSelect = krakenconfigs.map(({ name }, index) => ({
        key: `param-idx-${index}`,
        value: name,
        label: name,
        disabled: checkDisabled(name),
      }));
      setParameters(parametersForSelect);
      if (isEdit) {
        const selected = krakenconfigs.find(({ name }) => name === parameter.name);
        setSelectedParameter(selected);
      }
    }
  }, [
    allParameters,
    isEdit,
    parameter.name,
    parametersData,
    parametersFetching,
    selectedLabel,
    selectedProfile,
  ]);

  const handleChangeParameter = (value) => {
    const { krakenconfigs } = parametersData;
    const parameterDefinition = krakenconfigs.find(({ name }) => name === value);
    form.setFieldValue('type', parameterDefinition?.type);
    // reset value
    form.setFieldValue('value', undefined);
    setSelectedParameter(parameterDefinition);
  };

  const onChangeProfile = (newProfile) => {
    setSelectedProfile(newProfile);
    // reset value
    form.setFieldValue('name', undefined);
    form.setFieldValue('type', undefined);
    form.setFieldValue('value', undefined);
    form.setFieldValue('label', undefined);
    setSelectedLabel(undefined);
    setParametersWithOtherProfile([]);
  };

  const onChangeLabel = (newLabel) => {
    // check if label is assigned to other profile
    const paramsWithOtherProfile = allParameters.filter(
      ({ label, profile, service }) =>
        label === newLabel && profile !== selectedProfile && service === 'kraken'
    );
    const profileJoined = [...new Set(paramsWithOtherProfile.map(({ profile }) => profile))];
    setParametersWithOtherProfile(profileJoined);
    if (profileJoined.length === 0) {
      setSelectedLabel(newLabel);
    } else {
      setSelectedLabel(undefined);
    }
    // reset value
    form.setFieldValue('name', undefined);
    form.setFieldValue('type', undefined);
    form.setFieldValue('value', undefined);
    setSelectedParameter(undefined);
  };

  let initialValues = {
    type: parameter?.type,
    service: 'kraken',
  };

  const convertType = (type, value) => {
    let converted;
    switch (type) {
      case 'bool':
        converted = value === 'true';
        break;
      case 'int':
        converted = parseInt(value, 10);
        break;
      case 'float':
        converted = parseFloat(value);
        break;
      default:
        converted = value;
        break;
    }
    return converted;
  };

  if (isEdit) {
    const { name, value, type, profile, label } = parameter;
    // convert value to type
    const valueConverted = convertType(type, value);
    initialValues = {
      name,
      value: valueConverted,
      type,
      profile,
      label,
    };
  }

  // reset form only if drawer is displayed
  useEffect(() => {
    if (drawerVisible) {
      form.resetFields();
    }
  }, [form, drawerVisible]);

  const onFinish = async (values) => {
    setButtonLoading(true);
    const formValues = { ...values, ...(organizationId && { organizationId }) };
    try {
      setTriggerClean(true);
      if (isEdit) {
        await updateParameter({ id: parameter.id, body: formValues }).unwrap();
      } else {
        await createParameter(formValues).unwrap();
      }
      message.success('Formulario enviado correctamente');
      onCloseDrawer();
    } catch (error) {
      message.error('Formulario con errores, revisar');
      onCloseDrawer();
    }
    setTriggerClean(false);
    setButtonLoading(false);
  };
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  const getItemByType = (parameterToDraw) => {
    const { type, bounds, values } = parameterToDraw;
    let element;
    let min;
    let max;
    if (bounds) {
      [min] = bounds;
      max = bounds[1] === 'Infinity' ? 99999999 : bounds[1];
    }
    const boolOptions = [
      { key: 'true-key', value: true, label: 'Verdadero' },
      { key: 'false-key', value: false, label: 'Falso' },
    ];
    switch (type) {
      case 'bool':
        // use select because switch trigger error on dynamic change
        element = <Select options={boolOptions} placeholder="Seleccione" />;
        break;
      case 'int':
        element = <InputNumber min={min} max={max} style={{ width: '100%' }} />;
        break;
      case 'float':
        element = <InputNumber min={min} max={max} style={{ width: '100%' }} />;
        break;
      case 'str':
        // can be a select
        if (values) {
          const options = values.map((val, index) => ({
            key: `${val}-${index}`,
            label: val,
            value: val,
          }));
          element = <Select options={options} placeholder="Seleccione" filterSort={sortSelect} />;
        } else {
          element = <Input />;
        }
        break;
      default:
        break;
    }
    return element;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={{ required: 'Este campo es obligatorio' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={initialValues}
    >
      <Form.Item
        label="Perfil"
        name="profile"
        required
        rules={[{ required: true }]}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        <Select
          options={profiles.map((profile, index) => ({
            key: `profile-${index}`,
            label: profile,
            value: profile,
          }))}
          allowClear
          filterSort={sortSelect}
          placeholder="Seleccione"
          disabled={isEdit}
          onChange={onChangeProfile}
        />
      </Form.Item>
      {!selectedProfile && (
        <Alert
          description="Seleccione un perfil para continuar"
          type="warning"
          showIcon
          style={{ padding: 10, marginBottom: '1rem' }}
        />
      )}
      {selectedProfile && (
        <SelectPlusForm
          spanLabel={24}
          spanWrapper={24}
          nameField="label"
          label="Etiqueta"
          baseItems={labels}
          onChange={onChangeLabel}
          disabled={isEdit}
          helpText="Esta etiqueta se reflejará en el campo Objetivo al parametrizar"
        />
      )}
      {parametersWithOtherProfile.length > 0 && (
        <Alert
          message={`No puede asociar ${
            parametersWithOtherProfile.length + 1
          } perfiles distintos de ruteo a la misma etiqueta`}
          description={
            <ul style={{ marginBottom: 0 }}>
              {parametersWithOtherProfile.map((profile, index) => {
                const keyLi = `prof-${index}`;
                return <li key={keyLi}>{profile}</li>;
              })}
            </ul>
          }
          type="error"
          showIcon
          style={{ padding: 10, marginBottom: '1rem' }}
        />
      )}
      <Skeleton loading={!selectedLabel} active>
        {selectedLabel && (
          <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <TitleHelp
                    title="Nombre de variable"
                    helpText="Parámetros disponibles del ruteador"
                  />
                }
                name="name"
                required
                rules={[{ required: true }]}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 23,
                }}
              >
                <Select
                  showSearch
                  onChange={handleChangeParameter}
                  options={parameters}
                  allowClear
                  filterSort={sortSelect}
                  disabled={isEdit}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {selectedParameter && (
                <Alert
                  description={selectedParameter?.description}
                  type="info"
                  showIcon
                  style={{ padding: 10 }}
                />
              )}
            </Col>
          </Row>
        )}
        {!selectedParameter && (
          <Alert
            description="Seleccione una variable para continuar"
            type="warning"
            showIcon
            style={{ padding: 10, marginBottom: '1rem' }}
          />
        )}
        <Skeleton loading={!selectedParameter} active>
          {selectedParameter && (
            <Row>
              <Col span={12}>
                <Form.Item name="type" hidden>
                  <Input name="type" />
                </Form.Item>
                <Form.Item name="service" hidden>
                  <Input name="service" />
                </Form.Item>
                <Form.Item
                  label={
                    <TitleHelp
                      title="Valor"
                      helpText={`Valor que se enviará al ruteador asociado al parámetro. Debe ser de tipo [${selectedParameter.type}]`}
                    />
                  }
                  name="value"
                  rules={[{ required: true }]}
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 23,
                  }}
                >
                  {getItemByType(selectedParameter)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Space style={{ paddingTop: '.5rem' }} direction="vertical">
                  Es usado en:
                  {selectedParameter?.tags?.map((tag, index) => {
                    const keyTag = `tg-${index}`;
                    return <Tag key={keyTag}>{tag}</Tag>;
                  })}
                </Space>
              </Col>
              <Col span={12} offset={12}>
                <Space direction="vertical">
                  Valor por defecto si no se configura:
                  {selectedParameter?.default_value.split(',').map((defaultValue, index) => {
                    const keyTag = `tgdv-${index}`;
                    return <Tag key={keyTag}>{defaultValue}</Tag>;
                  })}
                </Space>
              </Col>
            </Row>
          )}
        </Skeleton>
      </Skeleton>
    </Form>
  );
}

RoutingParametersForm.propTypes = {
  onCloseDrawer: PropTypes.func,
  parameter: RoutingParametersPropTypes,
  form: formType,
  setButtonLoading: PropTypes.func,
  drawerVisible: PropTypes.bool,
  profiles: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  organizationId: PropTypes.number,
  setTriggerClean: PropTypes.func,
};

RoutingParametersForm.defaultProps = {
  onCloseDrawer: () => {},
  parameter: {},
  form: {},
  setButtonLoading: () => {},
  drawerVisible: false,
  profiles: [],
  labels: [],
  organizationId: null,
  setTriggerClean: () => {},
};

export default RoutingParametersForm;
