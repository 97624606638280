import PropTypes from 'prop-types';

const LoadUnitsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.number,
    name: PropTypes.string,
    unit: PropTypes.string,
    load: PropTypes.number,
  })
);

const ObjectWithAnyValue = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    LoadUnitsPropTypes,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    // addr is an object
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
    // itemDetails is an array of objects
    PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]))
    ),
  ])
);

const SettingsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    keyValue: PropTypes.arrayOf(ObjectWithAnyValue),
  })
);

const OrganizationPropTypes = PropTypes.shape({
  id: PropTypes.number,
  defaultAction: PropTypes.string,
  loadsUnit: LoadUnitsPropTypes,
});

const ItemSelectedPropTypes = ObjectWithAnyValue;

export { ItemSelectedPropTypes, LoadUnitsPropTypes, OrganizationPropTypes, SettingsPropTypes };
