import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  App,
  Avatar,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import PhoneInput from 'components/common/Form/PhoneInput';
import usePasswordStrengthChecker from 'components/common/Password/PasswordStrengthChecker';
import StrengthMeter from 'components/common/Password/StrengthMeter';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentUser, setCurrentUser } from 'features/users/userSlice';
import { useCreateUserMutation, useUpdateUserMutation } from 'features/users/usersApiSlice';
import _, { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formType } from 'types';
import { RolesPropTypes, UserPropTypes } from '../userRoles.propTypes';

const acceptedFormats = ['png', 'jpeg', 'jpg'];

function UsersForm(props) {
  const {
    onCloseDrawer,
    user,
    roles,
    form,
    setButtonLoading,
    drawerVisible,
    setTriggerClean,
    typeDrawer,
  } = props;
  const [avatarUrl, setAvatarUrl] = useState(user?.photo);
  const [organizations, setOrganizations] = useState([]);
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'logo.png',
      status: 'done',
      url: user?.photo,
    },
  ]);
  const [newPwd, setNewPwd] = useState('');
  const [passwordErrors, checkPasswordStrength, hasPasswordErrors] = usePasswordStrengthChecker();
  const [phoneIsRequired, setPhoneIsRequired] = useState(false);
  const [emailIsRequired, setEmailIsRequired] = useState(true);
  const [showPasswordFields, setShowPasswordFields] = useState(true);
  const [orgRoles, setOrgRoles] = useState([]);
  const handlePwdInput = (e) => setNewPwd(e.target.value);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.users' };
  const isCreation = typeDrawer === 'create';

  // custom hooks
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const {
    subject: subjectCurrentUser,
    organizations: currentUserOrganizations,
    organization: currentOrganization,
  } = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const labelPassword = i18n.t(user.id ? 'newPassword' : 'password', scopeI18n);

  const filterRoles = (filteredRoles) => {
    return _(filteredRoles)
      .groupBy('organization.name')
      .map((roleData, orgName) => {
        return {
          label: orgName,
          title: orgName,
          options: roleData.map((rData) => ({
            label: `${orgName} / ${rData.name}`,
            value: rData.id,
          })),
        };
      })
      .value();
  };

  useEffect(() => {
    if (currentUserOrganizations) {
      setOrganizations(currentUserOrganizations);
    }
  }, [currentUserOrganizations]);

  useEffect(() => {
    if (organizations.length > 0) {
      // set organizations
      const orgIdsFromCurrent = organizations.map(({ id }) => id);
      const onlyOrgsValid =
        user?.organizations?.filter(({ id }) => orgIdsFromCurrent.includes(id)) || [];
      const organizationInitialValues =
        organizations.length === 1
          ? [organizations[0].id]
          : onlyOrgsValid.map((organization) => organization.id);
      form.setFieldsValue({ organizationIds: organizationInitialValues });

      // set roles filtered by organization
      const orgRolesFiltered = roles?.filter((roleObj) =>
        organizationInitialValues.includes(roleObj?.organizationId)
      );
      const optionRoles = filterRoles(orgRolesFiltered);
      setOrgRoles(optionRoles);
    }
  }, [organizations, form, user?.organizations, roles]);

  const setCurrentOrganizationId = (formOrgsIds) => {
    const currentOrgId = user?.organization?.id || formOrgsIds[0];
    const currentOrgsIds = user?.organizations?.map((cO) => cO.id) || [organizations[0].id];
    if (!isEqual(currentOrgsIds, formOrgsIds)) {
      if (!formOrgsIds?.includes(currentOrgId)) {
        return formOrgsIds[0];
      }
    }
    return currentOrgId;
  };

  const onChangeRequiredFields = (fieldName) => {
    if (fieldName === 'email') {
      setPhoneIsRequired(false);
      setEmailIsRequired(true);
    } else {
      setEmailIsRequired(false);
      setPhoneIsRequired(true);
    }
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const {
        nationalNidForm: nationalNid,
        nameForm: name,
        phoneNumber: phone,
        phonePrefix: prefix,
        organizationIds,
        avatar,
        roleIds,
      } = values;
      // estos roles debo mantenerlos siempre porque son de otra organización
      const anotherRoles =
        user?.roles?.filter((mRole) => mRole.organizationId !== currentOrganization?.id) || [];
      const anotherRolesIds = anotherRoles.map((anotherRole) => anotherRole?.id);
      // agrego los roles seleccionados en la vista
      const finalRoles = [...anotherRolesIds, ...roleIds];
      const body = {
        ...values,
        nationalNid,
        username: nationalNid,
        name,
        phone: phone ? `${prefix}${phone}` : undefined,
        currentOrganizationId: setCurrentOrganizationId(organizationIds),
        roleIds: finalRoles,
        ...(!values.email && { email: 'development@theoptimalpartner.com' }),
      };
      setTriggerClean(true);
      if (user.id) {
        const result = await updateUser({ userId: user.id, avatar, body }).unwrap();
        // ToDo: check if is the best way to handle this
        if (result.subject === subjectCurrentUser) {
          const userProfile = {
            id: result.id,
            nationalNid: result.nationalNid,
            givenName: result.name,
            lastName: result.lastName,
            fullName: `${result.name} ${result.lastName}`,
            photo: result.photo,
            phone: result.phone,
            email: result.email,
            subject: result.subject,
            organization: result.organization,
            organizations: result.organizations,
            roles: result.roles,
            actions: result.actions,
            enableTour: result.enableTour,
          };
          dispatch(setCurrentUser({ userProfile }));
        }
      } else {
        await createUser({ avatar, body }).unwrap();
      }
      message.success(i18n.t('form.success'));
      onCloseDrawer();
    } catch (error) {
      if (error?.data?.message === 'Usuario ya existe') {
        form.setFields([
          {
            name: 'nationalNidForm',
            errors: [i18n.t('errors.nationalNid', scopeI18n)],
          },
        ]);
      }
      message.error(i18n.t('form.error'));
      console.error(error);
    }
    setTriggerClean(false);
    setButtonLoading(false);
  };
  const onFinishFailed = () => {
    message.error(i18n.t('form.checkForm'));
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onRemove = () => {
    setAvatarUrl();
  };
  const handleChange = (info) => {
    setFileList(info.fileList);
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (url) => {
      setAvatarUrl(url);
    });
  };

  const dummyRequest = async (options) => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const beforeUpload = (file) => {
    const isAccepted = acceptedFormats.includes(file.name.split('.').pop());
    if (!isAccepted) {
      message.error(
        i18n.t('fileFormat', {
          ...scopeI18n,
          fileName: file.name,
          formats: acceptedFormats.join(', '),
        })
      );
      onRemove();
    }
    return isAccepted;
  };

  useEffect(() => {
    if (organizations.length > 0) {
      // only organizations that i can see
      const userOrgsIds = organizations.map((uOrg) => uOrg.id);
      const initialValuesForm = {
        ...user,
        prefix: '+56',
        nationalNidForm: user.nationalNid,
        nameForm: user.name,
        phoneNumber: user.phone?.substring(3),
        phonePrefix: user?.phone?.substring(0, 3) || '+56',
        roleIds: user.roles
          ?.filter((mRole) => userOrgsIds.includes(mRole.organizationId))
          ?.map((role) => role.id),
        generatePassword: false,
      };
      form.setFieldsValue(initialValuesForm);
    }
  }, [form, organizations, user]);

  // reset form only if drawer is displayed
  useEffect(() => {
    if (drawerVisible) {
      form.resetFields();
    }
  }, [form, drawerVisible]);

  const handleChangeOrg = (orgIds) => {
    const orgRolesFiltered = roles?.filter((roleObj) => orgIds.includes(roleObj?.organizationId));
    const optionRoles = filterRoles(orgRolesFiltered);
    setOrgRoles(optionRoles);
    // set roles form values
    const rolesFormFiltered = user.roles?.filter((roleObj) =>
      orgIds.includes(roleObj?.organizationId)
    );
    form.setFieldsValue({ roleIds: rolesFormFiltered?.map((roleFormObj) => roleFormObj.id) });
  };

  const onChangeGeneratePassword = (event) => {
    setShowPasswordFields(!event.target.checked);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={{ required: i18n.t('fieldRequired', scopeI18n) }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      scrollToFirstError
    >
      <Row justify="center">
        <Col>
          <Space direction="vertical" size="middle">
            <Avatar
              size={84}
              style={{ marginLeft: '1rem' }}
              icon={<FontAwesomeIcon icon={['fa', 'user']} />}
              src={avatarUrl}
            />
            <Form.Item name="avatar" getValueFromEvent={({ file }) => file.originFileObj}>
              <Upload
                fileList={fileList}
                beforeUpload={beforeUpload}
                name="avatar"
                onChange={handleChange}
                onRemove={onRemove}
                accept=".png,.jpeg,.jpg"
                maxCount={1}
                showUploadList={false}
                customRequest={dummyRequest}
              >
                <Button>{i18n.t('uploadPicture', scopeI18n)}</Button>
              </Upload>
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={
              <TitleHelp
                title={i18n.t('nationalNid', scopeI18n)}
                helpText={i18n.t('helps.nationalNid', scopeI18n)}
              />
            }
            name="nationalNidForm"
            maxLength={10}
            required
            rules={[{ required: true, max: 10 }]}
          >
            <Input placeholder={i18n.t('nationalNid', scopeI18n)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={i18n.t('name', scopeI18n)}
            name="nameForm"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.t('name', scopeI18n)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="lastName"
            label={i18n.t('lastName', scopeI18n)}
            required
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.t('lastName', scopeI18n)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <PhoneInput
            phoneIsRequired={phoneIsRequired}
            onChangeRequiredFields={onChangeRequiredFields}
          />
        </Col>
        <Col span={12}>
          <Form.Item
            label={i18n.t('email', scopeI18n)}
            name="email"
            rules={[
              {
                type: 'email',
                message: i18n.t('rules.email', scopeI18n),
              },
              { required: emailIsRequired },
            ]}
            onChange={() => onChangeRequiredFields('email')}
          >
            <Input name="email" placeholder={i18n.t('email', scopeI18n)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          {organizations.length > 0 && (
            <>
              <Form.Item
                name="organizationIds"
                label={
                  <TitleHelp
                    title={i18n.t('organization', scopeI18n)}
                    helpText={i18n.t('helps.organization', scopeI18n)}
                  />
                }
                hidden={organizations.length === 1}
              >
                {organizations.length > 1 ? (
                  <Select
                    mode="multiple"
                    placeholder={i18n.t('commons.select')}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={organizations.map((org) => {
                      return {
                        label: org.name,
                        value: org.id,
                        key: org.id,
                      };
                    })}
                    onChange={handleChangeOrg}
                  />
                ) : (
                  <Input name="organizationIds" />
                )}
              </Form.Item>
              <Form.Item name="currentOrganizationId" hidden>
                <Input name="currentOrganizationId" />
              </Form.Item>
            </>
          )}
        </Col>
        <Col span={12}>
          <Form.Item
            name="roleIds"
            label={
              <TitleHelp
                title={i18n.t('role', scopeI18n)}
                helpText={i18n.t('helps.role', scopeI18n)}
              />
            }
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              placeholder={i18n.t('commons.select')}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              options={orgRoles}
              disabled={orgRoles.length === 0}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={24}>
        {isCreation && (
          <Col span={24}>
            <Form.Item name="generatePassword" valuePropName="checked">
              <Checkbox onChange={onChangeGeneratePassword}>
                <TitleHelp
                  title={i18n.t('generatePassword', scopeI18n)}
                  helpText={i18n.t('helps.generatePassword', scopeI18n)}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        {showPasswordFields && (
          <>
            <Col span={12}>
              <Form.Item
                label={labelPassword}
                name="password"
                required={!user.id}
                validateTrigger="onBlur"
                rules={[
                  { required: !user.id },
                  () => ({
                    validator() {
                      if (!hasPasswordErrors) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(i18n.t('errors.minStrength', scopeI18n)));
                    },
                  }),
                ]}
              >
                <Input.Password
                  onInput={checkPasswordStrength}
                  onChange={handlePwdInput}
                  placeholder={i18n.t('password', scopeI18n)}
                  autoComplete="new-password"
                />
              </Form.Item>
              <Form.Item
                name="passwordConfirm"
                label={i18n.t('repeatPassword', scopeI18n)}
                dependencies={['password']}
                required={!user.id}
                rules={[
                  { required: !user.id },
                  ({ getFieldValue }) => ({
                    validator(__, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(i18n.t('errors.notEquals', scopeI18n)));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={i18n.t('repeatPassword', scopeI18n)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              {newPwd && (
                <Row gutter={24}>
                  <Col>
                    <StrengthMeter passwordErrors={passwordErrors} showDivider={false} />
                  </Col>
                </Row>
              )}
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
}

UsersForm.propTypes = {
  onCloseDrawer: PropTypes.func,
  user: UserPropTypes,
  roles: RolesPropTypes,
  form: formType,
  setButtonLoading: PropTypes.func,
  drawerVisible: PropTypes.bool,
  setTriggerClean: PropTypes.func,
  typeDrawer: PropTypes.string,
};

UsersForm.defaultProps = {
  onCloseDrawer: () => {},
  user: {},
  roles: [],
  form: {},
  setButtonLoading: () => {},
  drawerVisible: false,
  setTriggerClean: () => {},
  typeDrawer: 'create',
};

export default UsersForm;
