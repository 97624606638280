import { Button, Col, Result, Row, Skeleton } from 'antd';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import PageHeader from 'components/common/PageHeader';
import NoResponsiveComponent from 'components/layout/NoResponsiveContent';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Can from 'utils/Can';
import PanelDeleteComponent from '../../DeleteComponent';
import { PanelPropTypes } from '../../panels.propTypes';

function PanelDetailContent(props) {
  const { panel } = props;
  const { id, title, category, description, widgetCode } = panel;

  const sufix = '?:showVizHome=no&:embed=true';
  const src = `${widgetCode}${sufix}`;
  const httpRegex =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
  // Validate URL
  const isValidUrl = httpRegex.test(src);

  const [isLoadingIframe, setIsloadingIframe] = useState(!!isValidUrl);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [baseSize, setBaseSize] = useState({
    width: 0,
    height: 0,
  });

  const navigate = useNavigate();

  const loadComplete = () => {
    setIsloadingIframe(false);
    setBaseSize({
      width: '100%',
      height: '100%',
    });
  };
  const editClick = () => {
    navigate(`/reports/panels/${id}/edit`);
  };

  const showArchiveModal = (event) => {
    event.stopPropagation();
    setIsModalVisible(true);
  };

  const onBack = () => {
    navigate('/reports');
  };

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/reports',
      label: 'Reportes',
    },
    {
      key: 'panels',
      url: `/reports`,
      label: 'Paneles',
    },
    {
      key: 'panel-detail',
      url: `/reports/panels/${id}`,
      label: 'Detalle',
    },
  ];

  return (
    <Row>
      <Col md={20} xs={24}>
        <BreadcrumbComponent breadcrumbItems={breadcrumbItems} marginBottom="0px" />
        <PageHeader
          className="site-page-header"
          onBack={onBack}
          title={title}
          subtitle={`(${category})`}
        />
      </Col>
      <Col md={4} xs={0}>
        <Can key="update-panel" I="update" a="panels">
          <Button style={{ marginLeft: '1rem', float: 'right' }} onClick={editClick}>
            Editar
          </Button>
        </Can>
        <Can key="delete-panel" I="delete" a="panels">
          <Button type="primary" danger style={{ float: 'right' }} onClick={showArchiveModal}>
            Archivar
          </Button>
        </Can>
      </Col>
      <Col md={2} xs={24}>
        Descripción:
      </Col>
      <Col md={22} xs={24}>
        {description}
      </Col>
      <Col
        span={24}
        style={{
          marginTop: '2rem',
          width: '100%',
          height: '75vh',
        }}
      >
        {isLoadingIframe ? <Skeleton loading active paragraph={{ rows: 10 }} /> : null}
        {isValidUrl ? (
          <Row style={{ height: '100%' }}>
            <Col md={24} xs={0}>
              <iframe
                width={baseSize.width}
                height={baseSize.height}
                title="panel-1"
                src={src}
                onLoad={loadComplete}
              />
            </Col>
            <Col md={0} xs={24} style={{ border: '1px solid #d9d9d9', padding: '.4rem' }}>
              <NoResponsiveComponent />
            </Col>
          </Row>
        ) : (
          <Result
            status="404"
            title="404"
            subTitle="Lo sentimos. La url no tiene un formato valido, por lo que no se puede cargar el panel. Favor editela y vuelva a cargar."
            extra={
              <Can key="update-panel" I="update" a="panels">
                <Button onClick={editClick}>Editar</Button>
              </Can>
            }
          />
        )}
      </Col>
      <Can key="delete-panel" I="delete" a="panels">
        <PanelDeleteComponent
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          id={id}
        />
      </Can>
    </Row>
  );
}

PanelDetailContent.defaultProps = {
  panel: {},
};

PanelDetailContent.propTypes = {
  panel: PanelPropTypes,
};

export default PanelDetailContent;
