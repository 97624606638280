import { Alert, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

function IntroComponent(props) {
  const { type, title, mainText, downloadExampleFile } = props;

  const AlertTitleText = () => {
    return (
      <>
        <Text strong>Recuerda</Text>
        <Text> antes de llenar la plantilla considera:</Text>
      </>
    );
  };

  const AlertBodyText = () => {
    return (
      <ul style={{ marginLeft: '-1rem' }}>
        <li>
          <Text>La extención del archivo de la demanda debe de ser .csv.</Text>
        </li>
        <li>
          <Text>
            No se debe superar la cantidad de registros (filas) en el archivo según como se tiene
            configurado en la plataforma.
          </Text>
        </li>
        <li>
          <Text>
            Es importante conservar y no modificar la estructura y orden de las cabeceras del
            archivo cargado, estas deben de ser el mismo archivo de ejemplo (Windows u otro), sino
            cumple con la estructura base, arrojará error.
          </Text>
        </li>
        <li>
          <Text>Es necesario que las demandas estén creadas como visitas.</Text>
        </li>
      </ul>
    );
  };

  return (
    <Row gutter={[12, 12]} justify={'center'}>
      <Col md={{ span: 16 }} xs={{ span: 24 }}>
        <Title className="help-title">{title}</Title>
        <Text>
          {mainText} tienes que usar nuestra plantilla usando nuestro formato de CSV, debes llenar
          correctamente la planilla con los formatos correspondientes de cada columna para evitar
          errores. Debes seguir el orden de columnas del archivo ejemplo que puedes descargar
          haciendo click{' '}
        </Text>
        <Link onClick={() => downloadExampleFile(type)} component={Typography.Link}>
          <u>aquí</u>
        </Link>
        <Text>
          .<br /> Aquí tienes algunas recomendaciones para llenar cada columna:
        </Text>
        <Alert
          message={<AlertTitleText />}
          description={<AlertBodyText />}
          type="warning"
          showIcon
          style={{ marginTop: 20, marginBottom: 20 }}
        />
      </Col>
    </Row>
  );
}

IntroComponent.defaultProps = {
  type: '',
  title: '',
  mainText: '',
  downloadExampleFile: () => {},
};

IntroComponent.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  mainText: PropTypes.string,
  downloadExampleFile: PropTypes.func,
};

export default IntroComponent;
