import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import ButtonDownload from '../ButtonDownload';

function DownloadList(props) {
  const { downloadQuery, reduxFun, extraParams } = props;
  const { notification } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'download' };

  const splitQuery = () => {
    let queryObject = {};
    if (downloadQuery !== '') {
      const stringQuery = downloadQuery.slice(1);
      const splittedParams = stringQuery.split('&');
      const newObject = splittedParams.reduce((accumulator, paramValue) => {
        const [key, value] = paramValue.split('=');
        accumulator[key] = value;
        return accumulator;
      }, {});
      queryObject = newObject;
    }
    return queryObject;
  };

  const doCallback = async (e) => {
    const { key: typeFile } = e;
    const params = splitQuery();
    try {
      const payload = {
        params,
        format: typeFile,
        ...extraParams,
      };
      await reduxFun(payload).unwrap();
      notification.info({
        message: i18n.t('generatingDocument', scopeI18n),
        description: i18n.t('youWillBeNoticed', scopeI18n),
        placement: 'topRight',
        icon: <FontAwesomeIcon icon="cloud-arrow-down" bounce color="#0d63cf" />,
      });
    } catch (error) {
      notification.error({ message: 'Some error happened', description: error.message });
    }
  };

  return <ButtonDownload size="normal" onClickEvent={doCallback} buttonName={null} showTooltip />;
}

DownloadList.defaultProps = {
  reduxFun: async () => {},
  downloadQuery: '',
  extraParams: {},
};

DownloadList.propTypes = {
  reduxFun: PropTypes.func,
  downloadQuery: PropTypes.string,
  extraParams: PropTypes.shape({
    type: PropTypes.string,
  }),
};

export default DownloadList;
