import apiSlice from 'app/api/apiSlice';

/**
 * organizationsApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const organizationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: () => '/organizations',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Organizations', id })),
              { type: 'Organizations', id: 'LIST' },
            ]
          : [{ type: 'Organizations', id: 'LIST' }],
    }),
    getOrganization: builder.query({
      query: (id) => `organizations/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Organizations', id }],
    }),
    getOrganizationsByFilters: builder.mutation({
      query: (query) => `/organizations${query}`,
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Organizations', id })) : ['Organizations'],
    }),
    createOrganization: builder.mutation({
      query: ({ logo, body }) => {
        const fmData = new FormData();
        fmData.append('logo', logo);
        fmData.append('body', JSON.stringify(body));
        return {
          url: '/organizations',
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: ['Organizations'],
    }),
    updateOrganization: builder.mutation({
      query: (params) => {
        const fmData = new FormData();
        fmData.append('logo', params.logo);
        fmData.append('body', JSON.stringify(params.body));
        return {
          url: `/organizations/${params.organizationId}`,
          method: 'PATCH',
          body: fmData,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Organizations', id }],
    }),
    deleteOrRestoreOrganization: builder.mutation({
      query: (organizationId) => ({
        url: `organizations/change-status/${organizationId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Organizations'],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useGetOrganizationsByFiltersMutation,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrRestoreOrganizationMutation,
} = organizationsApiSlice;
