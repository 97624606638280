import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Tooltip } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useGeocodeMutation } from 'features/georreference/georreferenceApiSlice';
import PropTypes, { arrayOf, string } from 'prop-types';
import { useContext, useState } from 'react';
import './style.css';

function Georreference(props) {
  const { locationCascade, customOnChange } = props;
  const form = Form.useFormInstance();
  const [loading, setLoading] = useState(false);
  const [geoSuccessfull, setGeoSuccessful] = useState(true);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.location' };
  // custom hook
  const [geocode] = useGeocodeMutation();

  const fetchData = async (params) => {
    setLoading(true);
    if (!params.addressStreetForm || !params.addressNumberForm || !locationCascade) {
      setLoading(false);
      setGeoSuccessful(false);
      return;
    }

    const address = `${params.addressStreetForm} ${params.addressNumberForm},${locationCascade[3]},${locationCascade[2]},${locationCascade[1]},${locationCascade[0]}`;
    const client = 'locations-editor';

    try {
      const result = await geocode({ address, client }).unwrap();
      if (result) {
        const latLng = {
          latForm: result.latitude,
          lngForm: result.longitude,
        };
        form.setFieldsValue(latLng);
        setLoading(false);
        customOnChange(latLng);
        setGeoSuccessful(true);
      } else {
        setLoading(false);
        setGeoSuccessful(false);
        console.error('Cannot get data: ', result);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Tooltip
      title={
        geoSuccessfull ? i18n.t('geocodeAddress', scopeI18n) : i18n.t('geocodeError', scopeI18n)
      }
    >
      <Button
        type="default"
        className="search-button"
        size="middle"
        onClick={() => fetchData(form.getFieldsValue())}
        loading={loading}
        danger={!geoSuccessfull}
      >
        <FontAwesomeIcon icon={['fas', 'magnifying-glass-location']} />
      </Button>
    </Tooltip>
  );
}

Georreference.defaultProps = {
  locationCascade: [],
  customOnChange: () => {},
};

Georreference.propTypes = {
  locationCascade: arrayOf(string),
  customOnChange: PropTypes.func,
};

export default Georreference;
