import { Alert, Button, Layout, Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Content } from 'antd/es/layout/layout';
import moment from 'moment';
import { selectCurrentOrganization } from '../features/users/userSlice';
import { convertDateToDayjs } from '../services/repeated-functions';
import LocaleContext from './locale/LocaleContext';

const { Text } = Typography;

function NotificationArea(props) {
  const { isOffline } = props;
  const { demoFinishDate, timezone, purchasedPlan, demoIsExpired } =
    useSelector(selectCurrentOrganization);
  const [showMessages, setShowMessages] = useState(false);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'notifications.alerts' };

  const checkNotificationGranted = () => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        return true;
      }
      Notification.requestPermission();
    }
    return false;
  };

  const notificationDisabled = !checkNotificationGranted();
  const lostConection = isOffline;
  const scheduledMaintenance = false;
  const isDemo = purchasedPlan === 'demo';

  const alertsDefinitions = [];
  const whiteHex = '#FFFFFF';
  const blackHex = '#000000';
  const yellowHex = '#db9a1b';
  const greyHex = '#404040';
  const silverHex = '#838A9F';
  const iconWhite = (
    <FontAwesomeIcon
      icon={['fas', 'circle-exclamation']}
      fontSize={14}
      style={{ color: whiteHex }}
    />
  );

  if (isDemo) {
    if (!demoIsExpired) {
      const demoFinishDateFormatted = convertDateToDayjs(demoFinishDate, timezone, 'HH:mm:ss');
      const demoDaysLeft = Math.ceil(dayjs(demoFinishDate).diff(dayjs(), 'day', true));
      alertsDefinitions.push({
        key: 'in-demo-alert',
        style: {
          background: greyHex,
        },
        icon: iconWhite,
        message: (
          <>
            <Text style={{ color: whiteHex }}>{i18n.t('demoWillBeExpired', scopeI18n)}</Text>
            <Text style={{ color: yellowHex }}>
              {i18n.t('demoWillBeExpiredDays', { ...scopeI18n, count: demoDaysLeft })}
            </Text>
            <Text style={{ color: whiteHex }}>{i18n.t('helps.demoWillBeExpired', scopeI18n)}</Text>
            <Text style={{ color: yellowHex }}>{demoFinishDateFormatted}</Text>
          </>
        ),
      });
    } else {
      alertsDefinitions.push({
        key: 'demo-expired-alert',
        message: <b>{i18n.t('demoExpired', scopeI18n)}</b>,
      });
    }
  }

  if (lostConection) {
    alertsDefinitions.push({
      key: 'lost-conection-alert',
      style: {
        background: silverHex,
        color: whiteHex,
      },
      icon: iconWhite,
      message: (
        <span>
          <b>{i18n.t('lostConnection', scopeI18n)}</b> {i18n.t('helps.lostConnection', scopeI18n)}
        </span>
      ),
      type: 'error',
    });
  }

  if (notificationDisabled) {
    alertsDefinitions.push({
      key: 'enable-notification-alert',
      message: (
        <span>
          <b>{i18n.t('notificationDisabled', scopeI18n)}</b>
          {i18n.t('helps.notificationDisabled', scopeI18n)}
        </span>
      ),
    });
  }

  // ToDo: check how handle this notification
  if (scheduledMaintenance) {
    const startDate = moment(); // date with start time
    const duration = 180; // minutes
    const endDate = startDate.clone();
    endDate.add(duration, 'minutes');
    const dayMonth = startDate.format('DD [de] MMMM');
    const startFormated = startDate.format('HH:mm a');
    const endFormated = endDate.format('HH:mm a');
    alertsDefinitions.push({
      key: 'scheduled-maintenance-alert',
      message: (
        <span>
          <b>{i18n.t('scheduledMaintenance', scopeI18n)}</b>
          {i18n.t('scheduledMaintenanceFromTo', {
            ...scopeI18n,
            date: dayMonth,
            start: startFormated,
            end: endFormated,
          })}
        </span>
      ),
    });
  }

  const onClickButton = async () => {
    setShowMessages(!showMessages);
    if ('Notification' in window) {
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission();
      }
    }
  };
  const buttonShowOthers = (key) => {
    const colorButton =
      key.includes('lost-conection') || key.includes('in-demo') ? whiteHex : blackHex;
    return (
      <Button
        onMouseEnter={onClickButton}
        onMouseLeave={onClickButton}
        size="small"
        type="link"
        title={i18n.t('showAll', scopeI18n)}
      >
        <FontAwesomeIcon
          icon={['fas', `chevron-${showMessages ? 'up' : 'down'}`]}
          fontSize={14}
          color={colorButton}
        />
      </Button>
    );
  };

  // first alert wil be fixed
  const firstAlert = alertsDefinitions.length > 0 ? alertsDefinitions.shift() : undefined;

  const drawAlert = (definition, showButton = false) => {
    const { key, message, style, icon, type = 'warning' } = definition;
    const displayButton = alertsDefinitions.length > 0 ? showButton : false;
    return (
      <Alert
        key={key}
        message={message}
        type={type}
        banner
        icon={icon}
        action={displayButton ? buttonShowOthers(key) : undefined}
        style={{
          ...style,
          boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25)',
          paddingLeft: '1.5rem',
        }}
      />
    );
  };
  const contentStyle = {
    minHeight: firstAlert ? 38 : 0,
    lineHeight: '38px', // ToDo: para versiones mobile un 50 se ve bien (.navbar {height: 90px})
    oveflow: 'hidden',
  };
  const layoutStyle = {
    overflow: 'hidden',
    width: '100vw',
  };

  return (
    <Layout style={layoutStyle}>
      <Content style={contentStyle}>
        <Space
          direction="vertical"
          style={{
            width: '100%',
            zIndex: 1,
            position: 'absolute',
          }}
        >
          {firstAlert && drawAlert(firstAlert, true)}
          {showMessages && alertsDefinitions.map((definition) => drawAlert(definition))}
        </Space>
      </Content>
    </Layout>
  );
}

NotificationArea.propTypes = {
  isOffline: PropTypes.bool,
};

NotificationArea.defaultProps = {
  isOffline: true,
};

export default NotificationArea;
