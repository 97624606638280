import apiSlice from 'app/api/apiSlice';

/**
 * rolesApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const rolesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: () => '/permissions',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Permissions', id })) : ['Permissions'],
    }),
    getRoles: builder.query({
      query: () => '/roles',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Roles', id })) : ['Roles'],
    }),
    getBaseRoles: builder.query({
      query: () => '/roles/base-roles',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Roles', id })) : ['Roles'],
    }),
    createRole: builder.mutation({
      query: (params) => ({
        url: '/roles',
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['Roles'],
    }),
    massiveAssignRole: builder.mutation({
      query: (params) => ({
        url: '/roles/massive-assign',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Roles'],
    }),
  }),
});

export const {
  useGetPermissionsQuery,
  useGetRolesQuery,
  useGetBaseRolesQuery,
  useCreateRoleMutation,
  useMassiveAssignRoleMutation,
} = rolesApiSlice;
