import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space, Table, Tag, Typography } from 'antd';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import {
  CalculateDiffTimes,
  CalculateEta,
  FormatToTime,
  IsDepotOrRtd,
  transformValueToNumberWithSeparator,
} from 'services/repeated-functions';
import { FollowingItemProp } from '../../following.propTypes';

const { Text } = Typography;

function TableDetailRoute(props) {
  const {
    items,
    allItems,
    isLoading,
    routeStartedAt: startedAt,
    routeFinishedAt: finishedAt,
    timezone,
    showDrawerEvents,
  } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const getStatus = (record) => {
    const { type } = record;
    let fakeEvents = [];
    if (IsDepotOrRtd(type)) {
      if (type === 'DEPOT' && startedAt) {
        fakeEvents.push({ status: 'STARTED' });
      } else if (type === 'RETURN_TO_DEPOT' && finishedAt) {
        fakeEvents.push({ status: 'FINISHED' });
      }
    } else {
      fakeEvents = record.events;
    }

    let label;
    let color;
    let border;
    const lastEvent = fakeEvents.length > 0 ? fakeEvents[fakeEvents.length - 1] : undefined;

    switch (lastEvent?.status) {
      case 'SUCCESS':
        label = i18n.t('states.success', scopeI18n);
        color = 'success';
        break;
      case 'PARTIAL':
        label = i18n.t('states.partial', scopeI18n);
        color = 'warning';
        break;
      case 'FAILURE':
        label = i18n.t('states.failure', scopeI18n);
        color = 'error';
        break;
      case 'POSTPONED':
        label = i18n.t('states.postponed', scopeI18n);
        color = 'cyan';
        break;
      case 'STARTED':
        label = i18n.t('states.started', scopeI18n);
        color = 'processing';
        border = '#0D63CF';
        break;
      case 'FINISHED':
        label = i18n.t('states.finished', scopeI18n);
        color = 'processing';
        border = '#0D63CF';
        break;
      default:
        label = i18n.t('states.default', scopeI18n);
        color = 'default';
        break;
    }
    return {
      label,
      color,
      border,
    };
  };

  const renderDifference = (real, planned) => {
    const { newTime, seconds } = CalculateDiffTimes(real, planned);
    const isPositive = seconds < 0;
    const classColor = isPositive ? 'positive' : 'negative';
    return (
      <Space style={{ width: '120px' }} direction="vertical">
        {real && (
          <Text className={`color-${classColor}-text`}>
            <FontAwesomeIcon
              icon={`caret-${isPositive ? 'down' : 'up'}`}
              style={{ marginRight: '0.3rem' }}
            />
            {FormatToTime(newTime)}
          </Text>
        )}
        {!real && '-'}
      </Space>
    );
  };

  const renderEta = (item) => {
    // ToDo: pass other times ass array of seconds to be added to base eta
    const otherTimes = [];
    const eta = CalculateEta(item, allItems, startedAt, timezone, otherTimes);
    return <Space style={{ width: '80px' }}>{eta}</Space>;
  };

  const columns = [
    {
      key: 'itemOrder',
      title: i18n.t('order', scopeI18n),
      dataIndex: 'itemOrder',
      fixed: 'left',
      render: (value, record) => {
        const { type } = record;
        if (IsDepotOrRtd(type)) {
          return '-';
        }
        return <Space style={{ width: '40px' }}>{value}</Space>;
      },
    },
    {
      key: 'nid',
      title: i18n.t('clientId', scopeI18n),
      dataIndex: 'nid',
      fixed: 'left',
      render: (value, record) => {
        const { type } = record;
        if (IsDepotOrRtd(type)) {
          return '-';
        }
        return <Space style={{ width: '100px' }}>{value}</Space>;
      },
    },
    {
      key: 'name',
      title: i18n.t('clientName', scopeI18n),
      dataIndex: 'name',
      render: (value) => {
        return (
          <Space style={{ width: '120px' }}>
            <Text ellipsis className="client-text-table" title={value}>
              {value || '-'}
            </Text>
          </Space>
        );
      },
    },
    {
      key: 'document',
      title: i18n.t('document', scopeI18n),
      dataIndex: 'document',
      render: (value, record) => {
        const { type } = record;
        let returnValue = value;
        if (IsDepotOrRtd(type)) {
          returnValue = '-';
        }
        return <Space style={{ width: '100px' }}>{returnValue}</Space>;
      },
    },
    {
      key: 'arrivesAt',
      title: i18n.t('arrivesAt', scopeI18n),
      dataIndex: 'arrivesAt',
      render: (value) => {
        return <Space style={{ width: '120px' }}>{FormatToTime(value, timezone)}</Space>;
      },
    },
    {
      key: 'arrivedAt',
      title: i18n.t('arrivedAt', scopeI18n),
      dataIndex: 'arrivedAt',
      render: (value, record) => {
        const { type } = record;
        let dateToUse = value;
        if (type === 'DEPOT' && startedAt) {
          dateToUse = startedAt;
        } else if (type === 'RETURN_TO_DEPOT' && finishedAt) {
          dateToUse = finishedAt;
        }
        return (
          <Space style={{ width: '80px' }}>
            {(dateToUse && FormatToTime(dateToUse, timezone)) || '-'}
          </Space>
        );
      },
    },
    {
      key: 'diff',
      title: (
        <TitleHelp
          title={i18n.t('diffTime', scopeI18n)}
          helpText={i18n.t('helps.diffTime', scopeI18n)}
        />
      ),
      dataIndex: 'diff',
      render: (_, record) => {
        const { arrivedAt, arrivesAt } = record;
        return renderDifference(arrivedAt, arrivesAt);
      },
    },
    {
      key: 'eta',
      title: (
        <TitleHelp title={i18n.t('eta', scopeI18n)} helpText={i18n.t('helps.eta', scopeI18n)} />
      ),
      dataIndex: 'eta',
      render: (_, record) => {
        return renderEta(record);
      },
    },
    {
      key: 'stoppedTime',
      title: (
        <Space>
          <FontAwesomeIcon icon="clock" />
          <TitleHelp title={i18n.t('stop', scopeI18n)} helpText={i18n.t('helps.stop', scopeI18n)} />
        </Space>
      ),
      dataIndex: 'stoppedTime',
      render: (_, record) => {
        const { arrivedAt, departedAt } = record;
        const hasArrivedDeparted = arrivedAt && departedAt;
        const { newTime: stoppedTime } = CalculateDiffTimes(departedAt, arrivedAt);
        return (
          <Space style={{ width: '80px' }}>
            {hasArrivedDeparted ? FormatToTime(stoppedTime, timezone) : '-'}
          </Space>
        );
      },
    },
    {
      key: 'departedAt',
      title: i18n.t('departedAt', scopeI18n),
      dataIndex: 'departedAt',
      render: (value) => {
        return (
          <Space style={{ width: '80px' }}>{(value && FormatToTime(value, timezone)) || '-'}</Space>
        );
      },
    },
    {
      key: 'farawayReport',
      title: (
        <TitleHelp
          title={i18n.t('distance', scopeI18n)}
          helpText={i18n.t('helps.distance', scopeI18n)}
        />
      ),
      dataIndex: 'farawayReport',
      render: (_, record) => {
        const { farawayReport, distanceFromReport } = record;
        if (distanceFromReport !== 0) {
          const colorClass = farawayReport ? 'negative' : 'positive';
          const suffixTitle = i18n.t(farawayReport ? 'overcome' : 'within', scopeI18n);
          return (
            <Text
              title={`${suffixTitle} ${i18n.t('distanceRange', scopeI18n)}`}
              className={`color-${colorClass}-text`}
            >
              {transformValueToNumberWithSeparator(distanceFromReport)} Mts.
            </Text>
          );
        }
        return '-';
      },
    },
    {
      key: 'status',
      title: (
        <TitleHelp
          title={i18n.t('statusLabel', scopeI18n)}
          helpText={i18n.t('helps.status', scopeI18n)}
        />
      ),
      dataIndex: 'status',
      render: (_, record) => {
        const { color, label, border } = getStatus(record);
        return (
          <Tag
            color={color}
            style={{
              borderColor: border,
              color: border,
              width: '110px',
              textAlign: 'center',
            }}
          >
            {label}
          </Tag>
        );
      },
    },
    {
      key: 'button',
      dataIndex: 'button',
      fixed: 'right',
      width: 200,
      onCell: () => {
        return {
          style: {
            width: '200px',
          },
        };
      },
      render: (_, record) => {
        const { events, type, latitude, longitude, name } = record;
        const isReported = events.length > 0;
        const hasPhoto = events?.some(({ eventAttachments }) =>
          eventAttachments?.some(({ photoType }) => photoType === 'photo')
        );
        return (
          <Space>
            <Button
              key="btn-location"
              target="_blank"
              // href={`https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`}
              href={`https://wego.here.com/location?map=${latitude},${longitude},normal&msg=${
                name || ''
              }`}
              title={i18n.t('showInMap', scopeI18n)}
              onClick={(event) => event.stopPropagation()}
            >
              <FontAwesomeIcon icon="location-pin" />
            </Button>
            {!IsDepotOrRtd(type) && (
              <Button
                key="btn-show"
                type="primary"
                onClick={() => {
                  showDrawerEvents(record.id);
                }}
                disabled={!isReported}
                title={i18n.t(isReported ? 'showEvents' : 'noEvents', scopeI18n)}
                style={{ width: 120 }}
              >
                {i18n.t('comment', scopeI18n)}
                {hasPhoto && (
                  <FontAwesomeIcon
                    icon="camera"
                    style={{ marginLeft: '.5rem' }}
                    title={i18n.t('stopWithPhotos', scopeI18n)}
                  />
                )}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      rowKey="id"
      key="table-detail-route-following"
      columns={columns}
      dataSource={items}
      loading={isLoading}
      scroll={{
        x: true,
      }}
    />
  );
}

TableDetailRoute.defaultProps = {
  items: [],
  allItems: [],
  timezone: 'America/Santiago',
  isLoading: false,
  routeStartedAt: undefined,
  routeFinishedAt: undefined,
  showDrawerEvents: () => {},
};

TableDetailRoute.propTypes = {
  items: PropTypes.arrayOf(FollowingItemProp),
  allItems: PropTypes.arrayOf(FollowingItemProp), // used to get all items and compare times
  timezone: PropTypes.string,
  isLoading: PropTypes.bool,
  routeStartedAt: PropTypes.string,
  routeFinishedAt: PropTypes.string,
  showDrawerEvents: PropTypes.func,
};

export default TableDetailRoute;
