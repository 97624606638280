import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Empty, Form, Row, Skeleton } from 'antd';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import PageHeader from 'components/common/PageHeader';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { useGetRoutingSetQuery } from 'features/routingSets/routingSetsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllIndexFromArray } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import RestrictionsDrawer from '../Editor/RestrictionsDrawer';
import RoutesDemandMap from '../Map';
import NavRouteButtons from '../NavRouteButtons';
import RouteEdit from '../RouteEdit';
import RouteEditFilter from '../RouteEdit/Filter';
import RoutesDemandSynoptic from '../Synoptic';

const SELECT_ALL = -1;

function RoutesDemandShow() {
  const { id } = useParams();
  const { id: currOrgId } = useSelector(selectCurrentOrganization);
  const { data: organization, isLoading: isLoadingOrg } = useGetOrganizationQuery(currOrgId);
  const loadsFromOrg = organization?.loadsUnit || [];
  const [showMap, setShowMap] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState(SELECT_ALL);
  const [allRoutes, setAllRoutes] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [dataFormated, setDataFormated] = useState([]);
  const [routeStops, setRouteStops] = useState([]);
  const [routingSet, setRoutingSet] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [routeEditForm] = Form.useForm();
  const [currentUpperValue, setCurrentUpperValue] = useState('normal');
  const [currentLowerValue, setCurrentLowerValue] = useState('normal');
  const [restrictionsDrawerOpen, setRestrictionsDrawerOpen] = useState(false);

  // object for accepted key-value for upper & lower container
  const upperValues = {
    expand: 85,
    normal: 59,
    hide: 0,
  };

  // values for table derive from upperValues
  const tableValues = {
    expand: 76,
    normal: 66,
    hide: 0,
  };

  // custom hooks
  const { data: routingSetData, isLoading } = useGetRoutingSetQuery(id, {
    skip: id === undefined,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (routingSetData) {
      setAllRoutes(routingSetData.routes);
      setRoutes(routingSetData.routes);
      setRoutingSet(routingSetData);
    }
  }, [routingSetData, isLoading]);
  // build data for table, check if is used
  useEffect(() => {
    const buildData = (routesFetched) => {
      const dataBuilded = routesFetched.map((route, index) => {
        return {
          id: route.id,
          key: route.id,
          route: index + 1,
          action: route.action,
          routeOrder: route.routeOrder,
          driver: route.driver,
          assistant: route.assistant,
          vehicle: route.vehicle,
          items: route.items?.map((item) => {
            return {
              key: item.id,
              lat: item.latitude,
              lng: item.longitude,
              type: item.type,
              routeOrder: route.routeOrder,
            };
          }),
        };
      });
      return dataBuilded;
    };

    setDataFormated(buildData(routes));
  }, [routes]);
  useEffect(() => {
    const stopItems = dataFormated.map((route) => route.items);
    setRouteStops(stopItems);
  }, [dataFormated]);

  const ability = useContext(CaslContext);
  const allRoutesFromArray = getAllIndexFromArray(['planning', 'routes', 'jobs'], ability);
  const { routes: routeIndexPath } = allRoutesFromArray;
  const { index: routesIndex, path: routesPath } = routeIndexPath;
  const routesKey = `planning${routesIndex ? '' : `/${routesPath}`}`;

  const breadcrumbItems = [
    {
      key: 'demands',
      url: `/${routesKey}`,
      label: 'Lista de rutas',
    },
    {
      key: 'route',
      url: `/planning/routes/${id}`,
      label: 'Conjunto de rutas',
    },
    {
      key: 'routeEdit',
      url: `/planning/routes/${id}/show`,
      label: 'Detalle',
    },
  ];

  const toggleMap = () => {
    setShowMap(!showMap);
  };
  const toggleMapButton = (
    <Button
      key="btn-toggleMap"
      onClick={toggleMap}
      style={{
        width: '20px',
        height: '32px',
        padding: 0,
      }}
    >
      <FontAwesomeIcon icon={['fas', showMap ? 'angle-right' : 'angle-left']} />
    </Button>
  );
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate(`/planning/routes/${id}`);
  };

  const handleOnClickNavRoute = useCallback((selected) => {
    setSelectedRoute(selected);
  }, []);
  const canEdit = false;

  return (
    <>
      <div className="demands-container">
        <Row
          gutter={16}
          className="upper-container"
          style={{
            height: `${upperValues[currentUpperValue]}%`,
            opacity: currentUpperValue === 'hide' ? 0 : 1,
          }}
        >
          <Col
            className="upper-container table-element"
            style={{
              width: showMap ? '60%' : '100%',
            }}
          >
            <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />

            <Skeleton loading={isLoading} paragraph={{ rows: 12 }}>
              <PageHeader
                className="padding-page-header"
                onBack={onClickBack}
                title={routingSet?.name}
                extra={[toggleMapButton]}
              />
              <NavRouteButtons
                size={allRoutes.length}
                onClick={handleOnClickNavRoute}
                selectedRoute={selectedRoute}
              />
              <>
                <RouteEditFilter
                  routingSetData={routingSetData}
                  routes={routes}
                  setRoutes={setRoutes}
                />
                <div
                  style={{
                    height: `${
                      tableValues[currentUpperValue] - (currentUpperValue === 'normal' ? 10 : 7)
                    }%`,
                    overflow: 'auto',
                  }}
                >
                  <Form form={routeEditForm} name="routeEdit" autoComplete="off" disabled>
                    {routes.map((route) => (
                      <RouteEdit
                        key={route.id}
                        route={route}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                        loadsFromOrg={loadsFromOrg}
                        routes={routes}
                        setRoutes={setRoutes}
                        setAllRoutes={setAllRoutes}
                        canEdit={canEdit}
                      />
                    ))}
                    {routes.length === 0 && (
                      <Empty description="No hay rutas o paradas que cumplan con los filtros" />
                    )}
                  </Form>
                </div>
              </>
            </Skeleton>
          </Col>
          {showMap && currentLowerValue !== 'expand' && routeStops.length !== 0 && (
            <RoutesDemandMap
              routeStops={routeStops}
              allRoutes={allRoutes}
              routingSet={routingSet}
              isEditable={false}
              showMap={showMap}
              setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
            />
          )}
        </Row>
        <RoutesDemandSynoptic
          currentLowerValue={currentLowerValue}
          setCurrentLowerValue={setCurrentLowerValue}
          setCurrentUpperValue={setCurrentUpperValue}
          routes={routes}
          setRoutes={setRoutes}
          selectedRoute={selectedRoute}
          allRoutes={allRoutes}
          setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
        />
      </div>
      {!isLoading && !isLoadingOrg && (
        <RestrictionsDrawer
          routes={routes}
          restrictionsDrawerOpen={restrictionsDrawerOpen}
          setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
export default RoutesDemandShow;
