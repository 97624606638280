import { App, Input } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

const { Search } = Input;

function InputSearch(props) {
  const { dataType, clearText, setData, func, routeId } = props;
  const [text, setText] = useState('');
  const isVehicle = dataType === 'vehicle';
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'commons' };

  useEffect(() => {
    if (clearText) {
      setText('');
    }
  }, [clearText]);

  const fetchDataFiltered = async (querySearch) => {
    try {
      const newQuery = { routeId, query: querySearch };
      const result = await func(newQuery).unwrap();
      setData(result);
    } catch (error) {
      message.error(error);
    }
  };

  const changeSearch = (e) => {
    setText(e.target.value);
    fetchDataFiltered(isVehicle ? `?plate=${e.target.value}` : `?name=${e.target.value}`);
  };

  return (
    <Search
      style={{
        width: '100%',
        marginTop: 16,
      }}
      allowClear
      value={text}
      placeholder={i18n.t('search', scopeI18n)}
      onChange={changeSearch}
    />
  );
}

InputSearch.propTypes = {
  func: PropTypes.func.isRequired,
  setData: PropTypes.func,
  dataType: PropTypes.string,
  clearText: PropTypes.bool,
  routeId: PropTypes.number,
};

InputSearch.defaultProps = {
  setData: () => {},
  dataType: 'driver',
  clearText: false,
  routeId: undefined,
};

export default InputSearch;
