import { Alert, App, Skeleton, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useUpdateOrganizationMutation } from 'features/organizations/organizationsApiSlice';
import { useGetLabelsFromParametersQuery } from 'features/routingParameters/routingParametersApiSlice';
import {
  useCreateSettingMutation,
  useGetSettingsQuery,
  useUpdateSettingMutation,
} from 'features/settings/settingsApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutingGeneralForm from './Form';

const { Title } = Typography;
function RoutingGeneral(props) {
  const { organizationId, goToNextStep, submitButton, current } = props;
  const [allLoaded, setAllLoaded] = useState(false);
  const [formattedSetting, setFormattedSetting] = useState({});
  const [labels, setLabels] = useState([]);
  const navigate = useNavigate();
  const [updateSettingMobile, { isLoading: updatingData }] = useUpdateSettingMutation();
  const [createSettingMobile, { isLoading: savingData }] = useCreateSettingMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const type = 'GENERALROUTING';
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.generalRouting' };

  const getProfilesQuery = organizationId ? `?organizationId=${organizationId}` : '';
  const url = `${type}?format=false${organizationId ? `&organizationId=${organizationId}` : ''}`;
  const {
    data: settingsFields,
    isLoading,
    // isFetching,
    refetch: refetchSetting,
  } = useGetSettingsQuery(url);

  const {
    data: labelsData,
    isLoading: labelsFetching,
    refetch: refetchLabels,
  } = useGetLabelsFromParametersQuery(getProfilesQuery);

  useEffect(() => {
    if (!labelsFetching) {
      const allLabels = labelsData.map((label) => ({
        label,
        value: label,
      }));
      setLabels(allLabels);
    }
    refetchLabels();
  }, [labelsData, labelsFetching, refetchLabels]);

  useEffect(() => {
    if (!isLoading) {
      // build object with all entries as attributes to use a simple search
      const fieldsFromDb = settingsFields.length > 0 ? settingsFields[0].keyValue : [];
      // override default values, use same values
      const fromDb = {
        enableFixedDeliveryStopCost: true,
        enableMaxWaitingCost: false,
        enableDeliveryDemandCostAndRates: false,
        enableDeliveryCostPerLoadUnit: false,
      };
      fieldsFromDb.forEach((field) => {
        Object.assign(fromDb, field);
      });
      // assign id
      fromDb.id = settingsFields[0]?.id;
      setFormattedSetting(fromDb);
      setAllLoaded(true);
    }
    refetchSetting();
  }, [isLoading, refetchSetting, settingsFields]);

  const defaultValues = {
    enableFixedDeliveryStopCost: false,
    enableMaxWaitingCost: false,
    enableDeliveryDemandCostAndRates: false,
    enableDeliveryCostPerLoadUnit: false,
    maxSendForWork: 500,
    minVehicles: 0,
    geoserviceSpeedWeight: 0.9,
    fixedDeliveryStopCost: 10,
    fixedDeliveryStopCostUnit: 'minutes',
    maxWaitingCostUnit: 'minutes',
    deliveryDemandCostAndRatesUnit: 'minutes',
    deliveryCostPerLoadUnitUnit: 'minutes',
    defaultVehicleLoads: [0, 0, 0],
    showInParameterize: false,
  };
  const initialValues = { ...defaultValues, ...formattedSetting };

  const onFinish = async (values) => {
    try {
      // get fields only with data
      const fieldsWithValues = Object.keys(values).filter((key) => {
        return values[key] !== undefined;
      });
      // build format to save
      const allFields = fieldsWithValues.map((field) => {
        const value = values[field];
        return { [field]: value };
      });
      const endObject = {
        id: initialValues.id,
        keyValue: allFields,
      };
      if (initialValues.id) {
        const body = {
          ...endObject,
          id: initialValues.id,
          ...(organizationId && { organizationId }),
        };
        await updateSettingMobile(body).unwrap();
        message.success(i18n.t('alerts.updateSuccessfully', scopeI18n));
      } else {
        await createSettingMobile({
          ...endObject,
          type,
          ...(organizationId && { organizationId }),
        }).unwrap();
        message.success(i18n.t('alerts.createSuccessfully', scopeI18n));
      }
      if (goToNextStep) {
        await updateOrganization({
          organizationId,
          body: { creationStep: current + 1 },
        })
          .then(() => {
            const urlRedirect = `/organizations/creation-steps/${organizationId}/${current + 1}`;
            navigate(urlRedirect);
          })
          .catch((error) => {
            console.error(error);
            message.error(i18n.t('form.error'));
          });
      }
    } catch (error) {
      console.error(error);
      message.error(i18n.t('alerts.errorOnSave', scopeI18n));
    }
  };

  return (
    <>
      <Title level={3}>{i18n.t('title', scopeI18n)}</Title>
      {labels.length === 0 && (
        <Alert
          description={i18n.t('alerts.routingParameterIsRequired', scopeI18n)}
          type="warning"
          showIcon
          style={{ padding: 10, marginBottom: '1rem' }}
        />
      )}
      <Skeleton loading={!allLoaded} paragraph={{ rows: 10 }}>
        <RoutingGeneralForm
          settingsFields={formattedSetting}
          onFinish={onFinish}
          savingData={savingData || updatingData}
          initialValues={initialValues}
          submitButton={submitButton}
          labels={labels}
          organizationId={organizationId}
        />
      </Skeleton>
    </>
  );
}

RoutingGeneral.defaultProps = {
  organizationId: null,
  goToNextStep: false,
  submitButton: false,
  current: null,
};
RoutingGeneral.propTypes = {
  organizationId: PropTypes.number,
  goToNextStep: PropTypes.bool,
  submitButton: PropTypes.bool,
  current: PropTypes.number,
};

export default RoutingGeneral;
