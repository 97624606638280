import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Col, Popover, Row, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext } from 'react';
import { ColorByStatusEvent, FormatToTime } from 'services/repeated-functions';
import DetailItem from '../DetailItem';

const { Text } = Typography;

function TimeLineRoute(
  item,
  onClickEvents,
  loadsFromOrg,
  routeStartedAt,
  routeFinishedAt,
  timezone,
  items,
  refButtonEvents
) {
  const { name, arrivesAt, arrivedAt, addr, loads, events, itemOrder, id, hasAlert, ref, type } =
    item;
  const { city, province, street, number, line2 = undefined } = addr;
  const composedAddr = street && number ? `${street} ${number}` : undefined;
  const addrArray = [composedAddr, line2, city, province];
  const fullAddress = addrArray.filter((st) => st).join(', ');
  const formatedArrivesAt = FormatToTime(arrivesAt, timezone);
  const formatedArrivedAt = arrivedAt ? FormatToTime(arrivedAt, timezone) : '--';
  const formatedRouteStartedAt = routeStartedAt ? FormatToTime(routeStartedAt, timezone) : '--';
  const formatedRouteFinishedAt = routeFinishedAt ? FormatToTime(routeFinishedAt, timezone) : '--';
  const isStop = type === 'STOP';
  const isReported =
    (isStop && events.length > 0) ||
    (type === 'DEPOT' && routeStartedAt) ||
    (type === 'RETURN_TO_DEPOT' && routeFinishedAt);
  const lastEvent = events.length > 0 ? events[events.length - 1] : undefined;

  let itemArrivedAt = formatedArrivedAt;
  if (type === 'DEPOT') {
    itemArrivedAt = formatedRouteStartedAt;
  } else if (type === 'RETURN_TO_DEPOT') {
    itemArrivedAt = formatedRouteFinishedAt;
  }
  const hasPhoto = events?.some(({ eventAttachments }) =>
    eventAttachments?.some(({ photoType }) => photoType === 'photo')
  );

  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const buildDot = () => {
    const color = ColorByStatusEvent(lastEvent?.status);
    const isDefault = color === '#F5F5F5';

    if (isStop) {
      // use icon with pulse
      if (item.isFocused) {
        return (
          <FontAwesomeIcon
            icon="magnifying-glass-location"
            color={color}
            beatFade
            size="2x"
            mask="circle"
            transform="shrink-6"
          />
        );
      }
      return (
        <Badge
          color={color}
          count={itemOrder}
          size="29"
          style={{
            color: isDefault ? '#999999' : undefined,
            borderColor: isDefault ? '#999999' : undefined,
          }}
        />
      );
    }
    // draw depot or depot with focus
    return (
      <FontAwesomeIcon
        icon={item.isFocused ? 'magnifying-glass-location' : 'house'}
        beatFade={item.isFocused}
        size={item.isFocused ? '2x' : '1x'}
        mask={item.isFocused ? 'house' : ''}
        transform={item.isFocused ? 'shrink-6' : ''}
        color={isReported ? undefined : '#999999'}
      />
    );
  };

  const getLoadsWithUnit = () => {
    return loadsFromOrg
      .map(({ unit }, index) => {
        return `${loads[index]?.load} ${unit}`;
      })
      .join(' - ');
  };

  return {
    children: (
      <Row
        ref={ref}
        style={{ backgroundColor: item.isFocused ? '#E6F4FF' : undefined, padding: 5 }}
      >
        <Col span={20}>
          <Text strong type={isReported ? '' : 'secondary'}>
            {name}
          </Text>
          {hasAlert && (
            <FontAwesomeIcon
              icon="exclamation-triangle"
              style={{ marginLeft: '.5rem' }}
              color="#D00101"
            />
          )}
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Text strong type={isReported ? '' : 'secondary'}>
            {formatedArrivesAt}
          </Text>
        </Col>
        <Col span={20}>
          <Text ellipsis type={isReported ? '' : 'secondary'} title={fullAddress}>
            {fullAddress || i18n.t('noAddress', scopeI18n)}
          </Text>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Text type="secondary">{itemArrivedAt}</Text>
        </Col>
        {isStop && (
          <Col span={24}>
            <Text type={isReported ? '' : 'secondary'}>
              <FontAwesomeIcon icon="weight-hanging" /> {getLoadsWithUnit()}
              <Button
                ref={refButtonEvents}
                type="text"
                size="small"
                style={{ marginLeft: '1rem' }}
                disabled={!isReported}
                title={i18n.t(isReported ? 'showEvents' : 'noEvents', scopeI18n)}
                onClick={() => {
                  onClickEvents(id);
                }}
              >
                <FontAwesomeIcon icon="message" />
                {hasPhoto && (
                  <FontAwesomeIcon
                    icon="camera"
                    style={{ marginLeft: '.5rem' }}
                    title={i18n.t('stopWithPhotos', scopeI18n)}
                  />
                )}
              </Button>
              <Popover
                placement="right"
                title={name}
                content={
                  <DetailItem
                    item={item}
                    timezone={timezone}
                    items={items}
                    routeStartedAt={routeStartedAt}
                  />
                }
                overlayStyle={{
                  width: 450,
                }}
                trigger="click"
              >
                <Button type="text" size="small" title={i18n.t('moreInfo', scopeI18n)}>
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </Popover>
            </Text>
          </Col>
        )}
      </Row>
    ),
    dot: buildDot(item),
  };
}

export default TimeLineRoute;
