import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Carousel, Col, Divider, Image, Row, Skeleton, Tooltip, Typography } from 'antd';
import TitleHelp from 'components/common/TitleHelp';
import { FollowingEventProp } from 'components/Following/following.propTypes';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetEventAttachmentByEventQuery } from 'features/eventAttachments/eventAttachmentsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import * as haversine from 'haversine-distance';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  convertDateToDayjs,
  TextByStatusEvent,
  transformValueToNumberWithSeparator,
} from 'services/repeated-functions';
import EventMap from '../EventMap';

const { Text } = Typography;

function EventComponent(props) {
  const { event, arrivesAt, departsAt, loads, coordsItem } = props;
  const {
    status,
    comment,
    reason,
    form,
    id,
    timeCaptured,
    latitude: eventLatitude,
    longitude: eventLongitude,
  } = event;
  const [loading, setLoading] = useState(true);
  const [dataFormated, setDataFormated] = useState([]);
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const [showMap, setShowMap] = useState(false);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  // get eventAttachment from endpoint /by-event/:eventId
  const {
    data: eventAttachments,
    isLoading,
    refetch,
  } = useGetEventAttachmentByEventQuery(id, {
    skip: id === undefined,
  });

  useEffect(() => {
    setLoading(isLoading);
    setDataFormated(eventAttachments);
    refetch();
  }, [isLoading, refetch, eventAttachments]);

  // calculate distance
  const eventCoords = {
    lat: parseFloat(eventLatitude),
    lng: parseFloat(eventLongitude),
  };
  const { latitude: itemLatitude, longitude: itemLongitude } = coordsItem;
  const itemCoords = {
    lat: parseFloat(itemLatitude),
    lng: parseFloat(itemLongitude),
  };
  const distanceWithUnit = transformValueToNumberWithSeparator(haversine(eventCoords, itemCoords));

  return (
    <Skeleton active loading={loading}>
      <Row key={id} style={{ marginBottom: '1rem' }}>
        <Divider style={{ marginBottom: 0, marginTop: 0 }}>
          <Text strong>{TextByStatusEvent(status, i18n)}</Text>
        </Divider>
        {dataFormated?.length === 1 && (
          <Row style={{ width: '100%', marginTop: '5.rem' }}>
            <Col
              span={12}
              offset={6}
              style={{
                textAlign: 'center',
              }}
            >
              <Image
                key={eventAttachments[0].id}
                height={160}
                src={eventAttachments[0].signedUrl}
              />
            </Col>
          </Row>
        )}
        {dataFormated?.length > 1 && (
          <Row style={{ marginTop: '1rem' }}>
            <Col span={18} offset={4}>
              <Carousel className="images-events">
                {dataFormated.map((eventAttachment) => (
                  <div key={`div-${eventAttachment.id}`}>
                    <Image
                      key={eventAttachment.id}
                      height={160}
                      width={160}
                      style={{
                        textAlign: 'center',
                      }}
                      src={eventAttachment.signedUrl}
                    />
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
        )}

        <Row gutter={[12, 12]} style={{ marginTop: '1rem' }}>
          <Col span={12}>
            <TitleHelp
              title={i18n.t('arrival', scopeI18n)}
              helpText={i18n.t('helps.arrival', scopeI18n)}
              useSolid={false}
            />{' '}
            <Text type="secondary">
              {arrivesAt ? convertDateToDayjs(arrivesAt, tzOrganization, 'HH:mm') : ''}
            </Text>
          </Col>
          <Col span={12}>
            <TitleHelp
              title={i18n.t('departure', scopeI18n)}
              helpText={i18n.t('helps.departure', scopeI18n)}
              useSolid={false}
            />{' '}
            <Text type="secondary">
              {departsAt ? convertDateToDayjs(departsAt, tzOrganization, 'HH:mm') : ''}
            </Text>
          </Col>
          <Col span={12}>
            <TitleHelp
              title={i18n.t('timeCaptured', scopeI18n)}
              helpText={i18n.t('helps.timeCaptured', scopeI18n)}
              useSolid={false}
            />{' '}
            <Text type="secondary">
              {timeCaptured
                ? convertDateToDayjs(timeCaptured, tzOrganization, 'DD-MM-YY HH:mm')
                : ''}
            </Text>
          </Col>
          {loads && (
            <Col span={12}>
              {i18n.t('loads', scopeI18n)}: <Text type="secondary">{loads}</Text>
            </Col>
          )}
          <Col span={24}>
            <TitleHelp
              title={i18n.t('distance', scopeI18n)}
              helpText={i18n.t('helps.distance', scopeI18n)}
              useSolid={false}
            />{' '}
            <Text type="secondary">{`${distanceWithUnit} mts`}</Text>
            <Tooltip placement="right" title={i18n.t(showMap ? 'closeMap' : 'openMap', scopeI18n)}>
              <Button
                key="btn-location"
                size="small"
                style={{ marginLeft: '.5rem' }}
                onClick={() => {
                  setShowMap(!showMap);
                }}
                type={showMap ? 'primary' : 'default'}
              >
                <FontAwesomeIcon icon="location-pin" />
              </Button>
            </Tooltip>
          </Col>
          {showMap && (
            <Col span={24}>
              <EventMap
                eventCoords={{ latitude: eventLatitude, longitude: eventLongitude }}
                itemCoords={coordsItem}
                eventId={id}
                changeVisible={setShowMap}
                distance={distanceWithUnit}
              />
            </Col>
          )}
          {comment && (
            <Col span={24}>
              {i18n.t('comment', scopeI18n)}: <Text type="secondary">{comment}</Text>
            </Col>
          )}
          {reason && (
            <Col span={24}>
              {i18n.t('reason', scopeI18n)}: <Text type="secondary">{reason}</Text>
            </Col>
          )}
        </Row>
        {form?.length > 0 && (
          <Row style={{ marginTop: '1rem' }}>
            <Col span={24} style={{ marginBottom: '.5rem' }}>
              {i18n.t('form', scopeI18n)}:
            </Col>
            {form.map(({ question, response }, index) => (
              <Col key={`form-${event.id}-${question}`}>
                {index + 1}.- {question}: <Text type="secondary">{response}</Text>
              </Col>
            ))}
          </Row>
        )}
      </Row>
    </Skeleton>
  );
}

EventComponent.defaultProps = {
  event: {}, // could be a empty object
  arrivesAt: '',
  departsAt: '',
  loads: '',
  coordsItem: {},
};

EventComponent.propTypes = {
  event: FollowingEventProp,
  arrivesAt: PropTypes.string,
  departsAt: PropTypes.string,
  loads: PropTypes.string,
  coordsItem: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

export default EventComponent;
