import { App } from 'antd';
import { setNotifications } from 'features/app/appSlice';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { configFirebase } from 'services/firebase-init';
import apiSlice from '../app/api/apiSlice';

function Layout() {
  const dispatch = useDispatch();
  const { notification } = App.useApp();
  // Listener don't work every time, use onmessage direct
  const firebaseApp = initializeApp(configFirebase);
  const messaging = getMessaging(firebaseApp);

  onMessage(messaging, (payload) => {
    try {
      const { title, body, data } = payload.data;
      if (data) {
        // this must be setted always
        dispatch(setNotifications({ notification: payload.data }));
        const dataParsed = JSON.parse(data);
        const { type } = dataParsed;
        let tagToInvalidate;
        // ToDo: add other cases to refetch other data
        switch (type) {
          case 'demands':
          case 'superAdvancedDemands':
            tagToInvalidate = ['DemandsSets', 'FileProcessing'];
            break;
          case 'routing_job':
          case 'routes':
            tagToInvalidate = ['RoutingSets', 'FileProcessing'];
            break;
          case 'bases':
          case 'edit-bases':
          case 'visits':
          case 'edit-visits':
            tagToInvalidate = [
              'FileProcessing',
              'Locations',
              // reload when file was processed
              'CategoriesDepot',
              'CategoriesVisit',
              'GroupsForLocation',
            ];
            break;
          case 'vehicles':
            tagToInvalidate = ['Vehicles', 'FileProcessing'];
            break;
          case 'reports':
          case 'dowload':
            tagToInvalidate = ['FileProcessing'];
            break;
          default:
            break;
        }
        if (tagToInvalidate) {
          dispatch(apiSlice.util.invalidateTags(tagToInvalidate));
        }
      }
      notification.info({
        message: title,
        description: body,
      });
    } catch (error) {
      console.log('error: ', error);
      notification.error({
        message: 'Error',
        description: JSON.stringify(error),
      });
    }
  });
  return <Outlet />;
}

export default Layout;
