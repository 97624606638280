import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Col, DatePicker, Row, Space, Switch, Tooltip, Typography } from 'antd';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertDateToDayjs, getAllIndexFromArray } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import { FollowingRoutingSet } from '../../following.propTypes';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function FollowingHeaderRoutes(props) {
  const {
    showTable,
    setShowTable,
    routingSet,
    setFilterVisible,
    filterVisible,
    routeStartFilter,
    setRouteStartFilter,
    showNotifications,
    noRoutes,
  } = props;
  const navigate = useNavigate();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };

  const totalNotificaction = 1;
  const formatDate = 'DD-MM-YYYY';

  const switchChange = (e) => {
    setShowTable(e);
  };
  const onClickShowFilters = () => {
    setFilterVisible(!filterVisible);
  };
  const ability = useContext(CaslContext);
  const allRoutesFromArray = getAllIndexFromArray(['planning', 'routes', 'jobs'], ability);
  const { routes: routeIndexPath } = allRoutesFromArray;
  const { index: routesIndex, path: routesPath } = routeIndexPath;
  const routesKey = `planning${routesIndex ? '' : `/${routesPath}`}`;
  const onClickBackRoute = () => {
    navigate(`/${routesKey}`);
  };
  const { addReference } = useContext(TourContext);
  const refShowTable = useRef(null);
  const refDisplayFilter = useRef(null);
  addReference('showTableFollowingRef', refShowTable);
  addReference('showFilterFollowingRef', refDisplayFilter);

  const todayAsDayjs = convertDateToDayjs(new Date(), tzOrganization);
  const maxDayRange = 14;
  const disabledDate = (current) => {
    // maxDayRange or selected as initial + maxDayRange

    const tooLate =
      current > todayAsDayjs.clone().add(maxDayRange, 'days') ||
      current > convertDateToDayjs(routeStartFilter[0], tzOrganization).add(maxDayRange, 'days');
    const tooEarly = current < todayAsDayjs.clone().subtract(maxDayRange, 'days');

    return !!tooEarly || !!tooLate;
  };

  return (
    <Row>
      {routingSet && (
        <Col span={12} xxl={{ span: 12 }}>
          <Title level={4} style={{ fontWeight: 500 }}>
            {i18n.t('routingSetNid', {
              ...scopeI18n,
              nid: `${routingSet.name} [${routingSet.id}]`,
            })}
          </Title>
        </Col>
      )}
      {!routingSet && (
        <>
          <Col span={4} md={{ span: 5 }} xxl={{ span: 3 }}>
            <Title level={4} style={{ fontWeight: 500 }}>
              {i18n.t('planningFor', scopeI18n)}
              <Tooltip
                placement="right"
                title={i18n.t('helps.planningFor', scopeI18n)}
                overlayStyle={{ maxWidth: '400px' }}
              >
                <FontAwesomeIcon icon={['fas', 'question-circle']} color="#999999" />
              </Tooltip>
            </Title>
          </Col>
          <Col span={6} md={{ span: 6 }} xxl={{ span: 5 }}>
            <RangePicker
              format={formatDate}
              disabledDate={disabledDate}
              allowClear={false}
              onChange={(e) => {
                if (e) {
                  const initial = e[0].format('YYYY-MM-DD');
                  const final = e[1].format('YYYY-MM-DD');
                  setRouteStartFilter([initial, final]);
                }
              }}
              defaultValue={[todayAsDayjs, todayAsDayjs]}
            />
          </Col>
        </>
      )}
      <Col
        span={4}
        offset={!routingSet ? 10 : 8}
        md={{ span: !routingSet ? 5 : 8, offset: !routingSet ? 8 : 4 }}
        xxl={{ span: !routingSet ? 4 : 6, offset: !routingSet ? 12 : 6 }}
        align="right"
      >
        <Space>
          <div ref={refShowTable}>
            <Switch defaultChecked={showTable} onChange={switchChange} />{' '}
            {i18n.t('table', scopeI18n)}
          </div>
          {showNotifications && (
            <Badge count={totalNotificaction} overflowCount={10}>
              <Button title={i18n.t('notifications', scopeI18n)}>
                <FontAwesomeIcon icon="exclamation-triangle" />
              </Button>
            </Badge>
          )}
          <Button
            title={
              noRoutes ? i18n.t('routesNeededToFilter', scopeI18n) : i18n.t('filter', scopeI18n)
            }
            onClick={onClickShowFilters}
            type={filterVisible ? 'primary' : 'default'}
            ghost={filterVisible}
            disabled={noRoutes}
            ref={refDisplayFilter}
          >
            <FontAwesomeIcon icon="filter" />
          </Button>
          {routingSet && (
            <Button title={i18n.t('back', scopeI18n)} onClick={onClickBackRoute}>
              Volver
            </Button>
          )}
        </Space>
      </Col>
    </Row>
  );
}

FollowingHeaderRoutes.defaultProps = {
  setShowTable: () => {},
  showTable: false,
  routingSet: undefined,
  setFilterVisible: () => {},
  filterVisible: false,
  setRouteStartFilter: () => {},
  routeStartFilter: [],
  showNotifications: false,
  noRoutes: false,
};

FollowingHeaderRoutes.propTypes = {
  setShowTable: PropTypes.func,
  showTable: PropTypes.bool,
  routingSet: FollowingRoutingSet,
  setFilterVisible: PropTypes.func,
  filterVisible: PropTypes.bool,
  setRouteStartFilter: PropTypes.func,
  routeStartFilter: PropTypes.arrayOf(PropTypes.string),
  showNotifications: PropTypes.bool,
  noRoutes: PropTypes.bool,
};

export default FollowingHeaderRoutes;
