import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: null,
};

const errorsSlide = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setErrors: (state, action) => {
      return { ...state, status: action.payload };
    },
    clearErrors: () => initialState,
  },
});

export const { setErrors, clearErrors } = errorsSlide.actions;

export const selectErrors = (state) => state.errors;

export default errorsSlide.reducer;
