import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Popover, Progress, Skeleton, Tooltip, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetFilesProcessingLastFiveQuery } from 'features/documents/fileProcessingApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GetUrl, convertDateToDayjs } from 'services/repeated-functions';

function DocumentStatus() {
  const { Text } = Typography;
  const [data, setData] = useState([]);
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { data: fileProcessingData, isLoading } = useGetFilesProcessingLastFiveQuery();
  const [loadings, setLoadings] = useState([]);
  const [open, setOpen] = useState(false);
  const urlCsv = 'file-processing/download/id';
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'documents' };

  useEffect(() => {
    const setDataForTable = () => {
      const { data: dataResponse } = fileProcessingData;
      setData(dataResponse);
    };
    if (!isLoading) {
      setDataForTable();
    }
  }, [fileProcessingData, isLoading]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 1000);
  };

  const renderTitle = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 8,
        paddingBottom: 8,
      }}
    >
      <Text strong>{i18n.t('importExport', scopeI18n)}</Text>
      <Link to="/documents" style={{ fontSize: 11 }}>
        {i18n.t('showAll', scopeI18n)}
      </Link>
    </div>
  );

  const renderStatusBar = (item) => {
    const { status, id, createdAt, expired, type } = item;
    let percent = 100;
    let finalStatus;

    let view = (
      <Button
        type="link"
        size="small"
        href={GetUrl(urlCsv.replace('id', id))}
        style={{ fontSize: 11, width: '39%' }}
        onClick={() => enterLoading(id)}
        loading={loadings[id]}
        className="btn-no-shadow"
      >
        {i18n.t('openFile', scopeI18n)}
      </Button>
    );

    if (status !== 'SUCCESS') {
      switch (status) {
        case 'PROCESSING':
          percent = 70;
          finalStatus = 'active';
          break;
        case 'FAILED':
          finalStatus = 'exception';
          break;
        default:
          percent = 0;
          finalStatus = 'active';
          break;
      }
      view = <Progress percent={percent} status={finalStatus} showInfo={false} size="132" />;
    }
    if (status === 'SUCCESS') {
      const tooOld =
        convertDateToDayjs(createdAt, tzOrganization) <
        convertDateToDayjs(new Date(), tzOrganization).subtract(1, 'day');
      const cannotDownload = expired || (tooOld && type === 'EXPORT');
      let titleButton = '';
      if (expired) {
        titleButton = i18n.t('status.expired', scopeI18n);
      }
      if (tooOld) {
        titleButton = i18n.t('status.tooOld', scopeI18n);
      }
      if (cannotDownload) {
        view = (
          <Text title={titleButton} style={{ fontSize: 11 }}>
            {i18n.t('expired', scopeI18n)}
          </Text>
        );
      }
    }

    return view;
  };

  const renderUploadFailed = () => (
    <Link to="/documents/error_upload_file#" style={{ fontSize: 11 }}>
      {i18n.t('showError', scopeI18n)}
    </Link>
  );

  const renderItem = (item, index) => (
    <div
      key={index}
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 16,
        marginBottom: 16,
        alignItems: 'center',
      }}
    >
      <Skeleton loading={isLoading}>
        <FontAwesomeIcon
          icon={`file-arrow-${item.type === 'IMPORT' ? 'up' : 'down'}`}
          style={{ fontSize: 16 }}
        />
        <div style={{ width: '80%', marginLeft: 16, display: 'flex', flexDirection: 'column' }}>
          <Text style={{ fontSize: 11 }}>
            {item.name} {convertDateToDayjs(item.createdAt, tzOrganization, 'DD-MM-YYYY')}
          </Text>
          {renderStatusBar(item)}
          {item.status === 'FAILED' && renderUploadFailed()}
        </div>
      </Skeleton>
    </div>
  );

  const renderContent = () => data?.map((item, index) => renderItem(item, index));

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Col xs={0} md={24}>
      <Popover
        placement="bottomRight"
        title={() => renderTitle()}
        content={() => renderContent()}
        trigger="click"
        open={open}
        arrow={{ pointAtCenter: true }}
        onOpenChange={handleOpenChange}
      >
        <div>
          <Tooltip title={i18n.t('show', scopeI18n)} arrow={false}>
            <Button
              icon={<FontAwesomeIcon icon="arrow-down" color="#c1c1c1" />}
              size="large"
              onClick={() => setOpen(!open)}
            />
          </Tooltip>
        </div>
      </Popover>
    </Col>
  );
}

export default DocumentStatus;
