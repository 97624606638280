import { Button, Dropdown } from 'antd';
import PropTypes from 'prop-types';

function IntegrationsSubMenu(props) {
  const { setContent, setDefaultValue } = props;
  const items = [
    {
      key: 'integrations',
      label: 'Reporte de Eventos',
    },
    {
      key: 'sms',
      label: 'SMS',
    },
  ];

  const handleOnClick = ({ key }) => {
    setContent(key);
    setDefaultValue('integrations');
  };

  return (
    <Dropdown menu={{ items, onClick: handleOnClick }} placement="bottom">
      <Button
        type="text"
        size="small"
        onClick={(e) => e.preventDefault()}
        className="dropdown-routing"
      >
        Comunicaciones
      </Button>
    </Dropdown>
  );
}

IntegrationsSubMenu.defaultProps = {
  setContent: () => {},
  setDefaultValue: () => {},
};

IntegrationsSubMenu.propTypes = {
  setContent: PropTypes.func,
  setDefaultValue: PropTypes.func,
};

export default IntegrationsSubMenu;
