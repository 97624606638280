import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import PropTypes from 'prop-types';
import CustomAttributesPropTypes from 'components/common/CustomAttributes/customAttributes.propTypes';
import TitleHelp from 'components/common/TitleHelp';
import SelectPlus from '../SelectPlus';

const { Option } = Select;

function DynamicFieldsInput(props) {
  const {
    customAttribute: { key, label, name, description, type, options },
    listGroups,
  } = props;
  const inputLabel = <TitleHelp title={label} helpText={description} />;
  const form = Form.useFormInstance();
  const dateFormat = 'DD-MM-YYYY';
  const timeFormat = 'HH:mm';

  const onChange = () => {
    // set value on dynamic field name
    form.setFieldValue(`dynamicFieldsNames[${key}]`, name);
    // set value on dynamic field type
    form.setFieldValue(`dynamicFieldsTypes[${key}]`, type);
    // set value on dynamic field label
    form.setFieldValue(`dynamicFieldsLabels[${key}]`, label);
    // set value on dynamic field description
    form.setFieldValue(`dynamicFieldsDescriptions[${key}]`, description);
    // format dynamic fields
    const formValues = form.getFieldsValue();
    const dFregex = /dynamicFields\[\d+\]/;
    const dFKeys = Object.keys(formValues).filter((obKey) => obKey.match(dFregex));
    const restrictionsValues = [];
    dFKeys?.forEach((value) => {
      const id = value.match(/\[(.*?)\]/)?.[1];
      const fieldType = formValues[`dynamicFieldsTypes[${id}]`];
      const fieldName = formValues[`dynamicFieldsNames[${id}]`];
      const fieldLabel = formValues[`dynamicFieldsLabels[${id}]`];
      const fieldDescription = formValues[`dynamicFieldsDescriptions[${id}]`];
      const valueForm = formValues[value];

      if (!fieldType && !fieldName && !valueForm) {
        return;
      }
      let parsedValue;
      if (fieldType === 'timerange') {
        const formattedValues = valueForm?.map((val) => {
          return val ? val.format('HH:mm:ss') : '';
        });
        parsedValue = formattedValues;
      } else if (fieldType === 'number') {
        parsedValue = parseInt(valueForm, 10);
      } else if (fieldType === 'date') {
        parsedValue = valueForm ? valueForm.format('YYYY-MM-DD') : null;
      } else if (fieldType === 'time') {
        parsedValue = valueForm ? valueForm.format(timeFormat) : null;
      } else {
        parsedValue = valueForm;
      }
      if (parsedValue) {
        const finalValue = parsedValue === null ? '' : parsedValue;
        restrictionsValues.push({
          id: parseInt(id, 10),
          name: fieldName,
          value: finalValue,
          type: fieldType,
          label: fieldLabel,
          description: fieldDescription,
        });
      }
    });
    // set restrictions field with dynamic fields values formatted
    form.setFieldValue('restrictions', restrictionsValues || {});
  };

  const defineComponent = () => {
    let elementToRender = `**Tipo "${type}" no tiene componente para dibujar!**`;
    switch (type) {
      case 'string':
        elementToRender = <Input key={key} placeholder={label} form={form} onChange={onChange} />;
        break;
      case 'select':
        elementToRender = (
          <Select key={key} placeholder={label} allowClear onChange={onChange}>
            {options.map((option) => {
              const { value: valueOption, label: labelOption, icon: iconOption } = option;
              return (
                <Option key={valueOption} value={valueOption}>
                  {iconOption ? (
                    <FontAwesomeIcon icon={iconOption} className="margin-right-icon" />
                  ) : undefined}
                  {labelOption}
                </Option>
              );
            })}
          </Select>
        );
        break;
      case 'date':
        elementToRender = <DatePicker key={key} format={dateFormat} onChange={onChange} />;
        break;
      case 'boolean':
        elementToRender = (
          <Checkbox key={key} style={{ marginTop: '2rem' }} onChange={onChange}>
            {inputLabel}
          </Checkbox>
        );
        break;
      case 'number':
        elementToRender = (
          <Input key={key} placeholder={label} form={form} type="number" onChange={onChange} />
        );
        break;
      case 'timerange':
        elementToRender = (
          <TimePicker.RangePicker
            key={key}
            format="HH:mm"
            popupClassName="timepicker-width"
            onChange={onChange}
          />
        );
        break;
      case 'time':
        elementToRender = (
          <TimePicker
            popupClassName="timepicker-width"
            key={key}
            format={timeFormat}
            onChange={onChange}
          />
        );
        break;
      case 'selectPlus':
        elementToRender = (
          <SelectPlus
            key={key}
            onChange={onChange}
            label={label}
            baseItems={listGroups}
            form={form}
            nameField={`dynamicFields[${key}]`}
          />
        );
        break;
      default:
        break;
    }
    return elementToRender;
  };

  return (
    <>
      <Form.Item
        key={key}
        name={`dynamicFields[${key}]`}
        label={type === 'boolean' ? '' : inputLabel}
        valuePropName={type === 'boolean' ? 'checked' : 'value'}
      >
        {defineComponent()}
      </Form.Item>
      <Form.Item name={`dynamicFieldsNames[${key}]`} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={`dynamicFieldsTypes[${key}]`} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={`dynamicFieldsLabels[${key}]`} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={`dynamicFieldsDescriptions[${key}]`} hidden>
        <Input />
      </Form.Item>
    </>
  );
}

DynamicFieldsInput.defaultProps = {
  customAttribute: {},
  listGroups: [],
};

DynamicFieldsInput.propTypes = {
  customAttribute: CustomAttributesPropTypes,
  listGroups: PropTypes.arrayOf(PropTypes.string),
};

export default DynamicFieldsInput;
