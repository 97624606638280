import { Button } from 'antd';
import PropTypes from 'prop-types';

function ButtonTable(props) {
  const { type, label, danger, className, onClickEvent } = props;
  return (
    <Button type={type} danger={danger} className={className} onClick={onClickEvent}>
      {label}
    </Button>
  );
}

ButtonTable.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onClickEvent: PropTypes.func,
  danger: PropTypes.bool,
  className: PropTypes.string,
};

ButtonTable.defaultProps = {
  type: 'default',
  label: 'Ver',
  danger: false,
  className: '',
  onClickEvent: () => {},
};

export default ButtonTable;
