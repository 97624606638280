import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  nationalNid: undefined,
  givenName: undefined,
  lastName: undefined,
  fullName: undefined,
  photo: undefined,
  phone: undefined,
  email: undefined,
  currentOrganizationId: undefined,
  subject: undefined,
  organizations: [],
  roles: [],
  actions: [{}],
};
const userSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      const { userProfile } = action.payload;
      const {
        id,
        nationalNid,
        fullName,
        photo,
        givenName,
        lastName,
        phone,
        email,
        subject,
        organizations,
        organization,
        actions,
        roles,
        notificationToken,
        enableTour,
      } = userProfile;

      const data = {
        id,
        nationalNid,
        givenName,
        lastName,
        fullName,
        photo,
        phone,
        email,
        subject,
        roles,
        currentOrganizationId: organization?.id,
        organizations,
        organization,
        actions,
        notificationToken,
        enableTour,
      };
      return {
        ...state,
        data,
      };
    },
    setActionsCurrentUser: (state, action) => {
      const { actions } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          actions,
        },
      };
    },
    logOutCurrentUser: () => initialState,
    setStatusTour: (state, action) => {
      const { enableTour } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          enableTour,
        },
      };
    },
  },
});

export const { setCurrentUser, logOutCurrentUser, setActionsCurrentUser, setStatusTour } =
  userSlice.actions;

export default userSlice.reducer;
export const selectCurrentUser = (state) => state.currentUser.data;
export const selectUserActions = (state) => state.currentUser.data.actions;
export const selectCurrentOrganization = (state) => state.currentUser.data.organization;
export const selectEnableTour = (state) => state.currentUser.data.enableTour;
