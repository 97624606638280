import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faCopy,
  faStar,
  faBell as farBell,
  faCircleQuestion as farCircleInfo,
  faCircleXmark as farCircleXmark,
  faMessage as farMessage,
  faXmarkCircle as farXmarkCircle,
  faCircleDot,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAmbulance,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBinoculars,
  faBuilding,
  faBurger,
  faCamera,
  faCar,
  faCaretDown,
  faCaretUp,
  faChartColumn,
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faCircleXmark,
  faClipboardCheck,
  faClock,
  faClockRotateLeft,
  faCloudArrowDown,
  faCog,
  faCrosshairs,
  faCrown,
  faDownload,
  faEllipsis,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faFileArrowDown,
  faFileArrowUp,
  faFileImport,
  faFileLines,
  faFilePen,
  faFilter,
  faFolderOpen,
  faG,
  faHeadset,
  faHouse,
  faHouseChimney,
  faInbox,
  faInfo,
  faKey,
  faLightbulb,
  faListUl,
  faLocationDot,
  faLocationPin,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassLocation,
  faMagnifyingGlassPlus,
  faMap,
  faMapLocation,
  faMapMarker,
  faMaximize,
  faMessage,
  faMinimize,
  faMotorcycle,
  faP,
  faPenToSquare,
  faPhone,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRightFromBracket,
  faRoad,
  faRotate,
  faRoute,
  faShuttleVan,
  faSpinner,
  faTable,
  faTimes,
  faTrash,
  faTruck,
  faTruckFront,
  faUpload,
  faUser,
  faUsers,
  faVanShuttle,
  faWeightHanging,
  faWrench,
  faXmark,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faStar,
  faListUl,
  faBuilding,
  faClipboardCheck,
  faInbox,
  faBell,
  farBell,
  faUpload,
  faCopy,
  faRoad,
  faMapMarker,
  faSpinner,
  faQuestionCircle,
  faTimes,
  faCircleInfo,
  faCircleExclamation,
  faMagnifyingGlassPlus,
  faMagnifyingGlassLocation,
  faTrash,
  faEye,
  faPhone,
  faDownload,
  faRoute,
  faArrowRight,
  faUser,
  faVanShuttle,
  faUsers,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faEllipsis,
  faArrowDown,
  faFileArrowUp,
  faShuttleVan,
  faAmbulance,
  faTruck,
  faMotorcycle,
  faCar,
  faRightFromBracket,
  faKey,
  faHouseChimney,
  faLocationPin,
  faRotate,
  faMap,
  faFilePen,
  faCheckCircle,
  faTable,
  faCircleCheck,
  faLock,
  faLightbulb,
  faCircleMinus,
  faXmark,
  faPenToSquare,
  faWrench,
  faAngleRight,
  faAngleLeft,
  faClockRotateLeft,
  faBars,
  faCrown,
  faClock,
  faCrosshairs,
  faMapLocation,
  faChartColumn,
  faCog,
  faHeadset,
  faArrowLeft,
  faPlus,
  faLocationDot,
  faExclamationTriangle,
  faFilter,
  faCircle,
  faCaretUp,
  faCaretDown,
  faMaximize,
  faMinimize,
  faWeightHanging,
  faMessage,
  faCirclePlus,
  faHouse,
  farCircleInfo,
  faChartPie,
  faFileLines,
  faCloudArrowDown,
  faFileArrowDown,
  faCamera,
  farMessage,
  farXmarkCircle,
  faCircleXmark,
  farCircleXmark,
  faMagnifyingGlass,
  faTruckFront,
  faG,
  faP,
  faQuestion,
  faEnvelope,
  faInfo,
  faBinoculars,
  faBurger,
  faFileImport,
  faCheck,
  faFolderOpen,
  faCircleDot,
  faTrashCan,
  faCircleNotch
);
