import { Skeleton } from 'antd';
import RoutingSettings from 'components/Organizations/Configurations/Routing/Settings';
import PropTypes from 'prop-types';

function OrganizationsCreationStepsRoutingSettings(props) {
  const { organizationId, isLoading, current } = props;
  return isLoading ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <RoutingSettings organizationId={organizationId} goToNextStep submitButton current={current} />
  );
}

OrganizationsCreationStepsRoutingSettings.defaultProps = {
  organizationId: null,
  isLoading: false,
  current: null,
};
OrganizationsCreationStepsRoutingSettings.propTypes = {
  organizationId: PropTypes.number,
  isLoading: PropTypes.bool,
  current: PropTypes.number,
};

export default OrganizationsCreationStepsRoutingSettings;
