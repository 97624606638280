import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { formType } from 'types';

// define an array of empty strings in props to setup empty inputs.
function InputsForm(props) {
  const {
    staticInputs, // Number, Sets the number of fields that are always present
    placeholderValue, // Function, defines the fields placeholder value, based on it's index.
    fieldValidation, // Function, Sets the field validation for every field created.
    addOptionsButtonCondition, // Function, Sets the condition for when the "+ Añadir opciones" button appears.
    form,
  } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  useEffect(() => {
    if (form) {
      form.validateFields();
    }
  }, [form]);

  return (
    <Form.List name="formOptions">
      {(fields, { add, remove }, { errors }) => (
        <Space
          direction="vertical"
          style={{
            display: 'flex',
          }}
        >
          <Row justify="end">
            <Col span={24}>
              <Form.ErrorList errors={errors} />
            </Col>
          </Row>
          {fields.map(({ key, name, ...restField }, loadIndex) => {
            const { isListField } = restField;
            return (
              <Row justify="space-between" align="top" key={`dynamic-${key}`}>
                <Col span={21}>
                  <Form.Item
                    isListField={isListField}
                    name={[name, 'option']}
                    rules={[{ validator: (_, value) => fieldValidation(_, value, loadIndex) }]}
                  >
                    <Input placeholder={placeholderValue(loadIndex)} />
                  </Form.Item>
                </Col>
                {fields.length > staticInputs && (
                  <Col span={2}>
                    <FontAwesomeIcon
                      className="dynamic-delete-button"
                      icon={['fas', 'circle-minus']}
                      onClick={() => remove(name)}
                    />
                  </Col>
                )}
              </Row>
            );
          })}
          {addOptionsButtonCondition() && (
            <Row>
              <Col span={21}>
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    {i18n.t('buttons.addOptions')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Space>
      )}
    </Form.List>
  );
}
InputsForm.defaultProps = {
  staticInputs: 0,
  placeholderValue: (index) => {
    return `Opción ${index + 1}`;
  },
  fieldValidation: (_, value) => {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('El campo no puede quedar vacio'));
  },
  addOptionsButtonCondition: () => {
    return true;
  },
  form: undefined,
};
InputsForm.propTypes = {
  staticInputs: PropTypes.number,
  placeholderValue: PropTypes.func,
  fieldValidation: PropTypes.func,
  addOptionsButtonCondition: PropTypes.func,
  form: formType,
};

export default InputsForm;
