import englishLocale from './en';
import spanishLocale from './es';
import portugueseBrLocale from './ptBr';

const localeTranslate = {
  ...englishLocale,
  ...portugueseBrLocale,
  ...spanishLocale,
};

export default localeTranslate;
