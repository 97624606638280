import PropTypes from 'prop-types';
import TitleHelp from 'components/common/TitleHelp';
import InputsForm from 'components/common/Form/InputsForm';
import { Form, Row, Col, Input, Select, Checkbox, Typography, App } from 'antd';
import { useEffect, useState } from 'react';
import { formType } from 'types';
import questionTypes from '../questionTypeData.json';
import { AllQuestionsPropTypes } from '../OrganizationMobile.propTypes';

const { Text } = Typography;

function FormQuestion(props) {
  const {
    form,
    setSaveButtonLoading,
    setSaveButtonDisabled,
    drawerVisible,
    statusSelected,
    baseForm,
    setDrawerVisible,
    selectedQuestion,
    allQuestions,
    setAllQuestions,
  } = props;
  const baseValues = {
    nid: '',
    label: '',
    type: undefined,
    formOptions: [{ option: '' }, { option: '' }],
    defaultOption: undefined,
    required: false,
  };
  const [fieldValues, setLoadedValues] = useState({});
  const [questionType, setQuestionType] = useState(baseValues.type);
  const { message } = App.useApp();

  useEffect(() => {
    if (drawerVisible) {
      form.resetFields();
    }
  }, [form, drawerVisible]);

  const searchOptionCategoty = (searchValue) => {
    return searchValue === undefined
      ? 'Input'
      : questionTypes.find((t) => t.value === searchValue).category;
  };

  const [formOptionsType, setFormOptionsType] = useState(searchOptionCategoty(questionType));

  const changeType = (value) => {
    setQuestionType(value);
  };

  // Validation of changed values based on the modified values.
  const hasChangedValues = (values) => {
    return Object.keys(values).every(
      (key) =>
        values[key] ===
        (selectedQuestion === undefined
          ? baseValues[key]
          : allQuestions[`questionsOn${statusSelected}`][selectedQuestion][key])
    );
  };

  // InputForms functions, conditions & validation
  const validateDynamicFields = (_, value, index) => {
    if (value) {
      if (formOptionsType !== 'Select') {
        if (index > 1) {
          setSaveButtonDisabled(true);
          return Promise.reject(new Error('La opción debe ser eliminada'));
        }
        if (!value.match(/^\d+$/)) {
          setSaveButtonDisabled(true);
          return Promise.reject(new Error('El valor debe ser numérico'));
        }
      }
      setSaveButtonDisabled(false);
      return Promise.resolve();
    }
    setSaveButtonDisabled(true);
    return Promise.reject(new Error('El campo no puede quedar vacio'));
  };
  const placeholderValue = (index) => {
    return formOptionsType === 'Select'
      ? `Opción ${index + 1}`
      : (index === 0 && 'Minimo') || 'Maximo';
  };
  const addOptionsButtonCondition = () => {
    return formOptionsType === 'Select';
  };

  const onFinish = async (values) => {
    setSaveButtonLoading(true);
    if (hasChangedValues(values)) {
      message.warning('No se han realizado cambios.', 4);
    } else {
      const existingQuestions = allQuestions[`questionsOn${statusSelected}`];
      if (selectedQuestion === undefined) {
        setAllQuestions({
          ...allQuestions,
          [`questionsOn${statusSelected}`]: [...existingQuestions, values],
        });
      } else {
        setAllQuestions({
          ...allQuestions,
          [`questionsOn${statusSelected}`]: [
            ...existingQuestions.map((question, index) => {
              if (index === selectedQuestion) {
                return values;
              }
              return question;
            }),
          ],
        });
      }
      message.success(
        `Pregunta ${selectedQuestion === undefined ? 'creada' : 'guardada'} correctamente`
      );
    }
    setDrawerVisible(false, statusSelected);
    setSaveButtonLoading(false);
  };

  const onFinishFailed = () => {
    message.error('Formulario con errores, revisar');
  };

  useEffect(() => {
    setFormOptionsType(searchOptionCategoty(questionType));
  }, [questionType]);

  // Loads the question drawer fields if a question is selected and the values were not loaded before.
  if (Object.keys(fieldValues).length === 0 && selectedQuestion !== undefined) {
    const localFieldValues = {};
    const mainFields = ['nid', 'label', 'type', 'required'];
    const questions = baseForm.getFieldValue(`questionsOn${statusSelected}`);
    mainFields.forEach((_field) => {
      localFieldValues[`${_field}`] = questions[selectedQuestion][`${_field}`];
    });
    setQuestionType(localFieldValues.type);
    setFormOptionsType(searchOptionCategoty(questionType));
    if (questions[selectedQuestion].formOptions !== undefined) {
      localFieldValues.formOptions = questions[selectedQuestion].formOptions;
    }
    setLoadedValues(localFieldValues);
  }

  const initialValues = { ...baseValues, ...fieldValues };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      name="question-form"
      initialValues={initialValues}
      autoComplete="off"
      requiredMark="required"
    >
      <Row gutter={24}>
        <Col span={16}>
          <Form.Item
            label={
              <TitleHelp title="NID" helpText="Identificador numérico único de la pregunta." />
            }
            name="nid"
            size="large"
            required
            rules={[
              () => ({
                validator(_, value) {
                  let currentQuestions = [];
                  Object.values(allQuestions).forEach((question) => {
                    currentQuestions = [...currentQuestions, ...question];
                  });
                  if (value) {
                    if (!value.match(/^\d+$/)) {
                      setSaveButtonDisabled(true);
                      return Promise.reject(new Error('El valor debe ser numérico'));
                    }
                    if (
                      currentQuestions.find((q) => {
                        return q.nid === value;
                      }) &&
                      (selectedQuestion === undefined
                        ? true
                        : allQuestions[`questionsOn${statusSelected}`][selectedQuestion].nid !==
                          value)
                    ) {
                      setSaveButtonDisabled(true);
                      return Promise.reject(new Error('El nid ingresado ya esta registrado'));
                    }
                    setSaveButtonDisabled(false);
                    return Promise.resolve();
                  }
                  setSaveButtonDisabled(true);
                  return Promise.reject(new Error('Debe definir un indentificador numérico'));
                },
              }),
            ]}
          >
            <Input placeholder="Identificador numérico" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={16}>
          <Form.Item
            label={
              <TitleHelp
                title="Etiqueta"
                helpText="Nombre de la pregunta que el conductor vera en el formulario."
              />
            }
            name="label"
            size="large"
            required
            rules={[
              () => ({
                validator(_, value) {
                  if (value) {
                    setSaveButtonDisabled(false);
                    return Promise.resolve();
                  }
                  setSaveButtonDisabled(true);
                  return Promise.reject(new Error('Debe definir un nombre'));
                },
              }),
            ]}
          >
            <Input placeholder="Nombre de pregunta" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={16}>
          <Form.Item
            label={<TitleHelp title="Tipo" helpText="Tipo de respuesta que verá el conductor." />}
            size="large"
            required
            name="type"
            rules={[
              () => ({
                validator(_, value) {
                  if (value) {
                    setSaveButtonDisabled(false);
                    return Promise.resolve();
                  }
                  setSaveButtonDisabled(true);
                  return Promise.reject(new Error('Debe definir un tipo de respuesta'));
                },
              }),
            ]}
          >
            <Select
              allowClear
              placeholder="Tipo de respuesta"
              value={questionType}
              options={questionTypes}
              onChange={changeType}
            />
          </Form.Item>
        </Col>
      </Row>
      {(formOptionsType === 'Select' || formOptionsType === 'Slider') && (
        <Row gutter={24}>
          <Col span={18}>
            <div style={{ margin: '0 0 8px' }}>
              <Text>{formOptionsType === 'Select' ? 'Opciones:' : 'Valores:'}</Text>
            </div>
            <InputsForm
              staticInputs={2}
              placeholderValue={placeholderValue}
              fieldValidation={validateDynamicFields}
              addOptionsButtonCondition={addOptionsButtonCondition}
              form={form}
            />
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col span={16}>
          <Form.Item name="required" valuePropName="checked">
            <Checkbox>
              <TitleHelp
                title="Obligatorio"
                helpText="Evita que el reporte sea enviado si la pregunta no es respondida."
              />
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
FormQuestion.defaultProps = {
  form: {},
  setSaveButtonLoading: () => {},
  setSaveButtonDisabled: () => {},
  drawerVisible: false,
  statusSelected: 'success',
  baseForm: {},
  setDrawerVisible: () => {},
  selectedQuestion: undefined,
  allQuestions: {},
  setAllQuestions: () => {},
};
FormQuestion.propTypes = {
  form: formType,
  setSaveButtonLoading: PropTypes.func,
  setSaveButtonDisabled: PropTypes.func,
  drawerVisible: PropTypes.bool,
  statusSelected: PropTypes.string,
  baseForm: formType,
  setDrawerVisible: PropTypes.func,
  selectedQuestion: PropTypes.number,
  allQuestions: AllQuestionsPropTypes,
  setAllQuestions: PropTypes.func,
};

export default FormQuestion;
