import { useLocation, Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NotificationArea from '../../components/NotificationArea';
import { selectCurrentToken } from './authSlice';
import './style.css';

function RequireAuth(props) {
  const { isOffline } = props;
  const token = useSelector(selectCurrentToken);
  const location = useLocation();

  return token ? (
    <>
      <NotificationArea isOffline={isOffline} />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

RequireAuth.propTypes = {
  isOffline: PropTypes.bool,
};

RequireAuth.defaultProps = {
  isOffline: true,
};
export default RequireAuth;
