import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Space } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';

function ButtonMenu(props) {
  const { onClickEvent, disableMassive, refElement } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'buttons' };
  const items = [
    {
      label: i18n.t('manual', scopeI18n),
      key: 'manual',
    },
    {
      label: i18n.t('massive', scopeI18n),
      key: 'massive',
      disabled: disableMassive,
    },
  ];
  return (
    <Dropdown menu={{ items, onClick: onClickEvent }}>
      <Button type="primary" className="float-right" ref={refElement}>
        <Space>
          {i18n.t('create', scopeI18n)}
          <FontAwesomeIcon icon={['fas', 'chevron-down']} fontSize={14} />
        </Space>
      </Button>
    </Dropdown>
  );
}

ButtonMenu.propTypes = {
  onClickEvent: PropTypes.func,
  disableMassive: PropTypes.bool,
  refElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

ButtonMenu.defaultProps = {
  onClickEvent: () => {},
  disableMassive: false,
  refElement: {},
};

export default ButtonMenu;
