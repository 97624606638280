import { Card, Flex, Modal } from 'antd';
import example from 'assets/documents/plantilla_usuarios.csv';
import exampleExcel from 'assets/documents/plantilla_usuarios_excel.csv';
import ButtonDownload from 'components/common/ButtonDownload';
import PropTypes from 'prop-types';
import { useState } from 'react';
import UserDragger from './Dragger';

function UserModalMassive(props) {
  const { visibleModal, setVisibleModal } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const items = [
    {
      label: 'Windows',
      key: 'xlsx',
    },
    {
      label: 'Otros',
      key: 'csv',
    },
  ];

  const downloadFile = async (event) => {
    const link = document.createElement('a');
    switch (event.key) {
      case 'xlsx':
        link.href = exampleExcel;
        link.download = 'plantilla_usuarios_excel.csv';
        break;
      case 'csv':
        link.href = example;
        link.download = 'plantilla_usuarios.csv';
        break;
      default:
        break;
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const titleModal = (
    <div className="ant-modal-title importer-modal">
      <Flex gap="middle" align="start" justify="space-between" vertical={false}>
        Importar Usuarios
        <ButtonDownload
          key="download-example-users-button"
          size="medium"
          onClickEvent={downloadFile}
          items={items}
          buttonName="Plantilla_ejemplo"
        />
      </Flex>
    </div>
  );

  const handleOk = () => {
    setVisibleModal(false);
    setConfirmLoading(false);
    setFileList([]);
  };

  const handleCancel = () => {
    setVisibleModal(false);
    setFileList([]);
  };

  const gridStyleLarge = {
    width: '100%',
    textAlign: 'center',
    height: '100%',
  };

  return (
    <Modal
      title={titleModal}
      open={visibleModal}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width="40%"
      centered
      cancelText="Cancelar"
      okText="Aceptar"
      closable={false}
    >
      <Card.Grid style={gridStyleLarge} hoverable={false}>
        <UserDragger
          fileList={fileList}
          setFileList={setFileList}
          setConfirmLoading={setConfirmLoading}
        />
      </Card.Grid>
    </Modal>
  );
}
UserModalMassive.propTypes = {
  setVisibleModal: PropTypes.func,
  visibleModal: PropTypes.bool,
};

UserModalMassive.defaultProps = {
  setVisibleModal: () => {},
  visibleModal: false,
};

export default UserModalMassive;
