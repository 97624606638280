import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Divider, Input, Select, Space } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';

function SelectPlus(props) {
  const { baseItems, label, onChange, form, nameField } = props;
  const [items, setItems] = useState([...baseItems]);
  const [name, setName] = useState('');
  const [value, setValue] = useState(form.getFieldValue(nameField));
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.selectPlus' };

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    if (name) {
      // add item only if not exist
      if (!items.includes(name)) {
        setItems([...items, name]);
      }
      setValue(name);
      onChange(name);
      setOpen(false);
      setName('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      message.error(
        i18n.t('typeForAddANewElement', { ...scopeI18n, element: label.toLowerCase() })
      );
    }
  };

  const ddRender = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: '8px 0',
        }}
      />
      <Space
        style={{
          padding: '0 8px 4px',
        }}
      >
        <Input
          placeholder={i18n.t('addTo', { ...scopeI18n, label })}
          ref={inputRef}
          value={name}
          onChange={onNameChange}
          maxLength={50}
          showCount
        />
        <Button type="primary" onClick={addItem}>
          <FontAwesomeIcon icon="plus" style={{ marginRight: '.5rem' }} />
          {i18n.t('add', scopeI18n)}
        </Button>
      </Space>
    </>
  );

  return (
    <Select
      placeholder={i18n.t('commons.select')}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      open={open}
      value={value}
      dropdownRender={(menu) => ddRender(menu)}
      options={items.map((item, index) => {
        const keyOption = `opt-${index}`;
        return {
          key: keyOption,
          label: item,
          value: item,
        };
      })}
      allowClear
      showSearch
      onChange={(newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
    />
  );
}

SelectPlus.defaultProps = {
  baseItems: [],
  label: 'Grupo',
  onChange: () => {},
  form: {},
  nameField: '',
};
SelectPlus.propTypes = {
  baseItems: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  onChange: PropTypes.func,
  nameField: PropTypes.string,
  form: PropTypes.shape({
    getFieldError: PropTypes.func,
    getFieldInstance: PropTypes.func,
    getFieldValue: PropTypes.func,
    getFieldWarning: PropTypes.func,
    getFieldsError: PropTypes.func,
    getFieldsValue: PropTypes.func,
    getInternalHooks: PropTypes.func,
    isFieldTouched: PropTypes.func,
    isFieldValidating: PropTypes.func,
    isFieldsTouched: PropTypes.func,
    isFieldsValidating: PropTypes.func,
    resetFields: PropTypes.func,
    scrollToField: PropTypes.func,
    setFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    setFieldValue: PropTypes.func,
    submit: PropTypes.func,
    validateFields: PropTypes.func,
  }),
};

export default SelectPlus;
