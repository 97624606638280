import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, App, Col, Empty, Row, Spin, Table } from 'antd';
import ModalConfirm from 'components/common/ModalConfirm';
import { useGetDepotLocationsQuery } from 'features/locations/locationsApiSlice';
import { useDownloadMutation } from 'features/reports/reportsApiSlice';
import { useEffect, useState } from 'react';
import editionReportColumns from './ColumnsDefinition/editionReportColumns';
import executionReportColumns from './ColumnsDefinition/executionReportColumns';
import reportRoutesColumns from './ColumnsDefinition/reportRouteColumns';
import DownloadFilter from './Filter';

function Downloads() {
  const [depots, setDepots] = useState([]);
  const [typeReport, setTypeReport] = useState();
  const [targetKeys, setTargetKeys] = useState([]);
  const [columns, setColumns] = useState([]);
  const [emptyData, setEmptyData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataForTable, setDataForTable] = useState([]);
  const [values, setValues] = useState();
  const { notification } = App.useApp();
  const [download] = useDownloadMutation();

  const {
    data: locationsData,
    refetch,
    isLoading,
  } = useGetDepotLocationsQuery({
    refetchOnMountOrArgChange: true,
  });
  // load depots
  useEffect(() => {
    const fetchData = () => {
      if (locationsData) {
        setDepots(locationsData);
      }
    };
    refetch();
    fetchData();
  }, [locationsData, refetch, isLoading]);

  useEffect(() => {
    if (typeReport && targetKeys) {
      // load columns for selected one
      setEmptyData(targetKeys.length === 0);
      // load structure from js
      if (typeReport === 'report_route') {
        setColumns(reportRoutesColumns.filter((col) => targetKeys.includes(col.transferKey)));
      } else if (typeReport === 'execution_report') {
        setColumns(executionReportColumns.filter((col) => targetKeys.includes(col.transferKey)));
      } else if (typeReport === 'edition_report') {
        setColumns(editionReportColumns.filter((col) => targetKeys.includes(col.transferKey)));
      }
    }
  }, [targetKeys, typeReport]);

  const downloadReport = async () => {
    try {
      await download({ ...values }).unwrap();
      notification.open({
        message: 'Generando documento',
        placement: 'topRight',
        description: 'Se le notificará cuando se encuentre disponible para descargar.',
        icon: <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" bounce color="#0d63cf" />,
      });
    } catch (error) {
      notification.error('No se pudo generar la descarga');
    }
  };

  const onConfirmDownload = (event) => {
    event.stopPropagation();
    downloadReport();
    setIsModalVisible(false);
  };

  const onCancelDownload = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
  };

  return (
    <Row gutter={24}>
      <Col span={8} md={8} xs={24} className="report-filter-col">
        <DownloadFilter
          depots={depots}
          typeReport={typeReport}
          setTypeReport={setTypeReport}
          targetKeys={targetKeys}
          setTargetKeys={setTargetKeys}
          setIsModalVisible={setIsModalVisible}
          setDataForTable={setDataForTable}
          setLoading={setLoading}
          setValues={setValues}
        />
      </Col>
      <Col span={16} md={16} xs={0} className="panel-list-container">
        <Alert message="La información es sólo de muestra" type="info" showIcon />
        <br />
        {typeReport && loading && (
          <div style={{ textAlign: 'center' }}>
            <Spin />{' '}
          </div>
        )}
        {typeReport && !loading && columns.length > 0 && (
          <Table
            rowKey="id"
            dataSource={dataForTable}
            columns={columns}
            size="small"
            scroll={{
              y: '59vh',
              x: 2400, // ToDo: maybe this width change with each report
            }}
            pagination={false}
            loading={loading}
          />
        )}
        {emptyData && <Empty />}
      </Col>
      <ModalConfirm
        okText="Generar"
        onOk={onConfirmDownload}
        onCancel={onCancelDownload}
        title="Se generará un nuevo informe. ¿Deseas continuar?"
        isModalVisible={isModalVisible}
        status="info"
        okButtonProps={{ danger: false }}
      />
    </Row>
  );
}

export default Downloads;
