import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Col, Row, Space, Table, Tag } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import TitleHelp from 'components/common/TitleHelp';
import TourContext from 'components/layout/TourComponent/TourContext';
import { useManualCallbackMutation } from 'features/routingEngine/routingEngineApiSlice';
import {
  useGetRoutingSetsJobsPageMutation,
  useGetRoutingSetsJobsQuery,
} from 'features/routingSets/routingSetsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ColorByStatus, convertDateToDayjs, textWithEllipsis } from 'services/repeated-functions';
import Can from 'utils/Can';

function TableRoutes() {
  const [loading, setLoading] = useState(true);
  const [dataFormated, setDataFormated] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const navigate = useNavigate();
  // custom hooks
  const { data: routingSetsJobData, isLoading, refetch } = useGetRoutingSetsJobsQuery();
  const [getByPage] = useGetRoutingSetsJobsPageMutation();
  const [manualCallback] = useManualCallbackMutation();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { notification } = App.useApp();
  const { addReference } = useContext(TourContext);
  const refShow = useRef(null);
  const refCallback = useRef(null);

  useEffect(() => {
    const setDataForTable = () => {
      const { data, total } = routingSetsJobData;
      setLoading(true);
      setTotalData(total);
      setDataFormated(data);
      refetch();
      setLoading(false);
    };
    if (!isLoading) {
      setDataForTable();
    }
  }, [routingSetsJobData, isLoading, refetch]);

  const showRoute = (routingSetId) => {
    setLoading(true);
    navigate(`/planning/jobs/${routingSetId}`);
  };

  const handleManualCallback = async (jobID) => {
    try {
      const result = await manualCallback(jobID).unwrap();
      if (result) {
        const { type = 'info', message } = result;
        notification[type]({
          message: 'Atención',
          description: message,
        });
      } else {
        console.error('Cannot get data: ', result);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Problemas con callback',
      });
    }
  };

  const columns = [
    {
      title: (
        <TitleHelp
          title="ID"
          helpText="El ID de Rutas corresponde al conjunto de rutas generado en “Lista de rutas”. Úsalo como referencia para un seguimiento preciso."
          placement="right"
        />
      ),
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Space size="middle">
          <Can I="read" a="routes">
            <Button
              type="link"
              className="btn-no-shadow"
              title="Ver rutas"
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/planning/routes/${record.id}`);
              }}
            >
              {record.id}
            </Button>
          </Can>
          <Can not I="read" a="routes">
            {text}
          </Can>
        </Space>
      ),
    },
    {
      title: (
        <TitleHelp
          title="ID Trabajo"
          helpText="El ID Trabajo es una referencia gestionada por el ruteador. Utilízalo para un seguimiento más eficiente."
          placement="right"
        />
      ),
      dataIndex: 'jobID',
      key: 'jobID',
      render: (_text, record) => (
        <Space size="middle">
          <Button
            type="link"
            className="btn-no-shadow"
            title="Ver Trabajo de ruteo"
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/planning/jobs/${record.jobID}/view/${record.id}`);
            }}
          >
            {record.jobID}
          </Button>
        </Space>
      ),
    },
    {
      title: 'Última actualización',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => convertDateToDayjs(text, tzOrganization, 'DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: '¿Callback ejecutado?',
      dataIndex: 'calledCallback',
      key: 'calledCallback',
      render: (_text, record) => {
        return record.jobData.callbackAt ? 'Sí' : 'No';
      },
    },
    {
      title: (
        <TitleHelp
          title="Estado trabajo"
          helpText='Si un trabajo de ruteo presenta un problema, su estado se mostrará en ROJO como FAILURE."'
        />
      ),
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        const color = ColorByStatus(text);
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Cantidad de Demandas',
      dataIndex: 'totalDemands',
      key: 'totalDemands',
    },
    {
      title: 'Creador',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (text) => {
        return <div style={{ maxWidth: '120px' }}>{textWithEllipsis(text)}</div>;
      },
    },
    {
      title: 'Tipo de callback',
      dataIndex: 'callbackType',
      key: 'callbackType',
      render: (_text, record) => {
        return record.jobData.callbackType;
      },
    },
    {
      key: 'action',
      render: (_text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            title="Ver"
            onClick={() => {
              showRoute(record.id);
            }}
            ref={refShow}
          >
            <FontAwesomeIcon icon={['fas', 'eye']} />
          </Button>
          <Can I="update" a="jobs">
            <Button
              type="default"
              title="Ejecutar callback manual"
              disabled={!record.jobID}
              onClick={() => {
                handleManualCallback(record.jobID);
              }}
              ref={refCallback}
            >
              <FontAwesomeIcon icon={['fas', 'phone']} />
            </Button>
          </Can>
        </Space>
      ),
    },
  ];

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/planning',
      label: 'Rutas',
    },
    {
      key: 'routeJobList',
      url: '/planning/jobs',
      label: 'Lista de Trabajos de ruteo',
    },
  ];
  addReference('buttonShowRouteJobsRef', refShow);
  addReference('buttonCallbackRouteJobsRef', refCallback);

  const onChangePage = async (page, pageSize) => {
    const queryString = `?page=${page}&take=${pageSize}`;
    try {
      setLoading(true);
      const { data, total } = await getByPage(queryString).unwrap();
      setTotalData(total);
      setDataFormated(data);
      setLoading(false);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Row gutter={24} style={{ height: '100%' }} className="vehicles-container">
        <Col flex="auto">
          <Table
            rowKey="id"
            columns={columns}
            dataSource={dataFormated}
            loading={loading}
            size="small"
            pagination={{
              showTotal: (total) => `Total: ${total}`,
              total: totalData,
              showSizeChanger: true,
              onChange: onChangePage,
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default TableRoutes;
