import { Col, Row, Steps, theme } from 'antd';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardHeader from '../Dashboard/Header';
import OrganizationsCreationStepsEmail from './Steps/Email';
import OrganizationsCreationStepsGeneral from './Steps/General';
import OrganizationsCreationStepsIntegrations from './Steps/Integrations';
import OrganizationsCreationStepsLoads from './Steps/Loads';
import OrganizationsCreationStepsMobile from './Steps/Mobile';
import OrganizationsCreationStepsModule from './Steps/Module';
import OrganizationsCreationStepsRoutingGeneral from './Steps/RoutingGeneral';
import OrganizationsCreationStepsRoutingParameters from './Steps/RoutingParameters';
import OrganizationsCreationStepsRoutingSettings from './Steps/RoutingSettings';
import OrganizationsCreationStepsSms from './Steps/Sms';

const MAX_STEP = 10;

function OrganizationsCreationSteps() {
  const { id, step } = useParams();
  const { data: organization, isLoading: isLoadingOrgData } = useGetOrganizationQuery(id, {
    skip: id === undefined,
  });
  const [current, setCurrent] = useState(0);
  const [stepsStatus, setStepsStatus] = useState('process');
  const { token } = theme.useToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (step) {
      if (step > MAX_STEP) {
        navigate('/organizations');
      }
      setCurrent(parseInt(step, 10) - 1);
    }
  }, [step, navigate]);

  const onChangeStep = (value) => {
    setCurrent(value);
  };

  const contentStyle = {
    lineHeight: '260px',
    borderRadius: token.borderRadiusLG,
    border: `1px solid ${token.colorBorder}`,
    padding: '24px',
  };

  const isStepDisabled = (stepIndex) => {
    if (!organization || organization?.creationStep < stepIndex) {
      return true;
    }
    return false;
  };

  const steps = [
    {
      title: 'General',
      content: (
        <OrganizationsCreationStepsGeneral
          setStepsStatus={setStepsStatus}
          current={current + 1}
          setCurrent={setCurrent}
          organization={organization}
          isLoading={isLoadingOrgData}
        />
      ),
    },
    {
      title: 'Módulo',
      content: (
        <OrganizationsCreationStepsModule
          organizationId={organization?.id}
          isLoading={isLoadingOrgData}
          current={current + 1}
        />
      ),
      disabled: isStepDisabled(2),
    },
    {
      title: 'Medidas',
      content: (
        <OrganizationsCreationStepsLoads
          setStepsStatus={setStepsStatus}
          current={current + 1}
          setCurrent={setCurrent}
          organization={organization}
          isLoading={isLoadingOrgData}
        />
      ),
      disabled: isStepDisabled(3),
    },
    {
      title: 'Mobile',
      content: (
        <OrganizationsCreationStepsMobile
          organizationId={organization?.id}
          isLoading={isLoadingOrgData}
          current={current + 1}
        />
      ),
      disabled: isStepDisabled(4),
    },
    {
      title: 'Parámetros de ruteo',
      content: (
        <OrganizationsCreationStepsRoutingParameters
          organizationId={organization?.id}
          isLoading={isLoadingOrgData}
          current={current + 1}
        />
      ),
      disabled: isStepDisabled(5),
    },
    {
      title: 'Ruteo General',
      content: (
        <OrganizationsCreationStepsRoutingGeneral
          organizationId={organization?.id}
          isLoading={isLoadingOrgData}
          current={current + 1}
        />
      ),
      disabled: isStepDisabled(6),
    },
    {
      title: 'Restricciones',
      content: (
        <OrganizationsCreationStepsRoutingSettings
          organizationId={organization?.id}
          isLoading={isLoadingOrgData}
          current={current + 1}
        />
      ),
      disabled: isStepDisabled(7),
    },
    {
      title: 'Correo',
      content: (
        <OrganizationsCreationStepsEmail
          organizationId={organization?.id}
          isLoading={isLoadingOrgData}
          current={current + 1}
        />
      ),
      disabled: isStepDisabled(8),
    },
    {
      title: 'Integraciones',
      content: (
        <OrganizationsCreationStepsIntegrations
          organizationId={organization?.id}
          isLoading={isLoadingOrgData}
          current={current + 1}
        />
      ),
      disabled: isStepDisabled(9),
    },
    {
      title: 'SMS',
      content: (
        <OrganizationsCreationStepsSms
          organizationId={organization?.id}
          isLoading={isLoadingOrgData}
          current={current + 1}
        />
      ),
      disabled: isStepDisabled(10),
    },
  ];

  return (
    <>
      <DashboardHeader showGoToHomeButton />
      <Row>
        <Col xs={8} sm={7} md={6} lg={4} xl={3}>
          <Steps
            current={current}
            onChange={onChangeStep}
            direction="vertical"
            items={steps}
            status={stepsStatus}
          />
        </Col>
        <Col xs="auto" sm={17} md={18} lg={20} xl={21} style={contentStyle}>
          {steps[current]?.content}
        </Col>
      </Row>
    </>
  );
}

export default OrganizationsCreationSteps;
