import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Avatar, Button, Col, DatePicker, Drawer, Form, Row, Select } from 'antd';
import ModalConfirm from 'components/common/ModalConfirm';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useRecalcutateTimesMutation } from 'features/routes/routesApiSlice';
import { every, find, isEmpty, last, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { calculateItemId, nameGroupByRoutes } from 'services/repeated-functions';
import { RoutePropTypes } from 'types';

dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const { Option } = Select;

function AddRestDrawer(props) {
  const {
    setDrawerOpen,
    drawerOpen,
    routes,
    setRoutes,
    setAllRoutes,
    allRoutes,
    setIsRoutingSetUnchanged,
  } = props;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formValues, setFormValues] = useState();
  const [routesWithLimitTime, setRoutesWithLimitTime] = useState();
  const [touchedRoutes, setTouchedRoutes] = useState([]);
  const [recalcutateTimes] = useRecalcutateTimesMutation();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'routes.editor.drawer' };

  const initialValues = {};

  useEffect(() => {
    const getTimes = async () => {
      const demandGroupRoutes = nameGroupByRoutes(touchedRoutes);
      const newRoutesRecalc1 = await Promise.all(
        touchedRoutes?.map(async (tRoute) => {
          const tRouteFill = { ...tRoute, demandGroupRoutes };
          const { data: dataResponse } = await recalcutateTimes(tRouteFill);
          return {
            ...tRoute,
            ...dataResponse,
          };
        })
      );
      if (touchedRoutes.length > 0) {
        setRoutes(newRoutesRecalc1);
        // modify all universe of routes to apply filters
        const allroutesAndChanged = allRoutes.map((thisRoute) => {
          const foundedRoute = newRoutesRecalc1.find(({ id }) => thisRoute.id === id);
          if (foundedRoute) {
            return foundedRoute;
          }
          return thisRoute;
        });
        setAllRoutes(allroutesAndChanged);
        setIsRoutingSetUnchanged(false);
      }
    };
    getTimes();
    //! skip pass allRoutes to skip recall
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalcutateTimes, setAllRoutes, setIsRoutingSetUnchanged, setRoutes, touchedRoutes]);

  const buildRoutes = (newItem) => {
    const newItemObj = { ...newItem };
    const { routeId } = newItemObj;
    const destinationRouteObject = find(routes, { id: routeId });
    const { items } = destinationRouteObject;
    const itemsObject = items.map((item) => {
      const itemObj = { ...item };
      const itemDepartsAt = dayjs(itemObj.departsAt);
      const newItemArrivesAt = dayjs(newItemObj.arrivesAt);
      if (itemObj.type !== 'RETURN_TO_DEPOT' && itemDepartsAt.isSameOrBefore(newItemArrivesAt)) {
        newItemObj.itemOrder = itemObj.itemOrder + 1;
        newItemObj.travelTimeToPrevious = newItemArrivesAt.diff(itemDepartsAt, 'seconds');
      } else if (itemObj.type !== 'DEPOT') {
        itemObj.itemOrder += 1;
      }
      return itemObj;
    });
    newItemObj.id = calculateItemId(routes);
    const newDestinationItems = itemsObject.concat(newItemObj);
    const itemsOrdered = orderBy(newDestinationItems, ['itemOrder'], ['asc']);
    return {
      ...destinationRouteObject,
      items: itemsOrdered,
    };
  };

  const validateRest = (values) => {
    const { routeIds, rangeTime } = values;
    const initialRangeTime = dayjs(rangeTime[0], 'UTC');
    const endRangeTime = dayjs(rangeTime[1], 'UTC');
    const routesFound = routes.filter((route) => routeIds.includes(route.id));
    return routesFound.map((route) => {
      const { items } = route;
      const itemsFiltered = items.filter((item) => {
        const itemArrivesAt = dayjs(item.arrivesAt);
        const itemDepartsAt = dayjs(item.departsAt);
        return (
          item.type !== 'RETURN_TO_DEPOT' &&
          (itemArrivesAt.isBetween(initialRangeTime, endRangeTime) ||
            itemDepartsAt.isBetween(initialRangeTime, endRangeTime))
        );
      });
      if (itemsFiltered.length > 0) {
        return {
          routeId: route.id,
          items: itemsFiltered,
        };
      }
      return {};
    });
  };

  const onClose = (event) => {
    event?.stopPropagation();
    form.resetFields();
    setDrawerOpen(false);
    setIsModalVisible(false);
  };

  const footer = (
    <div className="footer-drawer-buttons">
      <Button type="default" onClick={onClose}>
        Cancelar
      </Button>
      <Button type="primary" style={{ marginLeft: '1rem' }} onClick={() => form.submit()}>
        Agregar
      </Button>
    </div>
  );

  // ToDo: check if this do something
  const onConfirmAction = (event) => {
    event.stopPropagation();
    const { routeIds, rangeTime } = formValues;
    const initialRangeTime = dayjs(rangeTime[0], 'UTC');
    const endRangeTime = dayjs(rangeTime[1], 'UTC');
    const travelTime = endRangeTime.diff(initialRangeTime, 'seconds');
    try {
      const resultRoutes = routeIds.map((routeId) => {
        const newItem = {
          routeId,
          id: -1,
          type: 'REST',
          arrivesAt: initialRangeTime,
          departsAt: endRangeTime,
          serviceStartsAt: initialRangeTime,
          action: 'D',
          nid: 'REST',
          name: 'Colación',
          loads: [],
          itemOrder: 999999,
          restrictions: [],
          events: [],
          contactPhones: [],
          travelTime,
          travelTimeToPrevious: 0,
        };
        const routeWithLimitTimeFound = routesWithLimitTime.find(
          (routeWithLimitTime) => routeWithLimitTime.routeId === routeId
        );
        if (routeWithLimitTimeFound) {
          const { items } = routeWithLimitTimeFound;
          const itemWithTimeLimit = last(items);
          const arrivesAt = dayjs(itemWithTimeLimit.departsAt);
          const departsAt = arrivesAt.clone().add(travelTime, 'second');
          Object.assign(newItem, { arrivesAt, departsAt, serviceStartsAt: arrivesAt });
        }
        const buildedRoutes = buildRoutes(newItem);
        return buildedRoutes;
      });
      // use only modified routes not all
      const routesToModify = orderBy(resultRoutes, ['routeOrder'], ['asc']);
      setTouchedRoutes(routesToModify);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  const onCancelAction = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
  };

  const okText = 'Aceptar';
  const titleModal =
    'Existe topes con algunas paradas, la colación se agregará al finalizar dichas paradas. ¿Deseas continuar?';
  const colorButtonProps = { danger: false };
  const modalStatus = 'warning';

  const onFinish = async (values) => {
    setFormValues(values);
    const { routeIds, rangeTime } = values;
    const initialRangeTime = dayjs(rangeTime[0], 'UTC');
    const endRangeTime = dayjs(rangeTime[1], 'UTC');
    const travelTime = endRangeTime.diff(initialRangeTime, 'seconds');

    try {
      const validate = validateRest(values);
      if (every(validate, (value) => isEmpty(value))) {
        const resultRoutes = routeIds.map((routeId) => {
          const newItem = {
            routeId,
            id: -1,
            type: 'REST',
            arrivesAt: initialRangeTime,
            departsAt: endRangeTime,
            serviceStartsAt: initialRangeTime,
            action: 'D',
            nid: 'REST',
            name: 'Colación',
            loads: [],
            itemOrder: 999999,
            restrictions: [],
            events: [],
            contactPhones: [],
            travelTime,
          };
          const buildedRoutes = buildRoutes(newItem);
          return buildedRoutes;
        });
        // use only modified routes not all
        const routesToModify = orderBy(resultRoutes, ['routeOrder'], ['asc']);
        setTouchedRoutes(routesToModify);
        onClose();
      } else {
        setRoutesWithLimitTime(validate);
        setIsModalVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = () => {
    console.log('Formulario con errores');
  };

  return (
    <Drawer
      title="Agregar colación"
      footer={footer}
      onClose={(event) => onClose(event)}
      width="50%"
      open={drawerOpen}
      destroyOnClose
      maskClosable={false}
      closable={false}
    >
      <Form
        form={form}
        validateMessages={{ required: 'Este campo es obligatorio' }}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        <div
          style={{
            height: '77vh',
          }}
        >
          <Row gutter={32}>
            <Col span={24}>
              <div
                style={{
                  height: '80vh',
                  overflow: 'auto',
                  paddingRight: '1.5rem',
                }}
              >
                <Row justify="center" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <Col>
                    <Avatar
                      style={{ backgroundColor: '#F3F3F3' }}
                      size={64}
                      icon={<FontAwesomeIcon icon="fa-solid fa-burger" color="black" />}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Alert
                      message="Si hay tope de horario se cambiará la hora de inicio considerando la parada más cercana. Los tiempos de las paradas se recalcularán."
                      showIcon
                      type="warning"
                      style={{ marginBottom: '30px' }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="rangeTime"
                      label={
                        <TitleHelp
                          title="Hora (HH:MM)"
                          helpText="Inicio y término planificado de la ventana horaria de tipo"
                        />
                      }
                      rules={[{ required: true }]}
                    >
                      <DatePicker.RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder={['Inicio', 'Fin']}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Rutas"
                      name="routeIds"
                      style={{ marginBottom: '1rem' }}
                      rules={[
                        { required: true },
                        () => ({
                          validator(_, routeNumber) {
                            const routeMatch = routes.find((route) =>
                              routeNumber.includes(route.id)
                            );
                            if (routeMatch && !routeMatch.editable) {
                              return Promise.reject(
                                new Error(i18n.t('errors.notEditable', scopeI18n))
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Select placeholder="Seleccionar" allowClear mode="multiple">
                        {routes.map((option) => {
                          const { id, routingSetId, routeOrder } = option;
                          return (
                            <Option key={id} value={id}>
                              {`Ruta ${routingSetId}-${routeOrder}`}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <ModalConfirm
        onOk={onConfirmAction}
        onCancel={onCancelAction}
        title={titleModal}
        isModalVisible={isModalVisible}
        okButtonProps={colorButtonProps}
        okText={okText}
        status={modalStatus}
      />
    </Drawer>
  );
}

AddRestDrawer.defaultProps = {
  setDrawerOpen: () => {},
  drawerOpen: false,
  routes: [],
  allRoutes: [],
  setRoutes: () => {},
  setAllRoutes: () => {},
  setIsRoutingSetUnchanged: () => {},
};

AddRestDrawer.propTypes = {
  setDrawerOpen: PropTypes.func,
  drawerOpen: PropTypes.bool,
  routes: PropTypes.arrayOf(RoutePropTypes),
  setRoutes: PropTypes.func,
  setAllRoutes: PropTypes.func,
  allRoutes: PropTypes.arrayOf(RoutePropTypes),
  setIsRoutingSetUnchanged: PropTypes.func,
};

export default AddRestDrawer;
