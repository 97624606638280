import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, List } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { DefaultPasswordErrors } from './password-errors';
import './style.css';

function StrengthMeter(props) {
  const { passwordErrors, showDivider } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.changePassword.strength' };
  const data = Object.keys(DefaultPasswordErrors).map((error) => {
    return { active: passwordErrors[error], errorLabel: i18n.t(`${error}`, scopeI18n) };
  });

  return (
    <>
      {showDivider && <Divider />}
      <div>{i18n.t('mustHave', scopeI18n)}:</div>
      <List
        size="small"
        dataSource={data}
        renderItem={(item) => (
          <List.Item className="strength-meter">
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className={item.active ? 'error-active' : 'error-inactive'}
            />{' '}
            {item.errorLabel}
          </List.Item>
        )}
      />
    </>
  );
}

StrengthMeter.propTypes = {
  passwordErrors: PropTypes.shape({
    length: PropTypes.bool,
    uppercaseLetter: PropTypes.bool,
    lowercaseLetter: PropTypes.bool,
    digit: PropTypes.bool,
    specialCharacter: PropTypes.bool,
  }).isRequired,
  showDivider: PropTypes.bool,
};
StrengthMeter.defaultProps = {
  showDivider: true,
};

export default StrengthMeter;
