import { Skeleton } from 'antd';
import { useGetRouteQuery } from 'features/routes/routesApiSlice';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VehiclePositionPropTypes } from '../../Vehicles/vehicles.propTypes';
import RouteDetailContent from './RouteDetailContent';

function FollowingRouteDetail(props) {
  const { isConnected, vehiclesPositionEvents } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [route, setRoute] = useState();
  const [loading, setLoading] = useState(true);

  const {
    data: routesData,
    isLoading,
    refetch,
  } = useGetRouteQuery(id, {
    skip: id === undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setRoute(routesData);
        setLoading(isLoading);
      } catch (error) {
        console.error(error);
        navigate('/following');
      }
    };
    refetch();
    fetchData();
  }, [navigate, id, isLoading, routesData, refetch]);

  return (
    <Skeleton active loading={loading}>
      <RouteDetailContent
        route={route}
        isConnected={isConnected}
        vehiclesPositionEvents={vehiclesPositionEvents}
      />
    </Skeleton>
  );
}

FollowingRouteDetail.defaultProps = {
  isConnected: false,
  vehiclesPositionEvents: [],
};

FollowingRouteDetail.propTypes = {
  isConnected: PropTypes.bool,
  vehiclesPositionEvents: PropTypes.arrayOf(VehiclePositionPropTypes),
};

export default FollowingRouteDetail;
