import { createContext } from 'react';

// obj with references
const fns = {};
const addReference = (name, fn) => {
  fns[name] = fn;
};
const getReference = (name) => {
  return fns[name];
};
const getAllReferences = () => fns;

const TourContext = createContext({
  addReference,
  getReference,
  getAllReferences,
  tourOpen: true,
  setTourOpen: () => {},
});

export default TourContext;
