import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  refreshToken: null,
  userSub: null,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const {
        user,
        access_token: accessToken,
        refresh_token: refreshToken,
        user_sub: userSub,
        notificationToken,
      } = action.payload;
      return {
        ...state,
        user,
        token: accessToken,
        refreshToken,
        userSub,
        notificationToken,
      };
    },
    logOut: () => initialState,
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUsername = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
