import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';

export const configFirebase = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

const firebaseApp = initializeApp(configFirebase);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log('Firebase is not supported in this browser');
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
})();

export const getOrRegisterServiceWorker = async () => {
  if ('serviceWorker' in navigator && typeof window.navigator.serviceWorker !== 'undefined') {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  // ToDo: review this line if there is any issues with notifications
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return getOrRegisterServiceWorker()
        .then((serviceWorkerRegistration) => {
          return Promise.resolve(
            getToken(messagingResolve, {
              vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
              serviceWorkerRegistration,
            })
          );
        })
        .catch((error) => {
          console.error('error: ', error);
        });
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
  return null;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const onMessageListenerNotify = (
  notificationApi,
  onClickNotification,
  callbackFunction = () => {}
) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      const { title, body, data } = payload.data;
      // do somethings in each case
      notificationApi.success({
        message: title,
        description: body,
        onClick: () => {
          onClickNotification(data);
        },
      });
      callbackFunction(data);
      resolve(payload);
    });
  });
