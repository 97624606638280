/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Form, Input, Select, Table, Tooltip, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.css';

const { Text } = Typography;

const EditableContext = React.createContext(null);

function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}

function EditableCell({
  title,
  editable,
  typeEdit,
  optionSelect,
  children,
  dataIndex,
  record,
  handleSave,
  afterSaveCall,
  fieldInObject,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const { setFieldsValue, validateFields } = form;
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  useEffect(() => {
    if (editing && typeEdit === 'text') {
      inputRef.current.focus();
    }
  }, [editing, typeEdit]);

  const getValueFromRecord = (recordObj, attrObj) => {
    return typeof recordObj === 'object' && recordObj !== null ? recordObj[attrObj] : recordObj;
  };

  const toggleEdit = (event) => {
    if (event) {
      // prevent load of map when edit
      event.stopPropagation();
    }
    setEditing(!editing);
    setFieldsValue({
      [dataIndex]: getValueFromRecord(record[dataIndex], fieldInObject),
    });
  };

  const save = async () => {
    try {
      const values = await validateFields();
      const row = await handleSave(record.id, values);
      toggleEdit();
      afterSaveCall(row);
    } catch (errInfo) {
      // console.log('Save failed:', errInfo);
      message.error(i18n.t('errors.editableCell'));
      setTimeout(toggleEdit, 3000);
    }
  };

  let childNode = children;
  const editableIcon = (
    <FontAwesomeIcon
      icon={['fas', 'pen-to-square']}
      style={{
        color: 'rgb(13, 99, 207)',
      }}
    />
  );

  // ToDo: handle for other types of fields
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: i18n.t('errors.elementCannotBeBlank', { element: title }),
          },
        ]}
      >
        {typeEdit === 'text' ? (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        ) : (
          <Select
            onClick={(event) => event.stopPropagation()}
            onChange={save}
            onBlur={save}
            placeholder={i18n.t('commons.select')}
            options={optionSelect}
          />
        )}
      </Form.Item>
    ) : (
      <Tooltip title={i18n.t('commons.pressToEdit')}>
        <Text onClick={toggleEdit}>
          {children[1] == null ? '-- ' : `${children[1]} `}
          {editableIcon}
        </Text>
      </Tooltip>
    );
  }

  return <td {...restProps}>{childNode}</td>;
}

function EditableTable(props) {
  const { dataSource, columns, toggleMap, loading, handleSave, extraParams, paginationData } =
    props;
  const { totalData, onChangePage, actualPage } = paginationData;
  const pagination = totalData
    ? {
        showTotal: (total) => `Total: ${total}`,
        total: totalData,
        showSizeChanger: true,
        onChange: onChangePage,
        current: actualPage,
      }
    : undefined;
  const [dataset, setDataset] = useState(dataSource);

  useEffect(() => {
    setDataset(dataSource);
  }, [dataSource]);

  const updateDataSet = (row) => {
    const newData = [...dataset];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataset(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columnsFormated = columns.map((col) => {
    if (!col?.editProps?.editable) {
      return col;
    }

    const { editable, typeEdit, optionSelect, fieldInObject } = col.editProps;
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable,
        typeEdit,
        optionSelect,
        fieldInObject,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        afterSaveCall: updateDataSet,
      }),
    };
  });

  const setRowClass = (record) => {
    const selectedClass = 'selected-row';
    return `editable-row ${record.id === extraParams ? selectedClass : ''}`;
  };
  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        components={components}
        rowClassName={(record) => setRowClass(record)}
        bordered
        dataSource={dataset}
        columns={columnsFormated}
        loading={loading}
        onRow={(record) => {
          return {
            onClick: () => {
              toggleMap(record);
            },
          };
        }}
        pagination={pagination}
      />
    </div>
  );
}

EditableTable.defaultProps = {
  dataSource: [],
  columns: [],
  toggleMap: () => {
    return false;
  },
  loading: false,
  handleSave: () => {},
  extraParams: null,
  paginationData: {
    totalData: undefined,
    onChangePage: undefined,
    actualPage: 1,
  },
};

EditableTable.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      deletedAt: PropTypes.string,
      depot: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      id: PropTypes.number,
      name: PropTypes.string,
      totalDemands: PropTypes.number,
      totalLoads: PropTypes.arrayOf(PropTypes.number),
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      title: PropTypes.string,
      render: PropTypes.func,
    })
  ),
  toggleMap: PropTypes.func,
  loading: PropTypes.bool,
  handleSave: PropTypes.func,
  extraParams: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paginationData: PropTypes.shape({
    totalData: PropTypes.number,
    onChangePage: PropTypes.func,
    actualPage: PropTypes.number,
  }),
};

EditableRow.defaultProps = {
  index: '',
};

EditableRow.propTypes = {
  index: PropTypes.string,
};

EditableCell.defaultProps = {
  title: '',
  editable: false,
  typeEdit: 'text',
  optionSelect: [],
  children: {},
  dataIndex: '',
  record: {
    id: PropTypes.number,
  },
  fieldInObject: 'id',
  handleSave: () => {},
  afterSaveCall: () => {},
};

EditableCell.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.bool,
  typeEdit: PropTypes.string,
  optionSelect: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  dataIndex: PropTypes.string,
  record: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  fieldInObject: PropTypes.string,
  handleSave: PropTypes.func,
  afterSaveCall: PropTypes.func,
};

export default EditableTable;
