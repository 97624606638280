export const DefaultPasswordErrors = {
  length: false,
  uppercaseLetter: false,
  lowercaseLetter: false,
  digit: false,
  specialCharacter: false,
};

export const DefaultPasswordLabels = {
  length: 'Mínimo 8 caracteres',
  uppercaseLetter: 'Mínimo 1 Mayúscula',
  lowercaseLetter: 'Mínimo una mínuscula',
  digit: 'Mínimo 1 dígito',
  specialCharacter: `Mínimo 1 símbolo (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | ~ \` + =)`,
};

export default DefaultPasswordErrors;
