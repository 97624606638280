import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Col, Dropdown, Row, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { logOut } from 'features/auth/authSlice';
import { logOutCurrentUser, selectCurrentUser } from 'features/users/userSlice';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ChangePasswordDrawer from './ChangePasswordDrawer';
import MyProfileDrawer from './MyProfileDrawer';
import './style.css';

const { Text } = Typography;

function ProfileContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [myProfileVisible, setMyProfileVisible] = useState(false);
  const { photo: avatarUrl, fullName } = useSelector(selectCurrentUser);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'profile' };

  const handleLogout = async () => {
    dispatch(logOut());
    dispatch(logOutCurrentUser());
    navigate('/');
  };

  const handleActionMenu = ({ key }) => {
    if (key === 'logout') {
      handleLogout();
    } else if (key === 'changePassword') {
      setChangePasswordVisible(!changePasswordVisible);
    } else if (key === 'myProfile') {
      setMyProfileVisible(!myProfileVisible);
    }
  };

  const items = [
    {
      key: 'myProfile',
      label: i18n.t('myProfile', scopeI18n),
      icon: <FontAwesomeIcon icon={['fa', 'user']} />,
    },
    {
      key: 'changePassword',
      label: i18n.t('changePassword', scopeI18n),
      icon: <FontAwesomeIcon icon={['fa', 'key']} />,
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: i18n.t('logOut', scopeI18n),
      icon: <FontAwesomeIcon icon={['fa', 'right-from-bracket']} />,
    },
  ];

  return (
    <>
      <Dropdown
        menu={{ items, onClick: handleActionMenu }}
        style={{ width: '235px' }}
        trigger="click"
      >
        <Row>
          <Col md={0}>
            <Button size="large" title={fullName}>
              <Avatar
                size={24}
                icon={<FontAwesomeIcon icon={['fa', 'user']} />}
                src={avatarUrl}
                alt={i18n.t('altAvatar', scopeI18n)}
              />
              <FontAwesomeIcon icon={['fa', 'chevron-down']} className="chevron-icon" />
            </Button>
          </Col>
          <Col xs={0} md={24}>
            <Button size="large" title={fullName} className="profile-button">
              <Avatar
                size={31}
                icon={<FontAwesomeIcon icon={['fa', 'user']} />}
                src={avatarUrl}
                alt={i18n.t('altAvatar', scopeI18n)}
              />
              <Text ellipsis className="name-text">
                {fullName}
              </Text>
              <FontAwesomeIcon icon={['fa', 'chevron-down']} className="chevron-icon" />
            </Button>
          </Col>
        </Row>
      </Dropdown>
      <ChangePasswordDrawer
        isVisible={changePasswordVisible}
        setVisible={setChangePasswordVisible}
      />
      <MyProfileDrawer isVisible={myProfileVisible} setVisible={setMyProfileVisible} />
    </>
  );
}
export default ProfileContainer;
