import { Col, Row, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import CustomAttributesPropTypes from '../customAttributes.propTypes';

const { Text } = Typography;

function CustomAttributesShowField(props) {
  const { customAttribute, offset } = props;
  const { id, name, dataType, customValue = {} } = customAttribute;
  const { value } = customValue;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'commons' };
  let printValue = value;
  if (dataType === 'boolean') {
    printValue = i18n.t(value === 'true' ? 'yes' : 'no', scopeI18n);
  }
  return (
    <Row key={`rowCa${id}`} gutter={24} style={{ marginTop: '.5rem' }}>
      <Col span={8} md={7} offset={offset}>
        <Text key={`Text${id}`} strong>
          {name}:
        </Text>
      </Col>
      <Col key={`Val${id}`} span={16} md={15}>
        {printValue || '-'}
      </Col>
    </Row>
  );
}

CustomAttributesShowField.propTypes = {
  customAttribute: CustomAttributesPropTypes,
  offset: PropTypes.number,
};

CustomAttributesShowField.defaultProps = {
  customAttribute: {
    id: 0,
    name: 'no-name',
    dataType: 'no-name',
    customValue: {
      value: undefined,
    },
  },
  offset: 0,
};
export default CustomAttributesShowField;
