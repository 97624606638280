import { Alert, Drawer, Form, Space, Typography } from 'antd';
import FooterDrawer from 'components/common/FooterDrawer';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetCustomAttributeVehiclesQuery } from 'features/vehicles/vehiclesApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { getTitleToDrawer } from 'services/repeated-functions';
import { loadsFromOrgType } from 'types';
import VehicleForm from '../Form';
import VehicleShow from '../Show';
import { VehiclePropTypes } from '../vehicles.propTypes';

const { Text } = Typography;

function VehicleDrawer(props) {
  const {
    drawerVisible,
    setDrawerVehicle,
    typeDrawer,
    vehicle,
    loadsFromOrg,
    vehiclesData,
    setTriggerClean,
  } = props;
  const [caeFields, setCaeFields] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'vehicles' };
  // custom hooks
  const { data: caeVehiclesData, isLoading } = useGetCustomAttributeVehiclesQuery();
  const [form] = Form.useForm();

  const onClose = (event) => {
    event?.stopPropagation();
    setDrawerVehicle(false);
  };

  useEffect(() => {
    setCaeFields(caeVehiclesData);
  }, [caeVehiclesData, isLoading]);

  const title = (
    <Text strong>{getTitleToDrawer(typeDrawer, i18n.t('vehicle', scopeI18n), vehicle.nid)}</Text>
  );

  const footer = (
    <FooterDrawer
      onClose={onClose}
      typeDrawer={typeDrawer}
      form={form}
      entity={vehicle}
      buttonLoading={buttonLoading}
    />
  );

  return (
    <Drawer
      title={title}
      width="50%"
      onClose={(event) => onClose(event)}
      open={drawerVisible}
      destroyOnClose
      style={{ body: { paddingBottom: 80 } }}
      maskClosable={false}
      footer={footer}
      closable={false}
    >
      {['create', 'edit'].includes(typeDrawer) ? (
        <Space direction="vertical">
          {loadsFromOrg.length === 0 && (
            <Alert message={i18n.t('alerts.loads', scopeI18n)} type="warning" />
          )}
          <VehicleForm
            onCloseDrawer={onClose}
            vehicle={vehicle}
            loadsFromOrg={loadsFromOrg}
            caeFields={caeFields}
            form={form}
            setButtonLoading={setButtonLoading}
            drawerVisible={drawerVisible}
            vehiclesData={vehiclesData}
            setTriggerClean={setTriggerClean}
          />
        </Space>
      ) : (
        <VehicleShow onCloseDrawer={onClose} vehicle={vehicle} loadsFromOrg={loadsFromOrg} />
      )}
    </Drawer>
  );
}

VehicleDrawer.propTypes = {
  drawerVisible: PropTypes.bool,
  setDrawerVehicle: PropTypes.func,
  typeDrawer: PropTypes.string,
  vehicle: VehiclePropTypes,
  loadsFromOrg: loadsFromOrgType,
  vehiclesData: PropTypes.arrayOf(VehiclePropTypes),
  setTriggerClean: PropTypes.func,
};

VehicleDrawer.defaultProps = {
  drawerVisible: false,
  typeDrawer: 'create',
  setDrawerVehicle: () => {},
  vehicle: {},
  loadsFromOrg: [],
  vehiclesData: [],
  setTriggerClean: () => {},
};

export default VehicleDrawer;
