import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Card, Col, Descriptions, Popover, Row, Space, Tag, Typography } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import TourContext from 'components/layout/TourComponent/TourContext';
import { useManualCallbackMutation } from 'features/routingEngine/routingEngineApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ColorByStatus,
  GetUrl,
  convertDateToDayjs,
  getAllIndexFromArray,
} from 'services/repeated-functions';
import Can from 'utils/Can';
import CaslContext from 'utils/caslContext';

const { Text } = Typography;
const linksRe = [
  { name: 'Ver', url: 'view' },
  { name: 'Estado', url: 'status' },
  { name: 'Resultado', url: 'result' },
  { name: 'Abortar', url: 'abort' },
];

function RouteJobContent(props) {
  const { routeJob } = props;
  const {
    id,
    organizationName,
    status,
    createdBy,
    jobID,
    jobData = {},
    createdAt,
    updatedAt,
    totalDemands,
  } = routeJob;
  // job data will be the name of json field to save other stuff
  const { jobMessages, callbackAt } = jobData;

  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const fullDateFormat = 'DD-MM-YYYY HH:mm:ss';

  const callbackAtDaysjs = convertDateToDayjs(callbackAt, tzOrganization);
  const createdADayjs = convertDateToDayjs(createdAt, tzOrganization);
  const updatedAtDayjs = convertDateToDayjs(updatedAt, tzOrganization);
  const diff = callbackAt ? callbackAtDaysjs.diff(createdADayjs) : 0;
  const timePerDemand = diff / 1000 / totalDemands;
  // custom hooks
  const [manualCallback] = useManualCallbackMutation();

  const urlCsv = `routing-engine/routeJob/${jobID}/link_costs.csv`;
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const ability = useContext(CaslContext);
  const allRoutesFromArray = getAllIndexFromArray(['planning', 'routes', 'jobs'], ability);
  const { jobs: jobIndexPath } = allRoutesFromArray;
  const { index: jobsIndex, path: jobsPath } = jobIndexPath;
  const jobsKey = `planning${jobsIndex ? '' : `/${jobsPath}`}`;
  const { addReference } = useContext(TourContext);
  const refCallback = useRef(null);

  const handleManualCallback = async () => {
    try {
      const result = await manualCallback(jobID).unwrap();
      if (result) {
        const { type = 'info', message } = result;
        notification[type]({
          message: 'Atención',
          description: message,
        });
      } else {
        console.error('Cannot get data: ', result);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Problemas con callback',
      });
    }
  };

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/planning',
      label: 'Rutas',
    },
    {
      key: 'routeJobList',
      url: `/${jobsKey}`,
      label: 'Lista de Trabajos de ruteo',
    },
    {
      key: 'routeJobDetail',
      url: `/planning/jobs/${id}`,
      label: 'Trabajo de ruteo',
    },
  ];

  const titleJob = (
    <Space>
      Trabajo de Ruteo #{id} <Tag color={ColorByStatus(status)}>{status}</Tag>
    </Space>
  );
  addReference('buttonCallback2RouteJobsRef', refCallback);

  const helpStatus = [
    'En “Ver”, confirma lo enviado al ruteador en formato JSON.',
    'En “Resultado”, visualiza lo entregado de vuelta por el ruteador en formato JSON.',
    'Con “Abortar”, puedes cancelar el trabajo de ruteo.',
  ];
  const renderInformation = (
    <Card
      style={{ padding: 2, boxShadow: 'unset' }}
      bodyStyle={{ padding: '.5rem' }}
      bordered={false}
    >
      Tipos de ruteo:
      <ul>
        {helpStatus.map((text, index) => {
          const key = `help-${index}`;
          return <li key={key}>{text}</li>;
        })}
      </ul>
    </Card>
  );
  const titleInfoRoute = (
    <Space>
      <Text>Proceso</Text>
      <Popover content={renderInformation} placement="right">
        <FontAwesomeIcon icon="fa-solid fa-circle-question" color="#999999" />
      </Popover>
    </Space>
  );

  return (
    <Row gutter={24} style={{ height: '100%' }}>
      <Col flex="auto">
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        <Descriptions title={titleJob} column={2} bordered>
          <Descriptions.Item label="Organización">{organizationName}</Descriptions.Item>
          <Descriptions.Item label="Creador">{createdBy}</Descriptions.Item>
          <Descriptions.Item label="ID Trabajo">{jobID}</Descriptions.Item>
          <Descriptions.Item label="Mensaje del trabajo">{jobMessages || '-'}</Descriptions.Item>
          <Descriptions.Item label={titleInfoRoute} span={2}>
            {linksRe.map(({ name, url }) => (
              <Button
                type="dashed"
                className="route-button"
                title={`${name}`}
                key={name}
                disabled={!jobID}
                onClick={() => {
                  navigate(`/planning/jobs/${jobID}/${url}/${id}`);
                }}
              >
                {name}
              </Button>
            ))}
            <Button
              type="dashed"
              className="route-button"
              title="Descargar Link Costs (CSV)"
              key="Link Costs (CSV)"
              disabled={!jobID}
              href={GetUrl(urlCsv)}
            >
              <FontAwesomeIcon icon={['fas', 'download']} className="icon-btn-download" />
              Link Costs (CSV)
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="Set de Rutas">
            <Space size={50} key={`sp${id}`}>
              <Can I="read" a="routes">
                <Button
                  type="link"
                  className="btn-no-shadow"
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`/planning/routes/${id}`);
                  }}
                >
                  {id}
                </Button>
              </Can>
              <Can not I="read" a="routes">
                {id}
              </Can>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad de demandas">{totalDemands}</Descriptions.Item>
          <Descriptions.Item label="Tiempo por demanda">{`${timePerDemand.toFixed(
            4
          )} segundos/demanda`}</Descriptions.Item>
          <Descriptions.Item label="Callback at">
            {callbackAt ? callbackAtDaysjs.format(fullDateFormat) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Creación">
            {createdADayjs.format(fullDateFormat)}
          </Descriptions.Item>
          <Descriptions.Item label="Actualización">
            {updatedAtDayjs.format(fullDateFormat)}
          </Descriptions.Item>
          <Descriptions.Item />
          <Descriptions.Item>
            <Space className="float-right">
              <Can I="update" a="jobs">
                <Button
                  type="primary"
                  className="route-button"
                  title="Ejecutar callback manual"
                  disabled={!jobID}
                  onClick={() => {
                    handleManualCallback();
                  }}
                  ref={refCallback}
                >
                  <FontAwesomeIcon icon={['fas', 'phone']} className="icon-btn-callback" />
                  Ejecutar callback
                </Button>
              </Can>

              <Button
                className="route-button"
                onClick={() => {
                  navigate(`/${jobsKey}`);
                }}
              >
                Volver
              </Button>
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

RouteJobContent.defaultProps = {
  routeJob: {},
};

RouteJobContent.propTypes = {
  routeJob: PropTypes.shape({
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    id: PropTypes.number,
    jobData: PropTypes.shape({
      jobMessages: PropTypes.arrayOf(PropTypes.string),
      callbackAt: PropTypes.string,
      callbackType: PropTypes.string,
      totalDemands: PropTypes.number,
      timePerDemand: PropTypes.number,
    }),
    jobID: PropTypes.string,
    organizationId: PropTypes.number,
    status: PropTypes.string,
    totalRoutes: PropTypes.number,
    updatedAt: PropTypes.string,
    organizationName: PropTypes.string,
    totalDemands: PropTypes.number,
  }),
};

export default RouteJobContent;
