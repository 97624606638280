import { Button, Col, Drawer, Form, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { formType } from '../../../../../types';
import FormQuestion from '../FormQuestion';
import { AllQuestionsPropTypes } from '../OrganizationMobile.propTypes';

const { Text } = Typography;

function QuestionFormDrawer(props) {
  const {
    drawerVisible,
    setDrawerVisible,
    statusReport,
    baseForm,
    selectedQuestion,
    allQuestions,
    setAllQuestions,
  } = props;
  const [form] = Form.useForm();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const onClose = (event) => {
    event?.stopPropagation();
    setDrawerVisible(false, statusReport);
  };

  const drawerTitleAction = selectedQuestion === undefined ? ['Crear'] : ['Editar', 'Guardar'];
  const footer = (
    <Row gutter={16} justify="end">
      <Col>
        <Button onClick={onClose} size="large">
          Cancelar
        </Button>
      </Col>
      <Col>
        <Button
          id="saveButton"
          form="question-form"
          type="primary"
          size="large"
          htmlType="submit"
          loading={saveButtonLoading}
          disabled={saveButtonDisabled}
        >
          {drawerTitleAction.at(-1)}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Drawer
      title={<Text strong>{drawerTitleAction.at(0).concat(' pregunta')}</Text>}
      width="500px"
      onClose={(event) => onClose(event)}
      open={drawerVisible}
      destroyOnClose
      style={{ body: { paddingBottom: 80 } }}
      maskClosable={false}
      closable={false}
      footer={footer}
    >
      <FormQuestion
        form={form}
        baseForm={baseForm}
        setSaveButtonLoading={setSaveButtonLoading}
        setSaveButtonDisabled={setSaveButtonDisabled}
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        statusSelected={statusReport}
        selectedQuestion={selectedQuestion}
        allQuestions={allQuestions}
        setAllQuestions={setAllQuestions}
      />
    </Drawer>
  );
}
QuestionFormDrawer.defaultProps = {
  drawerVisible: false,
  setDrawerVisible: () => {},
  statusReport: undefined,
  baseForm: {},
  selectedQuestion: undefined,
  allQuestions: {},
  setAllQuestions: () => {},
};
QuestionFormDrawer.propTypes = {
  drawerVisible: PropTypes.bool,
  setDrawerVisible: PropTypes.func,
  statusReport: PropTypes.string,
  baseForm: formType,
  selectedQuestion: PropTypes.number,
  allQuestions: AllQuestionsPropTypes,
  setAllQuestions: PropTypes.func,
};

export default QuestionFormDrawer;
