import { Alert, App, Col, Form, Input, InputNumber, Row, Select, Skeleton } from 'antd';
import SelectPlusForm from 'components/common/SelectPlusForm';
import TitleHelp from 'components/common/TitleHelp';
import { useGetDefinitionsQuery } from 'features/definitions/definitionsAPiSlice';
import {
  useCreateRoutingParameterMutation,
  useGetRoutingParametersQuery,
  useUpdateRoutingParameterMutation,
} from 'features/routingParameters/routingParametersApiSlice';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { sortSelect } from 'services/repeated-functions';
import { formType } from 'types';
import RoutingParametersPropTypes from './routingParameters.propTypes';

function ServiceParameterForm(props) {
  const {
    onCloseDrawer,
    parameter,
    form,
    setButtonLoading,
    drawerVisible,
    organizationId,
    labels,
    setTriggerClean,
  } = props;
  const { message } = App.useApp();
  const isEdit = parameter?.id !== undefined;
  const [selectedParameter, setSelectedParameter] = useState();
  const [parameters, setParameters] = useState([]);
  const [allParameters, setAllParameters] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(isEdit ? parameter.label : undefined);
  const [createParameter] = useCreateRoutingParameterMutation();
  const [updateParameter] = useUpdateRoutingParameterMutation();

  // get current geoservice routingParameters
  const organizationIdParam = organizationId
    ? `?organizationId=${organizationId}&service=geoservices`
    : '?service=geoservices';
  const {
    data: routingParametersData,
    isLoading: isLoadingRoutingParameters,
    refetch: refetchRoutingParameters,
  } = useGetRoutingParametersQuery(organizationIdParam);

  // get definitions of routing services
  const { data: definitionsFromDb, isLoading } = useGetDefinitionsQuery('ROUTING_SERVICE');

  useEffect(() => {
    if (!isLoadingRoutingParameters) {
      setAllParameters(routingParametersData);
    }
    refetchRoutingParameters();
  }, [isLoadingRoutingParameters, refetchRoutingParameters, routingParametersData]);

  useEffect(() => {
    const checkDisabled = (currName) => {
      return allParameters
        .filter(({ label }) => label === selectedLabel)
        .some(({ name: pname }) => pname === currName);
    };
    if (!isLoading && definitionsFromDb) {
      const { data } = definitionsFromDb[0];
      const parametersForSelect = data.map(({ name }, index) => ({
        key: `param-idx-${index}`,
        value: name,
        label: name,
        disabled: checkDisabled(name),
      }));
      setParameters(parametersForSelect);
      if (isEdit) {
        const selected = data.find(({ name }) => name === parameter.name);
        setSelectedParameter(selected);
      }
    }
  }, [allParameters, definitionsFromDb, isEdit, isLoading, parameter.name, selectedLabel]);

  // form stuff
  let initialValues = {
    type: parameter?.type,
    service: 'geoservices',
  };
  const onFinish = async (values) => {
    setButtonLoading(true);
    const formValues = { ...values, ...(organizationId && { organizationId }) };
    try {
      setTriggerClean(true);
      if (isEdit) {
        await updateParameter({ id: parameter.id, body: formValues }).unwrap();
      } else {
        await createParameter(formValues).unwrap();
      }
      message.success('Formulario enviado correctamente');
      onCloseDrawer();
    } catch (error) {
      console.log(error);
      message.error('Formulario con errores, revisar');
    }
    setTriggerClean(false);
    setButtonLoading(false);
  };
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };
  const onChangeLabel = (newLabel) => {
    setSelectedLabel(newLabel);
    // reset value
    form.setFieldValue('name', undefined);
    form.setFieldValue('type', undefined);
    form.setFieldValue('value', undefined);
    setSelectedParameter(undefined);
  };
  const convertType = (type, value) => {
    let converted;
    switch (type) {
      case 'boolean':
        converted = value === 'true';
        break;
      case 'integer':
        converted = parseInt(value, 10);
        break;
      case 'number':
      case 'float':
        converted = parseFloat(value);
        break;
      default:
        converted = value;
        break;
    }
    return converted;
  };
  const getItemByType = (parameterToDraw) => {
    const { type, bounds, formOptions } = parameterToDraw;
    let element;
    let min;
    let max;
    if (bounds) {
      [min] = bounds;
      max = bounds[1] === 'Infinity' ? 99999999 : bounds[1];
    }
    const boolOptions = [
      { key: 'true-key', value: true, label: 'Verdadero' },
      { key: 'false-key', value: false, label: 'Falso' },
    ];
    switch (type) {
      case 'boolean':
        // use select because switch trigger error on dynamic change
        element = <Select options={boolOptions} placeholder="Seleccione" />;
        break;
      case 'integer':
      case 'number':
      case 'float':
        element = <InputNumber min={min} max={max} style={{ width: '100%' }} />;
        break;
      case 'select': {
        const options = formOptions.map(({ option: val }, index) => ({
          key: `${val}-${index}`,
          label: val,
          value: val,
        }));
        element = <Select options={options} placeholder="Seleccione" filterSort={sortSelect} />;
        break;
      }
      case 'string':
        element = <Input />;
        break;
      default:
        break;
    }
    return element;
  };
  const handleChangeParameter = (value) => {
    const { data } = definitionsFromDb[0];
    const parameterDefinition = data?.find(({ name }) => name === value);
    form.setFieldValue('type', parameterDefinition?.type);
    // set default value
    form.setFieldValue('value', convertType(parameterDefinition?.type, parameterDefinition?.value));
    setSelectedParameter(parameterDefinition);
  };

  if (isEdit) {
    const { name, value, type, label, service } = parameter;
    // convert value to type
    const valueConverted = convertType(type, value);
    initialValues = {
      name,
      value: valueConverted,
      type,
      label,
      service,
    };
  }

  // reset form only if drawer is displayed
  useEffect(() => {
    if (drawerVisible) {
      form.resetFields();
    }
  }, [form, drawerVisible]);

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={{ required: 'Este campo es obligatorio' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={initialValues}
    >
      <Form.Item name="service" hidden>
        <Input name="service" />
      </Form.Item>
      <SelectPlusForm
        spanLabel={24}
        spanWrapper={24}
        nameField="label"
        label="Etiqueta"
        baseItems={labels}
        onChange={onChangeLabel}
        disabled={isEdit}
        helpText="Esta etiqueta se reflejará en el campo Objetivo al parametrizar"
      />
      <Skeleton loading={!selectedLabel} active>
        {selectedLabel && (
          <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <TitleHelp
                    title="Nombre de variable"
                    helpText="Parámetros disponibles del ruteador"
                  />
                }
                name="name"
                required
                rules={[{ required: true }]}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 23,
                }}
              >
                <Select
                  showSearch
                  onChange={handleChangeParameter}
                  options={parameters}
                  allowClear
                  filterSort={sortSelect}
                  disabled={isEdit}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {selectedParameter && (
                <Alert
                  description={selectedParameter?.description}
                  type="info"
                  showIcon
                  style={{ padding: 10 }}
                />
              )}
            </Col>
          </Row>
        )}
        {!selectedParameter && (
          <Alert
            description="Seleccione una variable para continuar"
            type="warning"
            showIcon
            style={{ padding: 10, marginBottom: '1rem' }}
          />
        )}
        <Skeleton loading={!selectedParameter} active>
          {selectedParameter && (
            <Row>
              <Col span={12}>
                <Form.Item name="type" hidden>
                  <Input name="type" />
                </Form.Item>
                <Form.Item
                  label={
                    <TitleHelp
                      title="Valor"
                      helpText={`Valor que se enviará al ruteador asociado al parámetro. Debe ser de tipo [${selectedParameter.type}]`}
                    />
                  }
                  name="value"
                  rules={[{ required: true }]}
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 23,
                  }}
                >
                  {getItemByType(selectedParameter)}
                </Form.Item>
              </Col>
            </Row>
          )}
        </Skeleton>
      </Skeleton>
    </Form>
  );
}

ServiceParameterForm.propTypes = {
  onCloseDrawer: PropTypes.func,
  parameter: RoutingParametersPropTypes,
  form: formType,
  setButtonLoading: PropTypes.func,
  drawerVisible: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
  organizationId: PropTypes.number,
  setTriggerClean: PropTypes.func,
};

ServiceParameterForm.defaultProps = {
  onCloseDrawer: () => {},
  parameter: {},
  form: {},
  setButtonLoading: () => {},
  drawerVisible: false,
  labels: [],
  organizationId: null,
  setTriggerClean: () => {},
};

export default ServiceParameterForm;
