const englishLocale = {
  en: {
    workingForTranslate:
      'We are working to translate the system to your preferred language, please wait a little longer. And enjoy learning spanish.',
    hello: 'Hello there',
    spanish: 'Spanish',
    spanishAb: 'ESP',
    english: 'English',
    englishAb: 'ENG',
    goToOptimalWeb: 'Go to The Optimal website',
    theOptimal: 'The Optimal',
    form: {
      success: 'Form submitted successfully',
      error: 'Form with error, please check',
      sendSuccess: 'Form sended successfully',
      checkForm: 'Check the form',
      dataUpdated: 'Data updated',
      fieldRequired: 'Fill this field!',
      permissionRequiredForSetting: 'You do not have permissions to modify this setting',
      selectPlus: {
        addTo: `Add to %{label}`,
        add: 'Add',
        typeForAddANewElement: 'Type to add a new %{element}',
      },
      customAttributes: {
        unrecognizedType: '**Type "%{dataType}" has no component to draw!**',
      },
      parameterize: {
        title: 'Set your parameters',
        fieldDisabled: 'To use this field enable in organization settings',
        defaultVehicle: 'Default vehicle',
        times: 'Times',
        buttonPrimary: 'Route and optimize',
        rangeDeliveryDate: 'Dispatch date and time',
        action: 'Type of action',
        fixedDeliveryStopCost: 'Stop time',
        target: 'Target function',
        variableFleet: 'Use variable fleet',
        loadCardinale: 'Load %{number}',
        geoserviceSpeedWeight: 'Speed weighter',
        deliveryDemandCostAndRates: 'Fixed recharge time',
        maxWaitingCost: 'Maximum waiting time',
        deliveryCostPerLoadUnit: 'Recharge time per unit',
        help: {
          maxWaitingCost: 'Maximum waiting time before a service window',
          deliveryCostPerLoadUnit: 'Time it takes to load a unit of product into the vehicle',
          action: 'Determines whether the route start or end time should be prioritized',
          fixedDeliveryStopCost: 'Vehicle waiting time for each stop',
          target: 'Routing configuration to generate the routes',
          variableFleet:
            'When active, it considers the active vehicles of your organization; when deactivated, it considers an additional virtual vehicle with fixed loads',
          defaultVehicleLoads:
            'Values that are associated with the virtual vehicle used to optimize routing',
          geoserviceSpeedWeight:
            'Percentage factor applied to reduce or increase the average speed of the vehicle',
          deliveryDemandCostAndRates: 'Fixed time it takes to load all products to the vehicle',
        },
      },
      demandsImport: {
        buttonUpload: 'Upload demands',
        advancedOption: 'Advanced',
        simpleOption: 'Simple',
        superAdvancedOption: 'Super advanced',
        forStops: 'Visits',
        forDepots: 'Depots',
        forVehicles: 'Vehicles',
        superAdvanced: 'Super advanced',
        updateMassiveType: 'Massive update of %{type}',
        instructions: 'Instructions',
        helpText: 'Help',
        help: {
          needHelpWithUpload: 'Need help with uploading your files',
          howImportLocationsOrDemands: 'How do I import my visits or demands?',
          colsFormatToPreventErrors: 'Format columns to prevent errors',
          georeference: {
            enabled: 'On',
            disabled: 'Off',
            disabledDetail: 'Only stops without coordinates are georeferenced.',
            enabledDetail: 'All stops are georeferenced, even with a visit code.',
          },
          maxSize: 'The maximum size of a file that can be uploaded is 5 MB.',
          maxRows: 'Uploaded files must contain a maximum of %{maxRows} records (rows).',
          advancedOption:
            'Create demands, you should parameterize to optimize with the logic from The Optimal',
          simpleOption: 'Create routes without optimization logic from The Optimal',
          superAdvancedOption: "Don't use, in development",
          remember: 'Before upload',
          onlyCSVWithSeparator:
            'Only can upload files with type CSV (.csv) with semi colon as separator (";").',
          downloadExample: 'Download example template and replace the fields with your data.',
          caseForExcel: 'In case of use Excel, download example template for Windows.',
          skipEmptyLines: 'Skip empty lines.',
          formatForMailPhone:
            'Telephone and email contact information must comply with the format `+XXXXXXXXXXX` (with single quotes) and name@domain.com whenever the template contains any of these.',
          skuAndLoas:
            'In case of include SKU, the loads for this demand will be calculated with de sum of all SKU related',
          anyErrorCancel:
            'Any error in the import will cancel the entire operation and no records will be imported.',
          onlyCsv: 'Only can upload files with type CSV (.csv)',
          formatForContactEmail:
            'Contacto `+XXXXXXXXXXX` [with single quotes] (telefóno) & name@domain.com (correo electrónico), mandatory formatting if the template includes any.',
          codesForEntity:
            'The codes entered must correspond to existing %{entityName} that are not archived.',
        },
        upload: {
          showDetails: 'View details',
          showErrorDetails: 'View error details',
          uploadFile: 'Upload file',
          delete: 'Delete',
        },
        drawerErrors: {
          retryUpload: 'Retry upload',
          errorsOnFile: 'Errors in file',
          haveErrorsOnImporter: 'You have %{totalErrors} import errors',
          pleaseCheckDetails: 'Please review the details and try uploading again.',
          rowNumber: 'Row %{number}',
        },
        simple: 'Simple',
        advanced: 'Advanced',
        typeImport: '%{type} import',
        exampleTemplate: 'Example template',
        georeferenceAllEntries: 'Georeference all entries',
        clickForUpload: 'Click or drag the file to this area to upload it',
        massiveUpdateOf: 'Massive update of %{type}',
        errors: {
          validation: '%{fileName} file upload failed. Check rows: %{errors}',
          fileUpload: '%{fileName} file upload failed.',
          fileFormat: "File don't have valid format (%{formats})",
          notFound: 'Example file not found',
          sizeFile: 'Exceeds maximum size (%{maxFileSize} MB)',
        },
        alerts: {
          somethingWentWrong: 'Something went wrong.',
          processingRequest: 'We are processing your request...',
          validatingFile: 'Validating file...',
          validationSuccess: 'File %{fileName} validated successfully.',
          validationFailed: 'File %{fileName} is not valid.',
          fileUpload: '%{fileName} file uploaded successfully.',
          fileWithErrorMessage: 'Cannot upload and process because one of the files has errors.',
          fileWithErrorDescription:
            'Delete the file with errors and correct the problems, or delete only that file and upload the correct ones.',
        },
        messages: {
          row: {
            zero: 'File with errors',
            one: 'Row %{number}',
            other: 'Row %{number}',
          },
          requiredCols: {
            one: 'Column %{details} cannot be empty.',
            other: 'Columns %{details} cannot be empty.',
          },
          validateFormatRestrictions: {
            one: 'Column %{details} has invalid format.',
            other: 'Columns %{details} have invalid formats.',
          },
          validateDemandDetails: {
            one: 'The column %{details} in product is required.',
            other: 'The columns %{details} in product are required.',
          },
          validateCoords: {
            one: '%{details} out of range.',
            other: '%{details} out of range.',
          },
          validatePhone:
            'Phones do not comply with the +XXXXXXXXXXX format, remember to use single quotes before the + symbol in your csv file.',
          validateEmail:
            'Emails do not comply with the name@domain.com format, example contact@theoptimalpartner.com.',
          validateVisitsExist: 'Visit does not exist.',
          validateDepotsExist: 'Depot does not exist.',
          validateSameDateOnRoutingSet: 'There is more than one date assigned to the demand-set.',
          validateVisitCodeUniqueness: 'Existing code found.',
          validateVisitCodeUniquenessNotNumber: 'Duplicate code found in the file.',
          validateVisitsExistCode: 'Visit does not exist.',
          validateUniquenessCode: 'Duplicate code found in the file.',
          validateBaseCodeUniqueness: 'Existing code found.',
          validateBaseCodeUniquenessNotNumber: 'Duplicate code found in the file.',
          validateBasesExistCode: 'Depot does not exist.',
          validateVehicleCodeUniqueness: 'Existing code found.',
          validateVehicleCodeUniquenessNotNumber: 'Duplicate code found in the file.',
          validateVehicleExistCode: 'Vehicle does not exist.',
          validateMaxLoads: 'Maximum %{details} capacity exceeded.',
          column_mismatched: 'The number of columns is not correct compared to the header.',
          unknownError: 'Unknown error: %{details}, please contact support.',
          checkAmountOfRecords: 'The file exceeds the maximum number of records.',
          validateAllHeaders: 'The headers do not match the example file.',
          validateNotBlankFile: 'The file has no rows.',
          wrong_delimiter:
            'The file does not use the correct format. Check the separators, they must be semicolons (;).',
          conditionalRows:
            'Fill the columns [%{leftCondition}] or [%{rightCondition}] or [%{specialCondition}].',
          conditionalRowsLeftRight: '%{leftCondition} or %{rightCondition}.',
          conditionalRowsLeftRightSpecial:
            '%{leftCondition} or %{rightCondition} or %{specialCondition}.',
          conditionalRowsMessages: {
            'Latitud-Longitud': 'Enter valid latitude and longitude coordinates',
            'Pais-Region o Provincia-Comuna-Calle-Numero': 'Enter a valid and complete address',
            Codigo: 'Check the visit code. Please provide a registered code',
          },
        },
      },
      errors: {
        fileFormat: "%{fileName} file don't have valid format (%{formats})",
      },
      changePassword: {
        changePassword: 'Change password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        alerts: {
          changeSuccessfully: 'New password changed successfully',
        },
        errors: {
          wrongPassword: 'Wrong password',
          minStrength: 'Password does not meet requirements',
          notEquals: 'The two passwords you entered do not match',
        },
        rules: {
          currentPassword: 'Enter your current password',
          newPassword: 'Enter new password',
          confirmNewPassword: 'Confirm new password',
        },
        strength: {
          mustHave: 'Password must contain',
          length: 'Minimum 8 characters',
          uppercaseLetter: 'Minimum 1 Uppercase',
          lowercaseLetter: 'At least one lower case',
          digit: 'Minimum 1 digit',
          specialCharacter: `Minimum 1 symbol  (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | ~ \` + =)`,
        },
      },
      profile: {
        helps: {
          phoneNumber:
            'Este teléfono irá asociado a los correos electrónicos que se envíen a clientes',
        },
        errors: {
          name: 'Must enter a Name',
          lastName: 'Must enter a Last name',
          phoneNumber: 'Must contain only numbers, until 10 digits ej: 9123456789',
          email: 'No email defined',
          roles: 'No roles defined',
          relatedOrganizations: 'There is no associated organization',
        },
      },
      location: {
        updatingLocation: 'Updating location...',
        locationUpdated: 'The location has been updated, remember to save the changes',
        fieldRequired: 'This field is required',
        code: 'Code',
        name: 'Name',
        category: 'Category',
        vip: 'Vip',
        location: 'Location',
        localization: 'Localization',
        address: 'Address',
        number: 'Number',
        address2: 'Additional info',
        geolocation: 'Geolocation',
        latitude: 'Latitude',
        longitude: 'Longitude',
        additionalInformation: 'Additional info',
        restrictions: 'Restrictions',
        geocodeAddress: 'Georeference address',
        geocodeError: 'Error when georeferencing',
        searchAddress: 'Address finder',
        contactInfo: 'Contact information',
        phoneCount: 'Phone number %{count}',
        phone: 'Phone number',
        emailCount: 'Email %{count}',
        email: 'Email',
        rules: {
          code: 'Code %{code} already exists',
          phonePrefix: 'You must select a country',
          phoneJustNumber: 'It must contain only numbers, up to 10 digits, example: 9123456789',
          phone: 'You must enter a phone number',
          email: 'You must write an email',
          emailInvalid: 'It is not a valid Email',
        },
        alert: {
          changeOnlyHere: 'Modifying visit data will not affect the associated demands.',
        },
        helps: {
          searchAddress: 'Search based on street and number, recommending matches',
          typeToSearch: 'Enter the address to search',
        },
        georeferenceAllEntries: 'Georeference all entries',
        typeImport: '%{type} import',
        exampleTemplate: 'Example_template',
      },
      system: {
        updateSuccess: 'Data successfully updated',
        createSuccess: 'Data successfully created',
        cannotUpdate: 'Data could not be updated',
        advancedInformation: 'Advanced information',
        type: 'Type',
        totalRecordsForReports: 'Number of records in Report preview',
        serviceProvider: 'Service provider',
        mapForDisplay: 'Map',
        storageService: 'Storage service',
        serviceCollector: 'Trackpoints',
        label: 'Label',
        description: 'Description',
        name: 'Name',
        entities: 'Entities',
        value: 'Value',
        unit: 'Unit',
        location: 'Stop & depot',
        vehicle: 'Vehicle',
        depot: 'Depot',
        stop: 'Visit',
        fieldName: 'Field name',
        options: 'Options',
        values: 'Values',
        defaultValue: 'Default value (Optional)',
        defaultValue1: 'Default value',
        enableForAllSystem: 'Enable for all system',
        relatedEntity: 'Related entity',

        helps: {
          type: 'Organization type',
          totalRecordsForReports:
            'Determines the number of records to display in the Reports preview → Reports',
          mapForDisplay: 'Map provider shown',
          serviceCollector: 'Service that will store the trackpoints',
          description: 'Description',
          unit: 'Unit',
          fieldName: 'Field name',
          enableForAllSystem: 'Enable for all system',
          urlRoutingEngine: 'Base URL of routing engine',
          versionRoutingEngine: 'Version of routing engine to use',
        },
        errors: {
          duplicated: 'The value %{value} already exists',
          selectType: 'Select type',
          selectEntity: 'Select entity',
          urlInvalid: 'Routing engine URL is not a valid url',
          versionRoutingEngine: 'Invalid version, only accepts v[number] format. Ex: v1, v2',
        },
      },
      organization: {
        endpointRe: 'Routing engine endpoint (RE)',
        urlRoutingEngine: 'Routing Engine URL',
        versionRoutingEngine: 'Routing Engine Version',
        helps: {
          urlRoutingEngine: 'Base URL of routing engine',
          versionRoutingEngine: 'Version of routing engine to use',
        },
        errors: {
          urlInvalid: 'Routing engine URL is not a valid url',
          versionRoutingEngine: 'Invalid version, only accepts v[number] format. Ex: v1, v2',
        },
      },
      account: {
        recover: {
          recoverPassword: 'Recover password',
          code: 'Code',
          password: 'Password',
          confirmPassword: 'Confirm password',
          rules: {
            code: 'Type your code',
            password: 'Type your password',
          },
          errors: {
            minStrength: 'Password does not meet requirements',
            notEquals: 'The two passwords you entered do not match',
            wrongData: 'Wrong data',
          },
          alerts: {
            passwordChanged: 'Password updated successfully.',
            enabledToLogin: 'Now you can login with your user and password.',
          },
        },
        forgot: {
          recoverPassword: 'Recover password',
          userYourUser:
            'Enter the username you used to register. We will send you an email to reset your password.',
          user: 'Username',
          helps: {
            user: 'Type your DNI (RUT, Passport or other defined by your Admin)',
          },
          rules: {
            user: 'Type your username',
          },
          alerts: {
            successRequest: 'Request successfully',
            checkEmail: 'Check your email and follow the steps.',
          },
          errors: {
            notFoundUser: 'User not found',
          },
        },
        confirm: {
          confirmAccount: 'Confirm account',
          temporalPassword: 'Temporal password',
          newPassword: 'New password',
          confirmPassword: 'Confirm password',
          rules: {
            temporalPassword: 'Type your temporal password',
            newPassword: 'Type your new password',
          },
          errors: {
            userOrPassword: 'Wrong username or password',
            newPassword: 'Password does not meet requirements',
            confirmPassword: 'The two passwords you entered do not match',
          },
        },
        login: {
          loginButton: 'Log in',
          forgotPasswordLink: 'Forgotten password?',
          user: 'Username',
          password: 'Password',
          rememberme: 'Remember me',
          helps: {
            user: 'Type your DNI (RUT, Passport or other defined by your Admin)',
          },
          rules: {
            user: 'Type your username',
            password: 'Type your password',
          },
          errors: {
            userOrPassword: 'Wrong username or password',
          },
        },
      },
      vehicles: {
        fieldRequired: 'This field is required',
        nid: 'Vehicle code',
        plate: 'Plate',
        additionalInformation: 'Additional info',
        rules: {
          nid: 'Code %{nid} already exist',
          plate: 'Plate %{plate} already exist',
        },
      },
      users: {
        password: 'Password',
        newPassword: 'New password',
        fieldRequired: 'This field is required',
        uploadPicture: 'Upload a picture',
        nationalNid: 'DNI',
        name: 'Name',
        lastName: 'Last name',
        role: 'Role',
        email: 'Email',
        organization: 'Organization',
        repeatPassword: 'Repeat password',
        generatePassword: 'Generate temporary password',
        helps: {
          nationalNid:
            'National Identity Document: Enter the RUT, passport or unique identifier of the person',
          role: 'Roles for the user',
          organization: 'User organizations',
          generatePassword: 'Generate a secure password that will be sent to the user',
        },
        errors: {
          nationalNid: 'DNI is already registered',
          fileFormat: "%{fileName} file don't have valid format (%{formats})",
          minStrength: 'Password does not meet requirements',
          notEquals: 'The two passwords you entered do not match',
        },
        rules: {
          email: 'It is not a valid Email',
        },
      },
      generalRouting: {
        title: 'General routing',
        routeServices: 'Routing services',
        defaultTarget: 'Default target',
        maxSendForWork: 'Limit on records for mass upload of information',
        defaultVehicle: 'Default vehicle',
        showInParameterize: 'Show in parameterize',
        minVehicles: 'Minimum of vehicles',
        geoserviceSpeedWeight: 'Velocity weighting',
        times: 'Times',
        fixedDeliveryStopCost: 'Stop time',
        maxWaitingCost: 'Max waiting time',
        deliveryDemandCostAndRates: 'Fixed reload time',
        deliveryCostPerLoadUnit: 'Reload time per load',
        endpointRe: 'Routing engine endpoint to use',
        helps: {
          defaultTarget: 'Routing configuration that the organization will use as a base',
          maxSendForWork:
            'Maximum number of records that can be uploaded per importer, limited to 500 due to infrastructure limitations (lambda)',
          showInParameterize:
            'Allows you to hide or show the user the values ​​defined when parameterize a set of demands',
          minVehicles:
            'Fixed number of vehicles to be considered as a minimum to generate routes, which is associated with the min_vehicles parameter when routing',
          geoserviceSpeedWeight:
            'Percentage factor applied to reduce or increase the average vehicle speed',
          loadsUnits:
            'Values ​​that are associated with the virtual vehicle used to optimize routing',
          fixedDeliveryStopCost:
            'Fixed waiting time per stop, which is associated with the fixed_delivery_stop_cost parameter when routing',
          maxWaitingCost:
            'Maximum waiting time before an attention window, which is associated with the max_waiting_cost parameter when routing',
          deliveryDemandCostAndRates:
            'Fixed time it takes to load products into the vehicle, which is associated with the delivery_demand_cost_and_rates parameter when routing',
          deliveryCostPerLoadUnit:
            'Variable time it takes to load products onto the vehicle, which is associated with the delivery_cost_per_load_unit parameter when routing',
        },
        alerts: {
          updateSuccessfully: 'Routing configuration updated successfully',
          createSuccessfully: 'Routing configuration created successfully',
          errorOnSave: 'Routing configuration could not be updated',
          routingParameterIsRequired:
            'You must add at least one routing parameter to display the available profiles',
        },
      },
      mobile: {
        recommendFinish: 'Recommend finish',
        helps: {
          recommendFinish:
            'When activated, a message is shown on the screen to the driver when there are no more stops to report to finish the route.',
        },
      },
      calibrator: {
        title: 'Coordinate Calibrator',
        enableCalibrator: 'Enable Coordinate Calibrator',
        parameters: 'Parameters',
        consultTime: 'Query Time (months)',
        range: 'Range',
        range1: 'Range 1',
        depotMeters: 'Depot Meters',
        range2: 'Range 2',
        percentile: 'Percentile',
        aggregation: 'Aggregation',
        emails: 'Emails',
        emailCount: 'Email %{count}',
        addEmail: 'Add',
        helps: {
          enableCalibrator: 'Enable the use of the coordinate calibrator for the organization',
          typeANumber: 'Enter numbers starting from 1 in this field.',
        },
        alerts: {
          updateSuccessfully: 'Calibrator settings updated successfully',
          createSuccessfully: 'Calibrator configuration created successfully',
          errorOnSave: 'Calibrator configuration could not be updated',
          fillField: 'Please fill in the field',
        },
        rules: {
          email: 'You must enter an email',
          emailInvalid: 'This is not a valid email',
        },
      },
    },
    footerDrawer: {
      exit: 'Exit',
      cancel: 'Cancel',
      save: 'Save',
      confirm: 'Confirm',
      areYouSure: 'Are you sure?',
      loadAndProcess: 'Load and process',
    },
    buttons: {
      parameterize: {
        disabled: 'Must assign depot to parameterize',
        label: 'Parameterize',
      },
      edit: 'Edit',
      unarchive: 'Unarchive',
      archive: 'Archive',
      cancel: 'Cancel',
      accept: 'Accept',
      save: 'Save',
      changePhoto: 'Change photo',
      send: 'Send',
      create: 'Create',
      manual: 'Manual',
      massive: 'Massive',
      show: 'Show',
      addPhone: '+ Add phone number',
      addEmail: '+ add email',
      addOptions: '+ Add options',
      filter: 'Filter',
      close: 'Close',
      more: 'More',
      delete: 'Delete',
      saveChanges: 'Save changes',
    },
    commons: {
      unarchive: 'Unarchive',
      archive: 'Archive',
      depot: 'Depot',
      name: 'Name',
      lastname: 'Last name',
      phoneNumber: 'Phone number',
      email: 'Email',
      roles: 'Roles',
      relatedOrganizations: 'Related organizations',
      demands: 'Demands',
      totalOf: 'Total of %{element}',
      routes: 'Routes',
      demandSets: 'Demand sets',
      sureUnarchive: 'are you sure you want unarchive this %{element}?',
      sureArchive: 'are you sure you want archive this %{element}?',
      status: 'Status',
      totalDemands: 'Total demands',
      clear: 'Clear',
      startDate: 'Start date',
      endDate: 'End date',
      seconds: 'Seconds',
      minutes: 'Minutes',
      delivery: 'Delivery',
      pickup: 'Pickup',
      select: 'Select',
      changeLanguage: 'Change language',
      elementUnarchived: '%{element} Unarchived',
      elementArchived: '%{element} Archived',
      cannotUnarchiveElement: 'Failed to unarchive %{element}',
      cannotArchiveElement: 'Failed to archive %{element}',
      archived: 'Archived',
      archivedFem: 'Archived',
      unarchived: 'Unarchived',
      unarchivedFem: 'Unarchived',
      entityArchivedUnarchivedF: '%{entity} %{status}',
      emptyRoutes: 'No new routes have been added yet',
      pressToEdit: 'Press to edit',
      showTour: 'Show tour',
      goToOrganizations: 'Go to organizations',
      clientLogo: 'Client logo %{name}',
      phonePrefix: 'Code',
      type: 'Type',
      createdAt: 'Created at',
      download: 'Download',
      driver: 'Driver',
      vehicle: 'Vehicle',
      vehicles: 'Vehicles',
      assistant: 'Assistant',
      search: 'Search',
      notAvailable: 'Not available',
      visits: 'Visits',
      depots: 'Depots',
      typeHere: 'Type',
      restriction: 'Restriction',
      customAttribute: 'Custom attribute',
      statuses: {
        success: 'Successful',
        SUCCESS: 'Successful',
        partial: 'Partial',
        PARTIAL: 'Partial',
        failed: 'Failed',
        FAILURE: 'Failed',
        postponed: 'Postponed',
        POSTPONED: 'Postponed',
        pending: 'Pending',
        PENDING: 'Pending',
        ARRIVAL: 'Pending',
        default: 'Successful',
      },
      eventStatus: {
        arrival: 'Arrived',
        postponed: 'Postponed',
        partial: 'Partial',
        success: 'Success',
        failure: 'Failure',
        alert: 'Alert',
        default: 'Pending',
      },
      cognitoUserStatus: {
        unconfirmed: 'Unconfirmed',
        unconfirmedDescription: 'User has been created but not confirmed.',
        confirmed: 'Confirmed',
        confirmedDescription: 'User has been confirmed.',
        archived: 'Archived',
        archivedDescription: 'User has been created but archived.',
        compromised: 'Compromised',
        compromisedDescription: '--',
        unknown: 'Unknown',
        unknownDescription: "User status isn't known.",
        resetRequired: 'Reset Required',
        resetRequiredDescription:
          'User is confirmed, but the user must request a code and reset their password before they can sign in.',
        forceChangePassword: 'Force Change Password',
        forceChangePasswordDescription: 'The user must change their password to a new value.',
        deleted: 'User has been deleted from Cognito',
        deletedDescription: 'The user exists in the database but not in the Cognito user pool.',
        default: '--',
        defaultDescription: '--',
      },
      yes: 'Yes',
      no: 'No',
      user: 'User',
      advancedFilter: 'Advanced filter',
      advanced: 'Advanced',
      organization: 'Organization',
      showMenu: 'Show menu',
    },
    locations: {
      locations: 'Locations',
      visit: 'Visit',
      showAs: 'Show as %{type}',
      table: 'Table',
      map: 'Map',
      depot: 'Depot',
      identifier: 'Identifier',
      code: 'Code',
      name: 'Name',
      category: 'Category',
      vip: 'VIP',
      yes: 'Yes',
      no: 'No',
      contactData: 'Contact information',
      dni: 'DNI',
      phoneNumber: 'Phone number',
      email: 'Email',
      location: 'Location',
      country: 'Country',
      province: 'Province',
      city: 'City',
      address: 'Address',
      geolocation: 'Geolocation',
      latitude: 'Latitude',
      longitude: 'Longitude',
      creation: 'Creation',
      createdAt: 'Create at',
      createdBy: 'Created by',
      updatedAt: 'Updated at',
      restrictions: 'Restrictions',
      street: 'Street',
      date: 'Date',
      status: 'Status',
      changeStatus: 'Change status',
      totalRowsSelected: 'Selected %{total} rows',
      totalArchived: {
        one: '%{count} archived',
        other: '%{count} unarchived',
      },
      totalUnarchived: {
        one: '%{count} restored',
        other: '%{count} restored',
      },
      changeStatusMassiveDetail: 'Change of visit statuses correctly: %{archived} %{restored}',
      sureChangeMassiveVisits: {
        one: 'Are you sure you want to change the status of %{count} visit?',
        other: 'Are you sure you want to change the status of %{count} visits?',
      },
      sureChangeMassiveDepots: {
        one: 'Are you sure you want to change the status of %{count} depot?',
        other: 'Are you sure you want to change the status of %{count} depots?',
      },
      download: 'Download',
      helps: {
        code: 'The visit code can be used as a reference to create requests, either individually or massively.',
        category:
          'Categories are customizable labels that are available to the entire organization',
      },
      alerts: {
        zeroEntries: 'There are no visits for this search',
      },
      errors: {
        changeStatusMassive: 'There was an error changing states, please try again',
      },
    },
    download: {
      generatingDocument: 'Generating document',
      youWillBeNoticed: 'You will be notified when it is available for download.',
      updateMassive: 'Mass update data',
    },
    documents: {
      show: 'Show files',
      importExport: 'Import/Export',
      showAll: 'Show all',
      openFile: 'Open file',
      expired: 'Expiraded',
      showErro: 'Show error',
      display: 'Display',
      showOrigin: 'Show origin',
      import: 'Import',
      export: 'Export',
      status: {
        expired: 'A newer version of the file has been generated',
        tooOld: 'File expired, you must generate a new one',
      },
      helps: {
        type: 'File exported/imported',
      },
      statuses: {
        pending: 'Pending',
        pendingDesc: 'File will be processed soon',
        inProgress: 'In progress',
        inProgressDesc: 'File is processing',
        ready: 'Ready',
        readyDesc: 'File is ready and process was completed',
        error: 'Error',
        failed: 'Failed',
        errorDesc: 'Something went wrong, the process could not be completed',
      },
      modules: {
        demands: 'Demands',
        users: 'Users',
        visits: 'Visits',
        depots: 'Depots',
        vehicles: 'Vehicles',
        reports: 'Reports',
      },
      errors: {
        showOrigin: 'Cannot build origin url',
        showError: 'Show error',
      },
    },
    notifications: {
      show: 'Show notifications',
      notifications: 'Notifications',
      empty: "You don't have notifications",
      loadMore: 'Load more',
      additionalNotification: {
        one: 'And an additional notification...',
        other: 'And other %{count} additional notifications',
      },
      previous: 'Previous',
      today: 'Today',
      alerts: {
        lostConnection: 'Offline',
        notificationDisabled: 'Turn on your browser notifications',
        demoWillBeExpired: 'Your free plan ends in ',
        demoExpired:
          'Your free plan has come to an end. Boost your results in real time with a new plan.',
        demoWillBeExpiredDays: {
          zero: 'today',
          one: '%{count} day',
          other: '%{count} days',
        },
        scheduledMaintenance: 'Scheduled system maintenance:',
        scheduledMaintenanceFromTo:
          ' on %{date}, from %{start} to %{end}, our channels will be down.',
        showAll: 'Show all notifications',
        helps: {
          lostConnection: 'Check your internet.',
          notificationDisabled:
            ' to stay up to date with your updates and know if your files are processed.',
          demoWillBeExpired: ' at ',
        },
      },
    },
    errorBoundary: {
      title: {
        default: 'Oops! Something went wrong',
        error403: 'Error 403',
        error404: "Oops! this page doesn't exist",
        offline: 'Offline',
      },
      subtitle: {
        default: "We're working on fixing this problem. Please try again in a few minutes.",
        error403: 'No permissions for this page.',
        error404:
          "We couldn't find the page you were looking for. Click the button below to go to the home page.",
        offline: 'Check if you have an active and stable internet connection.',
      },
      button: 'Update',
      warning: 'Warning',
      warningHelp: 'Problems processing the request.',
    },
    assigment: {
      entityAlreadyAssigned: {
        zero: '%{entity} already assigned for this lapse',
        one: '%{entity} already assigned for this lapse in route: %{routes}',
        other: '%{entity} already assigned for this lapse in routes: [%{routes}]',
      },
      driverAlreadyAssigned: {
        zero: 'Driver already assigned for this lapse',
        one: 'Driver already assigned for this lapse in route: %{routes}',
        other: 'Driver already assigned for this lapse in routes: [%{routes}]',
      },
      vehicleAlreadyAssigned: {
        zero: 'Vehicle already assigned for this lapse',
        one: 'Vehicle already assigned for this lapse in route: %{routes}',
        other: 'Vehicle already assigned for this lapse in routes: [%{routes}]',
      },
      clearSelection: 'Clear selection',
      assigment: 'Assigment',
      routeNumber: 'Route %{number}',
      driver: 'Driver',
      assistant: 'Assistant',
      helps: {
        blockedBy: 'Blocked for being selected as %{type}',
        pressToUncheck: 'Press to uncheck',
      },
      errors: {
        alreadyAssigned: '%{type} already assigned for this route',
        inThisLapse: 'in this lapse.',
      },
    },
    profile: {
      myProfile: 'My profile',
      changePassword: 'Change password',
      logOut: 'Log out',
      altAvatar: 'Avatar',
    },
    driver: {
      congratulations: 'Congratulations!',
      welcomeMessage:
        'Your account has been successfully validated. Now is the time to take the next step! Download Routing Mobile app to start enjoying the experience.',
      selectOrScan: 'Select or scan an option:',
      downloadForOs: 'Download app mobile for %{os}',
    },
    system: {
      general: 'General',
      restrictions: 'Restrictions',
      loads: 'Loads',
      trackpoints: 'Trackpoints',
      customAttributes: 'Custom attributes',
      routingServices: 'Routing services',
      routingService: 'Routing service',
      definitions: 'Definitions',
      label: 'Label',
      description: 'Description',
      name: 'Name',
      type: 'Type',
      entity: 'Entity',
      unit: 'Unit',
      value: 'Value',
      definition: 'definition',
      restriction: 'Restriction',
      load: 'Load',
      trackpoint: 'Trackpoint',
      location: 'Stop & depot',
      vehicle: 'Vehicle',
      depot: 'Depot',
      stop: 'Visit',
      allEntities: 'All entities',
      enableForAllSystem: 'Enable for all system',
      all: 'All',
      yes: 'Yes',
      no: 'No',
      status: 'Status',
    },
    following: {
      stateRoutes: 'State of routes',
      active: 'Active',
      inactive: 'Inactive',
      reload: 'Reload',
      routesUnordered: 'Routes unordered',
      routesOutHour: 'Delayed routes',
      seconds: 'Seconds',
      minutes: 'Minutes',
      na: 'NA',
      pa: 'PA',
      pe: 'PE',
      plannedDelivery: 'Planned deliveries',
      inactivityNotice: 'Inactivity notice',
      keepActive: 'Keep active',
      close: 'Close',
      mapUnusedNotice:
        "You're not using the map. The map will be disabled in %{number} minutes. Do you need more time?",
      noRoutesInLapse: 'No routes availables for this lapse',
      routingSetNid: 'Routing set: %{nid}',
      planningFor: 'Planning for ',
      table: 'Table',
      notifications: 'Notifications',
      filter: 'Filter',
      routesNeededToFilter: 'Routes needed to filter',
      back: 'Back',
      clear: 'Clean',
      executedInDisorder: 'Routes executed in disorder',
      delayedRoutes: 'Delayed routes',
      FAILURE: 'Failed deliveries',
      PARTIAL: 'Partial deliveries',
      SUCCESS: 'Success deliveries',
      delayedRoutes1: 'Prioritize delays',
      routesWithFailure: 'Prioritize failed deliveries',
      route: 'Route',
      client: 'Client or ID',
      document: 'Document Number',
      depot: 'Depot',
      driver: 'Driver',
      vehicle: 'Vehicle',
      show: 'Show',
      orderBy: 'Order by',
      status: {
        ASSIGNED: 'Not started',
        default: 'Not started',
        STARTED: 'In route',
        FINISHED: 'Finished',
      },
      routeStatus: {
        finished: 'Finished',
        notAssigned: 'Not assigned',
        notStarted: 'Not started',
        inExecution: 'In execution',
      },
      statusLabel: 'Status',
      routingSet: 'Routing Set',
      routingSetInit: 'CR',
      routeOrder: 'Route number',
      notAssigned: 'Not assigned',
      stopsSize: 'Deliveries',
      startsAt: 'Starts at',
      endsAt: 'Ends at',
      lastPosition: 'Last position',
      lastConnection: 'Last connection: %{date}',
      gpsActive: 'GPS',
      progress: 'Progress',
      noMatch: 'No match',
      totalCompliance: 'Stop compliance',
      routingSetRoute: 'Routing Set %{routingSetId} | Route %{routeOrder}',
      noPhoneNumber: 'No Phone number',
      routeResume: '%{depot} | %{totalStops} stops | %{distance} Km.',
      searchDocumentClient: 'Search by document or client',
      noAddress: 'No address',
      showEvents: 'Show events',
      noEvents: 'No events',
      stopWithPhotos: 'Stop with photos',
      moreInfo: 'More info',
      overcome: 'Overcome',
      within: 'within',
      distanceRange: 'distance range',
      contactData: 'Contact data',
      name: 'Name',
      contactPhone: 'Phone number',
      email: 'Email',
      time: 'Time',
      diffTime: 'Difference time',
      estimatedTime: 'Estimated time (ETA)',
      stopTime: 'Stop time',
      outTime: 'Out time',
      distance: 'Distance',
      reportDistance: 'Report distance',
      states: {
        success: 'Successful stop',
        partial: 'Partial stop',
        failure: 'Failed stop',
        postponed: 'Postponed',
        started: 'Started',
        finished: 'Finished',
        default: 'Not reported',
      },
      order: 'Order',
      clientId: 'Client ID',
      clientName: 'Client name',
      arrivesAt: 'Planned time',
      arrivedAt: 'Real time',
      eta: 'ETA',
      stop: 'Stop',
      departedAt: 'Departed time',
      showInMap: 'Show position on map',
      comment: 'Comment',
      noEventsReported: 'No events reported',
      arrival: 'Arrival',
      departure: 'Departure',
      timeCaptured: 'Time',
      loads: 'Loads',
      reason: 'Reason',
      form: 'Form',
      closeMap: 'Close map',
      openMap: 'Open map',
      itemCoord: 'Real',
      eventCoord: 'Reported',
      difference: 'Difference',
      coordsItem: 'Item Coords',
      coordsEvent: 'Report Coords',
      helps: {
        routesOutHour:
          'A delay greater than %{delay} %{unit} at the start of the route is considered late.',
        na: 'NA: It is the real amount or number that you currently carry',
        pa: 'PA: It is the real percentage that you currently have',
        pe: 'PE: It is the estimated percentage that you should currently have',
        planningFor:
          'When you enter the Following module from the menu, you will automatically see the routes of the day. You can extend the range up to 14 days backward or forward.',
        startsAt: 'Planned time, actual time and difference between both are shown',
        endsAt: 'Planned time, actual time and difference between both are shown',
        lastPosition: "Time the vehicle's last position was recorded",
        gpsActive: 'It is active if the last connection was less than %{minutes} minutes ago',
        totalCompliance: 'Stops made vs total stops',
        diffTime: 'Difference between planned and actual time',
        eta: 'Estimated arrival time',
        stop: 'Stop time',
        distance: 'Meters of distance from the report of arrival at the point',
        status: 'Status of the last reported event',
        arrival: 'Planned arrival',
        departure: 'Planned departure',
        timeCaptured: 'Actual arrival time',
      },
      showStats: 'See stats',
      goToRoutingSet: 'Go to route set',
      showStatsTitle: 'Route %{routeOrder} stats',
    },
    vehicles: {
      vehicle: 'Vehicle',
      plate: 'Plate',
      additionalInformation: 'Additional info',
      status: 'Status',
      active: 'Active',
      inactive: 'Inactive',
      nid: 'Code',
      loads: 'Loads',
      changeStatus: 'Change status',
      totalRowsSelected: 'Selected %{total} rows',
      totalArchived: {
        one: '%{count} archived',
        other: '%{count} unarchived',
      },
      totalUnarchived: {
        one: '%{count} restored',
        other: '%{count} restored',
      },
      changeStatusMassiveDetail: 'Change of vehicle statuses correctly: %{archived} %{restored}',
      sureChangeMassiveVehicles: {
        one: 'Are you sure you want to change the status of %{count} vehicle?',
        other: 'Are you sure you want to change the status of %{count} vehicles?',
      },
      helps: {
        nid: 'The vehicle code is displayed in the routing assignment. Use it as a reference for precise tracking',
      },
      alerts: {
        loads:
          'Items related to Loads are disabled as you must configure the units of measure in Settings → Organization → Measurements',
      },
      errors: {
        changeStatusMassive: 'There was an error changing the statuses, try again',
      },
    },
    users: {
      nationalNid: 'DNI',
      name: 'Name',
      roles: 'Roles',
      role: 'Role',
      phoneNumber: 'Phone number',
      lastLogin: 'Last login',
      status: 'Status',
      user: 'User',
      personalInfo: 'Personal information',
      additionalInformation: 'Additional info',
      selectedOrganization: 'Organization',
      active: 'Active',
      inactive: 'Inactive',
      email: 'Email',
      helps: {
        nationalNid:
          'DNI (RUT, Passport, etc.) is the unique identifier of the user. There cannot be two alike in the system. Each one is unique!',
        userCognitoEnabled: 'Indicates if the user is enabled to sign in',
      },
      alerts: {
        rolesAssigmentSuccessful: 'Roles correctly assigned to user',
      },
      errors: {
        cannotUpdateUser: 'Could not update user, previous value will be maintained',
        roleRequired: 'You must enter at least one role',
      },
      userCognitoStatus: 'Cognito status',
      userCognitoEnabled: 'Enabled',
    },
    alert: {
      pinNumberInMap:
        'The number inside each pin on the map is for informational purposes, it does not imply that this is the order of the route.',
      pinDraggable:
        'The colored pin corresponds to the location you are viewing and you can drag it to change its position.',
      pinIsCurrentLocation: 'The colored pin corresponds to the location you are viewing.',
    },
    errors: {
      editableCell: 'Form with errors, previous value will be maintained',
      elementCannotBeBlank: '%{element} cannot be blank',
      noChangeRequested: 'No changes requested',
    },
    modules: {
      planning: 'My planning',
      routesList: 'Routes list',
      routeJobList: 'Route jobs list',
      index: 'Index',
      visit: 'Visit',
      depot: 'Depot',
      panels: 'Panels',
      report: 'Reports',
      fleet: 'Fleet',
      users: 'Users',
      roles: 'Roles & Permissions',
      organization: 'Organization',
      system: 'System',
      names: {
        routes: 'ROUTES',
        following: 'FOLLOWING',
        locations: 'LOCATIONS',
        reports: 'REPORTS',
        settings: 'SETTINGS',
        documents: 'IMPORTS & EXPORTS',
        help: 'HELP',
      },
    },
    sidebar: {
      routes: 'Routes',
      following: 'Following',
      locations: 'Locations',
      reports: 'Reports',
      settings: 'Settings',
      support: 'Help',
      documents: 'Documents',
      help: 'Help',
    },
    so: {
      windows: 'Windows',
      others: 'Others',
      android: 'Android',
      iphone: 'Iphone',
    },
    routes: {
      routeOrder: 'Route number',
      driver: 'Driver',
      plate: 'Vehicle plate',
      assistant: 'Assistant',
      status: 'Route status',
      editor: {
        addManualStop: 'Add manual stop',
        addRest: 'Add lunch time',
        sureRevertChanges: 'Are you sure you want to undo the changes?',
        undo: 'Undo',
        sureDeleteStop: {
          one: 'Are you sure you want to delete this stop?',
          other: 'Are you sure you want to delete these stops?',
        },
        emptyList: 'There are no routes or stops that match the filters',
        routingSets: 'Routing sets',
        editor: 'Editor',
        drawer: {
          titleEdit: 'Edit stop',
          titleCreate: 'Add manual stop',
          nid: 'code',
          name: 'name',
          searchByType: 'Only search %{type} among existing visits',
          searchClient: 'Client search',
          itemDetailNumber: 'Product %{number}',
          goToVisit: 'Go to visits',
          visits: 'Visits',
          form: {
            code: 'SKU',
            description: 'Description',
            quantity: 'Quantity',
            unit: 'Unit',
            loads: 'Loads',
            addLoad: 'Add load',
            routeDetail: 'Route detail',
            routeNumber: 'Route number',
            itemOrder: 'Stop position',
            action: 'Action type',
            fixedDeliveryStopCost: 'Stop time',
            identification: 'Identification',
            nid: 'Client code',
            name: 'Client name',
            document: 'Document ID',
            contactInformation: 'Contact information',
            contactId: 'Code',
            contactName: 'Name',
            contactPhones: 'Phone number',
            contactEmails: 'Email',
            itemDetails: 'Delivery detail',
            addItemDetail: 'Add product',
            location: 'Location',
            addressForm: 'Location',
            street: 'Address',
            number: 'Number',
            line2: 'Additional information',
            geolocation: 'Geolocation',
            latitude: 'Latitude',
            longitude: 'Longitude',
            helps: {
              code: 'Product code',
              description: 'Product description',
              quantity: 'Quantity',
              mustEnterAValue: 'You must enter a value',
              routeNumber: 'Route number',
              itemOrder: 'Stop number',
              fixedDeliveryStopCost: 'Stop',
            },
          },
          alerts: {
            itemCreated: 'A new stop was added to the route %{routeNumber}',
            itemUpdated: 'Successfully modified',
            rememberChangeInLocation:
              'Remember: If you need to make changes to this stop you will have to modify it in',
          },
          errors: {
            mustSelectClient: 'You must select a client',
            fillAllFields: 'You must fill in all required fields.',
            codeNotFound: 'The client code is not registered, please confirm and try again.',
            maxLoads: 'Maximum number of loads reached',
            fieldRequired: 'This field is required',
            notEditable: 'Finished route. Choose one in progress or assigned.',
          },
        },
        list: {
          routeName: 'Route %{nid}',
          unassigned: 'Unassigned',
          assign: 'Assign',
          reassign: 'Reassign',
          vehicleCapacity: 'Vehicle capacity',
          unassignedVehicle: 'Unassigned vehicle',
          alerts: {
            relaxedConstraints:
              'Route isolated because it contains an infeasible demand. Check capacities or other restrictions.',
            saveBeforeAssign: 'You must save before you can assign',
            vehicleCapacityExceeded: 'Vehicle capacity exceeded',
          },
          itemList: {
            contactName: 'Contact name',
            contactPhone: 'Contact number',
            document: 'Document ID',
            showInfo: 'View information',
            edit: 'Edit',
            restrictions: 'Restrictions',
            errors: {
              invalidNumber: 'invalid number',
              invalidRouteNumber: 'invalid route number',
              invalidOrderNumber: 'invalid order',
            },
          },
        },
        alerts: {
          routesUpdated: 'Routes have been updated',
          rememberSave: 'Remember to save your changes.',
        },
        errors: {
          cannotUpdate: 'Failed to update routes',
          selectRouteToDelete: 'You must select at least one stop to delete',
          makeChangeInRouteToSave: 'You must modify at least one route to be able to save',
        },
      },
    },
    help: {
      all: 'All',
      search: 'Search',
      back: 'Back',
      cannotFoundDocument: 'Document could not be found, please go back',
      displayedTotalOfFounded: 'Showing %{founded} results for %{total}',
      showHelp: 'Show help',
    },
  },
};

export default englishLocale;
