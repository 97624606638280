import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  isMapNoteHidden: false,
  notificationToken: null,
  notifications: [],
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    hideMapNote: (state) => {
      return {
        ...state,
        isMapNoteHidden: true,
      };
    },
    setNotificationToken: (state, action) => {
      const { notificationToken } = action.payload;
      return {
        ...state,
        notificationToken,
      };
    },
    setNotifications: (state, action) => {
      const { notification } = action.payload;
      const { title, body, data } = notification;
      const dataParsed = JSON.parse(data);
      const { status, createdAt, type, id, message, organizationId } = dataParsed;

      const newNotification = {
        title,
        body,
        status,
        createdAt,
        type,
        id,
        message,
        organizationId,
      };
      const { notifications } = current(state);
      const otherNotifications = [...notifications];
      // add notifications only if not exist
      if (!notifications.some(({ id: persistedId }) => id === persistedId))
        otherNotifications.push(newNotification);
      return {
        ...state,
        notifications: otherNotifications,
      };
    },
    setNotificationsAsReaded: (state, action) => {
      const { organizationId } = action.payload;
      const { notifications } = current(state);
      const otherOrgsNotifications = notifications.filter(
        ({ organizationId: notOrgId }) => notOrgId !== organizationId
      );
      const orgsNotifications = notifications.filter(
        ({ organizationId: notOrgId }) => notOrgId === organizationId
      );
      // only notifications from current org are mark as readed
      const orgNotificationsReaded = orgsNotifications.map((notification) => ({
        ...notification,
        status: 'readed',
      }));
      const allNotifications = [...otherOrgsNotifications, ...orgNotificationsReaded];
      return {
        ...state,
        notifications: allNotifications,
      };
    },
  },
});

export const { hideMapNote, setNotificationToken, setNotifications, setNotificationsAsReaded } =
  appSlice.actions;
export default appSlice.reducer;
export const selectIsMapNoteHidden = (state) => state.appSlice.isMapNoteHidden;
export const selectNotificationToken = (state) => state.appSlice.notificationToken;
export const selectNotifications = (state) => state.appSlice.notifications;
