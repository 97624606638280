import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { selectCurrentUser } from 'features/users/userSlice';

export const CaslAction = {
  // defined for easy read
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  // defined to match with action.itintial
  C: 'create',
  R: 'read',
  U: 'update',
  D: 'delete',
};

// eslint-disable-next-line no-unused-vars
const userRules = (can, user, cannot) => {
  // all actions from user has permission
  const { actions } = user;
  if (actions) {
    actions.forEach((p) => {
      const action = CaslAction[p.initial];
      const subject = p.permission.nid;
      can(action, subject);
    });
  }
};

export const defineRulesFor = (user) => {
  const { can, rules, cannot } = new AbilityBuilder();
  userRules(can, user, cannot);
  return rules;
};

export const buildAbilityFor = (user) => {
  return createMongoAbility(defineRulesFor(user));
};

export const ability = buildAbilityFor(selectCurrentUser);
