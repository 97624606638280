import RouteButton from 'components/common/Button';
import DrawerImporter from 'components/common/DrawerImporter';
import LocaleContext from 'components/locale/LocaleContext';
import {
  useUploadDemandSetsMutation,
  useUploadSimpleDemandSetsMutation,
  useUploadSuperAdvancedDemandSetsMutation,
} from 'features/demandSets/demandSetsApiSlice';
import { useGetSettingsQuery } from 'features/settings/settingsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import './style.css';

function NewRouteModal(props) {
  const { buttonType } = props;
  const [visible, setVisible] = useState(false);
  const [typeUpload, setTypeUpload] = useState();
  const [uploadAvancedDemandSets] = useUploadDemandSetsMutation();
  const [uploadSimpleDemandSets] = useUploadSimpleDemandSetsMutation();
  const [uploadSuperAdvancedDemandSets] = useUploadSuperAdvancedDemandSetsMutation();
  const { loadsUnit } = useSelector(selectCurrentOrganization);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.demandsImport' };

  // load settings for default action
  const { data: settingsFields, isLoading: isLoadingSetting } = useGetSettingsQuery(
    'GENERALROUTING?format=false'
  );

  const showDrawer = (type) => {
    setVisible(true);
    setTypeUpload(type);
  };
  const isAdvanced = typeUpload === 'advanced' || typeUpload === 'super-advanced';
  const defineFunc = () => {
    let reduxFuncToUse = uploadSimpleDemandSets;
    switch (typeUpload) {
      case 'advanced':
        reduxFuncToUse = uploadAvancedDemandSets;
        break;
      case 'super-advanced':
        reduxFuncToUse = uploadSuperAdvancedDemandSets;
        break;
      default:
        reduxFuncToUse = uploadSimpleDemandSets;
        break;
    }
    return reduxFuncToUse;
  };

  return (
    <>
      <RouteButton
        buttonType={buttonType}
        onClick={showDrawer}
        buttonText={i18n.t('buttonUpload', scopeI18n)}
        destroyOnClose
      />
      {!isLoadingSetting && (
        <DrawerImporter
          useGeoreference={isAdvanced}
          open={visible}
          typeUpload={typeUpload}
          reduxFunc={defineFunc()}
          setVisible={setVisible}
          settingsFields={settingsFields}
          loadsUnit={loadsUnit}
        />
      )}
    </>
  );
}

NewRouteModal.defaultProps = {
  buttonType: 'navBar',
};

NewRouteModal.propTypes = {
  buttonType: PropTypes.string,
};

export default NewRouteModal;
