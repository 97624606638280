import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_CORE_BACKEND;

const socket = io(URL, {
  withCredentials: true,
  autoConnect: false,
  path: '/socket.io',
  transports: ['websocket'],
  secure: true,
});

export default socket;
