import { Col } from 'antd';
import NoResponsiveComponent from '../layout/NoResponsiveContent';
import VehiclesList from './List';
import './style.css';

function Vehicles() {
  return (
    <>
      <Col md={24} xs={0}>
        <VehiclesList />
      </Col>
      <Col md={0} xs={24}>
        <NoResponsiveComponent />
      </Col>
    </>
  );
}

export default Vehicles;
