import { App, Skeleton } from 'antd';
import {
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from 'features/organizations/organizationsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkPermissionForSubject } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import FormModule from './Form';

function ModuleConfiguration(props) {
  const { organizationId, goToNextStep, submitButton, current } = props;
  const navigate = useNavigate();
  const [updateOrganization, { isLoading: savingData }] = useUpdateOrganizationMutation();
  const { message } = App.useApp();
  const { id: currOrgId, timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { data: organization, isLoading: isLoadingOrgData } = useGetOrganizationQuery(
    organizationId || currOrgId
  );
  // permissions
  const ability = useContext(CaslContext);
  const canUpdate = checkPermissionForSubject(ability, 'update', 'module');

  const initialValues = {
    purchasedPlan: organization?.purchasedPlan,
    demoDays: organization?.demoDays,
    demoFinishDate: organization?.demoFinishDate,
  };

  const onFinish = async (formValues) => {
    const body = {
      ...(organizationId ? { organizationId } : { organizationId: organization.id }),
      body: {
        ...(goToNextStep && { creationStep: current + 1 }),
        ...(formValues?.purchasedPlan !== 'demo' && { demoDays: null, demoFinishDate: null }),
        ...formValues,
      },
    };
    await updateOrganization(body)
      .then(() => {
        if (goToNextStep) {
          navigate(`/organizations/creation-steps/${organizationId}/${current + 1}`);
        } else {
          message.success('Configuración de módulo actualizada correctamente');
        }
      })
      .catch((error) => {
        console.error(error);
        message.error('Error al guardar el formulario');
      });
  };

  if (isLoadingOrgData) return <Skeleton paragraph={{ rows: 10 }} />;
  return (
    <FormModule
      initialValues={initialValues}
      onFinish={onFinish}
      submitButton={submitButton}
      canSave={canUpdate}
      savingData={savingData}
      organizationCreatedAt={organization?.createdAt}
      tzOrganization={tzOrganization}
    />
  );
}

ModuleConfiguration.defaultProps = {
  organizationId: null,
  goToNextStep: false,
  submitButton: false,
  current: null,
};
ModuleConfiguration.propTypes = {
  organizationId: PropTypes.number,
  goToNextStep: PropTypes.bool,
  submitButton: PropTypes.bool,
  current: PropTypes.number,
};

export default ModuleConfiguration;
