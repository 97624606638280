import apiSlice from 'app/api/apiSlice';

/**
 * vehiclesApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const vehiclesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVehicles: builder.query({
      query: () => '/vehicles',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Vehicles', id })),
              { type: 'Vehicles', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Vehicles', id: 'PARTIAL-LIST' }],
    }),
    getVehiclesByFilters: builder.mutation({
      query: (query) => `/vehicles${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Vehicles', id })),
              { type: 'Vehicles', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Vehicles', id: 'PARTIAL-LIST' }],
    }),
    deleteOrRestoreVehicle: builder.mutation({
      query: (vehicleId) => ({
        url: `vehicles/change-status/${vehicleId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Vehicles'],
    }),
    getCustomAttributeVehicles: builder.query({
      query: () => '/vehicles/custom-attributes/definition',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'CaVehicles', id })) : ['CaVehicles'],
    }),
    createVehicle: builder.mutation({
      query: (params) => ({
        url: '/vehicles',
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['Vehicles'],
    }),
    updateVehicle: builder.mutation({
      query: (params) => ({
        url: `/vehicles/${params.vehicleId}`,
        method: 'PATCH',
        body: { ...params.body },
      }),
      invalidatesTags: ['Vehicles'],
    }),
    getVehiclesAvailablesByFilter: builder.mutation({
      query: (params) => {
        const { routeId, query } = params;
        const queryParams = query || '';
        return `/vehicles/get-vehicles/${routeId}${queryParams}`;
      },
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Vehicles', id })) : ['Vehicles'],
    }),
    uploadVehicles: builder.mutation({
      query: ({ file }) => {
        const fmData = new FormData();
        fmData.append('file', file);
        return {
          url: '/vehicles/upload',
          prepareHeaders: (headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: ['FileProcessing'],
    }),
    uploadEditVehicles: builder.mutation({
      query: ({ file }) => {
        const fmData = new FormData();
        fmData.append('file', file);
        return {
          url: '/vehicles/upload-edit',
          prepareHeaders: (headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          method: 'POST',
          body: fmData,
        };
      },
      invalidatesTags: ['FileProcessing'],
    }),
    massiveDeleteOrRestoreVehicle: builder.mutation({
      query: (body) => ({
        url: `/vehicles/massive-change-status`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Vehicles'],
    }),
  }),
});

export const {
  useGetVehiclesQuery,
  useGetVehiclesByFiltersMutation,
  useDeleteOrRestoreVehicleMutation,
  useGetCustomAttributeVehiclesQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useGetVehiclesAvailablesByFilterMutation,
  useUploadVehiclesMutation,
  useUploadEditVehiclesMutation,
  useMassiveDeleteOrRestoreVehicleMutation,
} = vehiclesApiSlice;
