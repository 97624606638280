import { Segmented } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import './style.css';

function ButtonsTab(props) {
  const { type, onChange } = props;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'commons' };

  const options = [
    {
      label: <div className="button-assign-tabs">{i18n.t('driver', scopeI18n)}</div>,
      value: 'driver',
    },
    {
      label: <div className="button-assign-tabs">{i18n.t('vehicle', scopeI18n)}</div>,
      value: 'vehicle',
    },
    {
      label: <div className="button-assign-tabs">{i18n.t('assistant', scopeI18n)}</div>,
      value: 'assistant',
    },
  ];

  return (
    <Segmented
      options={options}
      value={type}
      onChange={(selected) => onChange(selected)}
      style={{ width: '100%', marginTop: 16, borderRadius: 6 }}
      size="large"
    />
  );
}

ButtonsTab.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
};

ButtonsTab.defaultProps = {
  type: '',
  onChange: () => {},
};

export default ButtonsTab;
