import { Cascader } from 'antd';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import data from './data.json';

function Locations(props) {
  const { country, province, city, town, isMultiple, placeholder, customOnChange, allowClear } =
    props;
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    /**
     * Find value in json
     * @param {*} objeto
     * @param {*} nodeLower
     * @returns object founded with his parents
     */
    const getTreeData = (objeto, nodeLower) => {
      let resp;
      const { label, children: childrensObj } = objeto;
      if (nodeLower === label.toLowerCase()) {
        resp = objeto;
      }
      if (childrensObj) {
        // result for some is boolean
        childrensObj.some((subObj) => {
          const { children, ...rest } = objeto;
          const thisObj = { ...subObj, parent: rest };
          const childrensObjResponse = getTreeData(thisObj, nodeLower);
          resp = childrensObjResponse;
          return childrensObjResponse; // boolean or undefined
        });
      }
      return resp;
    };
    const getDataformated = (foundedSearch) => {
      let addressArray = [];
      if (foundedSearch) {
        const { value, parent } = foundedSearch;
        addressArray.push(value);
        if (parent) {
          const childArray = getDataformated(parent);
          addressArray = [...addressArray, ...childArray];
        }
      }
      return addressArray;
    };
    const searchLocationTree = (lastNode) => {
      const nodeLower = lastNode.toLowerCase();
      let foundedSearch = {};
      data.some((countryObj) => {
        const responseGetTRee = getTreeData(countryObj, nodeLower);
        foundedSearch = responseGetTRee;
        return responseGetTRee;
      });
      const dataFormated = getDataformated(foundedSearch);
      return dataFormated.reverse();
    };
    if (
      !isNil(country) &&
      country !== '' &&
      !isNil(province) &&
      province !== '' &&
      !isNil(city) &&
      city !== ''
    ) {
      const baseAddress = [country, province, city];
      if (town && !isNil(town)) {
        baseAddress.push(town);
      }
      setSelectedValue(baseAddress);
    } else if (!isNil(town) && town !== '') {
      const founded = searchLocationTree(town);
      setSelectedValue(founded);
    } else if (!isNil(city) && city !== '') {
      const founded = searchLocationTree(city);
      setSelectedValue(founded);
    }
  }, [city, country, province, town]);

  useEffect(() => {
    setOptions(data);
  }, []);

  const onChange = (value) => {
    customOnChange(value);
  };

  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <Cascader
      options={options}
      onChange={onChange}
      showSearch={{ filter }}
      multiple={isMultiple}
      placeholder={placeholder}
      allowClear={allowClear}
      value={selectedValue}
    />
  );
}

Locations.defaultProps = {
  country: '',
  province: '',
  city: '',
  town: '',
  isMultiple: false,
  placeholder: 'Seleccione',
  customOnChange: () => {},
  allowClear: false,
};

Locations.propTypes = {
  country: PropTypes.string,
  province: PropTypes.string,
  city: PropTypes.string,
  town: PropTypes.string,
  isMultiple: PropTypes.bool,
  placeholder: PropTypes.string,
  customOnChange: PropTypes.func,
  allowClear: PropTypes.bool,
};

export default Locations;
