import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Skeleton, Space, Tag, Tooltip, Typography } from 'antd';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetSettingsQuery } from 'features/settings/settingsApiSlice';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSettingsValues } from 'services/repeated-functions';
import { FollowingItemsProps, FollowingRoutesProps } from '../following.propTypes';
import PlannedStops from './PlannedStops';
import ProgressAndTable from './ProgressAndTable';

import '../style.css';

const { Text, Title } = Typography;
function FollowingLeftComponent(props) {
  const { allRoutes, allItems, isLoading, offlineMode } = props;
  const navigate = useNavigate();
  const { data: settingsData, isLoading: isLoadingSetting } =
    useGetSettingsQuery('MOBILE?format=false');
  const settingsFields = getSettingsValues(settingsData);
  const { acceptableDelay, acceptableDelayUnit = 'minutes' } = settingsFields;
  const { addReference } = useContext(TourContext);
  const ref = useRef(null);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };
  addReference('leftCompFollowingRef', ref);

  const getTotalRoutesUnordered = () => {
    return allRoutes.filter((route) => route.executedInDisorder).length;
  };

  const getTotalRoutesOutHour = () => {
    return allRoutes.filter((route) => route.delayedStart).length;
  };

  const onReloadPage = () => {
    navigate(0);
  };
  if (isLoading || isLoadingSetting) return <Skeleton paragraph={{ rows: 10 }} />;
  return (
    <Col
      md={{ span: 12 }}
      lg={{ span: 6 }}
      xxl={{ span: 4 }}
      className="following-left-component"
      ref={ref}
    >
      <Space direction="vertical" size="large">
        <Row gutter={8}>
          <Col>
            <Space size="small">
              <Title level={4} className="assign-titles">
                {i18n.t('stateRoutes', scopeI18n)}
              </Title>
              <Tag>
                <FontAwesomeIcon
                  fixedWidth
                  icon="circle"
                  beatFade={!offlineMode}
                  style={{ marginRight: '5px' }}
                  className={`color-${offlineMode ? 'negative' : 'positive'}-gps`}
                />
                {i18n.t(offlineMode ? 'inactive' : 'active', scopeI18n)}
              </Tag>
              <Button
                style={{ fontSize: '10px', height: '22px', paddingTop: '2px' }}
                icon={<FontAwesomeIcon icon={['fas', 'clock-rotate-left']} />}
                onClick={() => onReloadPage()}
                title={i18n.t('reload', scopeI18n)}
              />
            </Space>
          </Col>
        </Row>
        <ProgressAndTable allRoutes={allRoutes} />
        <Row gutter={[8, 24]}>
          <Col>
            <Row>
              <Col span={11}>
                <Card size="small" className="card-states-routes">
                  <Skeleton loading={isLoading} active>
                    <Title level={3} className="title-states">
                      {getTotalRoutesUnordered()}
                    </Title>
                    <Text type="secondary" className="subtitle-states">
                      {i18n.t('routesUnordered', scopeI18n)}
                    </Text>
                  </Skeleton>
                </Card>
              </Col>
              <Col span={11} offset={2}>
                <Card size="small" className="card-states-routes">
                  <Skeleton loading={isLoading} active>
                    <Title level={3} className="title-states">
                      {getTotalRoutesOutHour()}
                    </Title>
                    <Tooltip
                      title={i18n.t('helps.routesOutHour', {
                        ...scopeI18n,
                        delay: acceptableDelay,
                        unit: i18n.t(acceptableDelayUnit, scopeI18n),
                      })}
                    >
                      <Text type="secondary" className="subtitle-states">
                        {i18n.t('routesOutHour', scopeI18n)}
                      </Text>
                    </Tooltip>
                  </Skeleton>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <PlannedStops allItems={allItems} />
      </Space>
    </Col>
  );
}

FollowingLeftComponent.defaultProps = {
  allItems: [],
  allRoutes: [],
  isLoading: false,
  offlineMode: false,
};

FollowingLeftComponent.propTypes = {
  allItems: FollowingItemsProps,
  allRoutes: FollowingRoutesProps,
  isLoading: PropTypes.bool,
  offlineMode: PropTypes.bool,
};

export default FollowingLeftComponent;
