import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Col, List, Row, Space, theme, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { selectCurrentUser } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

function DashboardHeader(props) {
  const { showGoToHomeButton } = props;
  const { fullName, photo } = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const headerStyle = { color: token.colorLink, fontWeight: 'bold' };

  const userNameContainer = (name) => {
    return (
      <>
        Hola, <Text style={headerStyle}>{name}!</Text> 👋
      </>
    );
  };
  const listDescriptionContainer = () => {
    return (
      <Space size={3}>
        <Text className="list-description-container-style">
          Bienvenida/o a las organizaciones de
          <span style={headerStyle}> The Optimal | Planners</span>
        </Text>
      </Space>
    );
  };
  const goToHome = () => {
    navigate('/organizations');
  };
  return (
    <Header
      style={{
        color: '#fff',
        minHeight: 64,
        paddingInline: 0,
        lineHeight: '64px',
        backgroundColor: '#fff',
        padding: 0,
        marginBottom: '30px',
      }}
    >
      <Row align="bottom">
        <Col flex="auto">
          <List
            itemLayout="horizontal"
            dataSource={[
              {
                name: fullName,
              },
            ]}
            renderItem={(user) => (
              <List.Item className="list-style">
                <List.Item.Meta
                  avatar={
                    <Avatar
                      icon={<FontAwesomeIcon icon={['fa', 'user']} />}
                      src={photo}
                      size={46}
                    />
                  }
                  title={userNameContainer(user.name)}
                  description={listDescriptionContainer()}
                />
              </List.Item>
            )}
          />
        </Col>
        {showGoToHomeButton && (
          <Col flex="200px">
            <Button type="default" className="float-right" onClick={goToHome}>
              Volver al home
            </Button>
          </Col>
        )}
      </Row>
    </Header>
  );
}

DashboardHeader.defaultProps = {
  showGoToHomeButton: false,
};

DashboardHeader.propTypes = {
  showGoToHomeButton: PropTypes.bool,
};

export default DashboardHeader;
