import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { ConvertToMinutes } from 'services/repeated-functions';
import { useSelector } from 'react-redux';
import { selectCurrentOrganization } from 'features/users/userSlice';

// All temporal events & ticks will be calculated in minutes.
// Returns svg component to draw the time ticks & the header lines for the complete selected period in the "draw area".
function HeaderDrawTime(props) {
  const {
    startTime,
    endTime,
    ticksShown,
    pxOnTick,
    hSpaceDrawBorder,
    vSpaceHeader,
    minimalTick,
    minimalTickReference,
  } = props;

  const { timezone } = useSelector(selectCurrentOrganization);

  // Maximum column width (in px, number) that the current time line will have to move through.
  // const svgWidth = ticksShown * pxOnTick + hSpaceDrawBorder * 2 || 0;

  const startsAt = dayjs(startTime).tz(timezone);
  const endsAt = dayjs(endTime).tz(timezone);

  // Vars for the day text in the time timeTicks
  const daysBtwnStartEnd = startsAt.isSame(endsAt, 'day') ? 1 : endsAt.diff(startsAt, 'days') + 1;

  // Vars for the timeTicks and hour to be shown.
  const timeTicks = [];
  let lastHour = '';
  for (let i = 0; i <= ticksShown; i += 1) {
    const tickTime = dayjs(startTime).add(
      ConvertToMinutes(minimalTick, minimalTickReference) * i,
      'minutes'
    );
    const hours = tickTime.format('HH');
    const minutes = tickTime.format('mm');

    // Adds time tick lines and hour text.
    timeTicks.push(
      <g key={`${i}-tickTime`}>
        <line
          x1={hSpaceDrawBorder + pxOnTick * i}
          y1={vSpaceHeader * 2}
          x2={hSpaceDrawBorder + pxOnTick * i}
          y2={vSpaceHeader * 2 - vSpaceHeader / 2}
          stroke="grey"
          strokeWidth="0.5"
        />
        <text
          x={hSpaceDrawBorder + pxOnTick * i + 2}
          y={vSpaceHeader * 2 - vSpaceHeader / 2 + 4}
          textAnchor="start"
          fontSize="10"
          fill="grey"
        >
          {`${hours}:${minutes}`}
        </text>
      </g>
    );

    // Line to separate the days
    if (daysBtwnStartEnd > 1 && hours === '00' && lastHour !== '00') {
      timeTicks.push(
        <line
          key={`${i}-day`}
          x1={hSpaceDrawBorder + pxOnTick * i}
          y1={1}
          x2={hSpaceDrawBorder + pxOnTick * i}
          y2={vSpaceHeader}
          stroke="grey"
          strokeWidth="0.5"
        />
      );
    }
    lastHour = hours;
  }

  return (
    <g>
      <rect x="0" y="0" width="100%" height={vSpaceHeader} fill="#f5f5f5" />
      <rect
        key="headerDrawFiller"
        x="0"
        y={vSpaceHeader}
        width="100%"
        height={vSpaceHeader}
        fill="white"
      />
      <line x1="0" y1={1} x2="100%" y2={1} stroke="grey" strokeWidth={0.5} />
      <line x1="0" y1={vSpaceHeader} x2="100%" y2={vSpaceHeader} stroke="grey" strokeWidth={0.5} />
      <line
        x1="0"
        y1={vSpaceHeader * 2}
        x2="100%"
        y2={vSpaceHeader * 2}
        stroke="grey"
        strokeWidth={0.5}
      />
      {timeTicks}
    </g>
  );
}
HeaderDrawTime.defaultProps = {
  // *** User defined setings
  startTime: dayjs(), // Start Time, in date format.
  endTime: dayjs(), // End Time, in date format.
  minimalTick: 15, // Time reference for each time tick.
  minimalTickReference: 'minutes', // Set for time convertion.
  // *** Synoptic space definitions
  hSpaceDrawBorder: 40, // Horizontal space (in px, number) between the time rule, consider that the value must be added at the start and end.
  vSpaceHeader: 30, // Vertical space (in px, number) between main top lines.
  ticksShown: 30, // Number of ticks to be rendered.
  pxOnTick: 40, // Width of the tick in px.
};
HeaderDrawTime.propTypes = {
  startTime: PropTypes.instanceOf(dayjs),
  endTime: PropTypes.instanceOf(dayjs),
  hSpaceDrawBorder: PropTypes.number,
  vSpaceHeader: PropTypes.number,
  ticksShown: PropTypes.number,
  pxOnTick: PropTypes.number,
  minimalTick: PropTypes.number,
  minimalTickReference: PropTypes.string,
};

export default HeaderDrawTime;
