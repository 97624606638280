import { App, Card, Col, Form, Popover, Row, Select, Space, Table, Tag, Typography } from 'antd';
import ModalConfirm from 'components/common/ModalConfirm';
import TableButtons from 'components/common/TableButtons';
import TableFilters from 'components/common/TableFilter';
import TitleHelp from 'components/common/TitleHelp';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import {
  selectCurrentOrganization,
  selectCurrentUser,
  setCurrentUser,
} from 'features/users/userSlice';
import {
  useAssignRolesToUserMutation,
  useDeleteOrRestoreUserMutation,
  useGetUsersByFiltersMutation,
} from 'features/users/usersApiSlice';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataByCognitoUserStatus, convertDateToDayjs } from 'services/repeated-functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RolesPropTypes, UserPropTypes } from '../userRoles.propTypes';

const { Option } = Select;
const { Text } = Typography;
const cognitoUserStatus = [
  'UNCONFIRMED',
  'CONFIRMED',
  'ARCHIVED',
  'COMPROMISED',
  'UNKNOWN',
  'RESET_REQUIRED',
  'FORCE_CHANGE_PASSWORD',
  'DELETED',
];
function TableUsers(props) {
  const [selectedUser, setSelectedUser] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'users' };
  const {
    setDrawerData,
    loading,
    data,
    roles,
    setDataCallback,
    canUpdate,
    triggerClean,
    setTriggerClean,
    paginationData,
  } = props;
  const { setTotalData, totalData, setActualPage, actualPage, downloadQuery, setDownloadQuery } =
    paginationData;
  const { id: currOrgId, timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { message } = App.useApp();
  // custom hooks
  const [assignRolesToUser] = useAssignRolesToUserMutation();
  const [deleteRestoreUser] = useDeleteOrRestoreUserMutation();
  const [getUsersByFilters] = useGetUsersByFiltersMutation();

  const { subject: subjectCurrentUser } = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const ref = useRef(null);
  const refRolesShow = useRef(null);
  const { addReference } = useContext(TourContext);
  addReference('rolesUserRef', ref);
  addReference('rolesUserShowRef', refRolesShow);

  useEffect(() => form.resetFields(), [data, form]);

  const handleSave = async (id, values) => {
    const modifiedUser = find(data, { id });
    // estos roles debo mantenerlos siempre porque son de otra organización
    const anotherRoles =
      modifiedUser?.roles?.filter((mRole) => mRole.organizationId !== currOrgId) || [];
    const anotherRolesIds = anotherRoles.map((anotherRole) => anotherRole?.id);
    // agrego los roles seleccionados en la vista
    const finalRoles = [...anotherRolesIds, ...values];
    const body = {
      roleIds: finalRoles,
    };
    try {
      const result = await assignRolesToUser({ userId: id, body }).unwrap();
      // update current user if is selected
      if (result.subject === subjectCurrentUser) {
        const userProfile = {
          id: result.id,
          nationalNid: result.nationalNid,
          givenName: result.name,
          lastName: result.lastName,
          phone: result.phone,
          email: result.email,
          roles: result.roles,
          fullName: `${result.name} ${result.lastName}`,
          photo: result.photo,
          subject: result.subject,
          organization: result.organization,
          organizations: result.organizations,
          actions: result.actions,
          enableTour: result.enableTour,
        };
        dispatch(setCurrentUser({ userProfile }));
      }
      message.success(i18n.t('alerts.rolesAssigmentSuccessful', scopeI18n));
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
      message.error(i18n.t('errors.cannotUpdateUser', scopeI18n));
    }
  };

  const save = async (record) => {
    try {
      const values = await form.validateFields();
      await handleSave(record.id, values[`roles-${record.id}`]);
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
      message.error(i18n.t('errors.cannotUpdateUser', scopeI18n));
    }
  };
  const checkStatusUser = (selUser) => {
    if (!selUser) {
      return false;
    }
    let deleted = false;
    deleted = selUser?.deletedAt !== null;
    if (!deleted) {
      const seletectRelation = selUser.organizationUsers.find(
        (orgUser) => orgUser.organizationId === currOrgId
      );
      deleted = seletectRelation?.deletedAt !== null;
    }
    return deleted;
  };

  const archiveRestoreUser = async () => {
    try {
      await deleteRestoreUser(selectedUser.id).unwrap();
      const successMessage = i18n.t(
        checkStatusUser(selectedUser) ? 'elementUnarchived' : 'elementArchived',
        {
          scope: 'commons',
          element: i18n.t('user'),
        }
      );
      message.success(successMessage);
    } catch (error) {
      const errorMessage = i18n.t(
        checkStatusUser(selectedUser) ? 'cannotUnarchiveElement' : 'cannotArchiveElement',
        { scope: 'commons', element: i18n.t('user') }
      );
      message.error(errorMessage);
    }
  };

  const onConfirmAction = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await archiveRestoreUser();
    setTriggerClean(false);
    setIsModalVisible(false);
  };
  const onCancelAction = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
    setSelectedUser();
  };
  const showModal = (event, record) => {
    event.stopPropagation();
    setSelectedUser(record);
    setIsModalVisible(true);
  };

  const onClickActionButton = (type, record) => {
    const drawerData = {
      drawerType: type,
      drawerVisibility: true,
      drawerUser: record,
    };
    setDrawerData(drawerData);
  };

  const buttonsTable = (record) => (
    <TableButtons
      subject="users"
      record={record}
      readClickHandle={onClickActionButton}
      editClickHandle={onClickActionButton}
      deleteClickHandle={showModal}
    />
  );

  const renderInformation = (
    <Card style={{ minWidth: '650px', padding: '.5rem', boxShadow: 'unset' }} bordered={false}>
      {cognitoUserStatus.map((status) => {
        const { color, text, description } = DataByCognitoUserStatus(status, i18n);
        return (
          <Row key={text} style={{ marginBottom: '5px' }}>
            <Col span={8}>
              <Tag color={color} style={{ minWidth: '75px', textAlign: 'center' }}>
                {text}
              </Tag>
            </Col>
            <Col flex="auto">{description}</Col>
          </Row>
        );
      })}
    </Card>
  );

  const titleStatus = (
    <Space ref={ref}>
      <Text>{i18n.t('userCognitoStatus', scopeI18n)}</Text>
      <Popover content={renderInformation}>
        <FontAwesomeIcon icon="fa-solid fa-circle-question" color="#999999" />
      </Popover>
    </Space>
  );

  const columns = [
    {
      title: (
        <TitleHelp
          title={i18n.t('nationalNid', scopeI18n)}
          helpText={i18n.t('helps.nationalNid', scopeI18n)}
        />
      ),
      dataIndex: 'nationalNid',
      key: 'nationalNid',
      width: 120,
    },
    {
      title: i18n.t('name', scopeI18n),
      dataIndex: 'name',
      key: 'name',
      width: 350,
      render: (_text, record) => {
        const { name, lastName } = record;
        return `${name || ''} ${lastName || ''}`;
      },
    },
    {
      title: i18n.t('roles', scopeI18n),
      dataIndex: 'roles',
      key: 'roles',
      width: 350,
      render: (_text, record) => {
        const selected = _text
          ?.filter((mRole) => mRole.organizationId === currOrgId)
          .map((rol) => rol.id);
        const userCurrentOrgRoles = roles.filter(
          (userRole) => userRole.organizationId === currOrgId
        );
        if (canUpdate) {
          return (
            <div ref={ref}>
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={`roles-${record.id}`}
                initialValue={selected}
                rules={[{ required: true, message: i18n.t('errors.roleRequired', scopeI18n) }]}
              >
                <Select
                  onClick={(event) => event.stopPropagation()}
                  onChange={() => {
                    save(record);
                  }}
                  onBlur={() => {
                    save(record);
                  }}
                  mode="multiple"
                  placeholder={i18n.t('commons.select')}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {userCurrentOrgRoles.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          );
        }
        return (
          <div ref={refRolesShow}>
            {record.roles.map(({ id: roleId, name: roleName }) => (
              <Tag key={roleId}>{roleName}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: i18n.t('phoneNumber', scopeI18n),
      dataIndex: 'phone',
      key: 'phone',
      width: 250,
    },
    {
      title: titleStatus,
      dataIndex: 'userCognitoStatus',
      key: 'userCognitoStatus',
      render: (status) => {
        const { color, text } = DataByCognitoUserStatus(status, i18n);
        return (
          <Tag color={color} style={{ minWidth: '65px', textAlign: 'center' }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: (
        <TitleHelp
          title={i18n.t('userCognitoEnabled', scopeI18n)}
          helpText={i18n.t('helps.userCognitoEnabled', scopeI18n)}
        />
      ),
      dataIndex: 'userCognitoEnabled',
      key: 'userCognitoEnabled',
      render: (enabled) => {
        return i18n.t(enabled ? 'yes' : 'no', {
          scope: 'commons',
        });
      },
    },
    {
      title: i18n.t('lastLogin', scopeI18n),
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      render: (date) => {
        return date ? convertDateToDayjs(date, tzOrganization, 'DD-MM-YYYY HH:mm') : '';
      },
      width: 200,
    },
    {
      key: 'action',
      width: 350,
      render: (_text, record) => buttonsTable(record),
    },
  ];

  const rolesFiltered = roles.filter((userRole) => userRole.organizationId === currOrgId);
  const fieldsFilter = [
    {
      name: 'nationalNid',
      label: i18n.t('nationalNid', scopeI18n),
      type: 'text',
    },
    {
      name: 'name',
      label: i18n.t('name', scopeI18n),
      type: 'text',
    },
    {
      name: 'role',
      label: i18n.t('role', scopeI18n),
      type: 'select',
      options: rolesFiltered.map((role) => {
        return { value: role.name, label: role.name };
      }),
    },
    {
      name: 'deletedAt',
      label: i18n.t('status', scopeI18n),
      type: 'select',
      options: [
        { value: 'true', label: i18n.t('archived', { scope: 'commons' }) },
        { value: 'false', label: i18n.t('unarchived', { scope: 'commons' }) },
      ],
    },
  ];

  const okText = checkStatusUser(selectedUser)
    ? i18n.t('commons.unarchive')
    : i18n.t('commons.archive');
  const titleModal = i18n.t(checkStatusUser(selectedUser) ? 'sureUnarchive' : 'sureArchive', {
    scope: 'commons',
    element: i18n.t('user', scopeI18n),
  });
  const colorButtonProps = { danger: checkStatusUser(selectedUser) };
  const modalStatus = checkStatusUser(selectedUser) ? 'warning' : 'error';

  const onChangePage = async (page, pageSize) => {
    // include filter parameters
    const filterQuery = downloadQuery !== '' ? `&${downloadQuery.slice(1)}` : '';
    const queryString = `?page=${page}&take=${pageSize}${filterQuery}`;
    try {
      setActualPage(page);
      const { data: dataLocation, total } = await getUsersByFilters(queryString).unwrap();
      setTotalData(total);
      setDataCallback(dataLocation);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const pagination = {
    showTotal: (total) => `Total: ${total}`,
    total: totalData,
    showSizeChanger: true,
    onChange: onChangePage,
    current: actualPage,
  };

  return (
    <>
      <TableFilters
        setDataCallback={setDataCallback}
        fieldsFilter={fieldsFilter}
        showTitle={false}
        triggerClean={triggerClean}
        reduxFunc={getUsersByFilters}
        setDownloadQuery={setDownloadQuery}
        paginationData={{ setTotalData, setActualPage }}
      />
      <Row gutter={24} style={{ height: '100%' }} className="table-container">
        <Col flex="auto">
          <Form form={form}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={data}
              loading={loading}
              pagination={pagination}
            />
          </Form>
        </Col>
      </Row>
      <ModalConfirm
        onOk={onConfirmAction}
        onCancel={onCancelAction}
        title={titleModal}
        isModalVisible={isModalVisible}
        okButtonProps={colorButtonProps}
        okText={okText}
        status={modalStatus}
      />
    </>
  );
}

TableUsers.propTypes = {
  setDrawerData: PropTypes.func,
  setDataCallback: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(UserPropTypes),
  roles: RolesPropTypes,
  canUpdate: PropTypes.bool,
  triggerClean: PropTypes.bool,
  setTriggerClean: PropTypes.func,
  paginationData: PropTypes.shape({
    setTotalData: PropTypes.func,
    totalData: PropTypes.number,
    setActualPage: PropTypes.func,
    actualPage: PropTypes.number,
    downloadQuery: PropTypes.string,
    setDownloadQuery: PropTypes.func,
  }),
};

TableUsers.defaultProps = {
  setDrawerData: () => {},
  setDataCallback: () => {},
  loading: true,
  data: [],
  roles: [],
  canUpdate: false,
  triggerClean: false,
  setTriggerClean: () => {},
  paginationData: {},
};

export default TableUsers;
