import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Space, Tooltip } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes, { number } from 'prop-types';
import { useContext, useState } from 'react';

const defaultItems = [
  {
    label: 'Excel',
    key: 'xlsx',
  },
  {
    label: 'CSV',
    key: 'csv',
  },
];
function ButtonDownload(props) {
  const { onClickEvent, size, timeOutLoading, items, buttonName, showTooltip, showIcon } = props;
  const [clicked, setClicked] = useState(false);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'locations' };
  const handleClick = (e) => {
    setClicked(true);
    onClickEvent(e);
    setTimeout(() => {
      setClicked(false);
    }, timeOutLoading);
  };

  const buttonComponent = (
    <Button size={size} loading={clicked}>
      <Space>
        {showIcon && <FontAwesomeIcon icon={['fas', 'download']} fontSize={14} />}
        {buttonName}
      </Space>
    </Button>
  );
  if (showTooltip) {
    return (
      <Dropdown menu={{ items, onClick: handleClick }} disabled={clicked}>
        <div style={{ display: 'initial' }}>
          <Tooltip placement="left" title={i18n.t('download', scopeI18n)}>
            {buttonComponent}
          </Tooltip>
        </div>
      </Dropdown>
    );
  }

  return (
    <Dropdown menu={{ items, onClick: handleClick }} disabled={clicked}>
      {buttonComponent}
    </Dropdown>
  );
}

ButtonDownload.propTypes = {
  onClickEvent: PropTypes.func,
  size: PropTypes.string,
  timeOutLoading: number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  buttonName: PropTypes.string,
  showTooltip: PropTypes.bool,
  showIcon: PropTypes.bool,
};

ButtonDownload.defaultProps = {
  onClickEvent: () => {},
  size: 'large',
  timeOutLoading: 1500,
  items: defaultItems,
  buttonName: 'Descargar',
  showTooltip: false,
  showIcon: true,
};

export default ButtonDownload;
