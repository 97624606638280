import { Col, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';

import image1 from 'assets/img/help/simple/requirements-simple1.png';
import image2 from 'assets/img/help/simple/requirements-simple2.png';
import image3 from 'assets/img/help/simple/requirements-simple3.png';
import image4 from 'assets/img/help/simple/requirements-simple4.png';
import IntroComponent from 'components/Help/Detail/common/introComponent';

const { Text, Title } = Typography;

function RequirementsSimple(props) {
  const { downloadExampleFile, helpBreadcrumbs } = props;
  const mainText = ' Si decides subir una demanda de tipo simple';

  return (
    <>
      {helpBreadcrumbs()}
      <IntroComponent
        title="Requisitos del formato simple"
        type="simple"
        downloadExampleFile={downloadExampleFile}
        mainText={mainText}
      />
      <Row gutter={[12, 12]} justify={'center'}>
        <Col md={{ span: 16 }} xs={{ span: 24 }}>
          <Title level={2}>En caso de ubicación</Title>
          <li>
            <Text>
              Si tienes ingresado el código de visita, la latitud y longitud, y la dirección
              completa, el sistema tomará como referencia el código de visita y usará la información
              almacenada en el sistema.
            </Text>
          </li>
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }} align="middle">
          <img src={image1} alt={'img1'} className="help-howtoimport-image1" />
        </Col>
        <Col md={{ span: 16 }} xs={{ span: 24 }}>
          <Space direction="vertical" size="middle">
            <Col md={{ span: 24 }}>
              <Text strong>Nota: </Text>
              <Text>
                Si activas la opción “Georeferenciar todos los registros”, el sistema tomará en
                cuenta la dirección completa para todos los registros y georeferenciará.
              </Text>
            </Col>

            <Col md={{ span: 24 }} align="middle">
              <img src={image2} alt={'img2'} className="help-howtoimport-image2" />
            </Col>
            <Col span={24}>
              <ol className="help-ol">
                <li className="help-li">
                  <p>
                    <Text strong>Conjunto: </Text>
                    <Text style={{ fontWeight: '400' }}>
                      En formato numérico y texto (alfanumérico), esto es obligatorio y esto es para
                      agrupar demandas de visitas.
                    </Text>
                    <br />
                    <br />
                    <Text strong>Nota: </Text>
                    <Text>
                      Los conjuntos tienen que ser diferentes en la fecha y hora.
                      <br />
                    </Text>
                    <Text>
                      En el caso de que los conjuntos tengan la misma fecha se arrojará como error:
                      “Tienen más de una fecha asignada”. Ejemplo:
                    </Text>
                  </p>
                  <Col align="middle">
                    <img src={image3} alt={'img3'} className="help-howtoimport-image1" />
                  </Col>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> Código visita *: </Text>
                    <Text>
                      En formato numérico y texto (alfanumérico) y es obligatorio, recuerda que debe
                      de existir o estar creado en el sistema, (para que asi mande a llamar).
                    </Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> Documento: </Text>
                    <Text>En formato numérico y texto (alfanumérico).</Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong>Ruta *: </Text>
                    <Text>En formato numérico y es obligatorio.</Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> Orden *: </Text>
                    <Text>
                      El valor es numérico y es obligatorio. Esto hace referencia al orden que
                      tendría tus visitas.
                    </Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> Cargas: </Text>
                    <Text>
                      El valor es numérico o decimal. En el caso de que coloques una carga en
                      decimal, recuerda que lo tienes que separar con una (,). Ejemplo: 12,3.
                    </Text>
                    <br />
                    <Text strong>Nota: </Text>
                    <Text>La carga 1 es obligatoria* y las demás son opcionales.</Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> Tiempo parada*: </Text>
                    <Text>
                      En formato numérico y es obligatorio. Esto significa el tiempo de espera del
                      vehículo en una visita.
                    </Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong>Acción*: </Text>
                    <Text>Formato texto y es obligatorio.</Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> Código Vehículo: </Text>
                    <Text>
                      Texto libre, es opcional. En el caso de que ingrese un código de vehículo
                      existente aparecerá asignada en la ruta creada, en el caso de que no, quedará
                      por asignar.
                    </Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> DNI Conductor: </Text>
                    <Text>
                      En formato numérico y texto (alfanumérico). En el caso de que ingrese un DNI
                      conductor existente aparecerá asignada en la ruta creada, en el caso de que
                      no, quedará por asignar.
                    </Text>
                    <br />
                    <Text strong>Nota: </Text>
                    <Text>Recuerda que solo se puede asignar usuario con el rol conductor.</Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> Código Base*: </Text>
                    <Text>
                      En formato numérico y texto (alfanumérico) es obligatorio, recuerda que debe
                      de existir o estar creado en el sistema, (para que asi mande a llamar).
                    </Text>
                  </p>
                </li>
                <li className="help-li">
                  <p>
                    <Text strong> Fecha*: </Text>
                    <Text>
                      Formato numérico y texto (alfanumérico) es obligatorio*. Las fecha y hora
                      tienen que ser diferentes a los conjuntos. Ejemplo:
                    </Text>
                  </p>
                  <Col align="middle">
                    <img src={image4} alt={'img4'} className="help-howtoimport-image1" />
                  </Col>
                </li>
              </ol>
            </Col>
          </Space>
        </Col>
      </Row>
    </>
  );
}

RequirementsSimple.defaultProps = {
  downloadExampleFile: () => {},
  helpBreadcrumbs: () => {},
};

RequirementsSimple.propTypes = {
  downloadExampleFile: PropTypes.func,
  helpBreadcrumbs: PropTypes.func,
};

export default RequirementsSimple;
