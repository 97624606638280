import { App, Button, Card, Col, Form, Row, Space } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext, useEffect, useState } from 'react';
import { defineComponent, isObject } from 'services/repeated-functions';
import TableFilterAdvanced from './Advanced';
import { TableFilterPropType } from './tableFilter.propTypes';

/**
 * Function to create component filter to tables
 * @param { * } props include setDatacallback, fieldsFilter, showTitle, triggerClean, reduxFunc, extraParams and setDownloadQuery
 * extraParams: this can be any parameter which is added to the filter's query
 * setDownloadQuery: is used when need same query to filter and download data
 * @returns Card element with fields as data input to filter
 */
function TableFilters(props) {
  const {
    setDataCallback,
    fieldsFilter,
    advancedFieldsFilter,
    showTitle,
    triggerClean,
    reduxFunc,
    extraParams,
    setDownloadQuery,
    paginationData,
    filterIsLoading,
  } = props;
  const { setTotalData, setActualPage } = paginationData;
  const usePagination = setTotalData !== undefined;
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const [advancedFormVisible, setAdvancedFormVisible] = useState(false);

  const isDate = (fieldName) => {
    // add others types
    const typesDate = ['range'];
    const fieldsDate = fieldsFilter.map((field) => {
      return typesDate.includes(field.type) ? field.name : null;
    });
    return fieldsDate.includes(fieldName);
  };

  const buildQuery = (values) => {
    let query = '';
    const fieldsForm = Object.keys(values);
    const fieldsAsQuery = fieldsForm.map((field) => {
      let valueField = values[field];
      if (valueField) {
        if (isDate(field)) {
          valueField = valueField?.map((date) => {
            // format: '2023-02-03T16:00:00-03:00'
            return date.format();
          });
        } else if (isObject(valueField)) {
          const valueFieldOb = Object.keys(valueField)
            .map((fieldKey) => {
              return `${fieldKey}=${valueField[fieldKey].join(',')}`;
            })
            .join('&');
          return valueFieldOb;
        }
        return `${field}=${valueField}`;
      }
      return undefined;
    });
    query = fieldsAsQuery.filter((element) => element !== undefined).join('&');
    return query.length > 0 ? `?${query}` : '';
  };

  const fetchDataFiltered = async (params = '') => {
    let query = params;
    if (extraParams) {
      query += params.length > 0 ? `&${extraParams}` : `?${extraParams}`;
    }
    setDownloadQuery(query);
    try {
      const result = await reduxFunc(query).unwrap();
      if (usePagination) {
        const { data, total } = result;
        setDataCallback(data);
        setTotalData(total);
      } else {
        setDataCallback(result);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const onFinish = (values) => {
    const query = buildQuery(values);
    if (usePagination) {
      setActualPage(1);
    }
    fetchDataFiltered(query);
  };

  const onReset = async () => {
    form.resetFields();
    if (usePagination) {
      setActualPage(1);
    }
    fetchDataFiltered();
  };

  const showAdvancedFilters = async () => {
    setAdvancedFormVisible(true);
  };

  const handleChanges = () => {
    form.submit();
  };

  useEffect(() => {
    const resetFilter = () => {
      form.resetFields();
    };
    if (triggerClean) {
      resetFilter();
    }
  }, [triggerClean, form]);

  return (
    <>
      <Col style={{ marginBottom: '1rem', paddingLeft: 0, paddingRight: 0 }}>
        <Card title={showTitle ? 'Filtros' : undefined} size="small">
          <Form form={form} onFinish={onFinish} autoComplete="off">
            <Row gutter={24}>
              {fieldsFilter.map((field) => {
                return defineComponent(field, form, handleChanges);
              })}
              <Col flex="auto">
                <Space className="float-right">
                  {advancedFieldsFilter.length > 0 && (
                    <Button onClick={showAdvancedFilters}>{i18n.t('commons.advanced')}</Button>
                  )}
                  <Button onClick={onReset}>{i18n.t('commons.clear')}</Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <TableFilterAdvanced
        isVisible={advancedFormVisible}
        setVisible={setAdvancedFormVisible}
        advancedFieldsFilter={advancedFieldsFilter}
        reduxFunc={reduxFunc}
        setDownloadQuery={setDownloadQuery}
        paginationData={{ setTotalData, setActualPage }}
        setDataCallback={setDataCallback}
        filterIsLoading={filterIsLoading}
      />
    </>
  );
}

TableFilters.defaultProps = {
  setDataCallback: () => {
    console.warn('undefined setDatacallback');
  },
  fieldsFilter: [],
  advancedFieldsFilter: [],
  showTitle: true,
  triggerClean: false,
  extraParams: null,
  setDownloadQuery: () => {},
  paginationData: {
    setTotalData: undefined,
    setActualPage: undefined,
  },
  filterIsLoading: false,
};

TableFilters.propTypes = TableFilterPropType;

export default TableFilters;
