import apiSlice from 'app/api/apiSlice';

/**
 * authorizationApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const authorizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActionsFromUser: builder.mutation({
      query: () => ({
        url: '/authorizations/get-actions',
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'ActionsUser', id })) : ['ActionsUser'],
    }),
  }),
});

export const { useGetActionsFromUserMutation } = authorizationApiSlice;
