import { DatePicker } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { FormPropType } from '../tableFilter.propTypes';

const { RangePicker } = DatePicker;
const limitYear = 10;
const maxDayRange = 30 - 1;

function RangePickerFilter(props) {
  const { nameField, handleChanges, showTime, placeholder, format, form } = props;
  const [dates, setDates] = useState(null);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();

  const yearsBefore = dayjs().subtract(limitYear, 'y');
  const yearsAfter = dayjs().add(limitYear, 'y');
  const disabledDate = (current) => {
    if (!dates) {
      return (
        (current && current < yearsBefore.endOf('day')) ||
        (current && current > yearsAfter.endOf('day'))
      );
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > maxDayRange;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > maxDayRange;
    return !!tooEarly || !!tooLate;
  };

  const customChange = (e) => {
    form.setFieldsValue({ [nameField]: e });
    handleChanges(e);
  };

  return (
    <RangePicker
      name={nameField}
      placeholder={placeholder || [i18n.t('commons.startDate'), i18n.t('commons.endDate')]}
      format={format}
      showTime={showTime}
      disabledDate={disabledDate}
      onCalendarChange={(val) => setDates(val)}
      onChange={customChange}
    />
  );
}

RangePickerFilter.defaultProps = {
  handleChanges: () => {
    console.warn('undefined handleChanges');
  },
  nameField: 'RangePicker',
  showTime: true,
  placeholder: undefined,
  format: 'DD-MM-YYYY HH:mm',
  form: {},
};

RangePickerFilter.propTypes = {
  handleChanges: PropTypes.func,
  nameField: PropTypes.string,
  showTime: PropTypes.bool,
  placeholder: PropTypes.arrayOf(PropTypes.string),
  format: PropTypes.string,
  form: FormPropType,
};

export default RangePickerFilter;
