import {
  App,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import LogoForm from 'components/Organizations/Configurations/General/LogoForm';
import SubmitButton from 'components/common/SubmitButton';
import TitleHelp from 'components/common/TitleHelp';
import {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} from 'features/organizations/organizationsApiSlice';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { getTimezonesWithOffset } from 'services/repeated-functions';
import { organizationType } from 'types';

const { Text } = Typography;
const timeZones = getTimezonesWithOffset();
const defaultActions = [
  { label: 'Llevada', value: 'delivery' },
  { label: 'Recogida', value: 'pickup' },
];

function OrganizationsCreationStepsGeneral(props) {
  const { setStepsStatus, current, setCurrent, organization, isLoading } = props;
  const [form] = Form.useForm();
  const [createOrganization, { isLoading: isCreating }] = useCreateOrganizationMutation();
  const [updateOrganization, { isLoading: isUpdating }] = useUpdateOrganizationMutation();
  const navigate = useNavigate();
  const { message } = App.useApp();

  const onFinish = async (values) => {
    const { logo } = values;
    const body = {
      ...values,
      type: 'EZ',
      creationStep: 2,
      creationStatus: 'INCOMPLETE',
    };

    if (organization) {
      await updateOrganization({
        organizationId: organization.id,
        logo,
        body,
      })
        .unwrap()
        .then((payload) => {
          message.success('Formulario enviado correctamente');
          setCurrent(current + 1);
          navigate(`/organizations/creation-steps/${payload?.id}/${current + 1}`);
        })
        .catch((error) => {
          console.error(error);
          setStepsStatus('error');
          message.error('Error al guardar el formulario');
        });
    } else {
      await createOrganization({ logo, body })
        .unwrap()
        .then((payload) => {
          message.success('Formulario enviado correctamente');
          setCurrent(current + 1);
          navigate(`/organizations/creation-steps/${payload?.id}/${current + 1}`);
        })
        .catch((error) => {
          console.error(error);
          setStepsStatus('error');
          message.error('Error al guardar el formulario');
        });
    }
  };
  const onFinishFailed = () => {
    message.error('Revise el formulario');
  };

  const initialValues = {
    timezone: 'America/Santiago',
  };
  if (isLoading) return <Skeleton paragraph={{ rows: 10 }} />;
  return (
    <Form
      form={form}
      name="createOrganization"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ ...initialValues, ...organization }}
    >
      <Row justify="start" gutter={16}>
        <Col span={4}>
          <Form.Item
            label="Nombre"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Nombre" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Nombre de fantasía" name="fantasyName">
            <Input placeholder="Nombre de fantasía" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <LogoForm url={organization?.logoUrl} />
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item
            label="Zona horaria"
            name="timezone"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select name="timezone" showSearch allowClear options={timeZones} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
        <Text strong>Parametrizar</Text>
      </Divider>
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <TitleHelp
                title="Acción por defecto"
                helpText="Acción por defecto al momento de parametrizar"
              />
            }
            name="defaultAction"
            rules={[
              {
                required: true,
                message: 'Por favor ingresar acción por defecto',
              },
            ]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {defaultActions.map(({ label, value }) => {
                  return (
                    <Radio key={value} value={value}>
                      {label}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12} />
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            <SubmitButton isLoading={isCreating || isUpdating} />
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

OrganizationsCreationStepsGeneral.defaultProps = {
  organization: null,
  isLoading: false,
};

OrganizationsCreationStepsGeneral.propTypes = {
  setStepsStatus: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
  organization: organizationType,
  isLoading: PropTypes.bool,
};

export default OrganizationsCreationStepsGeneral;
