import { VehiclePositionPropTypes } from 'components/Vehicles/vehicles.propTypes';
import PropTypes from 'prop-types';
import { RoutePropTypes, itemType } from 'types';
import MapContent from './Content';
import MapDetail from './Detail';
import MapHeader from './Header';

const finishedStatus = ['SUCCESS', 'FAILURE'];

function TrackingMap(props) {
  const { route, item, isConnected, vehiclesPositionEvents } = props;
  const { events } = item;
  const finishedEvent = events.find((event) => finishedStatus.includes(event?.status));
  return (
    <>
      <MapHeader route={route} item={item} finishedEvent={finishedEvent} />
      <MapContent
        route={route}
        item={item}
        finishedEvent={finishedEvent}
        isConnected={isConnected}
        vehiclesPositionEvents={vehiclesPositionEvents}
      />
      <MapDetail item={item} finishedEvent={finishedEvent} />
    </>
  );
}

TrackingMap.propTypes = {
  route: RoutePropTypes.isRequired,
  item: itemType.isRequired,
  isConnected: PropTypes.bool.isRequired,
  vehiclesPositionEvents: PropTypes.arrayOf(VehiclePositionPropTypes).isRequired,
};

export default TrackingMap;
