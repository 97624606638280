import PropTypes from 'prop-types';

const RoutingParametersPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  updatedAt: PropTypes.string,
  label: PropTypes.string,
});

export default RoutingParametersPropTypes;
