import apiSlice from 'app/api/apiSlice';

/**
 * routingParametersApiSlice
 * * Función basada en RTK Query que hereda el wrapper fetchBaseQuery
 * * del archivo apiSlice
 */

export const routingParametersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoutingParameters: builder.query({
      query: (query) => `/routing-parameters${query}`,
      providesTags: (result) =>
        result
          ? [
              result.map(({ id }) => ({ type: 'RoutingParameters', id })),
              { type: 'RoutingParameters', id: 'LIST' },
            ]
          : [{ type: 'RoutingParameters', id: 'LIST' }],
    }),
    getRoutingParametersByFilters: builder.mutation({
      query: (query) => `/routing-parameters${query}`,
      providesTags: (result) =>
        result
          ? [
              result.map(({ id }) => ({ type: 'RoutingParameters', id })),
              { type: 'RoutingParameters', id: 'LIST' },
            ]
          : [{ type: 'RoutingParameters', id: 'LIST' }],
    }),
    deleteOrRestoreParameter: builder.mutation({
      query: (id) => ({
        url: `routing-parameters/change-status/${id}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'RoutingParameters', id: 'LIST' }],
    }),
    createRoutingParameter: builder.mutation({
      query: (params) => ({
        url: '/routing-parameters',
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: ['LabelsRoutingParameters', { type: 'RoutingParameters', id: 'LIST' }],
    }),
    updateRoutingParameter: builder.mutation({
      query: (params) => ({
        url: `/routing-parameters/${params.id}`,
        method: 'PATCH',
        body: { ...params.body },
      }),
      invalidatesTags: ['LabelsRoutingParameters', { type: 'RoutingParameters', id: 'LIST' }],
    }),
    clearValues: builder.mutation({
      query: (params) => ({
        url: `/routing-parameters/clear-values`,
        method: 'POST',
        body: { ...params },
      }),
      invalidatesTags: [{ type: 'RoutingParameters', id: 'LIST' }],
    }),
    getProfilesFromParameters: builder.query({
      query: (query) => `/routing-parameters/get-profiles${query}`,
      providesTags: (_result, _error, id) => [{ type: 'ProfilesRoutingParameters', id }],
    }),
    getLabelsFromParameters: builder.query({
      query: (query) => `/routing-parameters/get-labels${query}`,
      providesTags: (_result, _error, id) => [{ type: 'LabelsRoutingParameters', id }],
    }),
  }),
});

export const {
  useGetRoutingParametersQuery,
  useGetRoutingParametersByFiltersMutation,
  useDeleteOrRestoreParameterMutation,
  useCreateRoutingParameterMutation,
  useUpdateRoutingParameterMutation,
  useClearValuesMutation,
  useGetProfilesFromParametersQuery,
  useGetLabelsFromParametersQuery,
} = routingParametersApiSlice;
