import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import TourContext from 'components/layout/TourComponent/TourContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../style.css';

function SidebarButton({ icon, text, isSelected, onClick, linkTo, moduleName }) {
  const [isHover, setIsHover] = useState(false);
  const ref = useRef(null);
  const { addReference } = useContext(TourContext);
  useEffect(() => {
    addReference(`${moduleName}Ref`, ref);
  }, [addReference, moduleName]);

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={isSelected || isHover ? 'sidebar-button hover' : 'sidebar-button'}
      ref={ref}
    >
      <Link onClick={onClick} to={linkTo}>
        <Space direction="vertical" align="center" size={4}>
          <FontAwesomeIcon icon={['fas', icon]} size="2x" style={{ marginTop: '10px' }} />
          <p>{text}</p>
        </Space>
      </Link>
    </div>
  );
}

SidebarButton.defaultProps = {
  onClick: () => {},
};

SidebarButton.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  linkTo: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
};

export default SidebarButton;
