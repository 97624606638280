import { Drawer, Form, Typography } from 'antd';
import FooterDrawer from 'components/common/FooterDrawer';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { getTitleToDrawer } from 'services/repeated-functions';
import RoutingParametersForm from './Form';
import ServiceParameterForm from './ServiceParameterForm';
import RoutingParametersShow from './Show';
import RoutingParametersPropTypes from './routingParameters.propTypes';

const { Text } = Typography;

function RoutingParametersDrawer(props) {
  const {
    drawerVisible,
    setDrawerParameter,
    typeDrawer,
    parameter,
    profiles,
    organizationId,
    labels,
    setTriggerClean,
    drawerEntity,
  } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();

  const onClose = (event) => {
    event?.stopPropagation();
    setDrawerParameter(false);
  };

  const title = <Text strong>{getTitleToDrawer(typeDrawer, 'parámetro', parameter.name)}</Text>;

  const footer = (
    <FooterDrawer
      onClose={onClose}
      typeDrawer={typeDrawer}
      form={form}
      entity={parameter}
      buttonLoading={buttonLoading}
    />
  );
  const renderForm = () => {
    if (drawerEntity === 'routingParameter') {
      return (
        <RoutingParametersForm
          onCloseDrawer={onClose}
          parameter={parameter}
          form={form}
          setButtonLoading={setButtonLoading}
          drawerVisible={drawerVisible}
          profiles={profiles}
          organizationId={organizationId}
          labels={labels}
          setTriggerClean={setTriggerClean}
        />
      );
    }
    return (
      <ServiceParameterForm
        onCloseDrawer={onClose}
        parameter={parameter}
        form={form}
        organizationId={organizationId}
        labels={labels}
        setButtonLoading={setButtonLoading}
        drawerVisible={drawerVisible}
        setTriggerClean={setTriggerClean}
      />
    );
  };

  return (
    <Drawer
      title={title}
      width="35%"
      onClose={(event) => onClose(event)}
      open={drawerVisible}
      destroyOnClose
      style={{ body: { paddingBottom: 80 } }}
      maskClosable={false}
      footer={footer}
      closable={false}
    >
      {['create', 'edit'].includes(typeDrawer) ? (
        renderForm()
      ) : (
        <RoutingParametersShow onCloseDrawer={onClose} parameter={parameter} />
      )}
    </Drawer>
  );
}

RoutingParametersDrawer.propTypes = {
  drawerVisible: PropTypes.bool,
  setDrawerParameter: PropTypes.func,
  typeDrawer: PropTypes.string,
  parameter: RoutingParametersPropTypes,
  profiles: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  organizationId: PropTypes.number,
  setTriggerClean: PropTypes.func,
  drawerEntity: PropTypes.string,
};

RoutingParametersDrawer.defaultProps = {
  drawerVisible: false,
  typeDrawer: 'create',
  setDrawerParameter: () => {},
  parameter: {},
  profiles: [],
  labels: [],
  organizationId: null,
  setTriggerClean: () => {},
  drawerEntity: 'routingParameter',
};

export default RoutingParametersDrawer;
