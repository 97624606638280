import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Divider, Row } from 'antd';
import dayjs from 'dayjs';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConvertToMinutes } from 'services/repeated-functions';
import CurrentTime from './CurrentTime';
import HeaderData from './HeaderData';
import HeaderDrawDay from './HeaderDrawDay';
import HeaderDrawTime from './HeaderDrawTime';
import RouteData from './RouteData';
import RouteDraw from './RouteDraw';
import {
  SynopticData,
  SynopticRelativeRoutes,
  SynopticSettings,
} from './SynopticSettings.propTypes';
import './style.css';

// TODO: add new prop to know if drag and drop is active or not

// All temporal events & ticks will be calculated in minutes.
// Returns the synoptic with all it's components.
function SynopticBase(props) {
  const {
    userSettings,
    setUserSettings,
    pxOnTick,
    vSpaceHeader,
    vSpaceRoute,
    hSpaceDrawBorder,
    preferedDataWidth,
    data,
    relativeRoutes,
    showRoutingSetName,
    showAvatar,
    routesColorBy,
    showRouteDetails,
    eta,
  } = props;
  const {
    referenceDate,
    shownTimeBackward,
    shownTimeForward,
    shownTimeReference,
    minimalTick,
    minimalTickReference,
    showRouteNumber,
  } = userSettings;

  const { timezone } = useSelector(selectCurrentOrganization);

  const synopticElement = useRef(null);
  const [hScrollDayText, setHScrollDayText] = useState(0);
  const [colDataWidth, setColDataWidth] = useState(0);
  const [colDrawWidth, setColDrawWidth] = useState(0);
  const [displayHeight, setDisplayHeight] = useState(0);
  const [routingSets, setRoutingSets] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showEta, setShowEta] = useState(eta);

  useEffect(() => {
    const intervealId = setInterval(() => {
      setCurrentTime(new Date());
    }, 5000); // updates every 5 sec.

    return () => {
      clearInterval(intervealId);
    };
  }, []);

  // convert dayjs to string
  const realReferenceDate = referenceDate instanceof dayjs ? referenceDate.format() : referenceDate;

  // Start and end date setup based on the userSettings var provided.
  const startTime = dayjs(realReferenceDate)
    .tz(timezone)
    .subtract(shownTimeBackward, shownTimeReference);
  const endTime = dayjs(realReferenceDate).tz(timezone).add(shownTimeForward, shownTimeReference);

  useEffect(() => {
    setShowEta(eta && dayjs().isBefore(endTime));
  }, [eta, endTime]);

  // Set the span for the data & draw area base in the showRouteNumber var.
  const [currentDataWidth, setCurrentDataWidth] = useState(showRouteNumber ? preferedDataWidth : 1);

  // General distance settings
  const routesShown = data.length; // amount.
  const fullHeaderSpace = vSpaceHeader * 2; // pixels
  const svgHeight = (routesLength) =>
    routesLength === 0 ? displayHeight : vSpaceRoute * routesLength;

  useEffect(() => {
    setRoutingSets(
      data.reduce((accumulator, obj) => {
        const { routingSetId } = obj;
        if (!accumulator[routingSetId]) {
          accumulator[routingSetId] = [];
        }
        accumulator[routingSetId].push(obj);
        return accumulator;
      }, {})
    );
  }, [data]);

  // Vars based on start & end time of the route
  const diff = Math.abs(endTime - startTime);
  const MinutesBtwn = diff === 0 ? 0 : Math.floor(diff / 60000);

  // Width of used by the route.
  const [drawSvgWidth, setDrawSvgWidth] = useState(
    (MinutesBtwn / ConvertToMinutes(minimalTick, minimalTickReference)) * pxOnTick +
      hSpaceDrawBorder * 2
  );

  // Width of the route draw representation area
  const svgWidth = useMemo(() => {
    if (drawSvgWidth < colDrawWidth || routesShown === 0) {
      return colDrawWidth || 0;
    }
    return drawSvgWidth || 0;
  }, [drawSvgWidth, colDrawWidth, routesShown]);

  // Sets the amount of time ticks to be displayed on the draw area header
  const ticksShown = useMemo(() => {
    if (drawSvgWidth < colDrawWidth || routesShown === 0) {
      return colDrawWidth / pxOnTick;
    }
    return MinutesBtwn / ConvertToMinutes(minimalTick, minimalTickReference);
  }, [
    colDrawWidth,
    pxOnTick,
    drawSvgWidth,
    routesShown,
    MinutesBtwn,
    minimalTick,
    minimalTickReference,
  ]);

  // Scroll event to update the value for the HeaderDrawDay component
  const handleScroll = (event) => {
    setHScrollDayText(event.target.scrollLeft);
  };

  // Method sets the current draw svg width based on the difference of minutes between the starts & ends time.
  const updateDrawSvgWidth = useCallback(() => {
    const tick = MinutesBtwn / ConvertToMinutes(minimalTick, minimalTickReference);
    setDrawSvgWidth(tick * pxOnTick + hSpaceDrawBorder * 2);
  }, [pxOnTick, hSpaceDrawBorder, MinutesBtwn, minimalTick, minimalTickReference]);

  // Method update the col widths of the data & draw area on call, the visible part NOT the width of the svg canvas.
  const getColWidths = useCallback(() => {
    if (synopticElement.current) {
      setColDataWidth(synopticElement.current.clientWidth * (currentDataWidth / 100));
      setColDrawWidth(synopticElement.current.clientWidth * ((100 - currentDataWidth) / 100));
    }
    if (synopticElement.current && displayHeight === 0) {
      setDisplayHeight(synopticElement.current.clientHeight);
    }
  }, [displayHeight, currentDataWidth]);

  const hideRoutesNumbers = () => {
    setUserSettings({ ...userSettings, showRouteNumber: !showRouteNumber });
  };

  // Gets current data & draw area col widths on render
  useEffect(() => {
    getColWidths();
  });

  // Updates the current draw svg width, used for when the settings are changed.
  useEffect(() => {
    updateDrawSvgWidth();
  }, [MinutesBtwn, updateDrawSvgWidth]);

  // Updates the col value for the data & draw area for when the window is resized
  useEffect(() => {
    const handleResize = () => {
      getColWidths();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getColWidths]);

  // Updates the data area span value for when the option of hide it (showRouteNumber) is enabled/disabled on the settings.
  useEffect(() => {
    setCurrentDataWidth(showRouteNumber ? preferedDataWidth : 2);
  }, [showRouteNumber, preferedDataWidth, setCurrentDataWidth]);

  // NOTE: The upper the component inside the SVG tag the closer will be drawn to the background
  return (
    <Row
      className="base-synoptic-style"
      style={{
        overflow: routesShown === 0 ? 'hidden' : 'auto',
      }}
      onScroll={(event) => handleScroll(event)}
      ref={synopticElement}
    >
      {/* DATA AREA */}
      <Col className="sticky-element column-data" style={{ width: `${currentDataWidth}%` }}>
        <div className="sticky-element header data-area">
          <HeaderDrawDay
            startTime={startTime}
            endTime={endTime}
            ticksShown={ticksShown}
            minimalTick={minimalTick}
            minimalTickReference={minimalTickReference}
            hScrollDayText={hScrollDayText}
            vSpaceHeader={vSpaceHeader}
            colDataWidth={colDataWidth}
            colDrawWidth={colDrawWidth}
          />
          <HeaderData vSpaceHeader={vSpaceHeader} fullHeaderSpace={fullHeaderSpace} />
          <Button
            className="chevron-button"
            onClick={() => hideRoutesNumbers()}
            icon={<FontAwesomeIcon icon={showRouteNumber ? faChevronLeft : faChevronRight} />}
          />
        </div>
        <div className="routing-sets-container">
          {Object.entries(routingSets).map(([routingSetId, routingSet]) => {
            const routingSetName =
              routingSet[0].routing_set && routingSet[0].routing_set.name?.length > 20
                ? `${routingSet[0]?.routing_set?.name?.slice(0, 20)}...`
                : routingSet[0]?.routing_set?.name;
            return (
              <div key={routingSetId}>
                {showRoutingSetName && (
                  <div
                    className="divider"
                    style={{ borderRight: '1px solid grey' }}
                    title={showRouteNumber && routingSet[0]?.routing_set?.name}
                  >
                    <Divider orientation="left">{showRouteNumber && routingSetName}</Divider>
                  </div>
                )}
                <RouteData
                  data={routingSet}
                  svgHeight={svgHeight(routingSet.length)}
                  showRouteNumber={showRouteNumber}
                  maxNameChar={Math.floor(colDataWidth / 14 - 1)}
                  hideColorLine={showRoutingSetName}
                  showAvatar={showAvatar}
                  showRouteDetails={showRouteDetails}
                />
              </div>
            );
          })}
        </div>
      </Col>
      {/* DRAW AREA */}
      <Col style={{ width: `${100 - currentDataWidth}%` }}>
        <div className="sticky-element header">
          <svg width={svgWidth} height={fullHeaderSpace}>
            <HeaderDrawTime
              startTime={startTime}
              endTime={endTime}
              ticksShown={ticksShown}
              minimalTick={minimalTick}
              minimalTickReference={minimalTickReference}
            />
          </svg>
        </div>
        <div className="routing-sets-container">
          {Object.entries(routingSets).map(([routingSetId, routingSet]) => (
            <div key={routingSetId}>
              {showRoutingSetName && (
                <div className="divider" style={{ width: svgWidth }}>
                  <svg style={{ width: svgWidth, height: '100%' }}>
                    <foreignObject style={{ width: svgWidth, height: '100%' }}>
                      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                        <Divider />
                      </div>
                    </foreignObject>
                    <CurrentTime
                      startTime={startTime}
                      endTime={endTime}
                      routesShown={routesShown}
                      ticksShown={ticksShown}
                      currentTime={currentTime}
                    />
                  </svg>
                </div>
              )}
              <svg width={svgWidth} height={svgHeight(routingSet.length)}>
                <RouteDraw
                  data={routingSet}
                  relativeRoutes={relativeRoutes}
                  startTime={startTime}
                  endTime={endTime}
                  svgWidth={svgWidth}
                  svgHeight={svgHeight(routingSet.length)}
                  displayHeight={displayHeight - fullHeaderSpace}
                  minimalTick={minimalTick}
                  minimalTickReference={minimalTickReference}
                  routesColorBy={routesColorBy}
                  currentTime={currentTime}
                  eta={showEta}
                />
                {routesShown > 0 && (
                  <CurrentTime
                    startTime={startTime}
                    endTime={endTime}
                    routesShown={routesShown}
                    ticksShown={ticksShown}
                    currentTime={currentTime}
                  />
                )}
              </svg>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
}
SynopticBase.defaultProps = {
  // *** User defined setings
  userSettings: {
    referenceDate: dayjs(), // Base date to be shown in the draw.
    shownTimeForward: 12, // Amount of time to be shown forward of the reference date
    shownTimeBackward: 0.5, // Amount of time to be shown backward of the reference date
    shownTimeReference: 'hours', // Set for a time convertion.
    showRouteNumber: true, // Bool to show or not the route number.
    minimalTick: 15, // 1, 5, 10, 15, 20, 30, 60 is recomended. 240 doesnt work quite well.
    minimalTickReference: 'minutes', // Set for time convertion.
  },
  setUserSettings: () => {},
  // *** Synoptic space definitions
  pxOnTick: 40, // pixels. 40px is used as minimum distance between time timeTicks.
  vSpaceHeader: 30, // pixels. 30px as minimum distance between upper horizontal lines of the header.
  vSpaceRoute: 70, // pixels. 70px as minumum vertical space for a route to display it's representation.
  hSpaceDrawBorder: 40, // pixels. 40px as minimum space between the start and end of the svg timeTicks space.
  preferedDataWidth: 20, // amount. Horizontal % definition for de data column, the rest with 100% will be aplied to the draw column.
  // *** Data to be drawn
  data: [], // Data to be shown and draw in the synoptic.
  relativeRoutes: [], // Data to be shown and draw in the synoptic when is not empty.
  showRoutingSetName: false, // Bool, gruo up routings sets or not
  showAvatar: false, // boolean, set the visibility of the driver's avatar
  routesColorBy: '', // string, set colors of routes (valid options: status, route-number)
  showRouteDetails: false, // bool, se the visibility of the show button for details of the route
  eta: false, // bool, show eta if internal conidtions apply
};
SynopticBase.propTypes = {
  userSettings: SynopticSettings,
  setUserSettings: PropTypes.func,
  pxOnTick: PropTypes.number,
  vSpaceHeader: PropTypes.number,
  vSpaceRoute: PropTypes.number,
  hSpaceDrawBorder: PropTypes.number,
  preferedDataWidth: PropTypes.number,
  data: SynopticData,
  relativeRoutes: SynopticRelativeRoutes,
  showRoutingSetName: PropTypes.bool,
  showAvatar: PropTypes.bool,
  routesColorBy: PropTypes.string,
  showRouteDetails: PropTypes.bool,
  eta: PropTypes.bool,
};

export default SynopticBase;
