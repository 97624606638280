import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

function UnauthorizedError() {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate('/');
  };
  return (
    <Result
      status="403"
      title="403"
      subTitle="Lo sentimos no estas autorizado para ver esta página.
      Prueba recargando la página o yendo a Inicio."
      extra={
        <Button type="primary" onClick={handleGoHome}>
          Ir a inicio
        </Button>
      }
    />
  );
}

export default UnauthorizedError;
