import { Button, Checkbox, Col, Form, Image, Input, Row } from 'antd';
import loginIcon from 'assets/img/login-icon.svg';
import loginPic from 'assets/img/login-image.png';
import Loading from 'components/common/Loading';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import { selectNotificationToken } from 'features/app/appSlice';
import { useLoginMutation } from 'features/auth/authApiSlice';
import { setCredentials } from 'features/auth/authSlice';
import { setCurrentUser } from 'features/users/userSlice';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userIsOnlyDriver } from 'services/repeated-functions';
import './style.css';

function LoginScreen() {
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [formError, setFormError] = useState();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const notificationToken = useSelector(selectNotificationToken);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const handleUserInput = (e) => setUser(e.target.value);
  const handlePwdInput = (e) => setPwd(e.target.value);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.account.login' };

  const onFinish = async () => {
    try {
      const userData = await login({ user, pwd, notificationToken }).unwrap();
      dispatch(setCredentials({ ...userData, user }));
      dispatch(setCurrentUser({ ...userData }));
      setUser('');
      setPwd('');
      const userIsDriver = userIsOnlyDriver(userData.userProfile);
      navigate(userIsDriver ? '/' : '/organizations');
    } catch (err) {
      const errMessage = i18n.t('errors.userOrPassword', scopeI18n);
      setFormError(errMessage);
    }
  };

  const theOptimalFooter = (
    <span>
      © {new Date().getFullYear()},{' '}
      <a
        href="https://theoptimalpartner.com"
        target="_blank"
        rel="noreferrer"
        title={i18n.t('goToOptimalWeb')}
      >
        {i18n.t('theOptimal')}
      </a>
    </span>
  );

  return !isLoading ? (
    <Row>
      <Col
        span={13}
        md={13}
        xs={0}
        style={{
          backgroundImage: `url(${loginPic})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
        }}
        className="login-left-content"
      >
        {theOptimalFooter}
      </Col>
      <Col span={11} xs={24} md={{ span: 9, offset: 1 }}>
        <div className="form-login-container">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Image
                src={loginIcon}
                alt="logo"
                title="Planners"
                preview={false}
                className="form-login-logo"
              />
            </Col>
            <Col span={24} xs={{ span: 22, offset: 1 }}>
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                name="login"
                initialValues={{
                  remember: true,
                }}
                autoComplete="off"
                requiredMark={false}
                style={{
                  maxWidth: 350,
                }}
              >
                <Form.Item
                  label={
                    <TitleHelp
                      title={i18n.t('user', scopeI18n)}
                      helpText={i18n.t('helps.user', scopeI18n)}
                    />
                  }
                  name="user"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('rules.user', scopeI18n),
                    },
                  ]}
                  validateStatus={formError ? 'error' : ''}
                  help={formError}
                >
                  <Input onChange={handleUserInput} size="large" />
                </Form.Item>

                <Form.Item
                  label={i18n.t('password', scopeI18n)}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('rules.password', scopeI18n),
                    },
                  ]}
                  validateStatus={formError ? 'error' : ''}
                  help={formError}
                >
                  <Input.Password onChange={handlePwdInput} size="large" />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox
                    style={{
                      flex: 'unset',
                    }}
                  >
                    {i18n.t('rememberme', scopeI18n)}
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '100%',
                    }}
                    size="large"
                  >
                    {i18n.t('loginButton', scopeI18n)}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Link to="/account/forgot-password" className="form-login-forgot-password">
                {i18n.t('forgotPasswordLink', scopeI18n)}
              </Link>
            </Col>
          </Row>
        </div>
      </Col>
      <Col md={0} xs={24} style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
        {theOptimalFooter}
      </Col>
    </Row>
  ) : (
    <Loading />
  );
}

export default LoginScreen;
