import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Space, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

/**
 * Function to create component title with question mark as popover with list
 * @param { * } props include title, content
 * @returns Text on title + icon question mark with popover with list in content
 */
function TitlePopover(props) {
  const { title, content } = props;
  const contentPopover = (
    <ul style={{ marginBottom: 0 }}>
      {content.map(({ strong, plain }, index) => {
        const keyLi = `li${index}`;
        return (
          <li span={24} key={keyLi}>
            <Text strong>{strong}: </Text>
            <Text>{plain}</Text>
          </li>
        );
      })}
    </ul>
  );
  return (
    <Space>
      {title}
      <Popover placement="right" content={contentPopover} overlayStyle={{ width: '400px' }}>
        <FontAwesomeIcon icon={['fas', 'question-circle']} color="#999999" />
      </Popover>
    </Space>
  );
}

TitlePopover.defaultProps = {
  title: '',
  content: [],
};

TitlePopover.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  content: PropTypes.arrayOf(
    PropTypes.shape({
      strong: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      plain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default TitlePopover;
