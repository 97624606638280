const spanishLocale = {
  es: {
    workingForTranslate:
      'Estamos trabajando por traducir el sistema a tu idioma prefererido, por favor espera un poco más',
    hello: 'Hola',
    spanish: 'Español',
    spanishAb: 'ESP',
    english: 'Inglés',
    englishAb: 'ENG',
    goToOptimalWeb: 'Ir al sitio web de The Optimal',
    theOptimal: 'The Optimal',
    form: {
      success: 'Formulario enviado correctamente',
      error: 'Formulario con errores, revisar',
      sendSuccess: 'Formulario enviado correctamente',
      checkForm: 'Revise el formulario',
      dataUpdated: 'Se han actualizado tus datos',
      fieldRequired: '¡Llene este campo!',
      permissionRequiredForSetting: 'No tiene permisos para modificar esta configuración',
      selectPlus: {
        addTo: `Agregar %{label}`,
        add: 'Agregar',
        typeForAddANewElement: 'Ingrese un texto para un nuevo %{element}',
      },
      customAttributes: {
        unrecognizedType: '**Tipo "%{dataType}" no tiene componente para dibujar!**',
      },
      parameterize: {
        title: 'Ingrese sus parámetros',
        fieldDisabled:
          'Para usar este campo debe habilitarlo en la configuración de la organización',
        defaultVehicle: 'Vehículo por defecto',
        times: 'Tiempos',
        buttonPrimary: 'Rutear y optimizar',
        rangeDeliveryDate: 'Fecha y hora de despacho',
        action: 'Tipo de acción',
        fixedDeliveryStopCost: 'Tiempo de parada',
        target: 'Objetivo',
        variableFleet: 'Flota variable',
        loadCardinale: 'Carga %{number}',
        geoserviceSpeedWeight: 'Ponderador de velocidad',
        deliveryDemandCostAndRates: 'Tiempo de recarga fijo',
        maxWaitingCost: 'Tiempo máximo de espera',
        deliveryCostPerLoadUnit: 'Tiempo de recarga por unidad',
        help: {
          maxWaitingCost: 'Tiempo máximo de espera antes de una ventana de atención',
          deliveryCostPerLoadUnit: 'Tiempo que toma el cargar una unidad de producto al vehículo',
          action: 'Determina si se debe priorizar el horario de inicio o término de ruta',
          fixedDeliveryStopCost: 'Tiempo de espera del vehículo por cada parada',
          target: 'Configuración de ruteo para generar las rutas',
          variableFleet:
            'Al estar activa considera los vehículos activos de su organización, al desactivar se considera un vehículo virtual adicional con cargas fijas',
          defaultVehicleLoads:
            'Valores que se asocian al vehículo virtual utilizado para optimizar el ruteo',
          geoserviceSpeedWeight:
            'Factor porcentual aplicado para reducir o aumentar la velocidad promedio del vehículo',
          deliveryDemandCostAndRates:
            'Tiempo fijo que toma la carga de todos los productos al vehículo',
        },
      },
      demandsImport: {
        buttonUpload: 'Cargar demandas',
        advancedOption: 'Avanzado',
        simpleOption: 'Simple',
        superAdvancedOption: 'Carga super avanzada',
        forStops: 'de visitas',
        forDepots: 'de bases',
        forVehicles: 'de vehículos',
        superAdvanced: 'Super avanzada',
        updateMassiveType: 'Actualización masiva %{type}',
        instructions: 'Instrucciones',
        helpText: 'Ayuda',
        help: {
          needHelpWithUpload: 'Necesitas ayuda con la carga de tus archivos',
          howImportLocationsOrDemands: '¿Cómo importar mis visitas o demandas?',
          colsFormatToPreventErrors: 'Formato de columnas para evitar errores',
          georeference: {
            enabled: 'Activado',
            disabled: 'Desactivado',
            disabledDetail: 'Se georreferencian sólo las paradas sin coordenadas.',
            enabledDetail: 'Se georreferencian todas las paradas, incluso con código de visita.',
          },
          maxSize: 'El peso máximo de un archivo que se puede cargar es de 5 MB.',
          maxRows:
            'Los archivos cargados deben contener un máximo de %{maxRows} registros (filas).',
          advancedOption:
            'Crea las demandas que deberás parametrizar para optimizar con la lógica de The Optimal',
          simpleOption: 'Crea directamente las rutas sin optimizar con la lógica de The Optimal',
          superAdvancedOption: '"No usar, es funcionalidad en desarrollo',
          remember: 'Recuerda',
          onlyCSVWithSeparator:
            'Puedes solamente subir archivos de tipo CSV (.csv) separados por punto y coma (";").',
          downloadExample: 'Descargue el archivo de ejemplo y reemplace los campos con sus datos.',
          caseForExcel: 'Si usas Excel, descarga el archivo de ejemplo Windows.',
          skipEmptyLines: 'No incluya filas vacías.',
          formatForMailPhone:
            'Los datos de contacto telefónico y correo electrónico deben cumplir con el formato `+XXXXXXXXXXX` (con comillas simples) y nombre@dominio.com siempre que la plantilla contenga alguno de estos.',
          skuAndLoas:
            'Si ingresa un SKU las cargas de la demandas serán la suma de las cargas de todos los SKU asociados',
          anyErrorCancel:
            'Cualquier error en la importación cancelará la operación completa y no se importará ningún registro.',
          onlyCsv: 'Puedes solamente subir archivos de tipo CSV (.csv)',
          formatForContactEmail:
            'Contacto `+XXXXXXXXXXX` [con comillas simples] (telefóno) y nombre@dominio.com (correo electrónico), formato obligatorio si la plantilla incluye alguno.',
          codesForEntity:
            'Los códigos ingresados deben corresponder a %{entityName} existentes y que no estén archivados.',
        },
        upload: {
          showDetails: 'Ver detalles',
          showErrorDetails: 'Ver detalle de errores',
          uploadFile: 'Subir archivo',
          delete: 'Eliminar',
        },
        drawerErrors: {
          retryUpload: 'Reintentar subida',
          errorsOnFile: 'Errores en el archivo',
          haveErrorsOnImporter: 'Tienes %{totalErrors} errores de importaciones',
          pleaseCheckDetails: 'Por favor, revise los detalles y vuelve a intentar la subida.',
          rowNumber: 'Fila %{number}',
        },
        simple: 'Simple',
        advanced: 'Avanzada',
        typeImport: 'Importación %{type}',
        exampleTemplate: 'Plantilla ejemplo',
        georeferenceAllEntries: 'Georreferenciar todos los registros',
        clickForUpload: 'Haga clic o arrastre el archivo a esta área para cargarlo',
        massiveUpdateOf: 'Actualización masiva de %{type}',
        errors: {
          validation: '%{fileName} subida de archivo fallida. Revise filas: %{errors}',
          fileUpload: '%{fileName} subida de archivo fallida.',
          fileFormat: 'No tiene formato válido (%{formats})',
          notFound: 'Archivo de ejemplo no encontrado',
          sizeFile: 'Supera el tamaño máximo (%{maxFileSize} MB)',
        },
        alerts: {
          somethingWentWrong: 'Algo salió mal.',
          processingRequest: 'Estamos procesando tu solicitud...',
          validatingFile: 'Validando archivo...',
          validationSuccess: 'Archivo %{fileName} validado correctamente.',
          validationFailed: 'Archivo %{fileName} no es valido.',
          fileUpload: '%{fileName} archivo subido correctamente.',
          fileWithErrorMessage:
            'No se puede cargar y procesar porque uno de los archivos tiene errores.',
          fileWithErrorDescription:
            'Elimina el archivo con errores y corrige los problemas, o elimina sólo ese archivo y carga los que están correctos.',
        },
        messages: {
          row: {
            zero: 'Archivo con errores',
            one: 'Fila %{number}',
            other: 'Fila %{number}',
          },
          requiredCols: {
            one: 'La columna %{details} no puede estar vacía.',
            other: 'Las columnas %{details} no pueden estar vacías.',
          },
          validateFormatRestrictions: {
            one: 'La columna %{details} tiene formato inválido.',
            other: 'Las columnas %{details} tienen formatos inválidos.',
          },
          validateDemandDetails: {
            one: 'La columna %{details} de producto es obligatoria.',
            other: 'Las columnas %{details} de producto son obligatorias.',
          },
          validateCoords: {
            one: '%{details} fuera de rango.',
            other: '%{details} fuera de rangos.',
          },
          validatePhone:
            'Los teléfonos no cumplen con el formato +XXXXXXXXXXX, recuerda usar comilla simple antes del símbolo + en tu archivo csv.',
          validateEmail:
            'Los correos electrónicos no cumplen con el formato nombre@dominio.com, ejemplo contacto@theoptimalpartner.com.',
          validateVisitsExist: 'Visita no existe.',
          validateDepotsExist: 'Base no existe.',
          validateSameDateOnRoutingSet: 'Existe más de una fecha asignada al conjunto.',
          validateVisitCodeUniqueness: 'Se encontró código ya existente.',
          validateVisitCodeUniquenessNotNumber: 'Se encontró código duplicado en el archivo.',
          validateVisitsExistCode: 'Visita no existe.',
          validateUniquenessCode: 'Se encontró código duplicado en el archivo.',
          validateBaseCodeUniqueness: 'Se encontró código ya existente.',
          validateBaseCodeUniquenessNotNumber: 'Se encontró código duplicado en el archivo.',
          validateBasesExistCode: 'Base no existe.',
          validateVehicleCodeUniqueness: 'Se encontró código ya existente.',
          validateVehicleCodeUniquenessNotNumber: 'Se encontró código duplicado en el archivo.',
          validateVehicleExistCode: 'Vehículo no existe.',
          validateMaxLoads: 'Se excede el máximo de %{details} capacidades.',
          column_mismatched:
            'La cantidad de columnas no es la correcta en comparación a la cabecera.',
          unknownError: 'Error desconocido: %{details}, favor contacte a soporte.',
          checkAmountOfRecords: 'El archivo supera la cantidad máxima de registros.',
          validateAllHeaders: 'Las cabeceras no coinciden con el archivo de ejemplo.',
          validateNotBlankFile: 'El archivo se encuentra sin filas.',
          wrong_delimiter:
            'El archivo no utiliza el formato correcto. Verifica los separadores, deben ser punto y coma (;).',
          conditionalRows:
            'Llene las columnas [%{leftCondition}] o [%{rightCondition}] o [%{specialCondition}].',
          conditionalRowsLeftRight: '%{leftCondition} o %{rightCondition}.',
          conditionalRowsLeftRightSpecial:
            '%{leftCondition} o %{rightCondition} o %{specialCondition}.',
          conditionalRowsMessages: {
            'Latitud-Longitud': 'Ingrese coordenadas de latitud y longitud válidas',
            'Pais-Region o Provincia-Comuna-Calle-Numero':
              'Ingrese una dirección válida y completa',
            Codigo: 'Verifique el código de visita. Proporcione un código registrado',
          },
        },
      },
      errors: {
        fileFormat: '%{fileName} no tiene formato válido (%{formats})',
      },
      changePassword: {
        changePassword: 'Cambiar contraseña',
        currentPassword: 'Contraseña actual',
        newPassword: 'Nueva contraseña',
        confirmNewPassword: 'Confirmar nueva contraseña',
        alerts: {
          changeSuccessfully: 'Se ha cambiado con éxito la contraseña',
        },
        errors: {
          wrongPassword: 'Contraseña incorrecta',
          minStrength: 'La contraseña no cumple con los requisitos',
          notEquals: '¡Las dos contraseñas que ingresó no coinciden!',
        },
        rules: {
          currentPassword: 'Ingresa tu contraseña actual',
          newPassword: 'Ingresa tu contraseña',
          confirmNewPassword: 'Confirmar contraseña',
        },
        strength: {
          mustHave: 'La contraseña debe tener',
          length: 'Mínimo 8 caracteres',
          uppercaseLetter: 'Mínimo 1 Mayúscula',
          lowercaseLetter: 'Mínimo una mínuscula',
          digit: 'Mínimo 1 dígito',
          specialCharacter: `Mínimo 1 símbolo (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | ~ \` + =)`,
        },
      },
      profile: {
        helps: {
          phoneNumber:
            'Este teléfono irá asociado a los correos electrónicos que se envíen a clientes',
        },
        errors: {
          name: 'Debe escribir un Nombre',
          lastName: 'Debe escibir un Apellido',
          phoneNumber: 'Debe contener sólo números, hasta 10 dígitos ej: 9123456789',
          email: 'No hay correo electrónico definido',
          roles: 'No hay roles asignados',
          relatedOrganizations: 'No hay organización asociada',
        },
      },
      location: {
        updatingLocation: 'Actualizando ubicación...',
        locationUpdated: 'La ubicación ha sido actualizada, recuerda guardar los cambios',
        fieldRequired: 'Este campo es obligatorio',
        code: 'Código',
        name: 'Nombre',
        category: 'Categoría',
        vip: 'Vip',
        location: 'Ubicación',
        localization: 'Localización',
        address: 'Dirección',
        number: 'Número',
        address2: 'Información adicional',
        geolocation: 'Geolocalización',
        latitude: 'Latitud',
        longitude: 'Longitud',
        additionalInformation: 'Información adicional',
        restrictions: 'Restricciones',
        geocodeAddress: 'Georreferenciar dirección',
        geocodeError: 'Error al georreferenciar',
        searchAddress: 'Buscador de dirección',
        contactInfo: 'Datos de contacto',
        phoneCount: 'N° teléfono %{count}',
        phone: 'N° telefónico',
        emailCount: 'Correo electrónico %{count}',
        email: 'Correo electrónico',
        rules: {
          code: 'Ya existe el código %{code}',
          phonePrefix: 'Debe seleccionar un país',
          phoneJustNumber: 'Debe contener sólo numeros, hasta 10 dígitos ej: 9123456789',
          phone: 'Debe escribir un nº telefónico',
          email: 'Debe escribir un correo electrónico',
          emailInvalid: 'No es un Email válido',
        },
        alert: {
          changeOnlyHere: 'Modificar datos de la visita no afectará las demandas asociadas.',
        },
        helps: {
          searchAddress: 'Busca en base a calle y número, recomendando coincidencias',
          typeToSearch: 'Escriba la dirección a buscar',
        },
        georeferenceAllEntries: 'Georreferenciar todos los registros',
        typeImport: 'Importación de %{type}',
        exampleTemplate: 'Plantilla ejemplo',
      },
      system: {
        updateSuccess: 'Datos actualizados correctamente',
        createSuccess: 'Datos creados correctamente',
        cannotUpdate: 'Los datos no pudieron ser actualizados',
        advancedInformation: 'Información avanzada',
        type: 'Tipo',
        totalRecordsForReports: 'Cantidad de registros en vista previa de Informe',
        serviceProvider: 'Proveedor de servicios',
        mapForDisplay: 'Mapa',
        storageService: 'Servicio de almacenamiento',
        serviceCollector: 'Trackpoints',
        label: 'Etiqueta',
        description: 'Descripción',
        name: 'Nombre',
        entities: 'Entidades',
        value: 'Valor',
        unit: 'Unidad',
        location: 'Ubicaciones',
        vehicle: 'Vehículo',
        depot: 'Base',
        stop: 'Visita',
        fieldName: 'Nombre del campo',
        options: 'Opciones',
        values: 'Valores',
        defaultValue: 'Valor por defecto (Opcional)',
        defaultValue1: 'Valor por defecto',
        enableForAllSystem: 'Habilitar para todo el sistema',
        relatedEntity: 'Entidad relacionada',
        helps: {
          type: 'Tipo de organización',
          totalRecordsForReports:
            'Determina el número de registros a mostrar en la vista previa de Reportes → Informes',
          mapForDisplay: 'Proveedor de mapa que se muestra',
          serviceCollector: 'Servicio que almacenará los trackpoints',
          description: 'Descripción',
          unit: 'Unidad',
          fieldName: 'Nombre del campo',
          enableForAllSystem: 'Habilitado para todas las entidades',
        },
        errors: {
          duplicated: 'Ya existe el valor %{value}',
          selectType: 'Seleccione un tipo',
          selectEntity: 'Seleccione una entidad',
        },
      },
      organization: {
        endpointRe: 'Endpoint del ruteador (RE)',
        urlRoutingEngine: 'URL de routing engine',
        versionRoutingEngine: 'Versión de routing engine',
        helps: {
          urlRoutingEngine: 'URL base de routing engine',
          versionRoutingEngine: 'Versión de routing engine a usar',
        },
        errors: {
          urlInvalid: 'URL de routing engine no es una url válida',
          versionRoutingEngine: 'Versión invalida, sólo acepta formato v[número]. Ej: v1, v2',
        },
      },
      account: {
        recover: {
          recoverPassword: 'Restablecer contraseña',
          code: 'Código',
          password: 'Contraseña',
          confirmPassword: 'Confirmar contraseña',
          rules: {
            code: 'Ingresa tu código',
            password: 'Ingresa tu contraseña',
          },
          errors: {
            minStrength: 'La contraseña no cumple con los requisitos',
            notEquals: '¡Las dos contraseñas que ingresó no coinciden!',
            wrongData: 'Datos incorrectos',
          },
          alerts: {
            passwordChanged: 'Contraseña actualizada correctamente.',
            enabledToLogin: 'Puede iniciar sesión con su nombre de usuario y contraseña.',
          },
        },
        forgot: {
          recoverPassword: 'Recuperar contraseña',
          userYourUser:
            'Ingresa tu nombre de usuario que usaste para registrarte. Te enviaremos un mensaje para restablecer tu contraseña.',
          user: 'Nombre de usuario',
          helps: {
            user: 'Ingresa tu DNI (RUT, Pasaporte u otro definido por tu administrador)',
          },
          rules: {
            user: 'Ingresa tu nombre de usuario',
          },
          alerts: {
            successRequest: 'Se ha enviado con éxito la información.',
            checkEmail: 'Revisa tu buzón de correo y sigue las instrucciones enviadas.',
          },
          errors: {
            notFoundUser: 'Este nombre de usuario no existe',
          },
        },
        confirm: {
          confirmAccount: 'Verifica tu cuenta',
          temporalPassword: 'Contraseña temporal',
          newPassword: 'Nueva contraseña',
          confirmPassword: 'Confirmar nueva contraseña',
          rules: {
            temporalPassword: 'Ingresa tu contraseña temporal',
            newPassword: 'Ingresa tu contraseña',
          },
          errors: {
            userOrPassword: 'Nombre de usuario y/o contraseña incorrectas',
            newPassword: 'La contraseña no cumple con los requisitos',
            confirmPassword: '¡Las dos contraseñas que ingresó no coinciden!',
          },
        },
        login: {
          loginButton: 'Iniciar sesión',
          forgotPasswordLink: '¿Olvidaste tu contraseña?',
          user: 'Nombre de usuario',
          password: 'Contraseña',
          rememberme: 'Recuérdame',
          helps: {
            user: 'Ingresa tu DNI (RUT, Pasaporte u otro definido por tu administrador)',
          },
          rules: {
            user: 'Ingresa tu nombre de usuario',
            password: 'Ingresa tu contraseña',
          },
          errors: {
            userOrPassword: 'Nombre de usuario y/o contraseña incorrectas',
          },
        },
      },
      vehicles: {
        fieldRequired: 'Este campo es obligatorio',
        nid: 'Código vehículo',
        plate: 'Patente',
        additionalInformation: 'Información adicional',
        rules: {
          nid: 'Ya existe el código %{nid}',
          plate: 'Ya existe la patente %{plate}',
        },
      },
      users: {
        password: 'Contraseña',
        newPassword: 'Nueva contraseña',
        fieldRequired: 'Este campo es obligatorio',
        uploadPicture: 'Sube una foto',
        nationalNid: 'DNI',
        name: 'Nombre',
        lastName: 'Apellidos',
        role: 'Rol',
        email: 'Email',
        organization: 'Organización',
        repeatPassword: 'Repetir contraseña',
        generatePassword: 'Generar contraseña temporal',
        helps: {
          nationalNid:
            'Documento Nacional de Identidad: Ingrese el RUT, pasaporte o identificador único de la persona',
          role: 'Roles para el usuario',
          organization: 'Organización del usuario',
          generatePassword: 'Genera una contraseña segura que será enviada al usuario',
        },
        errors: {
          nationalNid: 'DNI ya se encuentra registrado',
          fileFormat: '%{fileName} no tiene formato válido (%{formats})',
          minStrength: 'La contraseña no cumple con los requisitos',
          notEquals: '¡Las dos contraseñas que ingresó no coinciden!',
        },
        rules: {
          email: 'No es un Email válido',
        },
      },
      generalRouting: {
        title: 'Ruteo general',
        routeServices: 'Servicios de ruteo',
        defaultTarget: 'Objetivo predeterminado',
        maxSendForWork: 'Límite de registros por carga masiva de información',
        defaultVehicle: 'Vehículo por defecto',
        showInParameterize: 'Mostrar al parametrizar',
        minVehicles: 'Mínimo de vehículos',
        geoserviceSpeedWeight: 'Ponderador de velocidad',
        times: 'Tiempos',
        fixedDeliveryStopCost: 'Tiempo de parada',
        maxWaitingCost: 'Tiempo máximo de espera',
        deliveryDemandCostAndRates: 'Tiempo de recarga fijo',
        deliveryCostPerLoadUnit: 'Tiempo de recarga por load',
        endpointRe: 'Endpoint del ruteador a usar',
        helps: {
          defaultTarget: 'Configuración de ruteo que utilizará de base la organización',
          maxSendForWork:
            'Cantidad máxima de registros que se puede subir por importer, se limita a 500 por limitaciones de infraestructura (lambda)',
          showInParameterize:
            'Permite ocultar o mostrar al usuario los valores definidos al momento de parametrizar un conjunto de demandas',
          minVehicles:
            'Cantidad fija de vehículos a considerar como mínimo para generar rutas, el que es asociado al parámetro min_vehicles al rutear',
          geoserviceSpeedWeight:
            'Factor porcentual aplicado para reducir o aumentar la velocidad promedio del vehículo',
          loadsUnits:
            'Valores que se asocian al vehículo virtual utilizado para optimizar el ruteo',
          fixedDeliveryStopCost:
            'Tiempo fijo de espera por parada, el que es asociado al parámetro fixed_delivery_stop_cost al rutear',
          maxWaitingCost:
            'Tiempo máximo de espera antes de una ventana de atención, el que es asociado al parámetro max_waiting_cost al rutear',
          deliveryDemandCostAndRates:
            'Tiempo fijo que demora la carga de productos al vehículo, el que es asociado al parámetro delivery_demand_cost_and_rates al rutear',
          deliveryCostPerLoadUnit:
            'Tiempo variable que demora la carga de productos al vehículo, el que es asociado al parámetro delivery_cost_per_load_unit al rutear',
        },
        alerts: {
          updateSuccessfully: 'Configuración de ruteo actualizada correctamente',
          createSuccessfully: 'Configuración de ruteo creada correctamente',
          errorOnSave: 'Configuración de visualización no pudo ser actualizada',
          routingParameterIsRequired:
            'Debe agregar al menos un parametro de ruteo para visualizar los perfiles disponibles',
        },
      },
      mobile: {
        recommendFinish: 'Recomendar finalizar',
        helps: {
          recommendFinish:
            'Al activar se muestra mensaje en pantalla al conductor cuando no tiene más paradas por reportar para finalizar la ruta',
        },
      },
      calibrator: {
        title: 'Calibrador de coordenadas',
        enableCalibrator: 'Activar calibrador de coordenadas',
        parameters: 'Parámetros',
        consultTime: 'Tiempo Consulta (meses)',
        range: 'Rango',
        range1: 'Rango 1',
        depotMeters: 'Metros Depot',
        range2: 'Rango 2',
        percentile: 'Percentil',
        aggregation: 'Agregación',
        emails: 'Emails',
        emailCount: 'Email %{count}',
        addEmail: 'Añadir',
        helps: {
          enableCalibrator:
            'Permite habilitar el uso del calibrador de coordenadas para la organización',
          typeANumber: 'Ingrese números a partir de 1 en este campo.',
        },
        alerts: {
          updateSuccessfully: 'Configuración del calibrador actualizada correctamente',
          createSuccessfully: 'Configuración del calibrador creada correctamente',
          errorOnSave: 'Configuración del calibrador no pudo ser actualizada',
          fillField: 'Rellene el campo',
        },
        rules: {
          email: 'Debe escribir un correo electrónico',
          emailInvalid: 'No es un Email válido',
        },
      },
    },
    footerDrawer: {
      exit: 'Salir',
      cancel: 'Cancelar',
      save: 'Guardar',
      confirm: 'Confirmar',
      areYouSure: '¿ Está seguro ?',
      loadAndProcess: 'Cargar y procesar',
    },
    buttons: {
      parameterize: {
        disabled: 'Debe asignar una base para poder parametrizar',
        label: 'Parametrizar',
      },
      edit: 'Editar',
      unarchive: 'Desarchivar',
      archive: 'Archivar',
      cancel: 'Cancelar',
      accept: 'Aceptar',
      save: 'Guardar',
      changePhoto: 'Cambiar foto',
      send: 'Enviar',
      create: 'Crear',
      manual: 'Manual',
      massive: 'Masivo',
      show: 'Ver',
      addPhone: '+ Añadir n° teléfono',
      addEmail: '+ Añadir correo electrónico',
      addOptions: '+ Añadir opciones',
      filter: 'Filtrar',
      close: 'Cerrar',
      more: 'Más',
      delete: 'Eliminar',
      saveChanges: 'Guardar cambios',
    },
    commons: {
      unarchive: 'Desarchivar',
      archive: 'Archivar',
      depot: 'Base',
      name: 'Nombre',
      lastname: 'Apellidos',
      phoneNumber: 'N° telefóno',
      email: 'Correo',
      roles: 'Roles',
      relatedOrganizations: 'Organización asociada',
      demands: 'Demandas',
      totalOf: 'Cantidad de %{element}',
      routes: 'Rutas',
      demandSets: 'Conjunto de demandas',
      sureUnarchive: '¿ Estás seguro que desea desarchivar este %{element}?',
      sureArchive: '¿Estás seguro que desea archivar este %{element}?',
      status: 'Estado',
      totalDemands: 'Cantidad demandas',
      clear: 'Limpiar',
      startDate: 'Fecha inicio',
      endDate: 'Fecha final',
      seconds: 'Segundos',
      minutes: 'Minutos',
      delivery: 'Llevada',
      pickup: 'Recogida',
      select: 'Seleccione',
      changeLanguage: 'Cambiar idioma',
      elementUnarchived: '%{element} Desarchivado',
      elementArchived: '%{element} Archivado',
      cannotUnarchiveElement: 'No se pudo desarchivar el %{element}',
      cannotArchiveElement: 'No se pudo archivar el %{element}',
      archived: 'Archivado',
      archivedFem: 'Archivada',
      unarchived: 'Desarchivado',
      unarchivedFem: 'Desarchivada',
      entityArchivedUnarchivedF: '%{entity} %{status}',
      emptyRoutes: 'Aún no se han agregado nuevos ruteos',
      pressToEdit: 'Presione para editar',
      showTour: 'Mostrar tour',
      goToOrganizations: 'Ir a organizaciones',
      clientLogo: 'Logo Cliente %{name}',
      phonePrefix: 'Código',
      type: 'Tipo',
      createdAt: 'Fecha de creación',
      download: 'Descargar',
      driver: 'Conductor',
      vehicle: 'Vehículo',
      vehicles: 'Vehículos',
      assistant: 'Acompañante',
      search: 'Buscar',
      notAvailable: 'No disponible',
      visits: 'Visitas',
      depots: 'Bases',
      typeHere: 'Ingrese',
      restriction: 'Restricción',
      customAttribute: 'Campo personalizado',
      statuses: {
        success: 'Exitoso',
        SUCCESS: 'Exitoso',
        partial: 'Parcial',
        PARTIAL: 'Parcial',
        failed: 'Fallido',
        FAILURE: 'Fallido',
        postponed: 'Vuelvo más tarde',
        POSTPONED: 'Vuelvo más tarde',
        pending: 'Pendiente',
        PENDING: 'Pendiente',
        ARRIVAL: 'Pendiente',
        default: 'Exitoso',
      },
      eventStatus: {
        arrival: 'Llegué',
        postponed: 'Vuelvo más tarde',
        partial: 'Parcial',
        success: 'Exitoso',
        failure: 'Fallido',
        alert: 'Alerta',
        default: 'Pendiente',
      },
      cognitoUserStatus: {
        unconfirmed: 'Sin confirmar',
        unconfirmedDescription: 'Usuario creado pero no confirmado.',
        confirmed: 'Confirmado',
        confirmedDescription: 'Usuario confirmado.',
        archived: 'Archivado',
        archivedDescription: 'Usuario creado pero archivado.',
        compromised: 'Comprometido',
        compromisedDescription: '--',
        unknown: 'Desconocido',
        unknownDescription: 'No se conoce el estado del usuario.',
        resetRequired: 'Reinicio necesario',
        resetRequiredDescription:
          'El usuario está confirmado, pero debe solicitar un código y restablecer su contraseña antes de poder iniciar sesión.',
        forceChangePassword: 'Forzar cambio de contraseña',
        forceChangePasswordDescription: 'El usuario debe cambiar su contraseña.',
        deleted: 'Usuario no existe en Cognito',
        deletedDescription:
          'El usuario existe en la base de datos pero no en el pool de usuarios de Cognito.',
        default: '--',
        defaultDescription: '--',
      },
      yes: 'Sí',
      no: 'No',
      user: 'Usuario',
      advancedFilter: 'Filtro avanzado',
      advanced: 'Avanzado',
      organization: 'Organización',
      showMenu: 'Mostrar Menú',
    },
    locations: {
      locations: 'Ubicaciones',
      visit: 'Visita',
      showAs: 'Ver como %{type}',
      table: 'Tabla',
      map: 'Mapa',
      depot: 'Base',
      identifier: 'Identificador',
      code: 'Código',
      name: 'Nombre',
      category: 'Categoría',
      vip: 'VIP',
      yes: 'Si',
      no: 'No',
      contactData: 'Datos de contacto',
      dni: 'DNI',
      phoneNumber: 'Nº de teléfono',
      email: 'Correo electrónico',
      location: 'Ubicación',
      country: 'País',
      province: 'Región',
      city: 'Comuna',
      address: 'Dirección',
      geolocation: 'Geolocalización',
      latitude: 'Latitud',
      longitude: 'Longitud',
      creation: 'Creación',
      createdAt: 'Fecha creación',
      createdBy: 'Creado por',
      updatedAt: 'Fecha modificación',
      restrictions: 'Restricciones',
      street: 'Calle',
      date: 'Fecha',
      status: 'Estado',
      changeStatus: 'Cambiar estado',
      totalRowsSelected: 'Seleccionado %{total} filas',
      totalArchived: {
        one: '%{count} archivado',
        other: '%{count} archivados',
      },
      totalUnarchived: {
        one: '%{count} restaurado',
        other: '%{count} restaurados',
      },
      changeStatusMassiveDetail:
        'Cambio de estados de visitas correctamente: %{archived} %{restored}',
      sureChangeMassiveVisits: {
        one: '¿Estás seguro que desea cambiar el estado de %{count} visita?',
        other: '¿Estás seguro que desea cambiar el estado de %{count} visitas?',
      },
      sureChangeMassiveDepots: {
        one: '¿Estás seguro que desea cambiar el estado de %{count} base?',
        other: '¿Estás seguro que desea cambiar el estado de %{count} bases?',
      },
      download: 'Descargar',
      helps: {
        code: 'El código de visita puede usarse como referencia para crear demandas, ya sea de forma unitario o masiva',
        category:
          'Las categorías son etiquetas personalizables que quedan disponibles para toda la organización',
      },
      alerts: {
        zeroEntries: 'No existen visitas para esta búsqueda',
      },
      errors: {
        changeStatusMassive: 'Hubo un error al cambiar los estados, intente nuevamente',
      },
    },
    download: {
      generatingDocument: 'Generando documento',
      youWillBeNoticed: 'Se le notificará cuando se encuentre disponible para descargar.',
      updateMassive: 'Actualizar datos masivamente',
    },
    documents: {
      show: 'Ver documentos',
      importExport: 'Importar/Exportar',
      showAll: 'Ver todo',
      openFile: 'Abrir archivo',
      expired: 'Expirado',
      showErro: 'Ver error',
      display: 'Mostrar',
      showOrigin: 'Mostrar ubicación',
      import: 'Importar',
      export: 'Exportar',
      status: {
        expired: 'Se ha generado una versión más reciente del archivo',
        tooOld: 'Archivo expirado, debe generar uno nuevo',
      },
      helps: {
        type: 'Archivo exportado/importado',
      },
      statuses: {
        pending: 'Pendiente',
        pendingDesc: 'El archivo será procesado a la brevedad',
        inProgress: 'Progreso',
        inProgressDesc: 'El archivo aún sigue en proceso',
        ready: 'Listo',
        readyDesc: 'El archivo esta listo y completo',
        error: 'Error',
        failed: 'Fallido',
        errorDesc: 'Algo no salio bien y no se pudo completar',
      },
      modules: {
        demands: 'Demandas',
        users: 'Usuarios',
        visits: 'Visitas',
        depots: 'Bases',
        vehicles: 'Vehículos',
        reports: 'Informes',
      },
      errors: {
        showOrigin: 'No se pudo armar url de origen',
        showError: 'Ver error',
      },
    },
    notifications: {
      show: 'Ver notificaciones',
      notifications: 'Notificaciones',
      empty: 'No tienes notificaciones',
      loadMore: 'Cargar más',
      additionalNotification: {
        one: 'Y notificación adicional...',
        other: 'Y otras %{count} notificaciones adicionales...',
      },
      previous: 'Anteriores',
      today: 'Hoy',
      alerts: {
        lostConnection: 'Sin conexión',
        notificationDisabled: 'Activa las notificaciones de tu navegador',
        demoWillBeExpired: 'Tu plan gratuito termina en ',
        demoExpired:
          'Tu plan gratuito ha llegado a su fin. Impulsa tus resultados en tiempo real con un nuevo plan.',
        demoWillBeExpiredDays: {
          zero: 'hoy',
          one: '%{count} día',
          other: '%{count} días',
        },
        scheduledMaintenance: 'Mantenimiento programado del sistema:',
        scheduledMaintenanceFromTo:
          ' el día %{date}, de %{start} a %{end}, nuestros canales estarán fuera de servicio.',
        showAll: 'Ver todas las notificaciones',
        helps: {
          lostConnection: 'Verifica tu internet.',
          notificationDisabled:
            ' para estar al tanto de tus actualizaciones y saber si tus archivos están procesados.',
          demoWillBeExpired: ' a las ',
        },
      },
    },
    errorBoundary: {
      title: {
        default: '¡Uy! Algo salió mal',
        error403: 'Error 403',
        error404: 'Oops! esta página no existe',
        offline: 'Sin conexión',
      },
      subtitle: {
        default:
          'Estamos trabajando para solucionar el problema. Por favor reintenta en unos minutos.',
        error403: 'Sin permisos para esta página.',
        error404:
          'No hemos encontrado la página que buscas. Haz clic en el siguiente botón para ir al inicio.',
        offline: 'Verifica si tienes conexión activa y estable a internet.',
      },
      button: 'Actualizar',
      warning: 'Advertencia',
      warningHelp: 'Problemas para procesar la solicitud.',
    },
    assigment: {
      entityAlreadyAssigned: {
        zero: '%{entity} ya se encuentra asignado en este horario',
        one: '%{entity} ya se encuentra asignado en este horario a la ruta: %{routes}',
        other: '%{entity} ya se encuentra asignado en este horario a las rutas: [%{routes}]',
      },
      driverAlreadyAssigned: {
        zero: 'Conductor ya se encuentra asignado en este horario',
        one: 'Conductor ya se encuentra asignado en este horario a la ruta: %{routes}',
        other: 'Conductor ya se encuentra asignado en este horario a las rutas: [%{routes}]',
      },
      vehicleAlreadyAssigned: {
        zero: 'Vehículo ya se encuentra asignado en este horario',
        one: 'Vehículo ya se encuentra asignado en este horario a la ruta: %{routes}',
        other: 'Vehículo ya se encuentra asignado en este horario a las rutas: [%{routes}]',
      },
      clearSelection: 'Limpiar selección',
      assigment: 'Asignación',
      routeNumber: 'Ruta %{number}',
      driver: 'Conductor',
      assistant: 'Acompañante',
      helps: {
        blockedBy: 'Bloqueado por ser seleccionado como %{type}',
        pressToUncheck: 'Presione para desmarcar',
      },
      errors: {
        alreadyAssigned: '%{type} ya se encuentra asignado a la ruta',
        inThisLapse: 'en este horario.',
      },
    },
    profile: {
      myProfile: 'Mi perfil',
      changePassword: 'Cambiar contraseña',
      logOut: 'Cerrar Sesión',
      altAvatar: 'Imagen de perfil',
    },
    driver: {
      congratulations: '¡Felicidades!',
      welcomeMessage:
        'Tu cuenta ha sido validada exitosamente. ¡Ahora es el momento de dar el siguiente paso! Descarga la aplicación Routing Mobile para comenzar a disfrutar de la experiencia.',
      selectOrScan: 'Selecciona o escanea una opción:',
      downloadForOs: 'Descargar Aplicación mobile para %{os}',
    },
    system: {
      general: 'General',
      restrictions: 'Restricciones',
      loads: 'Medidas',
      trackpoints: 'Trackpoints',
      customAttributes: 'Campos personalizados',
      routingServices: 'Servicios de ruteo',
      routingService: 'Servicio de ruteo',
      definitions: 'Definiciones',
      label: 'Etiqueta',
      description: 'Descripción',
      name: 'Nombre',
      type: 'Tipo',
      entity: 'Entidad',
      unit: 'Unidad',
      value: 'Valor',
      definition: 'definición',
      restriction: 'Restricción',
      load: 'Carga',
      trackpoint: 'Trackpoint',
      location: 'Base y Visita',
      vehicle: 'Vehículo',
      depot: 'Base',
      stop: 'Visita',
      allEntities: 'Todas las entidades',
      enableForAllSystem: 'Habilitado para todo el sistema',
      all: 'Todo',
      yes: 'Sí',
      no: 'No',
      status: 'Estado',
    },
    following: {
      stateRoutes: 'Estado de rutas',
      active: 'Activo',
      inactive: 'Inactivo',
      reload: 'Recargar',
      routesUnordered: 'Rutas en desorden',
      routesOutHour: 'Rutas fuera de horario',
      seconds: 'Segundos',
      minutes: 'Minutos',
      na: 'NA',
      pa: 'PA',
      pe: 'PE',
      plannedDelivery: 'Entregas planificadas',
      inactivityNotice: 'Aviso de inactividad',
      keepActive: 'Mantener activo',
      close: 'Cerrar',
      mapUnusedNotice:
        'No estás utilizando el mapa. El mapa se desactivará en %{number} minutos. ¿Necesitas más tiempo?',
      noRoutesInLapse: 'No hay rutas disponibles para el período',
      routingSetNid: 'Conjunto de rutas: %{nid}',
      planningFor: 'Planificación del  ',
      table: 'Tabla',
      notifications: 'Notificaciones',
      filter: 'Filtrar',
      routesNeededToFilter: 'Debe tener rutas para filtrar',
      back: 'Volver',
      clear: ' Limpiar',
      executedInDisorder: 'Rutas en desorden',
      delayedRoutes: 'Rutas fuera de horario',
      FAILURE: 'Entregas fallidas',
      PARTIAL: 'Entregas parciales',
      SUCCESS: 'Entregas exitosas',
      delayedRoutes1: 'Priorizar fuera de horario',
      routesWithFailure: 'Priorizar entregas fallidas',
      route: 'Ruta',
      client: 'Cliente o ID',
      document: 'N° documento',
      depot: 'Base',
      driver: 'Conductor',
      vehicle: 'Vehículo',
      show: 'Mostrar',
      orderBy: 'Ordenar',
      status: {
        ASSIGNED: 'No iniciado',
        default: 'No iniciado',
        STARTED: 'En ruta',
        FINISHED: 'Terminado',
      },
      routeStatus: {
        finished: 'Finalizadas',
        notAssigned: 'No asignadas',
        notStarted: 'No iniciadas',
        inExecution: 'En ejecución',
      },
      statusLabel: 'Estado',
      routingSet: 'Conjunto de ruta',
      routingSetInit: 'CR',
      routeOrder: 'N° de ruta',
      notAssigned: 'Sin asignar',
      stopsSize: 'Entregas',
      startsAt: 'Hora de Inicio',
      endsAt: 'Hora de Fin',
      lastPosition: 'Última posición',
      lastConnection: 'Última conexión: %{date}',
      gpsActive: 'GPS',
      progress: 'Avance',
      noMatch: 'Sin coincidencias',
      totalCompliance: 'Cumplimiento de paradas',
      routingSetRoute: 'Conjunto de rutas %{routingSetId} | Ruta %{routeOrder}',
      noPhoneNumber: 'Sin N° telefono',
      routeResume: '%{depot} | %{totalStops} paradas | %{distance} Km.',
      searchDocumentClient: 'Buscador de N° documento o cliente',
      noAddress: 'Sin dirección',
      showEvents: 'Ver eventos',
      noEvents: 'Sin eventos',
      stopWithPhotos: 'Esta parada contiene fotos',
      moreInfo: 'Más información',
      overcome: 'Supera el',
      within: 'Dentro del',
      distanceRange: 'rango de distancia',
      contactData: 'Datos de contacto',
      name: 'Nombre',
      contactPhone: 'N° de contacto',
      email: 'Email',
      time: 'Tiempo',
      diffTime: 'Tiempo de diferencia',
      estimatedTime: 'Hora estimada (ETA)',
      stopTime: 'Tiempo de parada',
      outTime: 'Tiempo de salida',
      distance: 'Distancia',
      reportDistance: 'Distancia de reporte',
      states: {
        success: 'Parada exitosa',
        partial: 'Parada parcial',
        failure: 'Parada Fallida',
        postponed: 'Vuelvo más tarde',
        started: 'Iniciado',
        finished: 'Finalizado',
        default: 'No reportado',
      },
      order: 'Orden',
      clientId: 'ID Cliente',
      clientName: 'Nombre cliente',
      arrivesAt: 'Hora planificada',
      arrivedAt: 'Hora real',
      eta: 'ETA',
      stop: 'Parada',
      departedAt: 'Hora salida',
      showInMap: 'Mostrar posición en el mapa',
      comment: 'Comentario',
      noEventsReported: 'Sin eventos reportados',
      arrival: 'Llegada',
      departure: 'Partida',
      timeCaptured: 'Hora',
      loads: 'Cargas',
      reason: 'Motivo',
      form: 'Formulario',
      closeMap: 'Cerrar mapa',
      openMap: 'Abrir mapa',
      itemCoord: 'Real',
      eventCoord: 'Reportada',
      difference: 'Diferencia',
      coordsItem: 'Coordenadas item',
      coordsEvent: 'Coordenadas reporte',
      helps: {
        routesOutHour:
          'Se considera fuera de horario un retraso o adelanto mayor a %{delay} %{unit} en el inicio de la ruta',
        na: 'NA: Es la cantidad o número real que llevas actualmente',
        pa: 'PA: Es la porcentaje real que llevas actualmente',
        pe: 'PE: Es el porcentaje estimado que deberias llevar actualmente',
        planningFor:
          'Al entrar al módulo Seguimiento desde el menú, verás automáticamente las rutas del día. Puedes extender el rango hasta 14 días hacia atrás o adelante.',
        startsAt: 'Se muestran hora planificada, hora real y diferencia entre ambas',
        endsAt: 'Se muestran hora planificada, hora real y diferencia entre ambas',
        lastPosition: 'Hora en que se registró la última posición del vehículo',
        gpsActive: 'Se encuentra activo si la última conexión fue hace menos de %{minutes} minutos',
        totalCompliance: 'Paradas realizadas vs paradas totales',
        diffTime: 'Diferencia entre hora planificada y real',
        eta: 'Hora estimada de llegada',
        stop: 'Tiempo de parada',
        distance: 'Metros de distancia respecto al reporte de llegada al punto',
        status: 'Estado del último evento reportado',
        arrival: 'Llegada planificada',
        departure: 'Partida planificada',
        timeCaptured: 'Hora de llegada real',
      },
      showStats: 'Ver estadísticas',
      goToRoutingSet: 'Ir a conjunto de rutas',
      showStatsTitle: 'Estadísticas Ruta %{routeOrder}',
    },
    vehicles: {
      vehicle: 'Vehículo',
      plate: 'Patente',
      additionalInformation: 'Información adicional',
      status: 'Estado',
      active: 'Activo',
      inactive: 'Inactivo',
      nid: 'Código',
      loads: 'Capacidad',
      changeStatus: 'Cambiar estado',
      totalRowsSelected: 'Seleccionado %{total} filas',
      totalArchived: {
        one: '%{count} archivado',
        other: '%{count} archivados',
      },
      totalUnarchived: {
        one: '%{count} restaurado',
        other: '%{count} restaurados',
      },
      changeStatusMassiveDetail:
        'Cambio de estados de visitas correctamente: %{archived} %{restored}',
      sureChangeMassiveVehicles: {
        one: '¿Estás seguro que desea cambiar el estado de %{count} vehiculo?',
        other: '¿Estás seguro que desea cambiar el estado de %{count} vehiculos?',
      },
      helps: {
        nid: 'El código de vehículo se muestra en la asignación de rutas. Utilízalo como referencia para un seguimiento preciso',
      },
      alerts: {
        loads:
          'Los elementos relacionados con las Cargas están deshabilitados ya que debe configurar las unidades de medida en Configuración → Organización → Medidas',
      },
      errors: {
        changeStatusMassive: 'Hubo un error al cambiar los estados, intente nuevamente',
      },
    },
    users: {
      nationalNid: 'DNI',
      name: 'Nombre',
      roles: 'Roles',
      role: 'Rol',
      phoneNumber: 'N° Telefónico',
      lastLogin: 'Último ingreso',
      status: 'Estado',
      user: 'Usuario',
      personalInfo: 'Personal',
      additionalInformation: 'Información adicional',
      selectedOrganization: 'Organización',
      active: 'Activo',
      inactive: 'Inactivo',
      email: 'Email',
      helps: {
        nationalNid:
          'DNI (RUT, Pasaporte, etc.) es el identificador único del usuario. No pueden haber dos iguales en el sistema. ¡Cada uno es único!',
        userCognitoEnabled: 'Indica si el ususario está habilitado para iniciar sesión',
      },
      alerts: {
        rolesAssigmentSuccessful: 'Roles asignados correctamente a usuario',
      },
      errors: {
        cannotUpdateUser: 'No se pudo actualizar usuario se mantendrá valor anterior',
        roleRequired: 'Debe ingresar al menos un rol',
      },
      userCognitoStatus: 'Estado de Cognito',
      userCognitoEnabled: 'Habilitado',
    },
    alert: {
      pinNumberInMap:
        'El número dentro de cada pin en el mapa es a modo informativo, no implica que este sea el orden de la ruta.',
      pinDraggable:
        'El pin coloreado corresponde a la ubicación que está consultando y la puede arrastrar para cambiar su posición.',
      pinIsCurrentLocation: 'El pin coloreado corresponde a la ubicación que está consultando.',
    },
    errors: {
      editableCell: 'Formulario con errores, se mantendrá valor anterior',
      elementCannotBeBlank: 'No puede dejar el %{element} en blanco.',
      noChangeRequested: 'No se han realizado cambios',
    },
    modules: {
      planning: 'Mi planificación',
      routesList: 'Lista de rutas',
      routeJobList: 'Lista de Trabajos de ruteo',
      index: 'Inicio',
      visit: 'Visita',
      depot: 'Base',
      panels: 'Paneles',
      report: 'Informe',
      fleet: 'Flota',
      users: 'Usuarios',
      roles: 'Roles y Permisos',
      organization: 'Organización',
      system: 'Sistema',
      names: {
        routes: 'RUTAS',
        following: 'SEGUIMIENTO',
        locations: 'UBICACIONES',
        reports: 'REPORTES',
        settings: 'CONFIGURACIÓN',
        documents: 'IMPORTAR Y EXPORTAR',
        help: 'AYUDA',
      },
    },
    sidebar: {
      routes: 'Rutas',
      following: 'Seguimiento',
      locations: 'Ubicaciones',
      reports: 'Reportes',
      settings: 'Configuración',
      support: 'Ayuda',
      documents: 'Documentos',
      help: 'Ayuda',
    },
    so: {
      windows: 'Windows',
      others: 'Otros',
      android: 'Android',
      iphone: 'Iphone',
    },
    routes: {
      routeOrder: 'Nº de ruta',
      driver: 'Conductor',
      plate: 'Patente',
      assistant: 'Acompañante',
      status: 'Estado',
      editor: {
        addManualStop: 'Agregar parada manual',
        addRest: 'Agregar colación',
        sureRevertChanges: '¿Estás seguro que quieres deshacer los cambios?',
        undo: 'Deshacer',
        sureDeleteStop: {
          one: '¿Estás seguro que desea eliminar esta parada?',
          other: '¿Estás seguro que desea eliminar estas paradas?',
        },
        emptyList: 'No hay rutas o paradas que cumplan con los filtros',
        routingSets: 'Conjunto de rutas',
        editor: 'Editor',
        drawer: {
          titleEdit: 'Edición de parada',
          titleCreate: 'Agregar parada manual',
          nid: 'código',
          name: 'nombre',
          searchByType: 'Sólo busca %{type} entre las visitas existentes',
          searchClient: 'Buscador de cliente',
          itemDetailNumber: 'Producto %{number}',
          goToVisit: 'Ir a visitas',
          visits: 'Visitas',
          form: {
            code: 'SKU',
            description: 'Descripción',
            quantity: 'Cantidad',
            unit: 'Unidad',
            loads: 'Cargas',
            addLoad: 'Añadir carga',
            routeDetail: 'Detalle de ruta',
            routeNumber: 'Ruta',
            itemOrder: 'Posición de parada',
            action: 'Tipo de acción',
            fixedDeliveryStopCost: 'Tiempo de parada',
            identification: 'Identificación',
            nid: 'Código de cliente',
            name: 'Nombre cliente',
            document: 'ID Documento',
            contactInformation: 'Datos de contacto',
            contactId: 'Código',
            contactName: 'Nombre',
            contactPhones: 'N° telefónico',
            contactEmails: 'Correo electrónico',
            itemDetails: 'Detalle de entrega',
            addItemDetail: 'Añadir producto',
            location: 'Ubicación',
            addressForm: 'Localización',
            street: 'Dirección',
            number: 'Número',
            line2: 'Información adicional',
            geolocation: 'Geolocalización',
            latitude: 'Latitud',
            longitude: 'Longitud',
            helps: {
              code: 'Código producto',
              description: 'Descripción producto',
              quantity: 'Cantidad',
              mustEnterAValue: 'Debe ingresar un valor',
              routeNumber: 'N° Ruta',
              itemOrder: 'N° parada',
              fixedDeliveryStopCost: 'Parada',
            },
          },
          alerts: {
            itemCreated: 'Se agregó una nueva parada a la ruta %{routeNumber}',
            itemUpdated: 'Se modificó con éxito',
            rememberChangeInLocation:
              'Recuerda: Si necesitas hacer cambios para esta parada tendrás que modificarlo en',
          },
          errors: {
            mustSelectClient: 'Debe seleccionar un cliente',
            fillAllFields: 'Debe rellenar todos los campos obligatorios.',
            codeNotFound:
              'El código de cliente no se encuentra registrado, confirme e intente de nuevo.',
            maxLoads: 'Número máximo de cargas alcanzado',
            fieldRequired: 'Este campo es obligatorio',
            notEditable: 'Ruta finalizada. Elige una en curso o asignada.',
          },
        },
        list: {
          routeName: 'Ruta %{nid}',
          unassigned: 'Sin asignar',
          assign: 'Asignar',
          reassign: 'Reasignar',
          vehicleCapacity: 'Capacidad del vehículo',
          unassignedVehicle: 'Vehículo sin asignar',
          alerts: {
            relaxedConstraints:
              'Ruta aislada por contener una demanda infactible. Revisa capacidades u otras restricciones.',
            saveBeforeAssign: 'Debe guardar antes de poder asignar',
            vehicleCapacityExceeded: 'Capacidad del vehículo sobrepasada',
          },
          itemList: {
            contactName: 'Nombre de contacto',
            contactPhone: 'N° de contacto',
            document: 'ID documento',
            showInfo: 'Ver información',
            edit: 'Editar',
            restrictions: 'Restricciones',
            errors: {
              invalidNumber: 'número inválido',
              invalidRouteNumber: 'número de ruta invalida',
              invalidOrderNumber: 'orden invalido',
            },
          },
        },
        alerts: {
          routesUpdated: 'Se han actualizado las rutas',
          rememberSave: 'Recuerde guardar los cambios.',
        },
        errors: {
          cannotUpdate: 'No se pudo actualizar las rutas',
          selectRouteToDelete: 'Debe seleccionar al menos una parada para eliminar',
          makeChangeInRouteToSave: 'Debe modificar al menos una ruta para poder guardar',
        },
      },
    },
    help: {
      all: 'Todo',
      search: 'Buscar',
      back: 'Volver',
      cannotFoundDocument: 'No se pudo encontrar documento, favor vuelva atrás',
      displayedTotalOfFounded: 'Mostrando %{founded} resultados de %{total}',
      showHelp: 'Ver ayuda',
    },
  },
};

export default spanishLocale;
