import { App, Button, Col, Form, Input, Row, Typography } from 'antd';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import { useResetPasswordMutation } from 'features/auth/authApiSlice';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

const { Title } = Typography;

function ForgotPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.account.forgot' };

  const onFinish = async (values) => {
    try {
      await resetPassword(values).unwrap();
      message.success(
        <>
          <div>{i18n.t('alerts.successRequest', scopeI18n)}</div>
          <div>{i18n.t('alerts.checkEmail', scopeI18n)}</div>
        </>,
        4
      );
      navigate(`/account/recover-password/${values.user}`);
    } catch (err) {
      const errMessage = i18n.t('errors.notFoundUser', scopeI18n);
      form.setFields([
        {
          name: 'user',
          errors: [errMessage],
        },
      ]);
    }
  };

  return (
    <>
      <Title level={4} className="forgot-password-title">
        {i18n.t('recoverPassword', scopeI18n)}
      </Title>
      <Row>
        <Col span={24} xs={{ span: 22, offset: 1 }}>
          <div className="forgot-password-subtitle">{i18n.t('userYourUser', scopeI18n)}</div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            name="login"
            autoComplete="off"
            requiredMark={false}
            style={{
              maxWidth: 350,
            }}
          >
            <Form.Item
              className="login-item"
              label={
                <TitleHelp
                  title={i18n.t('user', scopeI18n)}
                  helpText={i18n.t('helps.user', scopeI18n)}
                />
              }
              name="user"
              size="large"
              rules={[
                {
                  required: true,
                  message: i18n.t('rules.user', scopeI18n),
                },
              ]}
              style={{ marginBottom: '50px' }}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="login-item"
                loading={isLoading}
              >
                {i18n.t('buttons.send')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default ForgotPassword;
