import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Button, Collapse, Divider, Skeleton, Space } from 'antd';
import ButtonDownload from 'components/common/ButtonDownload';
import PageHeader from 'components/common/PageHeader';
import { useGetDemandsQuery, useGetGroupsQuery } from 'features/demands/demandsApiSlice';
import { useDownloadDemandSetMutation } from 'features/demandSets/demandSetsApiSlice';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PointsContext from './context/PointsContext';
import DemandForm from './DemandForm';
import DemandSetPropTypes from './DemandSet.propTypes';
import DemandSetsFilters from './DemandSetsFilters';
import LoadsResume from './LoadsResume';

import './style.css';

const emptyDemand = {
  pointId: 'this-id',
  id: null,
  source: null,
  nid: null,
  name: null,
  vip: false,
  addr: {
    country: null,
    city: null,
    province: null,
    number: null,
    line2: null,
    street: null,
  },
  zip_code: null,
  origin_latitude: null,
  origin_longitude: null,
  destination_latitude: null,
  destination_longitude: null,
  loads: [],
  demandDetails: [],
  contactPhones: [],
  contactEmails: [],
};

function DemandsSection(props) {
  const { demandSet, triggerCollapseCallback } = props;
  const { id: demandSetId, depot } = demandSet;
  const { id: currOrgId } = useSelector(selectCurrentOrganization);
  const { data: organization, isLoading: isLoadingOrgData } = useGetOrganizationQuery(currOrgId);
  const loadsFromOrg = organization?.loadsUnit || [];
  const [isNewDemand, setIsNewDemand] = useState(false);
  const [maxDemandId, setMaxDemandId] = useState();
  const [activePanelKey, setActivePanelKey] = useState();
  const [demands, setDemands] = useState([]);
  const { setMapPoints, addrFounded, setMoveToPosition } = useContext(PointsContext);
  const newDemandPanelRef = useRef();
  const goToNewDemandPanel = () => {
    if (!newDemandPanelRef.current) return;
    newDemandPanelRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const [downloadDemandSet] = useDownloadDemandSetMutation();
  const { data: listGroups, isLoading: isLoadingGroups } = useGetGroupsQuery();
  const defaultOoriginLatitude = depot?.latitude || '-33.437864960278965';
  const defaultOriginLongitude = depot?.longitude || '-70.65044532470868';
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const goBack = () => {
    navigate('/planning');
  };
  const depotName = depot ? ` / ${depot?.name}` : '';

  const emptyDemandWithDefaultLoads = {
    ...emptyDemand,
    // load origin latitude-longitude
    origin_latitude: defaultOoriginLatitude,
    origin_longitude: defaultOriginLongitude,
    loads: loadsFromOrg.map(() => {
      return { load: null };
    }),
  };

  useEffect(() => {
    if (isNewDemand) {
      goToNewDemandPanel();
    }
  }, [isNewDemand]);

  // custom hooks
  const {
    data: demandsData,
    isFetching,
    isLoading,
    refetch,
  } = useGetDemandsQuery(demandSetId, {
    skip: demandSetId === undefined,
  });
  useEffect(() => {
    if (demandsData) {
      setDemands(demandsData);
    }
    refetch();
  }, [demandsData, refetch]);

  useEffect(() => {
    if (demands.length > 0) {
      const filteredDemandsIds = demands.map((demandPoint) => {
        return demandPoint.id;
      });
      setMapPoints((oldMapPoints) =>
        [...oldMapPoints].map((pointObject) => {
          return {
            ...pointObject,
            active: filteredDemandsIds.includes(pointObject.key),
          };
        })
      );
    }
  }, [demands, setMapPoints]);

  const handleOnChangePanel = (active) => {
    setActivePanelKey(active);
    triggerCollapseCallback(active);
    setIsNewDemand(false);
  };

  const demandPanelInfo = (demand) => {
    const { loads, demandDetails: details } = demand;
    return (
      <Space>
        <Divider type="vertical" />
        {`${details.length} productos`}
        {loads.map((loadObj, index) => {
          const loadData = loadsFromOrg.find((load) => load.key === index + 1);
          if (!loadData) {
            return null;
          }
          const loadName = loadData?.name || '--';
          const loadValue = !loadObj?.load ? 0 : loadObj?.load;
          return ` / ${loadValue} ${loadName}`;
        })}
      </Space>
    );
  };

  const handleAddDemand = () => {
    const demandsIds = demands.filter((key_) => key_.id).map((demand) => demand.id);
    const maxId = Math.max(...demandsIds) + 1;
    setMaxDemandId(maxId);
    setIsNewDemand(true);
    setActivePanelKey(maxId);
    // disable all other
    setMapPoints((oldMapPoints) =>
      [...oldMapPoints].map((pointObject) => ({
        ...pointObject,
        active: false,
        draggable: false,
      }))
    );
    // restart position on map
    setMoveToPosition();
  };

  const downloadFile = async (event) => {
    try {
      const format = event.key || 'xlsx';
      notification.info({
        message: 'Generando documento',
        description: 'Se le notificará cuando se encuentre disponible para descargar.',
        placement: 'topRight',
        icon: <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" bounce color="#0d63cf" />,
      });
      await downloadDemandSet({ id: demandSetId, format }).unwrap();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Problemas con callback',
      });
    }
  };

  const addDemandButton = (
    <Button key="add-demand-button" type="primary" onClick={handleAddDemand}>
      Añadir demanda
    </Button>
  );
  const downloadDemandButton = (
    <ButtonDownload key="download-demand-button" size="middle" onClickEvent={downloadFile} />
  );

  if (isLoading || isLoadingGroups) return <Skeleton />;

  const collapseItems = demands.map((demand) => {
    const demandName = demand.name || `Demanda ${demand.id}`;
    const demandCode = demand.nid || demand.id;
    const headName = `${demandCode} - ${demandName}`;
    return {
      label: headName,
      key: demand.id,
      extra: demandPanelInfo(demand),
      children: (
        <DemandForm
          demandSetId={demandSetId}
          demand={demand}
          loadsFromOrg={loadsFromOrg}
          isLastDemand={demands.length === 1}
          addrFounded={addrFounded}
          afterSave={handleOnChangePanel}
          listGroups={listGroups}
        />
      ),
    };
  });

  if (
    activePanelKey &&
    isNewDemand &&
    !demands.map(({ id }) => id).includes(maxDemandId) // prevent repeated render after save
  ) {
    collapseItems.push({
      label: 'Agregar Demanda',
      key: maxDemandId,
      children: (
        <div ref={newDemandPanelRef}>
          <DemandForm
            demandSetId={demandSetId}
            demand={emptyDemandWithDefaultLoads}
            loadsFromOrg={loadsFromOrg}
            isNewDemand={isNewDemand}
            onCancel={setActivePanelKey}
            isLastDemand={demands.length === 1}
            addrFounded={addrFounded}
            afterSave={handleOnChangePanel}
          />
        </div>
      ),
    });
  }

  return (
    !isLoadingOrgData && (
      <>
        <PageHeader
          className="site-page-header"
          onBack={() => goBack()}
          title={`${demandSet.name}${depotName}`}
          extra={[addDemandButton, downloadDemandButton]}
        />
        <DemandSetsFilters setDemands={setDemands} demandSetId={demandSetId} />
        {!isFetching && demands.length > 0 && (
          <LoadsResume demands={demands} loadsFromOrg={loadsFromOrg} />
        )}
        {!isFetching && (demands.length > 0 || isNewDemand) && (
          <div
            style={{
              height: '42vh',
              overflow: 'auto',
            }}
          >
            <Collapse
              activeKey={activePanelKey}
              onChange={handleOnChangePanel}
              accordion
              destroyInactivePanel
              items={collapseItems}
            />
          </div>
        )}
      </>
    )
  );
}

DemandsSection.defaultProps = {
  demandSet: {},
  triggerCollapseCallback: () => {},
};

DemandsSection.propTypes = {
  demandSet: DemandSetPropTypes,
  triggerCollapseCallback: PropTypes.func,
};

export default DemandsSection;
