import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Tooltip } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import ButtonMenu from 'components/common/ButtonMenu';
import DownloadList from 'components/common/DownloadList';
import DrawerImporter from 'components/common/DrawerImporter';
import ErrorBoundary from 'components/layout/ErrorBoundary';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import LocationDrawer from 'components/Locations/Drawer';
import {
  useDownloadLocationsMutation,
  useGetLocationsQuery,
  useUploadEditLocationsMutation,
  useUploadLocationsMutation,
} from 'features/locations/locationsApiSlice';
import { useGetSettingsQuery } from 'features/settings/settingsApiSlice';
import { useContext, useEffect, useRef, useState } from 'react';
import Can from 'utils/Can';
import TableBaseLoactions from './Table/TableBaseLocations';

function BaseLocationList() {
  const [data, setData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerMassiveVisible, setDrawerMassiveVisible] = useState(false);
  const [drawerUpdateVisible, setDrawerUpdateVisible] = useState(false);
  const [triggerClean, setTriggerClean] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState('create');
  const [location, setLocation] = useState();
  const [downloadQuery, setDownloadQuery] = useState('');
  const [actualPage, setActualPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'locations' };

  const breadcrumbItems = [
    {
      key: 'locations',
      url: '/locations',
      label: i18n.t('locations', scopeI18n),
    },
    {
      key: 'base',
      url: '/locations/base',
      label: i18n.t('depot', scopeI18n),
    },
  ];

  // load settings for default action
  const { data: settingsFields, isLoading: isLoadingSetting } = useGetSettingsQuery(
    'GENERALROUTING?format=false'
  );

  const { data: locationsData, isLoading } = useGetLocationsQuery('?type=DEPOT', {
    refetchOnMountOrArgChange: true,
  });
  const [downloadLocations] = useDownloadLocationsMutation();
  const [uploadLocations] = useUploadLocationsMutation();
  const [uploadEditLocations] = useUploadEditLocationsMutation();

  const { addReference } = useContext(TourContext);
  const refCreateButton = useRef(null);
  addReference('buttonCreateLocationRef', refCreateButton);

  useEffect(() => {
    if (locationsData) {
      const { data: dataLocations, total } = locationsData;
      setTotalData(total);
      setData(dataLocations);
    }
  }, [locationsData, isLoading]);

  const setDrawerLocation = (boolValue) => {
    setDrawerVisible(boolValue);
    if (!boolValue) {
      setLocation();
    }
  };

  const setDrawerData = (objectDrawer) => {
    const { drawerType, drawerVisibility, drawerLocation } = objectDrawer;
    setTypeDrawer(drawerType);
    setDrawerVisible(drawerVisibility);
    setLocation(drawerLocation);
  };

  const showDrawer = (event) => {
    if (event.key === 'manual') {
      setDrawerVisible(true);
      setTypeDrawer('create');
    } else {
      setDrawerMassiveVisible(true);
    }
  };

  const renderTitle = () => <Breadcrumb breadcrumbItems={breadcrumbItems} />;

  const renderHeader = () => (
    <Row gutter={24} style={{ height: '100%', marginBottom: '1rem' }}>
      <Col flex="auto" style={{ textAlign: 'right' }}>
        <DownloadList
          downloadQuery={downloadQuery}
          reduxFun={downloadLocations}
          extraParams={{ type: 'DEPOT' }}
        />
        <Can I="update" a="visits">
          <Tooltip placement="left" title={i18n.t('download.updateMassive')}>
            <Button
              style={{ marginRight: '.5rem', marginLeft: '.5rem' }}
              onClick={() => setDrawerUpdateVisible(!drawerUpdateVisible)}
            >
              <FontAwesomeIcon icon={['fas', 'file-import']} />
            </Button>
          </Tooltip>
        </Can>
        <Can I="create" a="base">
          <ButtonMenu onClickEvent={showDrawer} refElement={refCreateButton} />
        </Can>
      </Col>
    </Row>
  );

  const renderLocationDrawer = () => (
    <LocationDrawer
      drawerVisible={drawerVisible}
      setDrawerLocation={setDrawerLocation}
      typeDrawer={typeDrawer}
      location={location}
      locations={data}
      locationType="DEPOT"
      setTriggerClean={setTriggerClean}
      createdFrom="BaseList"
    />
  );

  const renderLocationTable = () => (
    <TableBaseLoactions
      setDrawerData={setDrawerData}
      data={data}
      loading={isLoading}
      setDataCallback={setData}
      setDownloadQuery={setDownloadQuery}
      triggerClean={triggerClean}
      setTriggerClean={setTriggerClean}
      paginationData={{ setTotalData, totalData, setActualPage, actualPage, downloadQuery }}
    />
  );

  return (
    <>
      <Can I="read" a="base">
        {renderTitle()}
        {renderHeader()}
        {renderLocationTable()}
        {renderLocationDrawer()}
        {!isLoadingSetting && (
          <DrawerImporter
            key="importer-massive-depot"
            open={drawerMassiveVisible}
            setVisible={setDrawerMassiveVisible}
            useGeoreference
            reduxFunc={uploadLocations}
            typeUpload="DEPOT"
            settingsFields={settingsFields}
          />
        )}
        {!isLoadingSetting && (
          <DrawerImporter
            key="importer-massive-depot-edit"
            open={drawerUpdateVisible}
            setVisible={setDrawerUpdateVisible}
            reduxFunc={uploadEditLocations}
            typeUpload="DEPOT-edit"
            settingsFields={settingsFields}
          />
        )}
      </Can>
      <Can not I="read" a="base">
        <ErrorBoundary status={403} />
      </Can>
    </>
  );
}

export default BaseLocationList;
