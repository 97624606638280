import { App, Skeleton, Typography } from 'antd';
import { useUpdateOrganizationMutation } from 'features/organizations/organizationsApiSlice';
import {
  useCreateSettingMutation,
  useGetSettingsQuery,
  useUpdateSettingMutation,
} from 'features/settings/settingsApiSlice';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import FormIntegrations from './Form';

const { Title } = Typography;

function IntegrationsConfiguration(props) {
  const { organizationId, goToNextStep, submitButton, current } = props;
  const type = 'INTEGRATIONS';
  const url = `${type}?format=false${organizationId ? `&organizationId=${organizationId}` : ''}`;
  const navigate = useNavigate();
  const { data: settingsFields, isLoading, isFetching, refetch } = useGetSettingsQuery(url);
  const [updateSettingIntegration, { isLoading: updatingData }] = useUpdateSettingMutation();
  const [createSettingIntegration, { isLoading: savingData }] = useCreateSettingMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();

  const { message } = App.useApp();

  const valuesFromDb = {};
  if (!isFetching && settingsFields.length > 0) {
    const fields = settingsFields[0].keyValue;
    fields.forEach((field) => {
      Object.keys(field).forEach((nameField) => {
        const valueField = field[nameField];
        valuesFromDb[nameField] = valueField;
      });
    });
    // assign id
    valuesFromDb.id = settingsFields[0]?.id;
  }
  const defaultValues = {
    sendEventUrl: undefined,
    statusForEvents: [],
  };
  const initialValues = { ...defaultValues, ...valuesFromDb };

  const onFinish = async (values) => {
    try {
      // get fields only with data
      const fieldsWithValues = Object.keys(values).filter((key) => {
        return values[key] !== undefined;
      });
      // build format to save
      const allFields = fieldsWithValues.map((field) => {
        const value = values[field];
        return { [field]: value };
      });
      const endObject = {
        keyValue: allFields,
        ...(organizationId && { organizationId }),
      };
      if (initialValues.id) {
        await updateSettingIntegration({ ...endObject, id: initialValues.id }).unwrap();
        message.success('Configuración de Integración actualizada correctamente');
      } else {
        await createSettingIntegration({ ...endObject, type }).unwrap();
        message.success('Configuración de Integración creada correctamente');
      }
      if (goToNextStep) {
        await updateOrganization({
          organizationId,
          body: { creationStep: current + 1 },
        })
          .then(() => {
            const urlRedirect = `/organizations/creation-steps/${organizationId}/${current + 1}`;
            navigate(urlRedirect);
          })
          .catch((error) => {
            console.error(error);
            message.error('Error al guardar el formulario');
          });
      }
      // get value reloaded
      refetch();
    } catch (error) {
      console.error(error);
      message.error('Configuración de Integración no pudo ser actualizada');
    }
  };

  if (isLoading) return <Skeleton paragraph={{ rows: 10 }} />;
  return (
    <>
      <Title level={3}>Reporte de eventos</Title>
      <FormIntegrations
        initialValues={initialValues}
        onFinish={onFinish}
        savingData={savingData || updatingData}
        submitButton={submitButton}
      />
    </>
  );
}

IntegrationsConfiguration.defaultProps = {
  organizationId: null,
  goToNextStep: false,
  submitButton: false,
  current: null,
};
IntegrationsConfiguration.propTypes = {
  organizationId: PropTypes.number,
  goToNextStep: PropTypes.bool,
  submitButton: PropTypes.bool,
  current: PropTypes.number,
};

export default IntegrationsConfiguration;
