import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Avatar, Card, Col, List, Radio, Row, Tag, Tooltip, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ErrorsPropTypes, UserPropTypes, VehiclePropTypes } from '../assigment.propTypes';

const { Paragraph, Text } = Typography;

function DataList(props) {
  const {
    dataType,
    vehicles,
    driver,
    assistant,
    vehicle,
    onSelected,
    drivers,
    assistants,
    errors,
  } = props;

  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'assigment' };

  const isVehicle = dataType === 'vehicle';
  const isDriver = dataType === 'driver';
  const isAssistant = dataType === 'assistant';

  const onChecked = (item) => {
    let checked = false;
    switch (dataType) {
      case 'driver':
        checked = item.id === driver?.id;
        break;
      case 'assistant':
        checked = item.id === assistant?.id;
        break;
      case 'vehicle':
        checked = item.id === vehicle?.id;
        break;
      default:
        checked = false;
        break;
    }
    return checked;
  };

  // ToDo: get check format on loads
  const renderLoads = (load, index) => {
    let data = load.load;

    if (index >= 1) {
      data = `| ${load.load}`;
    }

    return data;
  };

  const renderCustomAttributes = (item) => (
    <Row>
      {item.loads?.map((load, index) => {
        const position = index + 1;
        return (
          <Paragraph
            key={`load-${item.id}-${position}`}
            className="ant-typography-assign"
            style={{ marginRight: 4 }}
          >
            {load.load !== null && renderLoads(load, index)}
          </Paragraph>
        );
      })}
    </Row>
  );

  const onSelectOrClean = (item) => {
    let selectedItem = item;
    // assistant can unselect
    if (isAssistant && item.id === assistant?.id) {
      selectedItem = null;
    }
    // driver can unselect
    if (isDriver && item.id === driver?.id) {
      selectedItem = null;
    }
    onSelected(selectedItem);
  };

  const renderItem = (item) => {
    let isDisabled = !isVehicle;
    let textDisabled = '';
    if (isDriver) {
      isDisabled = item.id === assistant?.id;
      textDisabled = isDisabled
        ? i18n.t('helps.blockedBy', { ...scopeI18n, type: i18n.t('assistant', scopeI18n) })
        : '';
    }
    if (isAssistant) {
      isDisabled = item.id === driver?.id;
      textDisabled = isDisabled
        ? i18n.t('helps.blockedBy', { ...scopeI18n, type: i18n.t('driver', scopeI18n) })
        : '';
    }
    const classText = isDisabled ? 'color-assign-disabled' : '';
    const itemChecked = onChecked(item);
    return (
      <Card
        className="ant-card-assign"
        style={{ paddingTop: 8, paddingBottom: 8, cursor: 'pointer', marginBottom: 16 }}
        onClick={() => (isDisabled ? {} : onSelectOrClean(item))}
        key={item.id}
      >
        <Tooltip title={textDisabled}>
          <Row
            title={
              (isDriver || isAssistant) && itemChecked
                ? i18n.t('helps.pressToUncheck', scopeI18n)
                : ''
            }
          >
            <Col span={4}>
              <Avatar
                size={36}
                icon={
                  <FontAwesomeIcon
                    icon={['fa', isVehicle ? 'van-shuttle' : 'user']}
                    color="#999999"
                  />
                }
                alt={i18n.t('profile.altAvatar')}
                src={item?.photo}
                style={{ backgroundColor: '#EBEBEB' }}
              />
            </Col>
            <Col span={18}>
              <Paragraph className={`ant-typography-assign ${classText}`} strong>
                {isVehicle ? item.plate : `${item.fullName}`}
              </Paragraph>
              <Paragraph className={`ant-typography-assign ${classText}`}>
                {isVehicle ? renderCustomAttributes(item) : item.phone || item.email}
              </Paragraph>
              {item?.allocated ? (
                <Tooltip
                  title={i18n.t(isVehicle ? 'vehicleAlreadyAssigned' : 'driverAlreadyAssigned', {
                    ...scopeI18n,
                    routes: item?.routesAllocated?.join(', '),
                    count: item?.routesAllocated?.length || 0,
                  })}
                  placement="bottom"
                >
                  {!isDisabled && <Tag color="warning">{i18n.t('commons.notAvailable')}</Tag>}
                </Tooltip>
              ) : (
                ''
              )}
            </Col>
            <Col span={2}>
              <Radio
                style={{ marginTop: 8 }}
                value={item.id}
                disabled={isDisabled}
                onChange={() => onSelectOrClean(item)}
                checked={itemChecked}
              />
            </Col>
          </Row>
        </Tooltip>
      </Card>
    );
  };

  let dataSourceList = assistants;
  if (isDriver) {
    dataSourceList = drivers;
  }
  if (isVehicle) {
    dataSourceList = vehicles;
  }
  const messageError = errors[dataType] ? (
    <Text>
      {i18n.t('errors.alreadyAssigned', { ...scopeI18n, type: errors[dataType].label })}{' '}
      <Text strong>
        {errors[dataType].routingSetId} - {errors[dataType].routeOrder}
      </Text>{' '}
      {i18n.t('errors.inThisLapse', scopeI18n)}
    </Text>
  ) : (
    ''
  );
  return (
    <>
      {errors[dataType] ? (
        <Alert message={messageError} type="error" showIcon style={{ marginTop: '.5rem' }} />
      ) : (
        ''
      )}
      <List
        itemLayout="horizontal"
        dataSource={dataSourceList}
        renderItem={(item) => renderItem(item)}
        style={{ marginTop: 16, maxHeight: '53vh', overflow: 'auto' }}
      />
    </>
  );
}

DataList.propTypes = {
  vehicles: PropTypes.arrayOf(VehiclePropTypes),
  onSelected: PropTypes.func,
  dataType: PropTypes.string,
  driver: UserPropTypes,
  assistant: UserPropTypes,
  vehicle: VehiclePropTypes,
  drivers: PropTypes.arrayOf(UserPropTypes),
  assistants: PropTypes.arrayOf(UserPropTypes),
  errors: ErrorsPropTypes,
};

DataList.defaultProps = {
  dataType: '',
  vehicles: [],
  onSelected: () => {},
  driver: null,
  assistant: null,
  vehicle: null,
  drivers: [],
  assistants: [],
  errors: {},
};

export default DataList;
