import { Col } from 'antd';
import NoResponsiveComponent from '../../layout/NoResponsiveContent';

import TableRoutes from './TableRoutes';

function RoutesJobList() {
  return (
    <>
      <Col md={24} xs={0}>
        <TableRoutes />
      </Col>
      <Col md={0} xs={24}>
        <NoResponsiveComponent />
      </Col>
    </>
  );
}

export default RoutesJobList;
