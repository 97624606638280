import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { divIcon } from 'leaflet';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
import { coordsArrayPropTypes } from './LeafletMap.propType';

function FontAwesomeMarker(props) {
  const { index, coords, type, color, title, icon } = props;
  const iconFa =
    icon || (type === 'item' ? 'fa-regular fa-circle-dot' : 'fa-solid fa-location-dot');
  const titleMarker = title || (type === 'item' ? 'Coordenadas item' : 'Coordeandas reporte');
  const iconHTML = ReactDOMServer.renderToString(
    <FontAwesomeIcon icon={iconFa} color={color} size="2x" />
  );
  const customMarkerIcon = divIcon({
    html: iconHTML,
  });
  return <Marker position={coords} key={index} icon={customMarkerIcon} title={titleMarker} />;
}

FontAwesomeMarker.defaultProps = {
  index: 0,
  coords: [],
  type: '',
  color: '',
  title: undefined,
  icon: undefined,
};

FontAwesomeMarker.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  coords: coordsArrayPropTypes,
  type: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
};
export default FontAwesomeMarker;
