import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Empty, Modal, Row, Space, theme } from 'antd';
import { VehiclePositionPropTypes } from 'components/Vehicles/vehicles.propTypes';
import LeafletMap from 'components/common/Map/LeafletMap';
import SynopticBase from 'components/common/Synoptic';
import SynopticControlBar from 'components/common/Synoptic/SynopticControlBar';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import dayjs from 'dayjs';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormatToTime, convertDateToDayjs } from 'services/repeated-functions';
import useIdleTimeout from 'services/useIdleTimeout';
import socket from '../../../socket';
import { FollowingRoutesProps, FollowingRoutingSet } from '../following.propTypes';
import FollowingDrawerFilter from './DrawerFilter';
import FollowingHeaderRoutes from './HeaderRoutes';
import FollowingTableRoutes from './TableRoutes';

function FollowingRightComponent(props) {
  const {
    allRoutes,
    isLoading,
    routingSet,
    setAllRoutes,
    setAllItems,
    initialRoutes,
    routeStartFilter,
    setRouteStartFilter,
    setOfflineMode,
    offlineMode,
    isConnected,
    vehiclesPositionEvents,
  } = props;
  const { token } = theme.useToken();
  const { timezone, depot: defaultDepot } = useSelector(selectCurrentOrganization);
  const [filterVisible, setFilterVisible] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [mapFullSize, setMapFullSize] = useState(false);
  const [synopticFullSize, setSynopticFullSize] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);
  const { addReference } = useContext(TourContext);
  const ref = useRef(null);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'following' };
  addReference('synopticFollowingRef', ref);

  // handle idle
  const handleIdle = () => {
    setIsIdleModalOpen(true);
  };
  const { isIdle, activate, setDisableTimer, remaining } = useIdleTimeout({
    onIdle: handleIdle,
    idleTime: 3600,
  });

  useEffect(() => {
    // no-op if the socket is already connected
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isIdle) {
      setIsIdleModalOpen(false);
      setDisableTimer(true);
      setOfflineMode(true);
      socket.disconnect();
    }
  }, [isIdle, setDisableTimer, setOfflineMode, isConnected]);

  const handleIdleOk = () => {
    setIsIdleModalOpen(false);
    activate();
  };
  const handleIdleCancel = () => {
    setIsIdleModalOpen(false);
    setDisableTimer(true);
    setOfflineMode(true);
  };

  const modalIdleTitle = () => {
    return (
      <Space
        direction="vertical"
        size="middle"
        align="center"
        style={{ width: '100%', justifyContent: 'center', marginBottom: '15px' }}
      >
        <FontAwesomeIcon
          icon={['fas', 'fa-circle-exclamation']}
          color={token.colorWarning}
          style={{ marginLeft: '1rem', marginRight: '1rem', fontSize: '40px' }}
        />
        <strong>{i18n.t('inactivityNotice', scopeI18n)}</strong>
      </Space>
    );
  };

  const { id: currOrgId } = useSelector(selectCurrentOrganization);
  const { data: organization } = useGetOrganizationQuery(currOrgId);

  // Synoptic settings
  const [userSettings, setUserSettings] = useState({
    referenceDate: dayjs(),
    shownTimeForward: 0,
    shownTimeBackward: 0,
    shownTimeReference: 'hours',
    showRouteNumber: true,
    minimalTick: 15,
    minimalTickReference: 'minutes',
  });

  // Synoptic default settings based on the routes on display.
  useEffect(() => {
    const updateSettings = (routesFetched) => {
      const initDates = [];
      const endDates = [];
      routesFetched.forEach((route) => {
        initDates.push(convertDateToDayjs(route.startsAt, timezone));
        endDates.push(convertDateToDayjs(route.endsAt, timezone));
      });
      const minDate = dayjs(Math.min(...initDates));
      const maxDate = dayjs().add(5, 'hours');
      const diffHours = Math.ceil((maxDate - minDate) / 3600000);

      return {
        referenceDate: minDate,
        shownTimeForward: diffHours,
        shownTimeBackward: 0,
        showRouteNumber: true,
        shownTimeReference: 'hours',
        minimalTick: 15,
        minimalTickReference: 'minutes',
      };
    };

    if (allRoutes.length > 0) {
      setUserSettings(updateSettings(allRoutes));
    }
  }, [allRoutes, timezone]);

  const loadsFromOrg = useMemo(() => {
    return organization?.loadsUnit || [];
  }, [organization]);

  const getLoadsWithUnit = useCallback(
    (loads) => {
      return loadsFromOrg
        .map(({ unit }, index) => {
          return `${loads[index]?.load} ${unit}`;
        })
        .join(' - ');
    },
    [loadsFromOrg]
  );

  useEffect(() => {
    const buildVehicles = (routes) => {
      // show vehicles only with latitude and route is started
      const withVehicle = routes.filter(
        (route) => route.status === 'STARTED' && route.vehicle && route.vehicle?.latitude
      );
      return withVehicle.map((route) => {
        const { vehicle, driver, assistant, depot, items } = route;
        const { id, plate, latitude: lat, longitude: lng, prevLatitude, prevLongitude } = vehicle;
        const stopItems = items.filter((item) => item.type === 'STOP');

        const dayText = convertDateToDayjs(route.startsAt, timezone, 'DD/MM/YYYY');
        const initTimeText = FormatToTime(route.startsAt, timezone);
        const endTimeText = `-${FormatToTime(route.endsAt, timezone)}`;

        let success = 0;
        let partial = 0;
        let failure = 0;
        stopItems.forEach((item) => {
          if (item.events.length > 0) {
            // Search for last event, due to the countable states being final otherwise it will be asumed as pending.
            const lastStatus = item.events.at(-1).status;
            if (lastStatus === 'SUCCESS') {
              success += 1;
            }
            if (lastStatus === 'PARTIAL') {
              partial += 1;
            }
            if (lastStatus === 'FAILURE') {
              failure += 1;
            }
          }
        });

        return {
          key: id,
          Cod: `${route.id}`,
          Nombre: plate,
          common: {
            Conductor: driver ? driver.fullName : 'Sin Asignar',
            Patente: plate,
            'N˚ telefónico': driver.phone ? driver.phone : 'No definido',
            Acompañante: assistant ? assistant.fullName : 'No Asignado',
          },
          other: {
            'Carga total': getLoadsWithUnit(route.loads),
            Entregas: `${success + partial + failure}/${items ? stopItems.length : 0}`,
          },
          'Datos de la ruta': {
            'Conjunto de ruta': route.routingSetId,
            'N˚ de ruta': route.routeOrder,
            Base: depot ? depot.name : 'No definido',
            'Hora de ruta': `${dayText} ${initTimeText}${
              route.startsAt === route.endsAt ? '' : endTimeText
            }`,
          },
          graphData: {
            TotalItems: items ? stopItems.length : 0,
            SuccessItems: success,
            PartialItems: partial,
            FailedItems: failure,
          },
          navigateRoute: `/following/route-detail/${route.id}`,
          lat,
          lng,
          type: 'car',
          prevCoords: [prevLatitude || lat, prevLongitude || lng],
          usedRows: ['Datos de la ruta'],
        };
      });
    };

    const vehicles = buildVehicles(allRoutes);
    setDataSource(vehicles);
  }, [allRoutes, timezone, getLoadsWithUnit]);

  useEffect(() => {
    if (!offlineMode) {
      if (vehiclesPositionEvents.length === 0) {
        return;
      }
      const newMapPoints = [...dataSource].map((object) => {
        const foundedVehicle = vehiclesPositionEvents
          .sort((a, b) => Number(new Date(a.timestamp)) - Number(new Date(b.timestamp)))
          .findLast((vD) => vD.vehicleId.toString() === object.key.toString());
        if (object.key.toString() === foundedVehicle?.vehicleId?.toString()) {
          return {
            ...object,
            lat: `${foundedVehicle.latitude}`,
            lng: `${foundedVehicle.longitude}`,
          };
        }
        return object;
      });
      if (JSON.stringify(dataSource) !== JSON.stringify(newMapPoints)) {
        setDataSource(newMapPoints);
      }
    }
  }, [offlineMode, dataSource, vehiclesPositionEvents]);

  const noRoutes = allRoutes.length === 0;
  let defaultCoords;
  if (defaultDepot) {
    const { latitude, longitude } = defaultDepot;
    defaultCoords = [parseFloat(latitude), parseFloat(longitude)];
  }

  return (
    <Col xs={0} md={{ span: 12 }} lg={{ span: 18 }} xxl={{ span: 20 }}>
      <Modal
        open={isIdleModalOpen}
        onOk={handleIdleOk}
        onCancel={handleIdleCancel}
        okText={i18n.t('keepActive', scopeI18n)}
        cancelText={i18n.t('close', scopeI18n)}
        okButtonProps={{ style: { backgroundColor: token.colorWarning } }}
        closable={false}
        centered
      >
        {modalIdleTitle()}
        <p style={{ textAlign: 'center' }}>
          {i18n.t('mapUnusedNotice', { ...scopeI18n, number: remaining })}
        </p>
      </Modal>
      <Col md={24}>
        <FollowingHeaderRoutes
          showTable={showTable}
          setShowTable={setShowTable}
          routingSet={routingSet}
          filterVisible={filterVisible}
          setFilterVisible={setFilterVisible}
          routeStartFilter={routeStartFilter}
          setRouteStartFilter={setRouteStartFilter}
          noRoutes={noRoutes}
        />
        <Row gutter={[12, 12]} style={{ position: 'relative', minHeight: '90vh' }}>
          <FollowingDrawerFilter
            filterVisible={filterVisible}
            routes={initialRoutes}
            allRoutes={allRoutes}
            setAllRoutes={setAllRoutes}
            setAllItems={setAllItems}
            routingSet={routingSet}
            routeStartFilter={routeStartFilter}
            setFilterVisible={setFilterVisible}
          />
          {!showTable && noRoutes && (
            <Col span={24} style={{ marginTop: '25vh' }}>
              <Empty description={i18n.t('noRoutesInLapse', scopeI18n)} />
            </Col>
          )}
          {showTable && <FollowingTableRoutes allRoutes={allRoutes} isLoading={isLoading} />}
          {!showTable && !noRoutes && (
            <>
              {!synopticFullSize && (
                <Col span={24} style={{ minHeight: 400, height: mapFullSize ? '70vh' : 400 }}>
                  <LeafletMap
                    useContainerSize
                    useCustomFullscreen
                    typeMarkers="tracking"
                    showAlertRoutes={false}
                    dataSource={dataSource}
                    mapFullSize={mapFullSize}
                    setMapFullSize={setMapFullSize}
                    defaultCoords={defaultCoords}
                  />
                </Col>
              )}
              {!mapFullSize && (
                <div ref={ref} style={{ width: '100%' }}>
                  <Col span={24} style={{ height: synopticFullSize ? '70vh' : 300 }}>
                    <SynopticBase
                      data={allRoutes}
                      userSettings={userSettings}
                      setUserSettings={setUserSettings}
                      preferedDataWidth={25}
                      showRoutingSetName
                      showAvatar
                      routesColorBy="status"
                      showRouteDetails
                      eta
                    />
                  </Col>
                  <Col span={24}>
                    <SynopticControlBar
                      userSettings={userSettings}
                      setUserSettings={setUserSettings}
                      filtersEnabled={false}
                      toggleFullScreen={() => setSynopticFullSize(!synopticFullSize)}
                      currentState={synopticFullSize ? 'expand' : 'normal'}
                      tootltipsEnabled={{
                        parameterEnabled: false,
                        expandEnabled: true,
                        hideEnabled: false,
                      }}
                    />
                  </Col>
                </div>
              )}
            </>
          )}
        </Row>
      </Col>
    </Col>
  );
}

FollowingRightComponent.defaultProps = {
  routingSet: undefined,
  allRoutes: [],
  initialRoutes: [],
  isLoading: false,
  setAllRoutes: () => {},
  setAllItems: () => {},
  routeStartFilter: ['2023-05-22', '2023-05-22'],
  setRouteStartFilter: () => {},
  setOfflineMode: () => {},
  offlineMode: false,
  isConnected: false,
  vehiclesPositionEvents: [],
};

FollowingRightComponent.propTypes = {
  allRoutes: FollowingRoutesProps,
  initialRoutes: FollowingRoutesProps,
  isLoading: PropTypes.bool,
  routingSet: FollowingRoutingSet,
  setAllRoutes: PropTypes.func,
  setAllItems: PropTypes.func,
  routeStartFilter: PropTypes.arrayOf(PropTypes.string),
  setRouteStartFilter: PropTypes.func,
  setOfflineMode: PropTypes.func,
  offlineMode: PropTypes.bool,
  isConnected: PropTypes.bool,
  vehiclesPositionEvents: PropTypes.arrayOf(VehiclePositionPropTypes),
};

export default FollowingRightComponent;
