import { App, Button, Col, Row, Table, Typography } from 'antd';
import ModalConfirm from 'components/common/ModalConfirm';
import TableButtons from 'components/common/TableButtons';
import TableFilters from 'components/common/TableFilter';
import TitleHelp from 'components/common/TitleHelp';
import LocaleContext from 'components/locale/LocaleContext';
import {
  useDeleteOrRestoreLocationMutation,
  useGetCategoriesVisitQuery,
  useGetLocationsByFiltersStopMutation,
  useMassiveDeleteOrRestoreLocationMutation,
} from 'features/locations/locationsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertDateToDayjs, renderAddress } from 'services/repeated-functions';
import { LocationsPropTypes } from 'types';
import Can from 'utils/Can';

function TableVisitLocations(props) {
  const {
    data,
    loading,
    setDataCallback,
    setDrawerData,
    setDownloadQuery,
    triggerClean,
    setTriggerClean,
    paginationData,
  } = props;
  const { setTotalData, totalData, setActualPage, actualPage, downloadQuery } = paginationData;
  const [selectedLocation, setSelectedLocation] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [massiveArchiveRestoreModalVisible, setMassiveArchiveRestoreModalVisible] = useState(false);
  const [massiveChanges, { isLoading: isMassive }] = useMassiveDeleteOrRestoreLocationMutation();
  const [getLocationsByFilters] = useGetLocationsByFiltersStopMutation();
  const [deleteOrRestoreLocation] = useDeleteOrRestoreLocationMutation();
  const { data: categoriesData, isLoading } = useGetCategoriesVisitQuery();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'locations' };

  useEffect(() => {
    if (!isLoading && categoriesData) {
      const allCategories = categoriesData?.map((category) => ({
        label: category,
        value: category,
      }));
      setCategories(allCategories);
    }
  }, [categoriesData, isLoading]);

  const { Text } = Typography;

  const archiveOrRestoreLocation = async () => {
    try {
      await deleteOrRestoreLocation(selectedLocation.id).unwrap();
      const messageSuccess = i18n.t('commons.entityArchivedUnarchivedF', {
        entity: i18n.t('location', scopeI18n),
        status: i18n.t(selectedLocation.deletedAt ? 'unarchivedFem' : 'archivedFem', {
          scope: 'commons',
        }),
      });
      message.success(messageSuccess);
      // reset pagination
      setActualPage(1);
    } catch (error) {
      const errorMessage = i18n.t(
        selectedLocation.deletedAt ? 'cannotUnarchiveElement' : 'cannotArchiveElement',
        {
          scope: 'commons',
          element: i18n.t('location', scopeI18n),
        }
      );
      message.error(errorMessage);
    }
  };

  const onConfirmAction = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await archiveOrRestoreLocation();
    setTriggerClean(false);
    setIsModalVisible(false);
  };

  const onCancelAction = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
    setSelectedLocation();
  };

  const showModal = (event, record) => {
    event.stopPropagation();
    setSelectedLocation(record);
    setIsModalVisible(true);
  };

  const fieldsFilter = [
    {
      name: 'nid',
      label: i18n.t('code', scopeI18n),
      type: 'text',
    },
    {
      name: 'name',
      label: i18n.t('name', scopeI18n),
      type: 'text',
    },
    {
      name: 'address',
      label: i18n.t('city', scopeI18n),
      type: 'location_cascade',
    },
    {
      name: 'street',
      label: i18n.t('street', scopeI18n),
      type: 'text',
    },
    {
      name: 'updatedAt',
      label: i18n.t('date', scopeI18n),
      type: 'range',
    },
    {
      name: 'category',
      label: i18n.t('category', scopeI18n),
      type: 'select',
      options: categories,
    },
    {
      name: 'deletedAt',
      label: i18n.t('status', scopeI18n),
      type: 'select',
      options: [
        { value: 'true', label: i18n.t('commons.archived') },
        { value: 'false', label: i18n.t('commons.unarchived') },
      ],
    },
  ];

  const onChangePage = async (page, pageSize) => {
    // include filter parameters
    const filterQuery = downloadQuery !== '' ? `&${downloadQuery.slice(1)}` : '';
    const queryString = `?page=${page}&take=${pageSize}${filterQuery}`;
    try {
      setActualPage(page);
      const { data: dataLocation, total } = await getLocationsByFilters(queryString).unwrap();
      setTotalData(total);
      setDataCallback(dataLocation);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const renderTableFilters = () => (
    <TableFilters
      setDataCallback={setDataCallback}
      fieldsFilter={fieldsFilter}
      showTitle={false}
      reduxFunc={getLocationsByFilters}
      triggerClean={triggerClean}
      setDownloadQuery={setDownloadQuery}
      paginationData={{ setTotalData, setActualPage }}
    />
  );

  const onClickActionButton = (type, record) =>
    setDrawerData({
      drawerType: type,
      drawerVisibility: true,
      drawerLocation: record,
    });

  const buttonsTable = (record) => (
    <TableButtons
      subject="visits"
      record={record}
      readClickHandle={onClickActionButton}
      editClickHandle={onClickActionButton}
      deleteClickHandle={showModal}
    />
  );

  const renderDate = (recordDate) =>
    convertDateToDayjs(recordDate, tzOrganization, 'DD-MM-YYYY HH:mm');

  const columns = [
    {
      title: (
        <TitleHelp title={i18n.t('code', scopeI18n)} helpText={i18n.t('helps.code', scopeI18n)} />
      ),
      dataIndex: 'nid',
      key: 'nid',
      width: 24,
    },
    {
      title: i18n.t('name', scopeI18n),
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: i18n.t('address', scopeI18n),
      dataIndex: 'location',
      key: 'location',
      width: 200,
      render: (_text, record) => renderAddress(record?.addresses),
    },
    {
      title: i18n.t('createdAt', scopeI18n),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (_text, record) => renderDate(record?.createdAt),
    },
    {
      title: i18n.t('updatedAt', scopeI18n),
      dataIndex: 'updateAt',
      key: 'updateAt',
      width: 200,
      render: (_text, record) => renderDate(record?.updatedAt),
    },
    {
      title: (
        <TitleHelp
          title={i18n.t('category', scopeI18n)}
          helpText={i18n.t('helps.category', scopeI18n)}
        />
      ),
      dataIndex: 'category',
      key: 'category',
      width: 120,
    },
    {
      key: 'action',
      width: 220,
      render: (_text, record) => buttonsTable(record),
    },
  ];

  const okText = selectedLocation?.deletedAt
    ? i18n.t('commons.unarchive')
    : i18n.t('commons.archive');
  const titleModal = i18n.t(selectedLocation?.deletedAt ? 'sureUnarchive' : 'sureArchive', {
    scope: 'commons',
    element: i18n.t('locations.visit'),
  });
  const colorButtonProps = { danger: selectedLocation?.deletedAt === null };
  const modalStatus = selectedLocation?.deletedAt ? 'warning' : 'error';

  // rowSelection object indicates the need for row selection
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const showMassiveChangeStatusModal = (event) => {
    event.stopPropagation();
    setMassiveArchiveRestoreModalVisible(true);
  };
  const onMassiveChangeStatusCancelAction = (event) => {
    event.stopPropagation();
    setMassiveArchiveRestoreModalVisible(false);
  };

  const massiveArchiveRestore = async () => {
    try {
      const result = await massiveChanges({ ids: selectedRowKeys }).unwrap();
      const { data: dataLocation, total } = await getLocationsByFilters('').unwrap();
      setTotalData(total);
      setDataCallback(dataLocation);
      const { totalArchived, totalRestored } = result;
      const textArchived =
        totalArchived > 0 ? i18n.t('totalArchived', { ...scopeI18n, count: totalArchived }) : '';
      const textRestored =
        totalRestored > 0 ? i18n.t('totalUnarchived', { ...scopeI18n, count: totalRestored }) : '';
      const successText = i18n.t('changeStatusMassiveDetail', {
        ...scopeI18n,
        archived: textArchived,
        restored: textRestored,
      });
      message.success(successText);
      setSelectedRowKeys([]);
    } catch (error) {
      console.log(error);
      message.error(i18n.t('errors.changeStatusMassive', scopeI18n));
    }
  };
  const onMassiveChangeStatusOkAction = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await massiveArchiveRestore();
    setTriggerClean(false);
    setMassiveArchiveRestoreModalVisible(false);
  };

  const titleMassiveChangeStatus = i18n.t('sureChangeMassiveVisits', {
    ...scopeI18n,
    count: selectedRowKeys?.length,
  });

  const renderMassiveModalConfirm = () => (
    <ModalConfirm
      onOk={onMassiveChangeStatusOkAction}
      onCancel={onMassiveChangeStatusCancelAction}
      title={titleMassiveChangeStatus}
      isModalVisible={massiveArchiveRestoreModalVisible}
      okButtonProps={{ danger: false }}
      okText={i18n.t('changeStatus', scopeI18n)}
      status="info"
    />
  );

  const renderModalConfirm = () => (
    <ModalConfirm
      onOk={onConfirmAction}
      onCancel={onCancelAction}
      title={titleModal}
      isModalVisible={isModalVisible}
      okButtonProps={colorButtonProps}
      okText={okText}
      status={modalStatus}
    />
  );

  const pagination = {
    showTotal: (total) => `Total: ${total}`,
    total: totalData,
    showSizeChanger: true,
    onChange: onChangePage,
    current: actualPage,
  };

  const renderTable = () => (
    <Row gutter={24} style={{ height: '100%' }} className="table-container">
      <Col flex="auto">
        <Can key="delete-massive" I="delete" a="visits">
          <Button
            onClick={showMassiveChangeStatusModal}
            disabled={!hasSelected}
            loading={isMassive}
            style={{ marginBottom: '1rem' }}
          >
            {i18n.t('changeStatus', scopeI18n)}
          </Button>
        </Can>
        {selectedRowKeys.length > 0 ? (
          <Text style={{ paddingLeft: '.5rem' }}>
            {i18n.t('totalRowsSelected', { ...scopeI18n, total: selectedRowKeys.length })}
          </Text>
        ) : (
          ''
        )}
        <Table
          rowSelection={rowSelection}
          rowKey="id"
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={pagination}
        />
      </Col>
    </Row>
  );

  return (
    <>
      {renderTableFilters()}
      {renderTable()}
      {renderModalConfirm()}
      {renderMassiveModalConfirm()}
    </>
  );
}

TableVisitLocations.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(LocationsPropTypes),
  setDataCallback: PropTypes.func,
  setDrawerData: PropTypes.func,
  setDownloadQuery: PropTypes.func,
  setTriggerClean: PropTypes.func,
  triggerClean: PropTypes.bool,
  paginationData: PropTypes.shape({
    setTotalData: PropTypes.func,
    totalData: PropTypes.number,
    setActualPage: PropTypes.func,
    actualPage: PropTypes.number,
    downloadQuery: PropTypes.string,
  }),
};

TableVisitLocations.defaultProps = {
  loading: false,
  data: [],
  setDataCallback: () => {},
  setDrawerData: () => {},
  setDownloadQuery: () => {},
  setTriggerClean: () => {},
  triggerClean: false,
  paginationData: {},
};

export default TableVisitLocations;
