import { Drawer } from 'antd';
import FooterDrawer from 'components/common/FooterDrawer';
import { useGetFilesProcessingQuery } from 'features/documents/fileProcessingApiSlice';
import { useEffect, useState } from 'react';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import TableDocuments from './Table/TableDocuments';

function DocumentsList() {
  const [loading, setLoading] = useState(true);
  const [fileProcessings, setFileProcessings] = useState([]);
  const [document, setDocument] = useState();
  const [totalData, setTotalData] = useState(0);
  const { data: fileProcessingData, isLoading } = useGetFilesProcessingQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const setDataForTable = () => {
      const { data, total } = fileProcessingData;
      setFileProcessings(data);
      setTotalData(total);
      setLoading(false);
    };
    if (!isLoading) {
      setDataForTable();
    }
  }, [fileProcessingData, isLoading]);

  const onCloseDrawer = () => {
    setDocument();
  };

  const footer = <FooterDrawer onClose={onCloseDrawer} />;

  return (
    <>
      <TableDocuments
        data={fileProcessings}
        loading={loading}
        setDataCallback={setFileProcessings}
        setDocument={setDocument}
        totalData={totalData}
        setTotalData={setTotalData}
      />
      <Drawer
        title="Error"
        width={300}
        onClose={onCloseDrawer}
        open={document}
        style={{ body: { paddingBottom: 80 } }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={footer}
      >
        <JsonView data={document?.errors} shouldExpandNode={allExpanded} style={defaultStyles} />
      </Drawer>
    </>
  );
}

export default DocumentsList;
